import React from 'react';

const CitizensContext = React.createContext({
  citizensProvider: null,
});

export default CitizensContext;

export const useCitizens = () => {
  const context = React.useContext(CitizensContext);

  if (context === undefined) {
    throw new Error('useCitizens must be used within a CitizensContext');
  }

  return context;
};
