import { Locale } from '@peloton/internationalize/models/locale';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { Term as AffirmTerm } from '@ecomm/financing/affirm/term';
import { Term as KlarnaTerm } from '@ecomm/financing/klarna/term';
import type { Term } from '@ecomm/financing/models/term';
import { BundleType } from '@ecomm/shop/models/BundleType';

const DEFAULT_APR_IN_GERMANY = 0.129;
const UPDATED_APR_IN_GERMANY = 0.143;
const HOLIDAY_APR = 0.0499;
const ZERO_APR = 0;
const DEFAULT_APR = 0.1499;

export const useApr = (locale: Locale, bundleType: BundleType, term: Term): number => {
  const isToggleActive = useIsToggleActive();
  const isOft = isToggleActive('optimizeFinancingTerms');
  const isHft = isToggleActive('holidayFinancingTerms');
  const isKft = isToggleActive('updateKlarnaDEFinancingTerm');
  const apr = isHft ? HOLIDAY_APR : DEFAULT_APR;
  const aprGermany = isKft ? UPDATED_APR_IN_GERMANY : DEFAULT_APR_IN_GERMANY;

  const APR_STATIC = {
    [Locale.EnglishUnitedStates]: {
      [BundleType.Bike]: {
        [AffirmTerm.Twelve]: ZERO_APR,
        [AffirmTerm.TwentyFour]: apr,
        [AffirmTerm.ThirtyNine]: apr,
      },
      [BundleType.BikePlus]: {
        [AffirmTerm.Twelve]: ZERO_APR,
        [AffirmTerm.TwentyFour]: apr,
        [AffirmTerm.FortyThree]: apr,
      },
      [BundleType.Tread]: {
        [AffirmTerm.Twelve]: ZERO_APR,
        [AffirmTerm.TwentyFour]: apr,
        [AffirmTerm.FortyThree]: apr,
      },
      [BundleType.TreadPlus]: {
        [AffirmTerm.Twelve]: ZERO_APR,
        [AffirmTerm.TwentyFour]: apr,
        [AffirmTerm.FortyThree]: apr,
      },
      [BundleType.RainforestCafe]: {
        [AffirmTerm.Twelve]: ZERO_APR,
        [AffirmTerm.TwentyFour]: apr,
      },
      [BundleType.Row]: {
        [AffirmTerm.Twelve]: ZERO_APR,
        [AffirmTerm.TwentyFour]: apr,
        [AffirmTerm.FortyThree]: apr,
      },
    },
    [Locale.EnglishCanada]: {
      [BundleType.Bike]: {
        [AffirmTerm.Twelve]: ZERO_APR,
        [AffirmTerm.TwentyFour]: apr,
        [AffirmTerm.ThirtyNine]: apr,
      },
      [BundleType.BikePlus]: {
        [AffirmTerm.Twelve]: ZERO_APR,
        [AffirmTerm.TwentyFour]: apr,
        [AffirmTerm.FortyThree]: apr,
      },
      [BundleType.Tread]: {
        [AffirmTerm.Twelve]: ZERO_APR,
        [AffirmTerm.TwentyFour]: apr,
        [AffirmTerm.FortyThree]: apr,
      },
      [BundleType.TreadPlus]: {
        [AffirmTerm.Twelve]: ZERO_APR,
        [AffirmTerm.TwentyFour]: apr,
        [AffirmTerm.FortyThree]: apr,
      },
      [BundleType.RainforestCafe]: {
        [AffirmTerm.Twelve]: ZERO_APR,
        [AffirmTerm.TwentyFour]: apr,
      },
    },
    [Locale.EnglishAustralia]: {
      [BundleType.Bike]: {
        [AffirmTerm.Twelve]: ZERO_APR,
        [AffirmTerm.TwentyFour]: DEFAULT_APR,
        [AffirmTerm.ThirtyNine]: DEFAULT_APR,
      },
      [BundleType.BikePlus]: {
        [AffirmTerm.Twelve]: ZERO_APR,
        [AffirmTerm.TwentyFour]: DEFAULT_APR,
        [AffirmTerm.FortyThree]: DEFAULT_APR,
      },
      [BundleType.Tread]: {
        [AffirmTerm.Twelve]: ZERO_APR,
        [AffirmTerm.TwentyFour]: DEFAULT_APR,
        [AffirmTerm.FortyThree]: DEFAULT_APR,
      },
      [BundleType.TreadPlus]: {
        [AffirmTerm.Twelve]: ZERO_APR,
        [AffirmTerm.TwentyFour]: DEFAULT_APR,
        [AffirmTerm.FortyThree]: DEFAULT_APR,
      },
      [BundleType.RainforestCafe]: {
        [AffirmTerm.Twelve]: ZERO_APR,
        [AffirmTerm.TwentyFour]: DEFAULT_APR,
      },
    },
    [Locale.EnglishUnitedKingdom]: {
      [BundleType.Bike]: {
        [KlarnaTerm.Twelve]: ZERO_APR,
        [KlarnaTerm.TwentyFour]: DEFAULT_APR,
        [KlarnaTerm.ThirtyNine]: DEFAULT_APR,
      },
      [BundleType.BikePlus]: {
        [KlarnaTerm.Twelve]: ZERO_APR,
        [KlarnaTerm.TwentyFour]: DEFAULT_APR,
        [KlarnaTerm.FortyThree]: DEFAULT_APR,
      },
      [BundleType.Tread]: {
        [KlarnaTerm.Twelve]: ZERO_APR,
        [KlarnaTerm.TwentyFour]: DEFAULT_APR,
        [KlarnaTerm.FortyThree]: DEFAULT_APR,
      },
      [BundleType.TreadPlus]: {
        [KlarnaTerm.Twelve]: ZERO_APR,
        [KlarnaTerm.TwentyFour]: DEFAULT_APR,
        [KlarnaTerm.FortyThree]: DEFAULT_APR,
      },
      [BundleType.RainforestCafe]: {
        [KlarnaTerm.Twelve]: ZERO_APR,
        [KlarnaTerm.TwentyFour]: DEFAULT_APR,
      },
    },
    [Locale.GermanGermany]: {
      [BundleType.Bike]: {
        [KlarnaTerm.Twelve]: ZERO_APR,
        [KlarnaTerm.TwentyFour]: aprGermany,
        [KlarnaTerm.ThirtyNine]: aprGermany,
      },
      [BundleType.BikePlus]: {
        [KlarnaTerm.Twelve]: ZERO_APR,
        [KlarnaTerm.TwentyFour]: aprGermany,
        [KlarnaTerm.FortyThree]: aprGermany,
      },
      [BundleType.Tread]: {
        [KlarnaTerm.Twelve]: ZERO_APR,
        [KlarnaTerm.TwentyFour]: aprGermany,
        [KlarnaTerm.FortyThree]: aprGermany,
      },
      [BundleType.TreadPlus]: {
        [KlarnaTerm.Twelve]: ZERO_APR,
        [KlarnaTerm.TwentyFour]: aprGermany,
        [KlarnaTerm.FortyThree]: aprGermany,
      },
      [BundleType.RainforestCafe]: {
        [KlarnaTerm.Twelve]: ZERO_APR,
        [KlarnaTerm.TwentyFour]: aprGermany,
      },
    },
  };
  return isOft ? APR_STATIC[locale]?.[bundleType]?.[term] || ZERO_APR : ZERO_APR;
};
