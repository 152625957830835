import type { LocationChangeAction } from 'connected-react-router';
import { LOCATION_CHANGE } from 'connected-react-router';
import { pathOr } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import { getIsToggleActive } from '@ecomm/feature-toggle';
import { isLoaded } from '@ecomm/models';
import { fetchPackages } from '../api';
import {
  BundleType,
  toBundleType,
  toConfigurations,
  toProductConfiguration,
} from '../models';
import {
  addBundles,
  addCategories,
  addBundleConfigurations,
  addProductConfigurations,
  addDimensions,
  addProducts,
  addProductOptions,
  addTraits,
  getUIStateForBundleType,
  bundleTypesFetchFailed,
  bundleTypesFetchSucceeded,
  matchShopRoute,
  requestBundleTypes,
  matchRefurbConfigureRoute,
} from '../redux';

const getIsPackageGraphQLActive = getIsToggleActive('graphqlForShopPackages');

export const fetchShopBundleTypes = function* (
  client: Client,
  bundleType: BundleType,
): SagaIterator {
  try {
    if (bundleType === BundleType.Accessories) return;

    const isPackageGraphQLActive = yield select(getIsPackageGraphQLActive);
    if (isPackageGraphQLActive) {
      // short circuit if GraphQL is active
      return;
    }
    yield put(requestBundleTypes(bundleType));
    const normalizedResponse = yield call(fetchPackages, client, bundleType, true);

    const {
      entities: { bundles, categories, dimensions, options, products, traits },
    } = normalizedResponse;

    const configurations = yield call(toConfigurations, bundles, products);
    const productConfigurations = yield call(toProductConfiguration, products);

    // Reference/undefined errors were occurring in selectors because entities
    // were being put into redux before their dependencies (doesnt seem to
    // matter that promise.all was being used)
    yield all([
      put(addTraits(traits)),
      put(addDimensions(dimensions)),
      put(addProductOptions(options)),
      put(addProducts(products)),
      put(addBundles(bundles)),
      put(addCategories(categories)),
      put(addBundleConfigurations(configurations)),
      put(addProductConfigurations(productConfigurations)),
    ]);

    yield put(bundleTypesFetchSucceeded(bundleType));
  } catch (err) {
    const error = new DomainError(
      'Error fetching shop bundles in fetchShopBundleTypes saga',
      err,
    );
    yield all([put(reportError({ error })), put(bundleTypesFetchFailed(bundleType))]);
  }
};

export const checkShopStatus = function* (bundleType: BundleType): SagaIterator {
  const status: ReturnType<
    typeof getUIStateForBundleType
  > = yield select(getUIStateForBundleType, { bundleType });

  if (status && !isLoaded(status)) {
    const client = yield getContext(CLIENT_CONTEXT);
    yield call(fetchShopBundleTypes, client, bundleType);
  }
};

export const checkRouteSaga = function* (action: LocationChangeAction): SagaIterator {
  const match = yield select(matchShopRoute);
  const matchRefurb = yield select(matchRefurbConfigureRoute);
  if (!match || matchRefurb) return;

  const bundleTypeSlug = pathOr('', ['params', 'bundleType'], match);

  let bundleType;
  try {
    bundleType = toBundleType(bundleTypeSlug);
  } catch (err) {
    // The bundle type is not valid/implemented
    const error = new DomainError(
      `Error converting param ${bundleTypeSlug} to bundleType in fetcher checkRouteSaga`,
      err,
    );
    yield put(reportError({ error }));
  }

  if (bundleType) {
    yield call(checkShopStatus, bundleType);
  }
};

const watcherSaga = function* () {
  yield takeLatest(LOCATION_CHANGE, checkRouteSaga);
};

export default watcherSaga;
