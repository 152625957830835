import type { SagaIterator } from 'redux-saga';
import { call, select, takeEvery } from 'redux-saga/effects';
import { track } from '@peloton/analytics';
import { showDigitalFreeTrialForHardware } from '@ecomm/feature-toggle';
import type { ModalVariation, OpenModal } from '../redux';
import { Actions } from '../redux';

export const trackModalOpened = function* (
  event: Parameters<typeof toEvent>[0],
  { payload: { modal } }: OpenModal,
): SagaIterator {
  const showModal = yield select(showDigitalFreeTrialForHardware);

  if (showModal) {
    yield call(track, toEvent(event, modal));
  }
};

const watcher = function* () {
  yield takeEvery(Actions.OpenModal, trackModalOpened, 'Opened');
  yield takeEvery(Actions.ClickCTA, trackModalOpened, 'CTA Clicked');
};

export default watcher;

const toEvent = (event: 'Opened' | 'CTA Clicked', productInterest: ModalVariation) => ({
  event: `Free Trial Modal ${event}`,
  properties: {
    productInterest,
  },
});
