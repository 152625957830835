import { capitalize } from '@peloton/text';

export const getFormattedPageName = (path: string): string =>
  path === '/' ? 'homepage' : path.substr(1).replace('/', '-');

export const getTrackingName = (path: string, index?: number): string => {
  const pageName = getFormattedPageName(path);
  const trackingPrefix = `${pageName}-hero`;
  const slideInfo = index !== undefined ? `-slide-${index + 1}` : '';

  return `${trackingPrefix}${slideInfo}`;
};

export const getCtaTrackingData = (
  path: string,
  link: string,
  ctaIndex: number,
  slideIndex?: number,
): CtaTrackingProps => {
  const pageName = getFormattedPageName(path);
  const trackingName = getTrackingName(path, slideIndex);

  return {
    event: `Clicked Hero CTA on ${capitalize(pageName)}`,
    properties: {
      linkTo: link,
      parent: `${trackingName}`,
      unitName: `${trackingName}-cta-${ctaIndex + 1}`,
      page: path,
    },
  };
};

export type TrackingProps = {
  index?: number;
};

export type CtaTrackingProps = {
  event: string;
  properties: {
    linkTo: string;
    parent: string;
    unitName: string;
    page: string;
  };
};
