import type { LocationChangeAction } from 'connected-react-router';
import { routerActions, LOCATION_CHANGE } from 'connected-react-router';
import { takeEvery, put, all } from 'redux-saga/effects';
import { openCartPanel } from '@ecomm/cart';
import { CART_ROUTE, HOME_ROUTE } from './urls';

export const optionallyOpenCart = function* (action: LocationChangeAction) {
  // If the user opens /cart, open the cart and send them to the homepage
  if (action.payload.location.pathname === CART_ROUTE) {
    const { location } = action.payload;
    yield all([
      put(openCartPanel()),
      put(
        routerActions.replace({
          ...location,
          pathname: HOME_ROUTE,
        }),
      ),
    ]);
  }
};

const watcherSaga = function* () {
  yield takeEvery(LOCATION_CHANGE, optionallyOpenCart);
};

export default watcherSaga;
