import { grey } from '@pelotoncycle/design-system';
import type { FlattenSimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';
import { media } from '@peloton/styles';
import { EloquaEmailCapture } from '@ecomm/email-capture';
import { inlineLinkStyles } from '@ecomm/links';
import { b2, b3, b6, hb6, hr3 } from '@ecomm/typography';
import type { ThemeProps } from '../themes';
import { THEMES } from '../themes';

export const Container = styled.section`
  background-color: ${({ theme }: { theme: ThemeProps }) =>
    THEMES[theme].backgroundColor};

  padding: 40px 0;

  .success-message {
    color: ${({ theme }: { theme: ThemeProps }) => THEMES[theme].headerColor};
    text-align: center;
  }

  ${media.tabletXLarge`
    padding: 60px 0;
  `}
`;

export const ContainerInner = styled.div`
  margin: 0 auto;
  padding: 0 50px;

  ${media.tabletXLarge`
    flex-direction: row;
  `}

  ${media.desktop`
    justify-content: center;
    max-width: 960px;
  `}

  ${media.desktopLarge`
    padding: 0;
  `}
`;

export const CopyContainer = styled.div`
  color: ${({ theme }: { theme: ThemeProps }) => THEMES[theme].headerColor};
  margin: 0 auto 30px auto;
  text-align: center;
  max-width: 315px;

  ${media.tabletXLarge`
    margin-bottom: 40px;
    max-width: 480px;

  `}

  ${media.desktopLarge`
  margin-bottom: 40px;
  max-width: 680px;

`}

  h2 {
    ${hr3}
    margin-bottom: 16px;
  }

  p {
    ${b2}
  }
`;

export const CopyContainerVariant = styled.div<{
  headerTheme?: FlattenSimpleInterpolation;
}>`
  color: ${({ theme }: { theme: ThemeProps }) => THEMES[theme].headerColor};
  margin: 0 auto 30px;
  text-align: center;
  width: 100%;

  ${media.tabletXLarge`
    margin-bottom: 32px;
  `}

  h2 {
    ${({ headerTheme }) => (headerTheme ? headerTheme : hbVariant)};
    margin-bottom: 16px;
  }

  p {
    ${b2}
    margin: 0 auto;
    max-width: 316px;
    color: ${grey[70]};

    ${media.tablet`
      max-width: 600px;
    `}
  }
`;

export const hbVariant = css`
  ${hb6}
  ${media.tablet`
    font-size: 16px;
  `}
`;

export const CaptureContainer = styled.div`
  margin: 0 auto;
  width: 100%;
`;

export const StyledEmailCapture = styled(EloquaEmailCapture)`
  width: 100%;

  ${media.tablet`
    margin-bottom: 5px;
  `};

  > div:first-child > span {
    ${b3}
    text-align: center;
    color: ${({ theme }: { theme: ThemeProps }) => THEMES[theme].buttonColor};
  }

  > div:first-child > div:first-child {
    display: block;
    margin-bottom: 10px;

    > div:first-child {
      margin: 0 auto;
      max-width: 316px;

      > input {
        height: 50px !important;
      }
    }

    > div:last-child {
      margin: 8px auto 0 auto;
      max-width: 316px;
    }

    > div:last-child > button {
      border-color: ${({ theme }: { theme: ThemeProps }) => THEMES[theme].buttonColor};
      color: ${({ theme }: { theme: ThemeProps }) => THEMES[theme].buttonColor};
      font-size: 14px;
      height: 50px;
      line-height: initial;
      min-width: 100% !important;

      &:hover:not(:disabled) {
        background-color: ${({ theme }: { theme: ThemeProps }) =>
          THEMES[theme].buttonColor};
        color: ${({ theme }: { theme: ThemeProps }) => THEMES[theme].backgroundColor};
      }
    }
  }

  ${media.tablet`
    > div:first-child > div:first-child {
      display: flex;
      margin: 0;
      max-width: initial;

      > div:first-child {
        margin-right: 10px;
        max-width: 280px;
        width: 280px;

        > input {
          margin-bottom: 10px;
        }
      }

      > div:last-child {
        margin-left: 0;
        margin-top: 0;
        max-width: 200px;
        width: 200px;
      }
    }
  `}

  ${media.desktop`
    > div:first-child > div:first-child {
      width: 490px;
      margin: 0 auto;

      > div:first-child {
        margin-right: 10px;
        min-width: 280px;

        > input {
          margin-bottom: 10px;
        }
      }

      > div:last-child {
        margin-left: 0;
        margin-top: 0;
        min-width: 200px;

      }
    }
  `}
`;

export const PrivacyNotice = styled.div`
  ${b6}
  color: ${({ theme }: { theme: ThemeProps }) => THEMES[theme].finePrintColor};
  margin-left: auto;
  margin-right: auto;
  max-width: 200px;
  text-align: center;

  ${media.tablet`
    max-width: 100%;
  `};

  a {
    ${inlineLinkStyles}
  }
`;
