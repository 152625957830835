import type { TypePromo } from '@pelotoncycle/page-builder';
import { useMemo } from 'react';
import { useLocale } from '@peloton/internationalize';
import usePromosGroupedByProduct from '@content/client/hooks/usePromosGroupedByProduct';

type ContentfulLocale = NonNullable<TypePromo['fields']['showDealsPage']>[number];

const useShowPromoDealsNav = () => {
  const { data: activePromoByProduct } = usePromosGroupedByProduct();
  const locale = useLocale();

  return useMemo(() => {
    if (!activePromoByProduct) {
      return false;
    }

    return Object.values(activePromoByProduct).some(promo =>
      promo?.fields.showDealsPage?.includes(locale as ContentfulLocale),
    );
  }, [activePromoByProduct, locale]);
};

export default useShowPromoDealsNav;
