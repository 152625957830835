import { dissoc } from 'ramda';
import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { ID, Slug, TransactionState, UIState } from '@ecomm/models';
import { Status } from '@ecomm/models';
import type { Entities } from '../models';
import { BundleType } from '../models';
import type { AddToCartSuccessAction, AddToCartFailureAction } from './shopper';
import { ActionType as ShopperActionTypes } from './shopper';

export enum ActionType {
  BundleSlugRequest = 'ecomm/shop/BUNDLE_SLUG_FETCH_REQUESTED',
  BundleSlugSuccess = 'ecomm/shop/BUNDLE_SLUG_FETCH_SUCCEEDED',
  BundleSlugFail = 'ecomm/shop/BUNDLE_SLUG_FETCH_FAILED',
  BundleTypesRequest = 'ecomm/shop/BUNDLE_TYPES_FETCH_REQUESTED',
  BundleTypesSuccess = 'ecomm/shop/BUNDLE_TYPES_FETCH_SUCCEEDED',
  BundleTypesFail = 'ecomm/shop/BUNDLE_TYPES_FETCH_FAILED',
  ClearErrorsMatchingId = 'ecomm/shop/CLEAR_ERRORS_MATCHING_ID',
  ProductSlugRequest = 'ecomm/shop/PRODUCT_SLUG_FETCH_REQUESTED',
  ProductSlugSuccess = 'ecomm/shop/PRODUCT_SLUG_FETCH_SUCCEEDED',
  ProductSlugFail = 'ecomm/shop/PRODUCT_SLUG_FETCH_FAILED',
  TransactionStart = 'ecomm/shop/TRANSACTION_STARTED',
  TransactionFailed = 'ecomm/shop/TRANSACTION_FAILED',
}

export type State = Partial<Record<BundleType, UIState>> &
  Partial<Entities<TransactionState>>;

export const initialState: State = {
  [BundleType.Bike]: { status: Status.Init },
  [BundleType.BikePlus]: { status: Status.Init },
  [BundleType.Tread]: { status: Status.Init },
  [BundleType.TreadPlus]: { status: Status.Init },
  [BundleType.Accessories]: { status: Status.Init },
};

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.BundleTypesRequest:
      return {
        ...state,
        [action.payload.bundleType]: { status: Status.Loading },
      };

    case ActionType.BundleTypesSuccess:
      return {
        ...state,
        [action.payload.bundleType]: { status: Status.Loaded },
      };

    case ActionType.BundleTypesFail:
      return {
        ...state,
        [action.payload.bundleType]: {
          status: Status.Failed,
          exception: action.payload.exception,
        },
      };

    case ActionType.BundleSlugRequest:
      return {
        ...state,
        [action.payload.slug]: { status: Status.Loading },
      };

    case ActionType.BundleSlugSuccess:
      return {
        ...state,
        [action.payload.slug]: { status: Status.Loaded },
      };

    case ActionType.BundleSlugFail:
      return {
        ...state,
        [action.payload.slug]: {
          status: Status.Failed,
          exception: action.payload.exception,
        },
      };
    case ActionType.ProductSlugRequest:
      return {
        ...state,
        [action.payload.slug]: { status: Status.Loading },
      };

    case ActionType.ProductSlugSuccess:
      return {
        ...state,
        [action.payload.slug]: { status: Status.Loaded },
      };

    case ActionType.ProductSlugFail:
      return {
        ...state,
        [action.payload.slug]: {
          status: Status.Failed,
          exception: action.payload.exception,
        },
      };

    case ActionType.TransactionStart:
      return {
        ...state,
        [action.payload.id]: { status: Status.Loading },
      };

    case ActionType.TransactionFailed:
      return {
        ...state,
        [action.payload.id]: { status: Status.Failed },
      };

    case ShopperActionTypes.AddToCartSuccess:
      return dissoc(action.payload.id, state);

    case ShopperActionTypes.AddToCartFailure:
      return {
        ...state,
        [action.payload.id]: {
          status: Status.Failed,
          exception: action.payload.exception,
        },
      };

    case ActionType.ClearErrorsMatchingId:
      return Object.keys(state).reduce((newState, key) => {
        const value = state[key];

        if (value?.status === Status.Failed) {
          if (value.exception.id == action.payload.errorId) {
            return newState;
          }
        }

        newState[key] = value;
        return newState;
      }, {});

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  ui: State;
};

export const requestBundleTypes = (bundleType: BundleType): RequestBundleTypesAction => ({
  type: ActionType.BundleTypesRequest,
  payload: { bundleType },
});

export const bundleTypesFetchFailed = (
  bundleType: BundleType,
  errorId: string = 'shop.errors.bundleTypesFetch',
): RequestBundleTypesFailAction => ({
  type: ActionType.BundleTypesFail,
  payload: {
    exception: toException(errorId),
    bundleType,
  },
});

export const bundleTypesFetchSucceeded = (
  bundleType: BundleType,
): RequestBundleTypesSuccessAction => ({
  type: ActionType.BundleTypesSuccess,
  payload: { bundleType },
});

export const requestProductSlugFetch = (slug: Slug): RequestProductSlugAction => ({
  type: ActionType.ProductSlugRequest,
  payload: { slug },
});

export const productSlugFetchFailed = (
  slug: Slug,
  errorId: string = 'shop.errors.productSlugFetchFailed',
): RequestProductSlugFailAction => ({
  type: ActionType.ProductSlugFail,
  payload: {
    exception: toException(errorId),
    slug,
  },
});

export const productSlugFetchSucceeded = (
  slug: Slug,
): RequestProductSlugSuccessAction => ({
  type: ActionType.ProductSlugSuccess,
  payload: { slug },
});

export type RequestBundleSlugSuccessAction = {
  type: ActionType.BundleSlugSuccess;
  payload: {
    slug: Slug;
  };
};

export type RequestBundleSlugAction = {
  type: ActionType.BundleSlugRequest;
  payload: {
    slug: Slug;
  };
};

export type RequestBundleSlugFailAction = {
  type: ActionType.BundleSlugFail;
  payload: {
    exception: Exception;
    slug: Slug;
  };
};

export const requestBundleSlugFetch = (slug: Slug): RequestBundleSlugAction => ({
  type: ActionType.BundleSlugRequest,
  payload: { slug },
});

export const bundleSlugFetchFailed = (
  slug: Slug,
  errorId: string = 'shop.errors.bundleSlugFetchFailed',
): RequestBundleSlugFailAction => ({
  type: ActionType.BundleSlugFail,
  payload: {
    exception: toException(errorId),
    slug,
  },
});

export const bundleSlugFetchSucceeded = (
  slug: Slug,
): RequestProductSlugSuccessAction => ({
  type: ActionType.ProductSlugSuccess,
  payload: { slug },
});

type ClearErrorsMatchingIdAction = {
  type: ActionType.ClearErrorsMatchingId;
  payload: {
    errorId: ID;
  };
};

export const clearErrorsMatchingId = (errorId: ID): ClearErrorsMatchingIdAction => ({
  type: ActionType.ClearErrorsMatchingId,
  payload: { errorId },
});

export const startTransaction = (id: ID): StartTransactionAction => ({
  type: ActionType.TransactionStart,
  payload: { id },
});

export const failTransaction = (id: ID): FailTransactionAction => ({
  type: ActionType.TransactionFailed,
  payload: { id },
});

type RequestBundleTypesAction = {
  type: ActionType.BundleTypesRequest;
  payload: {
    bundleType: BundleType;
  };
};

type RequestBundleTypesFailAction = {
  type: ActionType.BundleTypesFail;
  payload: {
    exception: Exception;
    bundleType: BundleType;
  };
};

export type RequestBundleTypesSuccessAction = {
  type: ActionType.BundleTypesSuccess;
  payload: {
    bundleType: BundleType;
  };
};

export type RequestProductSlugSuccessAction = {
  type: ActionType.ProductSlugSuccess;
  payload: {
    slug: Slug;
  };
};

export type RequestProductSlugAction = {
  type: ActionType.ProductSlugRequest;
  payload: {
    slug: Slug;
  };
};

export type RequestProductSlugFailAction = {
  type: ActionType.ProductSlugFail;
  payload: {
    exception: Exception;
    slug: Slug;
  };
};

type StartTransactionAction = {
  type: ActionType.TransactionStart;
  payload: {
    id: ID;
  };
};

type FailTransactionAction = {
  type: ActionType.TransactionFailed;
  payload: {
    id: ID;
  };
};

type Action =
  | AddToCartSuccessAction
  | AddToCartFailureAction
  | ClearErrorsMatchingIdAction
  | RequestBundleSlugAction
  | RequestBundleSlugFailAction
  | RequestBundleSlugSuccessAction
  | RequestBundleTypesAction
  | RequestBundleTypesFailAction
  | RequestBundleTypesSuccessAction
  | RequestProductSlugAction
  | RequestProductSlugFailAction
  | RequestProductSlugSuccessAction
  | FailTransactionAction
  | StartTransactionAction;
