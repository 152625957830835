import { connect } from 'react-redux';
import type { State as EnvState } from '@peloton/env';
import type { GlobalState } from '@peloton/split-testing/redux/optimizely';
import type { ReducerState as ToggleState } from '@ecomm/feature-toggle';
import { getIsToggleActive } from '@ecomm/feature-toggle';
import HomepageView from './View';

const mapStateToProps = (state: EnvState & ToggleState & GlobalState) => ({
  showPortfolioHomepage: getIsToggleActive('showPortfolioHomepage')(state),
});

const Homepage = connect(mapStateToProps)(HomepageView);

export default Homepage;
