export enum ActionTypes {
  CheckUserExists = 'corporate-benefits/checkout/user/exists',
}

export type CheckUserExistsAction = {
  type: ActionTypes.CheckUserExists;
  payload: {
    email: string;
  };
};

export const checkUserExists = (email: string): CheckUserExistsAction => ({
  type: ActionTypes.CheckUserExists,
  payload: { email },
});
