import { contains, find, map, not, pipe, values, where, filter, prop } from 'ramda';
import { createSelector } from 'reselect';
import type { ID } from '@ecomm/models';
import type { Entities } from '@ecomm/shop/models';
import type { FitnessDiscipline, Instructor } from '../../models';
import { isPelotonInstructor } from '../../models';
import type { ReducerState } from '../redux';

export enum ActionType {
  Add = 'ecomm/instructors/ADD',
}

export type State = Record<ID, Instructor>;

export const defaultState: State = {};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.Add:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default reducer;

export const addInstructors = (instructors: State): AddInstructorsAction => ({
  type: ActionType.Add,
  payload: instructors,
});

type AddInstructorsAction = {
  type: ActionType.Add;
  payload: State;
};

type Action = AddInstructorsAction;

const getState = (state: ReducerState) => state.instructorsPage.instructors.data;

export const getInstructors = (state: ReducerState) => Object.keys(getState(state));

export const getInstructorsUserNames = createSelector(
  getState,
  (allInstructors: Entities<Instructor>) =>
    map((instructor: Instructor) => instructor.username)(values(allInstructors)),
);

type Filter = {
  pelotonInstructor: boolean;
  fitnessDiscipline: FitnessDiscipline;
};

export const getAllPelotonInstructorIds = (state: ReducerState) =>
  pipe<ReducerState, State, Instructor[], Instructor[], ID[]>(
    getState,
    values,
    filter<Instructor>(
      where({
        coachType: isPelotonInstructor,
      }),
    ),
    map(prop('id')),
  )(state);

// TODO: delete once instructors-refresh is baselined, find more composable pattern
export const getInstructorIdsByFilter = (
  state: ReducerState,
  { fitnessDiscipline, pelotonInstructor }: Filter,
) =>
  pipe<ReducerState, State, Instructor[], Instructor[], ID[]>(
    getState,
    values,
    filter<Instructor>(
      where({
        coachType: pelotonInstructor
          ? isPelotonInstructor
          : pipe(isPelotonInstructor, not),
        fitnessDisciplines: contains(fitnessDiscipline),
      }),
    ),
    map(prop('id')),
  )(state);

export const getInstructorIdsByDiscipline = (
  state: ReducerState,
  fitnessDiscipline: FitnessDiscipline,
) =>
  pipe<ReducerState, State, Instructor[], Instructor[], ID[]>(
    getState,
    values,
    filter<Instructor>(
      where({
        fitnessDisciplines: contains(fitnessDiscipline),
      }),
    ),
    map(prop('id')),
  )(state);

export const getInstructor = (state: ReducerState, id: ID) =>
  getState(state) && getState(state)[id];

const getUsername = (_: ReducerState, props: { username: string }) => props.username;

export const getInstructorByUsername = createSelector(
  getState,
  getUsername,
  (instructors: State, username: string) =>
    find(
      (instructor: Instructor) =>
        instructor.username.toLowerCase() === username.toLowerCase(),
      values(instructors),
    ),
);
