import React from 'react';
import gfm from 'remark-gfm';
import { Markdown } from '@peloton/copy';
import { Subhead } from './styles';

type Props = {
  subhead: string;
  trackingName: string;
  increaseMaxWidth?: boolean;
};

type RendererProps = {
  children: React.ReactNode;
};

const SubheadWithTransitions: React.FC<React.PropsWithChildren<Props>> = ({
  subhead,
  trackingName,
  increaseMaxWidth = false,
}) => {
  const dataTestId = `${trackingName}-subhead`;
  return (
    <Markdown
      content={subhead}
      markdown={{
        renderers: {
          // eslint-disable-next-line react/display-name
          paragraph: (() => ({ children }: RendererProps) => (
            <Subhead increaseMaxWidth={increaseMaxWidth} data-test-id={dataTestId}>
              {children}
            </Subhead>
          ))(),
        },
        plugins: [gfm],
      }}
    />
  );
};

const memo = (prevProps: Props, nextProps: Props) =>
  prevProps.subhead === nextProps.subhead &&
  prevProps.trackingName === nextProps.trackingName;

export default React.memo(SubheadWithTransitions, memo);
