import type { Locale } from '@peloton/internationalize';
import type {
  ContentAggregate,
  ProductBlockSet,
  Promo,
  PromoBanner,
  PromoHeroSet,
  LegalText,
  HeaderBodyImageWithLink,
} from '../models.generated';
import { usePromoQuery } from '../queries/contentTypes/promo.generated';
import type { PromotionalTextData } from './usePromotionalTextData';

export type PromoData = {
  key?: Promo;
  validLocales: Locale[];
  start?: string;
  end?: string;
  appBannerId?: PromoBanner;
  appCheckoutBannerId?: PromoBanner;
  bikesBannerId?: PromoBanner;
  bikeBannerId?: PromoBanner;
  bikePlusBannerId?: PromoBanner;
  treadsBannerId?: PromoBanner;
  treadBannerId?: PromoBanner;
  treadPlusBannerId?: PromoBanner;
  generalBannerId?: PromoBanner;
  shopBikeBannerId?: PromoBanner;
  shopBikePlusBannerId?: PromoBanner;
  shopTreadBannerId?: PromoBanner;
  shopTreadPlusBannerId?: PromoBanner;
  globalFinancingBannerId?: PromoBanner;
  homepageHeroId?: PromoHeroSet;
  homepageProductBlocksId?: ProductBlockSet;
  legalTextId?: LegalText;
  checkoutBannerId?: PromoBanner;
  promoModules?: HeaderBodyImageWithLink[];
  chaseBannerId?: PromoBanner;
  urgencyBanner?: PromotionalTextData[];
  promoRibbons?: PromotionalTextData[];
  productBlocks?: ContentAggregate[];
  guideBannerId?: PromoBanner;
  shopGuideBannerId?: PromoBanner;
  rowBannerId?: PromoBanner;
  shopRowBannerId?: PromoBanner;
};

export const transformPromoData = (response: any): PromoData => {
  const {
    key,
    validLocales,
    start,
    end,
    appBanner,
    appCheckoutBanner,
    bikesBanner,
    bikeBanner,
    bikePlusBanner,
    treadsBanner,
    treadBanner,
    treadPlusBanner,
    generalBanner,
    shopBikeBanner,
    shopBikePlusBanner,
    shopTreadBanner,
    shopTreadPlusBanner,
    globalFinancingBanner,
    homepageHero,
    homepageProductBlocks,
    legalText,
    checkoutBanner,
    promoModules,
    chaseBanner,
    urgencyBanner,
    promoRibbons,
    productBlocksCollection,
    guideBanner,
    shopGuideBanner,
    rowBanner,
    shopRowBanner,
  } = response?.data?.promo || {};

  const getKey = (data: any) => data?.key || undefined;

  return {
    key,
    validLocales,
    start: start || undefined,
    end: end || undefined,
    appBannerId: getKey(appBanner),
    appCheckoutBannerId: getKey(appCheckoutBanner),
    bikesBannerId: getKey(bikesBanner),
    bikeBannerId: getKey(bikeBanner),
    bikePlusBannerId: getKey(bikePlusBanner),
    treadsBannerId: getKey(treadsBanner),
    treadBannerId: getKey(treadBanner),
    treadPlusBannerId: getKey(treadPlusBanner),
    generalBannerId: getKey(generalBanner),
    shopBikeBannerId: getKey(shopBikeBanner),
    shopBikePlusBannerId: getKey(shopBikePlusBanner),
    shopTreadBannerId: getKey(shopTreadBanner),
    shopTreadPlusBannerId: getKey(shopTreadPlusBanner),
    globalFinancingBannerId: getKey(globalFinancingBanner),
    homepageHeroId: getKey(homepageHero),
    homepageProductBlocksId: getKey(homepageProductBlocks),
    urgencyBanner: urgencyBanner?.aggregateCollection?.items,
    promoRibbons: promoRibbons?.aggregateCollection?.items,
    legalTextId: getKey(legalText),
    checkoutBannerId: getKey(checkoutBanner),
    promoModules: promoModules?.aggregateCollection?.items?.map((item: any) =>
      getKey(item),
    ),
    chaseBannerId: getKey(chaseBanner),
    productBlocks: productBlocksCollection?.items?.map((item: any) => item.key),
    guideBannerId: getKey(guideBanner),
    shopGuideBannerId: getKey(shopGuideBanner),
    rowBannerId: getKey(rowBanner),
    shopRowBannerId: getKey(shopRowBanner),
  };
};

const usePromoData = (id: Promo): PromoData => {
  const res = usePromoQuery({ variables: { id } });

  return transformPromoData(res);
};

export default usePromoData;
