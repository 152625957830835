import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';
import Handler from './Handler';

const Routes = [
  <Route
    exact
    key="bike-holiday-page"
    toggleId="holidayBikePage"
    path={`/bike/promo`}
    pageBuilderRoute
    component={asyncComponent(() => import('@page-builder/layout/Page'))}
  />,
  <Route
    key="bike-page"
    path="/:discipline(bike)"
    component={Handler}
    shouldTrack={false}
  />,
];

export default Routes;
