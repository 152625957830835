import { authSaga } from '@peloton/auth';
import { sagas as driftSagas } from '@peloton/drift';
import { errorReportingSaga } from '@peloton/error-reporting';
import { splitTestingSaga } from '@peloton/split-testing/sagas';
import { analyticsSaga } from '@ecomm/analytics';
import { cartSaga } from '@ecomm/cart';
import { sagas as chatSagas } from '@ecomm/chat';
import { checkoutSagas } from '@ecomm/checkout';
import { sagas as featureToggleSagas } from '@ecomm/feature-toggle';
import { sagas as financingSagas } from '@ecomm/financing';
import { loginFormSaga } from '@ecomm/login-form';
import { sagas as paymentSagas } from '@ecomm/payment';
import { sagas as pageCheckoutSagas } from '@ecomm/pg-checkout';
import { analyticSaga as checkoutPageAnalyticsSaga } from '@ecomm/pg-checkout/analytics';
import { sagas as concessionaryPricingSaga } from '@ecomm/pg-concessionary-pricing';
import { sagas as digitalCheckoutSagas } from '@ecomm/pg-digital-checkout';
import { sagas as digitalPromotionSagas } from '@ecomm/pg-digital-promotion';
import homePageSagas from '@ecomm/pg-home/sagas';
import { sagas as instructorsSagas } from '@ecomm/pg-instructors';
import { saga as pressSagas } from '@ecomm/pg-press';
import { sagas as pgRegisterSagas } from '@ecomm/pg-register';
import { sagas as pgRegisterFreeTrialSagas } from '@ecomm/pg-register-free-trial';
import { sagas as shippingReservationSagas } from '@ecomm/pg-shipping-reservation';
import { sagas as privacyBannerSagas } from '@ecomm/privacy-banner';
import { sagas as promoModalSagas } from '@ecomm/promo-modal';
import { sagas as quickCheckoutSagas } from '@ecomm/quick-checkout';
import { sagas as registrationSagas } from '@ecomm/registration';
import { sagas as scrollSagas } from '@ecomm/scroll';
import { setPasswordSaga } from '@ecomm/set-password';
import { sagas as shopSagas } from '@ecomm/shop';
import { saga as showroomsSagas } from '@ecomm/showrooms';
import { sagas as tradeInSagas } from '@ecomm/trade-in';
import { userSubscriptionsWatcherSaga } from '@ecomm/user-subscriptions';
import { warrantySaga } from '@ecomm/warranty';
import oneWellnessSagas from '@onewellness/sagas/ecomm';

export const toStoreSagas = () => [
  analyticsSaga,
  authSaga,
  cartSaga,
  chatSagas,
  checkoutPageAnalyticsSaga,
  checkoutSagas,
  concessionaryPricingSaga,
  digitalCheckoutSagas,
  digitalPromotionSagas,
  driftSagas,
  errorReportingSaga,
  featureToggleSagas,
  financingSagas,
  homePageSagas,
  instructorsSagas,
  loginFormSaga,
  oneWellnessSagas,
  pageCheckoutSagas,
  paymentSagas,
  pgRegisterFreeTrialSagas,
  pgRegisterSagas,
  pressSagas,
  privacyBannerSagas,
  promoModalSagas,
  quickCheckoutSagas,
  registrationSagas,
  scrollSagas,
  setPasswordSaga,
  shippingReservationSagas,
  shopSagas,
  showroomsSagas,
  splitTestingSaga,
  tradeInSagas,
  userSubscriptionsWatcherSaga,
  warrantySaga,
];
