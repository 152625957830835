import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

const QuestionDetailsPage = asyncComponent(
  () => import('./QuestionDetailsPage' /* webpackChunkName: "QuestionDetailsPage" */),
);

const Routes = [
  <Route
    exact
    page
    key="question-detail-page"
    path="/:product/question/:questionId"
    component={QuestionDetailsPage}
  />,
  <Route
    exact
    page
    key="question-detail-page"
    path="/:product/answer/:answerId"
    component={QuestionDetailsPage}
  />,
];

export default Routes;
