import { all, fork } from 'redux-saga/effects';
import analytics from './analytics';
import init from './init';
import { default as redirectActive } from './redirectActive';
import registerWithFreeTrial from './registerWithFreeTrial';
import successfulLogin from './successfulLogin';
import userExists from './userExists';

export default function* sagas() {
  yield all([
    fork(analytics),
    fork(init),
    fork(redirectActive),
    fork(registerWithFreeTrial),
    fork(successfulLogin),
    fork(userExists),
  ]);
}
