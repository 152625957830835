import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { UIState } from '@ecomm/models';
import { Status, toUIState } from '@ecomm/models';

export enum ActionTypes {
  FreeTrialRequested = 'ecomm/pg-register-free-trial/FREE_TRIAL_REQUESTED',
  FreeTrialSucceeded = 'ecomm/pg-register-free-trial/FREE_TRIAL_SUCCEEDED',
  FreeTrialFailed = 'ecomm/pg-register-free-trial/FREE_TRIAL_FAILED',
  RegisterAndFreeTrialRequested = 'ecomm/pg-register-free-trial/REGISTER_FREE_TRIAL_REQUESTED',
  EmailSubmittedSuccess = 'ecomm/pg-register-free-trial/EMAIL_SUBMITTED_SUCCESS',
}

export type State = UIState;

export const defaultState = toUIState(Status.Init);

const reducer = (state: State = defaultState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.FreeTrialRequested:
    case ActionTypes.RegisterAndFreeTrialRequested:
      return toUIState(Status.Loading);

    case ActionTypes.FreeTrialSucceeded:
      return toUIState(Status.Loaded);

    case ActionTypes.FreeTrialFailed: {
      const { exception } = action.payload;
      return toUIState(Status.Failed, exception);
    }
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  pgRegisterFreeTrial: State;
};

export const requestRegisterWithFreeTrial = (): RegisterRequestAction => ({
  type: ActionTypes.RegisterAndFreeTrialRequested,
});

export const requestFreeTrial = (): RequestAction => ({
  type: ActionTypes.FreeTrialRequested,
});

export const succeedFreeTrial = (): SuccessAction => ({
  type: ActionTypes.FreeTrialSucceeded,
});

export const failFreeTrial = (errorId: string = 'default'): FailAction => ({
  type: ActionTypes.FreeTrialFailed,
  payload: { exception: toException(`pgRegisterFreeTrial.errors.${errorId}`) },
});

export const submittedEmailSuccess = (): EmailSuccessAction => ({
  type: ActionTypes.EmailSubmittedSuccess,
});

export type RegisterRequestAction = { type: ActionTypes.RegisterAndFreeTrialRequested };
export type RequestAction = { type: ActionTypes.FreeTrialRequested };
type SuccessAction = { type: ActionTypes.FreeTrialSucceeded };
type FailAction = {
  type: ActionTypes.FreeTrialFailed;
  payload: { exception: Exception };
};
type EmailSuccessAction = { type: ActionTypes.EmailSubmittedSuccess };

type Actions =
  | RegisterRequestAction
  | RequestAction
  | SuccessAction
  | FailAction
  | EmailSuccessAction;
