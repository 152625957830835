import { connect } from 'react-redux';
import { modalOpened, modalClosed } from '@ecomm/modal';
import { LinkView } from './ModalLinkView';

const mapDispatchToProps = {
  modalOpened,
  modalClosed,
};

export default connect(undefined, mapDispatchToProps)(LinkView);
