import { useEffect, useState } from 'react';
import { useClient } from '@peloton/api/ClientContext';
import { usePrograms } from '@ecomm/programs/Context';
import type { ShowroomProps } from '@ecomm/showrooms/model';
import { fetchShowrooms } from './api';
import type { PossibleShowroomState } from './model';

// Filter locatable showrooms
const locatableFilter = (item: ShowroomProps) => {
  return item.latitude !== 0 && item.longitude !== 0;
};

export const useShowroomData = (): ShowroomProps[] | null => {
  const client = useClient();
  const [showrooms, setShowrooms] = useState<ShowroomProps[] | null>(null);
  useEffect(() => {
    if (!client) return;

    const request = async () => {
      const response = await fetchShowrooms(client);
      if (response) {
        setShowrooms(response.filter(locatableFilter));
      }
    };

    request();
  }, [client]);

  return showrooms;
};

export const useGetShowroomStatus = (): PossibleShowroomState => {
  const { showroom } = usePrograms();
  return showroom;
};

export const useGetVirtualShowroom = (): boolean => {
  const { virtualShowroom } = usePrograms();
  return virtualShowroom;
};
