import React, { forwardRef } from 'react';
import type { Omit } from '@peloton/types';
import { trackWithEloqua } from '@ecomm/analytics';
import type { Props as EmailCaptureFormProps } from './EmailCaptureForm';
import EmailCaptureForm from './EmailCaptureForm';

type Props = Omit<EmailCaptureFormProps, 'toHandleFormSubmit'> & {
  eventName: string;
  eventProps: object;
  hasInvisibleForm?: boolean;
};

const EloquaEmailCaptureForm: React.FC<React.PropsWithChildren<Props>> = ({
  eventName,
  eventProps,
  ...props
}) => {
  const eloquaFormSubmit: EmailCaptureFormProps['toHandleFormSubmit'] = setSubmitStatus => (
    email: string,
  ) => {
    setSubmitStatus('submitting');

    trackWithEloqua({
      event: eventName,
      properties: {
        email: email,
        ...eventProps,
      },
      callback: () => {
        setSubmitStatus('success');
      },
    });
  };

  return <EmailCaptureForm toHandleFormSubmit={eloquaFormSubmit} {...props} />;
};
export default EloquaEmailCaptureForm;

type EloquaEmailCaptureFormProps = {
  email: string;
  formUrl: string;
  formName: string;
  siteId: string;
};

const EloquaHiddenCaptureForm = forwardRef<HTMLFormElement, EloquaEmailCaptureFormProps>(
  ({ email, formName, siteId, formUrl }, ref) => (
    <div style={{ display: 'none' }}>
      <form ref={ref} method="POST" action={formUrl} target="eloqua_form">
        <input type="hidden" name="emailAddress" value={email} />
        <input type="hidden" name="elqFormName" value={formName} />
        <input type="hidden" name="elqSiteID" value={siteId} />
        <input type="submit" value="Submit" />
      </form>
      <iframe title="eloqua" src="" name="eloqua_form" />
    </div>
  ),
);

EloquaHiddenCaptureForm.displayName = 'EloquaHiddenCaptureForm';
