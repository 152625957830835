import type { SagaIterator } from 'redux-saga';
import type { Effect } from 'redux-saga/effects';
import { call, select, takeEvery } from 'redux-saga/effects';
import { UserReducerActionType } from '@peloton/auth';
import { getExtLinkEnv, toHref } from '@peloton/external-links';
import { Links } from '@peloton/links';
import { redirect } from '@peloton/navigation';
import { matchCheckoutRoute } from '../redux';

export const redirectOnCheckout = function* (): SagaIterator {
  const match = yield select(matchCheckoutRoute);

  if (match && match.isExact) {
    const extLinkEnv = yield select(getExtLinkEnv);
    yield call(redirect, toHref(Links.peloton, extLinkEnv));
  }
};

const logOutWatcher = function* (): IterableIterator<Effect> {
  yield takeEvery(UserReducerActionType.LOGIN_REDIRECT, redirectOnCheckout);
};

export default logOutWatcher;
