import { path, pickAll } from 'ramda';
import type { Metadata as MetadataEnum } from '../models.generated';
import { useMetadataQuery } from '../queries/contentTypes/metadata.generated';
import type { Metadata, Asset } from '../types.generated';

export type MetadataDataType = {
  title: string;
  description: string;
  keywords: string[];
  ogtype: string;
  image?: Asset;
};

const useMetadataData = (id: MetadataEnum): MetadataDataType => {
  const res = useMetadataQuery({ variables: { id } });

  const {
    title = '',
    description = '',
    keywords = [],
    ogtype = 'website',
    image,
  } = pickAll<any, Metadata>(
    ['title', 'description', 'keywords', 'ogtype', 'image'],
    path(['data', 'metadata'], res) || {},
  );

  return {
    title,
    description,
    keywords: (keywords || []) as string[],
    ogtype,
    image,
  };
};

export default useMetadataData;
