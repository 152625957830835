import { LOCATION_CHANGE } from 'connected-react-router';
import { anyPass } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { call, getContext, select, take, takeEvery } from 'redux-saga/effects';
import {
  UserReducerActionType,
  isUserSignedIn,
  getSignedInUsername,
} from '@peloton/auth';
import { EXT_LINK_ENV_CONTEXT, toHref, toWWWLink } from '@peloton/external-links';
import { MYMEMBERSHIP_ROUTE } from '@peloton/links/account';
import { redirect } from '@peloton/navigation';
import { getUserTransactionState } from '@ecomm/auth';
import { isInitial, isLoaded, isLoading } from '@ecomm/models';
import { CALLBACK } from '@ecomm/oauth/urls';
import { toCheckRouteSaga } from '@ecomm/saga-utils';
import { matchRegister as matcher } from '../route';
import redirectOnSuccess from './redirectOnSuccess';

export const redirectedFromCallbackPageSSO = document.referrer.includes(CALLBACK);

export const redirectIfLoggedIn = function* (): SagaIterator {
  const isSignedIn = yield select(isUserSignedIn);
  const hasUsername = yield select(getSignedInUsername);
  if (isSignedIn) {
    if (!redirectedFromCallbackPageSSO) {
      yield call(redirectOnSuccess);
    }
    if (redirectedFromCallbackPageSSO && hasUsername) {
      const extLinkEnv = yield getContext(EXT_LINK_ENV_CONTEXT);
      yield call(redirect, toHref(toWWWLink(MYMEMBERSHIP_ROUTE), extLinkEnv));
    }
  }
};

export const waitUntilLoaded = function* (): SagaIterator {
  while (true) {
    yield take([
      UserReducerActionType.REQUEST_SUCCESS,
      UserReducerActionType.REQUEST_FAILURE,
    ]);

    return yield call(redirectIfLoggedIn);
  }
};

export const checkUserState = function* (): SagaIterator {
  const userStatus = yield select(getUserTransactionState);

  if (isLoaded(userStatus)) {
    yield call(redirectIfLoggedIn);
  } else if (isPending(userStatus)) {
    yield call(waitUntilLoaded);
  }
};

export const checkRouteAndUserState = toCheckRouteSaga({
  matcher,
  onMatch: checkUserState,
});

const watcher = function* () {
  yield takeEvery(LOCATION_CHANGE, checkRouteAndUserState);
};

export default watcher;

const isPending = anyPass([isLoading, isInitial]);
