const ZIP_SDK_URL =
  'https://static.zipmoney.com.au/lib/js/zm-widget-js/dist/zip-widget.min.js';

export const addZipSDKToPage = async () => {
  const script = document.createElement('script');
  script.id = 'zip-widget-script';
  script.src = ZIP_SDK_URL;
  script.async = true;
  document.body.appendChild(script);
};
