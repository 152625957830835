// NOTE: This format will likely soon be deprecated in favor of app level routing.
// If you are seeing this in the far future, something has gone wrong.
import { createMatchSelector } from 'connected-react-router';
import React from 'react';
import { createSelector } from 'reselect';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

export const PATH = '/pos-confirmation';

const route = [
  <Route
    exact={true}
    key={PATH}
    page={true}
    path={PATH}
    component={asyncComponent(() => import('./Page'))}
  />,
];

export default route;

const matchRegisterFreeTrialDefault = createMatchSelector(PATH);
const matchRegisterFreeTrialI18n = createMatchSelector(`/:locale${PATH}`);
export const matchRegisterFreeTrial = createSelector(
  [matchRegisterFreeTrialDefault, matchRegisterFreeTrialI18n],
  (us, i18n) => {
    return us || i18n;
  },
);
