import type { ReactNode } from 'react';
import React, { createContext, useMemo, useState } from 'react';

type ContextData = {
  geolocation?: Geolocation;
  setGeolocation: React.Dispatch<React.SetStateAction<Geolocation>>;
  position?: GeolocationPosition;
  setPosition: React.Dispatch<React.SetStateAction<GeolocationPosition>>;
};

const initialState = {
  setGeolocation: () => {},
  setPosition: () => {},
};

export const Context = createContext<ContextData>(initialState);
type Props = {
  children: ReactNode;
};

export const Provider: React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
  const [geolocation, setGeolocation] = useState<Geolocation | undefined>(undefined);
  const [position, setPosition] = useState<GeolocationPosition | undefined>(undefined);

  const value = useMemo(
    () => ({
      geolocation,
      setGeolocation,
      position,
      setPosition,
    }),
    [position, geolocation],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const withProvider = <T extends object>(
  Component: React.FC<React.PropsWithChildren<T>>,
  // eslint-disable-next-line react/display-name
) => (props: T) => <Provider>{<Component {...props} />}</Provider>;
