import { has } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { call, select, takeEvery } from 'redux-saga/effects';
import { track } from '@peloton/analytics';
import { getUserTrackingProperties } from '@ecomm/auth';
import type { Bundle, Product } from '@ecomm/shop/models';
import toTrackingCategory from '@ecomm/shop/models/toTrackingCategory';
import {
  toEquipmentTypeByProductLine,
  toCatalogProductLine,
} from '@ecomm/shop/sagas/models';
import type { ViewedProductAction } from '../redux';
import { AnalyticsActionType, getBundleById, getProductById } from '../redux';

export const trackSaga = function* ({
  payload: { bundleId, productId },
}: ViewedProductAction): SagaIterator {
  const user = yield select(getUserTrackingProperties);

  if (bundleId) {
    const bundle = yield select(getBundleById, { id: bundleId });
    if (bundle) {
      yield call(track, toTrackingEvent(bundle, user));
    }
  } else if (productId) {
    const product = yield select(getProductById, { id: productId });
    if (product) {
      yield call(track, toTrackingEvent(product, user));
    }
  }
};

const watcherSaga = function* () {
  yield takeEvery(AnalyticsActionType.ViewedProduct, trackSaga);
};

export default watcherSaga;

const toTrackingEvent = (bundleOrProduct: Bundle | Product, props = {}) => ({
  event: 'Product Viewed',
  properties: toTrackingProperties(bundleOrProduct, props),
});

const toTrackingProperties = (bundleOrProduct: Bundle | Product, props = {}) => {
  const { id: productId, name, price, slug: sku } = bundleOrProduct;
  const category = isBundle(bundleOrProduct)
    ? toTrackingCategory(toCatalogProductLine(bundleOrProduct.bundleType))
    : toTrackingCategory(toEquipmentTypeByProductLine(bundleOrProduct.productLine));

  return {
    category,
    productId,
    sku,
    name,
    price,
    ...props,
  };
};

const isBundle = (bundleOrProduct: Bundle | Product): bundleOrProduct is Bundle =>
  has('bundleType', bundleOrProduct);
