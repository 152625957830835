import { pathOr } from 'ramda';
import type { BundleType } from '@ecomm/shop/models';
import type { Headband } from '../models.generated';
import { useHeadbandQuery } from '../queries/contentTypes/headband.generated';
import type { CtaWithUrlData } from './types';
import { transformCtaWithUrlData } from './useCtaWithUrlData';
import { transformPromotionalTextData } from './usePromotionalTextData';

export type HeadbandData = {
  key: Headband;
  title: string;
  device: BundleType;
  financingText?: string;
  ctaLink: CtaWithUrlData;
};

export const transformHeadbandData = (response: object): HeadbandData => {
  const { key, title, financingText, ctaLink, device } = pathOr(
    {},
    ['data', 'headband'],
    response,
  );

  return {
    key,
    title,
    device,
    financingText:
      financingText &&
      transformPromotionalTextData({ data: { promotionalText: financingText } }),
    ctaLink: transformCtaWithUrlData({ data: { ctaWithUrl: ctaLink } }),
  };
};

const useHeadbandData = (id: Headband): HeadbandData => {
  const res = useHeadbandQuery({ variables: { id } });

  return transformHeadbandData(res);
};

export default useHeadbandData;
