// THIS IS AN AUTOGENERATED FILE (@ecomm/graphql/codegen.yml). DO NOT EDIT THIS FILE DIRECTLY.
/* eslint-disable */
import * as Types from '../../graphql/types.generated';

import { DocumentNode } from 'graphql';
import * as ApolloReactCommon from '@ecomm/graphql/hooks';
import * as ApolloReactHooks from '@ecomm/graphql/hooks';
export type QueryFinancingPerLocaleQueryVariables = Types.Exact<{
  locale: Types.Scalars['String'];
}>;

export type QueryFinancingPerLocaleQuery = { __typename?: 'Query' } & {
  financingPerLocale: { __typename?: 'Financing' } & Pick<
    Types.Financing,
    | 'row'
    | 'bike'
    | 'bikePlus'
    | 'tread'
    | 'treadPlus'
    | 'guide'
    | 'refurbishedBike'
    | 'refurbishedBikePlus'
  >;
};

export const QueryFinancingPerLocaleDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'QueryFinancingPerLocale' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'financingPerLocale' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locale' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'row' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bike' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bikePlus' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tread' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'treadPlus' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'guide' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'refurbishedBike' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'refurbishedBikePlus' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useQueryFinancingPerLocaleQuery__
 *
 * To run a query within a React component, call `useQueryFinancingPerLocaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFinancingPerLocaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFinancingPerLocaleQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useQueryFinancingPerLocaleQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    QueryFinancingPerLocaleQuery,
    QueryFinancingPerLocaleQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    QueryFinancingPerLocaleQuery,
    QueryFinancingPerLocaleQueryVariables
  >(QueryFinancingPerLocaleDocument, baseOptions);
}
export function useQueryFinancingPerLocaleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    QueryFinancingPerLocaleQuery,
    QueryFinancingPerLocaleQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    QueryFinancingPerLocaleQuery,
    QueryFinancingPerLocaleQueryVariables
  >(QueryFinancingPerLocaleDocument, baseOptions);
}
export type QueryFinancingPerLocaleQueryHookResult = ReturnType<
  typeof useQueryFinancingPerLocaleQuery
>;
export type QueryFinancingPerLocaleLazyQueryHookResult = ReturnType<
  typeof useQueryFinancingPerLocaleLazyQuery
>;
export type QueryFinancingPerLocaleQueryResult = ApolloReactCommon.QueryResult<
  QueryFinancingPerLocaleQuery,
  QueryFinancingPerLocaleQueryVariables
>;
