import { useCallback, useEffect } from 'react';
import { track } from '@peloton/analytics/analytics';
import { useLocale } from '@peloton/internationalize';
import { localeTldMap } from '@peloton/internationalize/models/locale';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import type { AffirmPrequalCallbackHandler } from './api';
import { prequalCompleteCallback, refreshAffirmUI } from './api';
import loadAffirm from './script';

type Opts = {
  refreshValues?: unknown[];
};

export const useAffirm = (price?: number, options: Opts = {}) => {
  const locale = useLocale();
  const useTransactionAPIKeys = useIsToggleActive()('affirmTransactionsAPIKeys');
  const refreshValues = options.refreshValues || [];

  const tld = localeTldMap[locale];
  useEffect(() => {
    loadAffirm(tld, useTransactionAPIKeys);
  }, [tld, useTransactionAPIKeys]);

  useEffect(() => {
    refreshAffirmUI();
  }, [price, ...refreshValues]);
};

export const useAffirmPrequalComplete = (callback?: AffirmPrequalCallbackHandler) => {
  useEffect(() => {
    let cleanup: () => void | undefined;
    if (callback) {
      cleanup = prequalCompleteCallback(callback);
    }

    return () => {
      cleanup?.();
    };
  }, [callback]);
};

export const useTrackAffirmPrequalComplete = (
  componentName: string,
  productName: string,
): AffirmPrequalCallbackHandler => {
  const locale = useLocale();

  return useCallback(
    (data: any) => {
      track({
        event: 'Completed Prequal',
        properties: {
          pageName: getCurrentRelativePathname(),
          productName,
          unitName: componentName,
          installmentAmount: data.installment_amount,
          installmentCount: data.installment_count,
          interestAmount: data.interest_amount,
          prequalAmount: data.prequal_amount,
          status: data.status,
          country: locale,
        },
      });
    },
    [componentName, locale, productName],
  );
};
