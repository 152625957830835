import type { RouterRootState } from 'connected-react-router';
import { useSelector } from 'react-redux';
import { checkAndRemoveLocalePrefix } from '@peloton/internationalize/models/path';
import type { ReducerState } from '@ecomm/feature-toggle';
import { getIsToggleActive } from '@ecomm/feature-toggle';
import { useProductStates, Product } from '@ecomm/product-states';
import { BannerPlacement } from '../models';

const urlComparison = (url: string, test: string): boolean => {
  const splitTest = test.split('/');
  const splitUrl = url.split('/');

  const noMatch = splitTest.find((urlPart, i) => {
    return urlPart !== splitUrl[i];
  });

  return !noMatch;
};

export const appPromos = BannerPlacement.app;
export const digitalAppPromos = BannerPlacement.appCheckout;
export const shopBikePromos = BannerPlacement.shopBike;
export const shopBikePlusPromos = BannerPlacement.shopBikePlus;
export const shopTreadPromos = BannerPlacement.shopTread;
export const treadProductPromos = BannerPlacement.tread;
export const treadPlusPromos = BannerPlacement.treadPlus;
export const shopTreadPlusPromos = BannerPlacement.shopTreadPlus;
export const bikesPromos = BannerPlacement.bikes;
export const treadsPromos = BannerPlacement.treads;
export const bikeProductPromos = BannerPlacement.bike;
export const bikePlusPromos = BannerPlacement.bikePlus;
export const checkoutPromos = BannerPlacement.checkout;
export const digitalCheckoutPromos = BannerPlacement.digitalCheckout;
export const financingPromos = BannerPlacement.globalFinancing;
export const chasePromos = BannerPlacement.chase;
export const generalPromos = BannerPlacement.general;
export const guidePromos = BannerPlacement.guide;
export const shopGuidePromos = BannerPlacement.shopGuide;
export const rowPromos = BannerPlacement.row;
export const shopRowPromos = BannerPlacement.shopRow;

export const usePromoPlacement = (): BannerPlacement => {
  const currentPath = useSelector((state: RouterRootState) =>
    checkAndRemoveLocalePrefix(state.router.location.pathname),
  );

  const {
    isProductAvailableForMarketingPages,
    isProductAvailableForPurchase,
    isProductAvailableForPurchaseOrSoldOut,
    isProductAvailableForShopLeadCapture,
  } = useProductStates();

  const isTreadPlusSignUpPageActive = useSelector((state: ReducerState) =>
    getIsToggleActive('treadPlusSignupPage')(state),
  );

  const isGuideMarketable = isProductAvailableForMarketingPages(Product.RainforestCafe);
  if (isGuideMarketable && urlComparison(currentPath, '/guide')) {
    return guidePromos;
  }

  const isGuideAvailable = isProductAvailableForPurchaseOrSoldOut(Product.RainforestCafe);
  if (isGuideAvailable && urlComparison(currentPath, '/shop/guide')) {
    return shopGuidePromos;
  }

  const isRowMarketable = isProductAvailableForMarketingPages(Product.Row);
  if (isRowMarketable && urlComparison(currentPath, '/row')) {
    return rowPromos;
  }

  const isRowAvailable = isProductAvailableForPurchaseOrSoldOut(Product.Row);
  if (isRowAvailable && urlComparison(currentPath, '/shop/row')) {
    return shopRowPromos;
  }

  const isAppMarketable = isProductAvailableForMarketingPages(Product.DigitalApp);

  if (isAppMarketable && urlComparison(currentPath, '/app')) {
    return appPromos;
  }

  const isAppAvailable = isProductAvailableForPurchase(Product.DigitalApp);
  if (isAppAvailable && urlComparison(currentPath, '/digital/checkout/digital-30d')) {
    return digitalAppPromos;
  }

  const isBikePlusPurchasable = isProductAvailableForPurchaseOrSoldOut(Product.BikePlus);
  if (isBikePlusPurchasable && urlComparison(currentPath, '/shop/bike-plus')) {
    return shopBikePlusPromos;
  }

  const isTreadPlusPurchasable = isProductAvailableForPurchaseOrSoldOut(
    Product.TreadPlus,
  );
  if (isTreadPlusPurchasable && urlComparison(currentPath, '/shop/tread-plus')) {
    return shopTreadPlusPromos;
  }

  const isTreadPurchasable = isProductAvailableForPurchaseOrSoldOut(Product.Tread);
  const isTreadShopLeadCapture = isProductAvailableForShopLeadCapture(Product.Tread);
  if (
    (isTreadPurchasable || isTreadShopLeadCapture) &&
    urlComparison(currentPath, '/shop/tread')
  ) {
    return shopTreadPromos;
  }

  const isBikePurchasable = isProductAvailableForPurchaseOrSoldOut(Product.Bike);
  if (isBikePurchasable && urlComparison(currentPath, '/shop/bike')) {
    return shopBikePromos;
  }

  const isTreadPlusMarketable = isProductAvailableForMarketingPages(Product.TreadPlus);
  if (
    (isTreadPlusMarketable && urlComparison(currentPath, '/tread-plus')) ||
    (!isTreadPlusMarketable &&
      isTreadPlusSignUpPageActive &&
      urlComparison(currentPath, '/tread-plus/sign-up'))
  ) {
    return treadPlusPromos;
  }

  const isBikeMarketable = isProductAvailableForMarketingPages(Product.Bike);
  const isBikePlusMarketable = isProductAvailableForMarketingPages(Product.BikePlus);

  if (isBikeMarketable && isBikePlusMarketable && urlComparison(currentPath, '/bikes')) {
    return bikesPromos;
  }

  const isTreadMarketable = isProductAvailableForMarketingPages(Product.Tread);
  if (
    isTreadMarketable &&
    isTreadPlusMarketable &&
    urlComparison(currentPath, '/treads')
  ) {
    return treadsPromos;
  }

  if (isBikePlusMarketable && urlComparison(currentPath, '/bike-plus')) {
    return bikePlusPromos;
  }

  if (
    isBikeMarketable &&
    (urlComparison(currentPath, '/shop/accessories/bike') ||
      urlComparison(currentPath, '/bike'))
  ) {
    return bikeProductPromos;
  }

  if (
    isTreadMarketable &&
    (urlComparison(currentPath, '/tread') ||
      urlComparison(currentPath, '/shop/accessories/tread-bootcamp'))
  ) {
    return treadProductPromos;
  }

  if (urlComparison(currentPath, '/digital/checkout')) {
    return digitalCheckoutPromos;
  }

  if (urlComparison(currentPath, '/checkout')) {
    return checkoutPromos;
  }

  if (urlComparison(currentPath, '/financing')) {
    return financingPromos;
  }

  if (urlComparison(currentPath, '/chase-sapphire')) {
    return chasePromos;
  }

  return generalPromos;
};
