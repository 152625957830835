import { hasVariation } from '@peloton/split-testing';

const CLASSES_LEAD_CAPTURE_EXPERIMENT = 'Classes Lead Capture Modal';
const VARIATION_1 = 'Variation #1';
const VARIATION_2 = 'Variation #2';
const VARIATION_3 = 'Variation #3';

export const showClassesModalVariation1 = hasVariation(
  CLASSES_LEAD_CAPTURE_EXPERIMENT,
  VARIATION_1,
);
export const showClassesModalVariation2 = hasVariation(
  CLASSES_LEAD_CAPTURE_EXPERIMENT,
  VARIATION_2,
);
export const showClassesModalVariation3 = hasVariation(
  CLASSES_LEAD_CAPTURE_EXPERIMENT,
  VARIATION_3,
);
