import type { SagaIterator } from 'redux-saga';
import { all, call, fork, select, takeEvery } from 'redux-saga/effects';
import { track } from '@peloton/analytics';
import { getCheckoutTrackProps } from '@ecomm/cart';
import type { SubmitFinancingAction } from '@ecomm/financing';
import { Actions as FinancingActions } from '@ecomm/financing';
import type { MessageAction } from './actions';
import { Message, Actions } from './actions';
import trackCheckoutStarted from './trackCheckoutStarted';
import trackCheckoutStepCompleted from './trackCheckoutStepCompleted';
import trackCheckoutStepViewed from './trackCheckoutStepViewed';
import trackPaymentInfoEntered from './trackPaymentInfoEntered';

type TrackSubmitFinancingProperties = {
  financingPartner?: string;
};

const trackSubmitFinancing = (properties: TrackSubmitFinancingProperties) =>
  properties.financingPartner === 'Affirm'
    ? track({ event: 'Started Affirm Registration', properties })
    : track({ event: 'Started Financing Application', properties });

const MESSAGES_MAP = {
  [Message.shippingDetailField]: 'Entered Shipping Detail',
  [Message.movedOffEmail]: 'Moved away from email field during checkout',
  [Message.paymentDetailField]: 'Entered Payment Detail',
  [Message.paymentCVCField]: 'Entered CVC',
  [Message.calculateShipping]: 'Calculated Shipping Fees',
  [Message.clickedBuyWithFinancing]: 'Clicked Buy With Financing',
  [Message.clickedPlaceOrder]: 'Clicked Place Order',
  [Message.clickedReviewOrder]: 'Clicked Review Order',
};

export const messageSaga = function* ({
  payload: { message, properties = {} },
}: MessageAction): SagaIterator {
  const event = MESSAGES_MAP[message];

  if (!event) {
    return;
  }

  if (message === Message.movedOffEmail) {
    const checkoutTrackProps = yield select(getCheckoutTrackProps);

    yield call(track, {
      event,
      properties: {
        ...properties,
        ...checkoutTrackProps,
        listname: 'Website Leads',
      },
    });
  } else {
    yield call(track, {
      event,
      properties,
    });
  }
};

export const financingSaga = function* (action: SubmitFinancingAction): SagaIterator {
  const financingPartner = action.payload.financingPartner;
  yield call(trackSubmitFinancing, { financingPartner });
};

const sagas = function* () {
  yield takeEvery(Actions.Message, messageSaga);
  yield takeEvery(FinancingActions.SubmitFinancing, financingSaga);
  yield all([
    fork(trackCheckoutStarted),
    fork(trackCheckoutStepViewed),
    fork(trackCheckoutStepCompleted),
    fork(trackPaymentInfoEntered),
  ]);
};

export default sagas;
