import { useProductStates } from './Context';

const useToLinkIfShopCta = (path: string): string | undefined => {
  const { isRouteAvailable } = useProductStates();
  if (isShopPath(path)) {
    return isRouteAvailable(path) ? path : undefined;
  }
  return path ? path : undefined;
};

export default useToLinkIfShopCta;

const isShopPath = (path: string): boolean =>
  /\/?shop\/((?:bike|tread)(?:-plus)?)\/?/.test(path);
