import type { Saga } from 'redux-saga';
import { call, select, takeLatest } from 'redux-saga/effects';
import {
  theUserIsInCanada,
  theUserIsInTheUnitedKingdom,
  toLocaleFromHostname,
} from '@peloton/internationalize';
import type { SelectPaymentMethodAction } from '@ecomm/checkout/redux';
import {
  getSelectedPaymentMethod,
  PaymentActions,
  UiActionType,
  ReviewActions,
  ShippingActions,
} from '@ecomm/checkout/redux';
import { takeFirst } from '@ecomm/saga-utils';
import type { AdditionalTrackingProps, Payload } from './trackCheckoutSteps';
import { toTrackCheckoutStepsSaga } from './trackCheckoutSteps';

const isAcceptedLocale = function () {
  const locale = toLocaleFromHostname();

  return theUserIsInTheUnitedKingdom(locale) || theUserIsInCanada(locale);
};

const sendOrderReviewEvent: Saga = function* () {
  const paymentMethod = yield select(getSelectedPaymentMethod);

  if (paymentMethod === 'Credit Card' || isAcceptedLocale()) {
    yield call(trackCheckoutStepViewedSaga, 4);
  } else {
    return;
  }
};

const toEvent = (step: number, properties: Payload) => ({
  event: 'Checkout Step Viewed',
  properties: { ...properties, step, stepName: toStepName(step) },
});

export const trackCheckoutStepViewedSaga = (toTrackCheckoutStepsSaga(
  toEvent,
) as any) as Saga<[number, AdditionalTrackingProps?]>;

export const trackPaymentSelected = function* ({ payload }: SelectPaymentMethodAction) {
  if (payload.paymentMethod !== 'unknown') {
    yield call(trackCheckoutStepViewedSaga, 3);
  }
};

export const trackActivationViewed = function* () {
  yield call(trackCheckoutStepViewedSaga, 6);
};

const watcherSaga = function* () {
  yield takeLatest(PaymentActions.SelectPaymentMethod, trackPaymentSelected);
  yield call(takeFirst, trackCheckoutStepViewedSaga, UiActionType.Ready, 1);
  yield call(
    takeFirst,
    trackCheckoutStepViewedSaga,
    ShippingActions.UpdateShippingRequest,
    2,
  );
  yield call(takeFirst, sendOrderReviewEvent, ReviewActions.ReviewOrderSuccess);
};

export default watcherSaga;

export const toStepName = (step: number) =>
  ({
    1: 'Viewed Checkout Page',
    2: 'Started Entering Shipping Information',
    3: 'Selected Payment Option (CC or Financing Selection)',
    4: '(CC only + UK and CA) Viewed Order Review Page',
    5: 'Viewed Checkout Payment Screen',
  }[step]);
