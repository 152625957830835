import type { Exception } from '@ecomm/exceptions';
import { toException } from '@ecomm/exceptions';
import type { ID } from '@ecomm/models';
import type { ProductLine } from '@ecomm/shop/models';
import type { BundleConfiguration, Entities } from '../models';

export enum ActionType {
  AddToCartRequested = 'ecomm/shop/ADD_TO_CART_REQUESTED',
  AddPackageToCartRequested = 'ecomm/shop/ADD_PACKAGE_TO_CART_REQUESTED',
  AddProductToCartRequested = 'ecomm/shop/ADD_PRODUCT_TO_CART_REQUESTED',
  AddBundleToCartRequested = 'ecomm/shop/ADD_BUNDLE_TO_CART_REQUESTED',
  AddWarrantyToCartRequested = 'ecomm/shop/ADD_WARRANTY_TO_CART_REQUESTED',
  AddWarrantyToCartSuccess = 'ecomm/shop/ADD_WARRANTY_TO_CART_SUCCESS',
  AddToCartSuccess = 'ecomm/shop/ADD_TO_CART_SUCCESS',
  AddToCartFailure = 'ecomm/shop/ADD_TO_CART_FAILURE',
  RemoveWarrantyFromCart = 'ecomm/shop/REMOVE_WARRANTY_FROM_CART',
}

type State = Entities<BundleConfiguration>;

export const initialState: State = {};

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  configure: State;
};

export const requestAddToCart = (
  id: ID = '',
  tradeIn: boolean = false,
): AddToCartRequestedAction => ({
  type: ActionType.AddToCartRequested,
  payload: {
    id,
    tradeIn,
  },
});

export const requestAddBundleToCart = (
  id: ID = '',
  isUpsell?: boolean,
): AddBundleToCartRequestedAction => ({
  type: ActionType.AddBundleToCartRequested,
  payload: {
    id,
    isUpsell,
  },
});

export const requestAddPackageToCart = (
  id: ID = '',
): AddPackageToCartRequestedAction => ({
  type: ActionType.AddPackageToCartRequested,
  payload: {
    id,
  },
});

export const requestAddProductToCart = (
  id: ID = '',
  isUpsell?: boolean,
): AddProductToCartRequestedAction => ({
  type: ActionType.AddProductToCartRequested,
  payload: {
    id,
    isUpsell,
  },
});

export const requestAddWarrantyToCart = (
  id: ID,
  productLine: ProductLine,
): AddWarrantyToCartRequestedAction => ({
  type: ActionType.AddWarrantyToCartRequested,
  payload: {
    id,
    productLine,
  },
});

export const addWarrantyToCartSuccess = (
  id: ID,
  productLine: ProductLine,
): AddWarrantyToCartSuccessAction => ({
  type: ActionType.AddWarrantyToCartSuccess,
  payload: {
    id,
    productLine,
  },
});

export const requestRemoveWarrantyFromCart = (
  id: ID,
  productLine: ProductLine,
): RemoveWarrantyFromCartRequestedAction => ({
  type: ActionType.RemoveWarrantyFromCart,
  payload: {
    id,
    productLine,
  },
});

export const addToCartSuccess = (
  id: ID,
  optionId?: ID,
  isUpsell?: boolean,
): AddToCartSuccessAction => ({
  type: ActionType.AddToCartSuccess,
  payload: {
    id,
    optionId,
    isUpsell,
  },
});

export const addToCartFailure = (id: ID, message: string): AddToCartFailureAction => ({
  type: ActionType.AddToCartFailure,
  payload: {
    id,
    exception: toException(message),
  },
});

export type AddToCartRequestedAction = {
  type: ActionType.AddToCartRequested;
  payload: {
    id: ID;
    tradeIn: boolean;
  };
};

export type AddBundleToCartRequestedAction = {
  type: ActionType.AddBundleToCartRequested;
  payload: {
    id: ID;
    isUpsell?: boolean;
  };
};

export type AddPackageToCartRequestedAction = {
  type: ActionType.AddPackageToCartRequested;
  payload: {
    id: ID;
  };
};

export type AddProductToCartRequestedAction = {
  type: ActionType.AddProductToCartRequested;
  payload: {
    id: ID;
    isUpsell?: boolean;
  };
};

export type AddWarrantyToCartRequestedAction = {
  type: ActionType.AddWarrantyToCartRequested;
  payload: {
    id: ID;
    productLine: ProductLine;
  };
};

export type AddWarrantyToCartSuccessAction = {
  type: ActionType.AddWarrantyToCartSuccess;
  payload: {
    id: ID;
    productLine: ProductLine;
  };
};

export type RemoveWarrantyFromCartRequestedAction = {
  type: ActionType.RemoveWarrantyFromCart;
  payload: {
    id: ID;
    productLine: ProductLine;
  };
};

export type AddToCartSuccessAction = {
  type: ActionType.AddToCartSuccess;
  payload: {
    id: ID;
    optionId?: ID;
    isUpsell?: boolean;
  };
};

export type AddToCartFailureAction = {
  type: ActionType.AddToCartFailure;
  payload: {
    id: ID;
    exception: Exception;
  };
};

type Action =
  | AddToCartRequestedAction
  | AddBundleToCartRequestedAction
  | AddPackageToCartRequestedAction
  | AddProductToCartRequestedAction
  | AddWarrantyToCartRequestedAction
  | AddWarrantyToCartSuccessAction
  | AddToCartSuccessAction
  | AddToCartFailureAction;
