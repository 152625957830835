// NOTE: This format will be deprecated in favor of app level routing.
// TODO: Remove
import { createMatchSelector } from 'connected-react-router';
import React from 'react';
import { createSelector } from 'reselect';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';
import { RoutePaths } from './config';

const route = [
  <Route
    page={true}
    exact={true}
    key={RoutePaths.RegisterPage}
    path={RoutePaths.RegisterPage}
    component={asyncComponent(
      () => import('./Handler' /* webpackChunkName: "Register" */),
    )}
  />,
];

export default route;

// This hack is necessary because the typings are as poor as the react-router library
const matchRegisterRoute = createMatchSelector({
  path: RoutePaths.RegisterPage,
  exact: true,
} as any);
const matchRegisterI18n = createMatchSelector({
  path: `/:locale${RoutePaths.RegisterPage}`,
  exact: true,
} as any);
export const matchRegister = createSelector(
  [matchRegisterRoute, matchRegisterI18n],
  (us, i18n) => {
    return us || i18n;
  },
);
