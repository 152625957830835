import { any, ascend, descend, prop, sort } from 'ramda';
import type { Currency } from '@peloton/internationalize';
import {
  isProductLine,
  isSubscriptionProductLine,
  ProductLine,
} from '@ecomm/shop/models/Product';
import type {
  Bundle,
  Cart,
  Device,
  Discount,
  Item,
  ShipmentQuote,
  SingleItem,
  TradeIn,
} from '../models';
import { ShippingMethod } from '../models';
import type {
  ApiBundleOptionSet,
  ApiCoupon,
  ApiCouponSavings,
  ApiItemSet,
  ApiOption,
  ApiShippingQuote,
  ApiSummary,
} from './types';

type ItemWithProductLine = SingleItem & { productLine: ProductLine };

const toProductLine = (apiProductLine: string): ProductLine => {
  if (isProductLine(apiProductLine)) {
    return apiProductLine;
  } else {
    return ProductLine.Other;
  }
};

const toItemWithProductLine = ({
  id,
  description,
  imageUrls,
  priceInCents,
  productId,
  productLine,
  sku,
  slug,
  displayName,
}: ApiOption): ItemWithProductLine => ({
  id,
  description,
  images: imageUrls,
  name: displayName,
  price: priceInCents,
  productId,
  productLine: toProductLine(productLine),
  quantity: 1,
  sku,
  slug,
  type: 'item',
});

const isDevice = (item: ItemWithProductLine) =>
  [
    ProductLine.Bike,
    ProductLine.StoneOcean,
    ProductLine.Tread,
    ProductLine.TreadPlus,
    ProductLine.BikePlus,
    ProductLine.Row,
    ProductLine.RefurbBike,
    ProductLine.RefurbBikePlus,
  ].includes(item.productLine);

const toBundleFromAPI = ({
  id,
  coupon,
  description,
  displayName,
  grossInCents,
  imageUrls,
  productOptions,
  bundle,
  slug,
}: ApiBundleOptionSet): Bundle | Device => {
  const items = productOptions.map(toItemWithProductLine);
  return {
    id,
    discount: coupon ? toDiscountFromBundleAPI(coupon) : undefined,
    description: bundle?.description ?? description,
    images: bundle?.imageUrls ?? imageUrls,
    items,
    name: displayName,
    price: grossInCents,
    quantity: 1,
    slug: bundle?.slug ?? slug,
    type: any(isDevice, items) ? 'device' : 'bundle',
  };
};

const toTradeIn = (acc: TradeIn, { id, hasTradeIn }: ApiBundleOptionSet): TradeIn =>
  hasTradeIn
    ? {
        id,
        quantity: acc?.quantity + 1 || 1,
      }
    : acc;

const toItemFromAPI = ({
  coupon,
  grossInCents,
  productOption,
  quantity,
}: ApiItemSet): Item => ({
  id: productOption.id,
  productId: productOption.productId,
  discount: coupon ? toDiscountFromBundleAPI(coupon) : undefined,
  // lift up name & description from option
  description: productOption.description,
  images: productOption.imageUrls,
  name: productOption.displayName,
  price: grossInCents,
  productLine: toProductLine(productOption.productLine),
  quantity,
  slug: productOption.slug,
  sku: productOption.sku,
  type: isSubscriptionProductLine(productOption.productLine) ? 'subscription' : 'item',
});

const byPrice = descend<Item>(prop('price'));
const byName = ascend<SingleItem>(prop('name'));

// TODO: Move this to model as it works on the model directly and based on business rules.
export const orderItems = (items: Item[]): Item[] => [
  ...sort(
    byPrice,
    items.filter(item => item.type === 'device'),
  ),
  ...sort(
    byPrice,
    items.filter(item => item.type === 'subscription'),
  ),
  ...sort(
    byPrice,
    items.filter(item => item.type === 'bundle'),
  ),
  ...sort(
    byPrice,
    items.filter(item => item.type === 'item'),
  ),
];

const trimItem = (item: Item): Item => {
  switch (item.type) {
    case 'bundle':
      return {
        ...item,
        items: sort(byName, item.items),
      };
    case 'device':
      return item;
    case 'item':
      return item;
    default:
      return item;
  }
};

const toDiscountFromBundleAPI = ({ code, value, description }: ApiCoupon): Discount => ({
  amount: value,
  code,
  description,
  isRemovable: true,
  displayName: code,
});

const toDiscountFromCartAPI = () => ({
  couponCode,
  totalSavingsInCents,
  description,
}: ApiCouponSavings): Discount => ({
  amount: totalSavingsInCents,
  code: couponCode,
  description: description,
  isRemovable: true,
  displayName: couponCode,
});

export const toShipmentQuote = (
  quoteSet?: ApiShippingQuote[],
): ShipmentQuote | undefined =>
  quoteSet && quoteSet.length > 0
    ? {
        amount: quoteSet.reduce((total, quote) => quote.priceInCents + total, 0),
        method: quoteSet.reduce(toShippingMethod, ShippingMethod.Peloton),
      }
    : undefined;

const shippingMethodMap = {
  peloton: ShippingMethod.Peloton,
  ux: ShippingMethod.Xpo,
};

const toShippingMethod = (
  acc: ShippingMethod,
  { partnerFlag, priceInCents }: ApiShippingQuote,
): ShippingMethod =>
  priceInCents > 0 ? shippingMethodMap[partnerFlag] || <ShippingMethod>partnerFlag : acc;

const toCurrency = (currency: string): Currency => <Currency>currency;

export const toCart = ({
  bundleOptionSet = [],
  couponSavings = [],
  currency,
  id,
  isGift = false,
  itemSet = [],
  paymentSet,
  preorderId,
  grossSubtotalInCents,
  totalInCents,
  quoteSet,
  email,
  shippingAddresseeFirstName,
  shippingAddresseeLastName,
  shippingStreetAddress1,
  shippingStreetAddress2,
  shippingCity,
  shippingState,
  shippingPostalCode,
  shippingCountry,
  shippingPhoneNumber,
  payments = [],
  ...rest
}: ApiSummary): Cart => {
  const asCurrency = toCurrency(currency);

  return {
    currency: asCurrency,
    discounts: couponSavings.map(toDiscountFromCartAPI()),
    id,

    items: orderItems(
      itemSet.map(toItemFromAPI).concat(bundleOptionSet.map(toBundleFromAPI)),
    ).map(trimItem),
    shipmentQuote: toShipmentQuote(quoteSet),
    subtotal: grossSubtotalInCents,
    total: totalInCents,
    tradeIn: bundleOptionSet.reduce(toTradeIn, undefined),
    tax: rest.taxPaidInCents === undefined ? rest.taxInCents : rest.taxPaidInCents,
    shipping: {
      name: {
        first: shippingAddresseeFirstName,
        last: shippingAddresseeLastName,
      },
      phone: shippingPhoneNumber,
      address: {
        line1: shippingStreetAddress1,
        line2: shippingStreetAddress2,
        city: shippingCity,
        state: shippingState,
        postalCode: shippingPostalCode,
        country: shippingCountry,
      },
    },
    email,
    isGift,
    payments: !!payments.length
      ? payments.map(payment => ({
          amountInCents: payment.amountInCents,
          lastFour: payment.label,
          paymentType: payment.paymentType,
          balance: payment.balance,
          paymentMethodToken: payment.paymentMethodToken,
        }))
      : [],
  };
};
