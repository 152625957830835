import {
  Body,
  brand,
  Button,
  Eyebrow,
  grey,
  Headline,
  Input,
  Support,
} from '@pelotoncycle/design-system';
import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { PanelManagerContext, Panels } from '@acme-ui/global/PanelManagerProvider';
import Flyout from '@ecomm/flyout';
import { usePromoCollectionData } from '../copy/helpers';
import type { PromoData } from '../copy/helpers/usePromoData';
import { PromoPanelContext } from './PromoPanelProvider';
import { PromoContext } from './PromoProvider';

type Props = {
  currentLocale: string;
};

const PromoPanel: React.FC<React.PropsWithChildren<Props>> = ({ currentLocale }) => {
  const { activePanel, setActivePanel } = useContext(PanelManagerContext);
  const [filter, setFilter] = useState('');

  const promo = useContext(PromoContext);
  const { key: activeKey } = promo;
  const { setActivePromo, resetActivePromo } = useContext(PromoPanelContext);
  const promos = usePromoCollectionData();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      setActivePromo(promos.find(({ key }) => key === e.currentTarget.value) || promo);
    }
  };

  const promoFilter = useCallback(
    (promoItem: PromoData) => promoItem.key?.toLowerCase().includes(filter),
    [filter],
  );

  return (
    <Flyout
      isOpen={activePanel === Panels.Promos}
      ariaLabel={'Promo Panel'}
      backgroundColor="#ffffff"
      handleRequestClose={() => setActivePanel(undefined)}
    >
      <Container>
        <Headline size="small">Promos</Headline>
        <FormWrapper>
          <Input
            value={filter}
            handleChange={value =>
              setFilter(value ? value.replace(/[^a-z0-9]/gi, '').toLowerCase() : '')
            }
            label="Search Promos"
            name="search"
          />
          <Button size="small" text="Reset active promo" onClick={resetActivePromo} />
        </FormWrapper>

        {promos.filter(promoFilter).map(({ key, start, end }) => {
          const isActivePromo = activeKey === key;

          return (
            <PromoWrapper
              className={isActivePromo ? 'active' : ''}
              key={`promo-panel-${key}`}
            >
              <input
                id={key}
                type="radio"
                onChange={handleChange}
                value={key}
                name="activePromo"
                checked={isActivePromo}
              />
              <label htmlFor={key}>
                {isActivePromo && <Eyebrow>ACTIVE PROMO</Eyebrow>}
                <Body>{key}</Body>
                <Support size="large">
                  Start: {formatDate(start, currentLocale) || 'N/A'}
                </Support>
                <Support size="large">
                  End: {formatDate(end, currentLocale) || 'N/A'}
                </Support>
              </label>
            </PromoWrapper>
          );
        })}
      </Container>
    </Flyout>
  );
};

export default PromoPanel;

export const formatDate = (utcDate?: string, locale?: string) => {
  if (!utcDate) return undefined;

  const date = new Date(utcDate);
  return date.toLocaleString(`${locale}`);
};

const PromoWrapper = styled.div`
  input {
    display: none;
  }
  border: solid 1px ${grey[30]};
  border-radius: 10px;
  padding: 30px 15px;
  box-shadow: 2px 0px 20px 2px rgba(0, 0, 0, 0.08);
  margin-top: 15px;

  label {
    cursor: pointer;
  }

  &.active {
    border: solid 3px ${brand.primary};
  }

  &:hover {
    box-shadow: 2px 0px 20px 2px rgba(0, 0, 0, 0.15);
  }
`;

const Container = styled.div`
  padding: 50px 30px;
  width: 320px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 10px;

  input,
  div {
    width: 100%;
  }

  button {
    margin-top: 15px;
    min-width: 100%;
  }
`;
