import type { ClientError } from '@peloton/api/ClientError';
import { isHttpError } from '@peloton/api/ClientError';

export const SHIPPING_ERROR = 4200;
export const UNSERVICED_POSTAL_CODE_ERROR = 4240;
export const TREAD_AVAILABILITY_IN_POSTAL_CODE = 4702;
export const BACKEND_SHIPPING_ERROR = 6100;
export const TRADE_IN_UNAVAILABLE_ERROR = 7100;

export enum ErrorCode {
  InvalidAddressee = 'invalidAddressee',
  InvalidAddress = 'invalidAddress',
  InvalidCity = 'invalidCity',
  InvalidState = 'invalidState',
  InvalidZip = 'invalidZip',
  InvalidCountry = 'invalidCountry',
  InvalidPhone = 'invalidPhone',
  PostalCodeDoesNotExist = 'postalCodeDoesNotExist',
  PostalCodeUnserviced = 'postalCodeUnserviced',
  TradeInUnavailable = 'tradeInUnavailable',
  Default = 'default',
}

const apiErrorMap = {
  [SHIPPING_ERROR]: ErrorCode.PostalCodeDoesNotExist,
  [UNSERVICED_POSTAL_CODE_ERROR]: ErrorCode.PostalCodeUnserviced,
  [TREAD_AVAILABILITY_IN_POSTAL_CODE]: ErrorCode.PostalCodeUnserviced,
  [TRADE_IN_UNAVAILABLE_ERROR]: ErrorCode.TradeInUnavailable,
};

// fields that the API validates, we are surfacing these errors to the client
enum apiAddressErrorMap {
  shipping_addressee = ErrorCode.InvalidAddressee,
  shipping_addressee_first_name = ErrorCode.InvalidAddressee,
  shipping_addressee_last_name = ErrorCode.InvalidAddressee,
  shipping_street_address_1 = ErrorCode.InvalidAddress,
  shipping_street_address_2 = ErrorCode.InvalidAddress,
  shipping_city = ErrorCode.InvalidCity,
  shipping_state = ErrorCode.InvalidState,
  shipping_postal_code = ErrorCode.InvalidZip,
  shipping_country = ErrorCode.InvalidCountry,
  shipping_phone_number = ErrorCode.InvalidPhone,
  Default = ErrorCode.Default,
}

export const toErrorCode = (error: ClientError): ErrorCode => {
  const apiErrorCode = isHttpError(error) ? error.responseBody.errorCode : undefined;

  if (isHttpError(error) && apiErrorCode === BACKEND_SHIPPING_ERROR) {
    const errorMessage = error.responseBody.message;
    const fields = Object.keys(apiAddressErrorMap);
    const invalidField =
      fields.find(field => {
        return errorMessage.includes(field);
      }) || 'Default';

    return apiAddressErrorMap[invalidField];
  }
  return apiErrorMap[apiErrorCode] || ErrorCode.Default;
};
