// NOTE: This format will likely soon be deprecated in favor of app level routing.
// If you are seeing this in the far future, something has gone wrong.
import { createMatchSelector } from 'connected-react-router';
import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

export const PATH = '/register/free-trial/600mass';

const route = [
  <Route
    exact={true}
    key={PATH}
    page={true}
    path={PATH}
    toggleId="freeTrial600MassPromo"
    component={asyncComponent(() => import('./Page'))}
  />,
];

export default route;

export const matchRegisterFreeTrial = createMatchSelector(PATH);
