import { asyncComponent } from '@peloton/code-splitting';

const BlogCardsModule = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "BlogCardsModule", webpackPrefetch: true */ './BlogCardsModule'
    ),
);

export { BlogCardsModule };
