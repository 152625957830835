import React from 'react';
import { NotFoundError } from '@ecomm/layout';
import oauthRoutes from '@ecomm/oauth/routes';
import { routes as arVisualizerRoutes } from '@ecomm/pg-ar-visualizer';
import bikeRoutes from '@ecomm/pg-bike/routes';
import bikesRoutes from '@ecomm/pg-bikes/routes';
import careersRoute from '@ecomm/pg-careers/route';
import { routes as cfuSignUpRoutes } from '@ecomm/pg-cfu-sign-up';
import { routes as chaseSapphireRoutes } from '@ecomm/pg-chase-sapphire';
import checkoutRoutes from '@ecomm/pg-checkout/routes';
import { routes as classesRoutes } from '@ecomm/pg-classes';
import concessionaryRoutes from '@ecomm/pg-concessionary-pricing/routes';
import creditCheckRoutes from '@ecomm/pg-credit-check/routes';
import { routes as deliveryWaitRoutes } from '@ecomm/pg-delivery-wait';
import digitalCheckoutRoutes from '@ecomm/pg-digital-checkout/routes';
import digitalDownloadRoute from '@ecomm/pg-digital-download/route';
import digitalPromotionRoutes from '@ecomm/pg-digital-promotion/routes';
import faasBuyoutRoutes from '@ecomm/pg-faas-buyout/routes';
import { routes as financingRoutes } from '@ecomm/pg-global-financing';
import guideRoutes from '@ecomm/pg-guide-specs/routes';
import homeRoutes from '@ecomm/pg-home/routes';
import impressumRoutes from '@ecomm/pg-impressum/routes';
import { routes as instructorsRoutes } from '@ecomm/pg-instructors';
import { routes as legalRoutes } from '@ecomm/pg-legal';
import membershipRoutes from '@ecomm/pg-membership/routes';
import { route as posConfirmationRoutes } from '@ecomm/pg-pos-confirmation';
import { routes as pressRoutes } from '@ecomm/pg-press';
import questionDetailsRoutes from '@ecomm/pg-question-details/routes';
import { routes as reasonsRoutes } from '@ecomm/pg-reasons';
import { route as registerFreeTrialRoute } from '@ecomm/pg-register-free-trial';
import registerFreeTrial600MassRoute from '@ecomm/pg-register-free-trial-600mass/route';
import registerFreeTrialBikeRoute from '@ecomm/pg-register-free-trial-bike/route';
import registerFreeTrialMaxwellRoute from '@ecomm/pg-register-free-trial-maxwell/route';
import registerFreeTrialTreadRoute from '@ecomm/pg-register-free-trial-tread/route';
import registerFreeTrialWoodlarkRoute from '@ecomm/pg-register-free-trial-woodlark/route';
import registerRoute from '@ecomm/pg-register/route';
import { routes as reviewsQandARoutes } from '@ecomm/pg-reviews-q-and-a';
import shippingReservationRoutes from '@ecomm/pg-shipping-reservation/routes';
import { Routes as shopRoutes } from '@ecomm/pg-shop';
import { routes as shopAccessoriesRoutes } from '@ecomm/pg-shop-accessories';
import { routes as shopAccessoriesDisplayRoutes } from '@ecomm/pg-shop-accessories-display';
import showroomsRoutes from '@ecomm/pg-showrooms/routes';
import { routes as treadRoutes } from '@ecomm/pg-tread';
import { routes as treadsRoutes } from '@ecomm/pg-treads';
import { routes as ugcRedirectRoutes } from '@ecomm/pg-ugc-redirect';
import calculatorRoutes from '@ecomm/pg-value-calculator/routes';
import { routes as verifyUgcEmailRoutes } from '@ecomm/pg-verify-ugc-email';
import { route as verizonPartnership } from '@ecomm/pg-verizon';
import { routes as waysToTryRoutes } from '@ecomm/pg-ways-to-try';
import { InternationalSwitch, internationalizeRoutePaths, Route } from '@ecomm/router';
import { ecommRoutes as oneWellnessRoutes } from '@onewellness/routes/ecomm';
import pageBuilderRoutes from '@page-builder/routes';

const accessoriesRedesignRoutes = [
  <Route
    key="shop-accessories-feature-toggle"
    path="/shop/accessories"
    component={() => (
      <InternationalSwitch>
        {[...shopAccessoriesRoutes, ...shopAccessoriesDisplayRoutes]}
        <NotFoundError />
      </InternationalSwitch>
    )}
  />,
];

const routes = [
  ...oauthRoutes,
  ...arVisualizerRoutes,
  ...homeRoutes,
  ...concessionaryRoutes,
  ...questionDetailsRoutes,
  ...reviewsQandARoutes,
  ...financingRoutes,
  ...cfuSignUpRoutes,
  ...bikesRoutes,
  ...accessoriesRedesignRoutes, // must come before other shop routes
  ...shopRoutes,
  ...checkoutRoutes,
  ...creditCheckRoutes,
  ...legalRoutes,
  ...instructorsRoutes,
  ...showroomsRoutes,
  ...shippingReservationRoutes,
  ...verifyUgcEmailRoutes,
  ...ugcRedirectRoutes,
  ...careersRoute,
  ...pressRoutes,
  ...digitalCheckoutRoutes,
  digitalDownloadRoute,
  ...digitalPromotionRoutes,
  ...registerFreeTrialRoute,
  ...registerFreeTrialBikeRoute,
  ...registerFreeTrialTreadRoute,
  ...registerFreeTrialMaxwellRoute,
  ...registerFreeTrial600MassRoute,
  ...registerFreeTrialWoodlarkRoute,
  ...registerRoute,
  ...posConfirmationRoutes,
  ...membershipRoutes,
  ...impressumRoutes,
  ...calculatorRoutes,
  ...reasonsRoutes,
  ...deliveryWaitRoutes,
  ...classesRoutes,
  ...chaseSapphireRoutes,
  ...oneWellnessRoutes,
  ...waysToTryRoutes,
  ...pageBuilderRoutes,
  ...bikeRoutes,
  ...guideRoutes,
  ...treadRoutes,
  ...treadsRoutes,
  verizonPartnership,
  ...faasBuyoutRoutes,
] as JSX.Element[];

const localisedRoutes = internationalizeRoutePaths(routes);

export { localisedRoutes, routes };
