import { createSelector } from 'reselect';
import { getLocale } from '@peloton/env';
import type { Locale } from '@peloton/internationalize';
import { GUIDE_MEMBERSHIP_PRICING } from '@peloton/internationalize';
import { getIsToggleActive } from '@ecomm/feature-toggle';
import { isGuideBundleTypeMatch } from '@ecomm/shop/models/Bundle';
import type { BundleType } from '@ecomm/shop/models/BundleType';
import { localeToMembershipPrice } from './utils';

// TODO: get this from plan in api instead of calculating based on Locale
// this is mostly to reduce dependency on intl as a stop-gap
export const getDeviceMembershipPrice = createSelector(
  [getLocale, getIsToggleActive('increasedAllAccessMembershipPrice')],
  localeToMembershipPrice,
);

export const getMembershipPrice = (device: BundleType) => {
  if (isGuideBundleTypeMatch(device)) {
    return createSelector([getLocale], (locale: Locale) => {
      if (Object.keys(GUIDE_MEMBERSHIP_PRICING).includes(locale)) {
        return GUIDE_MEMBERSHIP_PRICING[locale];
      }
    });
  }

  return getDeviceMembershipPrice;
};
