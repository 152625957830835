import { always, cond, equals } from 'ramda';
import type { ID } from '@ecomm/models/ID';
import type { Image } from '@ecomm/models/Image';
import type { Money } from '@ecomm/models/Money';
import type { Slug } from '@ecomm/models/Slug';
import { BundleType } from './BundleType';

export type Bundle = {
  bundleType: BundleType;
  categories: ID[];
  description: string;
  discount: Money;
  id: ID;
  images: Image[];
  name: string;
  products: ID[];
  price: Money;
  slug: Slug;
};

export const isGuideBundleTypeMatch = (bundle: string = ''): boolean => {
  return (
    BundleType.RainforestCafe.toLowerCase() === bundle.toLowerCase() ||
    'guide' === bundle.toLowerCase()
  );
};

export const selfAssemblyEligibleBundleTypes = [BundleType.Row];

export const excludedFromARBundleTypes = [
  BundleType.Accessories,
  BundleType.RainforestCafe,
];

export const isValidBundleType = (s: string) => {
  const bundleTypes = [...Object.values(BundleType), 'tread-bootcamp'];
  return bundleTypes.some(x => x === s);
};

const toIsBundleType = (bundleType: BundleType) => (bundle: Bundle) =>
  bundle.bundleType === bundleType;

export const isBikeBundle = toIsBundleType(BundleType.Bike);

export const isBikePlusBundle = toIsBundleType(BundleType.BikePlus);

export const isTreadPlusBundle = toIsBundleType(BundleType.TreadPlus);

export const isTreadBundle = toIsBundleType(BundleType.Tread);

export const isAccessoriesBundle = toIsBundleType(BundleType.Accessories);

export const isGuideBundle = toIsBundleType(BundleType.RainforestCafe);

const fail = (message: string): never => {
  throw new Error(message);
};

export const toBundleType = (
  s:
    | 'accessories'
    | 'bike'
    | 'tread'
    | 'bike-plus'
    | 'tread-plus'
    | 'guide'
    | 'rainforestCafe'
    | 'row'
    | 'refurbished-bike'
    | 'refurbished-bike-plus',
): BundleType => {
  switch (s) {
    case 'accessories':
      return BundleType.Accessories;
    case 'bike':
      return BundleType.Bike;
    case 'bike-plus':
      return BundleType.BikePlus;
    case 'tread':
      return BundleType.Tread;
    case 'tread-plus':
      return BundleType.TreadPlus;
    case 'guide':
    case 'rainforestCafe':
      return BundleType.RainforestCafe;
    case 'row':
      return BundleType.Row;
    case 'refurbished-bike':
      return BundleType.RefurbishedBike;
    case 'refurbished-bike-plus':
      return BundleType.RefurbishedBikePlus;
    default:
      return fail('Cannot convert to BundleType');
  }
};

export const toDisplayName = cond<BundleType, string>([
  [equals(BundleType.Bike), always('Bike')],
  [equals(BundleType.BikePlus), always('Bike+')],
  [equals(BundleType.Tread), always('Tread')],
  [equals(BundleType.TreadPlus), always('Tread+')],
  [equals(BundleType.RainforestCafe), always('Guide')],
  [equals(BundleType.Row), always('Row')],
  [equals(BundleType.RefurbishedBike), always('Refurbished Bike')],
  [equals(BundleType.RefurbishedBikePlus), always('Refurbished Bike+')],
  [() => true, () => fail('Cannot convert to display name')],
]);

export const toBundleTypeFromDisplayName = (device: string): BundleType | undefined => {
  switch (device) {
    case 'Bike':
      return BundleType.Bike;
    case 'Bike+':
      return BundleType.BikePlus;
    case 'BikePlus':
      return BundleType.BikePlus;
    case 'Tread':
      return BundleType.Tread;
    case 'Tread+':
      return BundleType.TreadPlus;
    case 'TreadPlus':
      return BundleType.TreadPlus;
    case 'Guide':
      return BundleType.RainforestCafe;
    case 'Row':
      return BundleType.Row;
    case 'RefurbishedBike':
      return BundleType.RefurbishedBike;
    case 'RefurbishedBikePlus':
      return BundleType.RefurbishedBikePlus;
    default:
      // support copyXray
      if (device && device.indexOf('🔑') !== -1) {
        const key = device.split('🔑')[0].replace('+', 'Plus');
        return BundleType[key];
      }
      console.error(`Unable to convert display name "${device}" to a BundleType`);
      return undefined;
  }
};

export const isBundleTypeCheck = (s: any): s is BundleType =>
  Object.values(BundleType).includes(s);
