// NOTE: This format will likely soon be deprecated in favor of app level routing.
// If you are seeing this in the far future, something has gone wrong.
import { createMatchSelector } from 'connected-react-router';
import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

export const PATH = '/register/free-trial/woodlark';

const route = [
  <Route
    exact={true}
    key={PATH}
    page={true}
    path={PATH}
    toggleId="freeTrialHotelPromo"
    component={asyncComponent(
      () => import('./Page' /* webpackChunkName: "RegisterFreeTrialWoodlark" */),
    )}
  />,
];

export default route;

export const matchRegisterFreeTrial = createMatchSelector(PATH);
