import type { Money, ID, Slug } from '@ecomm/models';
import type { ProductLine } from '@ecomm/shop/models';

export type Warranty = {
  id: ID;
  isExtended: boolean;
  slug: Slug;
  priceInCents: Money;
  extendedMonths: number;
};

export type WarrantyProductLine =
  | ProductLine.Bike
  | ProductLine.Tread
  | ProductLine.TreadPlus
  | ProductLine.BikePlus
  | ProductLine.RainforestCafe
  | ProductLine.Row
  | ProductLine.RefurbBike
  | ProductLine.RefurbBikePlus;

export type Warranties = Record<ProductLine, Array<Warranty>>;

export const isExtendedWarranty = ({ isExtended }: Warranty) => isExtended;
