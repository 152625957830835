import { Button, Icon, grey, spacing, Flex, lime } from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { theUserIsInAustria } from '@peloton/internationalize';
import { useLocale } from '@peloton/next/hooks/useLocale';
import useNav from '@content/client/www/nav/useNav';
import type { ProductNavMeta } from '@ecomm/header/models';
import { LinkButton } from '@ecomm/internationalize-ui';
import ApparelSecondaryMenuFlyout from './ApparelSecondaryMenuFlyout';
import SecondaryMenuFlyout from './SecondaryMenuFlyout';

type Props = {
  data: ProductNavMeta;
  handleRequestClose: () => void;
  className?: string;
};

const MenuItemWithFlyout: React.FC<React.PropsWithChildren<Props>> = ({
  data: { cmsId },
  handleRequestClose,
  className = '',
}) => {
  const [secondaryPanelOpen, setSecondaryPanelOpen] = useState<boolean>(false);
  const closeSecondaryPanel = () => setSecondaryPanelOpen(false);
  const locale = useLocale();
  const { content, isLoading } = useNav(locale);
  const { trackEvent } = useTracking();

  if (isLoading) {
    return null;
  }

  const entry = content?.subnavs.find((subnav: any) => subnav.key === cmsId);

  if (!entry) {
    return null;
  }

  const { key, title, ...nav } = entry;

  const url = nav.products?.[0]?.primaryCta.url;
  //If LinkComponent is defined, we are in CRA and we need to use the `to` prop.

  if (
    (cmsId as string) === 'nav.apparel' ||
    (cmsId as string) === 'nav.strength' ||
    theUserIsInAustria(locale)
  ) {
    return (
      <li className={className}>
        <ButtonChevronContainer>
          <StyledLinkButton
            data-test-id={key}
            href={url}
            text={title}
            color="dark"
            onClick={() => {
              trackEvent({
                properties: {
                  category: key,
                  linkName: title,
                  linkTo: url,
                },
              });
            }}
          />
          <StyledChevron
            name="chevron"
            primaryColor={grey[50]}
            aria-hidden={true}
            rotate={180}
            height={12}
          />
        </ButtonChevronContainer>
      </li>
    );
  }

  if ((cmsId as string) === 'nav.apparelNew') {
    // TODO: New Apparel nav here
    return (
      <li className={className}>
        <ButtonChevronContainer>
          <Button
            aria-expanded={secondaryPanelOpen}
            data-test-id={key}
            children={title}
            color="dark"
            width="adjustable"
            onClick={() => {
              trackEvent({
                properties: {
                  category: key,
                  linkName: title,
                },
              });
              setSecondaryPanelOpen(true);
            }}
          />
          <StyledChevron
            name="chevron"
            primaryColor={grey[50]}
            aria-hidden={true}
            rotate={180}
            height={12}
          />
        </ButtonChevronContainer>
        <ApparelSecondaryMenuFlyout
          isOpen={secondaryPanelOpen}
          onCloseRequest={handleRequestClose}
          onBackRequest={closeSecondaryPanel}
          data={{ key, title, ...nav }}
        />
      </li>
    );
  }

  if ((cmsId as string) === 'nav.deals') {
    return (
      <li className={className}>
        <HighlightedButtonContainer alignItems="center">
          <StyledLinkButton
            data-test-id={key}
            href={url}
            text={title}
            color="dark"
            onClick={() => {
              trackEvent({
                properties: {
                  category: key,
                  linkName: title,
                  linkTo: url,
                },
              });
            }}
          />
        </HighlightedButtonContainer>
      </li>
    );
  }

  return (
    <li className={className}>
      <ButtonChevronContainer>
        <Button
          aria-expanded={secondaryPanelOpen}
          data-test-id={key}
          children={title}
          color="dark"
          width="adjustable"
          onClick={() => {
            trackEvent({
              properties: {
                category: key,
                linkName: title,
                linkTo: url,
              },
            });
            setSecondaryPanelOpen(true);
          }}
        />
        <StyledChevron
          name="chevron"
          primaryColor={grey[50]}
          aria-hidden={true}
          rotate={180}
          height={12}
        />
      </ButtonChevronContainer>
      <SecondaryMenuFlyout
        isOpen={secondaryPanelOpen}
        onCloseRequest={handleRequestClose}
        onBackRequest={closeSecondaryPanel}
        data={{ key, title, ...nav }}
      />
    </li>
  );
};

export default MenuItemWithFlyout;

const StyledChevron = styled(Icon)`
  margin-left: ${spacing[8]};
`;

const ButtonChevronContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLinkButton = styled(LinkButton)`
  width: auto !important;
  padding: unset !important;
`;

const HighlightedButtonContainer = styled(Flex)`
  color: ${lime[50]} !important;

  a {
    color: currentColor !important;
  }
`;
