import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import type { ProductNavData, CtaWithUrlData } from '@ecomm/copy/helpers/types';
import type { ProductDetailsCard } from '@ecomm/copy/models.generated';
import useIsAppCtaExperimentActive from '@ecomm/feature-toggle/AppCta';
import { LinkButton } from '@ecomm/internationalize-ui';

const useAppCTAVariation = (cta: CtaWithUrlData) => {
  const isAppCTAExperimentActive = useIsAppCtaExperimentActive();
  if (!isAppCTAExperimentActive || !cta.url.includes('/app-membership')) {
    return cta;
  }

  return {
    ...cta,
    url: '/app/download',
  };
};

type CtaButtonComponentProps = {
  categoryId: string;
  contentfulKey: ProductDetailsCard;
  cta: CtaWithUrlData;
  handleClose: () => void;
};
const CtaButtonComponent: React.FC<React.PropsWithChildren<CtaButtonComponentProps>> = ({
  categoryId,
  contentfulKey,
  cta,
  handleClose,
}) => {
  const updatedCta = useAppCTAVariation(cta);
  const { trackEvent } = useTracking();

  return (
    <CtaButton
      key={contentfulKey}
      data-test-id={contentfulKey}
      href={updatedCta.url}
      variant="outline"
      color="light"
      size="small"
      onClick={() => {
        trackEvent({
          properties: {
            category: categoryId,
            unitName: contentfulKey,
            linkName: updatedCta.text,
            linkTo: updatedCta.url,
          },
        });
        handleClose();
      }}
      text={updatedCta.text}
    />
  );
};

type Props = {
  categoryId: string;
  products: ProductNavData['products'];
  handleClose: () => void;
};

const CtaSection: React.FC<React.PropsWithChildren<Props>> = ({
  categoryId,
  products,
  handleClose,
}) => {
  const isAppVariated = products[0].key.match(/^(?=.*nav)(?=.*app)(?=.*_variated)/);
  const { key: contentfulKey, primaryCta } = products[0];

  return (
    <>
      {isAppVariated && (
        <CtaButtonComponent
          categoryId={categoryId}
          cta={primaryCta}
          contentfulKey={contentfulKey}
          handleClose={handleClose}
        />
      )}
      {products.map(({ key, secondaryCta }) =>
        secondaryCta ? (
          <CtaButtonComponent
            categoryId={categoryId}
            cta={secondaryCta}
            contentfulKey={key}
            handleClose={handleClose}
            key={key}
          />
        ) : null,
      )}
    </>
  );
};

export default CtaSection;

const CtaButton = styled(LinkButton)`
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 15rem;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;
