import {
  HARD_CODED_GUIDE_PRICING_FOR_ECOMM_PRICING_HELPERS,
  theUserIsInTheUnitedStates,
} from '@peloton/internationalize';
import type { Locale } from '@peloton/internationalize/models';
import type { Store } from '@peloton/stores/WebStore';
import type { Money } from '@ecomm/models/Money';
import { BundleType } from '@ecomm/shop/models/BundleType';
import type { MembershipPlanData } from '../types';

export type ProductLinePackages = {
  name: string;
  slug: string;
  price: Money;
};

export type ProductPackages = Record<
  BundleType,
  {
    price: Money;
    packages: ProductLinePackages[];
  }
>;

// We should baseline this file to preview commit when Guide price is in CMS
// in order to make room for future secret projects :)
export const determineAdditionalPrices = (productPrices: LocaleProductPrices) => {
  const GUIDE_BUNDLE_TYPE = BundleType.RainforestCafe;

  // hard-coding this price discount for now, but these should get overwritten by graphql
  Object.keys(HARD_CODED_GUIDE_PRICING_FOR_ECOMM_PRICING_HELPERS).forEach(locale => {
    if (!productPrices[locale][GUIDE_BUNDLE_TYPE]?.packages?.length) {
      productPrices[locale][GUIDE_BUNDLE_TYPE] =
        HARD_CODED_GUIDE_PRICING_FOR_ECOMM_PRICING_HELPERS[locale];
    }
  });

  return {
    productPrices,
  };
};

export type LocaleProductPrices = Record<Locale, ProductPackages>;

export type StoreMembershipPrices = Record<Store, MembershipPlanData>;

export type Pricing = {
  getLowestPackagePriceByBundle: (bundle: BundleType) => Money;
};

export const getTemporaryHardcodedPricing = (locale: Locale, bundle: BundleType) => {
  return theUserIsInTheUnitedStates(locale) && bundle === BundleType.TreadPlus
    ? 429500
    : 0;
};
