import React from 'react';
import { useTracking } from 'react-tracking';
import toFormattedText from '@peloton/copy/toFormattedText';
import { toDollars } from '@ecomm/models';
import type { CartFragment } from '../graphql/fragments/Cart.generated';
import type { PartnerStorageItem } from '../shared/getStorageItem';
import { getStorageItem } from '../shared/getStorageItem';

export const AMOUNT = 'amount';
export const REFERRING_PARTNER = 'referringPartner';
export const REFERRAL_CODE_PARAM = 'referralCode';
export const PRODUCT = 'product';
export const PARTNER_STORAGE_KEY = 'ecomm/partnerPromo';
export const REFERRAL_CODE_STORAGE_KEY = 'ecomm/referralCode';

export enum Events {
  ViewedProduct = 'Viewed Product with Partner Referral Code',
  IncorrectCode = 'Incorrect Partner Referral Code Applied To Product',
}

interface PartnerParams {
  amount?: string;
  partner?: string;
  partnerParamsPresent: boolean;
  product?: string;
  referralCode?: string;
}

export const usePartnerParamsFromUrl = () => {
  const [params, setParams] = React.useState<PartnerParams>({
    amount: '',
    partner: '',
    partnerParamsPresent: false,
    product: '',
    referralCode: '',
  });

  React.useEffect(() => {
    const scopedParams = new URLSearchParams(window.location.search);
    const amount = scopedParams.get(AMOUNT);
    const partner = scopedParams.get(REFERRING_PARTNER);
    const product = scopedParams.get(PRODUCT);
    const referralCode = scopedParams.get(REFERRAL_CODE_PARAM);
    const partnerParamsPresent = [amount, partner, product].every(
      param => Boolean(param) && typeof param === 'string',
    );

    setParams({
      amount,
      partner,
      product,
      referralCode,
      partnerParamsPresent,
    } as PartnerParams);
  }, []);

  return params;
};

// this hoook is dependent on the useReferralCode stuff.
export const usePartnerPromo = () => {
  const [partnerData, setPartnerData] = React.useState<PartnerStorageItem | null>();
  const { trackEvent } = useTracking();
  const { referralCode } = usePartnerParamsFromUrl();
  const { amount, partner, partnerParamsPresent, product } = usePartnerParamsFromUrl();

  const verifyProductSelection = (cart?: CartFragment) => {
    if (cart) {
      const cartItems = {
        bike: cart.hasBike,
        'bike-plus': cart.hasBikePlus,
        tread: cart.hasTread,
        guide: cart.hasGuide,
        default: false,
      };
      const productKey = partnerData?.product ?? 'default';
      trackEvent({
        event: Events.ViewedProduct,
        properties: {
          partner: partnerData?.partner,
          product: partnerData?.product,
        },
      });
      return Boolean(cartItems[productKey]);
    } else {
      trackEvent({
        event: Events.IncorrectCode,
        properties: {
          partner: partnerData?.partner,
          product: partnerData?.product,
          referralCode,
        },
      });
      return false;
    }
  };

  React.useEffect(() => {
    if (partnerParamsPresent) {
      const formattedAmount = toFormattedText('{price, number, currency}', {
        price: toDollars(parseInt(amount || '0')),
      });
      const storageItem = {
        amount: formattedAmount,
        partner,
        partnerParamsPresent,
        product,
      };
      localStorage.setItem(PARTNER_STORAGE_KEY, JSON.stringify(storageItem));

      trackEvent({
        event: 'Viewed Shop Page with Partner Referral Code',
        properties: {
          partner,
          product,
        },
      });
    }
    const storageItem = getStorageItem(PARTNER_STORAGE_KEY) as PartnerStorageItem;
    setPartnerData(storageItem);
  }, [amount, partner, partnerParamsPresent, product, trackEvent]);

  return { ...partnerData, verifyProductSelection };
};

export const useGetPartnerParamsFromStorage = () => {
  const [partnerParams, setPartnerParams] = React.useState<PartnerParams>();
  const rawPartnerParams = localStorage?.getItem(PARTNER_STORAGE_KEY);
  const rawReferralCode = localStorage?.getItem(REFERRAL_CODE_STORAGE_KEY);

  React.useEffect(() => {
    const parseJSON = (json: string | null) => JSON.parse(json || 'null');

    const partnerPromo = parseJSON(rawPartnerParams);
    const referralCode = parseJSON(rawReferralCode)?.referralCode;
    if (Boolean(partnerPromo && referralCode)) {
      setPartnerParams({ ...partnerPromo, referralCode });
    }
  }, [rawPartnerParams, rawReferralCode]);

  return partnerParams;
};
