import { assoc, curry, dissoc } from 'ramda';
import type { OrderData } from '@ecomm/checkout/models/OrderData';

export type Stripe = stripe.Stripe;

const renameProp = curry((oldName: string, newName: string, obj: object) =>
  dissoc(oldName, assoc(newName, obj[oldName], obj)),
);

// format order data for consumption by the Stripe SDK
export const billingInfoMapper = (orderData: Pick<OrderData, 'billing' | 'email'>) => {
  const { address, name, phone } = orderData.billing;
  const { email } = orderData;

  return {
    address: renameProp('postalCode', 'postal_code', address),
    email,
    name: `${name.first} ${name.last}`,
    phone,
  };
};
