import { Locale } from '@peloton/internationalize/models/locale';
import { Locale as GraphLocale } from '@ecomm/graphql/types.generated';

const fromLocale = (locale: Locale): GraphLocale =>
  ({
    [Locale.EnglishUnitedStates]: GraphLocale.EnUs,
    [Locale.EnglishUnitedKingdom]: GraphLocale.EnGb,
    [Locale.EnglishCanada]: GraphLocale.EnCa,
    [Locale.GermanGermany]: GraphLocale.DeDe,
    [Locale.GermanAustria]: GraphLocale.DeAt,
    [Locale.EnglishAustralia]: GraphLocale.EnAu,
    [Locale.EnglishBeta]: GraphLocale.EnUs,
    [Locale.SpanishMexico]: GraphLocale.EnUs,
    [Locale.FrenchCanada]: GraphLocale.EnCa,
  }[locale]);

export default fromLocale;
