import { schema } from 'normalizr';
import deviceSubscription from './deviceSubscription';
import digitalSubscription from './digitalSubscription';

const subscriptions = new schema.Object({
  digitalSubscriptions: [digitalSubscription],
  subscriptions: [deviceSubscription],
});

export default subscriptions;
