import { BundleType } from '@ecomm/shop/models/BundleType';
import type { Partners } from './models/Partners';

export enum FinancedDevice {
  Bike = 'bike',
  Tread = 'tread',
  RainforestCafe = 'guide',
  Row = 'row',
}

export const bundleTypeToFinancedDevice = (bundleType: BundleType): FinancedDevice => {
  if (bundleType === BundleType.Accessories) {
    throw new Error('Cannot finance accessories');
  }

  switch (bundleType) {
    case BundleType.Bike:
    case BundleType.BikePlus:
    case BundleType.RefurbishedBike:
    case BundleType.RefurbishedBikePlus:
      return FinancedDevice.Bike;
    case BundleType.Tread:
    case BundleType.TreadPlus:
      return FinancedDevice.Tread;
    case BundleType.RainforestCafe:
      return FinancedDevice.RainforestCafe;
    case BundleType.Row:
      return FinancedDevice.Row;
  }
};

export const bundleTypesForFinancedDevice = (financedDevice: FinancedDevice) => {
  switch (financedDevice) {
    case FinancedDevice.Bike:
      return [BundleType.BikePlus, BundleType.Bike];
    case FinancedDevice.Tread:
      return [BundleType.TreadPlus];
    case FinancedDevice.RainforestCafe:
      return [BundleType.RainforestCafe];
    default:
      return undefined;
  }
};

export type Props = {
  bundleType: BundleType;
  financedDevice: FinancedDevice;
  financingPartner: Partners;
  secondaryFinancingPartner: Partners;
  financingPartnerDisplayName: string;
  term: number;
  membershipPrice: number;
  total: number;
  totalBeforeShipping: number;
  monthly: number;
  shippingEstimate: number;
  apr: number;
};
