import { brand, Media } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { useLocale } from '@peloton/next/hooks/useLocale';
import useNav from '@content/client/www/nav/useNav';
import { Link } from '@ecomm/internationalize-ui';
import { hb6, b5 } from '@ecomm/typography';

const StudioLink: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { trackEvent } = useTracking();
  const locale = useLocale();
  const { content, isLoading } = useNav(locale);

  const {
    header,
    body,
    image: { desktop, mobile, tablet },
  } = content.geoMenu?.['navigation.geoMenu']?.['nav.geo.studio'] || {
    header: '',
    body: '',
    image: { mobile: { file: { url: '' } } },
  };

  const cta = content?.geoMenu?.['navigation.geoMenu']?.['nav.geo.studio.cta'] || {
    key: '',
    text: '',
    url: '',
    targetBlank: false,
  };

  const { key, text, url, targetBlank } = cta;

  const mobileUrl = mobile?.file?.url;
  const tabletUrl = tablet?.file?.url || mobileUrl;
  const desktopUrl = desktop?.file?.url || tabletUrl;

  if (isLoading) {
    return null;
  }

  return (
    <StyledLink
      to={url}
      href={url}
      target={targetBlank ? '_blank' : '_self'}
      onClick={() => {
        trackEvent({
          properties: {
            category: 'location',
            unitName: key,
            linkName: text,
            linkTo: url,
          },
        });
      }}
    >
      <ShowroomBlurb>
        <div>
          <StyledImage
            media={{
              alt: text,
              desktop: desktopUrl,
              tablet: tabletUrl,
              mobile: mobileUrl,
              type: 'image',
            }}
          />
        </div>
        <StudioText>
          <h1>{header}</h1>
          <p className="light">{body}</p>
          <p>{text}</p>
        </StudioText>
      </ShowroomBlurb>
    </StyledLink>
  );
};

export default StudioLink;

const ShowroomBlurb = styled.div`
  background-color: ${brand.light};
  height: 160px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
`;

const StudioText = styled.div`
  width: 170px;

  h1 {
    ${hb6}
    margin-bottom: 8px;
  }

  p {
    ${b5}
    text-decoration: underline;
    &.light {
      margin-bottom: 16px;
      text-decoration: none;
    }
  }
`;

const StyledImage = styled(Media)`
  width: 112px;
`;

const StyledLink = styled(Link)`
  display: block;

  span {
    text-transform: none;
  }

  &:after {
    display: none;
  }
`;
