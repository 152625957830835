import { asyncComponent } from '@peloton/code-splitting';
import routes from './routes';

const ValueCalcEntryPoint = asyncComponent(
  () => import(/* webpackChunkName: "ValueCalcEntryPoint" */ './ValueCalcEntryPoint'),
);

export { routes, ValueCalcEntryPoint };

export { default as NAME } from './NAME';
