import React, { useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { SWRConfig } from 'swr';
import { ALLOW_PROMO_PANEL } from '@peloton/app-config';
import { toLocaleFromHostname } from '@peloton/internationalize';
import { LazyBelowFoldProvider, LazyBelowFold } from '@peloton/scroll/LazyBelowFold';
import StructuredFooter from '@acme-ui/footer';
import { GlobalUiStateContext } from '@acme-ui/global';
import { SkipLinkToMain } from '@acme-ui/global/skip-links';
import Nav from '@acme-ui/nav/Nav';
import { useCRAGeoModalCopy } from '@content/client/craGeoModalCopy';
import { toFetcher } from '@content/client/craGlobalEntriesFetcher';
import { useCRAMicroCopy } from '@content/client/craMicroCopy';
import NewCartPanel from '@ecomm/cart-next/panel/AsyncPanel';
import { PromoProvider } from '@ecomm/cms-promos';
import DateTriggerPanel from '@ecomm/cms-promos/DateTriggerPanel';
import PromoPanel from '@ecomm/cms-promos/PromoPanel';
import { useIsToggleActive } from '@ecomm/feature-toggle';
import AddZipScriptToPage from '@ecomm/financing/zip/AddZipScriptToPage';
import LegalSection from '@ecomm/footer/content/LegalSection';
import { SwitchHeadband } from '@ecomm/header/Headband';
import Banner from '@ecomm/layout/Banner';
import { useAllOtdEstimatesForMarkdown } from '@ecomm/otd/useOtdEstimates';
import { PersonalizedNotification } from '@ecomm/personalized-notification';
import { PostalCodeProvider } from '@ecomm/postal-code';
import { productPrices as prices } from '@ecomm/pricing/static';
import Container from './PageContainer';
import withGlobalReferences from './withGlobalReferences';

export type Props = React.HTMLAttributes<HTMLDivElement> & {
  banner?: JSX.Element;
  transparent?: boolean;
  hideBanner?: boolean;
  isPageOptimizedForLazyLoadingContentBelowTheFold?: boolean;
};

const GlobalModalStyles = createGlobalStyle<{ hidden: string }>`
body {
  overflow-y: ${props => props.hidden};
}
`;

const Page: React.FC<React.PropsWithChildren<Props>> = ({
  banner,
  className,
  children,
  transparent = false,
  hideBanner = false,
  isPageOptimizedForLazyLoadingContentBelowTheFold = false,
}) => {
  const preview = useIsToggleActive()('previewCopy');
  const otdEstimates = useAllOtdEstimatesForMarkdown();
  const { freeze } = useContext(GlobalUiStateContext);
  const hidden = freeze ? 'hidden' : 'auto';
  const locale = toLocaleFromHostname();
  const geoModalCopy = useCRAGeoModalCopy();
  const microCopy = useCRAMicroCopy();

  return (
    <SWRConfig
      value={{
        fetcher: toFetcher(preview),
        fallback: {
          microCopy,
          prices,
          otdEstimates,
          geoModalCopy,
        },
      }}
    >
      <PromoProvider>
        <PostalCodeProvider>
          <LazyBelowFoldProvider
            hasBelowFold={isPageOptimizedForLazyLoadingContentBelowTheFold}
          >
            <AddZipScriptToPage />
            <GlobalModalStyles hidden={hidden} />
            {!hideBanner && (banner || <Banner />)}
            <PersonalizedNotification key={window.location.pathname} />
            <SkipLinkToMain />
            <Nav transparent={transparent} Headband={SwitchHeadband} />
            <NewCartPanel />
            <Container className={className} data-test-id="full-height-flex">
              <ContentContainer>{children}</ContentContainer>
            </Container>
            <LazyBelowFold>
              <LegalSection />
              <StructuredFooter locale={locale} />
            </LazyBelowFold>
          </LazyBelowFoldProvider>
        </PostalCodeProvider>
        {ALLOW_PROMO_PANEL ? (
          <>
            <PromoPanel currentLocale={locale} />
            <DateTriggerPanel />
          </>
        ) : null}
      </PromoProvider>
    </SWRConfig>
  );
};

export default withGlobalReferences(Page);

export const ContentContainer = styled.div`
  flex-grow: 1;
`;
