import { all, curry, filter, isNil, map, merge, none, pipe, prop, values } from 'ramda';
import type { ID } from '@ecomm/models';
import type { Bundle } from './Bundle';
import type { Entities, WithCount } from './Normalized';
import { toEntitiesWithCount } from './Normalized';
import type { Product } from './Product';
import { isConfigurable } from './Product';

export type ProductConfiguration = {
  id?: ID;
  count: number;
  traits: ID[];
};

export type BundleConfiguration = Entities<ProductConfiguration>;

const toConfiguration = curry((products: Entities<Product>, bundle: Bundle) =>
  pipe<Bundle, ID[], ID[], Entities<WithCount>, BundleConfiguration>(
    prop('products'),
    filter((id: ID) => isConfigurable(products[id])),
    toEntitiesWithCount,
    map<Entities<WithCount>, Entities<ProductConfiguration>>(merge({ traits: [] })),
  )(bundle),
);

export const toProductConfiguration = (products: Entities<Product>) =>
  pipe<Entities<Product>, Entities<Product>, Entities<ProductConfiguration>>(
    filter(isConfigurable),
    map<Entities<Product>, Entities<ProductConfiguration>>((p: Product) => ({
      count: 1,
      traits: [],
    })),
  )(products);

export const toConfigurations = (
  bundles: Entities<Bundle>,
  products: Entities<Product>,
) =>
  map<Entities<Bundle>, Entities<BundleConfiguration>>(toConfiguration(products))(
    bundles,
  );

export const isProductConfigured = ({ count, traits }: ProductConfiguration): boolean =>
  traits.length === count && none(isNil, traits);

export const isBundleConfigured = pipe<
  BundleConfiguration,
  ProductConfiguration[],
  boolean
>(values, all(isProductConfigured));
