import { useApolloClient } from '@apollo/client';
import useSWR from 'swr';
import { toCountry } from '@peloton/internationalize';
import { toFetcher } from '@content/client/craGlobalEntriesFetcher';
import cartOtd from '@content/client/www/otd/cartOtd';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import * as Types from '@ecomm/graphql/types.generated';
import { toFormattedDateString, toLocalizedDate } from './models';
import type { DynamicOtdEstimate, CalculationStatus } from './models';
import type {
  OtdEstimateQuery,
  OtdEstimateQueryVariables,
} from './OtdEstimateQuery.generated';
import { OtdEstimateDocument } from './OtdEstimateQuery.generated';

type EquipmentType = Types.EquipmentType;
type Sku = Types.Scalars['Sku'];
type OtdEstimate = string;

/**
 * Hook for retrieving static delivery estimates (country estimates) for a given CFU
 * Note: if you're looking for dynamic OTDs, see the useToDynamicOtdEstimate hook
 * @param equipmentType An expanded EquipmentType value to properly account for "legacy" bundle types
 * @returns static OTD copy
 */
export const useOtdEstimate = (
  equipmentType:
    | 'bike'
    | 'bikePlus'
    | 'guide'
    | 'tread'
    | 'treadPlus'
    | 'row'
    | 'refurbBike'
    | 'refurbBikePlus'
    | EquipmentType,
): OtdEstimate => {
  const map: Record<string, any> = {
    [Types.EquipmentType.Bike]: 'otd_bike_estimates',
    bike: 'otd_bike_estimates',
    [Types.EquipmentType.Bikeplus]: 'otd_bike_plus_estimates',
    bikePlus: 'otd_bike_plus_estimates',
    [Types.EquipmentType.Guide]: 'otd_guide_estimates',
    guide: 'otd_guide_estimates',
    [Types.EquipmentType.Tread]: 'otd_tread_estimates',
    tread: 'otd_tread_estimates',
    [Types.EquipmentType.Treadplus]: 'otd_tread_plus_estimates',
    treadPlus: 'otd_tread_plus_estimates',
    [Types.EquipmentType.Row]: 'otd_row_estimates',
    row: 'otd_row_estimates',
    [Types.EquipmentType.Refurbbike]: 'otd_bike_estimates',
    refurbBike: 'otd_bike_estimates',
    [Types.EquipmentType.Refurbbikeplus]: 'otd_bike_plus_estimates',
    refurbBikePlus: 'otd_bike_plus_estimates',
  };

  const promotionalTextKey = map[equipmentType];

  const previewCopy = useIsToggleActive()('previewCopy');
  // Using the CRA toFetcher override because it's called outside of the SWR Config in ecomm/layout
  const { data } = useSWR(cartOtd.entryId, toFetcher(previewCopy));
  return data?.[promotionalTextKey]?.text ?? '';
};

/**
 * Hook for retrieving static delivery estimates (country estimates) across all CFUs
 * @returns Copy for all CFUs
 */
export const useAllOtdEstimates = (): Record<
  | 'bike'
  | 'bikePlus'
  | 'guide'
  | 'tread'
  | 'treadPlus'
  | 'row'
  | 'refurbBike'
  | 'refurbBikePlus'
  | EquipmentType,
  OtdEstimate
> => {
  const bikeEstimate = useOtdEstimate('bike');
  const bikePlusEstimate = useOtdEstimate('bikePlus');
  const guideEstimate = useOtdEstimate('guide');
  const treadEstimate = useOtdEstimate('tread');
  const treadPlusEstimate = useOtdEstimate('treadPlus');
  const rowEstimate = useOtdEstimate('row');

  return {
    bike: bikeEstimate,
    [Types.EquipmentType.Bike]: bikeEstimate,
    bikePlus: bikePlusEstimate,
    [Types.EquipmentType.Bikeplus]: bikePlusEstimate,
    guide: guideEstimate,
    [Types.EquipmentType.Guide]: guideEstimate,
    tread: treadEstimate,
    [Types.EquipmentType.Tread]: treadEstimate,
    treadPlus: treadPlusEstimate,
    [Types.EquipmentType.Treadplus]: treadPlusEstimate,
    row: rowEstimate,
    [Types.EquipmentType.Row]: rowEstimate,
    refurbBike: bikeEstimate,
    [Types.EquipmentType.Refurbbike]: bikeEstimate,
    refurbBikePlus: bikePlusEstimate,
    [Types.EquipmentType.Refurbbikeplus]: bikePlusEstimate,
  };
};

/**
 * Hook for retrieving static delivery estimates (country estimates) across all CFUs for markdown values
 * @returns Markdown ready copy for all CFUs
 */
export const useAllOtdEstimatesForMarkdown = () => {
  const bikeEstimate = useOtdEstimate('bike');
  const bikePlusEstimate = useOtdEstimate('bikePlus');
  const guideEstimate = useOtdEstimate('guide');
  const treadEstimate = useOtdEstimate('tread');
  const treadPlusEstimate = useOtdEstimate('treadPlus');
  const rowEstimate = useOtdEstimate('row');

  return {
    bikeEstimate,
    bikePlusEstimate,
    guideEstimate,
    treadEstimate,
    treadPlusEstimate,
    rowEstimate,
  };
};

type DynamicOtdCallback = (params: {
  cfuType: EquipmentType;
  postalCode: string;
  cfuSku?: Sku;
}) => Promise<{
  minDate?: Date;
  maxDate?: Date;
  estimate?: DynamicOtdEstimate;
  calculationStatus: CalculationStatus;
}>;

/**
 * Hook to retrieve dynamic OTD estimates for a given CFU and postal code
 * Applies the Apollo client to generate a query helper
 * @returns An asynchronous callback to request dynamic OTDs
 */
export const useToDynamicOtdEstimate = (): DynamicOtdCallback => {
  const apolloClient = useApolloClient();

  /**
   * Callback to request the delivery estimate for a given CFU and postal code
   * @param params A combination of CFU type and postal code to retrieve a dynamic OTD
   * @returns A formatted estimate and date range
   */
  const dynamicOtdCallback: DynamicOtdCallback = async ({
    cfuType,
    postalCode,
    cfuSku,
  }) => {
    const {
      data: {
        orderToDeliveryTime: { min, max, calculationStatus },
      },
    } = await apolloClient.query<OtdEstimateQuery, OtdEstimateQueryVariables>({
      query: OtdEstimateDocument,
      variables: {
        country: toCountry().toUpperCase(),
        postalCode,
        cfuType: cfuType.toUpperCase(),
        cfuSku,
      },
    });

    return {
      calculationStatus: calculationStatus as CalculationStatus,
      minDate: min ? toLocalizedDate(min) : undefined,
      maxDate: max ? toLocalizedDate(max) : undefined,
      estimate:
        min && max
          ? {
              startDate: toFormattedDateString(min),
              endDate: toFormattedDateString(max),
            }
          : undefined,
    };
  };

  return dynamicOtdCallback;
};
