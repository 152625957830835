import { useCallback, useEffect, useRef } from 'react';

const useInterval = (callback: Function, delay?: number) => {
  const savedCallback = useRef(callback);
  const intervalId = useRef<ReturnType<typeof setInterval> | undefined>();

  useEffect(() => {
    // If there's no delay value dont create the interval
    if (delay && !intervalId.current) {
      createInterval();
    }
    return () => intervalId.current && clearInterval(intervalId.current);
  }, [delay]);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const createInterval = useCallback(() => {
    const tick = () => {
      savedCallback && savedCallback.current();
    };
    const id = setInterval(tick, delay);
    intervalId.current = id;
  }, [delay]);

  const resetInterval = () => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
      createInterval();
    }
  };

  return { resetInterval };
};

export default useInterval;
