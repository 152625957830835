import type { Reducer } from 'redux';

export enum ActionType {
  Open = 'pelo/setNewUserPasswordModal/OPEN',
  Close = 'pelo/setNewUserPasswordModal/CLOSE',
}

export type State = {
  isOpen: boolean;
};

export const defaultState: State = {
  isOpen: false,
};

export const reducer: Reducer<State> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.Open:
      return { isOpen: true };
    case ActionType.Close:
      return { isOpen: false };
    default:
      return state;
  }
};

export default reducer;

// Actions
export type Open = {
  type: ActionType.Open;
};

export type Close = {
  type: ActionType.Close;
};

type Action = Open | Close;

// Action Creators
export const openModal = (): Open => ({
  type: ActionType.Open,
});

export const closeModal = (): Close => ({
  type: ActionType.Close,
});

// Selectors
export type ModalState = {
  newUserPasswordModal: State;
};

export const getIsOpen = (state: ModalState) => state.newUserPasswordModal.isOpen;
