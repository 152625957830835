import type {
  TypeComponentLegalText,
  TypeComponentLegalTextFields,
  TypePromo,
} from '@pelotoncycle/page-builder';
import type { Entry } from 'contentful';
import dayjs from 'dayjs';
import useSWR from 'swr';
import { ALLOW_PREVIEW_COPY } from '@peloton/app-config';
import type { Locale } from '@peloton/internationalize/models/locale';
import { toLocale } from '@peloton/internationalize/models/locale';
import { getClient } from '@content/client/peloContentClient';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { isActiveDate } from '@page-builder/modules/Variation/DateTriggerBlock';
import fetchBuilderData from './lib/fetchBuilderData';
import { IMMUTABLE } from './revalidation';

type DriftCta = {
  key?: string;
  text: string;
  interactionId: number;
};

type PeloContentLink = {
  key: string;
  url: string;
};

export type BannerLink = {
  key: string;
  text: string;
  link?: Entry<PeloContentLink>;
  targetBlank?: boolean;
  ariaLabel?: string;
  driftCta?: Entry<DriftCta>;
};

export type PeloContentBanner = {
  key: string;
  text: string;
  links: Entry<BannerLink>[];
  theme?: 'red' | 'white';
};

type PeloContentPromo = {
  key: string;
  validLocales: Locale[];
  start: string;
  end: string;
  generalBanner: Entry<PeloContentBanner>;
  legalText: TypeComponentLegalText;
};

export type PromoResponse = {
  name: string;
  banner: PeloContentBanner;
  legalText: TypeComponentLegalTextFields;
};

const DEFAULT_PROMO_ENTRY_ID = '3Jr3t9GzY3fys2cPwtEECd';

/**
 * Get the default promo from peloContent
 * @param preview Allow draft/changed content
 * @returns PromoResponse
 */
export const getDefaultPromo = async (preview: boolean = ALLOW_PREVIEW_COPY) => {
  const client = getClient(preview);
  const locale = toLocale();
  const promo = await client.getEntry<PeloContentPromo>(DEFAULT_PROMO_ENTRY_ID, {
    include: 10,
    locale,
  });

  return toPromoResponseObject(promo.fields);
};

/**
 * Get the active promo banner and legal text from peloContent
 * @param preview Allow draft/changed content
 * @returns PromoResponse
 */
export const getActivePromo = async (preview: boolean = ALLOW_PREVIEW_COPY) => {
  try {
    const locale = toLocale();
    const promos = await getPromos(Boolean(preview), locale);
    const activePromo = promos.find(promo => promoIsApplicable(promo, locale));
    if (activePromo) {
      return toPromoResponseObject(activePromo);
    }

    return await getDefaultPromo(preview);
  } catch (e) {
    throw new Error(`Error fetching promo data from peloContent ${e}`);
  }
};

export const toPromoFetcher = (preview: boolean = ALLOW_PREVIEW_COPY) => async () =>
  getActivePromo(preview);

/**
 * Get all promos from peloContent
 * @param preview Allow draft/changed content
 * @param locale Locale
 * @returns Entry<PeloContentPromo>[]
 */
export const getPromos = async (preview: boolean, locale: Locale) => {
  const client = getClient(preview);
  const { items } = await client.getEntries<PeloContentPromo>({
    content_type: 'promo',
    limit: 500,
    include: 10,
    locale,
  });

  return items.map(item => item.fields);
};

/**
 * Trim down raw promo data into smaller object with banner and legal text.
 * @param data PeloContentPromo
 * @returns PromoResponse
 */
export const toPromoResponseObject = (data: PeloContentPromo): PromoResponse => {
  const { key, generalBanner, legalText } = data;
  return {
    name: key,
    banner: generalBanner.fields,
    legalText: legalText.fields,
  };
};

export const createDateSlug = (today?: Date) => {
  if (!today) return undefined;
  const date = new Date(today);
  return dayjs(date).startOf('minute').toISOString();
};

export const promoValidNow = (start: string, end?: string): boolean => {
  const today = new Date();
  const hasStarted = new Date(start).valueOf() < today.valueOf();
  const hasEnded = end && new Date(end).valueOf() < today.valueOf();

  return hasStarted && !hasEnded;
};

export const promoIsApplicable = (
  { start, end, validLocales }: PeloContentPromo,
  currentLocale: Locale,
) => {
  if (!start) return false;

  const promoValidInCurrentLocale = validLocales?.includes(currentLocale);

  if (!promoValidInCurrentLocale) return false;

  return promoValidNow(start, end);
};

export const usePageBuilderPromo = () => {
  const previewCopy = useIsToggleActive()('previewCopy');
  const fetcher = toPromoFetcher(previewCopy);
  return useSWR<PromoResponse>('promo', fetcher, {
    ...IMMUTABLE,
    revalidateOnMount: false,
  });
};

export const findActivePromo = (promos: TypePromo[], todayOverride?: Date) => {
  const activePromo = promos
    .sort(
      (a, b) =>
        new Date(b.fields.dateTrigger.fields.start).valueOf() -
        new Date(a.fields.dateTrigger.fields.start).valueOf(),
    )
    .find(promo => {
      const { start, end } = promo.fields.dateTrigger.fields;
      return isActiveDate({ start, end, today: todayOverride });
    });

  return activePromo ?? null;
};

export const getActiveBundlePromo = (
  promos: TypePromo[],
  bundleSlug: string,
  todayOverride?: Date,
) => {
  const activePromo = findActivePromo(promos, todayOverride);

  const activeBundlePromo =
    activePromo?.fields.bundles.find(bundle => bundle.fields.slug === bundleSlug) ?? null;

  return activeBundlePromo;
};

export const getActivePromosInDateRange = (promos: TypePromo[], todayOverride?: Date) => {
  return promos.filter(item => {
    const { start, end } = item.fields.dateTrigger.fields;
    return isActiveDate({ start, end, today: todayOverride });
  });
};

// On-Demand Builder request
export const fetchPromos = async (locale: string) =>
  fetchBuilderData<TypePromo[]>(`/pageBuilderAllPromosHandler/${locale}`);

// On-Demand Builder request
export const fetchCFUPromos = async (locale: string, cfu: string) =>
  fetchBuilderData<TypePromo[]>(`/pageBuilderAllPromosCfuHandler/${locale}/${cfu}`);
