import { push, LOCATION_CHANGE } from 'connected-react-router';
import { pathOr } from 'ramda';
import { put, delay, select, takeLatest, all } from 'redux-saga/effects';
import { isCheckoutBillingInfoComplete } from '../models/CheckoutBillingInfo';
import { matchCheckoutReviewRoute, selectCheckoutBilling } from '../redux';

export const checkRouteSaga = function* () {
  const [matchRoute, billingInfo]: [
    ReturnType<typeof matchCheckoutReviewRoute>,
    ReturnType<typeof selectCheckoutBilling>,
  ] = yield all([select(matchCheckoutReviewRoute), select(selectCheckoutBilling)]);

  if (matchRoute) {
    const billingComplete = isCheckoutBillingInfoComplete(billingInfo);
    const hasKlarnaToken = pathOr('', ['token', 'kind'], billingInfo) === 'klarna';
    if (!billingComplete && !hasKlarnaToken) {
      yield delay(100);
      yield put(push('/checkout'));
    }
  }
};

const watcherSaga = function* () {
  yield takeLatest(LOCATION_CHANGE, checkRouteSaga);
};

export default watcherSaga;
