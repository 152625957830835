import { pathOr, prop, propEq } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getUserEmail } from '@peloton/auth';
import { trackWithEloqua } from '@ecomm/analytics';
import { getRegistrationField } from '@ecomm/registration/redux';
import { toCheckRouteSaga } from '@ecomm/saga-utils';
import { toTrackingData } from '../../models';
import type { RegisterRequestAction, RequestAction } from '../../redux';
import { ActionTypes, submittedEmailSuccess } from '../../redux';
import { matchRegisterFreeTrial as matcher } from '../../route';

export const trackSubmittedEmail = function* (action: Action): SagaIterator {
  const properties = yield call(trackingData, action);
  yield call(trackWithEloqua, { event: properties.eventName, properties });
  yield put(submittedEmailSuccess());
};

export const trackingData = function* (action: Action): SagaIterator {
  const match = yield select(matcher);
  const productInterest = pathOr('digital', ['params', 'productInterest'], match);
  const pageName = prop('url', match);

  const email = isRegistrationAction(action)
    ? yield select(getRegistrationEmail)
    : yield select(getUserEmail);

  const allowMarketing = yield select(getAllowMarketing);

  return toTrackingData({
    allowMarketing,
    email,
    event: 'submit',
    pageName,
    productInterest,
  });
};

export const checkRouteAndTrackSubmittedEmail = toCheckRouteSaga({
  matcher,
  onMatch: trackSubmittedEmail,
});

const watcher = function* () {
  yield takeEvery(
    [ActionTypes.FreeTrialRequested, ActionTypes.RegisterAndFreeTrialRequested],
    checkRouteAndTrackSubmittedEmail,
  );
};

export default watcher;

type Action = RegisterRequestAction | RequestAction;

const isRegistrationAction = propEq('type', ActionTypes.RegisterAndFreeTrialRequested);

export const getRegistrationEmail = (state: Parameters<typeof getRegistrationField>[0]) =>
  getRegistrationField(state, { name: 'email' });

export const getAllowMarketing = (state: Parameters<typeof getRegistrationField>[0]) =>
  getRegistrationField(state, { name: 'allowMarketing' });
