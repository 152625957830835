import { combineReducers } from 'redux';
import type { State as ClassesState } from './classes';
import classes from './classes';
import type { State as InstructorsState } from './instructors';
import instructors from './instructors';

export type State = {
  classes: ClassesState;
  instructors: InstructorsState;
};

export type ReducerState = {
  instructorsPage: State;
};

export default combineReducers({
  classes,
  instructors,
});
