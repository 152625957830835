import type { ReducerState as SubscriptionPlansState } from '@peloton/subscription-plans';
import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { UIState } from '@ecomm/models';
import { Status, toUIState } from '@ecomm/models';

export enum ActionTypes {
  PlansFetchRequested = 'ecomm/pg-digital-checkout/ui/SUBSCRIPTION_PLANS_REQUESTED',
  PlansFetchSucceeded = 'ecomm/pg-digital-checkout/ui/SUBSCRIPTION_PLANS_SUCCEEDED',
  PlansFetchFailed = 'ecomm/pg-digital-checkout/ui/SUBSCRIPTION_PLANS_FAILED',
  SetSuccessfulPaymentWithSSO = 'ecomm/pg-digital-checkout/ui/SET_SUCCESSFUL_PAYMENT_WITH_SSO',
}

export type State = UIState & {
  socialSignUpCheckout?: {
    receiptUrl: string;
    isPaymentSuccessful: boolean;
    id: string;
  };
};

export const defaultState = toUIState(Status.Init);

const reducer = (state: State = defaultState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.PlansFetchRequested:
      return { ...state, ...toUIState(Status.Loading) };

    case ActionTypes.PlansFetchSucceeded:
      return { ...state, ...toUIState(Status.Loaded) };

    case ActionTypes.PlansFetchFailed: {
      const { exception } = action.payload;
      return { ...state, ...toUIState(Status.Failed, exception) };
    }

    case ActionTypes.SetSuccessfulPaymentWithSSO: {
      return {
        ...state,
        ...toUIState(Status.Loaded),
        socialSignUpCheckout: {
          receiptUrl: action.payload.receiptUrl,
          id: action.payload.id,
          isPaymentSuccessful: true,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  pgDigitalCheckout: State;
} & SubscriptionPlansState;

export const requestedPlansFetch = (): PlansRequestAction => ({
  type: ActionTypes.PlansFetchRequested,
});

export const succeedPlansFetch = (): PlansSuccessAction => ({
  type: ActionTypes.PlansFetchSucceeded,
});

export const failPlansFetch = (
  errorId: string = 'pgDigitalCheckout.errors.plansFetch',
): PlansFailedAction => ({
  type: ActionTypes.PlansFetchFailed,
  payload: { exception: toException(errorId) },
});

export const setSuccessfulPaymentWithSSO = (
  receiptUrl: string,
  id: string,
): setSuccessfulPaymentWithSSOAction => ({
  type: ActionTypes.SetSuccessfulPaymentWithSSO,
  payload: { receiptUrl, id },
});

type PlansRequestAction = { type: ActionTypes.PlansFetchRequested };
type PlansSuccessAction = { type: ActionTypes.PlansFetchSucceeded };
type PlansFailedAction = {
  type: ActionTypes.PlansFetchFailed;
  payload: { exception: Exception };
};

type setSuccessfulPaymentWithSSOAction = {
  type: ActionTypes.SetSuccessfulPaymentWithSSO;
  payload: { receiptUrl: string; id: string };
};

type Actions =
  | PlansRequestAction
  | PlansSuccessAction
  | PlansFailedAction
  | setSuccessfulPaymentWithSSOAction;
