// THIS IS AN AUTOGENERATED FILE (@ecomm/graphql/codegen.yml). DO NOT EDIT THIS FILE DIRECTLY.
/* eslint-disable */
import * as Types from '../graphql/types.generated';

import { DocumentNode } from 'graphql';
import * as ApolloReactCommon from '@ecomm/graphql/hooks';
import * as ApolloReactHooks from '@ecomm/graphql/hooks';
export type OtdEstimateQueryVariables = Types.Exact<{
  postalCode: Types.Scalars['String'];
  country: Types.Scalars['String'];
  cfuType: Types.Scalars['String'];
  cfuSku?: Types.Maybe<Types.Scalars['String']>;
}>;

export type OtdEstimateQuery = { __typename?: 'Query' } & {
  orderToDeliveryTime: { __typename?: 'OrderToDeliveryTime' } & Pick<
    Types.OrderToDeliveryTime,
    'calculationStatus' | 'min' | 'max'
  >;
};

export const OtdEstimateDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OtdEstimate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'postalCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cfuType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cfuSku' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderToDeliveryTime' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'postalCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'postalCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cfuType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cfuType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cfuSku' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cfuSku' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calculationStatus' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'min' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'max' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useOtdEstimateQuery__
 *
 * To run a query within a React component, call `useOtdEstimateQuery` and pass it any options that fit your needs.
 * When your component renders, `useOtdEstimateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOtdEstimateQuery({
 *   variables: {
 *      postalCode: // value for 'postalCode'
 *      country: // value for 'country'
 *      cfuType: // value for 'cfuType'
 *      cfuSku: // value for 'cfuSku'
 *   },
 * });
 */
export function useOtdEstimateQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OtdEstimateQuery,
    OtdEstimateQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<OtdEstimateQuery, OtdEstimateQueryVariables>(
    OtdEstimateDocument,
    baseOptions,
  );
}
export function useOtdEstimateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OtdEstimateQuery,
    OtdEstimateQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<OtdEstimateQuery, OtdEstimateQueryVariables>(
    OtdEstimateDocument,
    baseOptions,
  );
}
export type OtdEstimateQueryHookResult = ReturnType<typeof useOtdEstimateQuery>;
export type OtdEstimateLazyQueryHookResult = ReturnType<typeof useOtdEstimateLazyQuery>;
export type OtdEstimateQueryResult = ApolloReactCommon.QueryResult<
  OtdEstimateQuery,
  OtdEstimateQueryVariables
>;
