import type { Money } from '@ecomm/models';
import { toDollars } from '@ecomm/models';
import { roundToDollarWithCents, toDollarsCeil } from '@ecomm/models/Money';
import { Partners } from './Partners';

export const roundNumberByFinancingPartner = (
  price: Money,
  term: number,
  financingPartner: Partners,
): Money => {
  return financingPartner === Partners.Zip
    ? Math.round(price / term)
    : Math.ceil(price / term);
};

/**
 *
 * @param total total loan value in cents
 * @param term term of loan in months
 * @param apr yearly decimal, for example 15% is 0.15
 * @returns monthly payment value in cents, 123.45$ is 12345
 */

export const monthlyPaymentWithApr = (
  total: Money,
  term: number,
  apr: number,
  financingPartner: Partners,
): Money => {
  if (apr === 0) {
    return roundNumberByFinancingPartner(total, term, financingPartner);
  }
  return financingPartner === Partners.Klarna
    ? monthlyPaymentWithCompoundApr(total, term, apr)
    : monthlyPaymentWithSimpleApr(total, term, apr);
};

/**
 *
 * @param total total sum in cents
 * @param term term of loan in months
 * @param apr yearly decimal, for example 15% is 0.15
 * @returns rounded up value in cents, 123.45$ -> 124$ is 12400
 */
export const monthlyPaymentRoundedWithApr = (
  total: Money,
  term: number,
  apr: number,
  financingPartner: Partners,
): Money => {
  return roundUpPrice(monthlyPaymentWithApr(total, term, apr, financingPartner));
};

/**
 *
 * @param price (in cents)
 * @returns rounded up value: if it has no cents, the same value is returned, otherwise the rounded up value (even if it's just 1 cent)
 *          NOTE: Negative 1 cent should return zero, but it returns negative zero instead (-0) Weird
 */
export const roundUpPrice = (price: Money): Money => {
  const monthlyPriceDollars = price / 100;
  const monthlyPriceDollarsRoundedUp = Math.ceil(monthlyPriceDollars);
  return monthlyPriceDollarsRoundedUp * 100;
};

const calculateMonthlyPayment = (
  total: Money,
  term: number,
  monthlyApr: number,
): Money => {
  const compound = Math.pow(1 + monthlyApr, term);
  return (total * monthlyApr * compound) / (compound - 1);
};

const monthlyPaymentWithSimpleApr = (total: Money, term: number, apr: number): Money => {
  const monthlyInterest = apr / 12;
  const monthly = calculateMonthlyPayment(total, term, monthlyInterest);
  return Math.ceil(Math.round(monthly));
};

const monthlyPaymentWithCompoundApr = (
  total: Money,
  term: number,
  apr: number,
): Money => {
  const monthlyInterestCompound = Math.pow(1 + apr, 1 / 12) - 1;
  const monthly = calculateMonthlyPayment(total, term, monthlyInterestCompound);
  return Math.ceil(monthly);
};

export const roundByFinancingPartner = (
  price: Money,
  term: number,
  apr: number,
  financingPartner: Partners,
): Money => {
  const monthlyPayment = monthlyPaymentRoundedWithApr(price, term, apr, financingPartner);

  const monthlyPaymentWithCents = monthlyPaymentWithApr(
    price,
    term,
    apr,
    financingPartner,
  );

  return financingPartner === Partners.Zip ? monthlyPaymentWithCents : monthlyPayment;
};

export const roundMonthlyPaymentByFinancingPartner = (
  price: Money,
  term: number,
  apr: number,
  financingPartner: Partners,
): Money => {
  const roundedPayment = roundByFinancingPartner(price, term, apr, financingPartner);

  const monthlyPayment = toDollars(roundedPayment);

  const monthlyPaymentWithCents = roundToDollarWithCents(roundedPayment);

  return financingPartner === Partners.Zip ? monthlyPaymentWithCents : monthlyPayment;
};

export const roundDollarsByFinancingPartner = (
  price: Money,
  financingPartner: Partners,
): Money => {
  const monthlyPaymentCeil = toDollarsCeil(price);

  const monthlyPaymentCents = roundToDollarWithCents(price);

  return financingPartner === Partners.Zip ? monthlyPaymentCents : monthlyPaymentCeil;
};
