import {
  BreakpointWidths,
  Button,
  primaryFamily,
  secondaryFamily,
  grey,
} from '@pelotoncycle/design-system';
import React, { useCallback, useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { media } from '@peloton/styles/breakpoints';
import InputWithLabelError from '@ecomm/forms/InputWithLabelError';

type Props = {
  className?: string;
  ctaText: string;
  failureMessage: string;
  inputId: string;
  placeholder: string;
  successMessage: string;
};

const EmailCapture: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  ctaText,
  failureMessage,
  inputId,
  placeholder,
  successMessage,
}) => {
  const [email, setEmail] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

  const onChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };
  const { trackEvent } = useTracking();

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setIsSubmitting(true);
      trackEvent({
        properties: {
          email,
        },
        callback: () => {
          setIsSubmitSuccessful(true);
          setIsSubmitting(false);
        },
      });
    },
    [email],
  );

  return (
    <form onSubmit={onSubmit} className={className}>
      <InputContainer>
        <EmailInput
          type="email"
          name="email"
          autoComplete="email"
          customErrorMessage={failureMessage}
          disabled={isSubmitting || isSubmitSuccessful}
          id={inputId}
          isActive={isActive}
          label={placeholder}
          onChange={onChange}
          onBlur={() => setIsActive(!!email)}
          onFocus={() => setIsActive(true)}
          placeholder={placeholder}
          pattern={EMAIL_REGEX}
          required
          style={{ minHeight: '50px' }}
        />
        <ButtonContainer>
          <StyledButton
            text={ctaText}
            isDisabled={isSubmitting || isSubmitSuccessful}
            isLoading={isSubmitting}
            color="dark"
            style={{
              borderRadius: '3px',
              height: '50px',
              width: '100%',
            }}
            type="submit"
            width="adjustable"
          />
        </ButtonContainer>
      </InputContainer>
      <SuccessMessage role="region" aria-live="polite" tabIndex={-1}>
        {isSubmitSuccessful && successMessage}
      </SuccessMessage>
    </form>
  );
};

export default EmailCapture;

const EMAIL_REGEX = '^.+@.+\\..{2,}$';

const EmailInput = styled(InputWithLabelError)`
  width: 100%;

  input:required:not(:disabled):not(:focus):not([data-user-focused='true']):not([data-has-error='true']) {
    border-color: ${grey[60]};
  }

  ${media.smallScreen`
    max-width: 280px;

    input {
      margin-bottom: 10px;
    }
  `}

  @media (min-width: ${BreakpointWidths.tablet}px) {
    overflow: initial;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 480px;
  text-align: left;

  ${media.smallScreen`
    flex-direction: row;
    margin: 0;
  `}
`;

const SuccessMessage = styled.span`
  color: inherit;
  display: block;
  font-family: ${primaryFamily};
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.025em;
  line-height: 1.5em;
  margin-top: 6px;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  font-family: ${secondaryFamily};
  line-height: 1.375em;
  font-size: 14px;
  margin-top: 10px;

  ${media.smallScreen`
    margin-top: 0;
    margin-left: 10px;
    margin-bottom: 0;
  `}
`;

const StyledButton = styled(Button)`
  margin-bottom: 10px;
  span {
    color: inherit;
  }
  &[data-focus-method='keyboard']:focus {
    border: 3px solid black;
    outline: 3px solid #b8ddf5;
    outline-offset: -1px;
  }
  ${media.smallScreen`
      padding: 0 10px;
      max-width: 105px;
  `}
`;
