import type { OperationVariables } from 'react-apollo';
import { useApq } from '@peloton/auth/config';
import { useLocale } from '@peloton/internationalize';
import { toStoreSlug } from '@peloton/stores/Store';
import toCurrencyfromLocale from '@ecomm/graphql-bridge/Currency/fromLocale';
import fromLocale from '@ecomm/graphql-bridge/Locale/fromLocale';
import type { QueryHookOptions } from '@ecomm/graphql/hooks';
import type { Currency, Locale } from '@ecomm/graphql/types.generated';

type CatalogQueryVariables = {
  locale: Locale;
  currency: Currency;
  catalog: string;
};

const useCatalogQueryOptions = <TData = any, TVariables = OperationVariables>(
  options: QueryHookOptions<TData, TVariables & Partial<CatalogQueryVariables>> = {},
): QueryHookOptions<TData, TVariables & CatalogQueryVariables> => {
  const baseLocale = useLocale();
  const locale = fromLocale(baseLocale);
  const currency = toCurrencyfromLocale(baseLocale);
  const catalog = toStoreSlug();

  return {
    useApq: useApq(),
    ...options,
    variables: {
      locale,
      currency,
      catalog,
      ...options?.variables,
      // TODO: figure out how to remove this typecast
    } as TVariables & CatalogQueryVariables,
  };
};

export default useCatalogQueryOptions;
