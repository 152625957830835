import React from 'react';
import { compose } from 'recompose';
import { Markdown } from '@peloton/copy';
import { withExtHrefs } from '@peloton/external-links';
import { Links } from '@peloton/links';
import type { Section } from '@ecomm/copy';
import { useSectionData } from '@ecomm/copy';
import type { EmailCaptureProps } from '@ecomm/email-capture';
import { withEmailCaptureData } from '@ecomm/email-capture';
import { FormattedMarkdown } from '@ecomm/formatted-markdown';
import type { ThemeProps } from '../themes';
import {
  CaptureContainer,
  Container,
  ContainerInner,
  CopyContainer,
  PrivacyNotice,
  StyledEmailCapture,
} from './viewCenteredStyles';

type CmsProps = {
  buttonText: string;
  cmsSectionId: Section;
  eventName: string;
  eventProps: object;
  theme?: ThemeProps;
  dataTestId?: string;
  altPrivacyKey?: string | null;
};

type StateProps = {
  country: string;
  path: string;
};

type Props = StateProps &
  EmailCaptureProps &
  CmsProps & {
    privacyPolicyLink: string;
  };

const CenteredEmailCaptureBanner: React.FC<React.PropsWithChildren<Props>> = ({
  buttonText,
  cmsSectionId,
  altPrivacyKey,
  country,
  dataTestId,
  eventName,
  eventProps,
  path,
  privacyPolicyLink,
  productInterest,
  theme = 'offWhite',
  testingInfo,
  children,
}) => {
  const { title, body } = useSectionData(cmsSectionId);
  const testPrefix = testingInfo
    ? `${testingInfo.page}_${testingInfo.section}`
    : 'emailCapture';
  const headingId = `${testPrefix}_heading`;

  return (
    <Container theme={theme} data-test-id={dataTestId}>
      <ContainerInner>
        <CopyContainer theme={theme}>
          <h2 data-test-id={headingId} id="headingId">
            {title}
          </h2>
          {body && (
            <Markdown
              dataTestId={`${testPrefix}_subheading`}
              content={body}
              markdown={{
                renderers: {
                  paragraph: 'p',
                },
              }}
            />
          )}
        </CopyContainer>
        <CaptureContainer>
          {children ?? (
            <StyledEmailCapture
              buttonText={buttonText}
              eventName={eventName}
              eventProps={{
                country,
                pageName: path,
                productInterest,
                ...eventProps,
              }}
              formLabelId={headingId}
              testingInfo={testingInfo}
              theme={theme}
            />
          )}
          <PrivacyNotice theme={theme}>
            <FormattedMarkdown
              id={altPrivacyKey || 'leadCapture.privacy'}
              values={{ privacyPolicyLink }}
            />
          </PrivacyNotice>
        </CaptureContainer>
      </ContainerInner>
    </Container>
  );
};

export default compose<Props, CmsProps>(
  withEmailCaptureData,
  withExtHrefs({ privacyPolicyLink: Links.privacy }),
)(CenteredEmailCaptureBanner);
