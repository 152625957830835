import type { Client } from '@peloton/api';
import { runWithCartQueue } from './synchronizer';
import type { LightCartFlagInfo } from './types';

const CART_URL = '/ecomm/cart';

type UpdateEmailBody = {
  email: string;
} & LightCartFlagInfo;

type UpdateEmailWithPolicyBody = {
  hasAcceptedPolicy: boolean;
} & UpdateEmailBody;

const _updateEmail = (api: Client, email: string) =>
  api.put(CART_URL, {
    email,
    renders_light_cart: true,
  } as UpdateEmailBody);

export const updateEmail = runWithCartQueue(_updateEmail);

const _updateEmailWithPolicy = (
  api: Client,
  email: string,
  hasAcceptedPolicy: boolean = true,
) =>
  api.put(CART_URL, {
    email,
    hasAcceptedPolicy,
    renders_light_cart: true,
  } as UpdateEmailWithPolicyBody);

export const updateEmailWithPolicy = runWithCartQueue(_updateEmailWithPolicy);
