/* eslint-disable no-shadow */
import { toBundleType } from '@ecomm/shop/models/Bundle';
import { BundleType } from '@ecomm/shop/models/BundleType';

export enum Product {
  Tread = 'Tread',
  TreadPlus = 'TreadPlus',
  Bike = 'Bike',
  BikePlus = 'BikePlus',
  DigitalApp = 'DigitalApp',
  RainforestCafe = 'Guide',
  Row = 'Row',
  RefurbBike = 'RefurbBike',
  RefurbBikePlus = 'RefurbBikePlus',
}

export type CFU =
  | Product.Tread
  | Product.TreadPlus
  | Product.Bike
  | Product.BikePlus
  | Product.RainforestCafe
  | Product.Row
  | Product.RefurbBike
  | Product.RefurbBikePlus;

export const assertNeverProduct = (product: never, info?: string): never => {
  throw new Error(
    `Function is not exhaustive. A code path must be added for: ${product}, ${info}`,
  );
};

export const getNameFromProduct = (product: CFU) => {
  switch (product) {
    case Product.RainforestCafe:
      return 'Guide';
    case Product.Tread:
      return 'Tread';
    case Product.TreadPlus:
      return 'Tread+';
    case Product.Bike:
      return 'Bike';
    case Product.BikePlus:
      return 'Bike+';
    case Product.Row:
      return 'Row';
    case Product.RefurbBike:
      return 'Refurbished Bike';
    case Product.RefurbBikePlus:
      return 'Refurbished Bike+';
  }

  assertNeverProduct(product);
};

export type ProductBundles =
  | BundleType.Bike
  | BundleType.BikePlus
  | BundleType.Tread
  | BundleType.TreadPlus
  | BundleType.RainforestCafe
  | BundleType.Row
  | BundleType.RefurbishedBike
  | BundleType.RefurbishedBikePlus;

export const isProductBundle = (bundleType: BundleType): bundleType is ProductBundles => {
  return [
    BundleType.Bike,
    BundleType.BikePlus,
    BundleType.Tread,
    BundleType.TreadPlus,
    BundleType.RainforestCafe,
    BundleType.Row,
    BundleType.RefurbishedBike,
    BundleType.RefurbishedBikePlus,
  ].includes(bundleType);
};

type ToProductBundle = (...args: Parameters<typeof toBundleType>) => ProductBundles;

export const toProductBundle: ToProductBundle = s => {
  const bundleType = toBundleType(s);
  if (isProductBundle(bundleType)) {
    return bundleType;
  } else {
    fail('Cannot convert to ProductBundle');
  }
};

export const bundleTypeToProduct = (bundleType: ProductBundles): CFU => {
  switch (bundleType) {
    case BundleType.Bike:
      return Product.Bike;
    case BundleType.BikePlus:
      return Product.BikePlus;
    case BundleType.Tread:
      return Product.Tread;
    case BundleType.TreadPlus:
      return Product.TreadPlus;
    case BundleType.RainforestCafe:
      return Product.RainforestCafe;
    case BundleType.Row:
      return Product.Row;
    case BundleType.RefurbishedBike:
      return Product.RefurbBike;
    case BundleType.RefurbishedBikePlus:
      return Product.RefurbBikePlus;
  }

  assertNeverProduct(bundleType);
};

export const productToBundleType = (product: CFU): BundleType => {
  switch (product) {
    case Product.Bike:
      return BundleType.Bike;
    case Product.BikePlus:
      return BundleType.BikePlus;
    case Product.Tread:
      return BundleType.Tread;
    case Product.TreadPlus:
      return BundleType.TreadPlus;
    case Product.RainforestCafe:
      return BundleType.RainforestCafe;
    case Product.Row:
      return BundleType.Row;
    case Product.RefurbBike:
      return BundleType.RefurbishedBike;
    case Product.RefurbBikePlus:
      return BundleType.RefurbishedBikePlus;
  }

  assertNeverProduct(product);
};
