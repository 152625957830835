import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

const routes = [
  <Route
    key="ar-visualizer"
    path="/:productType/ar"
    component={asyncComponent(
      () => import(/* webpackChunkName: 'ArVisualizerPageView' */ './Handler'),
    )}
  />,
];

export default routes;
