import type { Entities } from '@ecomm/shop/models';
import type { PurchasedItem } from '../models';

export enum ActionType {
  Add = 'ecomm/order/ADD_PURCHASED_ITEM',
}

export type State = Entities<PurchasedItem>;

export const defaultState = {};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.Add:
      return {
        ...state,
        ...action.payload.purchasedItems,
      };
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  purchasedItems: State;
};

export const addPurchasedItems = (
  purchasedItems: Entities<PurchasedItem>,
): AddPurchasedItemsAction => ({
  type: ActionType.Add,
  payload: { purchasedItems },
});

type AddPurchasedItemsAction = {
  type: ActionType.Add;
  payload: { purchasedItems: Entities<PurchasedItem> };
};

type Action = AddPurchasedItemsAction;
