import type { Client } from '@optimizely/optimizely-sdk';
import { createInstance } from '@optimizely/optimizely-sdk';
import { OPTIMIZELY_FULLSTACK_SDK_KEY } from '@peloton/app-config';
import { toLocale } from '@peloton/internationalize';
import type { OptimizelyToggleId } from '@ecomm/feature-toggle/optimizely/features';
import { getId } from '@ecomm/feature-toggle/optimizely/models';

// per https://docs.developers.optimizely.com/full-stack/v2.1/docs/configure-the-logger#section-default-logging-in-each-sdk
const OPTIMIZELY_WARNING_LOG_LEVEL = 3;

const toClient = () =>
  createInstance({
    sdkKey: OPTIMIZELY_FULLSTACK_SDK_KEY,
    logLevel: OPTIMIZELY_WARNING_LOG_LEVEL,
    datafileOptions: { autoUpdate: false },
  });

const getUpdatedFeatureValue = (client: Client = toClient()!) => async (
  toggleId: OptimizelyToggleId,
) => {
  try {
    await client.onReady();
    return client.isFeatureEnabled(toggleId, getId(), {
      locale: toLocale(),
      isEcommTester: false,
    });
  } catch {
    return false;
  }
};

export default getUpdatedFeatureValue;
