import { identity } from 'ramda';
import type { Client, ClientError, ClientRequestConfig } from '@peloton/api';
import {
  isHttpError,
  pipeDataTwice,
  pipeError,
  throwError,
  isNetworkError,
} from '@peloton/api';

export const toSetNewPasswordURL = (userId: string) =>
  `/api/user/${userId}/set_password_for_new_user`;

export enum ErrorCode {
  PasswordAlreadyExists = 'passwordAlreadyExists',
  PasswordDoesntValidate = 'passwordDoesntValidate',
  PasswordNotSent = 'passwordNotSent',
  PasswordTooShort = 'passwordTooShort',
  PasswordBlacklisted = 'passwordBlacklisted',
  NetworkError = 'networkError',
  Default = 'default',
}

const isSetNewPasswordErrorCode = (code: string): code is ErrorCode =>
  (Object.values(ErrorCode) as string[]).includes(code);

export const toSetNewUserPasswordErrorCodeOrDefault = (code?: string): ErrorCode => {
  if (!code || !isSetNewPasswordErrorCode(code)) {
    return ErrorCode.Default;
  } else {
    return code;
  }
};

const apiErrorMap = {
  3070: ErrorCode.PasswordAlreadyExists,
  3080: ErrorCode.PasswordDoesntValidate,
  3090: ErrorCode.PasswordNotSent,
  3100: ErrorCode.PasswordTooShort,
  3110: ErrorCode.PasswordBlacklisted,
};

const toErrorCode = (error: ClientError): ErrorCode => {
  const apiErrorCode = isHttpError(error) ? error.responseBody.errorCode : undefined;

  return isNetworkError(error)
    ? ErrorCode.NetworkError
    : apiErrorMap[apiErrorCode] || ErrorCode.Default;
};

export const setPasswordForNewUser = (
  api: Client,
  userId: string,
  password: string,
  config?: ClientRequestConfig,
) =>
  api
    .post(toSetNewPasswordURL(userId), { password }, config)
    .then(pipeDataTwice(identity))
    .catch(pipeError(throwError<ErrorCode>(toErrorCode)));
