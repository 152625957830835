import React from 'react';
import type { InputProps } from './types';

export type Props = InputProps<HTMLInputElement>;

export default class TextInput extends React.Component<Props> {
  static displayName = 'TextInput';

  render() {
    const {
      getRef,
      isActive,
      label,
      required = false,
      touched = false,
      type = 'text',
      ...props
    } = this.props;

    return (
      <input
        {...props}
        aria-label={label}
        aria-required={required}
        data-user-focused={touched}
        required={required}
        ref={getRef}
        type={type}
      />
    );
  }
}
