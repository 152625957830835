import { spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { useExtLinkEnv } from '@peloton/external-links/context/Provider';
import IdMeSvg from './IdMeSvg';
import { generateIdMeAuthorizationLink } from './utils';

const IdMeButton = () => {
  const externalLinks = useExtLinkEnv();
  return (
    <StyledButton
      type="button"
      id="idme-wallet-button"
      data-test-id="idme-wallet-button"
      onClick={() => (location.href = generateIdMeAuthorizationLink(externalLinks))}
    >
      <SvgContainer>
        <IdMeSvg />
      </SvgContainer>
    </StyledButton>
  );
};

export default IdMeButton;

// As specified by https://s3.amazonaws.com/idme/developer/idme-buttons/assets/img/verify.svg
const SvgContainer = styled.div`
  svg {
    .st0 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #08833d;
    }
    .st1 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #ffffff;
    }
    .st2 {
      enable-background: new;
    }
    .st3 {
      fill: #ffffff;
    }
  }
`;

// As specified by https://developers.id.me/brand-assets
const StyledButton = styled.button`
  height: 44px;
  width: 220px;
  margin: 0 ${spacing[16]};
`;
