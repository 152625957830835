import { CITIZENS_WIDGET_ID, CITIZENS_WIDGET_SDK } from '@peloton/app-config';

export const addCitizensSDKToPage = (callback: () => void) => {
  const script = document.createElement('script');
  script.id = 'citizens-widget-script';
  script.src = CITIZENS_WIDGET_SDK ?? '';
  script.async = true;
  document.body.appendChild(script);

  script.onload = () => {
    callback();
    getCitizens().initKey(CITIZENS_WIDGET_ID);
  };
};

export const getCitizens = () => (window as any).OfferByPrice;
