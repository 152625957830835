import React from 'react';
import { Redirect } from 'react-router';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

const ccpaRoutes = [
  <Route
    exact
    page
    toggleId="ccpaNotice"
    key="caPrivacyNotice"
    path="/california-privacy-notice"
    component={asyncComponent(
      () =>
        import('./ccpa/CaPrivacyNoticePage' /* webpackChunkName: "CaPrivacyNotice" */),
    )}
  />,
];

const routes = [
  ...ccpaRoutes,
  <Route
    key="accessibility"
    toggleId="accessibilityPage"
    exact
    page={true}
    path="/accessibility"
    component={asyncComponent(
      () => import('./AccessibilityPage' /* webpackChunkName: "AccessibilityPage" */),
    )}
  />,
  <Route
    key="legal"
    path="/legal"
    page={true}
    component={asyncComponent(() => import('./Handler' /* webpackChunkName: "Legal" */))}
  />,
  <Route
    key="tradeInTerms"
    path="/trade-in-terms-and-conditions"
    component={asyncComponent(
      () => import('./TradeInTermsPage' /* webpackChunkName: "tradeInTerms" */),
    )}
    page={true}
    shouldTrack={false}
  />,
  <Redirect key="bikeRentalsTerms" from="/one-peloton-club-terms" to="/rental-terms" />,
  <Route
    key="saasAddendumLegalText"
    path="/saas-addendum"
    exact
    page
    component={asyncComponent(
      () => import('./SaasAddendumPage' /* webpackChunkName: "saasAddendum" */),
    )}
  />,
];

export default routes;
