import React from 'react';
import scriptjs from 'scriptjs';
import { asyncComponent } from '@peloton/code-splitting';
import { googleMapsCommonProps } from '@ecomm/google-maps';
import { Route } from '@ecomm/router';
import { RoutePaths } from './config';

const noOp = () => {};

const Routes = [
  <Route
    key="checkout-page"
    path={[RoutePaths.CheckoutPage, '/replacement/checkout', '/bracket/checkout']}
    component={asyncComponent(() => {
      // here, we initialize fetch for google maps for address autocomplete.
      // we don't await the script's 'onload' event, as it should not block render of the checkout page,
      // nor is it actually required for the user to complete checkout.
      scriptjs(googleMapsCommonProps.googleMapURL, noOp);
      return import('./Handler' /* webpackChunkName: "Checkout" */);
    })}
    shouldTrack={false}
  />,
];

export default Routes;
