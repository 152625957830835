import { combineReducers } from 'redux';
import type { ReducerState as ProductOptionState } from '@ecomm/shop/redux/productOptions';
import productOptions from '@ecomm/shop/redux/productOptions';
import type { ReducerState as CouponSavingsState } from './couponSavings';
import couponSavings from './couponSavings';
import type { ReducerState as OrderState } from './orders';
import orders from './orders';
import type { ReducerState as PaymentState } from './payments';
import payments from './payments';
import type { ReducerState as BundlesState } from './purchasedBundles';
import purchasedBundles from './purchasedBundles';
import type { ReducerState as ItemsState } from './purchasedItems';
import purchasedItems from './purchasedItems';
import type { ReducerState as UiState } from './ui';
import ui from './ui';

export type State = BundlesState &
  ItemsState &
  ProductOptionState &
  OrderState &
  PaymentState &
  CouponSavingsState &
  UiState;

export default combineReducers({
  payments,
  purchasedBundles,
  purchasedItems,
  productOptions,
  orders,
  couponSavings,
  ui,
});

export type ReducerState = {
  order: State;
};
