import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import useHasFinancingPartner from '@ecomm/financing/hooks/useHasFinancingPartner';
import { usePrograms } from '@ecomm/programs/Context';
import { useGetShowroomStatus } from '@ecomm/showrooms/hooks';

const useToggles = () => {
  const hasFinancingPartner = useHasFinancingPartner();
  const isToggleActive = useIsToggleActive();
  const showrooms = useGetShowroomStatus();
  const { homeTrial } = usePrograms();

  return {
    accessibilityPage: isToggleActive('accessibilityPage'),
    areShowroomsActive: showrooms !== 'DISABLED',
    ccpaNotice: isToggleActive('ccpaNotice'),
    financingEnabled: hasFinancingPartner,
    hasBlog: isToggleActive('blog'),
    homeTrial,
    hotelFinder: isToggleActive('hotelFinder'),
    ourPledgeEnabled: isToggleActive('ourPledgeFooterLink'),
    bikeRentals: isToggleActive('bikeRentals'),
    showFinancingComplaints: isToggleActive('showFinancingComplaints'),
    showFinancingDisclosure: isToggleActive('showFinancingDisclosure'),
    showLondonStudioLink: isToggleActive('showLondonStudioLink'),
    showRecallLink: isToggleActive('showRecallLink'),
    supportCommercialBike: isToggleActive('supportCommercialBike'),
    waysToTryEnabled: isToggleActive('waysToTry'),
    securityPage: isToggleActive('securityPage'),
    showFinancingPartner: isToggleActive('showFinancingPartner'),
  };
};

export default useToggles;
