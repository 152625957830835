import { brand } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from '@ecomm/internationalize';
import { LoginForm } from '@ecomm/login-form';
import { Modal } from '@ecomm/modal';
import { hb4, b4 } from '@ecomm/typography';

export type StateProps = {
  email?: string;
  isOpen: boolean;
};

export type DispatchProps = {
  closeHandler(): void;
};

type Props = StateProps & DispatchProps;

const LoginModalView: React.FC<React.PropsWithChildren<Props>> = ({
  email,
  isOpen,
  closeHandler,
}) => (
  <FormattedMessage id="checkout.user.signInModal.contentLabel">
    {(contentLabel: string) => (
      <StyledModal
        contentLabel={contentLabel}
        isOpen={isOpen}
        closeHandler={closeHandler}
      >
        <Title>
          <FormattedMessage id="checkout.user.signInModal.title" />
        </Title>
        <Subtitle>
          <FormattedMessage id="checkout.user.signInModal.subtitle" />
        </Subtitle>
        <LoginForm email={email} />
      </StyledModal>
    )}
  </FormattedMessage>
);

export default LoginModalView;

const StyledModal = styled(Modal)<{ children?: React.ReactNode }>`
  background-color: ${brand.light};
`;

const Title = styled.h2`
  ${hb4}
`;

const Subtitle = styled.h3`
  ${b4}
  margin-top: 10px;
`;
