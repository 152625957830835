import type { Reducer } from 'redux';
import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';

export enum ActionType {
  Request = 'pelo/setNewUserPassword/REQUEST',
  RequestSuccess = 'pelo/setNewUserPassword/REQUEST_SUCCESS',
  RequestFailure = 'pelo/setNewUserPassword/REQUEST_FAILURE',
}

export enum Status {
  Init = 'init',
  Loading = 'loading',
  Failed = 'failed',
  Success = 'success',
}

type InitState = { status: Status.Init };
type LoadingState = { status: Status.Loading };
type FailedState = { status: Status.Failed; exception: Exception };
type SuccessState = { status: Status.Success };

export type State = InitState | LoadingState | FailedState | SuccessState;

export const defaultState: State = {
  status: Status.Init,
};

export const reducer: Reducer<State> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.Request:
      return { status: Status.Loading };
    case ActionType.RequestSuccess:
      return { status: Status.Success };
    case ActionType.RequestFailure:
      return {
        status: Status.Failed,
        exception: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

// Actions
export type Request = {
  type: ActionType.Request;
  payload: { password: string };
};

export type RequestSuccess = {
  type: ActionType.RequestSuccess;
};

export type RequestFailure = {
  type: ActionType.RequestFailure;
  payload: Exception;
};

type Action = Request | RequestSuccess | RequestFailure;

// Action Creators
export const setNewUserPassword = (password: string): Request => ({
  type: ActionType.Request,
  payload: { password },
});

export const setNewUserPasswordSuccess = (): RequestSuccess => ({
  type: ActionType.RequestSuccess,
});

export const setNewUserPasswordFailure = (error: Error): RequestFailure => ({
  type: ActionType.RequestFailure,
  payload: toException(error.message),
});

// Selectors
export type NewUserPasswordState = {
  newUserPassword: State;
};

export const getIsSuccess = (state: NewUserPasswordState) =>
  state.newUserPassword.status === Status.Success;

export const getIsLoading = (state: NewUserPasswordState) =>
  state.newUserPassword.status === Status.Loading;

export const getException = (state: NewUserPasswordState) =>
  state.newUserPassword.status === Status.Failed
    ? state.newUserPassword.exception
    : undefined;
