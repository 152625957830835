import _ from 'lodash';
import type { Locale } from '@peloton/internationalize';
import { ELIGIBLE_LOCALES_FOR_GIFTING, toCountry } from '@peloton/internationalize';
import {
  ShopPaymentType,
  ShopCartUpdateActionType,
} from '@ecomm/graphql/types.generated';
import type {
  CountryCode,
  ShopCartUpdateAction,
  ShopPayment,
} from '@ecomm/graphql/types.generated';
import type {
  CtCartFragment,
  LineItemDataFragment,
} from '@ecomm/shop-cart/graphql/fragments.generated';
import { BundleType } from '@ecomm/shop/models/BundleType';
import { HAULAWAY_SKU } from '../constants/skus';

export const PRODUCT_AVAILABLE_STATUS = 'available';
export const ALL_ACCESS_MEMBERSHIP = 'Subscription';
export const CFU_TYPE = 'Equipment';
export const ACCESSORY = 'Accessory';
export const GUIDE = 'guide';
export const BUNDLE = 'Bundle';
export const WARRANTY = 'Warranty';

export enum LegacyEquipmentType {
  Bike = 'bike',
  BikePlus = 'bikeplus',
  Guide = 'guide',
  Row = 'row',
  Tread = 'tread',
  TreadPlus = 'treadplus',
  RefurbBike = 'refurbbike',
  RefurbBikePlus = 'refurbbikeplus',
}

const legacyEquipmentTypeToBundleEquipmentType = {
  [LegacyEquipmentType.Bike]: BundleType.Bike,
  [LegacyEquipmentType.BikePlus]: BundleType.BikePlus,
  [LegacyEquipmentType.Guide]: BundleType.RainforestCafe,
  [LegacyEquipmentType.Tread]: BundleType.Tread,
  [LegacyEquipmentType.TreadPlus]: BundleType.TreadPlus,
  [LegacyEquipmentType.Row]: BundleType.Row,
  [LegacyEquipmentType.RefurbBike]: BundleType.RefurbishedBike,
  [LegacyEquipmentType.RefurbBikePlus]: BundleType.RefurbishedBikePlus,
};

export const getCountryCart = () => {
  return toCountry().toUpperCase() as CountryCode;
};

export const isProductAvailable = (configurationStatus: string) => {
  return configurationStatus.toLowerCase() === PRODUCT_AVAILABLE_STATUS;
};

export const isUnavailableProductExists = (lineItems: LineItemDataFragment[]) => {
  return (
    lineItems.filter(
      (obj: LineItemDataFragment) => !isProductAvailable(obj?.configurationStatus ?? ''),
    ).length > 0
  );
};

export const getCartPriceSummary = (cartItems?: CtCartFragment | null) =>
  cartItems?.priceSummaries?.cartPriceSummary;

export const sortedLineItems = (lineItems: LineItemDataFragment[]) =>
  _.orderBy(lineItems, ['addedAt'], ['desc']);

export const getCartSubTotal = (cartItems?: CtCartFragment | null) =>
  cartItems?.priceSummaries?.lineItemPriceSummary?.subtotal?.centAmount ?? 0;

export const getCartTax = (cartItems?: CtCartFragment | null) =>
  cartItems?.priceSummaries?.cartPriceSummary?.taxTotal?.centAmount ?? 0;

export const getCartShipping = (cartItems?: CtCartFragment | null) =>
  cartItems?.priceSummaries?.shippingPriceSummary?.subtotal?.centAmount ?? 0;

export const getCartTotal = (cartItems?: CtCartFragment | null) =>
  cartItems?.priceSummaries?.cartPriceSummary?.total?.centAmount ?? 0;

export const getCartCurrency = (cartItems?: CtCartFragment | null) =>
  cartItems?.priceSummaries?.cartPriceSummary?.total?.currencyCode ?? 'USD';

export const isUnavailableProductExistsInBundles = (
  lineItems: LineItemDataFragment[],
) => {
  return (
    lineItems.filter((obj: LineItemDataFragment) =>
      isUnavailableProductExists(obj?.bundleItemLineItems ?? []),
    ).length > 0
  );
};

// AAM = All-Access-Membership
export const getAAMProduct = (cartItemList: LineItemDataFragment[]) => {
  return cartItemList.find(
    (listItem: LineItemDataFragment) =>
      listItem?.productVariant?.type === ALL_ACCESS_MEMBERSHIP,
  );
};

export const getRegularAAMProduct = (cartItemList: LineItemDataFragment[]) => {
  return cartItemList.find(
    (listItem: LineItemDataFragment) =>
      listItem?.productVariant?.type === ALL_ACCESS_MEMBERSHIP &&
      listItem?.priceSummary?.subtotal?.centAmount == 0,
  );
};

export const getPrepaidAAMProduct = (cartItemList: LineItemDataFragment[]) => {
  return cartItemList.find(
    (listItem: LineItemDataFragment) =>
      listItem?.productVariant?.type === ALL_ACCESS_MEMBERSHIP &&
      listItem?.priceSummary?.subtotal?.centAmount > 0,
  );
};

type LimitedWarranty = {
  warrantySku: string;
  equipmentName: string;
};

export const getLimitedWarranties = (
  shopCart?: CtCartFragment | null,
): { [key in LegacyEquipmentType]?: LimitedWarranty } => {
  const limitedWarrantiesInCart: { [key in LegacyEquipmentType]?: LimitedWarranty } = {};

  for (const lineItem of shopCart?.lineItems ?? []) {
    if (lineItem.bundleItemLineItems && lineItem.productVariant.legacyEquipmentType) {
      const limitedWarranty = lineItem.bundleItemLineItems.find(
        item => item.productVariant.type === WARRANTY,
      );
      const equipment = lineItem.bundleItemLineItems.find(
        item => item.productVariant.type === CFU_TYPE,
      );

      if (limitedWarranty && equipment) {
        limitedWarrantiesInCart[lineItem.productVariant.legacyEquipmentType] = {
          warrantySku: limitedWarranty.productKey,
          equipmentName: equipment.productVariant.name,
        };
      }
    }
  }

  return limitedWarrantiesInCart;
};

export const isPrepaidMembership = (item: LineItemDataFragment | undefined) => {
  // TODO: once Shop team (Julia) creates different types to deferenciate regular and prepaid membership this logic needs to be modified a bit
  return (
    item?.productVariant?.type === ALL_ACCESS_MEMBERSHIP &&
    item?.priceSummary?.subtotal?.centAmount !== 0
  );
};

export const hasCFU = (cartItemList: LineItemDataFragment[]) => {
  const cfuItem = cartItemList.find((item: LineItemDataFragment) =>
    item?.bundleItemLineItems?.some(
      (attr: LineItemDataFragment) => attr?.productVariant?.type === CFU_TYPE,
    ),
  );

  return Boolean(cfuItem);
};

export const hasAccessories = (cartItemList: LineItemDataFragment[]): boolean => {
  return cartItemList?.some(
    (item: LineItemDataFragment) =>
      item?.productVariant?.type === ACCESSORY ||
      hasAccessories(item?.bundleItemLineItems ?? []),
  );
};

export const hasCFUExceptGuide = (cartItemList: LineItemDataFragment[]) => {
  return cartItemList.some(
    (item: LineItemDataFragment) => !isGuide(item) && isCFUbundle(item),
  );
};

export const isCFUbundle = (lineItem: LineItemDataFragment) => {
  const currentItem = lineItem?.bundleItemLineItems?.find(
    (bundle: LineItemDataFragment) => bundle?.productVariant?.type === CFU_TYPE,
  );

  return Boolean(currentItem);
};

export const hasCFUExceptGuideAndRefurbishedBike = (
  cartItemList: LineItemDataFragment[],
) => {
  return cartItemList.some(
    (item: LineItemDataFragment) =>
      !isGuide(item) && !isRefurbishedBike(item) && isCFUbundle(item),
  );
};

export const hasBikeorBikePlus = (cartItemList: LineItemDataFragment[]) => {
  return cartItemList.some(
    (item: LineItemDataFragment) => isBike(item) || isBikePlus(item),
  );
};

export const isGuide = (lineItem: LineItemDataFragment) =>
  lineItem.productVariant?.legacyEquipmentType === LegacyEquipmentType.Guide;

const isBike = (lineItem: LineItemDataFragment) =>
  lineItem.productVariant?.legacyEquipmentType === LegacyEquipmentType.Bike;

const isBikePlus = (lineItem: LineItemDataFragment) =>
  lineItem.productVariant?.legacyEquipmentType === LegacyEquipmentType.BikePlus;

const isTread = (lineItem: LineItemDataFragment) =>
  lineItem.productVariant?.legacyEquipmentType === LegacyEquipmentType.Tread;

const isTreadPlus = (lineItem: LineItemDataFragment) =>
  lineItem.productVariant?.legacyEquipmentType === LegacyEquipmentType.TreadPlus;

const isRefurbishedBike = (lineItem: LineItemDataFragment) => {
  const legacyEquipmentType = lineItem.productVariant
    ?.legacyEquipmentType as LegacyEquipmentType;
  return [LegacyEquipmentType.RefurbBike, LegacyEquipmentType.RefurbBikePlus].includes(
    legacyEquipmentType,
  );
};

export const lastCFUIndex = (cartItemList: LineItemDataFragment[]) => {
  // expects a list of line items without any membership product
  let index: number | null = null;

  cartItemList.forEach((item: LineItemDataFragment, i: number) => {
    const currentItem = item?.bundleItemLineItems?.find(
      (bundle: LineItemDataFragment) => bundle?.productVariant?.type === CFU_TYPE,
    );
    if (currentItem) {
      index = i;
    }
  });

  return index;
};

export const shouldAutoAddSubForLineItem = (lineItem: LineItemDataFragment) =>
  Boolean(lineItem.productVariant?.legacyEquipmentType && !isGuide(lineItem));

export const isCartEligibleForPrepaidMembership = (
  cartItemList: LineItemDataFragment[],
) =>
  cartItemList.some(item => item.productVariant?.legacyEquipmentType && !isGuide(item));

export const isCartEligibleForGifting = (
  cartItemList: LineItemDataFragment[],
  locale: string,
) =>
  ELIGIBLE_LOCALES_FOR_GIFTING.includes(locale as Locale) &&
  cartItemList.some(item => item.productVariant?.legacyEquipmentType);

export const resetPaymentInfo = (
  actions: ShopCartUpdateAction[],
  updateCartMutation: Function,
) => {
  updateCartMutation({
    variables: {
      input: {
        country: getCountryCart(),
        actions,
      },
    },
  });
};

export const getCFUBundleSKU = ({
  shopCart,
  financingBundleType,
}: {
  shopCart?: CtCartFragment | null;
  financingBundleType: BundleType;
}): string[] => {
  const financingBundleLineItem = shopCart?.lineItems?.find(lineItem => {
    const legacyEquipment = lineItem?.productVariant?.legacyEquipmentType ?? '';
    return (
      legacyEquipmentTypeToBundleEquipmentType[legacyEquipment] === financingBundleType
    );
  });
  const bundleItem = financingBundleLineItem?.bundleItemLineItems?.find(
    item => item?.productVariant?.type === CFU_TYPE,
  );
  const hasSKU = Boolean(bundleItem?.productVariant?.sku);
  // ts-server keeps reading bundleItemm as possibly undefined despite Boolean check
  return hasSKU ? [bundleItem?.productVariant.sku as string] : [];
};

export const hasGiftInformation = (shopCart?: CtCartFragment | null) => {
  const giftInformation = shopCart?.giftInformation;
  return Boolean(
    giftInformation?.recipientEmail ||
      giftInformation?.gifterName ||
      giftInformation?.giftMessage ||
      giftInformation?.recipientName,
  );
};

export const getProductIds = (cartItemList: LineItemDataFragment[]): string[] =>
  cartItemList.flatMap(cartLineItem => {
    if (cartLineItem.bundleItemLineItems) {
      return cartLineItem.bundleItemLineItems?.map(bundleItem => {
        return bundleItem?.productVariant?.legacyProductId as string;
      });
    } else return [cartLineItem.productVariant?.legacyProductId as string];
  });

export const clearPayments = (
  cartPaymentData: ShopPayment[],
  updateCartMutation: Function,
) => {
  const ccOrFinancingPayments = cartPaymentData.filter(
    payment =>
      payment.type === ShopPaymentType.StripeCreditCard ||
      payment.type === ShopPaymentType.KlarnaFinancing ||
      payment.type === ShopPaymentType.ZipFinancing ||
      payment.type === ShopPaymentType.AffirmFinancing ||
      payment.type === ShopPaymentType.CitizensFinancing ||
      payment.type === ShopPaymentType.Unknown,
  );

  const removePaymentActions: ShopCartUpdateAction[] = ccOrFinancingPayments.map(
    payment => ({
      removePayment: {
        type: payment.type,
        token: payment.paymentToken,
        id: payment.id,
      },
      actionType: ShopCartUpdateActionType.RemovePayment,
    }),
  );

  if (removePaymentActions.length) {
    resetPaymentInfo(removePaymentActions, updateCartMutation);
  }
};

export const isCartEligibleForHaulaway = (cartItemList: LineItemDataFragment[]) =>
  cartItemList.some(item => isBikePlus(item) || isTread(item) || isTreadPlus(item));

export const getCartIncludesHaulaway = (cartItems: LineItemDataFragment[]) => {
  const haulawayItem = cartItems.find(item => item?.productVariant?.key === HAULAWAY_SKU);

  return {
    cartIncludesHaulaway: Boolean(haulawayItem),
    haulawayItem,
  };
};

export const getCartItemsExcludingId = ({
  lineItems,
  id,
}: {
  lineItems: LineItemDataFragment[];
  id: string;
}) => {
  const indexOfRemoval = lineItems.findIndex(item => item.id === id);

  if (indexOfRemoval === -1) {
    return lineItems;
  }

  return [...lineItems.slice(0, indexOfRemoval), ...lineItems.slice(indexOfRemoval + 1)];
};
