import type { Client } from '@peloton/api';
import { pipeDataTwice } from '@peloton/api';
import { toStoreSlug } from '@peloton/stores';
import type { ID, Slug } from '@ecomm/models';
import { toBundles } from './mappers';

export const toBundleUrl = (identifier?: ID | Slug) =>
  identifier
    ? `/ecomm/store/${toStoreSlug()}/bundle/${identifier}?is_renamed=true`
    : `/ecomm/store/${toStoreSlug()}/bundles?is_renamed=true`;

export const fetchAllBundles = (api: Client) =>
  api.get(toBundleUrl()).then(pipeDataTwice(toBundles));

export const fetchBundleById = (api: Client, id: ID) =>
  api.get(toBundleUrl(id)).then(pipeDataTwice(toBundles));

export const fetchBundleBySlug = (api: Client, slug: Slug) =>
  api.get(toBundleUrl(slug)).then(pipeDataTwice(toBundles));
