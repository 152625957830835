import { apply } from 'ramda';

type FnArgs<F> = F extends (...args: infer A) => any ? A : never;
type GenericPromise = (...args: any[]) => Promise<{}>;

type Resolve = (r: any) => void;
type Reject = (r: any) => void;

type HoldRow<F> = [F, FnArgs<F>, Resolve, Reject];

type PromiseRow = HoldRow<GenericPromise>;

const queues: {
  [k: string]: {
    items: Array<PromiseRow>;
    running: boolean;
  };
} = {};

const _runQueue = (name: string) => {
  const promiseRow = queues[name].items.shift();
  if (promiseRow) {
    const [fn, args, resolve, reject] = promiseRow;
    apply(fn, args)
      .then(result => {
        resolve(result);
        _runQueue(name);
      })
      .catch(err => {
        reject(err);
        _runQueue(name);
      });
  } else {
    // queue's empty, reset
    queues[name].running = false;
  }
};

const runQueue = (name: string) => {
  // once it has started consuming the queue, it
  // will continue to do so automatically until the queue
  // is empty.
  if (!queues[name].running) {
    queues[name].running = true;
    _runQueue(name);
  }
};

/*
 * This provides a way to make requests to an endpoint synchronous, for example
 * with requests to /cart.
 *
 * @param name - The shared queue to synchronize on
 * @param fn - The promise-returning API function to wrap
 *
 * @returns The wrapped function, calls to it will now be queued
 */
export const runWithQueue = (name: string) => <B extends GenericPromise>(fn: B) => {
  // initialize the queue
  if (!queues[name]) {
    queues[name] = {
      items: [],
      running: false,
    };
  }

  return function (...args: FnArgs<B>) {
    // every call, push a new call onto the queue while holding onto the resolve & reject
    const p = new Promise((resolve, reject) => {
      queues[name].items.push([fn, args, resolve, reject]);
    });
    runQueue(name);

    // return the promise, which will be resolved by _runQueue above
    return p;
  };
};

export const runWithCartQueue = runWithQueue('CART');
