import React from 'react';
import { Redirect } from 'react-router-dom';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

export const PATH = '/classes';

const Routes = [
  <Route
    exact
    key="classes-pages"
    path={PATH}
    component={asyncComponent(
      () => import('./Handler' /* webpackChunkName: "Classes" */),
    )}
    page={true}
    shouldTrack={false}
  />,
  <Redirect exact key="classes-redirect" from={PATH} to="/bike/classes" />,
];

export default Routes;
