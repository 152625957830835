import type { RouterRootState } from 'connected-react-router';
import { createMatchSelector } from 'connected-react-router';
import { pathOr } from 'ramda';
import { createSelector } from 'reselect';
import type { ReducerState } from './redux';

export const getUIState = (state: ReducerState) => state.pgDigitalCheckout;
export const getReceiptUrl = (state: ReducerState) =>
  state.pgDigitalCheckout.socialSignUpCheckout?.receiptUrl;
export const getOrderId = (state: ReducerState) =>
  state.pgDigitalCheckout.socialSignUpCheckout?.id;
export const getIsPaymentSuccessful = (state: ReducerState) =>
  state.pgDigitalCheckout.socialSignUpCheckout?.isPaymentSuccessful;

export const getDigitalPlanSku = createSelector(
  (state: RouterRootState) => state.router,
  router => {
    const match = createMatchSelector('/digital/checkout/:slug')({
      router,
    });
    const matchI18n = createMatchSelector('/:locale/digital/checkout/:slug')({
      router,
    });
    return pathOr(undefined, ['params', 'slug'], match || matchI18n);
  },
);
