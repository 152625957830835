import { brand } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Spinner } from '@ecomm/spinner';

const PlaceholderHeroWithSpinner: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Placeholder>
    <Spinner area={40} />
  </Placeholder>
);

export default PlaceholderHeroWithSpinner;

const Placeholder = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${brand.darkest};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
