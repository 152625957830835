import React from 'react';
import styled from 'styled-components';
import { BackgroundImage } from '@ecomm/background-media';
import type { PromoHeroData } from '@ecomm/copy';
import type { TrackingProps } from '../trackingUtils';
import HeroContent from './HeroContent';
import {
  ContainerStyles,
  MOBILE_IMAGE_HEIGHT,
  buildMediaAgnosticHeroBreakpointDimensions,
  mediaAgnosticHeroBreakpointDimensions,
} from './styles';

type OptionalFields = 'video' | 'eyebrow' | 'support';
type Props = Required<Omit<PromoHeroData, OptionalFields>> &
  Pick<PromoHeroData, 'eyebrow' | 'support'> &
  TrackingProps & {
    ctaThemes?: object[];
    activeSlide?: number;
    customContent?: React.ReactNode;
  };

const ImageHero: React.FC<React.PropsWithChildren<Props>> = ({
  image: { mobile, tablet, desktop, desktopLarge },
  ...content
}) => {
  const breakpointOptions = {
    mobile: { src: mobile, height: MOBILE_IMAGE_HEIGHT },
    tablet: { src: tablet },
    desktop: { src: desktop },
    desktopLarge: { src: desktopLarge },
  };

  return (
    <StyledBackgroundImage
      src={mobile}
      breakpointOptions={buildMediaAgnosticHeroBreakpointDimensions({
        breakpointOptions,
        requiredDimensions: mediaAgnosticHeroBreakpointDimensions,
      })}
    >
      <HeroContent {...content} />
    </StyledBackgroundImage>
  );
};

export default ImageHero;

const StyledBackgroundImage = styled(BackgroundImage)`
  ${ContainerStyles}
`;
