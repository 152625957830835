import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { useLocale } from '@peloton/internationalize';
import useNav from '@content/client/www/nav/useNav';
import { Link } from '@ecomm/internationalize-ui';
import { virtualAppointmentBookingUrl } from '@ecomm/showrooms/urls';
import { b4 } from '@ecomm/typography';

type Props = {
  hideMenu: VoidFunction;
};

const VirtualShowroomLink: React.FC<React.PropsWithChildren<Props>> = ({ hideMenu }) => {
  const cmsId = 'nav.geo.virtual';
  const locale = useLocale();
  const { trackEvent } = useTracking();
  const { content, isLoading } = useNav(locale);

  const virtualAppt = content.geoMenu?.['navigation.geoMenu']?.[cmsId];

  if (isLoading) {
    return null;
  }

  const handleClick = () => {
    hideMenu();
    trackEvent({
      properties: {
        category: 'location',
        unitName: cmsId,
        linkName: virtualAppt.text,
      },
    });
  };

  return (
    <li>
      <MenuLink
        to={virtualAppointmentBookingUrl}
        href={virtualAppointmentBookingUrl}
        hasUnderline={false}
        onClick={() => handleClick()}
      >
        {virtualAppt.text}
      </MenuLink>
    </li>
  );
};

export default VirtualShowroomLink;

const MenuLink = styled(Link)`
  span {
    ${b4}
`;
