import type { Locale } from '@peloton/internationalize';
import useContentAggregate from '../aggregates/useContentAggregate';
import footer from './footer';

const useFooter = (locale: Locale) => useContentAggregate(footer.entryId, false, locale);

// For CRA and Next apps
export const useNativeCountryNames = () => {
  const { content } = useContentAggregate(footer.entryId);
  return content?.localePicker.nativeCountryNames;
};

export default useFooter;
