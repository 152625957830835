import { BundleType, isValidBundleType } from './models';

const toShopUrl = (path: string): string => {
  const [empty, shop, bundleType, slugOrType] = path.split('/');

  if (!isValidBundleType(bundleType)) {
    return path;
  }

  if (slugOrType === 'bikeplus-refurbished-consumer-na') {
    return '/shop/refurbished/bike-plus';
  } else if (slugOrType === 'bike-refurbished-consumer-package') {
    return '/shop/refurbished/bike';
  } else if (slugOrType === 'bike-refurbished-consumer-package-de') {
    return '/shop/refurbished/bike';
  } else if (slugOrType === 'bike-refurbished-consumer-package-uk') {
    return '/shop/refurbished/bike';
  }

  return bundleType === BundleType.Accessories
    ? [empty, shop, bundleType, slugOrType].join('/')
    : [empty, shop, bundleType].join('/');
};

type RedirectUrlParams = {
  showUpsellPage: boolean | null;
  bundleType: BundleType;
  isOPC: boolean;
  path: string;
};

const toUpsellUrl = ({ bundleType, isOPC }: RedirectUrlParams) => {
  const parts = ['shop', bundleType, isOPC && 'rent', 'complete-cart'].filter(Boolean);

  return `/${parts.join('/')}`;
};

export const toPostAddRedirectUrl = (params: RedirectUrlParams): string => {
  const { showUpsellPage, path } = params;

  return showUpsellPage ? toUpsellUrl(params) : toShopUrl(path);
};

export default toShopUrl;
