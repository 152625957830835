export enum Actions {
  Message = 'pelo/analytics/MESSAGE',
}

export enum Message {
  movedOffEmail,
  shippingDetailField,
  paymentDetailField,
  paymentCVCField,
  calculateShipping,
  clickedPlaceOrder,
  clickedReviewOrder,
  clickedBuyWithFinancing,
  clickedRemoveSubscription,
}

type MessageActionPayload = {
  message: Message;
  properties?: any;
};

export type MessageAction = {
  type: Actions.Message;
  payload: MessageActionPayload;
};

export const track = (message: Message, properties?: any): MessageAction => ({
  type: Actions.Message,
  payload: {
    message,
    properties,
  },
});
