import { white } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { track } from '@peloton/analytics/analytics';
import { toLocaleFlag } from '@peloton/internationalize/models/flag';
import type { Locale as LocaleKind } from '@peloton/internationalize/models/locale';
import { toDisplayName } from '@peloton/internationalize/models/locale';
import { underlineHover } from '@peloton/styles';
import {
  GeoModalLocaleSwitch,
  useGeoModal,
} from '@ecomm/bootstrapping/internationalize/GeoModalV2/hooks/useGeoModal';
import { Link } from '@ecomm/internationalize-ui';
type Decoration = 'underline' | undefined;

type Props = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'is'> & {
  currentLocale: LocaleKind;
  locale: LocaleKind;
  decoration?: Decoration;
};

const LocaleLinkView: React.FC<React.PropsWithChildren<Props>> = ({
  currentLocale,
  locale,
  decoration,
  onClick,
  ...props
}) => {
  const { switchApplication } = useGeoModal();
  return (
    <Locale
      {...props}
      // hrefLang={locale}
      target="_self"
      variant="body"
      onClick={() => {
        handleClick(currentLocale, locale, onClick);
        switchApplication(locale, GeoModalLocaleSwitch.footer);
      }}
    >
      <Flag src={toLocaleFlag(locale)} alt="" />
      <LocaleName decoration={decoration}>{toDisplayName(locale)}</LocaleName>
    </Locale>
  );
};

const handleClick = (
  originatingStore: LocaleKind,
  destinationStore: LocaleKind,
  onClick?: React.MouseEventHandler<HTMLAnchorElement>,
): React.MouseEventHandler<HTMLAnchorElement> => (...args) => {
  track({
    event: 'User switched stores',
    properties: {
      originatingStore,
      destinationStore,
    },
  });

  onClick && onClick(...args);
};

export default LocaleLinkView;

export const Flag = styled.img`
  display: block;
  height: 24px;
  width: 24px;
`;

const Locale = styled(Link)`
  align-items: center;
  color: ${white};
  display: flex;
  span {
    display: flex;
    align-items: center;
  }

  & + & {
    margin-top: 20px;
  }

  ${Flag} {
    margin-right: 15px;
  }
`;

const LocaleName = styled.span`
  ${({ decoration }: { decoration: Decoration }) =>
    decoration === 'underline' ? underlineHover : ''}
`;
