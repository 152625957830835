import { useMemo } from 'react';
import { useKeyValueData, usePromotionalTextData } from '@ecomm/copy';

// This will be replaced with page specific micro copy in Next. This is the least difficult pathway in CRA.
export const useCRAMicroCopy = () => {
  const pauseLabel = usePromotionalTextData('playPauseToggleDefaultPauseLabel');
  const playLabel = usePromotionalTextData('playPauseToggleDefaultPlayLabel');
  const nextButtonLabel = useKeyValueData('carousel.nextButtonLabel');
  const previousButtonLabel = useKeyValueData('carousel.previousButtonLabel');
  const perMonthSuffix = usePromotionalTextData('monthlySuffix');
  const beginner = useKeyValueData('beginner');
  const intermediate = useKeyValueData('intermediate');
  const advanced = useKeyValueData('advanced');
  const seeMoreLabel = useKeyValueData('reviews-q-and-a.read-more');
  const seeLessLabel = useKeyValueData('reviews-q-and-a.see-less');
  const zoomIntoImage = useKeyValueData('zoomIntoImage');
  const zoomOutOfImage = useKeyValueData('zoomOutOfImage');
  const skipToContentLabel = useKeyValueData('skipToContentLabel');
  const secondaryPagesNavigationLabel = useKeyValueData('secondaryPagesNavigationLabel');
  const legalPagesNavigationLabel = useKeyValueData('legalPagesNavigationLabel');
  const upsellRequiredField = useKeyValueData('upsellRequiredField');
  const selectQuantity = useKeyValueData('selectQuantity');
  const selectSizeLabel = useKeyValueData('selectSizeLabel');
  const multiselectSelectionLabel = useKeyValueData('multiselectSelectionLabel');
  const prop65Warning = useKeyValueData('prop65Warning');
  const policyUpdatesHeader = useKeyValueData('policyUpdatesHeader');
  const value = useMemo(() => {
    return {
      pauseLabel,
      playLabel,
      nextButtonLabel,
      previousButtonLabel,
      perMonthSuffix,
      beginner,
      intermediate,
      advanced,
      seeMoreLabel,
      seeLessLabel,
      zoomIntoImage,
      zoomOutOfImage,
      skipToContentLabel,
      secondaryPagesNavigationLabel,
      legalPagesNavigationLabel,
      upsellRequiredField,
      selectQuantity,
      selectSizeLabel,
      multiselectSelectionLabel,
      prop65Warning,
      policyUpdatesHeader,
    };
  }, [
    pauseLabel,
    playLabel,
    nextButtonLabel,
    previousButtonLabel,
    perMonthSuffix,
    beginner,
    intermediate,
    advanced,
    seeMoreLabel,
    seeLessLabel,
    zoomIntoImage,
    zoomOutOfImage,
    skipToContentLabel,
    secondaryPagesNavigationLabel,
    legalPagesNavigationLabel,
    upsellRequiredField,
    selectQuantity,
    selectSizeLabel,
    multiselectSelectionLabel,
    prop65Warning,
    policyUpdatesHeader,
  ]);

  return value;
};
