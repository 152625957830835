import useSWR, { useSWRConfig } from 'swr';
import { ALLOW_PREVIEW_COPY } from '@peloton/app-config';
import { useLocale } from '@peloton/internationalize';
import type { Locale } from '@peloton/internationalize';
import processContentfulData from '@ecomm/copy-xray/processContentfulData';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { toFetcher as peloContentFetcher } from '../../globalEntriesFetcher';
import { IMMUTABLE, REVALIDATE_DEFAULT } from '../../revalidation';

type Response<T> = {
  content: T | null;
  isLoading: boolean;
};

const useContentAggregate = <T extends object = any>(
  entryId: string,
  isPreview: boolean = false,
  paramLocale?: Locale,
): Response<T> => {
  const { fetcher } = useSWRConfig();
  const hookLocale = useLocale();
  const locale = paramLocale || hookLocale;
  const previewCopy = useIsToggleActive()('previewCopy');
  const isCopyXrayActive = useIsToggleActive()('copyXray');
  const preview = previewCopy || isPreview || ALLOW_PREVIEW_COPY;
  const revalidation = preview ? REVALIDATE_DEFAULT : IMMUTABLE;
  const { data, error } = useSWR(entryId, {
    fetcher: fetcher ?? peloContentFetcher(preview, locale), // If fetcher is defined we're in CRA and falling back to craGlobalEntriesFetcher
    ...revalidation,
  });
  const isLoading = !error && !data;
  const content = data as T; // Data is unknown and casted because of lack of ability to extend default fetcher types

  if (error) {
    const pathnameMessage =
      typeof window !== 'undefined' ? ` on ${window.location.pathname}` : '';
    console.error(`Error fetching content entry ${entryId}`, error);
    throw new Error(
      `The content entry ${entryId} you requested is missing from globalEntriesConfig${
        preview ? ' in preview' : ''
      } ${pathnameMessage}.`,
    );
  }

  return {
    content: isCopyXrayActive ? processCopyXrayData(content) : content,
    isLoading,
  };
};

const processCopyXrayData = <T extends object = any>(data: T): T => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    if (!value) return acc;
    if (value.constructor == Array) {
      return {
        ...acc,
        [key]: value.map(v => {
          return processCopyXrayData(v);
        }),
      };
    }
    return { ...acc, ...processContentfulData({ [key]: value }) };
  }, {}) as T;
};

export default useContentAggregate;
