import type { SagaIterator } from 'redux-saga';
import { call, getContext, put, select } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { updateEmailWithPolicy } from '@ecomm/cart/api';
import { selectCart } from '@ecomm/cart/redux';
import { getIsToggleActive } from '@ecomm/feature-toggle';
import { checkout } from '../affirm/adapters';
import type { SubmitFinancingAction } from '../redux';
import { affirmSubmitted, submitFinancingFailure } from '../redux';

export const submitFinancingSaga = function* (
  client: Client,
  action: SubmitFinancingAction,
): SagaIterator {
  const { email } = action.payload;
  try {
    yield put(affirmSubmitted());
    yield call(updateEmailWithPolicy, client, email);
    const { cart } = yield select(selectCart);

    const errorReporter = yield getContext('errorHandler');

    const isGiftCardEnabled = yield select(getIsToggleActive('legacy_cfu_gift_card'));

    yield call(checkout, {
      ...action.payload,
      cart,
      onError: errorReporter,
      isGiftCardEnabled,
    });
  } catch (error) {
    yield put(submitFinancingFailure(error));
  }
};
