import React, { useState, useContext, useEffect } from 'react';
import { PromoPanelContext } from './PromoPanelProvider';

type Props = {
  buildTime?: number;
};

const DateTriggerProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  buildTime,
}) => {
  const [overrideToday, setOverrideToday] = useState(new Date(buildTime || Date.now()));

  const resetOverrideToday = () => {
    setOverrideToday(new Date());
  };

  useEffect(() => {
    if (buildTime) {
      resetOverrideToday();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PromoPanelContext.Provider
      value={{
        setActivePromo: () => {},
        resetActivePromo: () => {},
        overrideToday,
        resetOverrideToday,
        setOverrideToday,
      }}
    >
      {children}
    </PromoPanelContext.Provider>
  );
};

export default DateTriggerProvider;

export const useDateTriggerContext = () => {
  const { overrideToday, resetOverrideToday, setOverrideToday } = useContext(
    PromoPanelContext,
  );
  return {
    overrideToday,
    resetOverrideToday,
    setOverrideToday,
  };
};
