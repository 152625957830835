import { cond, equals, always } from 'ramda';
import { EquipmentType } from '@ecomm/graphql/types.generated';
import { toEquipmentTypeFromBundleType } from '@ecomm/shop/models';
import { BundleType } from '@ecomm/shop/models/BundleType';
import { ProductLine } from '@ecomm/shop/models/Product';

export enum ProductType {
  Bike = 'bike',
  Tread = 'tread',
  Other = 'other',
}

export const toProductType = (bundleType: BundleType): ProductType => {
  if (bundleType === BundleType.Bike || bundleType === BundleType.BikePlus) {
    return ProductType.Bike;
  } else if (bundleType === BundleType.Tread || bundleType === BundleType.TreadPlus) {
    return ProductType.Tread;
  }
  return ProductType.Other;
};

export const toCatalogProductLine = (bundleType: BundleType): string =>
  toEquipmentTypeFromBundleType(bundleType) || '';

export const toEquipmentTypeByProductLine = (
  productLine: ProductLine,
): EquipmentType | undefined =>
  cond([
    [equals(ProductLine.Bike), always(EquipmentType.Bike)],
    [equals(ProductLine.BikePlus), always(EquipmentType.Bikeplus)],
    [equals(ProductLine.Tread), always(EquipmentType.Tread)],
    [equals(ProductLine.TreadPlus), always(EquipmentType.Treadplus)],
    [equals(ProductLine.Row), always(EquipmentType.Row)],
    [() => true, always(undefined)],
  ])(productLine);

// Calculate min and max ranges of variants
type WithPrice = {
  price: { amount: number };
};

type WithPriceAndVariants = {
  variants: WithPrice[];
  range: number;
};

export type PriceRange = { low: number; high: number };

export const toBundlePriceRange = ({
  variants,
  range = 6,
}: WithPriceAndVariants): PriceRange => {
  const defaultResponse = { low: 0, high: 0 };
  if (range === 0) return defaultResponse;

  let amounts: number[] = [];
  if (variants.length === 1) {
    // if there's exactly 1 variant and a range >= 1,
    // "amounts" should be that variant's price "range" number of times
    // ex. variant price = $50, range = 3
    // -> amounts = [50, 50, 50]
    // this ensures our min and max amounts take all 3 selections
    // -> minAmount = 50 * 3 = $150 (instead of just $50)
    // -> maxAmount = 50 * 3 = $150 (instead of just $50)
    const amount = variants[0].price.amount;
    amounts = Array(range).fill(amount);
  } else if (variants.length < range) {
    // if there's more than one variant AND the range exceeds our variants,
    // we can't cleanly find a min and max price.
    // This is like having a multi-select where you're required
    // to select every option mutliple times over!
    // We'll consider this an impossible case that returns the default response.
    return defaultResponse;
  } else {
    amounts = variants.map(variant => variant.price.amount);
  }

  const sortDescending = (a: number, b: number): number => b - a;
  const sumArray = (a: number, b: number): number => a + b;
  // sort descending
  const descendingAmounts = amounts.sort(sortDescending);
  // select the smallest and largest segments
  // ex. range = 3, amounts = [10, 20, 30, 40, 50]
  // -> minRange = [10, 20, 30]
  // -> maxRange = [30, 40, 50]
  const maxRange = descendingAmounts.slice(0, range);
  const minRange = descendingAmounts.slice(-range);

  // sum those smallest and largest segments
  const maxAmount = maxRange.reduce(sumArray);
  const minAmount = minRange.reduce(sumArray);

  return { low: minAmount, high: maxAmount };
};
