import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

export const IMPRESSUM_PATH = '/impressum';

const Routes = [
  <Route
    path={IMPRESSUM_PATH}
    key="impressum-page"
    exact
    strict
    component={asyncComponent(
      () => import('./index' /* webpackChunkName: "DE ImpressumPage" */),
    )}
  />,
];

export default Routes;
