import { createMatchSelector, LOCATION_CHANGE } from 'connected-react-router';
import type { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { DomainError } from '@peloton/domain-error';
import { getIsProdEnv } from '@peloton/env';
import { reportError } from '@peloton/error-reporting';
import { toCountry } from '@peloton/internationalize';
import { fetchPressHeadlines, fetchPressQuotes } from './api';
import {
  pressHeadlinesRequest,
  pressHeadlinesSuccess,
  pressQuotesRequest,
  pressQuotesSuccess,
} from './redux';

const fetchPressSaga = function* (): SagaIterator {
  yield put(pressHeadlinesRequest());

  try {
    const countryFilter = toCountry();
    const isProd = yield select(getIsProdEnv);
    const items = yield call(fetchPressHeadlines, { countryFilter, isProd });
    yield put(pressHeadlinesSuccess(items));
  } catch (e) {
    const error = new DomainError('Error fetching press headlines', e);
    yield put(reportError({ error }));
  }
};

const fetchPressQuotesSaga = function* (): SagaIterator {
  yield put(pressQuotesRequest());

  try {
    const isProd = yield select(getIsProdEnv);
    const items = yield call(fetchPressQuotes, { countryFilter: toCountry(), isProd });
    yield put(pressQuotesSuccess(items));
  } catch (e) {
    const error = new DomainError('Error fetching press quotes', e);
    yield put(reportError({ error }));
  }
};

const getPressRoute = createMatchSelector('/press');
const getPressInternationalRoute = createMatchSelector('/:locale/press');

const checkRoute = function* (): SagaIterator {
  const pressMatch = yield select(getPressRoute);
  const i18npressMatch = yield select(getPressInternationalRoute);

  if (pressMatch || i18npressMatch) {
    yield all([call(fetchPressSaga), call(fetchPressQuotesSaga)]);
  }
};

const saga = function* () {
  yield takeLatest(LOCATION_CHANGE, checkRoute);
};

export default saga;
