import type { Exception } from '@ecomm/exceptions';
import { toException } from '@ecomm/exceptions';
import type { ItemType } from '../models';

export enum ActionType {
  RemoveItem = 'ecomm/cart/removeItem/REQUEST',
  RemoveItemFailed = 'ecomm/cart/removeItem/REQUEST_FAILED',
  RemoveItemSucceeded = 'ecomm/cart/removeItem/REQUEST_SUCCEEDED',
  CartLoading = 'ecomm/cart/removeItem/CART_LOADING',
  CartReady = 'ecomm/cart/removeItem/CART_READY',
}

export type RemoveItemAction = {
  type: ActionType.RemoveItem;
  id: string;
  itemType: ItemType;
  quantity: number;
};

export const removeItem = (
  id: string,
  itemType: ItemType,
  quantity: number = 1,
): RemoveItemAction => ({
  type: ActionType.RemoveItem,
  id,
  itemType,
  quantity,
});

export type RemoveItemSuccessAction = {
  type: ActionType.RemoveItemSucceeded;
};

export const removeItemSucceeded = (): RemoveItemSuccessAction => ({
  type: ActionType.RemoveItemSucceeded,
});

export type RemoveItemFailedAction = {
  type: ActionType.RemoveItemFailed;
  payload: { exception: Exception };
};

export const removeItemFailed = (errorId: string): RemoveItemFailedAction => ({
  type: ActionType.RemoveItemFailed,
  payload: { exception: toException(errorId) },
});

export type CartLoadingAction = {
  type: ActionType.CartLoading;
};

export const cartLoading = () => ({
  type: ActionType.CartLoading,
});

export type CartReadyAction = {
  type: ActionType.CartReady;
};

export const cartReady = () => ({
  type: ActionType.CartReady,
});

export type Action =
  | RemoveItemAction
  | RemoveItemSuccessAction
  | RemoveItemFailedAction
  | CartLoadingAction
  | CartReadyAction;
