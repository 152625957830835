import { black, white } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import styled from 'styled-components';
import { zIndex } from '@ecomm/header/constants';

export const Menu = styled.div`
  position: absolute;
  top: calc(100% - 4px); /* height of the Utilities menu + offset for caret*/
  background-color: ${white};
  border-radius: 3px;
  box-shadow: 0 30px 70px 0 ${rgba(black, 0.11)};
  z-index: ${zIndex.HEADER};
`;
