import React from 'react';
import { Links } from '@peloton/links';
import { Page } from '@ecomm/layout';
import { MetadataTags } from '@ecomm/metadata';
import Homepage from './Homepage';

const Handler: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Page transparent={true} isPageOptimizedForLazyLoadingContentBelowTheFold={true}>
    <MetadataTags id="home" canonicalLink={Links.peloton} />
    <Homepage />
  </Page>
);

export default Handler;
