export enum ActionTypes {
  Init = 'ecomm/app/@@INIT',
}

export const appInit = (): AppInit => ({
  type: ActionTypes.Init,
});

type AppInit = {
  type: ActionTypes.Init;
};
