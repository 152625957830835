import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { getKeyFromDigitalSku, useActiveDigitalPromotion } from '@ecomm/cms-digital';
import type {
  Headband as HeadbandKey,
  Product as ProductCopy,
  KeyValue,
} from '@ecomm/copy';
import {
  getAppPageRedesign,
  getIsFinancingLDComboVariation2Active,
} from '@ecomm/feature-toggle';
import { useRFCPSSubNav } from '@ecomm/feature-toggle/RFCPSSubNav';
import { useProductStates, Product } from '@ecomm/product-states';
import { usePrograms } from '@ecomm/programs';
import { InternationalSwitch } from '@ecomm/router/i18n/InternationalSwitch';
import Headband from './Headband';

type HeadbandProps = {
  cmsId: HeadbandKey | null;
  disclaimerTextId?: KeyValue;
  productInProjectPhoenix?: boolean;
};

export const productToHeadbandId = (
  areBothBikesAvailable: boolean,
  areBothTreadsAvailable: boolean,
  product: string,
  isTreadAvailable: boolean,
  treadShopWithLeadCapture: boolean,
  digitalPromoSku?: ProductCopy,
  showOnlyFinancing: boolean = false,
  ...rest: boolean[]
): HeadbandProps => {
  const [presale, rfcpsSubNavTest, sale] = rest;
  switch (product) {
    case 'bike':
      if (showOnlyFinancing) {
        return {
          cmsId: 'nav.headband.bike.FinancingVsOutrightPriceDisplay',
          productInProjectPhoenix: true,
        };
      }

      return { cmsId: 'nav.headband.bike', productInProjectPhoenix: true };
    case 'bike-plus':
      return { cmsId: 'nav.headband.bikePlus', productInProjectPhoenix: true };
    case 'treads':
      return { cmsId: areBothTreadsAvailable ? 'nav.headband.treads' : null };
    case 'row':
      return { cmsId: 'nav.headband.row', productInProjectPhoenix: true };
    case 'tread':
      if (isTreadAvailable) {
        return {
          cmsId: 'nav.headband.tread',
          productInProjectPhoenix: true,
        };
      }

      if (treadShopWithLeadCapture) {
        // this case doesn't use any pricing info so project phoenix doesn't apply
        return {
          cmsId: 'nav.headband.treadLeadCapture',
        };
      }

      return {
        cmsId: 'nav.headband.treadPreCart',
        productInProjectPhoenix: true,
      };
    case 'tread-plus':
      return { cmsId: 'nav.headband.treadPlus' };
    case 'app':
      return digitalPromoSku
        ? {
            cmsId: getKeyFromDigitalSku('nav.headband.app', digitalPromoSku),
            disclaimerTextId: getKeyFromDigitalSku(
              'nav.headband.app.disclaimer',
              digitalPromoSku,
            ),
          }
        : {
            cmsId: 'nav.headband.app',
            disclaimerTextId: 'nav.headband.app.disclaimer',
          };
    case 'classes':
      return {
        cmsId: getKeyFromDigitalSku('classes.headband', digitalPromoSku),
        disclaimerTextId: getKeyFromDigitalSku(
          'classes.headband.disclaimer',
          digitalPromoSku,
        ),
      };
  }

  if (product === 'guide') {
    if (rest?.length) {
      if (presale) {
        if (rfcpsSubNavTest) {
          return { cmsId: 'nav.headband.rainforestCafe.unavailable.shop.leadCaptureB' };
        } else {
          return { cmsId: 'nav.headband.rainforestCafe.unavailable.shop.leadCapture' };
        }
      } else if (sale) {
        return {
          cmsId: 'nav.headband.rainforestCafe.available',
          disclaimerTextId: 'nav.headband.rainforestCafe.disclaimer',
        };
      }
    }
  }

  return { cmsId: null };
};

const SwitchHeadband: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    isProductAvailableForShopLeadCapture,
    isProductAvailableForPurchase,
    isProductAvailableForMarketingPages,
  } = useProductStates();

  const digitalPromoSku = useActiveDigitalPromotion();

  const isTreadAvailable = isProductAvailableForPurchase(Product.Tread);
  const { tradeIn: isTradeInActive } = usePrograms();

  const treadShopWithLeadCapture = isProductAvailableForShopLeadCapture(Product.Tread);

  const areBothBikesAvailable =
    isProductAvailableForMarketingPages(Product.Bike) &&
    isProductAvailableForMarketingPages(Product.BikePlus);

  const areBothTreadsAvailable =
    isProductAvailableForMarketingPages(Product.Tread) &&
    isProductAvailableForMarketingPages(Product.TreadPlus);

  const showOnlyFinancing = useSelector(getIsFinancingLDComboVariation2Active);
  const rfcps = isProductAvailableForShopLeadCapture(Product.RainforestCafe);
  const rfcpsSubNavTest = useSelector(useRFCPSSubNav);
  const isPageRedesignVariation = useSelector(getAppPageRedesign);
  const appRoute = isPageRedesignVariation ? '/app' : '';
  const rfc = isProductAvailableForPurchase(Product.RainforestCafe);

  return (
    <InternationalSwitch>
      <Route
        path="/bikes/trade-in"
        render={() =>
          isTradeInActive ? (
            <Headband
              product="bike-plus"
              cmsId="nav.headband.bikesTradeIn"
              productInProjectPhoenix={true}
            />
          ) : null
        }
      />
      <Route
        path={[
          '/bikes/sign-up',
          '/bike/sign-up',
          '/bike/promo',
          '/bike/rentals',
          '/tread/promo',
          '/row/why-row',
          '/row/specs',
          '/treads/specs',
        ]}
        render={() => null}
      />
      <Route
        path={['/bike', '/bike-plus', '/tread', '/row', appRoute, '/guide']}
        exact
        render={() => null}
      />
      <Route
        path="/:product"
        render={({ match }) => {
          const product = match.params.product;
          const {
            cmsId,
            disclaimerTextId,
            productInProjectPhoenix,
          } = productToHeadbandId(
            areBothBikesAvailable,
            areBothTreadsAvailable,
            product,
            isTreadAvailable,
            treadShopWithLeadCapture,
            digitalPromoSku,
            showOnlyFinancing,
            rfcps,
            rfcpsSubNavTest,
            rfc,
          );

          return (
            cmsId && (
              <Headband
                cmsId={cmsId}
                product={product}
                disclaimerTextId={disclaimerTextId}
                productInProjectPhoenix={productInProjectPhoenix}
              />
            )
          );
        }}
      />
    </InternationalSwitch>
  );
};

export default SwitchHeadband;
