import React, { useEffect } from 'react';
import type { FC } from 'react';
import { useSplitTestingContext } from '@peloton/split-testing/hooks';
import { useToggles } from '@ecomm/feature-toggle/context/ToggleContext';
import reporterClient from './datadogReporterClient';
import { useDatadogFeatureFlags } from './useDatadogFeatureFlags';

const DatadogReporter: FC<React.PropsWithChildren<unknown>> = () => {
  const toggles = useToggles();
  const { experiments } = useSplitTestingContext();

  useEffect(() => {
    const featureFlags = {};

    for (const key in toggles) {
      featureFlags[key] = toggles[key].active;
    }

    reporterClient.init();
    reporterClient.addFeatureFlags(featureFlags);
  }, [toggles]);

  useEffect(() => {
    reporterClient.init();
    reporterClient.addFeatureFlags(experiments || {});
  }, [experiments]);

  return null;
};

export const DatadogReporterTracker: FC<React.PropsWithChildren<unknown>> = ({
  ...props
}) => {
  const datadogFeatureFlags = useDatadogFeatureFlags();

  return datadogFeatureFlags ? <DatadogReporter {...props} /> : null;
};
