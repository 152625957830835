import type { Entities } from '@ecomm/shop/models';
import type { PurchasedBundle } from '../models';

export enum ActionType {
  Add = 'ecomm/order/ADD_PURCHASED_BUNDLE',
}

export type State = Entities<PurchasedBundle>;

export const defaultState = {};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.Add:
      return {
        ...state,
        ...action.payload.purchasedBundles,
      };
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  purchasedBundles: State;
};

export const addPurchasedBundles = (
  purchasedBundles: Entities<PurchasedBundle>,
): AddPurchasedBundlesAction => ({
  type: ActionType.Add,
  payload: { purchasedBundles },
});

type AddPurchasedBundlesAction = {
  type: ActionType.Add;
  payload: { purchasedBundles: Entities<PurchasedBundle> };
};

type Action = AddPurchasedBundlesAction;
