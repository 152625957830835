import { brand, grey } from '@pelotoncycle/design-system';
import { css } from 'styled-components';
import type { LabelProps } from '@peloton/forms';
import { disabledGray, snowWhite, superRed as errorColor, white } from '@ecomm/colors';
import { b4, b6, b7, bodyFamily, hb6, reg } from '@ecomm/typography';

const inputBorder = `1px solid ${grey[40]}`;

// This is half of the extra padding added by line height.
// 14px (size) * 1.5 (line height) - 14px = 7px of extra space. Divided by 2 ~ 3
const FAUX_LABEL_LINE_HEIGHT = '3px';

const base = css`
  ${b4}
  ${reg}
  background: ${white};
  border: ${inputBorder};
  border-radius: 2px;
  color: ${brand.darkest};
  height: 46px;
  padding: 0 12px;
  width: 100%;
`;

const active = css`
  border-color: ${brand.darkest};
`;

const placeholder = css`
  color: ${grey[70]};
`;

const disabled = css`
  background: ${snowWhite};
  border-color: ${grey[40]};
  color: ${grey[70]};
`;

const error = css`
  border-color: ${errorColor};
`;

export const inputStyles = {
  base,
  active,
  placeholder,
  disabled,
  error,
};

export const errorStyles = css`
  ${b6}
  ${reg}
  background: ${errorColor};
  color: ${white};
  display: inline-block;
  padding: 4px 10px;
  margin-top: 5px;
`;

// The odd color and text-shadow combo is a little hack to keep firefox from
// rendering a little dotted outline around the select. The color of the outline
// is determined by the font color.
export const selectStyles = {
  ...inputStyles,
  base: css`
    ${base}
    padding-top: 16px;
    appearance: none;

    &[data-value=''] {
      color: ${grey[70]};
      color: transparent;
      text-shadow: 0 0 0 ${grey[70]};
    }

    option {
      color: ${grey[70]};
    }

    color: ${brand.darkest};
    color: transparent;
    text-shadow: 0 0 0 ${brand.darkest};

    &::-ms-expand {
      display: none;
    }
  `,
  error: css`
    ${error}

    &[data-user-focused="true"] + div {
      ${error}
    }

    &[data-user-focused='true'] + div > svg {
      fill: ${errorColor};
    }

    &[data-has-error='true'] + div {
      ${error}
    }

    &[data-has-error='true'] + div > svg {
      fill: ${errorColor};
    }
  `,
  active: css`
    ${active}

    &[data-user-focused="true"] + div {
      ${active}
    }

    &[data-user-focused='true'] + div > svg {
      fill: ${brand.darkest};
    }

    &::-ms-value {
      background: none;
      color: ${brand.darkest};
    }
  `,
  disabled: css`
    ${disabled}

    color: ${disabledGray};
    color: transparent;
    text-shadow: 0 0 0 ${disabledGray};
    cursor: default;

    &::-ms-value {
      color: ${disabledGray};
    }
  `,
};

export const selectContainerStyles = css`
  cursor: pointer;
  position: relative;
`;

export const selectArrowStyles = css`
  align-items: center;
  bottom: 0;
  border-left: ${inputBorder};
  display: flex;
  height: 46px;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 42px;
  background-color: ${white};
  border: 1px solid ${grey[40]};

  svg {
    fill: ${grey[70]};
  }
`;

const getFontStyle = ({ isActive, hasText }: LabelProps) =>
  isActive || hasText ? b7 : b4;

const getPosition = ({ isActive, hasText }: LabelProps) =>
  isActive || hasText
    ? 'transform: translate(12px, 5px);'
    : `transform: translateX(12px) translateY(50%) translateY(${FAUX_LABEL_LINE_HEIGHT});`;

export const labelStyles = css`
  ${getFontStyle}
  ${reg}
  ${getPosition}
  color: ${grey[70]};
  position: absolute;
  transition: all 150ms ease;
  user-select: none;
`;

export const topLabelStyles = css`
  ${hb6}
  margin-bottom: 10px;
  display: inline-block;
`;

export const cardStyleOptions = {
  style: {
    base: {
      color: brand.darkest,
      fontFamily: bodyFamily,
      fontSize: '14px',
      fontSmoothing: 'antialiased',
      fontWeight: 400,
      letterSpacing: '0.025em',
      lineHeight: '30px',
      '::placeholder': {
        color: 'transparent',
      },
    },
    invalid: {
      color: brand.darkest,
    },
  },
};
