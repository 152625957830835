import { combineReducers } from 'redux';
import type { ReducerState as ClassBookmarsReducerState } from './classBookmarks';
import classBookmarks from './classBookmarks';
import type { ReducerState as UsernameReducerState } from './username';
import username from './username';

export type State = ClassBookmarsReducerState & UsernameReducerState;

export default combineReducers({
  classBookmarks,
  username,
});

export type ReducerState = {
  userProfile: State;
};
