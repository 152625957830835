import { grey } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import asyncComponent from '@peloton/code-splitting/asyncComponent';
import { theUserIsInAustria } from '@peloton/internationalize';
import { useLocale } from '@peloton/next/hooks/useLocale';
import type { ProductNavData, ProductDetailsData } from '@ecomm/copy/helpers/types';
import useFilteredProductCards from '../hooks/useFilteredProductCards';
import CtaSection from './CtaSection';
import Header from './Header';
import type { CloseHandlers } from './NavFlyout';
import PrimaryLinks from './PrimaryLinks';
import SecondaryLinks from './SecondaryLinks';

const NavFlyout = asyncComponent(
  () => import(/* webpackChunkName: "ModalChunk", webpackPrefetch: true */ './NavFlyout'),
);

type Props = {
  isOpen: boolean;
  onCloseRequest: () => void;
  onBackRequest: () => void;
  data: Pick<ProductNavData, 'key' | 'title' | 'products' | 'secondaryLinks'>;
};

const SecondaryMenuFlyout: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onCloseRequest,
  onBackRequest,
  data: { key, title, products, secondaryLinks },
}) => {
  const filteredProducts: ProductDetailsData[] = useFilteredProductCards(products);
  const locale = useLocale();

  return (
    <NavFlyout
      isOpen={isOpen}
      ariaLabel="navigation"
      backgroundColor={grey[90]}
      handleRequestClose={onCloseRequest}
      handleRequestBack={onBackRequest}
    >
      {({ handleRequestClose, handleRequestBack }: CloseHandlers) => (
        <Container>
          <Header
            heading={title}
            handleClose={handleRequestClose}
            handleBack={handleRequestBack}
          />
          <Nav>
            <PrimaryLinks
              categoryId={key}
              products={filteredProducts}
              handleClose={onCloseRequest}
            />
            (
            <CtaSection
              categoryId={key}
              products={filteredProducts}
              handleClose={onCloseRequest}
            />
            )
            {secondaryLinks && !theUserIsInAustria(locale) && (
              <>
                <Divider aria-hidden="true" />
                <SecondaryLinks
                  categoryId={key}
                  secondaryLinks={secondaryLinks}
                  handleClose={onCloseRequest}
                  className="nav-panel-item"
                />
              </>
            )}
          </Nav>
        </Container>
      )}
    </NavFlyout>
  );
};

export default SecondaryMenuFlyout;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Divider = styled.hr`
  background-color: ${grey[60]};
  border: none;
  height: 1px;
  width: 100%;

  &.nav-panel-item {
    opacity: 0;
  }
`;

const Nav = styled.nav`
  padding: 0 1.5rem 3rem;
`;
