import { useMemo } from 'react';
import {
  useCtaWithUrlData as useCtaWithUrlDataEcomm,
  useKeyValueData as useKeyValueDataEcomm,
  useSectionData as useSectionDataEcomm,
} from '@ecomm/copy';

type AccountCopy = {
  useCtaWithUrlData: any;
  useKeyValueData: any;
  useSectionData: any;
};

/** This will be replaced with page specific geoModal in Next.
    This is the least difficult pathway in CRA. */
export const useCRAGeoModalCopy = (accountCopy?: AccountCopy) => {
  let useSectionData = useSectionDataEcomm;
  let useKeyValueData = useKeyValueDataEcomm;
  let useCtaWithUrlData = useCtaWithUrlDataEcomm;
  if (accountCopy) {
    useSectionData = accountCopy.useSectionData;
    useKeyValueData = accountCopy.useKeyValueData;
    useCtaWithUrlData = accountCopy.useCtaWithUrlData;
  }
  const geoModalV2 = useSectionData('geoModalV2');
  const geoModalV2Alt = useSectionData('geoModalV2Alt');
  const currencyCopy = useKeyValueData('geoModalV2.currency');
  const selectItemLabel = useKeyValueData('geo.modal.select.item');
  const updatePreferences = useCtaWithUrlData('geoModalV2.updatePreferences');

  const value = useMemo(() => {
    return {
      geoModalV2,
      geoModalV2Alt,
      currencyCopy,
      selectItemLabel,
      updatePreferences,
    };
  }, [geoModalV2, geoModalV2Alt, currencyCopy, selectItemLabel, updatePreferences]);

  return value;
};
