import { identity } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeData, pipeError, throwError } from '@peloton/api';
import type { ErrorCode } from './utils';
import { toErrorCode } from './utils';

const toApplyBenefitUrl = () => '/api/corporate-wellness/apply-benefit';

export enum NextStepAction {
  Purchase = 'purchase',
}

export const applyBenefit = (api: Client, token: string) =>
  api
    .post(toApplyBenefitUrl(), { benefit_hub_access_token: token })
    .then(pipeData(identity))
    .catch(pipeError(throwError<ErrorCode>(toErrorCode)));
