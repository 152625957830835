import type { Locale } from '@peloton/internationalize/models/locale';
import { toLocaleFromTLD } from '@peloton/internationalize/models/locale';
import type { Programs } from './models';

const programs = require('./programs.json');

export const getContentfulPrograms = (locale: Locale): Programs => {
  return (programs as Record<Locale, Programs>)[locale];
};

export const useContentfulProgramsByLocale = (): Programs => {
  const locale = toLocaleFromTLD();
  return getContentfulPrograms(locale);
};
