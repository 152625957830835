import React, { useContext } from 'react';
import CmsHeroOrCarousel from '@ecomm/cms-hero-carousel';
import { PromoContext } from '@ecomm/cms-promos';
import { usePromoHeroSetData } from '@ecomm/copy';

const HomepageHeroCarousel: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { homepageHeroId: promoHeroSetId, isResolved } = useContext(PromoContext);

  const heroCmsIds = usePromoHeroSetData(promoHeroSetId || 'dualTitanPrismSet');

  return <CmsHeroOrCarousel cmsIds={isResolved ? heroCmsIds : []} />;
};

export default HomepageHeroCarousel;
