import { hasVariation } from '@peloton/split-testing';

const FINANCING_LEAD_CAPTURE_EXPERIMENT = 'Financing HT Lead Capture Modal';
const VARIATION_1 = 'Variation #1';
const VARIATION_2 = 'Variation #2';

export const showBikeModalFinancing = hasVariation(
  FINANCING_LEAD_CAPTURE_EXPERIMENT,
  'Original',
);

export const showBikeModalHomeTrial = hasVariation(
  FINANCING_LEAD_CAPTURE_EXPERIMENT,
  VARIATION_1,
);
export const showBikeModalFinancingHomeTrial = hasVariation(
  FINANCING_LEAD_CAPTURE_EXPERIMENT,
  VARIATION_2,
);
