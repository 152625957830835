import { always, cond } from 'ramda';
import { connect } from 'react-redux';
import { toCountry } from '@peloton/internationalize';
import type { RoutingState } from '@peloton/redux';
import { getLocation } from '@peloton/redux';

type TestingInfo = {
  page: string;
  section: string;
};

export type EmailCaptureProps = {
  productInterest?: string;
  testingInfo?: TestingInfo;
};

const isBikeRoute = (path = ''): boolean => path.includes('bike');
const isBikePlusRoute = (path = ''): boolean => path.includes('bike-plus');
const isTreadRoute = (path = ''): boolean => path.includes('tread');
const isGuideRoute = (path = ''): boolean => path.includes('guide');
const isRowRoute = (path = ''): boolean => path.includes('row');
const isDigitalRoute = (path = ''): boolean => path.includes('digital');

const withEmailCaptureData = (state: RoutingState, ownProps: EmailCaptureProps) => {
  const path = getLocation(state).pathname;

  return {
    country: toCountry(),
    path: path,
    productInterest:
      ownProps.productInterest ??
      cond([
        // bike-plus needs to come first or "bike" will fire instead due to partial path match
        [isBikePlusRoute, always('bike-plus')],
        [isBikeRoute, always('bike')],
        [isTreadRoute, always('tread')],
        [isGuideRoute, always('guide')],
        [isRowRoute, always('row')],
        [isDigitalRoute, always('digital')],
        [() => true, always('')],
      ])(path),
  };
};

export default connect(withEmailCaptureData);
