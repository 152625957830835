export enum Term {
  ThirtySix = 36,
  FortyThree = 43,
}

export const maxTerm = (isZipThirtySixMonthsFinancingEnabled: boolean) =>
  isZipThirtySixMonthsFinancingEnabled ? Term.ThirtySix : Term.FortyThree;

export const TERMS: Record<string, Term> = {
  '36mo-financing': Term.ThirtySix,
  '43mo-financing': Term.FortyThree,
};

export const getTerm = (slug: string) => TERMS[slug];
