import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { downloadApp } from '@peloton/links/wwwPaths';
import { Route } from '@ecomm/router';

const route = (
  <Route
    exact
    key={downloadApp}
    path={downloadApp}
    component={asyncComponent(
      () => import('./Handler' /* webpackChunkName: "Digital" */),
    )}
  />
);

export default route;
