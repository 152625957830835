import React from 'react';
import FormatText from '../FormatText';
import { useCtaWithUrlData } from '../helpers';
import type { CtaWithUrl } from '../models.generated';
import type linkList from './linkList';

type AcceptedLinks = keyof typeof linkList;

export type CtaWithUrlData = {
  text: string;
  url: AcceptedLinks;
};

type Props = {
  id: CtaWithUrl;
  values?: Record<string, React.ReactNode>;
};

const FormatCtaWithUrl: React.FC<React.PropsWithChildren<Props>> = ({ id, values }) => {
  const content = useCtaWithUrlData(id);

  return <FormatText content={content.text} values={values} />;
};

export default FormatCtaWithUrl;
