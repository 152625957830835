import { useCallback, useEffect, useState } from 'react';
import type { Financing } from '@ecomm/graphql/types.generated';
import type { QueryFinancingPerLocaleQuery } from '../graphql/QueryFinancingPerLocale.generated';
import { useGetFinancingAvailabilityQuery } from '../graphql/useGetFinancingAvailabilityQuery';

import { BundleType } from '../models';

export type FinancingAvailabilityState = Record<BundleType, boolean>;

type OptionalFinancingAvailabilityState = Partial<FinancingAvailabilityState>;

type BundleFinancingReturnType = {
  financingState: FinancingAvailabilityState | null;
  financingByBundle?: boolean;
};

type FinancingKeys = keyof Omit<Financing, '__typename'>;

export const financingPerLocaleToBundleTypeMap: Record<FinancingKeys, BundleType> = {
  row: BundleType.Row,
  bike: BundleType.Bike,
  bikePlus: BundleType.BikePlus,
  tread: BundleType.Tread,
  treadPlus: BundleType.TreadPlus,
  guide: BundleType.RainforestCafe,
  refurbishedBike: BundleType.RefurbishedBike,
  refurbishedBikePlus: BundleType.RefurbishedBikePlus,
};

export const financingToBundleType = (data: QueryFinancingPerLocaleQuery) => {
  const bundleFinancing = {};
  for (const financingKey in data?.financingPerLocale) {
    const key = financingPerLocaleToBundleTypeMap[financingKey];
    if (key) {
      bundleFinancing[key] = data?.financingPerLocale[financingKey];
    }
  }
  return bundleFinancing;
};

export const addMissingBundles = (
  financingState: OptionalFinancingAvailabilityState,
): FinancingAvailabilityState => {
  const financingDefaultStatus = false;
  const allFinancingKeys = Object.values(BundleType).reduce(
    (acc, curr) => ({ ...acc, [curr]: financingDefaultStatus }),
    {},
  ) as FinancingAvailabilityState;

  return {
    ...allFinancingKeys,
    ...financingState,
  };
};

const useBundleFinancing = (bundle?: BundleType): BundleFinancingReturnType => {
  const [financing, setFinancing] = useState<FinancingAvailabilityState | null>(null);
  const financingAvailabilityQuery = useGetFinancingAvailabilityQuery();

  const getFinancing = useCallback(async () => {
    try {
      const data = await financingAvailabilityQuery();
      setFinancing(addMissingBundles(financingToBundleType(data)));
    } catch (e) {
      console.error(e);
    }
  }, [financingAvailabilityQuery]);

  useEffect(() => {
    getFinancing();
  }, [getFinancing]);

  const financingByBundle = bundle ? financing?.[bundle] : undefined;

  return {
    financingState: financing,
    financingByBundle,
  };
};

export default useBundleFinancing;
