import {
  complement,
  filter,
  find,
  isEmpty,
  pipe,
  prop,
  propEq,
  sortBy,
  unnest,
  values,
} from 'ramda';
import type { Reducer } from 'redux';
import { createSelector } from 'reselect';
import { ProductLine } from '@ecomm/shop/models';
import type { Warranties, Warranty, WarrantyProductLine } from './models';
import { isExtendedWarranty } from './models';

export enum Actions {
  LoadWarranties = 'pelo/warranty/LOAD',
  LoadWarrantiesSuccess = 'pelo/warranty/LOAD_SUCCESS',
}

const defaultState: State = {
  entities: {
    [ProductLine.Bike]: [],
    [ProductLine.Tread]: [],
    [ProductLine.StoneOcean]: [], // TODO: completely replace with BikePlus when possible
    [ProductLine.TreadPlus]: [],
    [ProductLine.BikePlus]: [],
    [ProductLine.RainforestCafe]: [],
  } as any,
};

type State = {
  entities: Warranties;
};

export type ReducerState = {
  warranties: State;
};

export const reducer: Reducer<State> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case Actions.LoadWarrantiesSuccess:
      return {
        ...state,
        entities: { ...state.entities, [action.productLine]: action.warranties },
      };
    default:
      return state;
  }
};

type Action = LoadWarranties | LoadWarrantiesSuccess;

export type LoadWarranties = {
  type: Actions.LoadWarranties;
  productLine: WarrantyProductLine;
};

type LoadWarrantiesSuccess = {
  type: Actions.LoadWarrantiesSuccess;
  productLine: ProductLine;
  warranties: Array<Warranty>;
};

export const loadWarrantiesSuccess = (
  productLine: ProductLine,
  warranties: Warranty[],
) => ({
  type: Actions.LoadWarrantiesSuccess,
  productLine,
  warranties,
});

export const loadBikeWarranties = () => loadWarranties(ProductLine.Bike);

export const loadTreadWarranties = () => loadWarranties(ProductLine.Tread);

export const loadBikePlusWarranties = () => loadWarranties(ProductLine.BikePlus);

export const loadTreadPlusWarranties = () => loadWarranties(ProductLine.TreadPlus);

export const loadGuideWarranties = () => loadWarranties(ProductLine.RainforestCafe);

export const loadRowWarranties = () => loadWarranties(ProductLine.Row);

export const loadRefurbBikePlusWarranties = () =>
  loadWarranties(ProductLine.RefurbBikePlus);

export const loadRefurbBikeWarranties = () => loadWarranties(ProductLine.RefurbBike);

export const loadWarranties = (productLine: WarrantyProductLine) => ({
  type: Actions.LoadWarranties,
  productLine,
});

export const getWarranties = (state: ReducerState) => state.warranties.entities;

export const getWarrantiesByProductLine = (
  state: ReducerState,
  props: { productLine: ProductLine },
) => state.warranties.entities[props.productLine] ?? [];

export const getAllWarranties = createSelector(
  getWarranties,
  (allWarranties: Warranties): Warranty[] => unnest(values(allWarranties)),
);

export const getWarrantyById = (state: ReducerState, props: { id: string }) =>
  pipe(values, unnest, find(propEq('id', props.id)))(getWarranties(state));

export const getUpsellWarrantiesForProduct = createSelector(
  [getWarrantiesByProductLine],
  pipe<Warranty[], Warranty[], Warranty[]>(
    filter(isExtendedWarranty),
    sortBy(prop('priceInCents')),
  ),
);

export const hasUpsellWarrantiesForProduct = createSelector(
  [getUpsellWarrantiesForProduct],
  complement(isEmpty),
);
