import { track } from '@peloton/analytics';
import type { getUserTrackingProperties } from '@ecomm/auth';
import type { Audience } from '@onewellness/routes';

export type UserTrackingEligibilityProps = {
  audience: Audience;
  userTrackingProperties: ReturnType<typeof getUserTrackingProperties>;
};

export const trackUsernameUpdated = ({
  audience,
  userTrackingProperties,
}: UserTrackingEligibilityProps) =>
  track({
    event: 'Eligibility Username Updated',
    properties: {
      audience,
      pageName: window.location.pathname,
      ...userTrackingProperties,
    },
  });
