import { useEffect, useState } from 'react';
import type { LatLng } from './../showroomUtils';

export function useIpGeolocation(isEnabled: boolean) {
  const [position, setPosition] = useState<LatLng | null>(null);

  useEffect(() => {
    // Resolve geolocation coordinates if already available
    const { __promiseHooks } = window as any;
    const location = __promiseHooks.geolocationCoordinates;
    if (location) {
      setPosition({
        latitude: location.lat,
        longitude: location.lng,
      });
    } else {
      // Fetch coordinates if not available
      const fetchGeo = async () => {
        const json = await __promiseHooks.geolocationPromise;

        if (json) {
          const { location: coordinates } = json;
          setPosition({ latitude: coordinates.lat, longitude: coordinates.lng });
        }
      };

      fetchGeo();
    }
  }, [setPosition]);
  if (!isEnabled) {
    return { position: null };
  }
  return { position };
}
