import useSWR from 'swr';
import { ENABLE_NEBULA_CT_PRICING_ENDPOINTS } from '@peloton/app-config';
import type { Locale } from '@peloton/internationalize/models/locale';
import { fetchBuilderCtPricing } from '@content/client/lib/fetchBuilderCtPricing';
import type { ProductCatalogType } from '@ecomm/commercetools/models/main';
import { fetchCtBundles } from '@ecomm/commercetools/pricing/fetchBundleData';
import { localizedBasicPackagesMap } from '@ecomm/shop/localizedBasicPackagesMap';
import type { Packages } from '@ecomm/shop/localizedBasicPackagesMap';
import type { BundleType } from '@ecomm/shop/models/BundleType';

type ProductDetails = {
  price?: number;
};

export type ProductPackages = Record<BundleType, ProductDetails>;

export const defaultCtDetailsData: ProductDetails = {
  price: undefined,
};

const transformProductData = (bundle?: ProductCatalogType): ProductDetails => {
  if (!bundle) return defaultCtDetailsData;
  const price: number =
    bundle.masterData?.current?.masterVariant?.price?.value?.centAmount;
  return {
    price,
  };
};

/**
 * @param locale
 * @returns Parsed CT product price data or undefined
 * Currently being used on next-www, pelofax, and nebula (ctPricesHandler)
 * Ex: {
 *  [BundleType.Bike]: {
 *    price: 145000
 *  },
 *  [BundleType.Tread]: {
 *    price: 250000
 *  },
 *  ...
 * }
 */
export const fetchCtPricingData = async (
  locale: Locale,
): Promise<Partial<ProductPackages | undefined>> => {
  const basicsPackages: Packages = localizedBasicPackagesMap[locale];

  if (!basicsPackages) {
    console.warn(`No CT product slug specified in locale: ${locale}`);
    return undefined;
  }
  const products = await fetchCtBundles(basicsPackages, locale);

  // Clean up CT pricing response to mirror the shape of the legacy pricing data structure
  return Object.entries(basicsPackages).reduce((acc, bundle) => {
    const [bundleType, basicsPackageName] = bundle;
    const bundleKey = products?.find(product => product.key === basicsPackageName);
    const productData = transformProductData(bundleKey as ProductCatalogType);
    acc[bundleType] = productData;
    return acc;
  }, {});
};

export const useCtPrice = (bundleType: BundleType) => {
  const { data: ctPrices } = useSWR<ProductPackages>('ctPrices');

  if (!ctPrices || !ctPrices[bundleType]) {
    /**
     * No fallback data was provided to swr cache in next-www app
     * OR
     * There is no CT response for the given locale
     */
    return defaultCtDetailsData;
  }

  return ctPrices[bundleType];
};

export const ctPricingFetcher = ENABLE_NEBULA_CT_PRICING_ENDPOINTS
  ? fetchBuilderCtPricing
  : fetchCtPricingData;
