import { brand, Icon } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles/breakpoints';
import { l2 } from '@ecomm/links/links';

const keyIconMap = {
  ['footer.social.facebook']: 'facebook',
  ['footer.social.instagram']: 'instagram',
  ['footer.social.twitter']: 'twitter',
  ['footer.social.youtube']: 'youtube',
};

type CTAWithUrl = {
  key: string;
  text: string;
  url: string;
};

type Props = {
  key: string;
  ctas: CTAWithUrl[];
};

const SocialMediaLinks: React.FC<React.PropsWithChildren<Props>> = ({ ctas }) => (
  <Container>
    {ctas.map(({ key, text, url }: CTAWithUrl) => {
      return (
        <SocialLink key={text} href={url} label={text}>
          <Icon name={keyIconMap[key]} height={30} />
        </SocialLink>
      );
    })}
  </Container>
);

type LinkProps = {
  href: string;
  label: string;
};

const SocialLink: React.FC<React.PropsWithChildren<LinkProps>> = props => (
  <LinkItem>
    <StyledLink href={props.href} target="_blank" rel="noopener" aria-label={props.label}>
      {props.children}
    </StyledLink>
  </LinkItem>
);

export default SocialMediaLinks;

const Container = styled.ul`
  margin-top: 40px;

  ${media.smallScreen`
    margin-top: 22px;
  `}

  ${media.tablet`
    margin-top: 7px;
  `}

  ${media.desktopLarge`
    margin-top: 25px;
  `}
`;

const LinkItem = styled.li`
  display: inline-block;
`;

const StyledLink = styled.a`
  margin-right: 15px;
  display: block;

  &:hover svg path {
    ${l2()}
    fill: ${brand.primary};
    color: ${brand.primary};
  }
`;
