import type { ParsedUrlQuery } from 'querystring';
import type { RedirectLoginResult } from '@auth0/auth0-spa-js';
import React from 'react';
import { AuthEnv } from '@peloton/auth/authClient';
import { socialErrorCodesDictionary } from '@peloton/auth/constants';
import { addAuthParamsToV2ConfigSchema } from '@peloton/auth/oauthConfigSchema';
import POauthProvider from '@peloton/auth/OauthProvider';
import { toHostnameEnv } from '@peloton/env-hostnames/toHostnameEnv';
import type { UatHostnameEnv } from '@peloton/env-hostnames/toHostnameEnv';
import environmentFlags from '@peloton/env/environmentFlags';
import { useErrorReporter } from '@peloton/error-reporting';
import { useExtLinkEnv } from '@peloton/external-links/context/Provider';
import {
  ApiEnvs,
  toExtLinkEnv,
  LinkEnvs,
  safelyDecode,
} from '@peloton/external-links/models';
import toRedirectUrl from '@peloton/external-links/toRedirectUrl';
import {
  REDIRECT_URL_QUERY_PARAM,
  REDIRECT_APP_QUERY_PARAM,
} from '@peloton/external-links/urls';
import { useHistoryMethod } from '@peloton/hooks';
import { toAuth0Config } from '@ecomm/env/auth0';
import { CALLBACK, LOGIN, NEXT_LOGIN } from './urls';

const isDeployPreview = (hostname: string): boolean =>
  hostname.startsWith('deploy-preview');

export const decodeRedirectUrl = (record: ParsedUrlQuery) =>
  safelyDecode(record[REDIRECT_URL_QUERY_PARAM]) || '/';

export const toAppState = (redirectUrl: string, app: string = 'www') => ({
  [REDIRECT_URL_QUERY_PARAM]: btoa(redirectUrl),
  [REDIRECT_APP_QUERY_PARAM]: app,
});

export const getSocialErrorCopy = (errorMessage?: string) => {
  const [errorCode] = (errorMessage && errorMessage.match(new RegExp(/\d+/, 'i'))) ?? '';

  const hasSocialErrorCode = !!errorCode && socialErrorCodesDictionary[errorCode];

  const errorDescription = hasSocialErrorCode ? errorCode : '';

  return {
    errorDescription,
    shouldRedirect: hasSocialErrorCode,
  };
};

const useRedirectUrl = () => {
  const extLinkEnv = useExtLinkEnv();

  if (typeof window === 'undefined') {
    return () => '';
  }

  const envFlags = environmentFlags('www')();
  return (path: string, type: string = 'www') => {
    if (envFlags.isLocal) {
      const test = toRedirectUrl({
        extLinkEnv: toExtLinkEnv({ www: LinkEnvs.Local }),
        path,
        type,
      });

      return test;
    }
    const redirectUrl = toRedirectUrl({ extLinkEnv, path, type });
    const currentHostname =
      typeof window !== 'undefined' ? window?.location?.hostname : '';
    if (isDeployPreview(currentHostname)) {
      const hostNameEnv = toHostnameEnv(currentHostname) as UatHostnameEnv;
      if (hostNameEnv.urlApp) {
        return redirectUrl.replace(
          `${type}`,
          `${hostNameEnv.instance}--${hostNameEnv.urlApp}`,
        );
      }
      return redirectUrl.replace(`${type}`, `${hostNameEnv.instance}--${type}`);
    }

    return redirectUrl;
  };
};

export const useLoginUrl = () => {
  const redirectUrl = useRedirectUrl();
  return (redirectUrlParam: string) => redirectUrl(toLoginRoute(redirectUrlParam));
};

export const useNextLoginUrl = () => {
  const redirectUrl = useRedirectUrl();
  return (redirectUrlParam: string) => redirectUrl(toNextLoginRoute(redirectUrlParam));
};

const toAuthEnv = (apiEnv: ApiEnvs): AuthEnv => {
  if (apiEnv === ApiEnvs.Qa1) {
    return AuthEnv.Stage;
  }
  return AuthEnv.Prod;
};

const toNextLoginRoute = (redirectUrlParam: string) =>
  `${NEXT_LOGIN}?${REDIRECT_URL_QUERY_PARAM}=${btoa(redirectUrlParam)}`;

export const toLoginRoute = (redirectUrlParam: string) =>
  `${LOGIN}?${REDIRECT_URL_QUERY_PARAM}=${btoa(redirectUrlParam)}`;

const OauthProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const redirectUrl = useRedirectUrl();
  const { errorReporter } = useErrorReporter();
  const { replace } = useHistoryMethod();
  const extLinkEnv = useExtLinkEnv();
  const auth0Config = toAuth0Config(extLinkEnv.api);
  const redirectUri = { redirect_uri: redirectUrl(CALLBACK) };

  return (
    <POauthProvider
      {...addAuthParamsToV2ConfigSchema(auth0Config, redirectUri)}
      authEnv={toAuthEnv(extLinkEnv.api)}
      onRedirectCallback={async (state: RedirectLoginResult['appState']) => {
        await replace(
          `${window.location.pathname}?redirectUrl=${btoa(decodeRedirectUrl(state))}`,
        );
      }}
      onErrorCallback={(e, action) => {
        errorReporter.reportError(
          new Error(`OauthProvider | ${action} | ${e.toString()}`),
        );
      }}
      shouldUseRedirectPrevention
    >
      {children}
    </POauthProvider>
  );
};

export default OauthProvider;
