import { useEffect } from 'react';
import useGetFinancingPartner from '@ecomm/financing/hooks/useGetFinancingPartner';
import { Partners } from '../models';
import { addZipSDKToPage } from './api';

export const useZip = () => {
  const financingPartner = useGetFinancingPartner();
  useEffect(() => {
    const loadZip = async () => {
      const zipWidgetScripts = document.querySelectorAll('[id=zip-widget-script]');
      if (zipWidgetScripts.length === 0) await addZipSDKToPage();
    };

    if (financingPartner === Partners.Zip) {
      loadZip().catch(error => console.error(error));
    }
  }, [financingPartner]);
};
