import React, { useContext } from 'react';
import { useTracking } from 'react-tracking';
import { theUserIsInAustria } from '@peloton/internationalize';
import { useLocale } from '@peloton/next/hooks/useLocale';
import { capitalize } from '@peloton/text';
import HighlightedLinkItem from '@acme-ui/nav/ProductsMenu/HighlightedLinkItem';
import useNav from '@content/client/www/nav/useNav';
import type { ProductNav } from '@ecomm/copy/models.generated';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { HeaderNavContext } from '@ecomm/header/HeaderNavProvider';
import type { ProductsMenuItemRefs, UtilitiesMenuRefs } from '@ecomm/header/models';
import { Menu } from '@ecomm/header/models';
import { isActiveMenu, isCurrentMenu } from '@ecomm/header/utils';
import { useProductStates } from '@ecomm/product-states/Context';
import { Product } from '@ecomm/product-states/models/product';
import NestedLinksMenu from '../NestedLinksMenu/MegaMenu';
import LinkItem from './LinkItem';
import MegaMenu from './MegaMenu/MegaMenu';
import MenuItem from './MenuItem';

type Props = {
  cmsId: ProductNav;
  customSubnavName?: string;
  activeMenu: Menu;
  menuItemRefs: ProductsMenuItemRefs | UtilitiesMenuRefs;
  realignLastMegaMenu: boolean;
};

const MenuItemWithMegaMenu: React.FC<React.PropsWithChildren<Props>> = ({
  cmsId,
  customSubnavName,
  activeMenu,
  menuItemRefs,
  realignLastMegaMenu,
}) => {
  const locale = useLocale();

  const { currentMenu, setCurrentMenu, setFocusedMenu } = useContext(HeaderNavContext);
  const { content, isLoading } = useNav(locale);
  const { trackEvent } = useTracking();
  const { isProductAvailableForPurchaseOrSoldOut } = useProductStates();

  const isShowGiftCardMenu = useIsToggleActive()('showGiftCardMenu');
  const isGuideRemovedFromMainNav = useIsToggleActive()('removeGuideFromMainNav');
  const isDE3PTransitionActive = useIsToggleActive()('de3pTransition');
  const isGuideAvailable = isProductAvailableForPurchaseOrSoldOut(Product.RainforestCafe);

  if (isLoading) {
    return null;
  }

  const entry = content?.subnavs.find((subnav: any) => subnav.key === cmsId);

  if (!entry) {
    return null;
  }

  const { key, title, ...nav } = entry;

  const subnavName: string = customSubnavName || (key && key.split('.').pop());
  const menuItem = subnavName ? Menu[capitalize(subnavName)] : undefined;

  if (!menuItem) {
    return null;
  }

  if (isDE3PTransitionActive && cmsId === 'nav.accessories') {
    return null;
  }

  const onlyOneProduct = nav.products?.length === 1;
  const noSecondaryUrl = !nav.products?.[0]?.secondaryCta;
  const noSecondaryLinks = !nav.secondaryLinks;
  const onlyStrength = cmsId === 'nav.strength';
  const isAccessoriesExpandable =
    isShowGiftCardMenu || (isGuideAvailable && isGuideRemovedFromMainNav);

  const noMegaMenu =
    (onlyOneProduct && noSecondaryLinks && noSecondaryUrl && onlyStrength) ||
    (cmsId === 'nav.accessories' && !isAccessoriesExpandable) ||
    cmsId === 'nav.apparel' ||
    cmsId === 'nav.deals' ||
    theUserIsInAustria(locale);

  const handleClick = () => {
    trackEvent({
      properties: {
        category: key,
        linkName: title,
      },
    });
    const menu = currentMenu === menuItem ? Menu.None : menuItem;
    setCurrentMenu(menu);
    setFocusedMenu(menu);
  };

  if (cmsId === ('nav.apparelNew' as any)) {
    return (
      <>
        <MenuItem
          text={title}
          testId={key}
          isActive={
            isActiveMenu(activeMenu, [menuItem]) &&
            !isCurrentMenu(currentMenu, [menuItem])
          }
          onClick={handleClick}
          expanded={isCurrentMenu(currentMenu, [menuItem])}
          itemRef={menuItemRefs[menuItem]}
        />
        {isCurrentMenu(currentMenu, [menuItem]) && (
          <NestedLinksMenu
            label={`${title} submenu`}
            data={{ key, title, ...nav }}
            realignLastChild={false}
          />
        )}
      </>
    );
  }

  if (cmsId === 'nav.deals') {
    return (
      <HighlightedLinkItem
        text={title}
        link={nav.products[0].primaryCta.url}
        testId={key}
        isActive={
          isActiveMenu(activeMenu, [menuItem]) && !isCurrentMenu(currentMenu, [menuItem])
        }
        onClick={handleClick}
      />
    );
  }

  if (noMegaMenu) {
    return (
      <LinkItem
        text={title}
        link={nav.products[0].primaryCta.url}
        testId={key}
        isActive={
          isActiveMenu(activeMenu, [menuItem]) && !isCurrentMenu(currentMenu, [menuItem])
        }
        onClick={handleClick}
      />
    );
  }

  return (
    <>
      <MenuItem
        text={title}
        testId={key}
        isActive={
          isActiveMenu(activeMenu, [menuItem]) && !isCurrentMenu(currentMenu, [menuItem])
        }
        onClick={handleClick}
        expanded={isCurrentMenu(currentMenu, [menuItem])}
        itemRef={menuItemRefs[menuItem]}
      />
      {isCurrentMenu(currentMenu, [menuItem]) && (
        <MegaMenu
          label={`${title} submenu`}
          data={{ key, ...nav }}
          realignLastChild={realignLastMegaMenu}
        />
      )}
    </>
  );
};

export default MenuItemWithMegaMenu;
