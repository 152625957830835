import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

const routes = [
  <Route
    key="value-calculator-pages"
    path="/calculator"
    component={asyncComponent(
      () => import('./Handler' /* webpackChunkName: "ValueCalculator" */),
    )}
    page={true}
    shouldTrack={false}
  />,
];

export default routes;
