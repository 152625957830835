import { path, prop } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { reportError } from '@peloton/error-reporting';
import { removeItemFromCart } from '@ecomm/cart/api';
import { addItemsToCart } from '@ecomm/cart/api/createCartItems';
import type { Item } from '@ecomm/cart/models';
import { ItemType } from '@ecomm/cart/models';
import {
  getWarrantiesInCartForProductLine,
  loadCart,
  loadCartSuccess,
  getDevicesInCartForProductLine,
} from '@ecomm/cart/redux';
import { updateShipping } from '@ecomm/checkout/redux';
import type { ContactInfo } from '@ecomm/models';
import type {
  AddWarrantyToCartRequestedAction,
  RemoveWarrantyFromCartRequestedAction,
} from '../redux';
import {
  ActionType,
  startTransaction,
  addToCartSuccess,
  addToCartFailure,
  addWarrantyToCartSuccess,
} from '../redux';

export const addWarrantyToCartSaga = function* (
  action: AddWarrantyToCartRequestedAction,
): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);

  yield put(startTransaction(action.payload.id));
  try {
    const warrantiesInCart = yield select(
      getWarrantiesInCartForProductLine(action.payload.productLine),
    );
    yield all(
      warrantiesInCart.map((warranty: Item) =>
        call(removeItemFromCart, client, warranty.id, ItemType.Item, warranty.quantity),
      ),
    );

    const quantity = yield select(
      getDevicesInCartForProductLine(action.payload.productLine),
    );

    const payload = {
      products: [{ productOptionId: action.payload.id, quantity }],
    };

    const cart = yield call(addItemsToCart, client, payload);

    const maybePostalCode = path(['shipping', 'address', 'postalCode'], cart);
    if (maybePostalCode) {
      yield put(updateShipping(prop('shipping', cart) as ContactInfo));
    }

    yield all([
      put(loadCartSuccess(cart)),
      put(addToCartSuccess(action.payload.id)),
      put(addWarrantyToCartSuccess(action.payload.id, action.payload.productLine)),
    ]);
  } catch (error) {
    yield all([
      put(reportError({ error })),
      put(addToCartFailure(action.payload.id, `checkout.errors.${error.message}`)),
      put(loadCart()),
    ]);
  }
};

export const removeWarrantyFromCartSaga = function* (
  action: RemoveWarrantyFromCartRequestedAction,
): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);

  yield put(startTransaction(action.payload.id));
  try {
    const warrantiesInCart = yield select(
      getWarrantiesInCartForProductLine(action.payload.productLine),
    );
    const warrantyToRemove = warrantiesInCart.find(
      (warranty: Item) => warranty.id === action.payload.id,
    );

    const cart = yield call(
      removeItemFromCart,
      client,
      warrantyToRemove.id,
      ItemType.Item,
      warrantyToRemove.quantity,
    );

    const maybePostalCode = path(['shipping', 'address', 'postalCode'], cart);
    if (maybePostalCode) {
      yield put(updateShipping(prop('shipping', cart) as ContactInfo));
    }

    yield all([put(loadCartSuccess(cart)), put(addToCartSuccess(action.payload.id))]);
  } catch (error) {
    yield all([
      put(reportError({ error })),
      put(addToCartFailure(action.payload.id, `checkout.errors.${error.message}`)),
    ]);
  }
};

const watcherSaga = function* () {
  yield takeEvery(ActionType.AddWarrantyToCartRequested, addWarrantyToCartSaga);
  yield takeEvery(ActionType.RemoveWarrantyFromCart, removeWarrantyFromCartSaga);
};

export default watcherSaga;
