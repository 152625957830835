import { useHasVariation } from '@peloton/split-testing/hooks';

export const CONVERSATIONAL_COMMERCE_EXPERIMENT = 'Conversational Commerce ABC';
export const ORIGINAL = 'Original';
export const SINGLE_PROMPT_VARIATION = 'Variation #1';
export const ROTATING_PROMPTS_VARIATION = 'Variation #2';

export const useIsOriginalActive = () => {
  return useHasVariation(CONVERSATIONAL_COMMERCE_EXPERIMENT, ORIGINAL);
};

export const useIsSinglePromptActive = () => {
  return useHasVariation(CONVERSATIONAL_COMMERCE_EXPERIMENT, SINGLE_PROMPT_VARIATION);
};

export const useIsRotatingPromptsActive = () => {
  return useHasVariation(CONVERSATIONAL_COMMERCE_EXPERIMENT, ROTATING_PROMPTS_VARIATION);
};
