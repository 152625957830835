import { brand, primaryFamily, slate } from '@pelotoncycle/design-system';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { hover } from '@peloton/styles/hover';
import FormatText from '@ecomm/copy/FormatText';

type Props = {
  disclaimer: any;
};

const FinancingDisclaimer: React.FC<React.PropsWithChildren<Props>> = ({
  disclaimer,
}) => {
  return (
    <Disclaimers data-test-id="footer-disclaimer-section">
      <FormatText content={disclaimer.text}>
        <>
          {(message: string) => (
            <ReactMarkdown
              {...{
                escapeHtml: false,
                renderers: {
                  link: DisclosureLink,
                },
                source: message,
              }}
            />
          )}
        </>
      </FormatText>
    </Disclaimers>
  );
};

export default FinancingDisclaimer;

const Disclaimers = styled.div`
  color: ${brand.darkest};
  font-size: 12px;
  font-family: ${primaryFamily};
  font-weight: 300;
  letter-spacing: 0.025em;
  line-height: 1.5em;
  padding-top: 55px;
`;

const DisclosureLink = styled.a.attrs({ target: '_blank' })`
  color: ${brand.darkest};

  ${hover`
    color: ${slate[50]};
  `}
`;
