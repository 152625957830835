import { values } from 'ramda';

// TODO: Add compare when StoneOcean is no longer private
// TODO: Add trade-in when RockLobster is no longer private
export enum ModalPath {
  Assembly = 'assembly',
  Subscription = 'subscription',
  Warranty = 'warranty',
  Compare = 'compare',
  TradeIn = 'trade-in',
  Delivery = 'delivery',
}

const shopModals = values(ModalPath);

export default shopModals;
