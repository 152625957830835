import { path, pickAll } from 'ramda';
import type { Banner } from '../models.generated';
import { useBannerQuery } from '../queries/contentTypes/banner.generated';

export type BannerData = {
  text: string;
  cta: string;
};

type BannerResponse = BannerData & {
  key?: any;
};

export const transformBannerData = (response: object): BannerData => {
  const { cta, key, text }: BannerResponse = pickAll(
    ['cta', 'key', 'text'],
    path(['data', 'banner'], response) || {},
  );

  if (!text && !cta) {
    return {
      text: `Banner: ${key}`,
      cta: `Banner: ${key}`,
    };
  }

  return {
    text: text || '',
    cta: cta || '',
  };
};

const useBannerData = (id: Banner): BannerData => {
  const res = useBannerQuery({ variables: { id } });

  return transformBannerData(res);
};

export default useBannerData;
