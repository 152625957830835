import { schema } from 'normalizr';
import type { Instructor } from '../../../../models';
import { CoachType, FitnessDiscipline } from '../../../../models';
import type { ApiInstructor, QAndA } from './models';

const toCoachType = (coachType: string) => {
  switch (coachType) {
    case CoachType.PelotonCoach:
      return CoachType.PelotonCoach;
    case CoachType.ProCyclist:
      return CoachType.ProCyclist;
    default:
      return CoachType.GuestCoach;
  }
};

const processInstructor = ({
  aboutImageUrl: aboutImageSrc,
  background,
  bio,
  coachType,
  facebookFanPage: facebook,
  firstName,
  fitnessDisciplines: fitnessDisciplineStrings,
  id,
  instagramProfile: instagram,
  instructorHeroImageUrl: heroImageSrc,
  lifeStyleImageUrl: footerImageSrc,
  name,
  orderedQAndAs,
  quote,
  stravaProfile: strava,
  spotifyPlaylistUri: playlistURL,
  twitterProfile: twitter,
  username,
  webInstructorListDisplayImageUrl: listImageSrc,
}: ApiInstructor): Instructor => ({
  aboutImageSrc,
  background: toBackground(background),
  bio,
  coachType: toCoachType(coachType),
  firstName,
  fitnessDisciplines: toFitnessDisciplines(fitnessDisciplineStrings),
  footerImageSrc,
  id,
  orderedQAndAs: toOrderedQAndAs(orderedQAndAs),
  heroImageSrc,
  listImageSrc,
  name,
  playlistURL,
  username,
  facebook,
  instagram,
  quote,
  strava,
  twitter,
});

const toBackground = (background: string | null): string => background ?? '';

const toFitnessDisciplines = (fitnessDisciplineStrings: string[]): FitnessDiscipline[] =>
  fitnessDisciplineStrings.map((fitnessDiscipline: string) => {
    if (fitnessDiscipline === 'cycling') {
      return FitnessDiscipline.Bike;
    }
    if (fitnessDiscipline === 'running') {
      return FitnessDiscipline.Tread;
    }
    if (fitnessDiscipline === 'yoga') {
      return FitnessDiscipline.Yoga;
    }
    return FitnessDiscipline.Other;
  });

const toOrderedQAndAs = (orderedQAndAs: QAndA[]) =>
  orderedQAndAs
    .map(([question, answer]) => ({ question, answer }))
    .filter(({ question, answer }) => Boolean(question) && Boolean(answer));

export const instructor = new schema.Entity(
  'instructors',
  {},
  { processStrategy: processInstructor },
);
