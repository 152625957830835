import styled, { css } from 'styled-components';
import type { StyleConfig, InputComponent } from './types';

export const rawStyles = ({
  active,
  base,
  disabled,
  error,
  placeholder,
}: StyleConfig<any>) => css`
  appearance: none;
  box-shadow: none;
  outline: 0;
  ${base}

  &:required:not(:disabled):not(:focus):not([data-user-focused="true"]):not([data-has-error="true"]) {
    ${base}
  }

  &:active,
  &:focus {
    ${active};

    &:required:not(:disabled) {
      ${active}
    }
  }

  &:disabled {
    ${disabled};
  }

  &[data-has-error='true'],
  &:invalid:not(:disabled) {
    ${error};
  }

  &::placeholder {
    ${placeholder};
    // A Firefox hack
    opacity: 1;
  }
`;

const styleInput = <C extends InputComponent<any>>(Input: C) => (
  props: StyleConfig<any>,
) => {
  // this gives a warning about creating styled components within a component
  // the css styles have been extracted for use in other components directly
  const StyledInput = styled(Input)`
    ${rawStyles(props)}
  `;

  StyledInput.displayName = Input.displayName;

  return StyledInput;
};

export default styleInput;
