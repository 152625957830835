import type { ApolloClient } from '@apollo/client';
import type { FC } from 'react';
import React, { createContext, useContext } from 'react';

export type CommercetoolsClient = ApolloClient<any>;

const ClientContext = createContext<CommercetoolsClient | undefined>(undefined);

export const CommercetoolsClientProvider: FC<
  React.PropsWithChildren<{ client: CommercetoolsClient }>
> = ({ client, children }) => (
  <ClientContext.Provider value={client}>{children}</ClientContext.Provider>
);

export const useCommercetoolsClient = () => useContext(ClientContext);
