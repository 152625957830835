import React from 'react';
import GlobalReferenceProvider from '@acme-ui/global/GlobalReferenceProvider';

const withGlobalReferences = <T extends object>(
  Component: React.FC<React.PropsWithChildren<T>>,
  // eslint-disable-next-line react/display-name
) => (props: T) => (
  <GlobalReferenceProvider>{<Component {...props} />}</GlobalReferenceProvider>
);

export default withGlobalReferences;
