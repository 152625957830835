import type { RouterRootState } from 'connected-react-router';
import { createMatchSelector } from 'connected-react-router';
import { createSelector } from 'reselect';

export const getActiveRoute = createSelector(
  (state: RouterRootState) => state.router,
  router => {
    return router.location.pathname;
  },
);
const matchBikePath = createMatchSelector('/bike');
const matchBikePlusPath = createMatchSelector('/bike-plus');
const matchBikesPath = createMatchSelector('/bikes');
const matchBikeHomeTrialPath = createMatchSelector('/bike/home-trial');
const matchBikeFinancingPath = createMatchSelector('/bike/financing');
const matchBikeRentalsPath = createMatchSelector('/bike/rentals');
const matchBikeReviewFormPath = createMatchSelector('/bike/reviews/submit');
const matchTreadPath = createMatchSelector('/tread');
const matchTreadPlusPath = createMatchSelector('/tread-plus');
const matchTreadsPath = createMatchSelector('/treads');
const matchTreadFinancingPath = createMatchSelector('/tread/financing');
const matchTreadReviewFormPath = createMatchSelector('/tread/reviews/submit');
const matchClassesPath = createMatchSelector('/classes');
const matchHomeTrialPath = createMatchSelector('/home-trial');
const matchFinancingPath = createMatchSelector('/financing');
const matchRowPath = createMatchSelector('/row');
const matchGuidePath = createMatchSelector('/guide');
const matchRefurbishedPath = createMatchSelector('/refurbished');
const matchMembershipPath = createMatchSelector('/membership');

export const isBikeMarketingPage = createSelector(
  [
    matchBikePath,
    matchBikeReviewFormPath,
    matchBikeHomeTrialPath,
    matchBikeFinancingPath,
    matchBikeRentalsPath,
    matchRefurbishedPath,
  ],
  (
    isBike,
    isBikeReviewForm,
    isHomeTrial,
    isBikeFinancing,
    isBikeRentals,
    isBikeRefurbished,
  ): boolean =>
    Boolean(
      !isBikeReviewForm &&
        !isHomeTrial &&
        !isBikeFinancing &&
        (isBike || isBikeRentals || isBikeRefurbished),
    ),
);

export const isBikePlusMarketingPage = createSelector(
  [matchBikePlusPath, matchBikesPath],
  (isBikePlus, isBikes): boolean => Boolean(isBikePlus || isBikes),
);

export const isTreadMarketingPage = createSelector(
  [
    matchTreadPath,
    matchTreadPlusPath,
    matchTreadsPath,
    matchTreadReviewFormPath,
    matchTreadFinancingPath,
  ],
  (isTread, isTreadPlus, isTreads, isTreadReviewForm, isTreadFinancing): boolean =>
    Boolean(
      !isTreadReviewForm && !isTreadFinancing && (isTread || isTreadPlus || isTreads),
    ),
);

export const isClassesMarketingPage = createSelector(
  [matchClassesPath],
  (isClasses): boolean => Boolean(isClasses),
);

export const isBikeOrTreadMarketingOrClassesPage = () =>
  isBikeMarketingPage || isTreadMarketingPage || isClassesMarketingPage;

export const isBikeOrTreadMarketingPage = () =>
  isBikeMarketingPage || isTreadMarketingPage;

export const isHomeTrialPage = createSelector(
  [matchHomeTrialPath],
  (isHomeTrial): boolean => Boolean(isHomeTrial),
);

export const isFinancingPage = createSelector(
  [matchFinancingPath],
  (isFinancing): boolean => Boolean(isFinancing),
);

export const isGuideMarketingPage = createSelector([matchGuidePath], (isGuide): boolean =>
  Boolean(isGuide),
);

export const isRowMarketingPage = createSelector([matchRowPath], (isRow): boolean =>
  Boolean(isRow),
);

export const isMembershipMarketingPage = createSelector(
  [matchMembershipPath],
  (isMembership): boolean => Boolean(isMembership),
);
