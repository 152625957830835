import { Currency } from '@peloton/internationalize';

export type ShippingPrice = {
  first: number;
  additional: number;
};

export const BIKE_SHIPPING_PRICES = {
  [Currency.UnitedStatesDollars]: { first: 25000, additional: 10000 },
  [Currency.PoundSterling]: { first: 0, additional: 0 },
  [Currency.CanadianDollar]: { first: 35000, additional: 13000 },
  [Currency.Euro]: { first: 0, additional: 0 },
  [Currency.AustralianDollars]: { first: 0, additional: 0 },
};

export const BIKE_PLUS_SHIPPING_PRICES = {
  [Currency.UnitedStatesDollars]: { first: 0, additional: 0 },
  [Currency.PoundSterling]: { first: 0, additional: 0 },
  [Currency.CanadianDollar]: { first: 0, additional: 0 },
  [Currency.Euro]: { first: 0, additional: 0 },
  [Currency.AustralianDollars]: { first: 0, additional: 0 },
};

export const TREAD_SHIPPING_PRICES = {
  [Currency.UnitedStatesDollars]: { first: 35000, additional: 15000 },
  [Currency.PoundSterling]: { first: 0, additional: 0 },
  [Currency.CanadianDollar]: { first: 45000, additional: 20000 },
  [Currency.Euro]: { first: 0, additional: 0 },
  [Currency.AustralianDollars]: { first: 0, additional: 0 },
};

export const TREAD_PLUS_SHIPPING_PRICES = {
  [Currency.UnitedStatesDollars]: { first: 0, additional: 0 },
  [Currency.PoundSterling]: { first: 0, additional: 0 },
  [Currency.CanadianDollar]: { first: 0, additional: 0 },
  [Currency.Euro]: { first: 0, additional: 0 },
  [Currency.AustralianDollars]: { first: 0, additional: 0 },
};

export const getBikeShippingCosts = (currency: Currency): ShippingPrice =>
  BIKE_SHIPPING_PRICES[currency] || BIKE_SHIPPING_PRICES[Currency.Default];

export const getBikePlusShippingCosts = (currency: Currency): ShippingPrice =>
  BIKE_PLUS_SHIPPING_PRICES[currency] || BIKE_PLUS_SHIPPING_PRICES[Currency.Default];

export const getTreadShippingCosts = (currency: Currency): ShippingPrice =>
  TREAD_SHIPPING_PRICES[currency] || TREAD_SHIPPING_PRICES[Currency.Default];

export const getTreadPlusShippingCosts = (currency: Currency): ShippingPrice =>
  TREAD_PLUS_SHIPPING_PRICES[currency] || TREAD_PLUS_SHIPPING_PRICES[Currency.Default];
