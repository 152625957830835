import { pathOr, pickAll } from 'ramda';
import type { ValueProp } from '../models.generated';
import { useValuePropQuery } from '../queries/contentTypes/valueProp.generated';

export type IconData = { url: string };

export type SvgIconData = {
  iconComponent: string;
  fill?: string;
  tooltip?: string;
};

export type ValuePropData = {
  key: ValueProp;
  body?: string;
  icon?: IconData | null;
  svgIcon?: SvgIconData | null;
  title: string;
  onClick?: string;
};

const toSvgIconKeys = (svgIcon: SvgIconData): SvgIconData | null => {
  const svgIconKeys = ['iconComponent', 'fill', 'tooltip'];
  const hasAnyKeys = svgIconKeys.find(key => svgIcon[key]);
  // if all the icon's keys have a null value, change the whole object to null
  return hasAnyKeys ? pickAll(svgIconKeys, svgIcon) : null;
};

const toTextWithFallback = (
  text: string | undefined,
  key: string,
  hasNoValuePropText: boolean,
) => {
  if (hasNoValuePropText) {
    return `Value Prop: ${key}`;
  } else if (text == null) {
    return '';
  } else {
    return text;
  }
};

export const transformValuePropData = (response: any): ValuePropData => {
  const propInfo: ValuePropData = pickAll(
    ['key', 'title', 'body', 'icon', 'svgIcon', 'onClick'],
    pathOr({}, ['data', 'valueProp'], response),
  );

  const { key, title, body, icon, svgIcon, onClick } = propInfo;

  const iconUrl = icon?.url;
  const hasNoValuePropText = !title && !body;

  return {
    key,
    title: toTextWithFallback(title, key, hasNoValuePropText),
    body: toTextWithFallback(body, key, hasNoValuePropText),
    icon: iconUrl ? { url: iconUrl } : null,
    svgIcon: svgIcon ? toSvgIconKeys(svgIcon) : null,
    onClick: onClick,
  };
};

const useValuePropData = (id: ValueProp) => {
  const res = useValuePropQuery({ variables: { id } });

  return transformValuePropData(res);
};

export default useValuePropData;
