import type { Token } from '@ecomm/models';
import type {
  ApiSubmitOrderPayment,
  ApiSubmitOrderPostData,
  SubmitOrderPayment,
  SubmitOrderPostData,
} from './types';

export const toApiSubmitOrder = ({
  allowMarketing,
  hasAcceptedPolicy,
  hasAcceptedLeaseAgreement,
  payments,
  onlyIncludes,
}: SubmitOrderPostData): ApiSubmitOrderPostData => ({
  allowMarketing,
  hasAcceptedPolicy,
  payments: payments.map(
    payment =>
      ({
        amountInCents: payment.amount,
        [toTokenKey(payment.token)]: payment.token.id || payment.token,
        ...toPaymentData(payment),
      } as ApiSubmitOrderPayment),
  ),
  onlyIncludes,
  ...(hasAcceptedLeaseAgreement ? { hasAcceptedLeaseAgreement } : {}),
});

const toTokenKey = (token: Token): string => {
  let tokenKey: string;

  switch (token.kind) {
    case 'klarna':
      tokenKey = 'klarna';
      break;
    case 'gift_card':
      tokenKey = 'payment_method_token';
      break;
    default:
      tokenKey = 'token';
      break;
  }

  return tokenKey;
};

const toPaymentData = ({ token, method, lastFour }: SubmitOrderPayment) => {
  let paymentData;

  switch (token.kind) {
    case 'stripePaymentIntent':
      paymentData = {
        label: method,
        paymentIntentId: token.id,
        paymentType: 'stripe_payment_intent',
      };
      break;
    case 'gift_card':
      paymentData = { label: lastFour || method, paymentType: 'gift_card' };
      break;
    default:
      paymentData = { label: method };
      break;
  }

  return paymentData;
};
