import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { toFormattedText } from '@peloton/copy';
import {
  isRedirectFromGeomodal,
  resetGeomodalState,
} from '@ecomm/bootstrapping/internationalize/GeoModalV2/geoModalV2Helpers';
import { Phone } from '@ecomm/contact-us';
import { useCtaWithUrlData, useKeyValueData } from '@ecomm/copy';
import { useReportPageNotFoundError } from '@ecomm/hooks';
import { inlineLinkStyles } from '@ecomm/links';
import { Prerender404Tags } from '@ecomm/metadata';
import GeoModalError from './GeoModalError';
import { Content, Title, Subtitle } from './sharedErrorComponents';

const PhoneLink = styled(Phone)`
  ${inlineLinkStyles}
`;

const StyledLink = styled(Link)`
  ${inlineLinkStyles}
`;

const NotFoundError: React.FC<React.PropsWithChildren<unknown>> = () => {
  useReportPageNotFoundError();

  const title = useKeyValueData('errors.pageNotFound');
  const subtitle = useKeyValueData('errors.pageNotFoundSubtitle');
  const cta = useCtaWithUrlData('erros.pageNotFoundCta');
  const fromGeomodal = isRedirectFromGeomodal({ is404: true });

  if (fromGeomodal) {
    resetGeomodalState({ is404: true });
  }

  return fromGeomodal ? (
    <GeoModalError />
  ) : (
    <>
      <Prerender404Tags />
      <Content>
        <Title>{title}</Title>
        <Subtitle>
          {toFormattedText(subtitle, {
            link: <StyledLink to={cta.url}>{cta.text}</StyledLink>,
            phone: <PhoneLink id="supportPhoneNumber" />,
          })}
        </Subtitle>
      </Content>
    </>
  );
};

export default NotFoundError;
