import { normalize } from 'normalizr';
import type { NormalizedBundle, NormalizedProduct } from '../models';
import { bundle, product } from './schemas';
import type { ApiBundle, ApiProduct, ApiEligibility } from './types';

export const toBundles = (data: ApiBundle[] | ApiBundle): NormalizedBundle =>
  normalize(Array.isArray(data) ? data : [data], [bundle]);

export const toProducts = (data: ApiProduct[] | ApiProduct): NormalizedProduct =>
  normalize(Array.isArray(data) ? data : [data], [product]);

export const toIsEligible = ({ isEligible }: { isEligible: ApiEligibility }): boolean =>
  isEligible === 'eligible';
