import { getClass, getClassesByInstructor } from './data';
import { getUIState } from './fetcher';
import type { State as TState } from './redux';
import classes from './redux';

export { getClass, getClassesByInstructor, getUIState };

export default classes;

export type State = TState;
