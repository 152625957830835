import type { ReactNode } from 'react';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { toLocaleFromHostname } from '@peloton/internationalize';
import type { PromoData } from '@ecomm/copy/helpers';
import { usePromoData, usePromoCollectionData } from '@ecomm/copy/helpers';
import useHomepageHeroCarouselExperiment from '@ecomm/feature-toggle/homepageCarouselExperiment';
import { PromoPanelContext } from './PromoPanelProvider';
import {
  getPopulatedPromoFields,
  getPromoToMatchExperimentBucket,
  promoIsApplicable,
  toPromoExperimentPair,
} from './utils';

const DEFAULT_PROMO_KEY = 'default';

export type PromoContextValue = PromoData & {
  isResolved: boolean;
};

type Props = {
  children: ReactNode;
};

const initialState: PromoContextValue = {
  validLocales: [],
  start: undefined,
  end: undefined,
  appBannerId: undefined,
  appCheckoutBannerId: undefined,
  bikesBannerId: undefined,
  bikeBannerId: undefined,
  bikePlusBannerId: undefined,
  treadsBannerId: undefined,
  treadBannerId: undefined,
  treadPlusBannerId: undefined,
  generalBannerId: undefined,
  shopBikeBannerId: undefined,
  shopBikePlusBannerId: undefined,
  shopTreadBannerId: undefined,
  shopTreadPlusBannerId: undefined,
  globalFinancingBannerId: undefined,
  homepageHeroId: undefined,
  homepageProductBlocksId: undefined,
  legalTextId: undefined,
  checkoutBannerId: undefined,
  isResolved: false,
  promoModules: undefined,
  urgencyBanner: undefined,
  promoRibbons: undefined,
  productBlocks: undefined,
  guideBannerId: undefined,
  shopGuideBannerId: undefined,
  rowBannerId: undefined,
  shopRowBannerId: undefined,
};

export const PromoContext = createContext<PromoContextValue>(initialState);

const PromoProvider: React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
  const promos = usePromoCollectionData();
  const activeExperiment = useHomepageHeroCarouselExperiment();
  const currentLocale = toLocaleFromHostname();

  const firstApplicablePromo = promos.find(promo =>
    promoIsApplicable(promo, currentLocale),
  );
  const promoOriginalAndVariation = toPromoExperimentPair(firstApplicablePromo, promos);
  const initialActivePromo = getPromoToMatchExperimentBucket(
    promoOriginalAndVariation,
    activeExperiment,
  );

  const [isResolved, setIsResolved] = useState(!!initialActivePromo);
  const [activePromo, setActivePromo] = useState<PromoData | undefined>(
    initialActivePromo,
  );
  const [overrideToday, setOverrideToday] = useState(new Date());

  const resetOverrideToday = () => {
    setOverrideToday(new Date());
  };

  useEffect(() => {
    if (promos.length && !isResolved) {
      setActivePromo(initialActivePromo);
      setIsResolved(true);
    }
  }, [initialActivePromo, isResolved, promos.length]);

  const defaultPromo = usePromoData(DEFAULT_PROMO_KEY);

  const resetActivePromo = () => {
    setActivePromo(initialActivePromo);
    setIsResolved(!!initialActivePromo);
  };

  const promo = useMemo(() => {
    const activePromoValues = getPopulatedPromoFields(activePromo);

    return { ...defaultPromo, ...activePromoValues, isResolved };
  }, [activePromo, defaultPromo, isResolved]);

  return (
    <PromoContext.Provider value={promo}>
      <PromoPanelContext.Provider
        value={{
          setActivePromo,
          resetActivePromo,
          overrideToday,
          resetOverrideToday,
          setOverrideToday,
        }}
      >
        {children}
      </PromoPanelContext.Provider>
    </PromoContext.Provider>
  );
};

export default PromoProvider;
