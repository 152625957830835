import { useMemo } from 'react';
import useSWR from 'swr';
import { IMMUTABLE } from '@content/client/revalidation';
import { useFinancingValues } from '@ecomm/copy/helpers/useFinancingValues';
import useCurrentDate from './useCurrentDate';

const useMarkdownValues = (values?: object) => {
  const { data: otdEstimates } = useSWR('otdEstimates', IMMUTABLE);
  const financingValues = useFinancingValues();
  const today = useCurrentDate();

  const formatValues = useMemo(() => {
    const defaultValues = { ...otdEstimates, ...financingValues, today };
    return values ? { ...defaultValues, ...values } : { ...defaultValues };
  }, [otdEstimates, financingValues, values, today]);

  return formatValues;
};

export default useMarkdownValues;
