import { useEffect, useRef, useState } from 'react';
import type { PromoHero } from '@ecomm/copy/models.generated';
import useCarousel from './useCarousel';

export const DEFAULT_INTERVAL = 6000;

const useCarouselWithAutoAdvance = (cmsIds: PromoHero[]) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const timeoutId = useRef<number | undefined>();
  const carousel = useCarousel(cmsIds.length);
  const { activeSlide, handleAutoAdvance, handleNext, handlePrevious } = carousel;

  useEffect(() => {
    return () => clearTimeout(timeoutId.current);
  }, []);

  useEffect(() => {
    clearTimeout(timeoutId.current);
    if (isPlaying) {
      // Need window for typescript resolution with clearTimeout (number vs NodeJS.Timer)
      timeoutId.current = window.setTimeout(() => {
        handleAutoAdvance();
      }, DEFAULT_INTERVAL);
    }
  }, [activeSlide, cmsIds, isPlaying]);

  const handleStart = () => setIsPlaying(true);
  const handlePause = () => setIsPlaying(false);

  return {
    ...carousel,
    handleStart,
    handleNext,
    handlePrevious,
    handlePause,
    isPlaying,
  };
};

export default useCarouselWithAutoAdvance;
