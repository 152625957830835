import React from 'react';
import type { PromoHero as PromoHeroId } from '@ecomm/copy';
import { usePromoHeroData } from '@ecomm/copy';
import type { HeroCarouselProps } from './models';
import type { TrackingProps } from './trackingUtils';
import { ImageHero, VideoHero } from './Views';

type Props = TrackingProps &
  HeroCarouselProps & {
    cmsId: PromoHeroId;
    activeSlide?: number;
  };

const CmsHero: React.FC<React.PropsWithChildren<Props>> = ({
  cmsId,
  inCarousel,
  index = undefined,
  onPause,
  onPlay,
  onReady,
  activeSlide = 0,
}) => {
  const data = usePromoHeroData(cmsId);

  if (data?.video) {
    return (
      <VideoHero
        {...data}
        inCarousel={inCarousel}
        index={index}
        onPause={onPause}
        onPlay={onPlay}
        onReady={onReady}
        video={data.video}
        activeSlide={activeSlide}
      />
    );
  } else if (data?.image) {
    return (
      <ImageHero {...data} image={data.image} index={index} activeSlide={activeSlide} />
    );
  } else {
    return null;
  }
};

export default CmsHero;
