import {
  getOrdersSummary,
  getOrderIdFromLocation,
  getCreatedAtById,
} from './orderHistory';
import { addOrders } from './orders';
import { addPayments } from './payments';
import { addPurchasedBundles } from './purchasedBundles';
import { addPurchasedItems } from './purchasedItems';
import type { ReducerState as TReducerState } from './rootReducer';
import reducer from './rootReducer';
import {
  getCreatedAt,
  getDeliveryDate,
  getDevicesFromOrder,
  getBillingAddressFromOrder,
  getConfirmationCodeById,
  getContainsDevice,
  getContainsPreorder,
  getCurrencyById,
  getDigitalSubscription,
  getEmailFromOrder,
  getItemOrBundleById,
  getOrderIds,
  getPayments,
  getPrimaryPayment,
  getProductOptionsForItemOrBundleId,
  getShippingAddressFromOrder,
  getSortedItemAndBundleIdsFromOrder,
  getTotalWithTaxPaid,
  getThirdPartyShipping,
  getUIState,
  hasBike,
  hasDigitalSubscription,
  hasTread,
  matchOrderConfirmationRoute,
  shouldShowShippingDetails,
} from './selectors';
import type { RequestOrderAction as TRequestOrderAction } from './ui';
import { requestOrderFetch, orderFetchSuccess, orderFetchFailed, ActionType } from './ui';

export default reducer;

export {
  ActionType as OrderActions,
  addOrders,
  addPayments,
  addPurchasedBundles,
  addPurchasedItems,
  getBillingAddressFromOrder,
  getConfirmationCodeById,
  getContainsDevice,
  getContainsPreorder,
  getCurrencyById,
  getCreatedAt,
  getCreatedAtById,
  getDeliveryDate,
  getDevicesFromOrder,
  getDigitalSubscription,
  getEmailFromOrder,
  getItemOrBundleById,
  getOrderIdFromLocation,
  getOrderIds,
  getOrdersSummary,
  getPayments,
  getPrimaryPayment,
  getProductOptionsForItemOrBundleId,
  getShippingAddressFromOrder,
  getSortedItemAndBundleIdsFromOrder,
  getTotalWithTaxPaid,
  getThirdPartyShipping,
  getUIState,
  hasBike,
  hasDigitalSubscription,
  hasTread,
  matchOrderConfirmationRoute,
  orderFetchFailed,
  orderFetchSuccess,
  requestOrderFetch,
  shouldShowShippingDetails,
};

export type ReducerState = TReducerState;
export type RequestOrderAction = TRequestOrderAction;
