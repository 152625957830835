import { path, pathOr } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, takeLatest } from 'redux-saga/effects';
import { track } from '@peloton/analytics';
import { CLIENT_CONTEXT } from '@peloton/api';
import { fetchUserExists } from '@peloton/auth';
import {
  toLocale,
  toNeedsConsentForMarketingInAnalytics,
} from '@peloton/internationalize';
import { ValidationErrors } from '@ecomm/form-validation';
import type { Email } from '@ecomm/models';
import type { BlurAction } from '../redux';
import { updateFieldError, userExists } from '../redux';
import { ActionTypes } from '../redux/form';

export const trackEmailBlurSaga = function* (action: BlurAction): SagaIterator {
  const needsConsentForMarketing = yield call(
    toNeedsConsentForMarketingInAnalytics,
    toLocale(),
  );

  if (!needsConsentForMarketing) {
    const email = pathOr('', ['payload', 'value'], action);
    const event = {
      event: 'Moved away from email field on Digital Checkout',
      properties: {
        email,
        listName: 'Website Leads',
      },
    };

    yield call(track, event);
  }
};

export const checkExistingUser = function* (action: BlurAction): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  const email: Email = pathOr('', ['payload', 'value'], action);

  const exists = Boolean(email) ? yield call(fetchUserExists, client, email) : false;

  if (exists) {
    yield all([
      put(updateFieldError('email', ValidationErrors.UserExists)),
      put(userExists(email)),
    ]);
  }
};

export const filter = function* (action: BlurAction) {
  const name = path(['payload', 'name'], action);

  if (name === 'email') {
    yield all([call(checkExistingUser, action), call(trackEmailBlurSaga, action)]);
  }
};

const watcher = function* () {
  yield takeLatest(ActionTypes.FieldBlurred, filter);
};

export default watcher;
