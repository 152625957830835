import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

export const PATH = '/benefits/*';

const route = (
  <Route
    key={PATH}
    page
    path={PATH}
    component={asyncComponent(() => import('./Handler'))}
  />
);

export default route;
