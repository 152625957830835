import momentNamespace from 'moment';
import type { Client } from '@peloton/api';
import { pipeData } from '@peloton/api';
import type { ID } from '@ecomm/models';
import { toClasses } from './mappers';
import type { ApiClass } from './models';

const classesUrl = '/api/v3/ride/live';

enum ScheduleType {
  All = 'all',
  Live = 'live',
  Encore = 'encore',
}

type Options = {
  end: number;
  excludeComplete: boolean;
  excludeStudioOnly: boolean;
  instructorId: ID;
  inStudio: boolean;
  limit: number;
  scheduleType: ScheduleType;
};

type Response = {
  rides: ApiClass[];
};

const fetchClasses = (api: Client, options: Partial<Options> = {}) =>
  api
    .get(classesUrl, toQueryString(options))
    .then(pipeData((response: Response) => toClasses(response.rides)));

const API_MAXIMUM_DAYS = momentNamespace.duration(14, 'days');

export const fetchUpcomingClasses = (api: Client, instructorId: ID) =>
  fetchClasses(api, {
    end: momentNamespace().add(API_MAXIMUM_DAYS).unix(),
    instructorId,
    limit: 4,
  });

const toQueryString = ({
  end,
  excludeComplete = true, // do not show complete classes
  excludeStudioOnly = true, // do not show studio-only classes
  instructorId,
  limit,
  scheduleType = ScheduleType.Live, // only show live classes
}: Partial<Options>) => ({
  params: {
    end,
    limit,
    instructor_id: instructorId,
    schedule_type: scheduleType,
    exclude_complete: excludeComplete,
    exclude_live_in_studio_only: excludeStudioOnly,
  },
});
