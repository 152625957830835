import React from 'react';
import styled from 'styled-components';
import { Markdown } from '@peloton/copy';
import { startInteraction } from '@peloton/drift';
import { useBannerReference } from '@acme-ui/global';
import { a11yOutline, Button } from '@ecomm/buttons';
import EcommBanner, { CtaText } from './EcommBanner';
import type { BannerPropsDriftCta } from './models';

const DriftInteractionBanner: React.FC<React.PropsWithChildren<BannerPropsDriftCta>> = ({
  text,
  theme,
  driftCta,
  dataTestIdPrefix,
}) => {
  const bannerRef = useBannerReference();

  const { interactionId, text: ctaText } = driftCta;

  const TextWithDataTestId: React.FC<React.PropsWithChildren<unknown>> = ({
    children,
  }) => <StyledSpan data-test-id={`${dataTestIdPrefix}Text`}>{children}</StyledSpan>;

  return (
    <BannerButton
      data-test-id={`${dataTestIdPrefix}Url`}
      data-element-id="banner"
      onClick={() => {
        startInteraction(interactionId);
      }}
    >
      <EcommBanner ref={bannerRef} theme={theme}>
        <div>
          <Markdown
            content={text}
            markdown={{ renderers: { paragraph: TextWithDataTestId } }}
          />{' '}
          <CtaText tabIndex={0} data-test-id={`${dataTestIdPrefix}Cta`} theme={theme}>
            {ctaText}
          </CtaText>
        </div>
      </EcommBanner>
    </BannerButton>
  );
};

export default DriftInteractionBanner;

const BannerButton = styled(Button)`
  height: auto;
  padding: 0;
`;

const StyledSpan = styled.span`
  ${a11yOutline}
`;
