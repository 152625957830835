import type { LocationChangeAction } from 'connected-react-router';
import { LOCATION_CHANGE, replace } from 'connected-react-router';
import { pathOr, values } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import { getIsToggleActive } from '@ecomm/feature-toggle';
import type { Slug } from '@ecomm/models';
import { isLoaded } from '@ecomm/models';
import shopModals from '@ecomm/shop-modals';
import type { BundleType } from '../models';
import { toBundleType } from '../models';
import { getBundleBySlug, getUIStateForBundleType, matchConfigureRoute } from '../redux';
import { ActionType } from '../redux/ui';
import toShopUrl from '../toShopUrl';

export const validateRouteSaga = function* (
  bundleType: BundleType,
  slug: Slug,
  url: string,
): SagaIterator {
  const bundle = yield select(getBundleBySlug, { slug });

  if (!bundle) {
    const shopUrl = toShopUrl(url);
    yield put(replace(shopUrl));
  }
};

export const checkStatusSaga = function* (
  bundleType: BundleType,
  slug: Slug,
  url: string,
): SagaIterator {
  const status = yield select(getUIStateForBundleType, { bundleType });

  if (isLoaded(status)) {
    yield call(validateRouteSaga, bundleType, slug, url);
  } else {
    // Wait until the bundleTypes are loaded
    while (true) {
      yield take(ActionType.BundleTypesSuccess);
      yield call(validateRouteSaga, bundleType, slug, url);
    }
  }
};

export const getIsPackageGraphQLActive = getIsToggleActive('graphqlForShopPackages');

export const checkRouteSaga = function* (_: LocationChangeAction): SagaIterator {
  const isPackageGraphQLActive = yield select(getIsPackageGraphQLActive);
  if (isPackageGraphQLActive) {
    // short circuit if GraphQL is active
    return;
  }
  const match = yield select(matchConfigureRoute);

  if (!match) return;

  const bundleTypeSlug = pathOr('', ['params', 'bundleType'], match);
  const bundleSlug = pathOr('', ['params', 'slug'], match);
  const url = pathOr('', ['url'], match);

  if (['compare', 'trade-in'].includes(bundleSlug)) {
    return;
  }

  if (values(shopModals).indexOf(bundleSlug) !== -1) {
    return;
  }

  // Fix type errors
  let bundleType: BundleType | undefined = undefined;
  try {
    bundleType = toBundleType(bundleTypeSlug);
  } catch (err) {
    const error = new DomainError(
      `Error converting ${bundleTypeSlug} to bundleType in configureRoute checkRouteSaga`,
      err,
    );
    yield put(reportError({ error }));
  }

  if (bundleType) {
    yield call(checkStatusSaga, bundleType, bundleSlug, url);
  }
};

const watcherSaga = function* () {
  yield takeLatest(LOCATION_CHANGE, checkRouteSaga);
};

export default watcherSaga;
