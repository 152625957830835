import React from 'react';
import { useDispatch } from 'react-redux';
import { ALLOW_TOGGLE_PANEL } from '@peloton/app-config';
import { asyncComponent } from '@peloton/code-splitting';
import { useKeyValueData } from '@ecomm/copy';
import {
  useGenericIsToggleActive,
  useGenericSetToggleValue,
  useToggles,
} from '@ecomm/feature-toggle/context/ToggleContext';
import { switchToggle } from '@ecomm/feature-toggle/redux';

const PanelComponent = asyncComponent(
  () =>
    import(
      '@ecomm/feature-toggle/panel/View' /* webpackChunkName: "FeatureTogglePanel" */
    ),
);

const FeatureTogglePanel: React.FC<React.PropsWithChildren<unknown>> = () => {
  const flyoutAriaLabel = useKeyValueData('toggleViewer.ariaLabel');
  const isToggleActive = useGenericIsToggleActive();
  const setToggleValue = useGenericSetToggleValue();
  const toggles = useToggles();
  const dispatch = useDispatch();

  return (
    <PanelComponent
      toggles={toggles}
      isToggleActive={isToggleActive}
      setToggleValue={(toggleId, value) => {
        setToggleValue(toggleId, value);
        dispatch(switchToggle(toggleId, value));
      }}
      flyoutAriaLabel={flyoutAriaLabel}
      shouldCreateWindowHandler={ALLOW_TOGGLE_PANEL}
    />
  );
};

export default FeatureTogglePanel;
