import { lensPath, set } from 'ramda';
import type { ID } from '@ecomm/models';
import type { BundleConfiguration, Entities } from '../../models';

export enum ActionType {
  AddBundleConfigurations = 'ecomm/shop/ADD_BUNDLE_CONFIGURATIONS',
  UpdateBundleConfiguration = 'ecomm/shop/UPDATE_BUNDLE_CONFIGURATION',
}

type State = Entities<BundleConfiguration>;

export const initialState: State = {};

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.AddBundleConfigurations:
      return {
        ...action.payload.configurations,
        ...state,
      };

    case ActionType.UpdateBundleConfiguration: {
      const { bundle, product, trait, traitIndex } = action.payload;
      const updateLens = lensPath([bundle, product, 'traits', traitIndex]);

      return set(updateLens, trait, state);
    }

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  bundles: State;
};

export const addBundleConfigurations = (
  configurations: Entities<BundleConfiguration>,
): AddBundleConfigurationsAction => ({
  type: ActionType.AddBundleConfigurations,
  payload: {
    configurations,
  },
});

type UpdateObject = {
  bundle: ID;
  product: ID;
  trait: ID;
  traitIndex: number;
};

export const updateBundleConfiguration = ({
  bundle,
  product,
  trait,
  traitIndex,
}: UpdateObject): UpdateBundleConfigurationAction => ({
  type: ActionType.UpdateBundleConfiguration,
  payload: {
    bundle,
    product,
    trait,
    traitIndex,
  },
});

export type AddBundleConfigurationsAction = {
  type: ActionType.AddBundleConfigurations;
  payload: {
    configurations: Entities<BundleConfiguration>;
  };
};

export type UpdateBundleConfigurationAction = {
  type: ActionType.UpdateBundleConfiguration;
  payload: {
    bundle: ID;
    product: ID;
    trait: ID;
    traitIndex: number;
  };
};

type Action = AddBundleConfigurationsAction | UpdateBundleConfigurationAction;
