import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles/breakpoints';
import { openChat } from '@ecomm/chat/chat';
import ContactLinks from './ContactLinks';
import SocialMediaLinks from './SocialMediaLinks';

type Props = {
  contactLinks?: any;
  socialLinks?: any;
};

const ContactSection: React.FC<React.PropsWithChildren<Props>> = ({
  contactLinks,
  socialLinks,
}) => {
  return (
    <SectionContainer>
      {contactLinks && <ContactLinks {...contactLinks} onChatRequest={openChat} />}
      {socialLinks && <SocialMediaLinks {...socialLinks} />}
    </SectionContainer>
  );
};

export default ContactSection;

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  line-height: 1.375em;
  margin-top: 40px;
  min-width: 20%;

  ${media.tablet`
    flex: initial;
    margin-top: 0;
  `}

  ${media.desktopLarge`
    flex-direction: column;
  `}
`;
