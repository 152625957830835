import { createMatchSelector } from 'connected-react-router';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { trackWithTestIds } from '@ecomm/analytics';

const matchBikesPath = createMatchSelector('/bikes');
const matchBikesTradeIn = createMatchSelector('/bikes/trade-in');

type Config = {
  showingOriginalBikeText: boolean;
  headingTestId: string;
  subHeadingTestId: string;
};

const useHeadbandConfig = (): Config => {
  const matchApplicablePage = createSelector(
    [matchBikesPath, matchBikesTradeIn],
    (isBikesRoute, isBikesTradeIn) => Boolean(isBikesRoute) && !Boolean(isBikesTradeIn),
  );
  const originalFinancingPage = useSelector(matchApplicablePage);
  const testId = `${trackWithTestIds.getPageName()}_subnavFinancingMessage`;

  return {
    headingTestId: `${testId}_heading`,
    subHeadingTestId: `${testId}_subHeading`,
    showingOriginalBikeText: originalFinancingPage,
  };
};

export default useHeadbandConfig;
