import { reducer as formReducer } from 'redux-form';
import { userReducer } from '@peloton/auth';
import { reducer as chatReducer } from '@peloton/drift';
import { envReducer } from '@peloton/env';
import { extLinkEnvReducer } from '@peloton/external-links';
import { splitTestingReducer } from '@peloton/split-testing';
import { reducer as subscriptionPlans } from '@peloton/subscription-plans';
import { cartSummaryReducer } from '@ecomm/cart';
import { checkoutReducer } from '@ecomm/checkout';
import { reducer as concessionaryPopupReducer } from '@ecomm/concessionary-popup';
import { reducer as configReducer } from '@ecomm/config';
import { reducer as toggles } from '@ecomm/feature-toggle';
import { reducer as financingReducer } from '@ecomm/financing';
import { loginModalReducer } from '@ecomm/login-modal';
import { reducer as modalReducer } from '@ecomm/modal';
import { orderReducer } from '@ecomm/order';
import { reducer as paymentReducer } from '@ecomm/payment';
import { reducer as pageCheckoutReducer } from '@ecomm/pg-checkout';
import { reducer as pgConcessionaryPricing } from '@ecomm/pg-concessionary-pricing';
import { reducer as pgDigitalCheckout } from '@ecomm/pg-digital-checkout';
import { reducer as pgDigitalPromotion } from '@ecomm/pg-digital-promotion';
import { reducer as instructorsPage } from '@ecomm/pg-instructors';
import { reducer as pressReducer } from '@ecomm/pg-press';
import { reducer as pgRegisterFreeTrial } from '@ecomm/pg-register-free-trial';
import { reducer as shippingReservationPage } from '@ecomm/pg-shipping-reservation';
import { reducer as postalCodeCheckReducer } from '@ecomm/postal-code-check';
import { reducer as privacyBannerReducer } from '@ecomm/privacy-banner';
import { reducer as promoModalReducer } from '@ecomm/promo-modal';
import { reducer as quickCheckout } from '@ecomm/quick-checkout';
import { reducer as registration } from '@ecomm/registration';
import { newUserPasswordReducer, newUserPasswordModalReducer } from '@ecomm/set-password';
import { reducer as shopReducer } from '@ecomm/shop';
import { reducer as showroomsReducer } from '@ecomm/showrooms';
import { reducer as tradeInReducer, REDUCER_KEY as TRADE_IN_KEY } from '@ecomm/trade-in';
import { reducer as userSubscriptions } from '@ecomm/user-subscriptions';
import vendorKeysReducer from '@ecomm/vendor-keys';
import { warrantyReducer } from '@ecomm/warranty';
import oneWellnessReducers from '@onewellness/redux/ecomm';

export const toStoreReducers = () => ({
  cartSummary: cartSummaryReducer,
  chat: chatReducer,
  checkout: checkoutReducer,
  config: configReducer,
  concessionaryPopup: concessionaryPopupReducer,
  ...oneWellnessReducers,
  env: envReducer,
  extLinkEnv: extLinkEnvReducer,
  financing: financingReducer,
  form: formReducer,
  instructorsPage,
  loginModal: loginModalReducer,
  modal: modalReducer,
  newUserPassword: newUserPasswordReducer,
  newUserPasswordModal: newUserPasswordModalReducer,
  order: orderReducer,
  payments: paymentReducer,
  pgCheckout: pageCheckoutReducer,
  pgConcessionaryPricing,
  pgDigitalCheckout,
  pgDigitalPromotion,
  pgRegisterFreeTrial,
  press: pressReducer,
  postalCodeCheck: postalCodeCheckReducer,
  privacyBanner: privacyBannerReducer,
  promoModal: promoModalReducer,
  quickCheckout,
  registration,
  [TRADE_IN_KEY]: tradeInReducer,
  shippingReservationPage,
  shop: shopReducer,
  showrooms: showroomsReducer,
  splitTesting: splitTestingReducer,
  subscriptionPlans,
  toggles,
  user: userReducer,
  userSubscriptions,
  vendorKeys: vendorKeysReducer,
  warranties: warrantyReducer,
});
