import type { ReportError } from '@peloton/error-reporting';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import type { QueryHookOptions } from '@ecomm/graphql/hooks';
import { useCartQuery } from '../graphql/queries/Cart.generated';

type Opts = {
  fetchPolicy?: QueryHookOptions<any, any>['fetchPolicy'];
  throwError?: boolean;
  reportSwallowedError?: ReportError;
};

const useCartWithVariables = (opts?: Opts) =>
  useCartQuery({
    fetchPolicy: opts?.fetchPolicy,
    suspend: false,
    variables: {
      calculateEstimatedShippingPrice: useIsToggleActive()('projectPhoenix'),
    },
    throwError: opts?.throwError ?? true,
    reportSwallowedError: opts?.reportSwallowedError,
    notifyOnNetworkStatusChange: true,
  });

export default useCartWithVariables;
