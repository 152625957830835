import type { createMatchSelector } from 'connected-react-router';
import { complement, isNil } from 'ramda';
import type { match as Match } from 'react-router';
import type { AnyAction } from 'redux';
import type { Saga } from 'redux-saga';
import { call, select } from 'redux-saga/effects';

type Options = {
  matcher: ReturnType<typeof createMatchSelector>;
  onMatch: Saga;
  predicate?(match: Match<any>): boolean;
};

const toCheckRouteSaga = ({ matcher, onMatch, predicate = hasMatch }: Options): Saga =>
  function* (action: AnyAction) {
    const match = yield select(matcher);

    if (!match) {
      return;
    }

    if (!predicate(match)) {
      return;
    }

    yield action ? call(onMatch, action) : call(onMatch);
  };

export default toCheckRouteSaga;

const hasMatch = complement(isNil);
