import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '@peloton/styles';
import { LoadingStatus, LoadingStatusWithoutCopyHooks } from '@ecomm/loading-status';
import { Spinner } from '@ecomm/spinner';
import type { ButtonProps } from './buttons';
import { StandardButtonSize } from './buttons';

type ButtonWithSpinnerProps = ButtonProps & {
  button: React.ComponentType<React.PropsWithChildren<ButtonProps>>;
  loadingMessageText?: string;
  processing: boolean;
};

const StyledSpinner = styled(Spinner)`
  ${({ size }: ButtonProps) => SpinnerMargin(size)}
  position: absolute;
`;

const SpinnerMargin = (opts = {}) => {
  const size = { ...StandardButtonSize, ...opts };
  return css`
    margin: ${size.mobile === 'STANDARD' ? '15px' : SpinnerMarginMap[size.mobile]};
    ${media.tablet`
      margin: ${SpinnerMarginMap[size.tablet]};
    `};
    ${media.desktop`
      margin: ${SpinnerMarginMap[size.desktop]};
    `}
  `;
};

const SpinnerMarginMap = {
  XSMALL: '5px',
  SMALL: '10px',
  STANDARD: '15px',
};

const ButtonWithSpinner: React.FC<React.PropsWithChildren<ButtonWithSpinnerProps>> = ({
  className,
  loadingMessageText,
  processing,
  ...props
}) => {
  props.disabled = props.disabled || processing;
  return (
    <div className={className}>
      <props.button {...props} />
      {loadingMessageText ? (
        <LoadingStatusWithoutCopyHooks
          isLoading={processing}
          loadingMessageText={loadingMessageText}
        />
      ) : (
        <LoadingStatus isLoading={processing} />
      )}
      {processing && <StyledSpinner size={props.size} />}
    </div>
  );
};
ButtonWithSpinner.displayName = 'ButtonWithSpinner';

export { ButtonWithSpinner };
