import type { Breakpoint } from '@peloton/responsive/reducer';
import type { BpVideoObjType } from '@ecomm/copy/helpers/utils';
import type { BreakpointOptions } from '@ecomm/image';

type Poster = {
  src: string;
  breakpointOptions: BreakpointOptions;
};

export type VideoProps = {
  poster: Poster;
  src: string;
};

const toVideoProps = (
  bpVideoObj: BpVideoObjType,
  breakpoint: Breakpoint = 'desktop',
): VideoProps => {
  const {
    mobile,
    tablet,
    desktop: { poster, video },
    desktopLarge,
  } = bpVideoObj;
  return {
    poster: {
      src: poster,
      breakpointOptions: {
        mobile: { width: 580, src: mobile?.poster || poster },
        tablet: { width: 800, src: tablet?.poster || poster },
        desktop: { width: 1024, src: poster },
        desktopLarge: { width: 600, src: desktopLarge?.poster || poster },
      },
    },
    src: bpVideoObj[breakpoint]?.video || video,
  };
};

export default toVideoProps;
