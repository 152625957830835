import type { ContactInfo } from '@ecomm/models';

// TODO: Avoid names suffixed with Data.
// Customer?
// OrderRequest?
// CheckoutInfo? CheckoutForm? CheckoutFields? CheckoutValues?
// SubmittedForm?
// Purchaser?
export type OrderData = {
  email: string;
  shipping: ContactInfo;
  creditCardName: string;
  billing: ContactInfo;
  recipientEmail?: string;
  gifterName?: string;
  giftMessage?: string;
  isGiftingEnhancementEnabled?: boolean;
};

export enum OrderType {
  PaymentGateway = 'paymentGateway',
  PaymentRequest = 'paymentRequest',
  GiftCard = 'giftCard',
}
