import { combineReducers } from 'redux';
import type { State as DeliveryDatesState } from './deliveryDates/redux';
import deliveryDates from './deliveryDates/redux';
import type { State as ScheduleDeliveryState } from './scheduleDelivery/redux';
import { default as scheduleDelivery } from './scheduleDelivery/redux';

export type State = {
  deliveryDates: DeliveryDatesState;
  scheduleDelivery: ScheduleDeliveryState;
};

export type ReducerState = {
  shippingReservationPage: State;
};

export default combineReducers({
  deliveryDates,
  scheduleDelivery,
});
