import type { ReducerState as SubscriptionPlansState } from '@peloton/subscription-plans';
import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { UIState } from '@ecomm/models';
import { Status, toUIState } from '@ecomm/models';

export enum ActionTypes {
  PlansFetchRequested = 'ecomm/pg-concessionary-pricing/ui/SUBSCRIPTION_PLANS_REQUESTED',
  PlansFetchSucceeded = 'ecomm/pg-concessionary-pricing/ui/SUBSCRIPTION_PLANS_SUCCEEDED',
  PlansFetchFailed = 'ecomm/pg-concessionary-pricing/ui/SUBSCRIPTION_PLANS_FAILED',
}

export type State = UIState;

export const defaultState = toUIState(Status.Init);

const reducer = (state: State = defaultState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.PlansFetchRequested:
      return toUIState(Status.Loading);

    case ActionTypes.PlansFetchSucceeded:
      return toUIState(Status.Loaded);

    case ActionTypes.PlansFetchFailed: {
      const { exception } = action.payload;
      return toUIState(Status.Failed, exception);
    }
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  pgConcessionaryPricing: State;
} & SubscriptionPlansState;

export const requestedPlansFetch = (): PlansRequestAction => ({
  type: ActionTypes.PlansFetchRequested,
});

export const succeedPlansFetch = (): PlansSuccessAction => ({
  type: ActionTypes.PlansFetchSucceeded,
});

export const failPlansFetch = (
  errorId: string = 'pgConcessionaryPricing.errors.plansFetch',
): PlansFailedAction => ({
  type: ActionTypes.PlansFetchFailed,
  payload: { exception: toException(errorId) },
});

type PlansRequestAction = { type: ActionTypes.PlansFetchRequested };
type PlansSuccessAction = { type: ActionTypes.PlansFetchSucceeded };
type PlansFailedAction = {
  type: ActionTypes.PlansFetchFailed;
  payload: { exception: Exception };
};

type Actions = PlansRequestAction | PlansSuccessAction | PlansFailedAction;
