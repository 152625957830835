import { useHasVariation } from '@peloton/split-testing/hooks';
import useEntry from '@content/client/hooks/useEntry';
import { client } from '@content/client/peloContentClient';
import type { CtaWithUrlData } from '@ecomm/copy';

const USED_BIKES_CTA_ENTRY_ID = '7z4LRoa5K0vIZo4dbj4jXm';

export const usePelotonHistorySummary = () => {
  const isPelotonHistorySummaryVariation = useHasVariation(
    'PHS Entrypoint',
    'Variation #1',
  );
  const { data } = useEntry(client, USED_BIKES_CTA_ENTRY_ID);

  const usedBikesCta = data
    ? ({
        key: data.fields.key,
        targetBlank: false,
        text: data.fields.text,
        url: data.fields.link.fields.url,
      } as Required<CtaWithUrlData>)
    : undefined;

  return { isPelotonHistorySummaryVariation, usedBikesCta };
};
