import { push } from 'connected-react-router';
import type { SagaIterator } from 'redux-saga';
import { call, take, getContext, put, select, race, takeEvery } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { getSignedInUserEmail } from '@peloton/auth/redux';
import { getLocale } from '@peloton/env';
import { reportError } from '@peloton/error-reporting';
import { Locale } from '@peloton/internationalize';
import { loadCartInitialSuccess, getCartEmail } from '@ecomm/cart';
import { updateEmail, updateShipping } from '@ecomm/cart/api';
import { _setGiftingInformation } from '@ecomm/cart/api/gifting';
import { hasEquipmentLeaseInCart } from '@ecomm/cart/models/Cart';
import type { Address } from '@ecomm/checkout/models';
import { AddressType, toContactInfoFromAddress } from '@ecomm/checkout/models';
import { getCheckoutAddress } from '@ecomm/checkout/redux';
import { selectCheckoutToken } from '@ecomm/checkout/redux/selectors';
import { getIsToggleActive } from '@ecomm/feature-toggle';
import { PaymentMethod } from '@ecomm/models';
import type { ContactInfo, Token } from '@ecomm/models';
import {
  preparePayments,
  preparePaymentsSuccess,
  Actions as PaymentActions,
} from '@ecomm/payment/redux';
import { billingInfoMapper } from '@ecomm/payment/stripe';
import { OrderType } from '../models';
import { updateBillingInfo, submitOrder } from '../redux';
import type {
  ReviewOrderAction,
  ReviewOrderSuccessAction,
  ReviewWalletOrderAction,
} from '../redux/reviewOrder';
import {
  Actions as OrderActions,
  reviewOrderSuccess,
  reviewOrderFailure,
} from '../redux/reviewOrder';

export const reviewOrderSaga = function* (
  client: Client,
  action: ReviewOrderAction,
): SagaIterator {
  try {
    const isQuickPayCheckoutEnabled = action.payload.isQuickPayCheckoutEnabled;
    const userEmail = yield select(getSignedInUserEmail);
    const cartEmail = yield select(getCartEmail);
    const email = userEmail || cartEmail || action.payload.orderData.email;
    yield call(updateEmail, client, email);

    const {
      shipping,
      billing,
    }: { shipping: ContactInfo; billing: ContactInfo } = yield call(resolveAddressSaga);

    const cart = yield call(updateShipping, client, shipping);

    const isGiftingEnhancementEnabled =
      action.payload.orderData.isGiftingEnhancementEnabled;

    if (isGiftingEnhancementEnabled && cart.isGift) {
      const giftingInformation = {
        cartId: cart.id,
        recipientName: shipping.name.first,
        gifterName: action.payload.orderData.gifterName,
        recipientEmail: action.payload.orderData.recipientEmail,
        giftMessage: action.payload.orderData.giftMessage,
      };
      yield call(_setGiftingInformation, giftingInformation);
    }

    // yield put(loadCartSuccess(cart));
    // TODO: cart summary reducer doesn't update correctly, this should not need to FORCE updating the entire cart
    yield put(loadCartInitialSuccess(cart));

    if (action.payload.kind === OrderType.GiftCard) {
      yield put(
        updateBillingInfo({
          ...billing.address,
          method: action.payload.method,
          token: {
            kind: 'gift_card',
          },
        }),
      );
      yield put(reviewOrderSuccess(action.payload.kind));
      return;
    }

    if (action.payload.kind === OrderType.PaymentRequest) {
      const maybeToken = yield select(selectCheckoutToken);
      yield put(
        submitOrder({
          kind: action.payload.kind,
          method: action.payload.method,
          token: action.payload.token?.id || maybeToken,
          onSuccess: action.payload.onSuccess,
          onError: action.payload.onError,
        }),
      );
    } else if (action.payload.kind === OrderType.PaymentGateway) {
      const hasOPC = hasEquipmentLeaseInCart(cart);
      const isFaasQuickpayCheckout = isQuickPayCheckoutEnabled && hasOPC;

      let paymentMethod = null;
      const billingDetails = billingInfoMapper({ billing, email });
      // handles creating the payment intent & method
      const { paymentMethod: payloadPaymentMethod } = action.payload;
      if (payloadPaymentMethod) {
        payloadPaymentMethod.billing_details = billingDetails as stripe.BillingDetails;
        paymentMethod = payloadPaymentMethod;
        yield put(
          preparePaymentsSuccess({
            kind: 'stripePaymentIntent',
            paymentGateway: action.payload.paymentGateway!,
            paymentMethod,
          }),
        );
      } else {
        yield put(
          preparePayments(
            action.payload.paymentGateway!,
            {
              amount: action.payload.cartTotal,
              currency: cart.currency,
            },
            billingDetails,
          ),
        );
        const { success, error } = yield race({
          success: take(PaymentActions.PreparePaymentsSuccess),
          error: take(PaymentActions.PreparePaymentsFailure),
        });

        if (error) {
          throw error.payload.error;
        }
        paymentMethod = success.payload.paymentMethod;
      }

      if (action.payload.onSuccess) {
        yield call(action.payload.onSuccess);
      }
      const locale = yield select(getLocale);
      const isDebitCardEnabled = yield select(
        getIsToggleActive('faas_debit_card_enabled'),
      );
      const card = paymentMethod.card;

      // We are rejecting prepaid cards for OPC orders because there is a risk
      // that the card they will run out of balance before the subscription
      // is charged
      const isPrepaid = card.funding === 'prepaid';
      const isNotDebit = card.funding !== 'debit';
      const isUK = locale === Locale.EnglishUnitedKingdom;
      const isDebitCheckEnable = isNotDebit && isDebitCardEnabled;
      const shouldCheckDebit = isDebitCheckEnable && isUK && hasOPC;
      const shouldCheckPrepaid = isPrepaid && hasOPC;
      if (shouldCheckDebit) {
        throw new Error('noCreditForRentals');
      }
      if (shouldCheckPrepaid) {
        throw new Error('noPrepaidForRentals');
      }
      //

      const billingInfo = {
        ...billing.address,
        token: {
          kind: 'stripePaymentIntent',
        },
        addressee: card.name,
        brand: card.brand,
        expMonth: card.exp_month,
        expYear: card.exp_year,
        last4: card.last4,
        method: action.payload.method,
      } as const;
      yield put(updateBillingInfo(billingInfo));

      if (isFaasQuickpayCheckout && action.payload.method === PaymentMethod.CreditCard) {
        yield put(
          submitOrder({
            kind: OrderType.PaymentGateway,
            method: billingInfo.method,
            token: billingInfo.token,
            captchaToken: action.payload.captchaToken,
            captchaVersion: 'enterprise',
            onError: action.payload.onError,
          }),
        );
        return;
      }
    }

    yield put(reviewOrderSuccess(action.payload.kind));
  } catch (error) {
    if (action.payload.onError) {
      yield call(action.payload.onError, error);
    }
    yield put(reviewOrderFailure(error));
    yield put(reportError({ error }));
  }
};

export const reviewWalletOrderSaga = function* (
  client: Client,
  action: ReviewWalletOrderAction,
): SagaIterator {
  try {
    const isEmailOverwriteEnabled = yield select(
      getIsToggleActive('quick_pay_cfu_checkout_email_overwrite'),
    );
    const emailOverwrite =
      isEmailOverwriteEnabled &&
      localStorage.getItem('quick_pay_cfu_checkout_email_overwrite');

    const userEmail = yield select(getSignedInUserEmail);
    const cartEmail = yield select(getCartEmail);
    const email =
      emailOverwrite || userEmail || cartEmail || action.payload.orderData.email;
    const shipping = action.payload.orderData.shipping;
    const billing = action.payload.orderData.billing;
    const cart = yield call(updateShipping, client, shipping);
    yield call(updateEmail, client, email);

    yield put(loadCartInitialSuccess(cart));

    let paymentMethod;
    const { paymentMethod: payloadPaymentMethod } = action.payload;
    const billingDetails = billingInfoMapper({ billing, email });

    // handles creating the payment intent & method
    if (payloadPaymentMethod) {
      payloadPaymentMethod.billing_details = billingDetails as stripe.BillingDetails;
      paymentMethod = payloadPaymentMethod;

      yield put(
        preparePaymentsSuccess({
          kind: 'stripePaymentIntent',
          paymentGateway: action.payload.paymentGateway!,
          paymentMethod,
        }),
      );
    } else {
      throw new Error('noPaymentMethodForWalletPayment');
    }

    const card = paymentMethod.card;
    if (!card) {
      throw new Error('noCardForWalletPayment');
    }

    const token = {
      kind: 'stripePaymentIntent',
    } as Token;

    // We are rejecting prepaid cards for OPC orders because there is a risk
    // that the card they will run out of balance before the subscription
    // is charged
    const isPrepaid = card.funding === 'prepaid';
    const hasOPC = hasEquipmentLeaseInCart(cart);
    if (isPrepaid && hasOPC) {
      throw new Error('noPrepaidForRentals');
    }
    //

    if (action.payload.onSuccess) {
      yield call(action.payload.onSuccess);
    }

    yield put(
      updateBillingInfo({
        ...billing.address,
        token,
        addressee: paymentMethod.billing_details.name!,
        brand: card.brand,
        expMonth: card.exp_month,
        expYear: card.exp_year,
        last4: card.last4,
        method: action.payload.method,
      }),
    );

    yield put(
      submitOrder({
        kind: action.payload.kind,
        method: action.payload.method,
        token,
        captchaToken: action.payload.captchaToken,
        captchaVersion: 'enterprise',
        onError: action.payload.onError,
      }),
    );
  } catch (error) {
    if (action.payload.onError) {
      yield call(action.payload.onError, error);
    }
    yield put(reviewOrderFailure(error));
    yield put(
      reportError({
        error,
        options: {
          tags: {
            userAction: 'express wallet checkout',
            walletMethod: action.payload.method,
          },
        },
      }),
    );
  }
};

export const reviewPageSaga = function* (action: ReviewOrderSuccessAction): SagaIterator {
  if (
    action.payload.kind === OrderType.PaymentGateway ||
    action.payload.kind === OrderType.GiftCard
  ) {
    yield put(push('/checkout/review'));
  }
};

const sagas = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);

  yield takeEvery(OrderActions.ReviewOrder, reviewOrderSaga, client);
  yield takeEvery(OrderActions.ReviewWalletOrder, reviewWalletOrderSaga, client);
  yield takeEvery(OrderActions.ReviewOrderSuccess, reviewPageSaga);
};

export default sagas;

export const resolveAddressSaga = function* (): Iterator<any, any, any> {
  const reduxShipping: Address = yield select(getCheckoutAddress, {
    addressType: AddressType.Shipping,
  });

  const reduxBilling: Address = yield select(getCheckoutAddress, {
    addressType: AddressType.Billing,
  });

  const shipping = toContactInfoFromAddress(reduxShipping);
  const billing = toContactInfoFromAddress(reduxBilling);

  return yield {
    shipping,
    billing: billing.address.line1 ? billing : shipping,
  } as const;
};
