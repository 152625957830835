import React, { useState, useContext, createContext } from 'react';
import { getPostalCodeRegexForLocale, toLocale } from '@peloton/internationalize';

const PostalCodeContext = createContext<{
  postalCode: string;
  setPostalCode(postalCode: string, persist?: boolean): void;
  resetPostalCode(): void;
}>({
  postalCode: '',
  setPostalCode: () => undefined,
  resetPostalCode: () => undefined,
});

export const usePostalCodeContext = () => useContext(PostalCodeContext);

type Props = {
  children: React.ReactNode;
};

export const STORAGE_KEY = 'ecomm/postal-code-persistance';

const toPersistedPostalCode = (key: string) => {
  try {
    return localStorage.getItem(key) ?? '';
  } catch {
    return '';
  }
};

const updatePersistedPostalCode = (postalCode: string) => {
  const persistedPostalCode = toPersistedPostalCode(STORAGE_KEY);
  if (postalCode !== persistedPostalCode) {
    try {
      localStorage.setItem(STORAGE_KEY, postalCode);
    } catch {
      // shhhh it's okay
    }
  }
};

const isValidPostalCode = (postalCode: string) => {
  const locale = toLocale();
  const postalRegExp = getPostalCodeRegexForLocale(locale);
  return postalCode.match(postalRegExp) !== null;
};

export const PostalCodeProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  const [postalCode, setPostalCode] = useState(toPersistedPostalCode(STORAGE_KEY));
  const resetPostalCode = () => {
    setPostalCode('');
    updatePersistedPostalCode('');
  };

  const setPostalCodePersist = (newPostalCode: string, persist = true) => {
    if (persist && isValidPostalCode(newPostalCode)) {
      updatePersistedPostalCode(newPostalCode);
    }
    setPostalCode(newPostalCode);
  };

  return (
    <PostalCodeContext.Provider
      value={{
        setPostalCode: setPostalCodePersist,
        resetPostalCode,
        postalCode,
      }}
    >
      {children}
    </PostalCodeContext.Provider>
  );
};
