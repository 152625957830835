import { pathOr } from 'ramda';
import type { Category, Entities } from '../models';
import { mergeDeepWithUnion } from '../models';

export enum ActionType {
  Add = 'ecomm/shop/ADD_CATEGORIES',
}

export type State = Entities<Category>;

export const defaultState = {};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.Add: {
      const categories = pathOr({}, ['payload', 'categories'], action);
      return mergeDeepWithUnion(state, categories);
    }
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  categories: State;
};

export const addCategories = (categories: Entities<Category>): AddCategoriesAction => ({
  type: ActionType.Add,
  payload: { categories },
});

type Action = AddCategoriesAction;

type AddCategoriesAction = {
  type: ActionType.Add;
  payload: { categories: Entities<Category> };
};
