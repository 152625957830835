import { grey, white, Icon, spacing, Eyebrow, red } from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import type { Subnav } from '@ecomm/copy/helpers/types';
import SubSecondaryMenuFlyout from './SubSecondaryMenuFlyout';

type Props = {
  apparelData: Subnav;
  onSubCloseRequest: () => void;
};

const SubMenuItemWithFlyout: React.FC<React.PropsWithChildren<Props>> = ({
  apparelData,
  onSubCloseRequest,
}) => {
  const closeSubSecondaryPanel = () => setSubSecondaryPanelOpen(false);
  const [subSecondaryPanelOpen, setSubSecondaryPanelOpen] = useState<boolean>(false);
  const { trackEvent } = useTracking();

  const isTitleSale =
    'title' in apparelData &&
    apparelData.title &&
    apparelData.title.toLowerCase() === 'sale';

  return (
    <li data-test-id="submenu-item-with-flyout">
      <MenuItem
        aria-expanded={subSecondaryPanelOpen}
        data-test-id={apparelData.key}
        onClick={() => {
          trackEvent({
            properties: {
              category: apparelData.key,
              linkName: 'title' in apparelData ? apparelData.title : '',
            },
          });
          setSubSecondaryPanelOpen(true);
        }}
      >
        <StyledEyebrow
          textColor={isTitleSale ? red[50] : white}
          data-test-id="submenu-item-title"
          size="large"
        >
          {'title' in apparelData ? apparelData.title : ''}
        </StyledEyebrow>
        <StyledChevron
          name="chevron"
          primaryColor={grey[50]}
          aria-hidden={true}
          rotate={180}
          height={12}
        />
      </MenuItem>
      <SubSecondaryMenuFlyout
        apparelData={apparelData}
        subData={'subnavs' in apparelData ? apparelData.subnavs : []}
        isOpen={subSecondaryPanelOpen}
        onSubCloseRequest={onSubCloseRequest}
        onSubBackRequest={closeSubSecondaryPanel}
      />
    </li>
  );
};

export default SubMenuItemWithFlyout;

const MenuItem = styled.button`
  color: ${white};
  display: flex;
  align-items: center;
`;

const StyledChevron = styled(Icon)`
  margin-left: ${spacing[8]};
  margin-bottom: ${spacing[2]};
`;

const StyledEyebrow = styled(Eyebrow)`
  font-size: 18px;
  line-height: 1.375em;
`;
