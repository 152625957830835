// NOTE: This format will likely soon be deprecated in favor of app level routing.
// If you are seeing this in the far future, something has gone wrong.
import React from 'react';
import { Redirect } from 'react-router';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

export const PATH = '/careers';

const route = [
  // Redirect is to hide /company/careers since the old saga redirect wasn't working in pg-careers
  <Redirect key="careers-redirect" from="/company/careers" to={PATH} />,
  <Redirect
    key="tempe-redirect"
    from="/tempe"
    to={`${PATH}?city=%C2%A0%C2%A0%C2%A0%C2%A0%C2%A0%C2%A0%C2%A0%C2%A0Tempe`}
  />,
  <Redirect
    key="troy-redirect"
    from="/troy"
    to={`${PATH}?city=%C2%A0%C2%A0%C2%A0%C2%A0%C2%A0%C2%A0%C2%A0%C2%A0Troy`}
  />,
  <Route
    key={PATH}
    path={`${PATH}/:department?`}
    component={asyncComponent(() => import('./Page' /* webpackChunkName: "Careers" */))}
  />,
];

export default route;
