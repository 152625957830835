import toFormattedText from '@peloton/copy/toFormattedText';
import { toLocale as toLocalePeloton } from '@peloton/internationalize';
import { toDiffDuration, toNowTime } from '@peloton/time';
import type { Time } from '@peloton/time';
import useCartOtdContent from '@ecomm/cart/summary/OtdEstimate/useCartOtdContent';

export type CalculationStatus =
  | 'OK'
  | 'INSUFFICIENT_DATA'
  | 'POSTAL_CODE_CANT_SELF_SCHEDULE'
  | 'ERROR'
  | 'INVALID_POSTAL_CODE'
  | 'INELIGIBLE_POSTAL_CODE'
  | 'PREORDER'
  | 'DISABLED';

// appends "INITIAL" for use in forms and with the "useMessaging" hook
export type CalculationRequestStatus = CalculationStatus | 'INITIAL';

export type InelligibleForPurchase = 'INELLIGIBLE_FOR_PURCHASE';

export type DynamicOtdEstimate = {
  startDate: string;
  endDate: string;
};

export const toLocalizedDate = (dateStr: string) => {
  // Note: we're adding a timestamp to localize our ISO date string
  // to 12 AM for our given locale (prevents dates from rolling over by Greenwich time)
  return new Date(dateStr + 'T00:00:00');
};

export const toFormattedDateString = (
  dateStr: string,
  // dependency injected for unit testing
  toLocale: typeof toLocalePeloton = toLocalePeloton,
) => {
  const date = toLocalizedDate(dateStr);
  return date.toLocaleDateString(toLocale(), { month: 'short', day: 'numeric' });
};

export const useFormattedEstimateDateRange = (startDate: string, endDate: string) => {
  const isDateRange = startDate !== endDate;
  const estimateContentKey = isDateRange
    ? 'otd_estimate_date_range'
    : 'otd_estimate_single_date';
  const estimateContentValues = isDateRange
    ? { startDate, endDate }
    : { date: startDate };
  const estimateContent = useCartOtdContent(estimateContentKey)?.text;
  return `${toFormattedText(estimateContent, estimateContentValues)}`;
};

export const toDaysFromToday = (
  time: Time,
  injectedToNowTime: typeof toNowTime = toNowTime,
) => {
  return Math.round(toDiffDuration(injectedToNowTime().startOf('day'), time).asDays());
};
