import { DomainError } from '@peloton/domain-error';
import { useErrorReporter } from '@peloton/error-reporting';
import type { AvailabilityState } from '../models';
import { ProductStates, UNAVAILABLE } from '../models';
import useProductLinesQuery from './useProductLinesQuery';

type ProductLines = Record<string, AvailabilityState>;

const UNAVAILABLE_STATE: AvailabilityState = {
  state: ProductStates.Unavailable,
  code: UNAVAILABLE,
};

const useProductLineAvailabilityStates = (): ProductLines => {
  const { data, error } = useProductLinesQuery();
  const { errorReporter } = useErrorReporter();

  if (error) {
    const domainError = new DomainError(
      `Failed to retrieve availability states from Catalog.`,
      error,
    );
    errorReporter.reportError(domainError);
    throw domainError;
  }

  const productLines = data?.catalog?.productLines ?? [];

  return productLines.reduce(
    (acc, { productLine, product }) => ({
      ...acc,
      [productLine]: product?.availability ?? UNAVAILABLE_STATE,
    }),
    {},
  );
};

export default useProductLineAvailabilityStates;
