import React from 'react';
import { connect } from 'react-redux';
import { toFormattedText } from '@peloton/copy';
import type { State as EnvState } from '@peloton/env';
import { getLocale } from '@peloton/env';
import { ErrorBoundary } from '@peloton/error-reporting';
import type { ExtLinkEnv, ExtLinkEnvState, PeloLink } from '@peloton/external-links';
import { getExtLinkEnv } from '@peloton/external-links';
import type { Locale } from '@peloton/internationalize';
import type { Metadata } from '@ecomm/copy';
import { useMetadata, usePromotionalTextData } from '@ecomm/copy';
import MetadataTagsView from './MetadataTagsView';

type OwnProps = {
  canonicalLink: PeloLink;
  id: Metadata;
  includeCompanyPrefix?: boolean;
  companyPrefixPosition?: 'start' | 'end';
  values?: Record<string, string>;
};

type StateProps = {
  extLinkEnv: ExtLinkEnv;
  locale: Locale;
};

type Props = OwnProps & StateProps;

export const MetadataTags: React.FC<React.PropsWithChildren<Props>> = ({
  canonicalLink,
  extLinkEnv,
  id,
  includeCompanyPrefix = true,
  companyPrefixPosition = 'start',
  locale,
  values,
}) => {
  const { title, description, keywords, ogtype, image } = useMetadata(id);
  const companyPrefixText = usePromotionalTextData('companyPrefix');
  const companyPostfixText = usePromotionalTextData('companyPostfix');

  const companyPrefix = companyPrefixPosition === 'start' ? companyPrefixText : '';
  const companyPostfix = companyPrefixPosition === 'end' ? companyPostfixText : '';

  const formattedPageTitle = toFormattedText(title, values) as string;
  const prefixedPageTitle = includeCompanyPrefix
    ? `${companyPrefix}${formattedPageTitle}${companyPostfix}`
    : formattedPageTitle;

  return (
    <ErrorBoundary renderError={() => <></>}>
      <MetadataTagsView
        canonicalLink={canonicalLink}
        description={toFormattedText(description, values) as string}
        extLinkEnv={extLinkEnv}
        imageUrl={image ? image.url : undefined}
        keywords={keywords}
        locale={locale}
        ogtype={ogtype || 'website'}
        title={prefixedPageTitle}
      />
    </ErrorBoundary>
  );
};

export const mapStateToProps = (state: ExtLinkEnvState & EnvState): StateProps => ({
  extLinkEnv: getExtLinkEnv(state),
  locale: getLocale(state),
});

export default connect<StateProps, OwnProps>(mapStateToProps)(MetadataTags);
