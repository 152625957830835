import type { Saga, SagaIterator, Task } from 'redux-saga';
import type { ActionPattern } from 'redux-saga/effects';
import { call, cancel, fork, take } from 'redux-saga/effects';

const toForkOrCancel = (
  pattern: ActionPattern,
  predicate: Saga<boolean[]> | ((x?: any) => boolean),
  worker: Saga,
) =>
  function* (): SagaIterator {
    let task: Task | undefined;
    while (true) {
      yield take(pattern);

      const matches = yield call(predicate);

      if (matches && (!task || (task && !task.isRunning()))) {
        task = yield fork(worker);
      } else if (!matches && task) {
        task = yield cancel(task);
      }
    }
  };

export default toForkOrCancel;
