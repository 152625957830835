import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import type { Slug } from '@ecomm/models';
import { fetchProductBySlug } from '../api';
import { toProductConfiguration } from '../models';
import type { RequestProductSlugAction } from '../redux';
import {
  addCategories,
  addProductConfigurations,
  addDimensions,
  addProducts,
  addProductOptions,
  addTraits,
  productSlugFetchFailed,
  productSlugFetchSucceeded,
  UiActionType,
  getProductBySlug,
} from '../redux';

export const fetchSaga = function* (slug: Slug): SagaIterator {
  try {
    const client = yield getContext(CLIENT_CONTEXT);
    const normalizedResponse = yield call(fetchProductBySlug, client, slug);

    const {
      entities: { categories, dimensions, options, products, traits },
    } = normalizedResponse;

    const productConfigurations = yield call(toProductConfiguration, products);

    // Reference/undefined errors were occuring in selectors because entities
    // were being put into redux before their dependencies (doesnt seem to
    // matter that promise.all was being used)
    yield all([
      put(addTraits(traits)),
      put(addDimensions(dimensions)),
      put(addProductOptions(options)),
      put(addProducts(products)),
      put(addCategories(categories)),
      put(addProductConfigurations(productConfigurations)),
    ]);

    yield put(productSlugFetchSucceeded(slug));
  } catch (err) {
    const error = new DomainError(`Error fetching ${slug} in fetchSaga saga`, err);
    yield all([put(reportError({ error })), put(productSlugFetchFailed(slug))]);
  }
};

export const checkStoreSaga = function* (action: RequestProductSlugAction): SagaIterator {
  const product = yield select(getProductBySlug, { slug: action.payload.slug });
  if (!product) {
    yield call(fetchSaga, action.payload.slug);
  } else {
    yield put(productSlugFetchSucceeded(action.payload.slug));
  }
};

const watcherSaga = function* () {
  yield takeEvery(UiActionType.ProductSlugRequest, checkStoreSaga);
};

export default watcherSaga;
