import React from 'react';

const Dismiss: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg role="img" width={18} height={18} {...props}>
    <circle cx={9} cy={9} r={9} />
    <path
      d="M9.755 9l2.692-2.703a.521.521 0 0 0-.01-.747.55.55 0 0 0-.764.01L9 8.243 6.327 5.56a.548.548 0 0 0-.764-.01.52.52 0 0 0-.01.747L8.245 9l-2.692 2.702a.52.52 0 0 0 .01.748.544.544 0 0 0 .764-.01L9 9.757l2.673 2.683a.545.545 0 0 0 .764.01.521.521 0 0 0 .01-.748L9.755 9z"
      fill="#FFF"
    />
  </svg>
);

export default Dismiss;
