import { throttle } from 'frame-throttle';
import type { Dispatch, SetStateAction } from 'react';
import { useState, useEffect } from 'react';
import { isBrowser } from '@peloton/browser/identify';
import usePrevious from './usePrevious';

export enum ScrollDirection {
  Up = 'up',
  Down = 'down',
}

export type ScrollPosition = {
  position: number;
  direction: ScrollDirection;
  setPosition: Dispatch<SetStateAction<number>>;
};

const useScrollPosition = (): ScrollPosition => {
  const [position, setPosition] = useState(isBrowser ? window.pageYOffset : 0);
  const prevPosition = usePrevious(position);

  useEffect(() => {
    const throttled = throttle(() => setPosition(window.pageYOffset));

    window.addEventListener('scroll', throttled);
    return () => window.removeEventListener('scroll', throttled);
  }, []);

  return {
    position,
    direction: position > prevPosition ? ScrollDirection.Down : ScrollDirection.Up,
    setPosition,
  };
};

export default useScrollPosition;
