import type {
  ReducerState as TReducerState,
  CheckoutRequestAction as TCheckoutRequestAction,
  CheckoutFailAction as TCheckoutFailAction,
  CheckoutSuccessAction as TCheckoutSuccessAction,
} from './creditCardValidation';
import reducer, {
  ActionTypes,
  checkoutSuccess,
  failCheckout,
  requestCreditCheckout,
} from './creditCardValidation';

import { getCheckoutState } from './selectors';

export default reducer;

export {
  ActionTypes,
  checkoutSuccess,
  failCheckout,
  getCheckoutState,
  requestCreditCheckout,
};

export type CheckoutFailAction = TCheckoutFailAction;
export type CheckoutRequestAction = TCheckoutRequestAction;
export type CheckoutSuccessAction = TCheckoutSuccessAction;
export type ReducerState = TReducerState;
