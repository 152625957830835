import type { Saga, SagaIterator } from 'redux-saga';
import { call, take } from 'redux-saga/effects';

const takeFirst = function* (
  saga: Saga,
  action: string,
  arg: any = undefined,
): SagaIterator {
  while (true) {
    const data = yield take(action);
    if (data && data.payload) {
      return yield call(saga, arg, data.payload);
    }
    return yield call(saga, arg);
  }
};

export default takeFirst;
