import type { UIState } from '@ecomm/models';
import { Status } from '@ecomm/models';
import type { PressHeadline, PressQuote } from './models';

export enum ActionType {
  HeadlinesRequest = 'press/headlines/REQUEST',
  HeadlinesSuccess = 'press/headlines/SUCCESS',
  HeadlinesLoadMore = 'press/headlines/LOAD_MORE',
  QuotesRequest = 'press/quotes/REQUEST',
  QuotesSuccess = 'press/quotes/SUCCESS',
}

type PressHeadlinesRequest = {
  type: ActionType.HeadlinesRequest;
};

type PressHeadlinesSuccess = {
  type: ActionType.HeadlinesSuccess;
  payload: PressHeadline[];
};

type PressHeadlinesLoadMore = {
  type: ActionType.HeadlinesLoadMore;
};

type PressQuotesRequest = {
  type: ActionType.QuotesRequest;
};

type PressQuotesSuccess = {
  type: ActionType.QuotesSuccess;
  payload: PressQuote[];
};

type State = {
  headlines: {
    uiState?: UIState;
    pressHeadlines?: PressHeadline[];
    headlinesLimit: number;
  };
  quotes: {
    uiState?: UIState;
    pressQuotes?: PressQuote[];
  };
};

export type ReducerState = {
  press: State;
};

type Action =
  | PressHeadlinesRequest
  | PressHeadlinesSuccess
  | PressHeadlinesLoadMore
  | PressQuotesRequest
  | PressQuotesSuccess;

const initialState: State = {
  headlines: {
    headlinesLimit: 15,
  },
  quotes: {},
};

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.HeadlinesRequest:
      return {
        ...state,
        headlines: { ...state.headlines, uiState: { status: Status.Loading } },
      };
    case ActionType.HeadlinesSuccess:
      return {
        ...state,
        headlines: {
          ...state.headlines,
          uiState: { status: Status.Loaded },
          pressHeadlines: action.payload,
        },
      };
    case ActionType.HeadlinesLoadMore:
      return {
        ...state,
        headlines: {
          ...state.headlines,
          headlinesLimit: state.headlines.headlinesLimit + 15,
        },
      };
    case ActionType.QuotesRequest:
      return {
        ...state,
        quotes: { ...state.quotes, uiState: { status: Status.Loading } },
      };
    case ActionType.QuotesSuccess:
      return {
        ...state,
        quotes: {
          ...state.quotes,
          uiState: { status: Status.Loaded },
          pressQuotes: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;

export const pressHeadlinesRequest = (): Action => ({
  type: ActionType.HeadlinesRequest,
});

export const pressQuotesRequest = (): Action => ({
  type: ActionType.QuotesRequest,
});

export const pressHeadlinesSuccess = (pressHeadlines: PressHeadline[]): Action => ({
  type: ActionType.HeadlinesSuccess,
  payload: pressHeadlines,
});

export const pressQuotesSuccess = (pressQuotes: PressQuote[]): Action => ({
  type: ActionType.QuotesSuccess,
  payload: pressQuotes,
});

export const pressHeadlinesLoadMore = () => ({
  type: ActionType.HeadlinesLoadMore,
});

export const getHeadlinesUIState = (state: ReducerState) => state.press.headlines.uiState;

export const getQuotesUIState = (state: ReducerState) => state.press.quotes.uiState;

export const getPressHeadlines = (state: ReducerState) =>
  state.press.headlines.pressHeadlines;

export const getPressQuotes = (state: ReducerState) => state.press.quotes.pressQuotes;

export const getHeadlinesLimit = (state: ReducerState) =>
  state.press.headlines.headlinesLimit;
