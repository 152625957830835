import React from 'react';
import styled from 'styled-components';
import { screenReaderOnly } from '@peloton/accessibility';

type Props = {
  isLoading: boolean;
  loadingMessageText?: string;
};

const ScreenReaderText = styled.span`
  ${screenReaderOnly}
`;

export const LoadingStatusWithoutCopyHooks: React.FC<React.PropsWithChildren<Props>> = ({
  isLoading,
  loadingMessageText,
}) => (
  <ScreenReaderText aria-live="assertive" role="alert">
    {isLoading ? loadingMessageText : null}
  </ScreenReaderText>
);
