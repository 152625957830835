import styled from 'styled-components';
import PageSpinner from '@ecomm/spinner/PageSpinner';
// TODO: For ^^^ Can copy the spinner svg over when migrating to Page Builder Repo...or just don't take this with us.

const StyledPageSpinner = styled(PageSpinner)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999999;
  background-color: white;
  align-items: center;
  margin: 0;
`;

export default StyledPageSpinner;
