import type { ShowroomProps } from './model';

export const filterOutInvisibleShowrooms = (showrooms: ShowroomProps[]) => {
  return showrooms.filter(showroom => {
    return showroom.isVisible && showroom.latitude && showroom.longitude;
  });
};

export const getOperationalShowrooms = (showrooms: ShowroomProps[]) => {
  return showrooms.filter(showroom => !showroom.isComingSoon);
};
