import { takeEvery } from 'redux-saga/effects';
import { RegisterTypes } from '@ecomm/registration/redux';
import { toCheckRouteSaga } from '@ecomm/saga-utils';
import { matchRegister as matcher } from '../route';
import redirectOnSuccess from './redirectOnSuccess';

export const checkRouteAndRedirect = toCheckRouteSaga({
  matcher,
  onMatch: redirectOnSuccess,
});

const watcher = function* () {
  yield takeEvery(RegisterTypes.RegistrationSucceeded, checkRouteAndRedirect);
};

export default watcher;
