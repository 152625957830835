import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { usePromotionalTextData } from '@ecomm/copy';
import { TogglePause, TogglePlay } from '@ecomm/icons';

export type ToggleButtonTheme = { theme?: 'light' | 'dark' };

export type ToggleProps = ToggleButtonTheme & {
  onClick: () => void;
  playing?: boolean;
};

const CarouselVideoControl: React.FC<React.PropsWithChildren<ToggleProps>> = ({
  onClick,
  playing,
  theme,
}) => {
  const pauseLabel = usePromotionalTextData('playPauseToggleDefaultPauseLabel');
  const playLabel = usePromotionalTextData('playPauseToggleDefaultPlayLabel');

  return (
    <StyledButton onClick={onClick}>
      {playing ? (
        <TogglePause label={pauseLabel} theme={theme} />
      ) : (
        <TogglePlay label={playLabel} theme={theme} />
      )}
    </StyledButton>
  );
};

export default CarouselVideoControl;

CarouselVideoControl.displayName = 'CarouselVideoControl';

const StyledButton = styled.button`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  padding: 16px;
  ${media.desktopLarge`
    padding: 24px;
  `}

  svg {
    width: 26px;

    ${media.desktopLarge`
      width: 34px;
    `}
  }

  transition: opacity 250ms ease;
  &:hover {
    opacity: 0.6;
  }
`;
