export type CreditCard = {
  brand: string;
  expMonth: number;
  expYear: number;
  last4: string;
};

export const isCreditCardComplete = ({
  brand,
  expMonth,
  expYear,
  last4,
}: CreditCard): boolean =>
  Boolean(brand.trim()) && Boolean(expMonth) && Boolean(expYear) && Boolean(last4.trim());

export const toFormattedMonth = (month: number | string) =>
  month.toString().length > 1 ? `${month}` : `0${month}`;

export const toFormattedYear = (year: number | string) =>
  `${year.toString().length > 2 ? year.toString().slice(2, 4) : year}`;
