import { lensPath, set } from 'ramda';
import type { Slug } from '@ecomm/models';
import type { Entities, PackageSelections, ProductSelection } from '../../models';

enum ActionType {
  UpdateSinglePackageSelection = 'ecomm/shop/UPDATE_PACKAGE_SELECTION',
  UpdateAllPackageSelections = 'ecomm/shop/UPDATE_ALL_PACKAGE_SELECTIONS',
}

type State = Entities<PackageSelections>;

export const initialState: State = {};

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.UpdateSinglePackageSelection: {
      const { packageSlug, productIndex, productSelection } = action.payload;
      const productSlug = productSelection.product;
      const selections = productSelection.selections;
      const lens = lensPath([packageSlug, productSlug, productIndex]);
      return set(lens, selections.length > 0 ? productSelection : null, state);
    }
    case ActionType.UpdateAllPackageSelections: {
      const { packageSlug, productSelections, productSlug } = action.payload;

      const lens = lensPath([packageSlug, productSlug]);
      return set(lens, productSelections, state);
    }

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = { packages: State };

type SinglePackageSelectionPayload = {
  packageSlug: Slug;
  productIndex: number;
  productSelection: ProductSelection;
};

export type FullPackageSelectionPayload = {
  packageSlug: Slug;
  productSlug: Slug;
  productSelections: ProductSelection[];
};

export const updateSinglePackageSelection = (payload: SinglePackageSelectionPayload) =>
  ({
    type: ActionType.UpdateSinglePackageSelection,
    payload,
  } as const);

export const updateAllPackageSelections = (payload: FullPackageSelectionPayload) =>
  ({
    type: ActionType.UpdateAllPackageSelections,
    payload,
  } as const);
type Action = ReturnType<
  typeof updateSinglePackageSelection | typeof updateAllPackageSelections
>;
