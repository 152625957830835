import type { ReactNode } from 'react';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Menu } from './models';

type HeaderContextData = {
  currentMenu: Menu;
  setCurrentMenu: React.Dispatch<React.SetStateAction<Menu>>;
  focusedMenu: Menu;
  setFocusedMenu: React.Dispatch<React.SetStateAction<Menu>>;
  hideMenu: () => void;
};

const initialState = {
  currentMenu: Menu.None,
  setCurrentMenu: () => {},
  focusedMenu: Menu.None,
  setFocusedMenu: () => {},
  hideMenu: () => {},
};

export const HeaderNavContext = createContext<HeaderContextData>(initialState);

type Props = {
  children: ReactNode;
};

const HeaderNavProvider: React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
  const [currentMenu, setCurrentMenu] = useState<Menu>(Menu.None);
  const [focusedMenu, setFocusedMenu] = useState<Menu>(Menu.None);

  useEffect(() => {
    const resetMenu = () => {
      setCurrentMenu(Menu.None);
      setFocusedMenu(Menu.None);
    };

    window.addEventListener('orientationchange', resetMenu);

    return () => {
      window.removeEventListener('orientationchange', resetMenu);
    };
  }, []);

  const hideMenu = () => {
    setCurrentMenu(Menu.None);
  };

  const value = useMemo(
    () => ({
      currentMenu,
      setCurrentMenu,
      focusedMenu,
      setFocusedMenu,
      hideMenu,
    }),
    [currentMenu, focusedMenu],
  );

  return <HeaderNavContext.Provider value={value}>{children}</HeaderNavContext.Provider>;
};

export default HeaderNavProvider;
