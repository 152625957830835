import type { SagaIterator } from 'redux-saga';
import { call, getContext, select, takeEvery, put } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { updateShipping } from '@ecomm/cart/api';
import { _setGiftingInformation } from '@ecomm/cart/api/gifting';
import { loadCartInitialSuccess } from '@ecomm/cart/redux';
import type { Address } from '@ecomm/checkout/models';
import { AddressType, toContactInfoFromAddress } from '@ecomm/checkout/models';
import { updateShippingFailure, getCheckoutAddress } from '@ecomm/checkout/redux';
import { Partners } from '@ecomm/financing/models';
import { PaymentMethod } from '@ecomm/models';
import type { SubmitFinancingAction } from '../redux';
import { Actions, submitFinancingFailure } from '../redux';
import { submitFinancingSaga as affirmSubmitFinancing } from './affirmSaga';
import { submitSaga as CitizensSubmitFinancing } from './citizensSaga';
import { submitSaga as klarnaSubmitFinancing } from './klarnaSaga';
import { submitSaga as ZipSubmitFinancing } from './zipSaga';

export const submitFinancingSaga = function* (
  client: Client,
  action: SubmitFinancingAction,
): SagaIterator {
  try {
    const { shipping, billing } = yield call(resolveAddressSaga);
    const cart = yield call(updateShipping, client, shipping);
    yield put(loadCartInitialSuccess(cart));
    action.payload.billing = billing;
    action.payload.shipping = shipping;

    const isGiftingEnhancementEnabled =
      action.payload.orderData?.isGiftingEnhancementEnabled;

    if (isGiftingEnhancementEnabled && cart.isGift) {
      const giftingInformation = {
        cartId: cart.id,
        recipientName: shipping.name.first,
        gifterName: action.payload.orderData?.gifterName,
        recipientEmail: action.payload.orderData?.recipientEmail,
        giftMessage: action.payload.orderData?.giftMessage,
      };
      yield call(_setGiftingInformation, giftingInformation);
    }

    switch (action.payload.financingPartner) {
      case Partners.Affirm:
        yield call(affirmSubmitFinancing, client, action);
        break;
      case Partners.Klarna:
        yield call(
          klarnaSubmitFinancing,
          PaymentMethod.Financing,
          action.payload.billing,
        );
        break;
      case Partners.PayBright:
        yield call(
          klarnaSubmitFinancing,
          PaymentMethod.Financing,
          action.payload.billing,
        );
        break;
      case Partners.Citizens:
        yield call(CitizensSubmitFinancing, client, action);
        break;
      case Partners.Zip:
        yield call(ZipSubmitFinancing, client, action);
        break;
      default:
        break;
    }
  } catch (error) {
    yield put(updateShippingFailure(error));
    yield put(submitFinancingFailure(error));
  }
};

const financingSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);

  yield takeEvery(Actions.SubmitFinancing, submitFinancingSaga, client);
};

export default financingSaga;

export const resolveAddressSaga = function* (): Iterator<any, any, any> {
  const reduxShipping: Address = yield select(getCheckoutAddress, {
    addressType: AddressType.Shipping,
  });

  const reduxBilling: Address = yield select(getCheckoutAddress, {
    addressType: AddressType.Billing,
  });

  const shipping = toContactInfoFromAddress(reduxShipping);
  const billing = toContactInfoFromAddress(reduxBilling);

  return yield {
    shipping,
    billing: billing.address.line1 ? billing : shipping,
  } as const;
};
