import type { Pricing } from '@ecomm/copy/helpers/useFinancingValues';
import { useFinancingValues } from '@ecomm/copy/helpers/useFinancingValues';
import type { HeaderBodyImageData } from '@ecomm/copy/helpers/useHeaderBodyImageData';
import type useHeaderBodyImageData from '@ecomm/copy/helpers/useHeaderBodyImageData';
import type useLegalTextData from '@ecomm/copy/helpers/useLegalTextData';
import type { HeaderBodyImage, LegalText, QAndA } from '@ecomm/copy/models.generated';
import type { FaqQuestion } from './useFaqData';
import type useFaqData from './useFaqData';
export interface CopyWithFinancingCallTypes {
  (copyHook: typeof useLegalTextData, id: LegalText): {
    content: string;
    pricing: Pricing;
  };
  (copyHook: typeof useHeaderBodyImageData, id: HeaderBodyImage): {
    content: HeaderBodyImageData;
    pricing: Pricing;
  };
  (copyHook: typeof useFaqData, id: QAndA): {
    content: FaqQuestion;
    pricing: Pricing;
  };
}
export const useCopyWithFinancing: CopyWithFinancingCallTypes = <T extends Function, K>(
  copyHook: T,
  id: K,
) => {
  const content = copyHook(id);
  const pricing = useFinancingValues();

  return {
    content,
    pricing,
  };
};

export default useCopyWithFinancing;
