import { asyncComponent } from '@peloton/code-splitting';

const PersonalizedNotification = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "PersonalizedNotification" */ './PersonalizedNotification'
    ),
);

export { PersonalizedNotification };
