import { useSelector } from 'react-redux';
import type { SplitTestingState } from '@peloton/split-testing';
import { getExperiment } from '@peloton/split-testing';

export const HOMEPAGE_HERO_CAROUSEL_EXPERIMENT = 'Titan Holiday 2021';
export const ORIGINAL = 'Original';
export const VARIATION = 'Variation #1';

const getHomepageHeroCarouselExperimentState = (state: SplitTestingState) =>
  getExperiment(state, HOMEPAGE_HERO_CAROUSEL_EXPERIMENT);

export type ActiveExperiment = {
  isOriginal: boolean | undefined;
  isVariation: boolean;
};

const useHomepageHeroCarouselExperiment = (): ActiveExperiment => {
  const experimentState = useSelector(getHomepageHeroCarouselExperimentState);

  return {
    isOriginal: !experimentState || experimentState === ORIGINAL,
    isVariation: experimentState === VARIATION,
  };
};

export default useHomepageHeroCarouselExperiment;
