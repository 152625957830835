import type { Client } from '@peloton/api';
import { pipeDataTwice } from '@peloton/api';
import { ItemType } from '../models';
import { toCart } from './mapper';

const toRemoveBundleUrl = (id: string) => `/ecomm/remove_bundled_item/${id}`;
const toRemoveItemUrl = (id: string, quantity: number) =>
  `/ecomm/remove_item/${id}/${quantity}`;

// TODO: error/loading handling
export const removeItemFromCart = async (
  api: Client,
  id: string,
  type: ItemType,
  quantity: number,
) => {
  let apiResponse;
  if (type === ItemType.Item || type === ItemType.Subscription) {
    apiResponse = await api.post(toRemoveItemUrl(id, quantity));
  } else if (type === ('tradein' as ItemType)) {
    const removeTradeInUrl = '/ecomm/remove_trade_in';
    apiResponse = await api.post(removeTradeInUrl);
  } else if (type === ItemType.Device) {
    apiResponse = await api.post(toRemoveBundleUrl(id), {
      removeSubAndWarranty: true,
    });
  } else {
    apiResponse = await api.post(toRemoveBundleUrl(id));
  }

  return pipeDataTwice(toCart)(apiResponse);
};
