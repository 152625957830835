import { MONTHS_IN_YEAR } from '@membership/models/Time';
import toFormattedText from '@peloton/copy/toFormattedText';
import { toDollars } from '@peloton/models/Money';

export const toFormattedPrice = (
  priceInCents: number,
  forceRounding?: boolean,
): string => {
  const price = toDollars(priceInCents);
  let shouldRound = forceRounding;
  if (typeof forceRounding === 'undefined') {
    shouldRound = price % 1 === 0;
  }
  const currencyType = shouldRound ? 'currency' : 'currencyWithCents';
  return toFormattedText(`{price, number, ${currencyType}}`, {
    price,
  }) as string;
};

export const toRoundedYearlySavings = (
  monthlyCostInCents: number,
  yearlyCostInCents: number,
) => {
  const costInCents = monthlyCostInCents * MONTHS_IN_YEAR - yearlyCostInCents;
  const roundedCostInCents = Math.floor(costInCents / 100) * 100;

  if (roundedCostInCents > 0) {
    return toFormattedPrice(roundedCostInCents, true);
  }
  return null;
};
