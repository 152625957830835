import React from 'react';
import { Route } from '@ecomm/router';
import Handler from './Handler';
import { SHADOWED_ROUTES, CART_ROUTE } from './urls';

const Routes = [
  // PB Homepage now supercedes/falls back to this route
  // <Route key={HOME_ROUTE} exact={true} path={HOME_ROUTE} component={Handler} />,
  <Route key={CART_ROUTE} exact={true} path={CART_ROUTE} component={Handler} />,
  ...SHADOWED_ROUTES.map(route => (
    <Route key={route} exact={true} path={route} component={Handler} />
  )),
];

export default Routes;
