import type { Reducer } from 'redux';
import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { PaymentMethod } from '@ecomm/models';
import { Status } from '@ecomm/models';
import type { CheckoutPayload } from './models';

export type State = {
  isKlarnaLoaded: Status;
  isKlarnaPaymentAvailable: boolean;
  isKlarnaWidgetOpen: boolean;
  isLegalAccepted: boolean;
  isLegalModalOpen: boolean;
  isSubmitting: boolean;
  klarnaSDKStatus: Status;
};

const defaultState: State = {
  isKlarnaLoaded: Status.Init,
  isKlarnaPaymentAvailable: false,
  isKlarnaWidgetOpen: false,
  isLegalAccepted: false,
  isLegalModalOpen: false,
  isSubmitting: false,
  klarnaSDKStatus: Status.Init,
};

export const reducer: Reducer<State> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case Actions.KlarnaInitialize:
      return {
        ...state,
        klarnaSDKStatus: Status.Loading,
      };
    case Actions.KlarnaInitializeSuccess:
      return {
        ...state,
        klarnaSDKStatus: Status.Loaded,
      };
    case Actions.KlarnaLoad:
      return {
        ...state,
        isKlarnaLoaded: Status.Loading,
        isKlarnaPaymentAvailable: false,
        isSubmitting: false,
      };
    case Actions.KlarnaPaymentAvailable:
      return {
        ...state,
        isKlarnaPaymentAvailable: true,
      };
    case Actions.KlarnaPaymentUnavailable:
      return {
        ...state,
        isKlarnaPaymentAvailable: false,
      };
    case Actions.KlarnaLoadSuccess:
      return {
        ...state,
        isKlarnaLoaded: Status.Loaded,
        isSubmitting: false,
      };
    case Actions.KlarnaOpenWidget:
      return {
        ...state,
        isKlarnaWidgetOpen: true,
      };
    case Actions.KlarnaCloseWidget:
      return {
        ...state,
        isSubmitting: false,
        isKlarnaWidgetOpen: false,
      };
    case Actions.OpenLegalModal:
      return {
        ...state,
        isLegalModalOpen: true,
      };
    case Actions.CloseLegalModal:
      return {
        ...state,
        isLegalModalOpen: false,
      };
    case Actions.LegalToggleAcceptance:
      return {
        ...state,
        isLegalAccepted: action.payload.accepted,
      };
    case Actions.ZipSubmitted:
    case Actions.CitizensSubmitted:
    case Actions.AffirmSubmitted:
    case Actions.SubmitFinancing:
      return {
        ...state,
        isSubmitting: true,
      };
    case Actions.SubmitFinancingFailure: // TODO: do something with cart failures
      return {
        ...state,
        isSubmitting: false,
      };
    default:
      return state;
  }
};

export default reducer;

export enum Actions {
  AffirmSubmitted = 'ecomm/financing/AFFIRM_SUBMITTED',
  CitizensSubmitted = 'ecomm/financing/CITIZENS_SUBMITTED',
  ZipSubmitted = 'ecomm/financing/ZIP_SUBMITTED',
  AuthorizeFinancing = 'ecomm/financing/AUTHORIZE_FINANCING',
  InitializeFinancing = 'ecomm/financing/INITIALIZE_FINANCING',
  KlarnaInitialize = 'ecomm/financing/INITIALIZE_KLARNA_SDK',
  KlarnaInitializeSuccess = 'ecomm/financing/INITIALIZE_KLARNA_SDK_SUCCESS',
  KlarnaLoad = 'ecomm/financing/KLARNA_SDK_LOAD',
  KlarnaLoadSuccess = 'ecomm/financing/KLARNA_SDK_LOADED',
  KlarnaPaymentAvailable = 'ecomm/financing/KLARNA_PAYMENT_AVAILABLE',
  KlarnaPaymentUnavailable = 'ecomm/financing/KLARNA_PAYMENT_UNAVAILABLE',
  KlarnaStart = 'ecomm/financing/KLARNA_START',
  KlarnaOpenWidget = 'ecomm/financing/KLARNA_OPEN_WIDGET',
  KlarnaCloseWidget = 'ecomm/financing/KLARNA_CLOSE_WIDGET',
  SubmitFinancing = 'ecomm/financing/ORDER_SUBMITTED',
  SubmitFinancingFailure = 'ecomm/financing/ORDER_SUBMITTED_FAILURE',
  OpenLegalModal = 'ecomm/financing/OPEN_LEGAL_MODAL',
  CloseLegalModal = 'ecomm/financing/CLOSE_LEGAL_MODAL',
  LegalToggleAcceptance = 'ecomm/financing/LEGAL_TOGGLE_ACCEPTANCE',
}

export type Action =
  | AffirmSubmitted
  | KlarnaInitialize
  | KlarnaInitializeSuccess
  | KlarnaLoad
  | KlarnaLoadSuccess
  | KlarnaPaymentAvailable
  | KlarnaPaymentUnavailable
  | KlarnaStart
  | KlarnaOpenWidget
  | KlarnaCloseWidget
  | AuthorizeFinancingAction
  | SubmitFinancingAction
  | SubmitFinancingFailureAction
  | OpenLegalModal
  | CloseLegalModal
  | LegalToggleAcceptance
  | CitizensSubmitted
  | ZipSubmitted;

export type AffirmSubmitted = {
  type: Actions.AffirmSubmitted;
};

export type CitizensSubmitted = {
  type: Actions.CitizensSubmitted;
};

export type ZipSubmitted = {
  type: Actions.ZipSubmitted;
};

export type KlarnaInitialize = {
  type: Actions.KlarnaInitialize;
};

export type KlarnaInitializeSuccess = {
  type: Actions.KlarnaInitializeSuccess;
};

export type KlarnaLoad = {
  type: Actions.KlarnaLoad;
};

export type KlarnaLoadSuccess = {
  type: Actions.KlarnaLoadSuccess;
};

export type KlarnaStart = {
  type: Actions.KlarnaStart;
  payload: { paymentMethod: PaymentMethod };
};

export type KlarnaPaymentAvailable = {
  type: Actions.KlarnaPaymentAvailable;
};

export type KlarnaPaymentUnavailable = {
  type: Actions.KlarnaPaymentUnavailable;
  payload: { errors: string[] };
};

export type KlarnaOpenWidget = {
  type: Actions.KlarnaOpenWidget;
};

export type KlarnaCloseWidget = {
  type: Actions.KlarnaCloseWidget;
};

export type OpenLegalModal = {
  type: Actions.OpenLegalModal;
};

export type CloseLegalModal = {
  type: Actions.CloseLegalModal;
  payload: { accepted?: boolean };
};

export type LegalToggleAcceptance = {
  type: Actions.LegalToggleAcceptance;
  payload: { accepted: boolean };
};

export type AuthorizeFinancingAction = {
  type: Actions.AuthorizeFinancing;
  payload: { paymentMethod: PaymentMethod };
};

export type SubmitFinancingAction = {
  type: Actions.SubmitFinancing;
  payload: CheckoutPayload & { onError: () => void };
};

export type SubmitFinancingFailureAction = {
  type: Actions.SubmitFinancingFailure;
  payload: Exception;
};

export const zipSubmitted = (): ZipSubmitted => ({
  type: Actions.ZipSubmitted,
});

export const affirmSubmitted = (): AffirmSubmitted => ({
  type: Actions.AffirmSubmitted,
});

export const citizensSubmitted = (): CitizensSubmitted => ({
  type: Actions.CitizensSubmitted,
});

export const initializeKlarna = (): KlarnaInitialize => ({
  type: Actions.KlarnaInitialize,
});

export const initializeKlarnaSuccess = (): KlarnaInitializeSuccess => ({
  type: Actions.KlarnaInitializeSuccess,
});

export const openKlarnaWidget = (): KlarnaOpenWidget => ({
  type: Actions.KlarnaOpenWidget,
});

export const closeKlarnaWidget = (): KlarnaCloseWidget => ({
  type: Actions.KlarnaCloseWidget,
});

export const openLegalModal = (): OpenLegalModal => ({
  type: Actions.OpenLegalModal,
});

export const closeLegalModal = (accepted: boolean): CloseLegalModal => ({
  type: Actions.CloseLegalModal,
  payload: { accepted },
});

export const legalToggleAcceptance = (accepted: boolean): LegalToggleAcceptance => ({
  type: Actions.LegalToggleAcceptance,
  payload: { accepted },
});

export const klarnaLoad = (): KlarnaLoad => ({
  type: Actions.KlarnaLoad,
});

export const klarnaLoaded = (): KlarnaLoadSuccess => ({
  type: Actions.KlarnaLoadSuccess,
});

export const klarnaStart = (paymentMethod: PaymentMethod): KlarnaStart => ({
  type: Actions.KlarnaStart,
  payload: { paymentMethod },
});

export const klarnaPaymentAvailable = (): KlarnaPaymentAvailable => ({
  type: Actions.KlarnaPaymentAvailable,
});

export const klarnaPaymentUnavailable = (errors: string[]): KlarnaPaymentUnavailable => ({
  type: Actions.KlarnaPaymentUnavailable,
  payload: { errors },
});

export const submitFinancing = (
  props: CheckoutPayload,
  onError: () => void,
): SubmitFinancingAction => ({
  type: Actions.SubmitFinancing,
  payload: { ...props, onError },
});

export const authorizeFinancing = (
  paymentMethod: PaymentMethod,
): AuthorizeFinancingAction => ({
  type: Actions.AuthorizeFinancing,
  payload: { paymentMethod },
});

export const initializeFinancing = () => ({
  type: Actions.InitializeFinancing,
});

export const submitFinancingFailure = (error: Error): SubmitFinancingFailureAction => ({
  type: Actions.SubmitFinancingFailure,
  payload: toException(`financing.errors.${error.message}`),
});
