import { Button } from '@pelotoncycle/design-system';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useOauth } from '@peloton/auth';
import { addCurrentLocaleToUrl } from '@peloton/internationalize/addCurrentLocaleToUrl';
import { CALLBACK } from '@acme-ui/sso-functionality/constants';
import { getUserEmail } from '@ecomm/checkout/redux';
import { FormatCtaWithUrl } from '@ecomm/copy';
import { toAppState } from '@ecomm/oauth/OAuthProvider';

const OAuthLoginFormRedirectView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { loginWithRedirect } = useOauth();
  const userEmail = useSelector(getUserEmail);

  const onLoginClick = () =>
    loginWithRedirect({
      authorizationParams: {
        login_hint: userEmail,
        redirect_uri: addCurrentLocaleToUrl(`${window.location.origin}${CALLBACK}`, true),
      },
      appState: toAppState(window.location.href),
    });

  return (
    <ButtonContainer>
      <Button onClick={onLoginClick} type="submit" data-test-id="loginModalSubmit">
        <FormatCtaWithUrl id="signIn" />
      </Button>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  margin-top: 30px;
  text-align: center;
`;

export default OAuthLoginFormRedirectView;
