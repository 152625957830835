import type { Country } from '@peloton/internationalize';
import {
  parseCountry,
  theUserIsInGermany,
  theUserIsInTheUnitedKingdom,
} from '@peloton/internationalize';

export type Address = {
  addressee?: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
};

export const isAddressComplete = ({
  city,
  postalCode,
  state,
  line1,
  country,
}: Address): boolean =>
  Boolean(city && city.trim()) &&
  Boolean(line1 && line1.trim()) &&
  Boolean(country && country.trim()) &&
  Boolean(postalCode && postalCode.trim()) &&
  (Boolean(state && state.trim()) ||
    theUserIsInTheUnitedKingdom(country.toLowerCase()) ||
    theUserIsInGermany(country.toLowerCase()));

export const toCountry = ({ country }: Partial<Address>): Country =>
  parseCountry(country);
