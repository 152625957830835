import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';
import { CALLBACK, LOGIN } from './urls';

const LoginPage = asyncComponent(
  () => import('./Login' /* webpackChunkName: "AuthLoginPage" */),
);

const CallbackPage = asyncComponent(
  () => import('./Callback' /* webpackChunkName: "AuthCallbackPage" */),
);

const Routes = [
  <Route key={LOGIN} path={LOGIN} shouldTrack={true} component={LoginPage} />,
  <Route key={CALLBACK} path={CALLBACK} shouldTrack={true} component={CallbackPage} />,
];

export default Routes;
