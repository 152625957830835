import { schema } from 'normalizr';
import { assoc, pipe } from 'ramda';
import type { Subscription } from '../../models';
import { SubscriptionKind } from '../../models';
import type { ApiSubscription } from '../types';
import type { KindlessSub } from './toSubscription';
import toSubscription from './toSubscription';

const setDigital = assoc('kind', SubscriptionKind.Digital);

const toDigitalSubscription = pipe<ApiSubscription, KindlessSub, Subscription>(
  toSubscription,
  setDigital,
);

const digitalSubscription = new schema.Entity(
  'subscriptions',
  {},
  { processStrategy: toDigitalSubscription },
);

export default digitalSubscription;
