import type { SagaIterator } from 'redux-saga';
import { put, call, select, takeLatest, getContext } from 'redux-saga/effects';
import { track } from '@peloton/analytics';
import { CLIENT_CONTEXT } from '@peloton/api';
import type { User } from '@peloton/auth';
import { getUser, isSignedIn } from '@peloton/auth';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import type { RemoveItemAction, SingleItem } from '@ecomm/cart';
import {
  loadCartSuccess,
  loadCartFailure,
  RemoveItemActionType,
  selectCart,
  hasOrIsBikePlus,
} from '@ecomm/cart';
import { updateShippingSaga } from '@ecomm/checkout/sagas/shipping';
import { removeAllTradeIns } from './api';
import { ActionType, closeModal } from './redux';

export const handle = ({ cartState, user }: any) => {
  const cart = cartState.cart;
  const key = 'tradeIn';
  const targetItem = cart.items.find((item: SingleItem) => {
    return item.id === cart[key].id;
  });
  const bike = cart.items.find(hasOrIsBikePlus);
  let userProps = {};

  if (isSignedIn(user)) {
    userProps = {
      email: user.email,
      hashedEmail: user.hashedEmail,
    };
  }
  return {
    event: 'Removed Bike Trade-in From Cart',
    properties: {
      cartId: cart.id,
      id: 'bikePlus',
      name: targetItem.name,
      category: 'tradeIn',
      price: targetItem.price,
      propertyType: 'Web',
      quantity: 1,
      sku: bike.sku,
      ...userProps,
    },
  };
};

export const sendAnalytics = function* (action: RemoveItemAction): SagaIterator {
  if ((action.itemType as string) !== 'tradein') {
    return;
  }
  const user: User = yield select(getUser);
  const cartState = yield select(selectCart);

  try {
    const event = handle({ cartState, user });
    yield call(track, event);
  } catch (err) {
    const error = new DomainError('Error tracking event', err);
    yield put(reportError({ error }));
  }
};

export const removeTradeInSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  try {
    const cart = yield call(removeAllTradeIns, client);
    yield put(loadCartSuccess(cart));

    // re-update shipping to calculate taxes
    yield call(updateShippingSaga);
  } catch (e) {
    yield put(loadCartFailure(e));
  }
  yield put(closeModal());
};

const sagas = function* () {
  yield takeLatest(RemoveItemActionType.RemoveItem, sendAnalytics);
  yield takeLatest(ActionType.RemoveTradeIn, removeTradeInSaga);
};

export default sagas;
