import { useCallback, useState } from 'react';
import { track } from '@peloton/analytics';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import { getFormattedPageName } from '@ecomm/cms-hero';
import useInterval from './useInterval';

const trackSlideNavigation = (index: number) => {
  const path = getCurrentRelativePathname()!;
  const parent = getFormattedPageName(path);
  track({
    event: 'Carousel Slide Viewed',
    properties: {
      parent,
      linkTo: '',
      page: path,
      unitName: `${parent}-hero-nav-slide-${index + 1}`,
    },
  });
};

const useCarousel = (size: number, interval?: number) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const handleAutoAdvance = useCallback(() => {
    const nextSlide = (activeSlide + 1) % size;
    setActiveSlide(nextSlide);
  }, [activeSlide, size]);

  const { resetInterval } = useInterval(handleAutoAdvance, interval);

  const handlePrevious = useCallback(() => {
    const previousSlide = activeSlide === 0 ? size - 1 : activeSlide - 1;
    trackSlideNavigation(previousSlide);
    setActiveSlide(previousSlide);
    resetInterval();
  }, [size, activeSlide, resetInterval]);

  const handleNext = useCallback(() => {
    const nextSlide = (activeSlide + 1) % size;
    trackSlideNavigation(nextSlide);
    setActiveSlide(nextSlide);
    resetInterval();
  }, [size, activeSlide, resetInterval]);

  const handleClickIndicator = useCallback(
    (index: number) => {
      trackSlideNavigation(index);
      setActiveSlide(index);
      resetInterval();
    },
    [resetInterval, activeSlide],
  );

  return {
    activeSlide,
    handlePrevious,
    handleNext,
    handleClickIndicator,
    handleAutoAdvance,
  };
};

export default useCarousel;
