import {
  black,
  grey,
  Flex,
  Container,
  spacing,
  white,
} from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import type { PropsWithChildren, RefObject } from 'react';
import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { media } from '@peloton/styles/breakpoints';
import type {
  CtaWithUrlSetData,
  NavigationData,
  Subnav,
} from '@ecomm/copy/helpers/types';
import LinkColumn from './LinkColumn';
import LinkRow from './LinkRow';
import TopLevelMenu from './TopLevelMenu';

type MegaMenuProps = {
  wrapperRef?: RefObject<HTMLDivElement>;
  label: string;
  data: Pick<NavigationData, 'key' | 'title' | 'subnavs'>;
  realignLastChild: boolean;
  className?: string;
};

const MegaMenu: React.FC<React.PropsWithChildren<PropsWithChildren<MegaMenuProps>>> = ({
  wrapperRef,
  label,
  data: { key, subnavs },
  className = '',
}) => {
  const [selectedMenu, setSelectedMenu] = useState<string>(subnavs[0]?.key);

  const selected = subnavs.find(n => n.key === selectedMenu);

  // This logic allows us to always have 4 columns and a row in the UI
  // For the case where the 3rd column is empty but we want to render a 4th
  const [col1, col2, col3, col4, col5] = isNavigation(selected)
    ? selected.subnavs.map(n => (hasCTAs(n) ? n : undefined))
    : [];

  return (
    <StyledMegaMenu
      data-test-id={key}
      data-element-id={key}
      ref={wrapperRef}
      aria-label={label}
      className={className}
    >
      <TopLevelMenuContainer>
        {subnavs.map(n => (
          <TopLevelMenu
            key={n.key}
            id={n.key}
            name={(n as any).title}
            active={selectedMenu === n.key}
            setSelectedMenu={setSelectedMenu}
          />
        ))}
      </TopLevelMenuContainer>
      <StyledContainer>
        <StyledFlex>
          <LinkColumn ctas={col1} />
          <LinkColumn ctas={col2} />
          <LinkColumn ctas={col3} />
          <LinkColumn ctas={col4} isCollection={true} />
        </StyledFlex>
        <LinkRow ctas={col5} />
      </StyledContainer>
    </StyledMegaMenu>
  );
};

export default MegaMenu;

const isNavigation = (x: Subnav | undefined): x is NavigationData =>
  Boolean(x && 'subnavs' in x);

const hasCTAs = (x: Subnav | undefined): x is CtaWithUrlSetData =>
  Boolean(x && 'ctas' in x && x.ctas.filter(cta => cta.url).length > 1);

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const StyledMegaMenu = styled.div`
  color: ${black};
  position: absolute;
  top: 58px;
  display: flex;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 30px 80px 0 ${rgba(black, 0.37)};
  background-color: #ffffff;
  min-width: 50rem;
  animation: ${fadeIn} 600ms;

  ${media.desktopLarge`
    margin: 0 ${spacing[32]};
    width: calc(100vw - ${spacing[64]});
`}

  ${media.desktopXLarge`
    margin: 0 auto;    
    max-width: 1064px;
    padding: 0;
`}
`;

// TODO: fix desktop padding
const TopLevelMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing[40]} ${spacing[24]} ${spacing[40]} ${spacing[32]};
  background: ${grey[30]};
  color: ${grey[60]};
  min-width: 241px;

  ${media.desktopXLarge`
  padding: ${spacing[40]} ${spacing[24]} ${spacing[40]} ${spacing[48]};
  `}
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: ${spacing[40]} ${spacing[24]} ${spacing[40]} ${spacing[48]};
  background: ${white};
  color: ${grey[60]};
  min-width: 270px;
  flex-grow: 1;

  ${media.desktopLarge`
    padding: ${spacing[56]} ${spacing[32]} 0 ${spacing[40]}
  `}

  ${media.desktopXLarge`
    padding: ${spacing[56]} ${spacing[48]} 0 ${spacing[48]}
  `}
`;

const StyledFlex = styled(Flex)`
  min-height: 362px;
  height: -webkit-fill-available;
  ${media.desktopLarge`
      gap: ${spacing[40]};
  `}
  ${media.desktopXLarge`
      gap: ${spacing[56]};
  `};
`;
