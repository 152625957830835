import { pathOr } from 'ramda';

export enum ActionType {
  Scroll = 'ecomm/scroll/SCROLL',
  ScrollToElement = 'ecomm/scroll/SCROLL_TO_ELEMENT',
  ScrollToAndFocusElement = 'ecomm/scroll/SCROLL_TO_AND_FOCUS_ELEMENT',
}

export const smoothScroll = (props?: ScrollToOptions): ScrollAction => ({
  type: ActionType.Scroll,
  payload: {
    top: pathOr(0, ['top'], props),
    left: pathOr(0, ['left'], props),
    behavior: 'smooth',
  },
});

export const jumpScroll = (props?: ScrollToOptions): ScrollAction => ({
  type: ActionType.Scroll,
  payload: {
    top: pathOr(0, ['top'], props),
    left: pathOr(0, ['left'], props),
    behavior: 'auto',
  },
});

export const scrollToElement = (selector: string): ScrollToElementAction => ({
  type: ActionType.ScrollToElement,
  payload: {
    selector,
    options: {
      behavior: 'smooth',
      block: 'end',
      inline: 'start',
    },
  },
});

export const scrollToAndFocusElement = (
  selector: string,
  options?: ScrollIntoViewOptions,
): ScrollToAndFocusElementAction => ({
  type: ActionType.ScrollToAndFocusElement,
  payload: {
    selector,
    options: {
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
      ...options,
    },
  },
});

export type ScrollAction = {
  type: ActionType.Scroll;
  payload: ScrollToOptions;
};

export type ScrollToElementAction = {
  type: ActionType.ScrollToElement;
  payload: {
    selector: string;
    options: ScrollIntoViewOptions;
  };
};

export type ScrollToAndFocusElementAction = {
  type: ActionType.ScrollToAndFocusElement;
  payload: {
    selector: string;
    options: ScrollIntoViewOptions;
  };
};
