import React from 'react';
import styled from 'styled-components';
import { screenReaderOnly } from '@peloton/accessibility';
import { ErrorBoundary } from '@peloton/error-reporting';
import { useKeyValueData } from '@ecomm/copy';

type Props = {
  isLoading: boolean;
};

const ScreenReaderText = styled.span`
  ${screenReaderOnly}
`;

const LoadingMessage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const loadingMessage = useKeyValueData('financing.paybright.button.loading');
  return <>{loadingMessage}</>;
};

export const LoadingStatus: React.FC<React.PropsWithChildren<Props>> = ({
  isLoading,
}) => (
  <ScreenReaderText aria-live="assertive" role="alert">
    {isLoading ? (
      <ErrorBoundary renderError={() => <>Loading</>}>
        <LoadingMessage />
      </ErrorBoundary>
    ) : null}
  </ScreenReaderText>
);
