import { useMemo } from 'react';
import { useLocale } from '@peloton/internationalize/locales';

const useCurrentDate = () => {
  const locale = useLocale();
  const formattedDate = useMemo(() => {
    return new Intl.DateTimeFormat(locale, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(new Date());
  }, [locale]);

  return formattedDate;
};

export default useCurrentDate;
