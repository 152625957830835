import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getOptimizelyExperimentsAndVariations } from '@peloton/split-testing';
import { matchCheckoutReviewRoute, matchCheckoutRoute } from '@ecomm/checkout/redux';
import CmsPromoBanner from '@ecomm/cms-promo-banner/CmsPromoBanner';
import { useActivePromoBanner } from '@ecomm/cms-promos';
import { usePromoBannerData } from '@ecomm/copy';
import { useIsToggleActive } from '@ecomm/feature-toggle';
import { default as MaintenanceBanner } from '@ecomm/maintenance/MaintenanceBanner';
import { matchShopDeviceRoute } from '@ecomm/shop/redux';

const matchPromoBannerPages = createSelector(
  [matchCheckoutRoute, matchCheckoutReviewRoute, matchShopDeviceRoute],
  (isCheckoutRoute, isCheckoutReviewRoute, isShopDeviceRoute) =>
    Boolean(!isCheckoutRoute && !isCheckoutReviewRoute && !isShopDeviceRoute),
);

const Banner: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isToggleActive = useIsToggleActive();
  const isUnderMaintenance = isToggleActive('underMaintenance');
  const isPromoBannerPage = useSelector(matchPromoBannerPages);
  const showMaintenanceBanner = isUnderMaintenance && isPromoBannerPage;
  const { cmsId, dataTestIdPrefix } = useActivePromoBanner();
  const { hide: shouldHideBanner } = usePromoBannerData(cmsId);
  const activeBannerExperimentsAndVariations = useSelector(state =>
    getOptimizelyExperimentsAndVariations(state, 'bannerOptimizely'),
  );

  if (showMaintenanceBanner) {
    return <MaintenanceBanner />;
  } else if (cmsId && !shouldHideBanner) {
    return (
      <CmsPromoBanner
        cmsId={cmsId}
        dataTestIdPrefix={dataTestIdPrefix}
        experimentsAndVariations={activeBannerExperimentsAndVariations}
      />
    );
  }
  return null;
};

export default Banner;
