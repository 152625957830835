import React from 'react';
import NotFoundError from './NotFoundError';
import Page from './Page';

const NotFoundErrorPage = () => (
  <Page>
    <NotFoundError />
  </Page>
);

export default NotFoundErrorPage;
