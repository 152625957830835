import type { Entities } from '@ecomm/shop/models';
import type { Payment } from '../models';

export enum ActionType {
  Add = 'ecomm/order/ADD_PAYMENTS',
}

export type State = Entities<Payment>;

export const defaultState = {};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.Add:
      return {
        ...state,
        ...action.payload.payments,
      };
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  payments: State;
};

export const addPayments = (payments: Entities<Payment>): AddPaymentAction => ({
  type: ActionType.Add,
  payload: { payments },
});

type AddPaymentAction = {
  type: ActionType.Add;
  payload: { payments: Entities<Payment> };
};

type Action = AddPaymentAction;
