const TWENTY_FOUR_HOURS = 60 * 60 * 24;
const COOKIE_NAME = 'PromoModalDismissed';
const COOKIE_REGEX = new RegExp(COOKIE_NAME);

export const storeModalDismissed = () => {
  document.cookie = `${COOKIE_NAME}=1;max-age=${TWENTY_FOUR_HOURS}`;
};

export const isModalDismissed = (): boolean => {
  return COOKIE_REGEX.test(document.cookie);
};
