import React from 'react';
import type { RouteComponentProps } from 'react-router';
import { Markdown } from '@peloton/copy';
import { useLegalTextData } from '@ecomm/copy';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { StyledLegalCopy } from './LegalSectionStyles';

const RefurbishedBikeLegalSection: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const legalText = useLegalTextData('shopRefurbishedLegalText');
  const isCpoActive = useIsToggleActive()('cpo');

  return isCpoActive ? (
    <StyledLegalCopy>
      <Markdown content={legalText} />
    </StyledLegalCopy>
  ) : null;
};

export default RefurbishedBikeLegalSection;
