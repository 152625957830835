import type { SagaIterator } from 'redux-saga';
import { takeEvery, put, getContext, call } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { Errors } from '@ecomm/checkout';
import type { referrerRequest } from '@ecomm/checkout/redux';
import {
  ReferrerActionTypes,
  referrerRequestSuccess,
  referrerRequestFailure,
} from '@ecomm/checkout/redux';
import { referrerData as referrerDataQuery } from '../api';

export const referrerDataSaga = function* (
  action: ReturnType<typeof referrerRequest>,
): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  try {
    const data = yield call(referrerDataQuery, client, {
      code: action.payload.code,
    });
    yield put(referrerRequestSuccess(data));
  } catch (e) {
    //todo: handle error ? create a new one in Validation.ts ???
    yield put(referrerRequestFailure(Errors.InvalidPromoCode));
  }
};

const watcherSaga = function* () {
  yield takeEvery(ReferrerActionTypes.ReferrerRequest, referrerDataSaga);
};

export default watcherSaga;
