import { useIsToggleActive } from '@ecomm/feature-toggle';
import useHasFinancingPartner from './useHasFinancingPartner';

const usePrequalifyNowModule = () => {
  const hasFinancing = useHasFinancingPartner();
  const prequalifyNowActive = useIsToggleActive()('prequalifyNow');

  return hasFinancing && prequalifyNowActive;
};

export default usePrequalifyNowModule;
