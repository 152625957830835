import { toContentfulClient } from '@ecomm/contentful';
import type { PressHeadline, PressQuote } from '@ecomm/pg-press/models';

const pressHeadlineContentType = '3fI5sLqlYIeaKguyIkeyAM';
const pressQuoteContentType = '1VcIyFnR8oC2IMAA2SU2W2';

type ContentfulImageEntry = {
  fields: {
    file: {
      url: string;
    };
  };
};

type ContentfulPressQuote = {
  headerImage: ContentfulImageEntry;
  link: string;
  logo: ContentfulImageEntry;
  publication: string;
  quote: string;
};

type FetchProps = {
  countryFilter?: string;
  isProd: boolean;
};

export const fetchPressHeadlines = ({ countryFilter, isProd }: FetchProps) =>
  toContentfulClient({ isProd })
    .getEntries<PressHeadline>({
      content_type: pressHeadlineContentType,
      limit: 100,
      order: '-fields.date',
      ...toFilterQueryObject(countryFilter),
    })
    .then(entry => entry.items.map(item => item.fields));

export const fetchPressQuotes = ({
  countryFilter,
  isProd,
}: FetchProps): Promise<PressQuote[]> =>
  toContentfulClient({ isProd })
    .getEntries<ContentfulPressQuote>({
      content_type: pressQuoteContentType,
      limit: 6,
      order: '-fields.date',
      ...toFilterQueryObject(countryFilter),
    })
    .then(entry =>
      entry.items.map(({ fields: { headerImage, link, logo, publication, quote } }) => ({
        headerImage: headerImage.fields.file.url,
        link,
        logo: logo.fields.file.url,
        publication,
        quote,
      })),
    );

const toFilterQueryObject = (countryFilter?: string) =>
  countryFilter ? { 'fields.countries[in]': countryFilter } : {};
