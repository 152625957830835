import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

const Routes = [
  <Route
    key="failed-faas-credit-check"
    path="/failed-faas-credit-check"
    exact
    toggleId={'faas_credit_check'}
    component={asyncComponent(
      () =>
        import(
          './FailedCreditCheckLandingPage' /* webpackChunkName:  "FailedCreditCheckLandingPage" */
        ),
    )}
  />,
];

export default Routes;
