export enum MerchantIds {
  Dev = 'K505110',
  Prod = 'N101561',
}

export enum Subdomains {
  Dev = 'sandbox',
  Prod = 'app',
}

export const getMerchantId = (isProd: boolean) =>
  isProd ? MerchantIds.Prod : MerchantIds.Dev;

export const getSubdomain = (isProd: boolean) =>
  isProd ? Subdomains.Prod : Subdomains.Dev;
