import type { SagaIterator } from 'redux-saga';
import { all, getContext, call, put, select } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { isUserSignedIn } from '@peloton/auth';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import type { Hooks } from '@ecomm/models';
import { getHook } from '@ecomm/models';
import { grantFreeTrial } from './api';
import type { Days } from './models';
import { NEEDS_LOGIN } from './models';

type Options = {
  duration?: Days;
  hooks?: Hooks;
};

const grantFreeTrialSaga = function* ({ duration, hooks }: Options = {}): SagaIterator {
  const onError = getHook('onError', hooks);
  const onSuccess = getHook('onSuccess', hooks);

  const signedIn = yield select(isUserSignedIn);
  if (!signedIn) {
    return yield put(onError(NEEDS_LOGIN));
  }

  const client = yield getContext(CLIENT_CONTEXT);
  try {
    yield call(grantFreeTrial, client, duration);
    yield put(onSuccess());
  } catch (err) {
    const error = new DomainError('Error granting free trial', err);
    yield all([put(reportError({ error })), put(onError(err))]);
  }
};

export default grantFreeTrialSaga;
