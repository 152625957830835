import type { SetCookieTypes } from './types';

export const getCookie = (name: string) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);

  const match = document.cookie.match(regex);

  if (match) {
    return match[2];
  } else {
    return match;
  }
};

export const setCookie = ({ name, value, options }: SetCookieTypes) => {
  const cookie = name + '=' + value;

  const exp =
    typeof options?.expires === 'number'
      ? new Date(options.expires * 1000).toUTCString()
      : options?.expires?.toUTCString();

  const path = options?.path ? ';path=' + options.path : '';
  const domain = options?.domain ? ';domain=' + options.domain : '';
  const expires = options?.expires ? ';expires=' + exp : '';
  const sameSite = options?.sameSite ? ';sameSite=' + options.sameSite : '';
  const secure = options?.secure ? ';secure' : '';

  document.cookie = `${cookie}${path}${domain}${expires}${sameSite}${secure}`;
};

export const hasCookie = (name: string) => {
  const cookie = getCookie(name);

  if (cookie) {
    return !!cookie;
  }

  return false;
};

export const removeCookie = (name: string) => {
  const cookie = getCookie(name);

  if (!cookie) {
    return;
  } else {
    setCookie({
      name,
      value: '',
      options: { expires: new Date(Date.now() - 24 * 60 * 60 * 1000) },
    });
  }
};
