import {
  AccountLinkEnvs,
  ApiEnvs,
  AuthLinkEnvs,
  BookingLinkEnvs,
  DigitalLinkEnvs,
  HomecomingLinkEnvs,
  LinkEnvs,
  PreorderLinkEnvs,
  StudioLinkEnvs,
} from '@peloton/external-links/models';

export const ACCOUNT_EXISTS_ERROR_CODE_TYPE = {
  [10503]: 'apple',
  [10502]: 'google',
};

export enum Connection {
  apple = 'apple',
  google = 'google-oauth2',
}

export enum SocialButtonTypes {
  APPLE = 'apple',
  GOOGLE = 'google',
  EMAIL = 'email',
}

export const SSO_BUTTONS_ID = 'ssoSection-btn';

export const AnalyticUnitName = {
  account: {
    apple: 'createAccount.apple',
    email: 'createAccount.email',
    google: 'createAccount.google',
  },
  www: {
    apple: 'checkout.social.buttons.apple',
    email: 'checkout.social.buttons.email',
    google: 'checkout.social.buttons.google',
  },
  'next-www': {
    apple: 'checkout.socialbuttons.apple',
    email: 'checkout.socialbuttons.email',
    google: 'checkout.socialbuttons.google',
  },
};

export enum ParentTypeAnalytic {
  account = 'Account Key Value',
  www = 'ValuePropSet',
  'next-www' = 'ValuePropSet',
}

export const EMAIL_SSO_MARKETING_OPT_OUT_NAME_ECOMM = 'SSOMarketingOptOut';
export const APP_MEMBERSHIP_CHECKOUT_ROUTE = '/app-membership';
export const CALLBACK = '/callback';
export const NEXT_CALLBACK = '/next-callback';

export const extLinkEnvMatcher = {
  api: ApiEnvs,
  digital: DigitalLinkEnvs,
  ecomm: LinkEnvs,
  homecoming: HomecomingLinkEnvs,
  preorder: PreorderLinkEnvs,
  studio: StudioLinkEnvs,
  www: LinkEnvs,
  booking: BookingLinkEnvs,
  account: AccountLinkEnvs,
  auth: AuthLinkEnvs,
  'next-www': LinkEnvs,
};

export const loginUrlMatcher = {
  www: '/authlogin',
  account: '/login',
  'next-www': '/authlogin',
};
