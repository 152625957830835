import { has } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { call, getContext, select } from 'redux-saga/effects';
import {
  EXT_LINK_ENV_CONTEXT,
  REDIRECT_URL_QUERY_PARAM,
  getUrlForRedirectQueryParams,
  toHref,
} from '@peloton/external-links';
import { Links } from '@peloton/links';
import { redirect } from '@peloton/navigation';
import { getQueryParams } from '@peloton/redux';

const redirectOnSuccess = function* (): SagaIterator {
  const redirectUrl = yield call(toRedirectUrl);
  yield call(redirect, redirectUrl);
};

export default redirectOnSuccess;

export const toRedirectUrl = function* (): SagaIterator {
  const extLinkEnv = yield getContext(EXT_LINK_ENV_CONTEXT);

  return has(REDIRECT_URL_QUERY_PARAM, yield select(getQueryParams))
    ? yield select(getUrlForRedirectQueryParams)
    : toHref(Links.profile, extLinkEnv);
};
