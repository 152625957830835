import { brand, grey, red } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { useLocale, toDisplayName } from '@peloton/internationalize';
import { media, hoverTransition } from '@peloton/styles';
import Accordion from '@ecomm/accordion/Accordion';
import type { default as Props } from '@ecomm/internationalize/LocaleChooserProps';
import LocaleAccordionHeader from './LocaleAccordionHeader';
import LocaleLink from './LocaleLink';

const LocaleAccordion: React.FC<React.PropsWithChildren<Props>> = ({
  currentLocale: paramLocale,
  availableLocales,
  darkMode = false,
}) => {
  const baseLocale = useLocale();
  const currentLocale = paramLocale ?? baseLocale;

  return (
    <Accordion
      name="locale"
      renderHeader={LocaleAccordionHeader(currentLocale, darkMode)}
    >
      {availableLocales
        .filter(locale => locale !== currentLocale)
        .sort((a, b) => (toDisplayName(a) > toDisplayName(b) ? 1 : -1))
        .map(locale => (
          <StyledLocaleLink
            key={locale}
            locale={locale}
            currentLocale={currentLocale}
            className={darkMode ? 'dark' : ''}
          />
        ))}
    </Accordion>
  );
};

export default LocaleAccordion;

const StyledLocaleLink = styled(LocaleLink)`
  color: ${brand.darkest};
  &.dark {
    text-transform: none;
    color: ${grey[50]};
  }

  ${media.desktopLarge`
    ${hoverTransition({
      property: 'color',
      to: red[50],
    })}
  `}
`;
