import type { Section } from '../models.generated';
import { useSectionQuery } from '../queries/contentTypes/section.generated';

export type SectionItem = {
  key: string;
  title: string;
  body: string;
};

export const refineSectionData = (obj: any, key: Section): SectionItem => {
  let { title, body } = obj;

  if (!title && !body) {
    title = `Section: ${key}`;
    body = `Section: ${key}`;
  }

  return {
    key,
    title: title || '',
    body: body || '',
  };
};

export const transformSectionData = (response: any, id: Section): SectionItem => {
  return refineSectionData(response?.data?.section || {}, id);
};

const useSectionData = (id: Section): SectionItem => {
  const res = useSectionQuery({ variables: { id } });

  return transformSectionData(res, id);
};

export default useSectionData;
