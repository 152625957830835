import { Locale } from '@peloton/internationalize';

// Normalizers will reformat values into data friendly strings with all formatting removed
// (Normalizers are the inverse of Formatters)
//  ex. phone number
//    (212) 123-4567 -> 2121234567

export const normalizeNumber = (value: string): string => value.replace(/[^\d]/g, '');

const normalizeCA = (value: string): string =>
  value
    .replace(/^[wzWZ0-9]/, '')
    .replace(/[^abceghj-nprstv-zABCEGHJ-NPRSTV-Z0-9]+/g, '')
    .toUpperCase();

const normalizeWithMaxLength = (fn: (_: string) => string) => (length: number) => (
  value: string,
) => fn(value).substr(0, length);

// todo: more sophisticated normalization.
const normalizeGB = (value: string): string =>
  value.replace(/[^A-z0-9]/g, '').toUpperCase();

const Normalizers = {
  phoneNumber: normalizeWithMaxLength(normalizeNumber)(10),
  zipCode: {
    [Locale.EnglishUnitedStates]: normalizeWithMaxLength(normalizeNumber)(9),
    [Locale.EnglishUnitedKingdom]: normalizeGB,
    [Locale.EnglishCanada]: normalizeWithMaxLength(normalizeCA)(6),
    [Locale.GermanGermany]: normalizeWithMaxLength(normalizeNumber)(9),
    [Locale.EnglishAustralia]: normalizeWithMaxLength(normalizeNumber)(4),
    [Locale.GermanAustria]: normalizeWithMaxLength(normalizeNumber)(4),
  },
  normalizeNumber,
};

export default Normalizers;
