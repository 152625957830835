import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

const routes = [
  '/press',
  '/press/articles',
  '/press/coverage',
  '/press/tags/:tag',
  '/press/articles/:articleSlug',
].map(path => (
  <Route
    key={path}
    path={path}
    exact
    component={asyncComponent(() => import('./Handler' /* webpackChunkName: "Press" */))}
  />
));

export default routes;
