import { brand } from '@pelotoncycle/design-system';
import React, { useContext } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { HeaderNavContext } from '@ecomm/header/HeaderNavProvider';
import type { SecondaryLinksData } from '@ecomm/header/models';
import { Link } from '@ecomm/internationalize-ui';
import useFilteredSecondaryLinks from '../../hooks/useFilteredSecondaryLinks';

const SecondaryLinks: React.FC<React.PropsWithChildren<SecondaryLinksData>> = ({
  categoryId,
  secondaryLinks,
}) => {
  const { trackEvent } = useTracking();
  const filteredLinks = useFilteredSecondaryLinks(secondaryLinks);
  const { hideMenu } = useContext(HeaderNavContext);

  return filteredLinks ? (
    <SecondaryLinksContainer data-test-id={secondaryLinks.key}>
      <ul>
        {filteredLinks.map(({ key, text, url, targetBlank }) => (
          <li key={text}>
            <Link
              data-test-id={key}
              variant="body"
              to={url}
              href={url}
              target={targetBlank ? '_blank' : '_self'}
              onClick={() => {
                trackEvent({
                  properties: {
                    category: categoryId,
                    parent: secondaryLinks.key,
                    unitName: key,
                    linkTo: url,
                    linkName: text,
                  },
                });
                hideMenu();
              }}
            >
              {text}
            </Link>
          </li>
        ))}
      </ul>
    </SecondaryLinksContainer>
  ) : null;
};

export default SecondaryLinks;

const SecondaryLinksContainer = styled.div`
  background-color: ${brand.light};
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 4rem;

  span {
    line-height: 21px;
  }

  ul {
    width: 168px;
  }

  li {
    line-height: 0;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
    /* 
    The following CSS styles are added to remove the underline on the link 
    generated from the underlying DS Link component 
    */
    a {
      &::after {
        display: none;
      }
    }
    span::after {
      display: none;
    }
  }
`;
