import {
  toFormattedPrice,
  toRoundedYearlySavings,
} from '@membership/copy/formatters/pricing';
import { BillingFrequency } from '@membership/models/Subscription';
import type { Product } from '@membership/ui/ComparisonTable/types';
import { useCallback, useMemo } from 'react';
import toFormattedText from '@peloton/copy/toFormattedText';

type OwnIdentifier = {
  billingFrequency: string;
  paidSubscriptionPlan?: {
    priceInCents?: number | null;
    displayPrice?: string | null;
  } | null;
};

type OwnTier = {
  level: number;
  identifiers: Array<OwnIdentifier>;
};

const useTierDisplayPrice = (tiers: OwnTier[]) => {
  const tierMap: Record<number, OwnTier> = useMemo(
    () =>
      tiers?.reduce(
        (acc, curr) => ({
          [curr.level]: curr,
          ...acc,
        }),
        {},
      ),
    [tiers],
  );

  const toDisplayPrice = useCallback(
    (billingFrequency: BillingFrequency, product: Product, content: string) => {
      const identifier = tierMap[product.level!].identifiers.find(
        i => i.billingFrequency === billingFrequency,
      );
      const priceInCents = identifier?.paidSubscriptionPlan?.priceInCents;
      return priceInCents
        ? toFormattedText(content, {
            price: toFormattedPrice(priceInCents),
          })
        : product.price;
    },
    [tierMap],
  );

  const toPriceInCents = useCallback(
    (billingFrequency: BillingFrequency, level: number) => {
      try {
        const identifier = tierMap?.[level!].identifiers.find(
          i => i.billingFrequency === billingFrequency,
        );
        return identifier?.paidSubscriptionPlan?.priceInCents || 0;
      } catch (_) {
        return 0;
      }
    },
    [tierMap],
  );

  const toDiscountText = useCallback(
    (product: Product, daysOfTrial: number) => {
      const identifiers: OwnIdentifier[] = tierMap[product.level!].identifiers;

      let monthlyPrice = '';
      let monthlyCostInCents = 0;
      let annualPrice = '';
      let annualCostInCents = 0;

      identifiers
        .filter(({ paidSubscriptionPlan }) => Boolean(paidSubscriptionPlan))
        .forEach(identifier => {
          switch (<BillingFrequency>identifier.billingFrequency) {
            case BillingFrequency.Monthly:
              monthlyCostInCents = identifier.paidSubscriptionPlan?.priceInCents!;
              monthlyPrice = toFormattedPrice(monthlyCostInCents);
              break;
            case BillingFrequency.Annual:
              annualCostInCents = identifier.paidSubscriptionPlan?.priceInCents!;
              annualPrice = toFormattedPrice(annualCostInCents);
              break;
          }
        });

      return toFormattedText(product.discountText, {
        monthlyPrice,
        annualPrice,
        discount: toRoundedYearlySavings(monthlyCostInCents, annualCostInCents),
        daysOfTrial,
      });
    },
    [tierMap],
  );

  return { toDisplayPrice, toDiscountText, toPriceInCents };
};

export default useTierDisplayPrice;
