import type { SagaIterator } from 'redux-saga';
import { all, call, select, takeEvery } from 'redux-saga/effects';
import { theUserIsInCanada } from '@peloton/internationalize';
import { toLocaleFromTLD } from '@peloton/internationalize/models/locale';
import { CartActions } from '@ecomm/cart/redux';
import type { SelectPaymentMethodAction } from '@ecomm/checkout/redux';
import {
  PaymentActions,
  ShippingActions,
  UserActions,
  getUserEmail,
  getUserField,
  getVerification,
} from '@ecomm/checkout/redux';
import { shouldTrackConcessionary } from '@ecomm/concessionary-pricing';
import { getIsToggleActive } from '@ecomm/feature-toggle';
import { PaymentMethod } from '@ecomm/models';
import { ActionType as ShopAction } from '@ecomm/shop/redux';
import type { MessageAction } from './actions';
import { Message as CheckoutMessage, Actions as CheckoutActions } from './actions';
import type { AdditionalTrackingProps, Payload } from './trackCheckoutSteps';
import { toTrackCheckoutStepsSaga } from './trackCheckoutSteps';

const toEvent = (step: number, properties: Payload & AdditionalTrackingProps) => ({
  event: 'Checkout Step Completed',
  properties: { ...properties, stepName: toStepName(step) },
});

export const trackCheckoutStepCompletedSaga = toTrackCheckoutStepsSaga(toEvent);

const handleCheckoutActionSaga = function* ({ payload: { message } }: MessageAction) {
  if (message === CheckoutMessage.paymentDetailField) {
    yield call(trackCheckoutStepCompletedSaga, 3);
  }
  if (message === CheckoutMessage.clickedPlaceOrder) {
    yield call(trackCheckoutStepCompletedSaga, 4);
  }
  if (message === CheckoutMessage.clickedRemoveSubscription) {
    yield call(trackCheckoutStepCompletedSaga, 5);
  }
};

const handlePaymentSelectionSaga = function* ({ payload }: SelectPaymentMethodAction) {
  if (payload.paymentMethod === PaymentMethod.Financing) {
    yield call(trackCheckoutStepCompletedSaga, 3);
  }
};

const handleCompletedEmailSaga = function* (action: any): SagaIterator {
  const [email, allowMarketing, needsConsent]: [
    ReturnType<typeof getUserEmail>,
    ReturnType<typeof getUserField>,
    ReturnType<ReturnType<typeof getIsToggleActive>>,
  ] = yield all([
    select(getUserEmail),
    select(getUserField, { name: 'allowMarketing' }),
    select(getIsToggleActive('needsConsentForMarketing')),
  ]);
  // Per ECSHOP-639, in CA we still want to collect email for abandoned cart props regardless
  // if a user checked the consent for marketing box.
  const isCA = theUserIsInCanada(toLocaleFromTLD());

  const cartAbandonedProps =
    !needsConsent || allowMarketing || isCA
      ? { campaignId: '70115000000oiWfAAI', email }
      : {};
  const verification = yield select(getVerification);
  const _toEvent = (step: number, props: Payload) => {
    if (props.category === 'digital') {
      const overridePromotion = shouldTrackConcessionary(
        props.page,
        props.promotion || '',
        verification,
        action.payload.skus,
      );
      if (overridePromotion) {
        props.promotion = verification.segment;
        props.verificationId = verification.id;
      }
    }
    let additionalTrackingProps: { [key: string]: string } = {};
    if (action.payload.additionalTrackingProps) {
      additionalTrackingProps = action.payload.additionalTrackingProps;
    }
    const properties = {
      ...props,
      ...additionalTrackingProps,
      ...cartAbandonedProps,
      skus: action.payload.skus,
    };
    return toEvent(step, properties);
  };

  const trackingSaga = toTrackCheckoutStepsSaga(_toEvent);

  yield call(trackingSaga, 1);
};

// You can both `take` and `call` a saga because take and call require different params
// any sort of typing the worker saga will cause one of the two to break, I just did this
// to shut up TS.
const takeE = takeEvery as any;

const watcherSaga = function* () {
  yield takeEvery(UserActions.EnteredEmail, handleCompletedEmailSaga);
  yield takeE(ShippingActions.UpdateSuccess, trackCheckoutStepCompletedSaga, 2);
  yield takeEvery(CheckoutActions.Message, handleCheckoutActionSaga);
  yield takeEvery(PaymentActions.SelectPaymentMethod, handlePaymentSelectionSaga);
  yield takeE(ShopAction.AddWarrantyToCartSuccess, trackCheckoutStepCompletedSaga, 6);
  yield takeE(CartActions.SET_IS_GIFT_SUCCESS, trackCheckoutStepCompletedSaga, 7);
};

export default watcherSaga;

export const toStepName = (step: number) =>
  ({
    1: 'User Moves away from Email Address Field',
    2: 'Entered Shipping Information + Tax/Shipping Calculated',
    3: 'Entered Payment Details or clicked Klarna/Affirm/Paybright',
    4: '(CC only) Clicked Place Order on Review Page',
    5: 'Opted out of Peloton Membership',
    6: 'Selected Warranty',
    7: 'Opted for order as a gift',
    8: 'User Logged In',
  }[step]);
