import { pathOr, pickAll } from 'ramda';
import type { PromoHero } from '../models.generated';
import { usePromoHeroQuery } from '../queries/contentTypes/promoHero.generated';
import type { CtaWithUrlData } from './types';
import { transformCtaWithUrlData } from './useCtaWithUrlData';
import type {
  BpVideo,
  BpVideoObjType,
  BpImageObjType,
  ImageBreakpoints as RespImage,
} from './utils';
import { toBpVidObj, toImgWithFallbacks } from './utils';

type BreakpointVideo = BpVideo & {
  __typename: 'BreakpointVideo';
};

type Image = RespImage & {
  __typename: 'Image';
};

export type PromoHeroData = {
  eyebrow?: string;
  header: string;
  subhead: string;
  ctas: CtaWithUrlData[];
  support?: string;
  video?: BpVideoObjType;
  image?: BpImageObjType;
};

const transformMedia = (media: Image | BreakpointVideo) => {
  if (media && media.__typename === 'BreakpointVideo') {
    return {
      video: toBpVidObj(media),
    };
  }
  const bpImage: RespImage = pickAll(
    ['mobile', 'tablet', 'desktop', 'desktopLarge'],
    media,
  );
  return {
    image: toImgWithFallbacks(bpImage),
  };
};

export const transformPromoHeroData = (response: object): PromoHeroData => {
  const {
    key,
    eyebrow,
    header,
    subhead,
    ctasCollection = { items: [] },
    support,
    backgroundMedia = {
      mobile: { url: '' },
      tablet: { url: '' },
      desktop: { url: '' },
      desktopLarge: { url: '' },
    },
  } = pathOr([], ['data', 'promoHero'], response);

  const fallbackText = `PromoHero: ${key}`;

  const ctas: CtaWithUrlData[] = ctasCollection.items[0]
    ? ctasCollection.items.map((ctaWithUrl: object) =>
        transformCtaWithUrlData({ data: { ctaWithUrl } }),
      )
    : [];

  return {
    // prefer undefined over null for optional field
    eyebrow: eyebrow || undefined,
    header: header || fallbackText,
    subhead: subhead || fallbackText,
    ctas,
    // prefer undefined over null for optional field
    support: support || undefined,
    ...transformMedia(backgroundMedia),
  };
};

const usePromoHeroData = (id: PromoHero): PromoHeroData => {
  const res = usePromoHeroQuery({ variables: { id } });

  return transformPromoHeroData(res);
};

export default usePromoHeroData;
