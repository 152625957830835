import React from 'react';
import type { LogoProps } from '@peloton/logos';
import { oneLine } from '@peloton/text/index';

const DEFAULT_AFFIRM_TEXT_COLOR = '#060809';
const DEFAULT_AFFIRM_TITLE_ID = 'affirm-logo-title';
const DEFAULT_AFFIRM_SWOOP_COLOR = '#4a4af4';

type AffirmLogoProps = {
  textColor?: string;
  titleId?: string;
  swoopColor?: string;
};

const AffirmCheckoutIcon: React.FC<
  React.PropsWithChildren<LogoProps & AffirmLogoProps>
> = ({
  textColor = DEFAULT_AFFIRM_TEXT_COLOR,
  titleId = DEFAULT_AFFIRM_TITLE_ID,
  swoopColor = DEFAULT_AFFIRM_SWOOP_COLOR,
  ...props
}) => (
  <svg role="img" viewBox="0 0 325.06 129.71" aria-labelledby={titleId} {...props}>
    <title id={titleId}>Affirm</title>
    <g>
      <path
        style={{ fill: textColor }}
        d={oneLine(`
          M83.87,130.33c-8.82,0-19,4.15-24.47,8.54l5,10.59c4.42-4,11.56-7.49,18-7.49,6.12,0,9.5,2,9.5,6.17,0,2.77-2.24,
          4.18-6.47,4.73-15.82,2.05-28.22,6.42-28.22,18.61,0,9.67,6.88,15.51,17.63,15.51a20.76,20.76,0,0,0,
          17.75-9.89v8.32h14.3V150.56C106.91,136.17,96.91,130.33,83.87,130.33Zm-5.32,45.48c-4,0-6-2-6-5.24,0-6,
          6.76-8.1,19.1-9.42C91.62,169.23,86.16,175.81,78.55,175.81Z
        `)}
        transform="translate(-57.23 -57.27)"
      />
      <path
        style={{ fill: textColor }}
        d={oneLine(`
          M227.44,131.9v53.5h15.3V159.62c0-12.25,7.42-15.85,12.59-15.85a11.69,11.69,0,0,1,6.54,1.93l2.79-14.14a17.41,
          17.41,0,0,0-6.86-1.24c-7.87,0-12.81,3.48-16.07,10.57v-9Z
        `)}
        transform="translate(-57.23 -57.27)"
      />
      <path
        style={{ fill: textColor }}
        d={oneLine(`
          M335.61,130.33a21.22,21.22,0,0,0-17.29,9.39c-2.92-6-9.12-9.39-16.54-9.39A17.73,17.73,0,0,0,285.5,
          140V131.9H270.76v53.5h15.31V157.87c0-9.89,5.18-14.63,10-14.63,4.37,0,8.39,2.83,8.39,
          10.14v32h15.29V157.87c0-10,5.05-14.63,10.11-14.63,4,0,8.31,2.94,8.31,10V185.4h15.29v-37C353.48,136.4,345.39,
          130.33,335.61,130.33Z
        `)}
        transform="translate(-57.23 -57.27)"
      />
      <path
        style={{ fill: textColor }}
        d={oneLine(`
          M190.19,131.9H176.33v-5.44c0-7.08,4-9.1,7.53-9.1a15.26,15.26,0,0,1,6.85,
          1.71l4.72-10.79s-4.78-3.13-13.48-3.13c-9.78,0-20.91,5.51-20.91,22.82v3.93H137.83v-5.44c0-7.08,4-9.1,
          7.53-9.1a14.53,14.53,0,0,1,6.85,1.71l4.72-10.79c-2.82-1.65-7.34-3.13-13.48-3.13-9.78,0-20.91,5.51-20.91,
          22.82v3.93h-8.88v11.8h8.88v41.7h15.29V143.7H161v41.7h15.29V143.7h13.86Z
        `)}
        transform="translate(-57.23 -57.27)"
      />
      <rect
        style={{ fill: textColor }}
        x="142.08"
        y="74.63"
        width="15.28"
        height="53.5"
      />
      <path
        style={{ fill: swoopColor }}
        d={oneLine(`
          M288.44,57.27c-41.31,0-78.12,28.67-88.56,65.53h15c8.73-27.45,38.35-51.55,73.59-51.55,42.84,0,79.86,32.61,
          79.86,83.39A104.34,104.34,0,0,1,364,185.4h14.52l.14-.5a122.91,122.91,0,0,0,3.59-30.26C382.29,98,341,57.27,
          288.44,57.27Z
        `)}
        transform="translate(-57.23 -57.27)"
      />
    </g>
  </svg>
);

export { AffirmCheckoutIcon as AffirmLogo };
