import { createMatchSelector } from 'connected-react-router';
import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { ID, UIState } from '@ecomm/models';
import { Status } from '@ecomm/models';
import { path } from '../../../routes';
import type { ReducerState } from '../../redux';

export enum ActionType {
  Request = 'ecomm/classes/REQUEST',
  Success = 'ecomm/classes/SUCCESS',
  Fail = 'ecomm/classes/FAIL',
}

export type State = Record<ID, UIState>;

export const initialState: State = {};

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.Request:
      return {
        ...state,
        [action.payload.instructorID]: {
          status: Status.Loading,
        },
      };

    case ActionType.Fail:
      return {
        ...state,
        [action.payload.instructorID]: {
          status: Status.Failed,
          exception: action.payload.exception,
        },
      };

    case ActionType.Success:
      return {
        ...state,
        [action.payload.instructorID]: {
          status: Status.Loaded,
        },
      };

    default:
      return state;
  }
};

export default reducer;

type RequestAction = {
  type: ActionType.Request;
  payload: {
    instructorID: ID;
  };
};

export const request = (instructorID: ID): RequestAction => ({
  type: ActionType.Request,
  payload: {
    instructorID,
  },
});

type SuccessAction = {
  type: ActionType.Success;
  payload: {
    instructorID: ID;
  };
};

export const success = (instructorID: ID): SuccessAction => ({
  type: ActionType.Success,
  payload: {
    instructorID,
  },
});

type FailAction = {
  type: ActionType.Fail;
  payload: {
    instructorID: ID;
    exception: Exception;
  };
};

export const fail = (instructorID: ID, error: Error): FailAction => ({
  type: ActionType.Fail,
  payload: {
    exception: toException(error.message),
    instructorID,
  },
});

type Action = RequestAction | SuccessAction | FailAction;

export const getUIState = (state: ReducerState, instructorID: ID) =>
  state.instructorsPage.classes.fetcher[instructorID] || Status.Init;

export const toMatchInstructorsClassesPath = () =>
  createMatchSelector(`${path}/:username`);
