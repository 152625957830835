import React from 'react';
import useTrackLinkClick from '@ecomm/analytics/hooks/useTrackLinkClick';
import type { MarkdownChildrenType } from '../../utils/Markdown';

const TrackedLinkRenderer: React.FC<{
  children: MarkdownChildrenType;
  href: string;
}> = ({ children, href }) => {
  const { trackLinkClick } = useTrackLinkClick();
  return (
    <a
      href={href}
      onClick={() =>
        trackLinkClick({
          href,
          parent: 'hurricane banner',
          additionalProps: {
            parentType: 'componentBanner',
          },
        })
      }
    >
      <>{children}</>
    </a>
  );
};

export default TrackedLinkRenderer;
