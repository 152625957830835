import { all, fork } from 'redux-saga/effects';
import { default as addBundleToCartSaga } from './addBundleToCartSaga';
import { default as addProductToCartSaga } from './addProductToCartSaga';
import addToCartSaga from './addToCartSaga';
import { default as addWarrantyToCartSaga } from './addWarrantyToCartSaga';
import analyticsSaga from './analyticsSaga';
import configureRouteSaga from './configureRouteSaga';
import fetchBundleSaga from './fetchBundleSaga';
import fetcherSaga from './fetcherSaga';
import fetchProductSaga from './fetchProductSaga';
import trackProductViewedSaga from './trackProductViewed';

export default function* shopSagas() {
  yield all([
    fork(addToCartSaga),
    fork(addBundleToCartSaga),
    fork(addProductToCartSaga),
    fork(addWarrantyToCartSaga),
    fork(analyticsSaga),
    fork(configureRouteSaga),
    fork(fetcherSaga),
    fork(fetchProductSaga),
    fork(fetchBundleSaga),
    fork(trackProductViewedSaga),
  ]);
}
