import { useEffect } from 'react';
import { useTracking } from 'react-tracking';

type EventCallback = (event: any) => void;

declare global {
  interface Window {
    drift?: {
      api: {
        startInteraction: ({ interactionId }: { interactionId: number }) => void;
      };
      on: (eventName: string, callback: EventCallback) => void;
      off: (eventName: string, callback: EventCallback) => void;
    };
  }
}

export const getDrift = () => window.drift;

export const driftExists = () => Boolean(getDrift());

export const startDriftInteraction = (interactionId: number) => {
  if (!window.drift) {
    return;
  }
  return window.drift.api.startInteraction({ interactionId });
};

enum ProductInterest {
  Bike = 'Bike',
  Tread = 'Tread',
  Digital = 'Digital',
}

const driftEventsToSegment = {
  startConversation: 'Conversation Started',
  message: 'Message Received',
  emailCapture: 'Email Captured',
  phoneCapture: 'Phone Captured',
  'conversation:selected': 'Conversation Selected',
  'message:sent': 'Message Sent',
  'scheduling:requestMeeting': 'Meeting Request Sent',
  'scheduling:meetingBooked': 'Meeting Booked',
  'conversation:playbookFired': 'Playbook Fired',
  'conversation:playbookClicked': 'Playbook Clicked',
  'conversation:playbookDismissed': 'Playbook Dismissed',
  'conversation:buttonClicked': 'Button Clicked',
  'conversation:firstInteraction': 'First Interaction',
};

const driftEventNames = Object.keys(driftEventsToSegment);

const toProductInterestFromWindow = () => {
  const path = window.location.pathname;

  if (path.match(/\/bike/)) {
    return ProductInterest.Bike;
  } else if (path.match(/\/tread/)) {
    return ProductInterest.Tread;
  } else if (path.match(/\/digital/)) {
    return ProductInterest.Digital;
  }

  return '';
};

export const useDriftEventTracking = () => {
  const { trackEvent } = useTracking();

  useEffect(() => {
    const eventHandlers: Record<string, EventCallback> = {};

    const drift = getDrift();
    if (drift) {
      for (const eventName of driftEventNames) {
        const handler = (data: any) => {
          const mappedSegmentEvent = driftEventsToSegment[eventName];
          if (mappedSegmentEvent) {
            trackEvent({
              event: `Drift ${mappedSegmentEvent}`,
              properties: {
                productInterest: toProductInterestFromWindow(),
                ...data,
              },
            });
          }
        };

        drift.on(eventName, handler);
        eventHandlers[eventName] = handler;
      }
    }

    return () => {
      if (drift) {
        driftEventNames.map(eventName => drift.off(eventName, eventHandlers[eventName]));
      }
    };
  }, [trackEvent]);
};
