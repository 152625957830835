import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import { addItemsToCart } from '@ecomm/cart/api/createCartItems';
import { loadCartSuccess } from '@ecomm/cart/redux';
import type { ID } from '@ecomm/models';
import {
  areProductsConfigurable,
  getBundleById,
  getCheapestOptions,
  getIsBundleConfigured,
  getOptionsAsConfigured,
  startTransaction,
} from '../redux';
import type { AddBundleToCartRequestedAction } from '../redux/shopper';
import { addToCartSuccess, ActionType } from '../redux/shopper';

export const addBundleToCartSaga = function* (
  id: ID,
  productOptions: ID[],
  isUpsell?: boolean,
): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);

  yield put(startTransaction(id));

  try {
    const payload = {
      bundles: [{ bundleId: id, productOptions }],
    };
    const cart = yield call(addItemsToCart, client, payload);
    yield all([
      put(addToCartSuccess(id, undefined, isUpsell)),
      put(loadCartSuccess(cart)),
    ]);
  } catch (err) {
    const error = new DomainError(
      'Error adding package to cart in addBundleToCartSaga',
      err,
    );
    yield put(reportError({ error }));
  }
};

export const configureBundleSaga = function* (
  action: AddBundleToCartRequestedAction,
): SagaIterator {
  const { id, isUpsell } = action.payload;
  const { products } = yield select(getBundleById, { id }) || {};

  const { isConfigured, needsConfiguration } = yield all({
    isConfigured: select(getIsBundleConfigured, { id }),
    needsConfiguration: select(areProductsConfigurable, { products }),
  });

  if (needsConfiguration && !isConfigured) {
    // NOTE: Should we need to display more nuanced errors to the user,
    // this is the place to do so.
  } else if (!needsConfiguration) {
    const cheapestProductOptions = yield select(getCheapestOptions, { products });
    yield call(addBundleToCartSaga, id, cheapestProductOptions, isUpsell);
  } else {
    const productOptions = yield select(getOptionsAsConfigured, { id });
    yield call(addBundleToCartSaga, id, productOptions, isUpsell);
  }
};

const watcherSaga = function* () {
  yield takeEvery(ActionType.AddBundleToCartRequested, configureBundleSaga);
};

export default watcherSaga;
