import type React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  ADA_BOT_HANDLE,
  ADA_BOT_HANDLE_GENERATIVE,
  DRIFT_KEY,
} from '@peloton/app-config';
import { addOpenChatCallbackToWindow, injectAdaScript } from '@peloton/chat';
import { initChat } from '@peloton/drift';
import type { EnvironmentFlags } from '@peloton/env';
import type { Locale } from '@peloton/internationalize';
import { useIsAdaMigrationEnabled } from '@ecomm/feature-toggle/AdaMigration';
import { useIsOriginalActive } from '@ecomm/feature-toggle/CocoEntryPoint';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';

type Props = {
  envFlags: EnvironmentFlags;
  locale: Locale;
};

export const ChatBotScript: React.FC<Props> = ({ envFlags, locale }) => {
  const isInCocoOriginal = useIsOriginalActive();
  const isAdaMigrationActive = useIsAdaMigrationEnabled();
  const dispatch = useDispatch();
  const isFirstPartySales = !useIsToggleActive()('de3pTransition');

  useEffect(() => {
    setTimeout(() => {
      // Don't inject any chat scripts when the locale is doing 3p sales
      if (!isFirstPartySales) {
        return;
      }
      if (ADA_BOT_HANDLE_GENERATIVE && isInCocoOriginal === false) {
        injectAdaScript(locale, ADA_BOT_HANDLE_GENERATIVE);
      }
      if (
        !envFlags.isLocal &&
        !envFlags.isCI &&
        !isAdaMigrationActive &&
        DRIFT_KEY &&
        isInCocoOriginal
      ) {
        dispatch(initChat(DRIFT_KEY, locale));
      }

      if (isAdaMigrationActive && ADA_BOT_HANDLE && isInCocoOriginal) {
        injectAdaScript(locale, ADA_BOT_HANDLE);
      }

      addOpenChatCallbackToWindow();
    }, 2000);
  }, [
    envFlags.isLocal,
    envFlags.isCI,
    dispatch,
    isAdaMigrationActive,
    locale,
    isInCocoOriginal,
    isFirstPartySales,
  ]);

  return null;
};
