import { createMatchSelector } from 'connected-react-router';
import { createSelector } from 'reselect';
import { localeUrlRegexMatcher } from '@peloton/internationalize/models/path';

export default function (path: string) {
  const getRouteDefault = createMatchSelector(path);
  const getRouteIntl = createMatchSelector(`/:locale(${localeUrlRegexMatcher})${path}`);

  return createSelector([getRouteDefault, getRouteIntl], (us, i18n) => {
    return us || i18n;
  });
}
