import { useHasVariation } from '@peloton/split-testing/hooks';

const APP_CTA_EXPERIMENT = 'App CTA Test';
const APP_CTA_VARIATION = 'Variation #1';

const useIsAppCtaExperimentActive = () => {
  return useHasVariation(APP_CTA_EXPERIMENT, APP_CTA_VARIATION);
};

export default useIsAppCtaExperimentActive;
