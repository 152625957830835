import { identity } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeDataTwice } from '@peloton/api';
import type { Time } from '@peloton/time';
import type { ID } from '@ecomm/models';
import type { ApiOrder } from '@ecomm/order/api/types';

const toOrderUrl = (orderId: ID) => `/ecomm/order/${orderId}`;
const toScheduleUrl = (shipmentId: ID) =>
  `/ecomm/shipment/${shipmentId}/schedule_delivery`;

// encapsulate weird inability to schedule delivery by order id
export const scheduleDelivery = async (
  api: Client,
  orderId: ID,
  deliveryDate: Time | null,
) => {
  const orderResponse = await api.get(toOrderUrl(orderId));
  const order = pipeDataTwice(identity)(orderResponse) as ApiOrder;

  const bikeShipment = order.shipmentSet.find(
    shipmentSet => shipmentSet.canScheduleDelivery,
  );
  if (bikeShipment) {
    await api.post(toScheduleUrl(bikeShipment.id), {
      // a null deliveryDate unschedules the delivery
      deliveryDate: deliveryDate !== null ? deliveryDate.format('YYYY-MM-DD') : null,
    });
  }
};
