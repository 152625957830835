import { identity } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeDataTwice, pipeError, throwError } from '@peloton/api';
import type { ErrorCode, Registration } from '@ecomm/registration/models';
import { toErrorCode, toRegistrationWithCountry } from '@ecomm/registration/models';

const toCorpWellnessRegistrationUrl = () => '/api/corporate-wellness/register-user';

export const cw_register = (api: Client, data: Registration) =>
  api
    .post(toCorpWellnessRegistrationUrl(), toRegistrationWithCountry(data))
    .then(pipeDataTwice(identity))
    .catch(pipeError(throwError<ErrorCode>(toErrorCode)));
