import type { SagaIterator } from 'redux-saga';
import { all, fork, put, select, takeLatest } from 'redux-saga/effects';
import type { ExistsAction, DoesNotExistAction } from '@ecomm/checkout/redux';
import { getDoesUserExist, hasUserEmailErrors, UserActions } from '@ecomm/checkout/redux';
import { smoothScroll } from '@ecomm/scroll';
import type { NextFromEmailCaptureRequestedAction } from '../redux';
import {
  MultiStepActionTypes,
  denyNextFromEmailCapture,
  allowNextFromEmailCapture,
} from '../redux';
import type { PreviousFromCheckoutRequestedAction } from '../redux/multiStep';
import {
  AllowPreviousFromCheckoutStep,
  getGoToNextStep,
  getHasAlreadyRequested,
} from '../redux/multiStep';
import analytics from './analytics';

const validateEmailCaptureStep = function* (
  action: NextFromEmailCaptureRequestedAction,
): SagaIterator {
  const hasErrors = yield select(hasUserEmailErrors);
  const userShouldLogin = yield select(getDoesUserExist);

  if (hasErrors || userShouldLogin || userShouldLogin === undefined) {
    yield put(denyNextFromEmailCapture());
  } else {
    yield put(allowNextFromEmailCapture(action.payload.skus));
    if (action.payload.goToNextStep) {
      action.payload.goToNextStep();
    }
    yield put(smoothScroll({ top: 0 }));
  }
};

const validateCheckoutStep = function* (action: PreviousFromCheckoutRequestedAction) {
  yield put(AllowPreviousFromCheckoutStep());
  if (action.payload.goToPreviousStep) {
    action.payload.goToPreviousStep();
  }
};
const validateUserStatusResponse = function* (
  action: ExistsAction | DoesNotExistAction,
): SagaIterator {
  const hasErrors = yield select(hasUserEmailErrors);
  const userShouldLogin = yield select(getDoesUserExist);
  const alreadyRequested = yield select(getHasAlreadyRequested);
  const goToNextStep = yield select(getGoToNextStep);

  if (hasErrors || userShouldLogin || !alreadyRequested) {
    yield put(denyNextFromEmailCapture());
  } else {
    yield put(allowNextFromEmailCapture(action.payload.skus));
    goToNextStep();
    yield put(smoothScroll({ top: 0 }));
  }
};

const watcherSaga = function* () {
  yield takeLatest(
    MultiStepActionTypes.NextFromEmailCaptureRequested,
    validateEmailCaptureStep,
  );
  yield takeLatest(
    MultiStepActionTypes.PreviousFromCheckoutRequested,
    validateCheckoutStep,
  );
  yield takeLatest(UserActions.Exists, validateUserStatusResponse);
  yield takeLatest(UserActions.DoesNotExist, validateUserStatusResponse);
  // I'm just using `all()` here because its used in other places
  // that have multiple forks, and I'm not sure what side effects
  // `all` has that it might be relying on.
  yield all([fork(analytics)]);
};

export default watcherSaga;
