import { all, call, select, take } from 'redux-saga/effects';
import { track } from '@peloton/analytics';
import { getCheckoutCategory, getCheckoutStartedProps } from '@ecomm/cart';
import { UiActionType } from '@ecomm/checkout/redux';

const sagas = function* () {
  yield take(UiActionType.Ready);

  const [properties, category]: [
    ReturnType<typeof getCheckoutStartedProps>,
    ReturnType<typeof getCheckoutCategory>,
  ] = yield all([select(getCheckoutStartedProps), select(getCheckoutCategory)]);

  yield call(track, {
    event: 'Checkout Started',
    properties: { ...properties, category },
  });
};

export default sagas;
