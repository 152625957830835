import type { SagaIterator } from 'redux-saga';
import { all, getContext, takeLatest, call, put } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { UserReducerActionType as Login } from '@peloton/auth';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import type { Hooks } from '@ecomm/models';
import { getHook } from '@ecomm/models';
import { fetchUserSubscriptions } from '../api';
import { ErrorCode } from '../models';
import {
  failSubscriptionFetch,
  requestSubscriptionFetch,
  resetSubscriptionFetch,
  succeedSubscriptionFetch,
} from '../redux';

type Options = {
  hooks?: Hooks;
};

export const fetcherSaga = function* ({ hooks }: Options = {}): SagaIterator {
  const onError = getHook('onError', hooks);
  const onSuccess = getHook('onSuccess', hooks);

  const client = yield getContext(CLIENT_CONTEXT);
  try {
    yield put(requestSubscriptionFetch());
    const entities = yield call(fetchUserSubscriptions, client);

    yield all([put(succeedSubscriptionFetch(entities)), put(onSuccess())]);
  } catch (err) {
    if (err.message === ErrorCode.Unauthenticated) {
      yield put(resetSubscriptionFetch());
      return;
    }

    const error = new DomainError(`Error fetching user's subscriptions`, err);

    yield all([
      put(failSubscriptionFetch(err)),
      put(reportError({ error })),
      put(onError(err)),
    ]);
  }
};

const userSubscriptionsWatcherSaga = function* () {
  yield takeLatest(Login.REQUEST_SUCCESS, fetcherSaga);
};

export default userSubscriptionsWatcherSaga;
