import { brand } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { asyncComponent } from '@peloton/code-splitting';
import { LazyBelowFold, BelowFold } from '@peloton/scroll/LazyBelowFold';
import { media } from '@peloton/styles';
import { BlogCardsModule } from '@ecomm/blog-cards-module';
import { useHeaderBodyImageGalleryData } from '@ecomm/copy';
import { useIsToggleActive } from '@ecomm/feature-toggle';
import usePrequalifyNowModule from '@ecomm/financing/hooks/usePrequalifyNowModule';
import { PrequalifyNowHomePageModule } from '@ecomm/financing/Views/PrequalifyNowHomePageModule';
import { ValueCalcEntryPoint } from '@ecomm/pg-value-calculator';
import { Product, useProductStates } from '@ecomm/product-states';
import {
  HomepageHeroCarousel,
  LeadGenBlock,
  ProductBellyBand,
  PortfolioHomepageValuesSection,
} from './Views';

const PromoProductBlocks = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "PromoProductBlocks", webpackPrefetch: true */ './Views/ProductBlock/PromoProductBlocks'
    ),
);

const ValuesSection = asyncComponent(
  () => import(/* webpackChunkName: "ValuesSection"*/ './Views/ValuesSection'),
);

const PressQuoteBanner = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "PressQuoteBanner"*/ '@ecomm/themed-modules/PressQuoteBanner'
    ),
);

const BikeNearestShowroom = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "BikeNearestShowroom" */ '@ecomm/geolocation/BikeNearestShowroom'
    ),
);

const TryPelotonSectionWithBorder = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "TryPelotonSectionWithBorder" */ './Views/TryPelotonSectionWithBorder'
    ),
);

type ToggleProps = {
  showPortfolioHomepage: boolean;
};

const HomepageView: React.FC<React.PropsWithChildren<ToggleProps>> = ({
  showPortfolioHomepage,
}) => {
  const { isProductAvailableForShopLeadCapture } = useProductStates();
  const showPreSaleLeadCapture = isProductAvailableForShopLeadCapture(
    Product.RainforestCafe,
  );

  const isToggleActive = useIsToggleActive();

  const isPressQuotesActive = isToggleActive('pressQuotes');
  const isProductBlockSwitchActive = isToggleActive('homepageProductBlockSwitch');
  const tryPelotonActive = isToggleActive('tryPeloton');

  const showPrequalifyNowModule = usePrequalifyNowModule();
  const galleryCards = useHeaderBodyImageGalleryData('homepage.videoGallery.carousel');

  return (
    <section>
      <HomepageHeroCarousel />
      {showPrequalifyNowModule && <PrequalifyNowHomePageModule />}
      <ProductBellyBand showTryBanner={tryPelotonActive} />

      {/*
      If you add/change content around the top of the page, make sure all
      content displayed 'beneath the fold' goes inside BelowFold for
      the most performant lazy-loading */}
      <BelowFold>
        {showPortfolioHomepage && <PortfolioHomepageValuesSection cards={galleryCards} />}
        {isProductBlockSwitchActive && <PromoProductBlocks />}
        {showPortfolioHomepage && <ValueCalcEntryPoint />}
        {!isProductBlockSwitchActive && <PromoProductBlocks />}
        {showPreSaleLeadCapture && <LeadGenBlock />}

        <LazyBelowFold dataTestId="lazy-loaded">
          {isPressQuotesActive && (
            <PressQuoteBanner contentfulId="homepageQuotes" dark={true} />
          )}
          {!showPortfolioHomepage && <ValueCalcEntryPoint />}
          <BlogCardsModule page="homepage" />
          <BikeNearestShowroom />
          {!showPortfolioHomepage && <ValuesSection cards={galleryCards} />}
          {tryPelotonActive && <TryPelotonSectionWithBorder />}
          {!showPortfolioHomepage && <SpaceBeforeFooter />}
        </LazyBelowFold>
      </BelowFold>
    </section>
  );
};

export default HomepageView;

const SpaceBeforeFooter = styled.div`
  background-color: ${brand.light};
  height: 80px;
  ${media.tablet`
    height: 100px;
  `}
`;
