import { ActionType as FetcherActionType, getUIState } from './fetcher';
import type { State as TState } from './redux';
import classes, {
  getInstructor,
  getInstructors,
  getInstructorByUsername,
  getInstructorIdsByFilter,
  getInstructorIdsByDiscipline,
  getInstructorsUserNames,
} from './redux';

export {
  FetcherActionType,
  getInstructor,
  getInstructors,
  getInstructorByUsername,
  getInstructorIdsByFilter,
  getInstructorIdsByDiscipline,
  getInstructorsUserNames,
  getUIState,
};

export default classes;

export type State = TState;
