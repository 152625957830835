import { pathOr, prop } from 'ramda';
import type { PromoHeroSet, PromoHero } from '../models.generated';
import { usePromoHeroSetQuery } from '../queries/contentTypes/promoHeroSet.generated';

type PromoSetData = {
  key: PromoHero;
};

export const transformPromoHeroSetData = (response: object): PromoHero[] => {
  const rawPromoHeroes = pathOr(
    [],
    ['data', 'promoHeroSet', 'heroItemsCollection', 'items'],
    response,
  );

  const PromoHeroSetsWithFallbacks: PromoHero[] = rawPromoHeroes
    .filter((el: PromoSetData) => Boolean(el))
    .map(prop('key'));

  return PromoHeroSetsWithFallbacks;
};

const usePromoHeroSetData = (id: PromoHeroSet): PromoHero[] => {
  const res = usePromoHeroSetQuery({ variables: { id } });

  return transformPromoHeroSetData(res);
};

export default usePromoHeroSetData;
