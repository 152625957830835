enum Setting {
  key = 'ecomm/privacyBanner',
  value = 'accepted',
}

export const persist = (
  storage = window.localStorage,
  key: string = Setting.key,
  value: string = Setting.value,
) => storage && storage.setItem(key, value);

export const isPersisted = (
  storage = window.localStorage,
  key: string = Setting.key,
): boolean => storage && storage.getItem(key) === Setting.value;
