import type { ID } from '@ecomm/models';

export enum ActionType {
  ViewedProduct = 'ecomm/shop/VIEWED_PRODUCT',
}

type BundleOrProductId = {
  bundleId?: ID;
  productId?: ID;
};

export const viewedProduct = ({
  bundleId,
  productId,
}: BundleOrProductId): ViewedProductAction => ({
  type: ActionType.ViewedProduct,
  payload: { productId, bundleId },
});

export type ViewedProductAction = {
  type: ActionType.ViewedProduct;
  payload: BundleOrProductId;
};
