import { identity, propOr, toString, compose } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeData, toSkipErrorHandlingConfig, isNetworkError } from '@peloton/api';
import { toProfileUrl } from './member';

export enum ErrorMessage {
  UsernameTaken = 'onewellness.errors.usernameTaken', // That username is taken
  UsernameInvalid = 'onewellness.errors.usernameInvalid', // Username invalid
  Unknown = 'onewellness.errors.one-wellness-error', // Oops, something went wrong
}

// Error codes defined by the monolith API.
const subcodeMap = {
  10: ErrorMessage.UsernameTaken,
  11: ErrorMessage.UsernameInvalid,
};

const toErrorMessage = (err: any) => {
  if (isNetworkError(err)) {
    return ErrorMessage.Unknown;
  }
  const response = err.responseBody || err.response.data;
  if (response.errorCode === 152) {
    return ErrorMessage.UsernameInvalid;
  }
  if (response.errorCode === 150) {
    return propOr<string, typeof subcodeMap, string>(
      ErrorMessage.Unknown,
      toString(response.subcode),
      subcodeMap,
    );
  }
  return ErrorMessage.Unknown;
};

const throwError = (errorMessage: string) => {
  const e = new Error();
  e.message = errorMessage;
  throw e;
};

export const updateMemberUsername = (api: Client, memberId: string, username: string) =>
  api
    .put(toProfileUrl(memberId), { username }, toSkipErrorHandlingConfig())
    .then(pipeData(identity))
    .catch(compose(throwError, toErrorMessage));
