// THIS IS AN AUTOGENERATED FILE (@ecomm/graphql/codegen.yml). DO NOT EDIT THIS FILE DIRECTLY.
/* eslint-disable */
import * as Types from '../../graphql/types.generated';

import { DocumentNode } from 'graphql';
import * as ApolloReactCommon from '@ecomm/graphql/hooks';
import * as ApolloReactHooks from '@ecomm/graphql/hooks';
export type PackagesQueryVariables = Types.Exact<{
  locale: Types.Locale;
  currency: Types.Currency;
  catalog: Types.Scalars['Slug'];
  productLine: Types.Scalars['String'];
}>;

export type PackagesQuery = { __typename?: 'Query' } & {
  catalog: { __typename?: 'Catalog' } & {
    packages?: Types.Maybe<
      Array<
        { __typename?: 'Package' } & {
          connectedFitnessUnit: { __typename?: 'Product' } & {
            variants: Array<
              { __typename?: 'ProductVariant' } & Pick<Types.ProductVariant, 'skus'>
            >;
          };
        }
      >
    >;
  };
};

export const PackagesDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Packages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Locale' } },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Currency' } },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'catalog' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Slug' } },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productLine' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'catalog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'catalog' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locale' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'packages' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'productLine' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'productLine' },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'connectedFitnessUnit' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'variants' },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skus' },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __usePackagesQuery__
 *
 * To run a query within a React component, call `usePackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagesQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      currency: // value for 'currency'
 *      catalog: // value for 'catalog'
 *      productLine: // value for 'productLine'
 *   },
 * });
 */
export function usePackagesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PackagesQuery, PackagesQueryVariables>,
) {
  return ApolloReactHooks.useQuery<PackagesQuery, PackagesQueryVariables>(
    PackagesDocument,
    baseOptions,
  );
}
export function usePackagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PackagesQuery,
    PackagesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<PackagesQuery, PackagesQueryVariables>(
    PackagesDocument,
    baseOptions,
  );
}
export type PackagesQueryHookResult = ReturnType<typeof usePackagesQuery>;
export type PackagesLazyQueryHookResult = ReturnType<typeof usePackagesLazyQuery>;
export type PackagesQueryResult = ApolloReactCommon.QueryResult<
  PackagesQuery,
  PackagesQueryVariables
>;
