import React from 'react';
import { I18N_LOCALE_TOGGLE } from '@peloton/app-config';
import { toHref } from '@peloton/external-links/models';
import type { PeloLink, ExtLinkEnv } from '@peloton/external-links/models';
import type { Locale } from '@peloton/internationalize';
import {
  AVAILABLE_LOCALES_FOR_ECOMM_INTERNATIONALIZE,
  toLocaleFromString,
  toLocaleHostname,
} from '@peloton/internationalize';

export type Props = {
  extLinkEnv: ExtLinkEnv;
  canonicalLink: PeloLink;
  availableHrefLangs?: Partial<Locale>[];
  locale?: Locale;
};

type LinkProps = React.LinkHTMLAttributes<HTMLLinkElement> & { key: string };

const toLinks = ({
  extLinkEnv,
  canonicalLink,
  availableHrefLangs = AVAILABLE_LOCALES_FOR_ECOMM_INTERNATIONALIZE,
  locale,
}: Props) =>
  toLinkProps({
    extLinkEnv,
    canonicalLink,
    availableHrefLangs,
    locale,
  }).map(({ key, ...linkProps }) => <link key={key} {...linkProps} />);

const toLinkProps = (props: Props): LinkProps[] => [
  toCanonicalLink(props),
  ...toAlternateLinks(props),
];

export const toCanonicalLink = ({
  canonicalLink,
  extLinkEnv,
  locale,
}: Props): LinkProps => {
  // SEO paths need to be lowercase but locale needs to be mixed case
  const formattedCanonical = {
    ...canonicalLink,
    path: canonicalLink.path?.toLowerCase(),
  };
  let href = toHref(formattedCanonical, {
    ...extLinkEnv,
    subpathLocale: I18N_LOCALE_TOGGLE ? locale : undefined,
  });
  // if href ends with /, remove it
  if (href.endsWith('/')) {
    href = href.slice(0, -1);
  }
  return {
    key: `canonical-${href}`,
    rel: 'canonical',
    href,
  };
};

export const toAlternateLinks = ({
  canonicalLink,
  extLinkEnv,
  availableHrefLangs,
}: Props): LinkProps[] =>
  toAvailableHrefLangs(availableHrefLangs!).map(hrefLang => {
    let href = toHref(canonicalLink, {
      ...extLinkEnv,
      // TODO: This feels hacky.
      //       toHref should have a better way to override the tld.
      hostname: I18N_LOCALE_TOGGLE
        ? 'onepeloton.com'
        : toLocaleHostname(toLocaleFromString(hrefLang)),
      subpathLocale:
        hrefLang === 'x-default' ? toLocaleFromString('') : toLocaleFromString(hrefLang),
    });
    // if href ends with /, remove it
    if (href.endsWith('/')) {
      href = href.slice(0, -1);
    }
    return {
      key: `alternate-${hrefLang}-${href}`,
      rel: 'alternate',
      hrefLang,
      href,
    };
  });

type HrefLang = Locale | 'x-default';

export const toAvailableHrefLangs = (
  availableHrefLangs: Partial<Locale>[],
): HrefLang[] => {
  // TODO: Be smarter about which domain is the default
  //       for each language code ('en', 'de', 'fr', etc.)
  //       Currently we rely on undefined behavior by `toHref`
  //       that defaults all unmatched locales to .com.
  //       Maybe make a toLocaleFromHrefLang to use in toAlternateLinks.
  return [...availableHrefLangs, 'x-default'];
};

export default toLinks;
