import type {
  ApolloClient as ApolloClientV3,
  NormalizedCacheObject as NormalizedCacheObjectV3,
  ApolloLink,
} from '@apollo/client';
import { ALLOW_GRAPHQL_DEV_TOOLS } from '@peloton/app-config';
import type { ExtLinkEnv } from '@peloton/external-links/models';
import { toApolloClient } from '@peloton/with-apollo/v3/withApollo';

interface Options {
  errorLink: ApolloLink;
  authLink: ApolloLink;
  env: ExtLinkEnv;
  initialState: NormalizedCacheObjectV3;
  connectToDevTools?: boolean;
}

let apolloV3Client: ApolloClientV3<NormalizedCacheObjectV3>;

export const getApolloClientInstanceV3 = (locale: string, options?: Options) => {
  const opts = {
    locale,
    ...options,
    connectToDevTools: ALLOW_GRAPHQL_DEV_TOOLS,
  };
  if (apolloV3Client) {
    return apolloV3Client;
  }
  apolloV3Client = toApolloClient(opts)();
  return apolloV3Client;
};
