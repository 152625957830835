import type { RefObject } from 'react';
import {
  isEscapeKey,
  isLeftArrowKey,
  isRightArrowKey,
  isTabKey,
} from '@peloton/keyboard';
import { Menu } from '../models';

export const handleTabKey = (
  e: KeyboardEvent | any,
  menus: Menu[],
  menuItemRefs: Object,
  onFocus: Function,
) => {
  const currentlyFocusedMenu = menus.find((menu: Menu) => {
    const ref = menuItemRefs[menu];
    return ref.current && ref.current === e.target;
  });
  onFocus(currentlyFocusedMenu || Menu.None);
};

export const handleArrowKeys = (
  e: KeyboardEvent | any,
  menus: Menu[],
  focusedMenu: Menu,
  currentMenu: Menu,
  menuItemRefs: Object,
  onFocus: Function,
) => {
  const isMenuActiveAndFocused = currentMenu !== Menu.None && focusedMenu !== Menu.None;
  if (isMenuActiveAndFocused) {
    const currentMenuByIndex: number = menus.findIndex((menu: Menu): boolean =>
      focusedMenu ? menu === focusedMenu : menu === currentMenu,
    );

    const getMenuBySelectionIndex = (menuIndex: number) => {
      const firstMenuIndex = 0;
      const lastMenuIndex = menus.length - 1;

      if (menuIndex > lastMenuIndex) {
        return menus[firstMenuIndex];
      } else if (menuIndex < firstMenuIndex) {
        return menus[lastMenuIndex];
      } else return menus[menuIndex];
    };

    const menuToFocus = isRightArrowKey(e)
      ? getMenuBySelectionIndex(currentMenuByIndex + 1)
      : getMenuBySelectionIndex(currentMenuByIndex - 1);

    const btnRef = menuItemRefs[menuToFocus];

    if (btnRef && menuToFocus !== focusedMenu) {
      btnRef.current?.focus();
      onFocus(menuToFocus);
    }
  }
};

export const handleEscapeKey = (onEscapeAction: Function) => {
  onEscapeAction();
};

const keyboardTrap = (
  wrapperRef: RefObject<HTMLUListElement>,
  menus: Menu[],
  currentMenu: Menu,
  focusedMenu: Menu,
  menuItemRefs: Object,
  onEscape: Function,
  onFocus: Function,
) => () => {
  const handleEscapeAndArrowKeys = (e: KeyboardEvent) => {
    if (isEscapeKey(e)) {
      handleEscapeKey(onEscape);
    } else if (isRightArrowKey(e) || isLeftArrowKey(e)) {
      handleArrowKeys(e, menus, focusedMenu, currentMenu, menuItemRefs, onFocus);
    } else if (isTabKey(e)) {
      handleTabKey(e, menus, menuItemRefs, onFocus);
    }
  };
  wrapperRef.current?.addEventListener('keyup', handleEscapeAndArrowKeys);
  return () => {
    wrapperRef.current?.removeEventListener('keyup', handleEscapeAndArrowKeys);
  };
};

export default keyboardTrap;
