import type { Email } from '@ecomm/models';

export enum ActionTypes {
  UserExists = 'ecomm/registration/user/USER_EXISTS',
  ResolveDefaultMarketingState = 'ecomm/registration/user/RESOLVE_DEFAULT_MARKETING_STATE',
}

export const userExists = (email: Email): UserExistsAction => ({
  type: ActionTypes.UserExists,
  payload: { email },
});

export type UserExistsAction = {
  type: ActionTypes.UserExists;
  payload: { email: Email };
};

export const resolveDefaultMarketingState = () => ({
  type: ActionTypes.ResolveDefaultMarketingState,
});
