import type { Client } from '@peloton/api';
import { pipeDataTwice } from '@peloton/api';
import { toStoreSlug } from '@peloton/stores';
import type { BundleType } from '../models';
import { toBundles } from './mappers';

const toPackagesUrl = (bundleType: BundleType, isRenamed?: boolean) => {
  if (isRenamed) {
    return `/ecomm/store/${toStoreSlug()}/packages/${bundleType}?is_renamed=true`;
  } else {
    return `/ecomm/store/${toStoreSlug()}/packages/${bundleType}`;
  }
};

export const fetchPackages = (api: Client, bundleType: BundleType, isRenamed?: boolean) =>
  api.get(toPackagesUrl(bundleType, isRenamed)).then(pipeDataTwice(toBundles));
