import { lensPath, map, pick, set, view } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeDataTwice } from '@peloton/api';
import { toStoreSlug } from '@peloton/stores';
import { ProductLine } from '@ecomm/shop/models';
import type { WarrantyProductLine } from './models';

const toPathSlug = (p: WarrantyProductLine) => {
  switch (p) {
    case ProductLine.Bike:
      return 'bike';
    default:
      return p;
  }
};

const toWarrantyUrlForProductLine = (productLine: WarrantyProductLine) =>
  `/ecomm/store/${toStoreSlug()}/warranties/${toPathSlug(productLine)}`;

const warrantyMapper = (warranty: object) => {
  const apiPriceLens = lensPath(['price', 'minorValue']);
  const priceLens = lensPath(['priceInCents']);
  return set(
    priceLens,
    view(apiPriceLens, warranty),
    pick(['id', 'isExtended', 'slug', 'extendedMonths'], warranty),
  );
};

const toWarranties = map(warrantyMapper);

export const fetchWarrantiesForProductLine = (
  api: Client,
  productLine: WarrantyProductLine,
) => api.get(toWarrantyUrlForProductLine(productLine)).then(pipeDataTwice(toWarranties));
