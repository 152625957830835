import { black, brand, grey } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { Markdown } from '@peloton/copy';
import { Links } from '@peloton/links';
import * as PrivateLinks from '@peloton/links/wwwPrivate';
import * as PrivateTreadLinks from '@peloton/links/wwwPrivateTread';
import { ieHideScroll, media } from '@peloton/styles';
import { snowWhite } from '@ecomm/colors';
import type { Image } from '@ecomm/copy';
import {
  getLinkTargetPropsFromTargetBlank,
  useCtaWithUrlData,
  useKeyValueData,
} from '@ecomm/copy';
import { useIsToggleActive } from '@ecomm/feature-toggle';
import type { TrackingLinkProps } from '@ecomm/links';
import { TrackingLink } from '@ecomm/links';
import { Product, useProductStates } from '@ecomm/product-states';
import { usePrograms } from '@ecomm/programs';
import { hb6, hb8 } from '@ecomm/typography';
import ProductBellyBandCard from './ProductBellyBandCard';

type Props = { showTryBanner?: boolean };
type StyleProps = {
  cardCount: number;
  showTryBanner: boolean;
};

const CARD_LIST_SIDE_PADDING_DESKTOP = 40;
const CARD_LIST_SIDE_PADDING_MOBILE = 25;
const MAXIMUM_BELLY_BAND_WIDTH = 1050;

export const getCardIdsProductStates = (
  isProductAvailableForMarketingPages: (p: Product) => boolean,
  isProductAvailableForPurchaseOrSoldOut: (p: Product) => boolean,
  showApparel: boolean,
  isGuideAvailable: boolean,
): Image[] => {
  const cardIds: Image[] = [];

  const isTreadAvailable = isProductAvailableForMarketingPages(Product.Tread);
  const isTreadPlusAvailable = isProductAvailableForMarketingPages(Product.TreadPlus);
  const isBikePlusAvailable = isProductAvailableForMarketingPages(Product.BikePlus);
  const isBikeAvailable = isProductAvailableForMarketingPages(Product.Bike);
  const isAppAvailable = isProductAvailableForPurchaseOrSoldOut(Product.DigitalApp);

  const isAccessoriesAvailable =
    isProductAvailableForPurchaseOrSoldOut(Product.Bike) ||
    isProductAvailableForPurchaseOrSoldOut(Product.BikePlus) ||
    isProductAvailableForPurchaseOrSoldOut(Product.Tread) ||
    isProductAvailableForPurchaseOrSoldOut(Product.TreadPlus);

  if (isBikeAvailable && !isBikePlusAvailable) {
    cardIds.push('productBellyBandBike');
  } else if (isBikeAvailable || isBikePlusAvailable) {
    cardIds.push('productBellyBandBikes');
  }
  if (isTreadAvailable && isTreadPlusAvailable) {
    cardIds.push('productBellyBandTreads');
  } else if (isTreadAvailable || isTreadPlusAvailable) {
    cardIds.push('productBellyBandTread');
  }

  if (isGuideAvailable) {
    cardIds.push('productBellyBandGuide');
  }

  if (isAppAvailable) {
    cardIds.push('productBellyBandApp');
  }
  if (isAccessoriesAvailable) {
    cardIds.push('productBellyBandAccessories');
  }
  if (showApparel) {
    cardIds.push('productBellyBandApparel');
  }

  return cardIds;
};

const ProductBellyBand: React.FC<React.PropsWithChildren<Props>> = ({
  showTryBanner = false,
}) => {
  const {
    isProductAvailableForMarketingPages,
    isProductAvailableForPurchase,
  } = useProductStates();
  const isBikePlusActive = isProductAvailableForMarketingPages(Product.BikePlus);
  const isGuideAvailable = useIsToggleActive()('leadGenBetaPage');
  const { apparel: showApparel } = usePrograms();
  const ids = getCardIdsProductStates(
    isProductAvailableForMarketingPages,
    isProductAvailableForPurchase,
    showApparel,
    isGuideAvailable,
  );

  const cardCount = ids.length;
  const cardUrls = {
    productBellyBandBike: isBikePlusActive ? PrivateLinks.bikes : Links.bike,
    productBellyBandBikes: PrivateLinks.bikes,
    productBellyBandTread: Links.tread,
    productBellyBandTreads: PrivateTreadLinks.treads,
    productBellyBandApp: Links.app,
    productBellyBandAccessories: Links.shopAccessories,
    productBellyBandApparel: Links.boutique,
    productBellyBandGuide: Links.guideAnnounce,
  };

  const { key, targetBlank, text, url } = useCtaWithUrlData(
    'homepage.trySection.banner.cta',
  );
  const ariaLabel = useKeyValueData('pgHome.productBellyBand.label');

  const linkTargetProps = getLinkTargetPropsFromTargetBlank(targetBlank);

  return (
    <>
      <NavContainer aria-label={ariaLabel}>
        <ListContainer cardCount={cardCount} showTryBanner={showTryBanner}>
          {ids.map((id, index) => (
            <ProductBellyBandCard
              cardCount={cardCount}
              id={id}
              url={cardUrls[id]}
              key={id}
              testId={`BellyBandProduct${index + 1}`}
            />
          ))}
        </ListContainer>
      </NavContainer>

      {showTryBanner && (
        <TryBanner>
          <TryLink
            eventProps={{ unitName: key }}
            {...linkTargetProps}
            parent={key as string}
            to={url}
          >
            <Markdown content={text} />
          </TryLink>
        </TryBanner>
      )}
    </>
  );
};

export default ProductBellyBand;

const NavContainer = styled.nav`
  background-color: ${brand.light};
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    height: 0;
    width: 0;
    background: transparent;
  }

  ${ieHideScroll}
`;

const ListContainer = styled.ul<StyleProps>`
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: ${({ showTryBanner }) => (showTryBanner ? '30px' : '35px')};
  padding-left: ${CARD_LIST_SIDE_PADDING_MOBILE}px;
  padding-right: ${CARD_LIST_SIDE_PADDING_MOBILE}px;
  padding-top: 35px;
  width: ${({ cardCount }) => `${cardCount * 45}vw`};

  @media (min-width: 550px) {
    width: ${({ cardCount }) => `${cardCount * 30}vw`};
  }

  @media (min-width: 1024px) and (max-width: 1100px) {
    justify-content: ${({ cardCount }) => (cardCount >= 5 ? 'flex-start' : 'center')};
  }

  ${media.tabletXLarge`
    max-width: ${MAXIMUM_BELLY_BAND_WIDTH}px;
  `}

  ${media.desktop<StyleProps>`
    padding-bottom: ${({ showTryBanner }) => (showTryBanner ? '30px' : '60px')};
    padding-left: ${CARD_LIST_SIDE_PADDING_DESKTOP}px;
    padding-right:${CARD_LIST_SIDE_PADDING_DESKTOP}px;
    padding-top: 60px;
  `}
`;

const TryBanner = styled.div`
  ${hb8};
  align-items: center;
  background-color: ${brand.light};
  display: flex;
  flex-direction: column;
  padding: 0 25px 32px 25px;

  u {
    border-bottom: 1px solid ${grey[70]};
    display: inline-block;
    text-decoration: none;
  }

  ${media.tablet`
    ${hb6}
  `}

  ${media.desktopLarge`
    padding: 0 40px 48px 40px;
  `}
`;

const TryLink = (styled(TrackingLink)`
  background-color: ${snowWhite};
  border-radius: 4px;
  box-shadow: 5px 3px 15px 0 ${rgba(black, 0.03)};
  color: ${grey[70]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 8px 18px 8px;
  text-align: center;
  width: 100%;

  ${media.tabletXLarge`
    max-width: calc(${MAXIMUM_BELLY_BAND_WIDTH}px - ${CARD_LIST_SIDE_PADDING_MOBILE}px * 2);
  `}

  ${media.desktop`
    max-width: calc(${MAXIMUM_BELLY_BAND_WIDTH}px - ${CARD_LIST_SIDE_PADDING_DESKTOP}px * 2);
  `}
` as any) as React.ComponentType<React.PropsWithChildren<TrackingLinkProps>>;
