import { useMemo, useCallback } from 'react';
import toFormattedText from '@peloton/copy/toFormattedText';
import {
  localeToMembershipPriceForGuidePricing,
  useLocale,
} from '@peloton/internationalize';
import useDiscountsByBundleType from '@content/client/hooks/useDiscountsByBundleType';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import useFinancing from '@ecomm/financing/hooks/useFinancingNext';
import { toDollars } from '@ecomm/models/Money';
import { useRentalPrices } from '@ecomm/rentals/useRentalPrices';
import { BundleType } from '@ecomm/shop/models/BundleType';
import useDigitalPrice from '@ecomm/subscription-plans/useDigitalPrice';
import useTierMobilityPrices from '@ecomm/subscription-plans/useTierMobilityPrices';

export type Pricing = {
  bikeTotal: number;
  bikeTotalBeforeShipping: number;
  bikeShippingEstimate: number;
  bikeMonthlyPayment: number;
  bikeMaxTerm: number;
  bikePlusTotal: number;
  bikePlusMonthlyPayment: number;
  bikePlusMaxTerm: number;
  bikePlusTotalBeforeShipping: number;
  bikePlusShippingEstimate: number;
  treadTotal: number;
  treadTotalBeforeShipping: number;
  treadShippingEstimate: number;
  treadMonthlyPayment: number;
  treadMaxTerm: number;
  treadPlusTotal: number;
  treadPlusMonthlyPayment: number;
  treadPlusMaxTerm: number;
  digitalPrice: number;
  membershipPrice: number;
  guideTotal: number;
  guideTotalBeforeShipping: number;
  guideShippingEstimate: number;
  guideMonthlyPayment: number;
  guideMaxTerm: number;
  guideMembershipPrice: number;
  rowTotalBeforeShipping: number;
  guideStrikethrough: number;
  refurbishedBikeTotal: number;
  refurbishedBikeTotalBeforeShipping: number;
  refurbishedBikeShippingEstimate: number;
  refurbishedBikePlusTotal: number;
  refurbishedBikePlusTotalBeforeShipping: number;
  refurbishedBikePlusShippingEstimate: number;
  bikeRentalSetupPrice: number;
  bikeRentalMonthlyPrice: number;
  bikePlusRentalSetupPrice: number;
  bikePlusRentalMonthlyPrice: number;
};

const formatPrice = (priceCents: number) => {
  return toFormattedText('{price, number, currency}', {
    price: toDollars(priceCents),
  }) as string;
};

const formatPricePromoStrikethrough = (basePrice: number, discountCents: number) => {
  const isPriceDrop = discountCents < 0;
  const totalPriceWithDiscount = basePrice - discountCents;

  if (basePrice === totalPriceWithDiscount) {
    return formatPrice(basePrice);
  } else if (isPriceDrop) {
    /*
    When the discount is negative, the promo is a price drop and the basePrice is actually the new, lower price,
    and the discount can be used to calculate the original "base price"
    */
    return `_${formatPrice(basePrice)}_ ~${formatPrice(totalPriceWithDiscount)}~`;
  }

  return `_${formatPrice(totalPriceWithDiscount)}_ ~${formatPrice(basePrice)}~`;
};

const formatOptionalBasePriceStrikethrough = (basePrice: number) => {
  return ` ~${formatPrice(basePrice)}~`;
};

export const useFinancingValues = (): Pricing => {
  const locale = useLocale();
  const digitalPrice = useDigitalPrice();
  const isToggleActive = useIsToggleActive();
  const isIncreasedMembershipPriceEnabled = isToggleActive(
    'increasedAllAccessMembershipPrice',
  );
  const membershipPrice = localeToMembershipPriceForGuidePricing(
    locale,
    isIncreasedMembershipPriceEnabled,
  );

  const bike = useFinancing(BundleType.Bike);
  const bikePlus = useFinancing(BundleType.BikePlus);
  const tread = useFinancing(BundleType.Tread);
  const treadPlus = useFinancing(BundleType.TreadPlus);
  const guide = useFinancing(BundleType.RainforestCafe);
  const row = useFinancing(BundleType.Row);
  const refurbBike = useFinancing(BundleType.RefurbishedBike);
  const refurbBikePlus = useFinancing(BundleType.RefurbishedBikePlus);

  const BIKE_RENTAL_PRICE = useRentalPrices('BIKE', locale);
  const BIKE_PLUS_RENTAL_PRICE = useRentalPrices('BIKE_PLUS', locale);

  const digitalPriceList = useTierMobilityPrices();

  const discountsByBundleType = useDiscountsByBundleType();

  const toCfuValues = useCallback(
    (cfuPrices: ReturnType<typeof useFinancing>) => {
      const discount = discountsByBundleType[cfuPrices.bundleType];
      const hasDiscount = discount > 0;

      const total = cfuPrices.total;
      const discountTotal = hasDiscount ? cfuPrices.total - discount : cfuPrices.total;
      const totalBeforeShipping = cfuPrices.totalBeforeShipping;
      const shippingEstimate = cfuPrices.shippingEstimate;
      const monthly = cfuPrices.monthly;
      const term = cfuPrices.term;
      const totalWithStrikethrough = formatPricePromoStrikethrough(total, discount);
      const optionalStrikethrough = hasDiscount
        ? formatOptionalBasePriceStrikethrough(total)
        : '';

      return [
        toDollars(total),
        toDollars(discountTotal),
        toDollars(totalBeforeShipping),
        toDollars(shippingEstimate),
        toDollars(monthly),
        term,
        totalWithStrikethrough,
        optionalStrikethrough,
      ] as const;
    },
    [discountsByBundleType],
  );

  const values = useMemo(() => {
    const [
      bikeTotal,
      bikeDiscountTotal,
      bikeTotalBeforeShipping,
      bikeShippingEstimate,
      bikeMonthlyPayment,
      bikeMaxTerm,
      bikeTotalWithStrikethrough,
      bikeOptionalStrikethrough,
    ] = toCfuValues(bike);

    const [
      bikePlusTotal,
      bikePlusDiscountTotal,
      bikePlusTotalBeforeShipping,
      bikePlusShippingEstimate,
      bikePlusMonthlyPayment,
      bikePlusMaxTerm,
      bikePlusTotalWithStrikethrough,
      bikePlusOptionalStrikethrough,
    ] = toCfuValues(bikePlus);

    const [
      treadTotal,
      treadDiscountTotal,
      treadTotalBeforeShipping,
      treadShippingEstimate,
      treadMonthlyPayment,
      treadMaxTerm,
      treadTotalWithStrikethrough,
      treadOptionalStrikethrough,
    ] = toCfuValues(tread);

    const [
      treadPlusTotal,
      treadPlusDiscountTotal,
      treadPlusTotalBeforeShipping,
      treadPlusShippingEstimate,
      treadPlusMonthlyPayment,
      treadPlusMaxTerm,
      treadPlusTotalWithStrikethrough,
      treadPlusOptionalStrikethrough,
    ] = toCfuValues(treadPlus);

    const [
      guideTotal,
      guideDiscountTotal,
      guideTotalBeforeShipping,
      guideShippingEstimate,
      guideMonthlyPayment,
      guideMaxTerm,
      guideTotalWithStrikethrough,
      guideOptionalStrikethrough,
    ] = toCfuValues(guide);

    const { membershipPrice: guideMembershipPrice } = guide;

    const [
      rowTotal,
      rowDiscountTotal,
      rowTotalBeforeShipping,
      rowShippingEstimate,
      rowMonthlyPayment,
      rowMaxTerm,
      rowTotalWithStrikethrough,
      rowOptionalStrikethrough,
    ] = toCfuValues(row);

    const [
      refurbishedBikeTotal,
      refurbishedBikeDiscountTotal,
      refurbishedBikeTotalBeforeShipping,
      refurbishedBikeShippingEstimate,
      refurbBikeMonthlyPayment,
      refurbishedBikeMaxTerm,
      refurbishedBikeTotalWithStrikethrough,
      refurbishedBikeOptionalStrikethrough,
    ] = toCfuValues(refurbBike);

    const [
      refurbishedBikePlusTotal,
      refurbishedBikePlusDiscountTotal,
      refurbishedBikePlusTotalBeforeShipping,
      refurbishedBikePlusShippingEstimate,
      refurbBikePlusMonthlyPayment,
      refurbishedBikePlusMaxTerm,
      refurbishedBikePlusTotalWithStrikethrough,
      refurbishedBikePlusOptionalStrikethrough,
    ] = toCfuValues(refurbBikePlus);

    const {
      setup: bikeRentalSetupPrice,
      monthly: bikeRentalMonthlyPrice,
    } = BIKE_RENTAL_PRICE;

    const {
      setup: bikePlusRentalSetupPrice,
      monthly: bikePlusRentalMonthlyPrice,
    } = BIKE_PLUS_RENTAL_PRICE;

    return {
      ...digitalPriceList,

      // v1 Bike
      bikeTotal,
      bikeDiscountTotal,
      bikeTotalWithStrikethrough,
      bikeOptionalStrikethrough,
      bikeTotalBeforeShipping,
      bikeShippingEstimate,
      bikeMonthlyPayment,
      bikeMaxTerm,

      // Bike+
      bikePlusTotal,
      bikePlusDiscountTotal,
      bikePlusTotalWithStrikethrough,
      bikePlusOptionalStrikethrough,
      bikePlusTotalBeforeShipping,
      bikePlusShippingEstimate,
      bikePlusMonthlyPayment,
      bikePlusMaxTerm,

      // Tread
      treadTotal,
      treadDiscountTotal,
      treadTotalWithStrikethrough,
      treadOptionalStrikethrough,
      treadTotalBeforeShipping,
      treadShippingEstimate,
      treadMonthlyPayment,
      treadMaxTerm,

      // Tread+
      treadPlusTotal,
      treadPlusDiscountTotal,
      treadPlusTotalWithStrikethrough,
      treadPlusOptionalStrikethrough,
      treadPlusTotalBeforeShipping,
      treadPlusShippingEstimate,
      treadPlusMonthlyPayment,
      treadPlusMaxTerm,

      // Guide
      guideTotal,
      guideDiscountTotal,
      guideTotalWithStrikethrough,
      guideOptionalStrikethrough,
      guideTotalBeforeShipping,
      guideShippingEstimate,
      guideMonthlyPayment,
      guideMaxTerm,
      guideStrikethrough: guideTotal,

      // Row
      rowTotal,
      rowDiscountTotal,
      rowTotalWithStrikethrough,
      rowOptionalStrikethrough,
      rowTotalBeforeShipping,
      rowShippingEstimate,
      rowMonthlyPayment,
      rowMaxTerm,

      // v1 Bike Refurb
      refurbishedBikeTotal,
      refurbishedBikeDiscountTotal,
      refurbishedBikeTotalWithStrikethrough,
      refurbishedBikeOptionalStrikethrough,
      refurbBikeMonthlyPayment,
      refurbishedBikeTotalBeforeShipping,
      refurbishedBikeShippingEstimate,
      refurbishedBikeMaxTerm,

      // Bike+ Refurb
      refurbishedBikePlusTotal,
      refurbishedBikePlusDiscountTotal,
      refurbishedBikePlusTotalWithStrikethrough,
      refurbishedBikePlusOptionalStrikethrough,
      refurbBikePlusMonthlyPayment,
      refurbishedBikePlusTotalBeforeShipping,
      refurbishedBikePlusShippingEstimate,
      refurbishedBikePlusMaxTerm,

      // Memberships
      digitalPrice: toDollars(digitalPrice),
      membershipPrice: toDollars(membershipPrice),
      guideMembershipPrice: toDollars(guideMembershipPrice),

      // Rentals
      // Rental prices are stored in USD
      bikeRentalSetupPrice,
      bikeRentalMonthlyPrice,
      bikePlusRentalSetupPrice,
      bikePlusRentalMonthlyPrice,
    };
  }, [
    toCfuValues,
    bike,
    bikePlus,
    tread,
    treadPlus,
    guide,
    row,
    refurbBike,
    refurbBikePlus,
    BIKE_RENTAL_PRICE,
    BIKE_PLUS_RENTAL_PRICE,
    digitalPriceList,
    digitalPrice,
    membershipPrice,
  ]);

  return values;
};

export default useFinancingValues;
