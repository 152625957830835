import type { TypePromo } from '@pelotoncycle/page-builder';
import useSWR from 'swr';
import { useLocale } from '@peloton/internationalize';
import { createDateSlug } from '@content/client/promos';
import {
  PB_GROUPED_PROMOS_API_ENDPOINT,
  PB_GROUPED_PROMOS_PREVIEW_API_ENDPOINT,
} from '@content/client/utils/constants';
import { useDateTriggerContext } from '@ecomm/cms-promos/DateTriggerProvider';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';

const fetcher = (url: string) => fetch(url).then(r => r.json());

const usePromosGroupedByProduct = () => {
  const { overrideToday } = useDateTriggerContext();
  const formattedDate = createDateSlug(overrideToday);

  const preview = useIsToggleActive()('previewCopy');
  const locale = useLocale();
  const dateOverride = formattedDate && preview ? `?today=${formattedDate}` : '';

  return useSWR<Record<string, TypePromo>>(
    `${
      preview ? PB_GROUPED_PROMOS_PREVIEW_API_ENDPOINT : PB_GROUPED_PROMOS_API_ENDPOINT
    }/${locale}${dateOverride}`,
    fetcher,
  );
};

export default usePromosGroupedByProduct;
