import { combineReducers } from 'redux';
import type { ReducerState as BundleState } from './bundles';
import bundles from './bundles';
import type { ReducerState as PackageState } from './packages';
import packages from './packages';
import type { ReducerState as ProductState } from './products';
import products from './products';

export default combineReducers({
  bundles,
  products,
  packages,
});

type State = BundleState & ProductState & PackageState;

export type ReducerState = {
  configure: State;
};
