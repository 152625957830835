export enum BannerPlacement {
  app = 'app',
  appCheckout = 'appCheckout',
  checkout = 'checkout',
  digitalCheckout = 'digitalCheckout',
  general = 'general',
  bikes = 'bikes',
  bike = 'bike',
  bikePlus = 'bikePlus',
  tread = 'tread',
  shopBike = 'shopBike',
  shopBikePlus = 'shopBikePlus',
  shopTread = 'shopTread',
  shopTreadPlus = 'shopTreadPlus',
  treadPlus = 'treadPlus',
  treads = 'treads',
  globalFinancing = 'globalFinancing',
  checkoutBanner = 'checkoutBanner',
  chase = 'chase',
  guide = 'guide',
  shopGuide = 'shopGuide',
  row = 'row',
  shopRow = 'shopRow',
}
