import { push } from 'connected-react-router';
import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, takeLatest } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import { scheduleDelivery } from './api';
import type { RequestAction } from './redux';
import { ActionType, fail, success } from './redux';

export const scheduleDeliverySaga = function* (action: RequestAction): SagaIterator {
  const { deliveryDate, id } = action.payload;

  const client = yield getContext(CLIENT_CONTEXT);

  try {
    yield call(scheduleDelivery, client, id, deliveryDate);
    yield put(success(id));
    yield put(push(`/checkout/success?order_id=${id}`));
  } catch (err) {
    const error = new DomainError('Error scheduling delivery', err);
    yield all([put(reportError({ error })), put(fail(id, error))]);
  }
};

const sagas = function* () {
  yield takeLatest(ActionType.Request, scheduleDeliverySaga);
};

export default sagas;
