import type { RefObject } from 'react';
import type { ProductNavData } from '@ecomm/copy/helpers/types';
import type { ProductNav } from '@ecomm/copy/models.generated';

export type ProductsMenuItemRefs = Record<
  Exclude<Menu, Menu.None | Menu.Account | Menu.GeoPicker>,
  RefObject<HTMLButtonElement>
>;

export type UtilitiesMenuRefs = Record<
  Extract<Menu, Menu.Account | Menu.GeoPicker>,
  RefObject<HTMLButtonElement>
>;

export enum Menu {
  Classes = 'classes',
  Bikes = 'bikes',
  Bike = 'bike',
  Treads = 'treads',
  Tread = 'tread',
  App = 'app',
  App_variated = 'app',
  Accessories = 'accessories',
  Apparel = 'apparel',
  ApparelNew = 'apparel',
  GeoPicker = 'geo',
  Account = 'account',
  Strength = 'strength',
  RainforestCafe = 'RainforestCafe',
  Row = 'row',
  Deals = 'deals',
  None = 'none',
  /** @todo temporary for .at launch */
  About = 'about',
}

export type ProductNavMeta = {
  name: Menu;
  cmsId: ProductNav;
  rootUrls: RegExp[];
  subnavName?: string;
};

export type ProductToggles = {
  isBikePlusAvailable: boolean;
  isTreadAvailable: boolean;
  isTreadPlusAvailable: boolean;
  isAppAvailable: boolean;
};

export type AlternateKeys = {
  navAppKey?: ProductNav;
};

export type SecondaryLinksData = Pick<ProductNavData, 'secondaryLinks'> & {
  categoryId: string;
};

export type OnClickProps = {
  currentMenu: Menu;
  menuItem: Menu;
  setCurrentMenu: React.Dispatch<React.SetStateAction<Menu>>;
  setFocusedMenu: React.Dispatch<React.SetStateAction<Menu>>;
};
