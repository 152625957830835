import rawATFlag from './at.png';
import rawAUFlag from './au.png';
import rawCAFlag from './ca.png';
import rawDEFlag from './de.png';
import rawUKFlag from './uk.png';
import rawUSFlag from './us.png';

/**
 * Next 11 loads images as StaticImageData
 * These reassignments keep the exports consistent between apps using NextJS and those that aren't
 *
 * ALSO: We have to copy/paste this interface because if we "/// <reference />" the types directly here,
 * apps start getting conflicting type information about images 🤦‍♂️
 */

interface NextStaticImageData {
  src: string;
}

const atFlag =
  typeof rawATFlag === 'string'
    ? rawATFlag
    : String((rawATFlag as NextStaticImageData | undefined)?.src);

const auFlag =
  typeof rawAUFlag === 'string'
    ? rawAUFlag
    : String((rawAUFlag as NextStaticImageData | undefined)?.src);

const caFlag =
  typeof rawCAFlag === 'string'
    ? rawCAFlag
    : String((rawCAFlag as NextStaticImageData | undefined)?.src);

const deFlag =
  typeof rawDEFlag === 'string'
    ? rawDEFlag
    : String((rawDEFlag as NextStaticImageData | undefined)?.src);

const ukFlag =
  typeof rawUKFlag === 'string'
    ? rawUKFlag
    : String((rawUKFlag as NextStaticImageData | undefined)?.src);

const usFlag =
  typeof rawUSFlag === 'string'
    ? rawUSFlag
    : String((rawUSFlag as NextStaticImageData | undefined)?.src);

export { atFlag, auFlag, caFlag, deFlag, ukFlag, usFlag };
