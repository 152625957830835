import { openChat } from './openChat';

declare global {
  interface Window {
    __pelotonOpenSalesChat?: () => void;
  }
}

export const addOpenChatCallbackToWindow = () => {
  window.__pelotonOpenSalesChat = openChat;
};
