import type { GiftCardPayment } from '../models/Cart';
import { toCart as legacyToCart } from './mapper';
import type { ApiShippingInfo, ApiSummary } from './types';

type BundleOptionSet = {
  bundle: {
    displayName: string;
  };
};
type Coupon = {
  amount: number;
  code: string;
  description: string;
};

export type LightCartAPIResponse = {
  bundleOptionSet: Array<BundleOptionSet>;
  couponSavings: Array<Coupon>;
  shipping: ApiShippingInfo;
  total: {
    grossSubtotalInCents: number;
    totalInCents: number;
    taxInCents: number;
  };
  payments: Array<GiftCardPayment>;
};

const liftName = (bundle: BundleOptionSet) => ({
  displayName: bundle.bundle.displayName,
  ...bundle,
});

const renameCoupon = ({ amount, code, description }: Coupon) => ({
  totalSavingsInCents: amount,
  couponCode: code,
  description,
});

export const toCart = ({
  total,
  bundleOptionSet,
  couponSavings,
  shipping,
  payments = [],
  ...rest
}: LightCartAPIResponse) => {
  const { grossSubtotalInCents, totalInCents, taxInCents } = total;
  const fixedBundles = bundleOptionSet.map(liftName);
  const fixedCoupons = couponSavings.map(renameCoupon);

  return legacyToCart(({
    bundleOptionSet: fixedBundles,
    couponSavings: fixedCoupons,
    grossSubtotalInCents,
    totalInCents,
    taxInCents,
    payments,
    ...shipping,
    ...rest,
  } as unknown) as ApiSummary);
};
