import { brand } from '@pelotoncycle/design-system';
import styled from 'styled-components';
import { b6 } from '@ecomm/typography';

export type PrivacyProps = {
  privacyPolicyLink: string;
};

export const PrivacyNotice = styled.span`
  display: block;
  ${b6}
  color: ${brand.darkest};
`;
