import { path, pathEq, pathOr, prop } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { call, select, takeLatest } from 'redux-saga/effects';
import { trackWithEloqua } from '@ecomm/analytics';
import { email as emailSchema } from '@ecomm/form-validation';
import type { Email } from '@ecomm/models';
import type { BlurAction } from '@ecomm/registration/redux';
import { FormTypes } from '@ecomm/registration/redux';
import { toCheckRouteSaga } from '@ecomm/saga-utils';
import { toTrackingData } from '../../models';
import { matchRegisterFreeTrial as matcher } from '../../route';

export const trackCapturedEmail = function* (action: Action): SagaIterator {
  const isEmailAction = toIsEmailAction(action);

  if (!isEmailAction) {
    return;
  }

  const isValid = yield call(validate, action);

  if (!isValid) {
    return;
  }

  const properties = yield call(trackingData, action);
  yield call(trackWithEloqua, { event: properties.eventName, properties });
};

export const requiredEmail = emailSchema.required();

export const validate = function* (action: Action): SagaIterator {
  const email = toEmail(action);
  const isValid = yield call([requiredEmail, 'isValid'], email);
  return isValid;
};

export const trackingData = function* (action: Action): SagaIterator {
  const match = yield select(matcher);

  const productInterest = pathOr('digital', ['params', 'productInterest'], match);
  const pageName = prop('url', match);
  const email = toEmail(action) as Email;

  return toTrackingData({
    email,
    event: 'capture',
    pageName,
    productInterest,
  });
};

export const checkRouteAndTrack = toCheckRouteSaga({
  matcher,
  onMatch: trackCapturedEmail,
});

const watcher = function* () {
  yield takeLatest(FormTypes.FieldBlurred, checkRouteAndTrack);
};

export default watcher;

type Action = BlurAction;

const toEmail = path<Email>(['payload', 'value']);
const toIsEmailAction = pathEq(['payload', 'name'], 'email');
