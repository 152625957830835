import { hasVariation } from '@peloton/split-testing';

const BLACK_FRIDAY_LEAD_CAPTURE_EXPERIMENT = 'BF Lead Capture Modals';
const ORIGINAL = 'Original';
const VARIATION_1 = 'Variation #1';

export const showBlackFridayLeadCaptureOriginal = hasVariation(
  BLACK_FRIDAY_LEAD_CAPTURE_EXPERIMENT,
  ORIGINAL,
);

export const showBlackFridayLeadCaptureVariation1 = hasVariation(
  BLACK_FRIDAY_LEAD_CAPTURE_EXPERIMENT,
  VARIATION_1,
);
