export type Money = number;

export const toDollars = (amount: Money): number => amount / 100; // TODO: Rename to make less USD-centric

export const toDollarsCeil = (amount: Money): Money => Math.ceil(amount / 100);

export const toDisplayCost = (price: number): string => {
  const dollarAmount = toDollars(price);
  return dollarAmount % 1 === 0 ? dollarAmount.toString() : dollarAmount.toFixed(2);
};
