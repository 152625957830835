import React from 'react';
import type { Locale } from '@peloton/internationalize';
import toContentfulClient from '@ecomm/copy/client';
import type { PublishedCopyBlob } from '@ecomm/copy/helpers';

type Props = {
  preview: boolean;
  locale: Locale;
  publishedLocaleCopy: PublishedCopyBlob;
  tag: string;
};

const useContentfulClient = ({ preview, locale, publishedLocaleCopy, tag }: Props) => {
  const [previewCopyLoaded, setPreviewCopyLoaded] = React.useState(false);
  const [loadingFinished, setLoadingFinished] = React.useState(false);
  const client = React.useMemo(
    () =>
      toContentfulClient({
        locale,
        preview,
        publishedLocaleCopy,
        tag,
      }),
    [locale, preview, publishedLocaleCopy, tag],
  );

  React.useEffect(() => {
    const handler = async () => {
      if (preview) {
        try {
          const fetchCopy = await import(
            './fetchCopy' /* webpackChunkName: "FetchPreviewCopy" */
          );
          // TS error to be fixed during Apollo 3 migration
          // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
          // @ts-ignore
          await fetchCopy.default(client, locale, preview, tag);
          setPreviewCopyLoaded(true);
          setLoadingFinished(true);
        } catch (e) {
          console.error(
            'Error fetching copy from contentful. For more help troubleshooting, see https://pelotoncycle.atlassian.net/wiki/spaces/EO/pages/1435140131/Contentful+overview#Contentful-caveats:',
            e,
          );
        }
      } else {
        setLoadingFinished(true);
      }
    };
    handler();
  }, [client, locale, preview, tag, publishedLocaleCopy]);

  // previewCopyLoaded is included in this value so that all copy hooks rerender once
  // preview copy loads by returning a new value that is set in the context
  return React.useMemo(() => {
    return { client, previewCopyLoaded, loadingFinished };
  }, [client, previewCopyLoaded, loadingFinished]);
};

export default useContentfulClient;
