import React from 'react';
import ProgramContext from './Context';
import type { Programs } from './models';
import { programArray } from './models';
import { useContentfulProgramsByLocale } from './utils';

export const ProgramProvider: React.FC<React.PropsWithChildren<Partial<Programs>>> = ({
  children,
  ...programOverrides
}) => {
  const contentfulPrograms = useContentfulProgramsByLocale();

  const initialPrograms: Programs = programArray.reduce(
    (acc, program) => ({
      ...acc,
      [program]: programOverrides?.[program] ?? contentfulPrograms[program],
    }),
    {} as Programs,
  );

  const [programs, setProgramsFull] = React.useState<Programs>(initialPrograms);

  const setPrograms = (partialPrograms: Partial<Programs>) => {
    setProgramsFull(p => ({ ...p, ...partialPrograms }));
  };
  return (
    <ProgramContext.Provider
      value={{
        ...programs,
        setPrograms,
        initialPrograms,
      }}
    >
      {children}
    </ProgramContext.Provider>
  );
};
