import { BundleType } from '@ecomm/shop/models/BundleType';

// NOTE: This is all configured on the API side now, in
//   ecomm/app/partners/klarna/data/create_session_request.py
// This is dead code that should be removed or refactored.

export enum Term {
  Twelve = 12,
  TwentyFour = 24,
  ThirtySix = 36,
  ThirtyNine = 39,
  FortyThree = 43,
}

export const maxTerm = (bundleType?: BundleType) => {
  if (bundleType && [BundleType.BikePlus, BundleType.Tread].indexOf(bundleType) >= 0) {
    return Term.FortyThree;
  }
  return Term.ThirtyNine;
};

export const TERMS: Record<string, Term> = {
  '12mo-financing': Term.Twelve,
  '24mo-financing': Term.TwentyFour,
  '36mo-financing': Term.ThirtySix,
  '39mo-financing': Term.ThirtyNine,
  '43mo-financing': Term.FortyThree,
};

export const getTerm = (slug: string) => TERMS[slug];
