import { pathOr } from 'ramda';
import type { Entities, Trait } from '../models';

export enum ActionType {
  Add = 'ecomm/shop/ADD_TRAITS',
}

export type State = Entities<Trait>;

export const defaultState = {};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.Add: {
      const traits = pathOr({}, ['payload', 'traits'], action);
      return {
        ...state,
        ...traits,
      };
    }

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  traits: State;
};

export const addTraits = (traits: Entities<Trait>): AddTraitsAction => ({
  type: ActionType.Add,
  payload: { traits },
});

type AddTraitsAction = {
  type: ActionType.Add;
  payload: { traits: Entities<Trait> };
};

type Action = AddTraitsAction;
