import { pathEq, propEq } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { grantFreeTrialSaga } from '@ecomm/free-trial';
import { LOGIN_FAILED } from '@ecomm/registration/models';
import type { FailAction, RequestAction, SuccessAction } from '@ecomm/registration/redux';
import { RegisterTypes, hasRegistrationErrors } from '@ecomm/registration/redux';
import { toCheckRouteSaga } from '@ecomm/saga-utils';
import {
  ActionTypes,
  failFreeTrial,
  requestRegisterWithFreeTrial,
  succeedFreeTrial,
} from '../redux';
import { matchRegisterFreeTrial as matcher } from '../route';
import redirectOnSuccess from './redirectOnSuccess';

export const freeTrial = function* () {
  yield call(grantFreeTrialSaga, {
    hooks: {
      onError: failFreeTrial,
      onSuccess: succeedFreeTrial,
    },
  });
};

export const validate = function* (): SagaIterator {
  const hasErrors = yield select(hasRegistrationErrors);

  if (hasErrors) {
    yield put(failFreeTrial('formInvalid'));
  } else {
    yield call(freeTrial);
  }
};

export const monitorRegistration = function* (action: Action): SagaIterator {
  if (isFailure(action) && !isLoginFailure(action)) {
    return;
  } else if (isFailure(action) && isLoginFailure(action)) {
    return yield put(failFreeTrial('userShouldLogin'));
  }

  yield call(freeTrial);
};

export const forkAction = function* (action: Action) {
  if (isRegisterRequest(action)) {
    yield put(requestRegisterWithFreeTrial());
  } else {
    yield call(monitorRegistration, action);
  }
};

export const checkRouteAndFork = toCheckRouteSaga({ matcher, onMatch: forkAction });

const watcherSaga = function* () {
  yield takeEvery(
    [
      RegisterTypes.RegistrationFailed,
      RegisterTypes.RegistrationRequested,
      RegisterTypes.RegistrationSucceeded,
    ],
    checkRouteAndFork,
  );

  yield takeEvery(ActionTypes.FreeTrialRequested, validate);

  yield call(redirectOnSuccess);
};

export default watcherSaga;

type Action = FailAction | RequestAction | SuccessAction;

const isLoginFailure = pathEq(
  ['payload', 'exception', 'id'],
  `registration.errors.${LOGIN_FAILED}`,
);

const isFailure = propEq('type', RegisterTypes.RegistrationFailed);

const isRegisterRequest = propEq('type', RegisterTypes.RegistrationRequested);
