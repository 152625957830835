export const parseJwt = (token: string) => {
  const base64Payload = token.split('.')[1];

  const payload = decodeURIComponent(
    atob(base64Payload)
      .split('')
      .map(char => '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );

  return JSON.parse(payload);
};
