import toAbsoluteUrl, { isDataUri, isRelativePath, isAbsoluteUrl } from './toAbsoluteUrl';
import toRedirectParams from './toRedirectParams';
import toRedirectUrl from './toRedirectUrl';

export * from './withExtHrefs';
export * from './withExtLinkEnv';
export * from './redux';
export * from './models';
export * from './urls';
export * from './toHrefWithRedirect';

export const EXT_LINK_ENV_CONTEXT = 'extLinkEnv';
export const EXT_LINK_NAMESPACE = 'extLinkEnv';

export {
  isDataUri,
  isRelativePath,
  toAbsoluteUrl,
  toRedirectParams,
  isAbsoluteUrl,
  toRedirectUrl,
};
