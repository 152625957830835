import { call, takeEvery } from 'redux-saga/effects';
import { trackCheckoutStepCompletedSaga } from '@ecomm/pg-checkout/analytics/trackCheckoutStepCompleted';
import { trackCheckoutStepViewedSaga } from '@ecomm/pg-checkout/analytics/trackCheckoutStepViewed';
import { takeFirst } from '@ecomm/saga-utils';
import { DigitalActionTypes, MultiStepActionTypes } from '../redux';
import type { NextFromEmailCaptureAllowedAction } from '../redux/multiStep';

export const trackSecondStepViewed = function* (
  action: NextFromEmailCaptureAllowedAction,
) {
  const additionalPayload = {
    skus: action.payload.skus,
  };
  yield call(trackCheckoutStepViewedSaga, 5, additionalPayload);
};

export const trackEnteredPaymentInfo = function* () {
  yield call(trackCheckoutStepCompletedSaga, 3);
};

const watcherSaga = function* () {
  // For some reason, the `call(takeFirst, ...)` must come *after* the `takeEvery()`,
  // otherwise the trackSecondStepViewed won't ever fire.
  yield takeEvery(
    MultiStepActionTypes.NextFromEmailCaptureAllowed,
    trackSecondStepViewed,
  );
  yield call(takeFirst, trackEnteredPaymentInfo, DigitalActionTypes.PaymentInfoEntered);
};

export default watcherSaga;
