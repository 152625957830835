import React from 'react';
import type { EmailTrackingProps } from '@ecomm/analytics';
import { useCtaWithUrlData } from '@ecomm/copy';
import { EmailCaptureBannerCentered } from '@ecomm/themed-modules';

const emailProps: EmailTrackingProps = {
  eventName: 'Submitted Email for Marketing',
  eventProps: {
    campaignId: '7011C000001QeUUQA0',
    campaignName: 'Guide Welcome',
    marketingConsent: 'explicit',
    propertyType: 'Web',
    unitName: 'Guide Launch Sign Up',
    unitPlacement: 'in-line',
    productInterest: 'Guide',
    productItemInterest: 'Guide',
  },
  testingInfo: {
    page: 'guide',
    section: 'leadJen',
  },
};

const LeadGenBlock: React.FC<React.PropsWithChildren<unknown>> = () => {
  const cmsSectionId = 'rainforestCafe.homePageLeadCapture';
  const buttonText = useCtaWithUrlData('rainforestCafe.homePageLeadCaptureCTAWithUrl');

  return (
    <>
      <EmailCaptureBannerCentered
        theme="offWhite"
        buttonText={buttonText.text}
        cmsSectionId={cmsSectionId}
        {...emailProps}
      />
    </>
  );
};

export default LeadGenBlock;
