import { all, call, take } from 'redux-saga/effects';
import { ActionTypes } from '../redux';
import redirectToProfileSaga from './redirectToProfileSaga';

const redirectOnSuccess = function* () {
  yield all([
    take(ActionTypes.FreeTrialSucceeded),
    take(ActionTypes.EmailSubmittedSuccess),
  ]);

  yield call(redirectToProfileSaga);
};

export default redirectOnSuccess;
