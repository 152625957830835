import type { LineItemDataFragment } from '@ecomm/shop-cart/graphql/fragments.generated';
import { ShopProductVariantType } from '../../graphql/types.generated';
import type { CartItemNextType } from '../cart-summary/cart-items/models';
import { isBundleItem } from '../cart-summary/cart-items/models';
import type { CartQuery } from '../graphql/queries/Cart.generated';

const toSingleItems = (items: Partial<CartItemNextType>[]): CartItemNextType[] =>
  items.flatMap(item =>
    isBundleItem(item)
      ? item.products?.map(i => ({ ...i, bundleSlug: item.slug }))
      : [item],
  );

type ShopLineItemWithBundleSlug = LineItemDataFragment & {
  bundleSlug?: string;
};

const toCTSingleItems = (
  items: LineItemDataFragment[],
): Partial<ShopLineItemWithBundleSlug | undefined>[] =>
  items.flatMap(item =>
    Boolean(item?.productVariant?.type === ShopProductVariantType.Bundle)
      ? item?.bundleItemLineItems?.map(i => ({
          ...i,
          bundleSlug: item?.productVariant?.key,
        }))
      : [item],
  );

export const toSegmentProducts = (items: CartItemNextType[]) =>
  toSingleItems(items).map(item => ({
    brand: 'Peloton',
    name: item.name,
    category: item.productLine,
    price: item.priceInCents.amount,
    quantity: item.quantity,
    sku: item.sku,
    product_id: item.productId,
    package_name: item.bundleSlug,
  }));

export const toCTSegmentProducts = (items: LineItemDataFragment[]) =>
  toCTSingleItems(items).map(item => ({
    brand: 'Peloton',
    name: item?.productVariant?.name ?? '',
    price: item?.priceSummary?.subtotal?.centAmount,
    quantity: item?.quantity ?? 0,
    sku: item?.productVariant?.sku,
    product_id: item?.productId,
    package_name: item?.bundleSlug,
  }));

export const toCategory = (cart: CartQuery['cart']) =>
  [
    cart?.hasBike && 'bike',
    cart?.hasBikePlus && 'bike-plus',
    cart?.hasGuide && 'guide',
    cart?.hasTread && 'tread',
    cart?.hasTreadPlus && 'tread-plus',
    cart?.hasRow && 'row',
    cart?.hasAccessories && 'accessory',
  ]
    .filter(Boolean)
    .join(',');

export const legacyEquipmentTypeToCTCategory = {
  bike: 'bike',
  bikeplus: 'bike-plus',
  guide: 'guide',
  row: 'row',
  tread: 'tread',
  treadplus: 'tread-plus',
  refurbbike: 'refurb-bike',
  refurbbikeplus: 'refurb-bike-plus',
};

export const toCTCategory = (items: LineItemDataFragment[]) => {
  const ACCESSORY = 'accessory';
  const categories: string[] = [];
  items.forEach(item => {
    const legacyEquipmentType = item?.productVariant?.legacyEquipmentType;
    const mappedCategory = legacyEquipmentTypeToCTCategory[legacyEquipmentType ?? ''];
    const hasBundleItems = Boolean(item?.bundleItemLineItems?.length);
    const hasAccessory =
      Boolean(
        hasBundleItems &&
          item?.bundleItemLineItems?.some(
            subItem => subItem?.productVariant?.type.toLowerCase() === ACCESSORY,
          ),
      ) || item?.productVariant?.type?.toLowerCase() === ACCESSORY;

    if (legacyEquipmentType && !categories.includes(mappedCategory)) {
      categories.push(mappedCategory);
    }

    if (hasAccessory && !categories.includes(ACCESSORY)) {
      categories.push(ACCESSORY);
    }
  });

  return categories.filter(Boolean).join(',');
};

export const toName = (items: CartItemNextType[]) =>
  items.map(({ name }) => name).join(',');

export const toCTName = (items: LineItemDataFragment[]) =>
  items.map(({ productVariant }) => productVariant.name).join(',');

export const toSkus = (cart: CartQuery['cart']) =>
  toSingleItems(cart?.items ?? [])
    .map(item => item.sku)
    .filter(Boolean)
    .join(',');

export const toCTSkus = (items: LineItemDataFragment[]) =>
  toCTSingleItems(items)
    .map(item => item?.productVariant?.sku)
    .filter(Boolean)
    .join(',');

export const toSlugs = (cart: CartQuery['cart']) =>
  toSingleItems(cart?.items ?? [])
    .map(item => item.slug)
    .filter(Boolean)
    .join(',');

export const toCTSlugs = (items: LineItemDataFragment[]) =>
  toCTSingleItems(items)
    .map(item => item?.productKey)
    .filter(Boolean)
    .join(',');
