import { useContext } from 'react';
import { ProductStatesContext } from '@ecomm/product-states/Context';
import { Product } from '@ecomm/product-states/models/product';

const useProductStateFooterSelector = () => {
  const {
    isProductAvailableForPurchase,
    isProductAvailableForPurchaseOrSoldOut,
    isProductAvailableForMarketingPages,
  } = useContext(ProductStatesContext);

  const atLeastOneCFUPurchasable =
    isProductAvailableForPurchaseOrSoldOut(Product.Tread) ||
    isProductAvailableForPurchaseOrSoldOut(Product.TreadPlus) ||
    isProductAvailableForPurchaseOrSoldOut(Product.Bike) ||
    isProductAvailableForPurchaseOrSoldOut(Product.BikePlus) ||
    isProductAvailableForPurchaseOrSoldOut(Product.Row) ||
    isProductAvailableForPurchaseOrSoldOut(Product.RainforestCafe);

  const atLeastOneProductAvailable =
    atLeastOneCFUPurchasable || isProductAvailableForPurchase(Product.DigitalApp);

  return {
    appEnabled: isProductAvailableForMarketingPages(Product.DigitalApp),
    showMembershipPage: atLeastOneProductAvailable,
    bikeReviews:
      isProductAvailableForPurchase(Product.Bike) ||
      isProductAvailableForPurchase(Product.BikePlus),
    treadReviewsEnabled: isProductAvailableForPurchase(Product.TreadPlus),
    guideReviewsEnabled: isProductAvailableForPurchase(Product.RainforestCafe),
    instructors: isProductAvailableForPurchase(Product.DigitalApp),
  };
};

export default useProductStateFooterSelector;
