import { brand } from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { useErrorReporter } from '@peloton/error-reporting';
import useCartViewedAnalytics from '@ecomm/cart-next/analytics/useCartViewedAnalytics';
import useCTCartViewedAnalytics from '@ecomm/cart-next/analytics/useCTCartViewedAnalytics';
import { useOpenCartPanel } from '@ecomm/cart-next/context/CartContext';
import useCartWithVariables from '@ecomm/cart-next/hooks/useCartWithVariables';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import { Provider as GeoLocationProvider } from '@ecomm/geolocation/Provider';
import HeaderNavProvider from '@ecomm/header/HeaderNavProvider';
import { useMigrationStatus } from '@ecomm/pg-checkout-commercetools/utils/useMigrationStatus';

import type { Props } from './HeaderNavView';
import HeaderNavView from './HeaderNavView';

const HeaderNavViewContainer = (
  props: Pick<Props, 'transparent' | 'style' | 'sticky'>,
) => {
  const [isPanelNavOpen, setIsPanelNavOpen] = useState(false);
  const openCartPanel = useOpenCartPanel();
  const { errorReporter } = useErrorReporter();
  const cartResponse = useCartWithVariables({
    throwError: false,
    reportSwallowedError: errorReporter.reportError,
  });
  const { trackCartViewed } = useCartViewedAnalytics();
  const { trackCTCartViewed } = useCTCartViewedAnalytics();
  const { data: shopCartData } = useGetCartItems({ fetchPolicy: 'cache-first' });
  const { buyFlowATCEnabled } = useMigrationStatus();

  const { Track } = useTracking({ event: 'Clicked Navigation' });

  return (
    <GeoLocationProvider>
      <HeaderNavProvider>
        <Track>
          <HeaderNavView
            {...props}
            isPanelNavOpen={isPanelNavOpen}
            onHamburgerButtonClick={() => setIsPanelNavOpen(!isPanelNavOpen)}
            onPanelNavCloseRequest={() => setIsPanelNavOpen(false)}
            onCartIconClick={() => {
              openCartPanel();
              if (buyFlowATCEnabled && !!shopCartData) {
                trackCTCartViewed(shopCartData);
              } else {
                trackCartViewed(cartResponse.data);
              }
            }}
          />
        </Track>
      </HeaderNavProvider>
    </GeoLocationProvider>
  );
};

const MainHeaderNav = styled(HeaderNavViewContainer)`
  background-color: ${brand.darkest};
`;

export { MainHeaderNav };
