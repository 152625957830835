import { InMemoryCache, ApolloClient } from '@apollo/client';
import { commercetoolsHttpLink } from './httpLink';

export const toCommercetoolsClient = (name?: string) =>
  new ApolloClient({
    name,
    link: commercetoolsHttpLink,
    cache: new InMemoryCache({
      addTypename: true,
    }),
    ssrMode: typeof window === 'undefined',
  });
