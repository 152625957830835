import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

export const path = '/showrooms';

const routes = [
  <Route
    key="showrooms-page"
    path={path}
    page
    component={asyncComponent(
      () => import('./Handler' /* webpackChunkName: "Showrooms" */),
    )}
  />,
];

export default routes;
