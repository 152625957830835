import { pipe } from 'ramda';
import React from 'react';
import { toHref, toExtLinkEnv } from '@peloton/external-links';
import { Links } from '@peloton/links';
import type { ValuePropData } from '@ecomm/copy';
import { useValuePropData } from '@ecomm/copy';
import { ErrorDisplay, ErrorDisplayButton } from '../ui/ErrorParts';

export enum ConnectionAPIError {
  PartnerNotFound = 'partner_not_found',
  SSONotEnabled = 'sso_not_enabled',
}

export enum ErrorStates {
  AlreadyClaimed = 'already_claimed',
  CertificateError = 'certificate_error',
  InvalidConnection = 'invalid_connection',
  InvalidJWT = 'invalid_jwt',
  VerificationFailed = 'verification_failed',
}
interface ErrorMessage {
  message: ErrorStates;
}

export const pullErrorMessage = (exception: any) => {
  if (exception) {
    const [{ message }]: ErrorMessage[] = exception.id.graphQLErrors;
    return message;
  } else {
    return undefined;
  }
};

const claimedError = (error: ErrorStates) => {
  return error && error === ErrorStates.AlreadyClaimed;
};

export const isClaimedError = pipe(pullErrorMessage, claimedError);

export const BenefitClaimedError = () => {
  const { title, body }: ValuePropData = useValuePropData(
    'onewellness.pgSsoEnrollment.errors.benefitClaimed',
  );

  return (
    <ErrorDisplay headline={title} body={body}>
      <ErrorDisplayButton
        ctaId="onewellness.pgSsoEnrollment.errors.benefitClaimed.button"
        href={toHref(Links.myMembership, toExtLinkEnv())}
      />
    </ErrorDisplay>
  );
};

export const ConnectionError = () => {
  const { title, body } = useValuePropData(
    'onewellness.pgSsoEnrollment.errors.connection',
  );
  return <ErrorDisplay headline={title} body={body} />;
};

export const NotConfirmedError = () => {
  const { title, body }: ValuePropData = useValuePropData(
    'onewellness.pgSsoEnrollment.errors.notConfirmed',
  );

  return <ErrorDisplay headline={title} body={body} />;
};
