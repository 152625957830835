import LoginModal from './LoginModal';
import loginModalReducer, {
  closeLoginModal,
  openLoginModal,
  selectIsOpen,
  selectPrefilledEmail,
} from './redux';

export default LoginModal;
export {
  closeLoginModal,
  loginModalReducer,
  openLoginModal,
  selectIsOpen,
  selectPrefilledEmail,
};
