import { map } from 'ramda';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import type { ReactMarkdownProps } from 'react-markdown';
import type { FormattedMessageProps } from '@ecomm/internationalize';
import { FormattedMessage } from '@ecomm/internationalize';

declare global {
  interface Window {
    Cypress: any;
  }
}

type FormattedMarkdownProps = Pick<
  ReactMarkdownProps,
  Exclude<keyof ReactMarkdownProps, 'source' | 'renderers'>
> & {
  renderers?: Record<string, React.ElementType>;
};

export type MarkdownProps = {
  markdown?: FormattedMarkdownProps;
};

export type Props = FormattedMessageProps & MarkdownProps;

const toReactMarkdownRenderer = (Renderer: React.ElementType) => {
  const extraProps = {};
  if (typeof window !== 'undefined' && window.Cypress) {
    extraProps['data-md'] = true;
  }
  // eslint-disable-next-line react/display-name
  return ({ node, ...props }: any) => <Renderer {...props} {...extraProps} />;
};

const FormattedMarkdown: React.FC<React.PropsWithChildren<Props>> = ({
  markdown = {},
  ...props
}) => {
  const { renderers, ...markdownProps } = markdown;
  return (
    <FormattedMessage {...props}>
      {(message: string) => (
        <ReactMarkdown
          {...({
            ...markdownProps,
            escapeHtml: false,
            renderers: {
              // eslint-disable-next-line @typescript-eslint/no-shadow
              root: ({ node, ...props }) => <React.Fragment {...props}></React.Fragment>,
              ...map(toReactMarkdownRenderer, renderers || {}),
            },
            source: message,
          } as ReactMarkdownProps)}
        />
      )}
    </FormattedMessage>
  );
};

export default FormattedMarkdown;
