import { useCallback, useEffect, useReducer } from 'react';
import type { PromoHero } from '@ecomm/copy';

type SequenceMap =
  | {
      [k in PromoHero]: HTMLVideoElement;
    }
  | {};

type State = {
  sequenceMap: SequenceMap;
  isReady: boolean;
};

type AddSequenceElement = {
  type: 'ADD_SEQUENCE_ELEMENT';
  payload: {
    id: PromoHero;
    element: HTMLVideoElement;
  };
};

type InitCarousel = {
  type: 'INIT_CAROUSEL';
};

type Action = AddSequenceElement | InitCarousel;

export const sequenceReducer = (state: State = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case 'ADD_SEQUENCE_ELEMENT':
      return {
        ...state,
        sequenceMap: {
          ...state.sequenceMap,
          [action.payload.id]: action.payload.element,
        },
      };
    case 'INIT_CAROUSEL':
      return {
        isReady: true,
        sequenceMap: {}, // Reset the map entirely if the cmsIds change
      };
    default:
      return state;
  }
};

const INITIAL_STATE: State = {
  sequenceMap: {},
  isReady: false,
};

const useSequence = () => {
  const [state, dispatch] = useReducer(sequenceReducer, {
    ...INITIAL_STATE,
  });

  const { sequenceMap } = state;

  useEffect(() => {
    dispatch({ type: 'INIT_CAROUSEL' });
  }, []);

  // Add data for each carousel video slide
  const addSequenceData = useCallback(
    (id: PromoHero, element: HTMLVideoElement) => {
      if (sequenceMap[id]) {
        return;
      }

      dispatch({ type: 'ADD_SEQUENCE_ELEMENT', payload: { id, element } });
    },
    [sequenceMap],
  );

  return { addSequenceData, ...state };
};

export default useSequence;
