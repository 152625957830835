import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

const routes = [
  <Route
    key="rainforest-cafe-page"
    path="/guide"
    component={asyncComponent(
      () => import('./Handler' /* webpackChunkName: "RainforestCafe" */),
    )}
    page
    shouldTrack
  />,
];

export default routes;
