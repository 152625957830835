import type { Reducer } from 'redux';

export type GlobalState = {
  modal: State;
};

type State = {
  open: boolean;
};

const defaultState: State = {
  open: false,
};

export const reducer: Reducer<State> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case Actions.Opened: {
      return {
        open: true,
      };
    }
    case Actions.Closed: {
      return {
        open: false,
      };
    }
    default:
      return state;
  }
};

export enum Actions {
  Opened = 'ecomm/modal/OPENED',
  Closed = 'ecomm/modal/CLOSED',
}

type Action = Opened | Closed;

type Opened = {
  type: Actions.Opened;
};

type Closed = {
  type: Actions.Closed;
};

export const modalOpened = (): Opened => ({
  type: Actions.Opened,
});

export const modalClosed = (): Closed => ({
  type: Actions.Closed,
});
