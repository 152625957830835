import type { Entities } from '@ecomm/shop/models';
import type { Order } from '../models';

export enum ActionType {
  Add = 'ecomm/order/ADD_ORDER',
}

export type State = Entities<Order>;

export const defaultState = {};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.Add:
      return {
        ...state,
        ...action.payload.orders,
      };
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  orders: State;
};

export const addOrders = (orders: Entities<Order>): AddOrdersAction => ({
  type: ActionType.Add,
  payload: { orders },
});

export type AddOrdersAction = {
  type: ActionType.Add;
  payload: { orders: Entities<Order> };
};

type Action = AddOrdersAction;
