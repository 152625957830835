import { slate } from '@pelotoncycle/design-system';
import React, { useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import useGetFinancingPartner from '../hooks/useGetFinancingPartner';
import useGetSecondaryFinancingPartner from '../hooks/useGetSecondaryFinancingPartner';
import { Partners } from '../models';
import { addCitizensSDKToPage, getCitizens } from './api';
import CitizensContext from './Context';

export const CitizensProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [citizensProvider, setCitizensProvider] = React.useState(null);
  const cpayPrequalifyEnabled = useIsToggleActive()('cpayPrequalify');
  const financingPartner = useGetFinancingPartner();
  const secondaryFinancingPartner = useGetSecondaryFinancingPartner();

  useEffect(() => {
    const loadCitizens = () => {
      const citizensWidgetScripts = document.querySelectorAll(
        '[id=citizens-widget-script]',
      );
      if (citizensWidgetScripts.length === 0)
        addCitizensSDKToPage(() => setCitizensProvider(getCitizens()));
      else setCitizensProvider(getCitizens());
    };

    if (
      (financingPartner === Partners.Citizens ||
        secondaryFinancingPartner === Partners.Citizens) &&
      cpayPrequalifyEnabled
    ) {
      loadCitizens();
    }
  }, [financingPartner, secondaryFinancingPartner, cpayPrequalifyEnabled]);

  return (
    <CitizensContext.Provider
      value={{
        citizensProvider,
      }}
    >
      <CitizensGlobalStyle />
      {children}
    </CitizensContext.Provider>
  );
};

const CitizensGlobalStyle = createGlobalStyle`
  div[id*="citizens-shop"] {
    display: inline !important;

    .offer-text {
      display: inline !important;
    }

    #jifitiIntroText,
    #addLinkToJifitiWidget {
      display: inline !important;
      color: ${slate[50]} !important;
    }
  }
  
  #citizens-checkout-widget {
    .offer-text {
      display: inline !important;
    }
  }
`;
