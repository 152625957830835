// THIS IS AN AUTOGENERATED FILE (@ecomm/graphql/codegen.yml). DO NOT EDIT THIS FILE DIRECTLY.
/* eslint-disable */
import * as Types from '../../graphql/types.generated';

import { DocumentNode } from 'graphql';
import * as ApolloReactCommon from '@ecomm/graphql/hooks';
import * as ApolloReactHooks from '@ecomm/graphql/hooks';
export type SetGiftInformationOnCartMutationVariables = Types.Exact<{
  giftingInformation: Types.GiftingInformationInput;
}>;

export type SetGiftInformationOnCartMutation = { __typename?: 'Mutation' } & {
  setGiftInformationOnCart?: Types.Maybe<
    | ({ __typename?: 'CartNext' } & Pick<Types.CartNext, 'id'> & {
          giftInformation?: Types.Maybe<
            { __typename?: 'GiftInformation' } & Pick<
              Types.GiftInformation,
              'gifterName' | 'recipientName' | 'recipientEmail' | 'giftMessage'
            >
          >;
        })
    | ({ __typename?: 'DomainError' } & Pick<Types.DomainError, 'message'>)
  >;
};

export const SetGiftInformationOnCartDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetGiftInformationOnCart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'giftingInformation' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GiftingInformationInput' },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setGiftInformationOnCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'giftingInformation' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'giftingInformation' },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CartNext' },
                  },
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'giftInformation' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gifterName' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'recipientName' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'recipientEmail' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'giftMessage' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DomainError' },
                  },
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export type SetGiftInformationOnCartMutationFn = ApolloReactCommon.MutationFunction<
  SetGiftInformationOnCartMutation,
  SetGiftInformationOnCartMutationVariables
>;

/**
 * __useSetGiftInformationOnCartMutation__
 *
 * To run a mutation, you first call `useSetGiftInformationOnCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGiftInformationOnCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGiftInformationOnCartMutation, { data, loading, error }] = useSetGiftInformationOnCartMutation({
 *   variables: {
 *      giftingInformation: // value for 'giftingInformation'
 *   },
 * });
 */
export function useSetGiftInformationOnCartMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetGiftInformationOnCartMutation,
    SetGiftInformationOnCartMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SetGiftInformationOnCartMutation,
    SetGiftInformationOnCartMutationVariables
  >(SetGiftInformationOnCartDocument, baseOptions);
}
export type SetGiftInformationOnCartMutationHookResult = ReturnType<
  typeof useSetGiftInformationOnCartMutation
>;
export type SetGiftInformationOnCartMutationResult =
  ApolloReactCommon.MutationResult<SetGiftInformationOnCartMutation>;
export type SetGiftInformationOnCartMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    SetGiftInformationOnCartMutation,
    SetGiftInformationOnCartMutationVariables
  >;
