import { Eyebrow } from '@pelotoncycle/design-system';
import type { EyebrowProps } from '@pelotoncycle/design-system';
import React from 'react';

/**
 * Breaking this out because it's shared between `LinkItem` & `MenuItem`
 * There've been issues where one style has been updated but not the other in the past...
 */

const HeaderText: React.FC<React.PropsWithChildren<EyebrowProps>> = ({
  children,
  ...props
}) => (
  <Eyebrow {...props} as="span">
    {children}
  </Eyebrow>
);

export default HeaderText;
