import React from 'react';
import { CmsHero } from '@ecomm/cms-hero';
import type { PromoHero } from '@ecomm/copy';
import { PlaceholderHeroWithSpinner } from '@ecomm/hero';
import CmsHeroCarousel from './CmsHeroCarousel';

type Props = {
  cmsIds: PromoHero[];
};

const CmsHeroOrCarousel: React.FC<React.PropsWithChildren<Props>> = ({ cmsIds }) => {
  if (cmsIds.length === 1) {
    return <CmsHero cmsId={cmsIds[0]} />;
  }
  if (cmsIds.length > 1) {
    return <CmsHeroCarousel cmsIds={cmsIds} />;
  }
  return <PlaceholderHeroWithSpinner />;
};

export default CmsHeroOrCarousel;
