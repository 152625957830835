import React from 'react';
import { useReportPageNotFoundError } from '@ecomm/hooks';
import GeoModalError from './GeoModalError';
import Page from './Page';

const GeoModalErrorPage = () => {
  useReportPageNotFoundError();

  return (
    <Page>
      <GeoModalError />
    </Page>
  );
};

export default GeoModalErrorPage;
