import useSWR from 'swr';
import type { Locale } from '@peloton/internationalize/models';
import { IMMUTABLE } from '@content/client/revalidation';
import type { Money } from '@ecomm/models/Money';
import type { BundleType } from '@ecomm/shop/models/BundleType';
import type { Pricing } from '../helpers/getProductPricesUtils';
import {
  determineAdditionalPrices,
  getTemporaryHardcodedPricing,
} from '../helpers/getProductPricesUtils';

// SWR/Hook version of getProductPrices()

const useAllBundlePrices = (locale: Locale): Pricing => {
  const { data: jsonPrices } = useSWR('prices', IMMUTABLE);

  if (jsonPrices.hasOwnProperty(locale)) {
    const { productPrices: prices } = determineAdditionalPrices(jsonPrices);
    const localePrices = prices[locale];

    return {
      getLowestPackagePriceByBundle: (bundle: BundleType): Money => {
        // This assumes that API and the serialized response
        // maintains lowest price sort order. This will not be true of GUIDE
        // but we are hard-coding prices for GUIDE  above.
        return localePrices.hasOwnProperty(bundle) &&
          localePrices[bundle]?.packages?.length
          ? localePrices[bundle]?.packages[0]?.price
          : localePrices[bundle]?.price ||
              getTemporaryHardcodedPricing(locale, bundle as BundleType);
      },
    };
  }

  // Following should:
  // - show app in error state (mechanism unknown currently)
  // - report error (done by default by above?
  throw new Error(`Bundle prices for locale "${locale}" not found.`);
};

export default useAllBundlePrices;
