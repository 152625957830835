import type { RouterState } from 'connected-react-router';
import { getLocation } from 'connected-react-router';
import type { Location as TLocation, LocationState } from 'history';
import {
  compose as rCompose,
  defaultTo,
  fromPairs,
  map,
  omit,
  prop,
  split,
  tail,
} from 'ramda';

export type Location<T = LocationState> = TLocation<T>;

export { getLocation };

// There isn't a ramda type for a compose chain this long
type Compose7<V0, T1, T2, T3, T4, T5, T6, T7> = (
  fn6: (x: T6) => T7,
  fn5: (x: T5) => T6,
  fn4: (x: T4) => T5,
  fn3: (x: T3) => T4,
  fn2: (x: T2) => T3,
  fn1: (x: T1) => T2,
  fn0: (x: V0) => T1,
) => (x: V0) => T7;

const compose: Compose7<
  Pick<Location, 'search'>,
  string | undefined,
  string,
  string,
  string[],
  string[][],
  Record<string, string>,
  Record<string, string>
> = rCompose;

export const parseSearch = (l: Pick<Location, 'search'>): Record<string, string> => {
  const queryParams = compose(
    omit(['']),
    fromPairs,
    map(split('=')),
    split('&'),
    tail,
    defaultTo(''),
    prop('search'),
  )(l);

  return Object.entries(queryParams).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [decodeURIComponent(key)]: decodeURIComponent(value),
    }),
    {},
  );
};

export const getQueryParams = rCompose(parseSearch, getLocation);

export type RoutingState = {
  router: RouterState;
};
