import type { OrderData } from '@ecomm/checkout/models';
import { toException } from '@ecomm/exceptions/Exception';
import type { TransactionState } from '@ecomm/models';
import { Status, toUIState } from '@ecomm/models';
import type { PaymentGateway } from '@ecomm/payment';

export enum ActionTypes {
  CheckoutFailed = 'ecomm/checkout/CHECKOUT_FAILED',
  CheckoutRequested = 'ecomm/checkout/CHECKOUT_REQUESTED',
  CheckoutSuccess = 'ecomm/checkout/CHECKOUT_SUCCEEDED',
}

export type State = {
  status: TransactionState;
};

export const defaultState = {
  status: toUIState(Status.Init) as TransactionState,
};

const reducer = (state: State = defaultState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.CheckoutRequested:
      return { status: toUIState(Status.Loading) };

    case ActionTypes.CheckoutFailed: {
      const { error } = action.payload;
      const exception = toException(error);
      return { status: toUIState(Status.Failed, exception) };
    }
    case ActionTypes.CheckoutSuccess:
      return { status: toUIState(Status.Init) };

    default:
      return state;
  }
};

export default reducer;

type CheckoutRequestPayload = {
  paymentGateway: PaymentGateway;
  orderData: OrderData;
  cartTotal: number;
  captchaToken?: string;
  isQuickPayCheckoutEnabled?: boolean;
};

export type ReducerState = {
  pgCheckout: State;
};

export const requestCreditCheckout = (
  payload: CheckoutRequestPayload,
): CheckoutRequestAction => ({
  type: ActionTypes.CheckoutRequested,
  payload,
});

export const failCheckout = (error: string): CheckoutFailAction => ({
  type: ActionTypes.CheckoutFailed,
  payload: { error },
});

export const checkoutSuccess = (): CheckoutSuccessAction => ({
  type: ActionTypes.CheckoutSuccess,
});

type Actions = CheckoutRequestAction | CheckoutFailAction | CheckoutSuccessAction;

export type CheckoutRequestAction = {
  type: ActionTypes.CheckoutRequested;
  payload: CheckoutRequestPayload;
};

export type CheckoutFailAction = {
  type: ActionTypes.CheckoutFailed;
  payload: {
    error: string;
  };
};

export type CheckoutSuccessAction = {
  type: ActionTypes.CheckoutSuccess;
};
