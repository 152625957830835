import type { SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';
import { initializeKlarna, initializeKlarnaSuccess } from '../redux';
import { getFinancing, isKlarnaSDKLoaded } from '../selectors';

const KLARNA_SDK_URL = 'https://x.klarnacdn.net/kp/lib/v1/api.js';

// Ramda's curried assoc doesn't play well with redux-sagas' call.
export const assoc = (key: string, value: any, obj: {}) => (obj[key] = value);

export const initializeKlarnaSaga = function* (): SagaIterator {
  const financingState = yield select(getFinancing);

  if (isKlarnaSDKLoaded(financingState)) {
    return;
  }

  yield put(initializeKlarna());

  const script = yield call([document, 'createElement'], 'script');

  yield all([
    call(assoc, 'src', KLARNA_SDK_URL, script),
    call(assoc, 'async', true, script),
  ]);

  yield call([document.body, 'appendChild'], script);

  yield put(initializeKlarnaSuccess());
};
