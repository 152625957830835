import { hasExperimentAndVariation, setUserAttribute } from '@peloton/split-testing';

const digitalFreeTrialForHardware = 'Digital Free Trial for HW';
const variationWithModals = 'Variation #1';

export const showDigitalFreeTrialForHardware = hasExperimentAndVariation(
  digitalFreeTrialForHardware,
  variationWithModals,
);

export const bucketUserForDigitalFreeTrialForHardware = () =>
  setUserAttribute('bike-tread-marketing-timeout-reached', true);
