import type { Client } from '@peloton/api';
import { pipeData, pipeError, throwError } from '@peloton/api';
import type { ErrorCode } from '../models';
import { toErrorCode } from '../models';
import mapper from './mapper';

const toSubscriptionsUrl = () => 'api/v2/user/subscriptions';

export const fetchUserSubscriptions = (api: Client) =>
  api
    .get(toSubscriptionsUrl())
    .then(pipeData(mapper))
    .catch(pipeError(throwError<ErrorCode>(toErrorCode)));
