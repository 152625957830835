import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';
import { BENEFIT_OPTIONS_PATH, SHORT_BENEFITS_OPTION_PATH } from '@onewellness/routes';

const benefitOptionsRoute = (
  <Route
    exact
    key={BENEFIT_OPTIONS_PATH}
    page
    path={BENEFIT_OPTIONS_PATH}
    component={asyncComponent(() => import('./Handler'))}
  />
);

const benefitOptionsVanityRoute = (
  <Route
    exact
    key={SHORT_BENEFITS_OPTION_PATH}
    page
    path={SHORT_BENEFITS_OPTION_PATH}
    component={asyncComponent(() => import('./Handler'))}
  />
);

export { benefitOptionsRoute, benefitOptionsVanityRoute };
