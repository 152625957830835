import type { Client } from '@peloton/api';
import { pipeDataTwice } from '@peloton/api';
import { toStoreSlug } from '@peloton/stores';
import { toCart as lightCartMapper } from './lightMapper';
import type { LightCartAPIWrapped } from './types';

export const getSummaryUrl = (cartId?: string) =>
  cartId
    ? `/ecomm/cart/${cartId}/load`
    : `/ecomm/store/${toStoreSlug()}/summary?renders_light_cart=true`;

export const fetchCart = async (api: Client, cartId?: string) => {
  const url = getSummaryUrl(cartId);
  const res = cartId
    ? await api.post(url, { targetStoreSlug: toStoreSlug() })
    : await api.get(url);
  return pipeDataTwice(data => lightCartMapper((data as LightCartAPIWrapped).cart))(res);
};
