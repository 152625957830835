import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

const Routes = [
  <Route
    key="chase-sapphire"
    toggleId={['chasePartnership']}
    path="/chase-sapphire"
    component={asyncComponent(
      () => import('./Handler' /* webpackChunkName: "ChaseSapphire" */),
    )}
    page={true}
    shouldTrack={false}
  />,
];

export default Routes;
