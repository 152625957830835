import { combineReducers } from 'redux';
import type { State as DataState } from './data';
import data, { getDates } from './data';
import type { State as FetcherState } from './fetcher';
import { fetcher } from './fetcher';

export type State = {
  data: DataState;
  fetcher: FetcherState;
};

export { getDates };

export default combineReducers({
  data,
  fetcher,
});
