import type { Client } from '@peloton/api';
import { pipeDataTwice } from '@peloton/api/utils';
import type { ID } from '@ecomm/models';
import { toCart as lightToCart } from './lightMapper';
import { toCart as oldToCart } from './mapper';
import type { ApiSummary, LightCartAPIWrapped } from './types';

export const createCartItemsURL = 'ecomm/cart/items';

type ApiProductOptions = {
  productOptions: ID[];
};

export const toProductOptions = (ids: ID[]): ApiProductOptions => ({
  productOptions: ids,
});

export type CreateCartItemsBody = {
  bundles?: {
    bundleId: ID;
    productOptions: Array<ID>;
    has_trade_in?: boolean;
  }[];
  products?: {
    productOptionId: ID;
    quantity: number;
  }[];
  renders_light_cart?: boolean;
};

/*
 * The new combined create-cart-items!
 *
 * Instead of separately posting bundles and items, we can do both
 * at the same time!
 */
const createCartItems = (useLightCart: boolean) => async (
  api: Client,
  bundleId: ID,
  bundleProductOptionIds: Array<ID>,
  tradeIn: boolean,
  productOptionId: ID,
) => {
  const body: CreateCartItemsBody = {
    bundles: [
      {
        bundleId: bundleId,
        productOptions: bundleProductOptionIds,
        has_trade_in: tradeIn,
      },
    ],
    products: [
      {
        productOptionId: productOptionId,
        quantity: 1,
      },
    ],
    renders_light_cart: useLightCart,
  };
  return api.post(createCartItemsURL, body).then(
    pipeDataTwice(data => {
      if (useLightCart) {
        return lightToCart((data as LightCartAPIWrapped).cart);
      } else {
        return oldToCart(data as ApiSummary);
      }
    }),
  );
};

export const addItemsToCart = (api: Client, body: CreateCartItemsBody) =>
  api
    .post(createCartItemsURL, { ...body, renders_light_cart: true })
    .then(pipeDataTwice(data => lightToCart((data as LightCartAPIWrapped).cart)));

export default createCartItems;
