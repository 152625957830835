import { all, fork } from 'redux-saga/effects';
import analytics from './analytics';
import cartLoaded from './cartLoaded';
import cartUserEmailSaga from './cartUserEmailSaga';
import freeTrialEligibility from './freeTrialEligibility';
import logOutSaga from './logOutSaga';
import redirectOnReview from './redirectOnReview';
import reviewOrderSagas from './reviewOrder';
import { default as shippingSagas } from './shipping';
import submitOrderSagas from './submitOrder';
import userSagas from './user';
import validate from './validate';

export default function* checkoutSaga() {
  yield all([
    fork(analytics),
    fork(cartLoaded),
    fork(cartUserEmailSaga),
    fork(freeTrialEligibility),
    fork(logOutSaga),
    fork(redirectOnReview),
    fork(reviewOrderSagas),
    fork(shippingSagas),
    fork(submitOrderSagas),
    fork(userSagas),
    fork(validate),
  ]);
}
