import { css } from 'styled-components';
import { defaultTransition } from './animation';
import { hover } from './hover';

export const underlineTransition = css`
  position: relative;

  &::after {
    ${defaultTransition('opacity')}
    border: 1px solid currentColor;
    border-radius: 1px;
    background: currentColor;
    content: '';
    height: 2px;
    width: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: calc(50% + 12px);
  }
`;

export const underlineTransitionThin = css`
  ${defaultTransition('border-color')}
  border-bottom: 1px solid transparent;
`;

export const underlineActive = css`
  ${underlineTransition}

  &.active, &:active {
    &::after {
      opacity: 1;
    }
  }
`;

export const underlineHover = css`
  ${underlineTransition}

  ${hover`
    &::after {
      opacity: 1;
    }
  `}
`;

export const underlineActiveHover = css`
  ${underlineTransition}

  ${hover`
    &::after {
      opacity: 1;
    }
  `}

  &.active, &:active {
    &::after {
      opacity: 1;
    }
  }
`;
