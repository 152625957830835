import { NEBULA_ENDPOINT, PREVIEW_NEBULA_ENDPOINT } from '@peloton/app-config';

export const NETLIFY_BUILDER_URL = `${NEBULA_ENDPOINT}/.netlify/builders`;
export const NETLIFY_FUNCTION_URL = `${NEBULA_ENDPOINT}/.netlify/functions`;
export const NETLIFY_BUILDER_PREVIEW_URL = `${PREVIEW_NEBULA_ENDPOINT}/.netlify/builders`;
export const NETLIFY_FUNCTION_PREVIEW_URL = `${PREVIEW_NEBULA_ENDPOINT}/.netlify/functions`;

export const PB_ENTRY_API_ENDPOINT = `${NETLIFY_BUILDER_URL}/pageBuilderEntryHandler`;
export const PB_ENTRY_PREVIEW_API_ENDPOINT = `${NETLIFY_BUILDER_PREVIEW_URL}/pageBuilderEntryHandler`;

export const PB_QUERY_API_ENDPOINT = `${NETLIFY_FUNCTION_URL}/pageBuilderQueryHandler`;
export const PB_QUERY_PREVIEW_API_ENDPOINT = `${NETLIFY_FUNCTION_PREVIEW_URL}/pageBuilderQueryHandler`;

export const PC_ENTRY_API_ENDPOINT = `${NETLIFY_BUILDER_URL}/peloContentEntryHandler`;
export const PC_ENTRY_PREVIEW_API_ENDPOINT = `${NETLIFY_BUILDER_PREVIEW_URL}/peloContentEntryHandler`;

export const PB_PROMO_BUNDLE_API_ENDPOINT = `${NETLIFY_FUNCTION_URL}/pageBuilderPromoCfuSlugHandler`;
export const PB_PROMO_BUNDLE_PREVIEW_API_ENDPOINT = `${NETLIFY_FUNCTION_PREVIEW_URL}/pageBuilderPromoCfuSlugHandler`;

export const PB_PROMOS_API_ENDPOINT = `${NETLIFY_FUNCTION_URL}/pageBuilderPromoHandler`;
export const PB_PROMOS_PREVIEW_API_ENDPOINT = `${NETLIFY_FUNCTION_PREVIEW_URL}/pageBuilderPromoHandler`;

export const PB_CFU_PROMO_API_ENDPOINT = `${NETLIFY_FUNCTION_URL}/pageBuilderPromoCfuHandler`;
export const PB_CFU_PROMO_PREVIEW_API_ENDPOINT = `${NETLIFY_FUNCTION_PREVIEW_URL}/pageBuilderPromoCfuHandler`;

export const PB_GROUPED_PROMOS_API_ENDPOINT = `${NETLIFY_FUNCTION_URL}/pageBuilderGroupedPromosHandler`;
export const PB_GROUPED_PROMOS_PREVIEW_API_ENDPOINT = `${NETLIFY_FUNCTION_PREVIEW_URL}/pageBuilderGroupedPromosHandler`;
