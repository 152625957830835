import { Container, Eyebrow } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import type { Locale } from '@peloton/internationalize/models/locale';
import { media } from '@peloton/styles/breakpoints';
import AppStoreLink from './AppStoreLink';
import type { CtaWithUrlSetData } from './models';

type Props = { country: Locale } & CtaWithUrlSetData;

const iconMap = {
  'footer.appStore.apple': 'appleEN',
  'footer.appStore.amazon': 'amazonEN',
  'footer.appStore.google': 'googleEN',
  'footer.appStore.roku': 'rokuEN',
  'footer.appStore.appleDE': 'appleDE',
  'footer.appStore.amazonDE': 'amazonDE',
  'footer.appStore.googleDE': 'googleDE',
};

const AppStoreLinks: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  ctas,
  country,
}) => {
  return (
    <AppStoreContainer as="section">
      <Eyebrow as="h2" size="small">
        {title}
      </Eyebrow>
      <AppStoreBadges data-test-id="app-store-badges">
        {ctas.map(
          ({ key, ...rest }) =>
            iconMap[key] && (
              <AppStoreLink
                key={key}
                iconName={iconMap[key]}
                country={country}
                {...rest}
              />
            ),
        )}
      </AppStoreBadges>
    </AppStoreContainer>
  );
};

export default AppStoreLinks;

const AppStoreContainer = styled(Container)`
  ${media.desktop`
    margin-top: 40px;
  `}
`;

const AppStoreBadges = styled.ul<{ children?: React.ReactNode }>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 16px;

  ${media.tabletXLarge`
    flex-direction: row;
    
    li:not(:first-child) {
      padding-left: 20px;
    }
  `}
`;
