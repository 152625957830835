import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

// Handlers should not have exact routes
const Routes = [
  <Route
    key="shop-page"
    path="/shop"
    component={asyncComponent(() => import('./Handler' /* webpackChunkName: "Shop" */))}
    shouldTrack={false}
  />,
];

export default Routes;
