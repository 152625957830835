import { curry, dissocPath, lensPath, lensProp, pair, pathEq, pipe, set } from 'ramda';
import type { LoadAuthUserSuccessAction, SuccessfulLogoutAction } from '@peloton/auth';
import { UserReducerActionType } from '@peloton/auth';
import { theUserIsInTheUnitedStates, toLocale } from '@peloton/internationalize';
import { ValidationErrors } from '@ecomm/form-validation';
import type { RegistrationForm } from '../models';

export enum ActionTypes {
  ErrorResolved = 'ecomm/registration/FIELD_ERROR_RESOLVED',
  FieldBlurred = 'ecomm/registration/FIELD_BLURRED',
  FieldErrored = 'ecomm/registration/FIELD_ERROR_UPDATED',
  FieldUpdated = 'ecomm/registration/FIELD_UPDATED',
}

export type Errors = Partial<Record<keyof RegistrationForm, string>>;

export type State = RegistrationForm & { errors: Errors };

export const defaultState = {
  allowMarketing: theUserIsInTheUnitedStates(toLocale()) ? true : false,
  email: '',
  hasAcceptedPolicy: false,
  password: '',
  confirmPassword: '',
  errors: {},
};

const reducer = (state: State = defaultState, action: Action) => {
  const toUpdateField = pipe(lensProp, set);
  const toUpdateError = pipe((name: string) => pair('errors', name), lensPath, set);
  const toResolveError = pipe((name: string) => pair('errors', name), dissocPath);

  switch (action.type) {
    case ActionTypes.ErrorResolved: {
      const { name } = action.payload;
      const resolveError = toResolveError(name);
      return resolveError(state);
    }

    case ActionTypes.FieldErrored: {
      const { name, errorMsg } = action.payload;
      const updateError = toUpdateError(name);
      return updateError(errorMsg, state);
    }

    case ActionTypes.FieldUpdated: {
      const { name, value } = action.payload;
      const updateField = toUpdateField(name);
      return updateField(value, state);
    }

    case UserReducerActionType.REQUEST_SUCCESS: {
      const isUserExistsError = pathEq(
        ['errors', 'email'],
        ValidationErrors.UserExists,
        state,
      );
      const resolveError = toResolveError('email');
      return isUserExistsError ? resolveError(state) : state;
    }

    case UserReducerActionType.LOGOUT_SUCCESS: {
      const setEmailError = toUpdateError('email');
      const updateEmail = toUpdateField('email');

      const updateEmailAndSetError = pipe(
        s => setEmailError(ValidationErrors.Missing, s),
        s => updateEmail('', s),
      );

      return updateEmailAndSetError(state);
    }

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  form: State;
};

export const updateRegistrationField = curry(
  (name: keyof RegistrationForm, value: boolean | string): UpdateAction => ({
    type: ActionTypes.FieldUpdated,
    payload: { name, value },
  }),
);

export const blurRegistrationField = curry(
  (name: keyof RegistrationForm, value: boolean | string): BlurAction => ({
    type: ActionTypes.FieldBlurred,
    payload: { name, value },
  }),
);

export const resolveFieldError = (name: keyof RegistrationForm): ResolveAction => ({
  type: ActionTypes.ErrorResolved,
  payload: { name },
});

export const updateFieldError = curry(
  (name: keyof RegistrationForm, errorMsg: string): ErrorAction => ({
    type: ActionTypes.FieldErrored,
    payload: { name, errorMsg },
  }),
);

export type BlurAction = {
  type: ActionTypes.FieldBlurred;
  payload: { name: keyof RegistrationForm; value: boolean | string };
};

type ErrorAction = {
  type: ActionTypes.FieldErrored;
  payload: { name: keyof RegistrationForm; errorMsg: string };
};

type ResolveAction = {
  type: ActionTypes.ErrorResolved;
  payload: { name: keyof RegistrationForm };
};

export type UpdateAction = {
  type: ActionTypes.FieldUpdated;
  payload: { name: keyof RegistrationForm; value: boolean | string };
};

type Action =
  | ErrorAction
  | LoadAuthUserSuccessAction
  | ResolveAction
  | SuccessfulLogoutAction
  | UpdateAction;
