import { useSelector } from 'react-redux';
import { getLocale } from '@peloton/env';
import { Locale, toCountryFromLocale } from '@peloton/internationalize';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import { hasVariation } from '@peloton/split-testing';
import { MarketingConsent } from '@ecomm/analytics';
import { getKeyFromDigitalSku, useActiveDigitalPromotion } from '@ecomm/cms-digital';

export const EXPERIMENT = 'Project PeloSwan Lead Gen'; // TODO: Remove this experiment
export const PLP_LEAD_GEN_EXPERIMENT = 'PLP Lead Gen Modal';
export const ORIGINAL = 'Original' as const;
export const VARIATION = 'Variation #1' as const;

type VariationName = typeof ORIGINAL | typeof VARIATION;

export const leadGenPromoConfig = {
  [ORIGINAL]: {
    campaignId: '7016O000001OR41QAG',
    campaignName: 'Email Apparel Promo',
    keySuffix: '.apparelPromo',
    shouldUseActiveDigitalPromo: false,
  },
  [VARIATION]: {
    campaignId: '7016O000001OSgIQAW',
    campaignName: 'Email Accessories Promo',
    keySuffix: '.accessoriesPromo',
    shouldUseActiveDigitalPromo: true,
  },
};

export const hasPLPLeadGenPromoOriginal = hasVariation(PLP_LEAD_GEN_EXPERIMENT, ORIGINAL);
export const hasPLPLeadGenPromoOriginalVariation1 = hasVariation(
  PLP_LEAD_GEN_EXPERIMENT,
  VARIATION,
);

export const hasLeadGenPromoOriginal = hasVariation(EXPERIMENT, ORIGINAL);
export const hasLeadGenPromoVariation1 = hasVariation(EXPERIMENT, VARIATION);

const useLeadGenPromoExperiment = () => useSelector(hasLeadGenPromoVariation1);
const useLeadGenPromoControl = () => useSelector(hasLeadGenPromoOriginal);

const useLeadGenPromoConfig = (forceVariation?: VariationName) => {
  const isBucketedInControl = useLeadGenPromoControl();
  const isBucketedInVariation = useLeadGenPromoExperiment();

  if (forceVariation) {
    return leadGenPromoConfig[forceVariation];
  }

  if (isBucketedInControl) {
    return leadGenPromoConfig[ORIGINAL];
  } else if (isBucketedInVariation) {
    return leadGenPromoConfig[VARIATION];
  }

  return null;
};

export const useLeadGenEventProps = (eventProps: { [key: string]: any }) => {
  const config = useLeadGenPromoConfig();
  const defaultProps = useLeadGenDefaultEventProps();

  if (config) {
    return {
      ...defaultProps,
      ...eventProps,
      campaignId: config.campaignId,
      campaignName: config.campaignName,
      marketingConsent: defaultProps.marketingConsent, // Ensure that marketing consent does not get overwritten
    };
  }

  return {
    ...defaultProps,
    ...eventProps,
  };
};

export const useLeadGenDefaultEventProps = () => {
  const locale = useSelector(getLocale);
  const marketingConsent = [
    Locale.EnglishAustralia,
    Locale.EnglishCanada,
    Locale.EnglishUnitedKingdom,
    Locale.GermanGermany,
  ].includes(locale)
    ? MarketingConsent.Explicit
    : MarketingConsent.Implicit;

  return {
    country: toCountryFromLocale(locale),
    pageName: getCurrentRelativePathname(),
    marketingConsent,
  };
};

export const useLeadGenPromoCopyKey = <ContentModel extends string>(
  originalKey: ContentModel,
  forceVariation?: VariationName,
): ContentModel => {
  const config = useLeadGenPromoConfig(forceVariation);
  const digitalPromotionSku = useActiveDigitalPromotion();

  if (config) {
    if (config.shouldUseActiveDigitalPromo && digitalPromotionSku) {
      return getKeyFromDigitalSku(originalKey, digitalPromotionSku);
    }
    return (originalKey + config.keySuffix) as ContentModel;
  }

  return originalKey;
};
