import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

const Routes = [
  <Route
    key="global-financing-page"
    exact
    path="/:discipline?/financing"
    component={asyncComponent(
      () => import('./Handler' /* webpackChunkName: "GlobalFinancing" */),
    )}
  />,
];

export default Routes;
