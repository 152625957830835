import { toLocaleFromHostname } from '@peloton/internationalize/models';
import type { State } from './toDefaultState';
import toDefaultState from './toDefaultState';

export enum ActionType {
  Set = 'ecomm/config/SET_CONFIG',
}

export type ReducerState = {
  config: State;
};

const defaultState: State = toDefaultState(toLocaleFromHostname());

type Action = ReturnType<typeof setConfig>;

const reducer = (state = defaultState, { type, payload }: Action) =>
  type === ActionType.Set ? payload : state;

export default reducer;

export const setConfig = (payload: State) =>
  ({
    type: ActionType.Set,
    payload,
  } as const);
