import { identity } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeData, pipeError, throwError } from '@peloton/api';
import type { ErrorCode } from '../models';
import { toErrorCode } from '../models';
import { toApiSubmitOrder } from './mapper';
import type { SubmitOrderPostData } from './types';

const COMPLETE_CHECKOUT_URL = '/ecomm/complete_checkout';

export const submitOrder = (api: Client, postData: SubmitOrderPostData) =>
  api
    .post(COMPLETE_CHECKOUT_URL, toApiSubmitOrder(postData))
    .then(pipeData(identity))
    .catch(pipeError(throwError<ErrorCode>(toErrorCode)));
