import { brand, grey, Body } from '@pelotoncycle/design-system';
import React from 'react';
import styled, { css } from 'styled-components';
import { toLocaleFlag } from '@peloton/internationalize/models/flag';
import type { Locale as LocaleKind } from '@peloton/internationalize/models/locale';
import { toDisplayName } from '@peloton/internationalize/models/locale';
import type { AccordionHeaderStateProps } from '@ecomm/accordion';
import { AccordionChevron } from '@ecomm/accordion';
import { hb8, b4 } from '@ecomm/typography';
import { Flag } from './LocaleLink';

const LocaleAccordionHeader = (currentLocale: LocaleKind, darkMode: boolean) => ({
  isOpen,
}: AccordionHeaderStateProps) => (
  <Header darkMode={darkMode}>
    <Flag src={toLocaleFlag(currentLocale)} alt="" />
    <Name textColor={brand.darkest}>{toDisplayName(currentLocale)}</Name>
    <Chevron isOpen={isOpen} />
  </Header>
);

export default LocaleAccordionHeader;

const Name = styled(Body)`
  ${hb8}
  margin: 0 15px;
`;

const Chevron = styled(AccordionChevron)`
  height: 7px;
  width: 12px;
`;

const Header = styled.div<{ darkMode: boolean }>`
  align-items: center;
  display: flex;
  margin-bottom: 20px;

  ${({ darkMode }) =>
    darkMode &&
    css`
      ${Name} {
        ${b4};
        text-transform: none;
        color: ${grey[50]};
      }

      ${Chevron} {
        fill: ${grey[50]};
      }
    `}
`;
