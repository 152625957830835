import type { Reducer } from 'redux';
import { isPersisted } from './persist';

type State = {
  hasDismissed: boolean;
};

const defaultState: State = {
  hasDismissed: isPersisted(),
};

export const reducer: Reducer<State> = (state = defaultState, action: Action): State => {
  switch (action.type) {
    case Actions.Accept:
    case Actions.Dismiss:
      return {
        ...state,
        hasDismissed: true,
      };
    default:
      return state;
  }
};

export default reducer;

export enum Actions {
  Accept = 'ecomm/privacyBanner/ACCEPT',
  Dismiss = 'ecomm/privacyBanner/DISMISS',
}

type Action = AcceptAction | DismissAction;

export type AcceptAction = {
  type: Actions.Accept;
};

export const accept = (): AcceptAction => ({
  type: Actions.Accept,
});

export type DismissAction = {
  type: Actions.Dismiss;
};

export const dismiss = (): DismissAction => ({
  type: Actions.Dismiss,
});

export type PrivacyBannerState = {
  privacyBanner: State;
};

export const getHasDismissed = (state: PrivacyBannerState): boolean =>
  state.privacyBanner.hasDismissed;
