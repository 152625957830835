import { lensPath, set } from 'ramda';
import type { Reducer } from 'redux';
import type { CloseAction } from '@ecomm/cart/redux/panel';
import { Actions as PanelActionTypes } from '@ecomm/cart/redux/panel';
import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { AddToCartSuccessAction } from '@ecomm/shop/redux/shopper';
import { ActionType as ShopperActionTypes } from '@ecomm/shop/redux/shopper';
import type { Cart } from '../models';

export type State = {
  isLoading: boolean;
  cart?: Cart;
  exception?: Exception;
  itemHasJustBeenAddedToCart?: boolean;
};

export enum Actions {
  REQUEST = 'pelo/fetchCart/REQUEST',
  REQUEST_INITIAL_SUCCESS = 'pelo/fetchCart/REQUEST_INITIAL_SUCCESS',
  REQUEST_SUCCESS = 'pelo/fetchCart/REQUEST_SUCCESS',
  REQUEST_FAILURE = 'pelo/fetchCart/REQUEST_FAILURE',
  UPDATE_EMAIL_SUCCESS = 'pelo/fetchCart/UPDATE_EMAIL_SUCCESS',
  SET_IS_GIFT_SUCCESS = 'pelo/fetchCart/SET_IS_GIFT_SUCCESS',
}

export const defaultState: State = {
  isLoading: false,
  itemHasJustBeenAddedToCart: false,
};

export const reducer: Reducer<State> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case Actions.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Actions.REQUEST_INITIAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cart: action.payload,
      };
    case Actions.REQUEST_SUCCESS: {
      const { email, shipping, ...restOfCart } = action.payload;
      return {
        ...state,
        isLoading: false,
        cart: {
          email: state.cart ? state.cart.email : email,
          shipping: state.cart ? state.cart.shipping : shipping,
          ...restOfCart,
        },
      };
    }
    case Actions.REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        exception: action.payload,
      };
    case Actions.UPDATE_EMAIL_SUCCESS: {
      const lens = lensPath(['cart', 'email']);
      return set(lens, action.payload.email, state);
    }
    case ShopperActionTypes.AddToCartSuccess:
      return { ...state, itemHasJustBeenAddedToCart: true };
    case PanelActionTypes.Close:
      return { ...state, itemHasJustBeenAddedToCart: false };
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  cart: State;
};

export type RequestAction = {
  type: Actions.REQUEST;
};

export type RequestInitialSuccess = {
  type: Actions.REQUEST_INITIAL_SUCCESS;
  payload: Cart;
};

export type RequestSuccess = {
  type: Actions.REQUEST_SUCCESS;
  payload: Cart;
};

export type RequestFailure = {
  type: Actions.REQUEST_FAILURE;
  payload: Exception;
};

export type UpdateEmailAction = ReturnType<typeof updateEmailSuccess>;

type Action =
  | RequestAction
  | RequestInitialSuccess
  | RequestSuccess
  | RequestFailure
  | UpdateEmailAction
  | CloseAction
  | AddToCartSuccessAction;

export const loadCart = (): RequestAction => ({
  type: Actions.REQUEST,
});

export const loadCartInitialSuccess = (c: Cart) => ({
  type: Actions.REQUEST_INITIAL_SUCCESS,
  payload: c,
});

export const loadCartSuccess = (c: Cart) => ({
  type: Actions.REQUEST_SUCCESS,
  payload: c,
});

export const loadCartFailure = (error: Error) => ({
  type: Actions.REQUEST_FAILURE,
  payload: toException(error.message),
});

export const updateEmailSuccess = (email: string) =>
  ({
    type: Actions.UPDATE_EMAIL_SUCCESS,
    payload: { email },
  } as const);
