import type { Locale } from '@peloton/internationalize/models';
import { getBundlePrices } from '@ecomm/pricing/helpers/getProductPrices';
import { BundleType } from '@ecomm/shop/models';

export type TotalPrices = {
  [BundleType.Bike]: number;
  [BundleType.BikePlus]: number;
  [BundleType.Tread]: number;
  [BundleType.TreadPlus]: number;
  [BundleType.RainforestCafe]: number;
  [BundleType.Row]: number;
};

export type State = {
  totalPrices: TotalPrices;
};

const toDefaultState = (locale: Locale): State => {
  const bundlePrices = getBundlePrices(locale);
  const bundles = [
    BundleType.Bike,
    BundleType.BikePlus,
    BundleType.Tread,
    BundleType.TreadPlus,
    BundleType.RainforestCafe,
    BundleType.Row,
    BundleType.RefurbishedBike,
    BundleType.RefurbishedBikePlus,
  ];

  const totalPrices = bundles.reduce(
    (bundleTotals, bundle) => ({
      ...bundleTotals,
      ...{
        [bundle]: bundlePrices.getLowestPackagePriceByBundle(bundle),
      },
    }),
    {} as TotalPrices,
  );

  return {
    totalPrices,
  };
};

export default toDefaultState;
