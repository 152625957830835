import type { SagaIterator } from 'redux-saga';
import { call, put, takeEvery, getContext } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import { fetchWarrantiesForProductLine } from './api';
import type { LoadWarranties } from './redux';
import { loadWarrantiesSuccess, Actions } from './redux';

const fetchWarrantiesSaga = function* (action: LoadWarranties): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  try {
    const data = yield call(fetchWarrantiesForProductLine, client, action.productLine);
    yield put(loadWarrantiesSuccess(action.productLine, data));
  } catch (err) {
    const error = new DomainError('Error fetching warranties', err);
    yield put(reportError({ error }));
  }
};

export const saga = function* () {
  yield takeEvery(Actions.LoadWarranties, fetchWarrantiesSaga);
};
