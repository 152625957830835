import { complement, equals, prop } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ValidationErrors } from '@ecomm/form-validation';
import type { RegistrationForm } from '../models';
import { toRegistrationValidation } from '../models';
import type { BlurAction, UpdateAction } from '../redux';
import {
  FormTypes,
  updateFieldError,
  resolveFieldError,
  getDoPasswordsMatch,
} from '../redux';

export const confirmPasswordSaga = function* (): SagaIterator {
  const passwordsMatch = yield select(getDoPasswordsMatch);
  if (passwordsMatch) {
    yield put(resolveFieldError('confirmPassword'));
  } else {
    yield put(updateFieldError('confirmPassword')(ValidationErrors.PasswordsDontMatch));
  }
};

export const validateRegistration = function* (action: Action): SagaIterator {
  const { name, value } = action.payload;

  try {
    const validations = yield call(toRegistrationValidation);
    const validator = prop(name, validations);
    yield call([validator, 'validate'], value);
    yield put(resolveFieldError(name));
  } catch (error) {
    const updateError = updateFieldError(name);
    yield put(updateError(error.message));
  }
};

export const validatorSaga = function* (action: Action) {
  const { name } = action.payload;

  if (isPasswordField(name)) {
    yield call(confirmPasswordSaga);
  }

  if (isRegistration(name)) {
    yield call(validateRegistration, action);
  }
};

export const debounce = function* (action: UpdateAction) {
  yield delay(200);
  yield call(validatorSaga, action);
};

const watcherSaga = function* () {
  // Must be takeEvery as on registration (or autocomplete), multiple actions
  // are fired simultaneously. Potentially investigate an explicit REGISTER action
  yield takeEvery(FormTypes.FieldUpdated, debounce);
  yield takeLatest(FormTypes.FieldBlurred, validatorSaga);
};

export default watcherSaga;

type Action = UpdateAction | BlurAction;

const isConfirmPassword = equals('confirmPassword');
const isPassword = equals('password');

const isPasswordField = (name: keyof RegistrationForm) =>
  isPassword(name) || isConfirmPassword(name);

const isRegistration = complement(isConfirmPassword);
