import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, takeLatest } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import type { SuccessfulLogoutAction } from '@peloton/auth';
import { UserReducerActionType as AuthActions } from '@peloton/auth';
import { fetchCart } from '../api';
import type { RequestAction } from '../redux';
import { CartActions, loadCartInitialSuccess, loadCartFailure } from '../redux';
import { cartReady } from '../redux/removeItem';

export const fetchCartSaga = function* (
  client: Client,
  _: SuccessfulLogoutAction | RequestAction,
): SagaIterator {
  try {
    const cart = yield call(fetchCart, client);
    yield put(loadCartInitialSuccess(cart));
  } catch (e) {
    yield put(loadCartFailure(e));
  }

  // Set the cart UI state to loaded
  yield put(cartReady());
};

export const cartSaga = function* () {
  const client: Client = yield getContext(CLIENT_CONTEXT);
  yield all([
    takeLatest(CartActions.REQUEST, fetchCartSaga, client),
    takeLatest(AuthActions.LOGOUT_SUCCESS, fetchCartSaga, client),
  ]);
};

export default cartSaga;
