import { connect } from 'react-redux';

import type { State as EnvState } from '@peloton/env';
import { getLocale } from '@peloton/env';
import type { GlobalState } from '@peloton/split-testing/redux/optimizely';

import { getActiveDigitalPromotion } from '@ecomm/cms-digital';
import type { ReducerState as ToggleState } from '@ecomm/feature-toggle';
import {
  getIsToggleActive,
  showCTAPromoModal1,
  showCTAPromoModal2,
  showDigitalFreeTrialForHardware,
  showUKTestClassLeadCaptureModal,
  showBikeModalFinancing,
  showBikeModalHomeTrial,
  showBikeModalFinancingHomeTrial,
  showFaasPromoModal,
  showTurkeyTrotLeadGenModal,
} from '@ecomm/feature-toggle';

import {
  showAppLeadCaptureVariation1,
  showAppLeadCaptureVariation2,
} from '@ecomm/feature-toggle/AppLeadCaptureModal';
import { showBlackFridayLeadCaptureVariation1 } from '@ecomm/feature-toggle/BlackFridayLeadCaptureModal';
import {
  showClassesModalVariation1,
  showClassesModalVariation2,
  showClassesModalVariation3,
} from '@ecomm/feature-toggle/ClassesLeadCaptureModal';
import {
  showModalAVariation1,
  showModalAVariation2,
  showModalAVariation3,
} from '@ecomm/feature-toggle/ProductLeadCaptureModalA';
import {
  showModalBVariation1,
  showModalBVariation2,
  showModalBVariation3,
} from '@ecomm/feature-toggle/ProductLeadCaptureModalB';
import {
  showModalCVariation1,
  showModalCVariation2,
  showModalCVariation3,
} from '@ecomm/feature-toggle/ProductLeadCaptureModalC';
import {
  hasPLPLeadGenPromoOriginal,
  hasPLPLeadGenPromoOriginalVariation1,
} from '@ecomm/feature-toggle/useLeadGenPromo';
import type { ReducerState } from '../redux';
import { clickCTA, closeModal } from '../redux';
import { getOpenModal, getTrigger } from '../selectors';

import View from './View';

const mapStateToProps = (state: EnvState & GlobalState & ReducerState & ToggleState) => ({
  country: getLocale(state),
  deviceType: getOpenModal(state),
  modalTrigger: getTrigger(state),
  isCTAPromoModal1Active: showCTAPromoModal1(state),
  isCTAPromoModal2Active: showCTAPromoModal2(state),
  isHactActive: showDigitalFreeTrialForHardware(state),
  isFaasPromoModalActive: showFaasPromoModal(state),
  isHomeTrialLeadCaptureActive:
    !getIsToggleActive('covid19') &&
    getIsToggleActive('homeTrialLeadCaptureModal')(state),
  isUKTestClassLeadCaptureModalActive: showUKTestClassLeadCaptureModal(state),
  isABAppModalVariation1: showAppLeadCaptureVariation1(state),
  isABAppModalVariation2: showAppLeadCaptureVariation2(state),
  isABCFinancingLeadCaptureActive:
    getIsToggleActive('financingHomeTrialTest')(state) && showBikeModalFinancing(state),
  isABCHomeTrialLeadCaptureActive:
    getIsToggleActive('financingHomeTrialTest')(state) && showBikeModalHomeTrial(state),
  isABCHTFinancingLeadCaptureActive:
    getIsToggleActive('financingHomeTrialTest')(state) &&
    showBikeModalFinancingHomeTrial(state),
  isABCProductModalAVariation1: showModalAVariation1(state),
  isABCProductModalAVariation2: showModalAVariation2(state),
  isABCProductModalAVariation3: showModalAVariation3(state),
  isABCProductModalBVariation1: showModalBVariation1(state),
  isABCProductModalBVariation2: showModalBVariation2(state),
  isABCProductModalBVariation3: showModalBVariation3(state),
  isABCProductModalCVariation1: showModalCVariation1(state),
  isABCProductModalCVariation2: showModalCVariation2(state),
  isABCProductModalCVariation3: showModalCVariation3(state),
  isClassesModalVariation1: showClassesModalVariation1(state),
  isClassesModalVariation2: showClassesModalVariation2(state),
  isClassesModalVariation3: showClassesModalVariation3(state),
  isLeadGenPromoModalOriginal: hasPLPLeadGenPromoOriginal(state),
  isLeadGenPromoModalVariation1: hasPLPLeadGenPromoOriginalVariation1(state),
  isBlackFridayLeadCaptureModalVariation1: showBlackFridayLeadCaptureVariation1(state),
  isTurkeyTrotLeadGenModalActive:
    getIsToggleActive('turkeyTrotLeadGenModal')(state) &&
    showTurkeyTrotLeadGenModal(state),
  digitalPromoSku: getActiveDigitalPromotion(state),
});

const mapDispatchToProps = {
  close: closeModal,
  handleCTA: clickCTA,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
