import { Container, Eyebrow, Icon, white, red } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';

const CartIconBuyFlow: React.FC<React.PropsWithChildren<{ hideCount: boolean }>> = ({
  hideCount,
}) => {
  const { totalLineItemQuantity } = useGetCartItems();

  return (
    <StyledContainer data-test-id="ct-cart-icon">
      <Icon name="cart" primaryColor={white} title="Cart-l10n" />
      {!hideCount && totalLineItemQuantity ? (
        <ItemCountBadge
          is="span"
          size="small"
          display="flex"
          textColor={white}
          data-test-id="cart-icon-count"
        >
          {totalLineItemQuantity}
        </ItemCountBadge>
      ) : null}
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  position: relative;
  width: auto;
  svg {
    fill: none;
    display: block;
  }
`;

const ItemCountBadge = styled(Eyebrow)`
  background: ${red[80]};
  height: 17px;
  width: 17px;
  border-radius: 50%;
  place-content: center;
  align-items: center;
  position: absolute;
  left: 20px;
  top: -3px;

  ${media.tabletXLarge`
    width: 20px;
    height: 20px;
  `}
`;

export default CartIconBuyFlow;
