import { LOCATION_CHANGE } from 'connected-react-router';
import type { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { addToAudienceAndBucket, getExperiments } from './optimizely';
import type { SetUserAttribute } from './redux/optimizely';
import { Actions, getOptimizelyProjectId, updateExperiments } from './redux/optimizely';
import { installPromisified } from './SplitTesting';

const updateExperimentsSaga = function* (): SagaIterator {
  const id = yield select(getOptimizelyProjectId);
  yield call(installPromisified, id);
  const experiments = getExperiments();
  yield put(updateExperiments(experiments));
};

export const setUserAttributeSaga = function* ({
  payload: { attribute, value },
}: SetUserAttribute) {
  yield call(addToAudienceAndBucket, attribute, value);
  yield call(updateExperimentsSaga);
};

export const splitTestingSaga = function* () {
  yield takeLatest([LOCATION_CHANGE, Actions.LOAD_EXPERIMENTS], updateExperimentsSaga);
  yield takeEvery(Actions.SET_USER_ATTRIBUTE, setUserAttributeSaga);
};
