import type { Optimizely } from './models';
import type { ExperimentAndVariation } from './redux/optimizely';

const optimizely = (): Optimizely => (window as any).optimizely;

const getState = () => optimizely() && optimizely().get && optimizely().get('state');

export const getScript = ({ projectId }: { projectId: string }) =>
  `https://cdn.optimizely.com/js/${projectId}.js`;

const campaignHasCurrentlyActivePage = (campaignId: string) => {
  const campaignData = optimizely().get('data').campaigns[campaignId];

  return campaignData.pageIds.find((pageId: string) => {
    const pageData = getState().getPageStates()[pageId];
    return pageData.isActive;
  });
};

export const PERSONALIZED_NOTIFICATION_PATTERN = 'personalizedNotification';
export const PROMO_BANNER_PATTERN = 'bannerOptimizely';

export const getExperiments = () => {
  const state = getState();

  if (state) {
    const campaignStates = state.getCampaignStates({ isActive: true });

    return experimentsWithActivePage(campaignStates);
  } else {
    return {};
  }
};

const experimentsWithActivePage = (campaignStates: object) => {
  return Object.keys(campaignStates).reduce((memo, campaignId: string) => {
    const { campaignName, experiment, variation } = campaignStates[campaignId];

    const pageSpecificCampaignForADifferentPage =
      campaignName.includes(PERSONALIZED_NOTIFICATION_PATTERN) &&
      !campaignHasCurrentlyActivePage(campaignId);

    if (pageSpecificCampaignForADifferentPage) {
      return memo;
    } else {
      return {
        ...memo,
        [experiment.name]: variation.name,
      };
    }
  }, {});
};

export const generatePersonalizationContentfulKey = (
  experimentAndVariation: ExperimentAndVariation | null,
  pattern: string,
): string => {
  if (experimentAndVariation) {
    const { experiment, variation } = experimentAndVariation;
    return `${pattern}.${experiment}.${variation}.`;
  }

  return '';
};

export const setUserAttribute = (attribute: string, value: any): boolean =>
  optimizely() &&
  optimizely().push({
    type: 'user',
    attributes: {
      [attribute]: value,
    },
  });

export const generateBannerExperimentContentfulKeySuffix = (
  experimentAndVariation: ExperimentAndVariation | null,
): string => {
  if (experimentAndVariation) {
    return `.${experimentAndVariation.experiment}.${experimentAndVariation.variation}`;
  }

  return '';
};

// Optimizely will call `handler` anytime the `name` lifeCycle event fires
// https://developers.optimizely.com/x/solutions/javascript/reference/#function_registerlisteners
export const addLifecycleListener = (name: string, handler: Function): boolean =>
  optimizely() &&
  optimizely().push({
    type: 'addListener',
    filter: {
      type: 'lifecycle',
      name,
    },
    handler,
  });

export const forceBucketUser = (): boolean => optimizely().push({ type: 'activate' });

export const addToAudienceAndBucket = (attribute: string, value: any) => {
  if (setUserAttribute(attribute, value)) {
    forceBucketUser();
  }
};

// Custom Events https://developers.optimizely.com/x/solutions/javascript/reference/index.html#events
export const trackCustomEvent = (eventName: string, tags: any = {}) =>
  optimizely() &&
  optimizely().push({
    type: 'event',
    eventName,
    tags,
  });
