import React from 'react';
import { Redirect, Switch } from 'react-router';
import {
  DEFAULT_LOCALE,
  PublishedLocale,
  addCurrentLocaleToUrl,
} from '@peloton/internationalize/models/path';
import {
  LOCALE_TOGGLE,
  REDIRECTS_ADD_BASEPATH,
} from '@peloton/internationalize/models/toggle';

type MyComponentProps = {
  children?: React.ReactNode;
};

export const additionalRouteLocales = Object.values(PublishedLocale).filter(
  locale => locale !== DEFAULT_LOCALE,
);

export function InternationalSwitch({ children }: MyComponentProps) {
  const i18nrouting = LOCALE_TOGGLE;
  if (!children) {
    return null;
  }
  if (!i18nrouting) {
    return <Switch>{children}</Switch>;
  }

  const childrenArr = Array.isArray(children) ? children : [children];
  const routesWithInternationalizedPaths = internationalizeRoutePaths(childrenArr);

  return <Switch>{routesWithInternationalizedPaths}</Switch>;
}

export function internationalizeRoutePaths(
  routes: React.ReactElement[],
): React.ReactElement[] {
  return routes.flatMap(element => {
    if (element?.props?.from && element.type === Redirect) {
      return cloneI18nRedirect(element);
    }
    if (element?.props?.path) {
      return cloneI18nRoute(element);
    }
    if (element && Array.isArray(element)) {
      return internationalizeRoutePaths(element);
    }
    return element;
  });
}

function cloneI18nRedirect(element: React.ReactElement) {
  const i18nRoutes = additionalRouteLocales.map(locale => {
    const localizedKey = `${locale}${element.props.from}`;
    const localizedTo = addCurrentLocaleToUrl(element.props.to, locale);
    return React.cloneElement(element, {
      from: `/${localizedKey}`,
      key: `${localizedKey}`,
      to: REDIRECTS_ADD_BASEPATH ? localizedTo : element.props.to,
    });
  });
  return [...i18nRoutes, element];
}

function cloneI18nRoute(element: React.ReactElement) {
  const i18nRoutes = additionalRouteLocales.map(locale => {
    if (Array.isArray(element.props.path)) {
      const localizedKey = `${locale}${element.props.path[0]}`;
      return React.cloneElement(element, {
        path: element.props.path.map((path: string) => `/${locale}${path}`),
        key: `${localizedKey}`,
      });
    }
    const localizedKey = `${locale}${element.props.path}`;
    return React.cloneElement(element, {
      path: `/${localizedKey}`,
      key: `${localizedKey}`,
    });
  });
  return [...i18nRoutes, element];
}
