import { getLocation } from 'connected-react-router';
import type { SagaIterator } from 'redux-saga';
import { takeEvery, select, call } from 'redux-saga/effects';
import { track } from '@peloton/analytics';
import { getUserTrackingProperties } from '@ecomm/auth';
import {
  accessoryProductLines,
  onePelotonClubProductLines,
} from '@ecomm/cart/models/Item';
import type { ProductLine } from '@ecomm/shop/models/Product';
import { PanelActions, getCartViewedProps, getCartId, AnalyticsAction } from '../redux';
import type { TrackCartLoadedAction, TrackCartRetrievedAction } from '../redux/analytics';
import type { OpenAction } from '../redux/panel';

type CartViewedType = {
  brand: string;
  category: ProductLine;
  name: string;
  price: number;
  product_id: string;
  quantity: number;
  sku: string;
  package_name: string | undefined;
};

const extractCartRetrievedData = function (products: CartViewedType[]) {
  const categories: string[] = [];
  let hasAccessory = false;
  let hasOPC = false;
  const names: string[] = [];
  let price = 0;

  products.forEach(product => {
    categories.push(product.category);
    names.push(product.name);
    price += product.price;

    if (!hasOPC) {
      hasOPC = onePelotonClubProductLines[product?.category] !== undefined;
    }

    if (!hasAccessory) {
      hasAccessory = accessoryProductLines[product?.category] !== undefined;
    }
  });

  return {
    category: categories.join(', '),
    hasAccessory,
    hasOPC,
    name: names.join(', '),
    price,
  };
};

export const submitAnalytics = function* (
  action: TrackCartLoadedAction | TrackCartRetrievedAction | OpenAction,
): SagaIterator {
  const cart_id = yield select(getCartId);
  const products: [CartViewedType] = yield select(getCartViewedProps);
  const user = yield select(getUserTrackingProperties);
  const { pathname: page } = yield select(getLocation);
  const cartRetrievedData =
    action.type === AnalyticsAction.CartRetrieved
      ? extractCartRetrievedData(products)
      : {};

  yield call(track, {
    event: EVENT_MAP[action.type],
    properties: {
      shared_cart_id:
        action.type === AnalyticsAction.CartLoaded
          ? action.payload.originalCartId
          : undefined,
      cart_id,
      ...cartRetrievedData,
      products,
      page,
      ...user,
    },
  });
};

const sagas = function* () {
  yield takeEvery(
    [PanelActions.Open, AnalyticsAction.CartLoaded, AnalyticsAction.CartRetrieved],
    submitAnalytics,
  );
};

export default sagas;

const EVENT_MAP = {
  [PanelActions.Open]: 'Cart Viewed',
  [AnalyticsAction.CartLoaded]: 'Cart Loaded',
  [AnalyticsAction.CartRetrieved]: 'Cart Retrieved',
};
