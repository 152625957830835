import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

const Routes = [
  <Route
    key="treads-pages"
    path="/treads"
    component={asyncComponent(() => import('./Handler' /* webpackChunkName: "Treads" */))}
    page={true}
    shouldTrack={false}
  />,
];

export default Routes;
