const validReviewsQandADeviceSlug = [
  'bike',
  'bikes',
  'bike-plus',
  'refurbished-bike',
  'refurbished-bike-plus',
  'tread',
  'tread-plus',
  'guide',
  'row',
] as const;

// typeof validReviewsQandADeviceSlug[number] returs type which includes only array items('bike'|'bikes'|'bike-plus'...)
export type ValidReviewsQandADeviceSlug = typeof validReviewsQandADeviceSlug[number];

const constructValidRoutes = () => validReviewsQandADeviceSlug.join('|');
const SUB_PAGE_ROUTES = 'reviews|questions';

export const getValidPaths = () =>
  `/:discipline(${constructValidRoutes()})/:type(${SUB_PAGE_ROUTES})/:submit(submit)?`;
