import type { SagaIterator } from 'redux-saga';
import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { getPartnerBySlugAPI } from '@onewellness/api';
import { CLIENT_CONTEXT } from '@onewellness/api/client';
import { ConnectionAPIError } from '../Error';
import type { FetchConnectionNameRequestAction } from '../redux';
import {
  FetchConnectionNameActionTypes,
  fetchConnectionNameSucceeded,
  fetchConnectionNameFailed,
} from '../redux';

export const fetchConnectionNameSaga = function* ({
  payload: { partnerSlug },
}: FetchConnectionNameRequestAction): SagaIterator {
  try {
    const client = yield getContext(CLIENT_CONTEXT);
    const responseData = yield call(getPartnerBySlugAPI, client, partnerSlug);

    const connection = responseData.partnerBySlug.federatedConnection;

    if (connection?.length) {
      yield put(fetchConnectionNameSucceeded(connection));
    } else {
      yield put(fetchConnectionNameFailed(ConnectionAPIError.PartnerNotFound));
    }
  } catch (e) {
    yield put(fetchConnectionNameFailed(e.graphQLErrors[0].message, e));
  }
};

const watcherSaga = function* (): SagaIterator {
  yield takeEvery(
    FetchConnectionNameActionTypes.FetchConnectionNameRequested,
    fetchConnectionNameSaga,
  );
};

export default watcherSaga;
