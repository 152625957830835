import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { useQueryParams, useQueryHash } from '@ecomm/hooks';
import { FormattedMessage } from '@ecomm/internationalize';
import { Page } from '@ecomm/layout';
import { b2, med } from '@ecomm/typography';

type BazaarvoiceParams = {
  deviceType: string;
};

const validEmailTypes = ['QUESTION_ANSWERED', 'QUESTION_APPROVED', 'ANSWER_APPROVED'];

const VerifyUgcEmailPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { deviceType } = useParams<BazaarvoiceParams>();
  const { bvmessageType } = useQueryParams();
  const msgType =
    typeof bvmessageType === 'string' ? bvmessageType : bvmessageType && bvmessageType[0];
  const hash = useQueryHash();
  const redirectUrl = getUrlByMessageType(deviceType, hash, msgType);

  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }

  return (
    <Page>
      <Container>
        <FailureHeader>
          <FormattedMessage id="errors.default" />
        </FailureHeader>
      </Container>
    </Page>
  );
};

export default VerifyUgcEmailPage;

const getUrlByMessageType = (
  deviceType: string,
  hash: string,
  messageType: string = '',
): string | null => {
  if (!validEmailTypes.includes(messageType)) {
    return null;
  }

  const [contentType, contentId] = hash.split('/'); //Hash is '#question/123456
  return `/${deviceType}/${contentType}/${contentId}`;
};

const Container = styled.div`
  max-width: 960px;
  padding: 20px;
  flex-grow: 1;
  width: 100%;
  position: relative;
  text-align: center;

  ${media.tablet`
    margin: 40px auto;
  `}
`;

const FailureHeader = styled.h1`
  ${b2}
  ${med}
  margin-top: 20px;
`;
