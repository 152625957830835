import { all, fork } from 'redux-saga/effects';
import { default as checkoutSaga } from './checkoutSaga';
import multiStepSaga from './multiStepSaga';
import promoCodeSaga from './promoCodeSaga';
import referrerRequestDataSaga from './referrerDataSaga';
import registerUserSaga from './registerUserSaga';
import subscriptionSaga from './subscriptionSaga';

export default function* sagas() {
  yield all([
    fork(checkoutSaga),
    fork(subscriptionSaga),
    fork(promoCodeSaga),
    fork(multiStepSaga),
    fork(referrerRequestDataSaga),
    fork(registerUserSaga),
  ]);
}
