import { equals, path, pathOr, pipe } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { call, select, takeLatest } from 'redux-saga/effects';
import { track } from '@peloton/analytics';
import {
  toLocale,
  toNeedsConsentForMarketingInAnalytics,
} from '@peloton/internationalize';
import type {
  BlurUserFieldAction,
  BlurUserFieldAnalyticsAction,
} from '@ecomm/checkout/redux/user';
import { ActionType as CheckoutActionTypes } from '@ecomm/checkout/redux/user';
import type { CheckoutRequestAction } from '@ecomm/quick-checkout/redux';
import { ActionTypes as QuickCheckoutActionTypes } from '@ecomm/quick-checkout/redux';
import { matchDigitalCheckout } from '../routes';

export const trackCheckoutRequested = function* () {
  const event = { event: 'Clicked Start Membership' };

  yield call(track, event);
};

export const trackEmailBlurSaga = function* (
  action: BlurUserFieldAction | BlurUserFieldAnalyticsAction,
): SagaIterator {
  // TODO: When feature toggles are accessible in redux, select
  // this property from there.
  const needsConsentForMarketing = yield call(
    toNeedsConsentForMarketingInAnalytics,
    toLocale(),
  );

  if (!needsConsentForMarketing) {
    const email = pathOr('', ['payload', 'value'], action);
    const event = {
      event: 'Moved away from email field on Digital Checkout',
      properties: {
        email,
        listName: 'Website Leads',
      },
    };

    yield call(track, event);
  }
};

export const filterActionSaga = function* (action: Action): SagaIterator {
  switch (action.type) {
    case CheckoutActionTypes.UserFieldBlurred:
    case CheckoutActionTypes.UserFieldBlurredAnalytics:
      {
        const blurredEmail = yield call(isEmailBlur, action);

        if (blurredEmail) {
          yield call(trackEmailBlurSaga, action);
        }
      }
      break;

    case QuickCheckoutActionTypes.CheckoutRequested:
      yield call(trackCheckoutRequested);
      break;

    default:
      return;
  }
};

export const filterRouteSaga = function* (action: Action): SagaIterator {
  const match = yield select(matchDigitalCheckout);

  if (match) {
    yield call(filterActionSaga, action);
  }
};

const watcherSaga = function* () {
  yield takeLatest(
    [
      CheckoutActionTypes.UserFieldBlurred,
      CheckoutActionTypes.UserFieldBlurredAnalytics,
      QuickCheckoutActionTypes.CheckoutRequested,
    ],
    filterRouteSaga,
  );
};

export default watcherSaga;

type Action = BlurUserFieldAction | BlurUserFieldAnalyticsAction | CheckoutRequestAction;

export const isEmailBlur = pipe(path(['payload', 'name']), equals('email'));
