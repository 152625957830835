import type { ApiMeUser } from '@peloton/auth';
import type { EcommUser } from './models';

const mapper = ({
  hasActiveDeviceSubscription,
  hasActiveDigitalSubscription,
}: ApiMeUser): EcommUser => ({
  hasDeviceSubscription: hasActiveDeviceSubscription,
  hasDigitalSubscription: hasActiveDigitalSubscription,
});

export default mapper;
