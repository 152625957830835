import { Eyebrow, spacing, grey, Icon } from '@pelotoncycle/design-system';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BreakpointWidth } from '@peloton/styles';

const StyledTextContainer = styled(Eyebrow)<{
  isActive: boolean;
}>`
  margin: ${spacing[16]} 0;
  align-items: center;
  ${props => (props.isActive ? `color: ${grey[90]};` : '')}
`;

const StyledIcon = styled(Icon)`
  transform: matrix(-1, 0, 0, 1, 0, 0);
  margin-left: ${spacing[12]};
`;

type Props = {
  id: string;
  name: string;
  active: boolean;
  setSelectedMenu: (name: string) => void;
};

const TopLevelMenu: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  name,
  active,
  setSelectedMenu,
}) => {
  const [countSize, setCountSize] = useState<string>('medium');

  useEffect(() => {
    const handleWindowSizeChange = () => {
      if (window.innerWidth >= BreakpointWidth.desktopXLarge) setCountSize('medium');
      else setCountSize('small');
    };
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  return (
    <button onClick={() => setSelectedMenu(id)}>
      <StyledTextContainer
        size={countSize == 'medium' ? 'medium' : 'small'}
        isActive={active}
        textColor={grey[70]}
      >
        {name}
        {active && <StyledIcon name="chevron" />}
      </StyledTextContainer>
    </button>
  );
};

export default TopLevelMenu;
