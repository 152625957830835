import type { LogoutOptions } from '@auth0/auth0-spa-js';
import { useState, useEffect } from 'react';
import { toClientDetails } from '@peloton/analytics/clientDetailsHeader';
import { useClient } from '@peloton/api/ClientContext';
import type { LogoutOptions as PLogoutOptions } from '@peloton/auth';
import { logOut as legacyLogout } from '@peloton/auth/api';
import { CHECKOUT_ACCESS_TOKEN_STORAGE_KEY } from '@peloton/auth/constants';
import { useOauth } from '@peloton/auth/OauthProvider';
import useLocalStorage from '@ecomm/hooks/useLocalStorage';
import { useLoginUrl } from './OAuthProvider';

const useLogout = (isUniversalLogoutEnabled: boolean = true) => {
  const client = useClient();
  const { logout } = useOauth();
  const loginUrl = useLoginUrl();
  const [, , removeToken] = useLocalStorage(CHECKOUT_ACCESS_TOKEN_STORAGE_KEY, '');
  const [href, setHref] = useState('');

  useEffect(() => {
    setHref(window.location.href);
  }, []);

  return async (
    options: PLogoutOptions = {},
    oauthLogoutOptions: LogoutOptions = {},
    shouldLogoutLegacy: boolean = true,
  ) => {
    if (shouldLogoutLegacy) {
      try {
        const clientDetails = options.source ? toClientDetails({}, options.source) : {};
        await legacyLogout(client, clientDetails);
      } catch (e) {
        // if we fail to logout of legacy, continue to oauth logout anyways
      }
    }

    removeToken();

    const { logoutParams, ...logoutOptions } = oauthLogoutOptions;
    logout(
      {
        logoutParams: {
          returnTo: loginUrl(href),
          ...(!!logoutParams ? logoutParams : {}),
        },
        ...(!!logoutOptions ? logoutOptions : {}),
      },
      isUniversalLogoutEnabled,
    );
  };
};

export default useLogout;
