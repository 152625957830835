import type { CartContactInfo } from '@ecomm/cart/models';

export const toShippingInfo = (shippingInfo?: CartContactInfo & { email: string }) => {
  if (shippingInfo) {
    return {
      shipping_address: {
        given_name: shippingInfo.name.first,
        family_name: shippingInfo.name.last,
        street_address: shippingInfo.address.line1,
        street_address2: shippingInfo.address.line2,
        postal_code: shippingInfo.address.postalCode,
        city: shippingInfo.address.city,
        email: shippingInfo.email,
        country: shippingInfo.address.country,
      },
    };
  } else {
    return {};
  }
};
