import { useEffect, useState } from 'react';
import {
  geoDataLacksCountryAndState,
  isLocatedInProtectedState,
} from '@peloton/onetrust/utils';

export function useLocationInProtectedState(): [boolean, boolean | null] {
  const [loading, setLoading] = useState<boolean>(true);
  const [isInProtectedState, setIsInProtectedState] = useState<boolean | null>(null);
  useEffect(() => {
    const getOneTrustLocation = async () => {
      const { __promiseHooks } = window as any;
      try {
        const oneTrustGeoData = await __promiseHooks.oneTrustLoaderPromise;
        setLoading(false);
        if (geoDataLacksCountryAndState(oneTrustGeoData)) {
          setIsInProtectedState(null);
          return;
        }
        if (isLocatedInProtectedState(oneTrustGeoData)) {
          setIsInProtectedState(true);
          return;
        }
        setIsInProtectedState(false);
      } catch (e) {
        setLoading(false);
        setIsInProtectedState(null);
      }
    };
    getOneTrustLocation();
  }, []);

  return [loading, isInProtectedState];
}
