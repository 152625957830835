import type React from 'react';
import { connect } from 'react-redux';
import type { ToggleId } from './models';
import type { ReducerState } from './redux';
import { _getIsToggleActive } from './selectors';
import type { Children } from './Toggle';

type Props = {
  toggleId: ToggleId;
  active: boolean;
  children: Children;
};

const FeatureToggle: React.FC<Props> = ({ children, active }) => children(active);

const mapStateToProps = (state: ReducerState, { toggleId }: Pick<Props, 'toggleId'>) => ({
  active: _getIsToggleActive(toggleId)(state),
});

export default connect(mapStateToProps)(FeatureToggle);
