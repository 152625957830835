import React from 'react';
import styled from 'styled-components';
import { a11yOutlineStyles } from '@peloton/accessibility';
import { Markdown } from '@peloton/copy';
import { useBannerReference } from '@acme-ui/global';
import { trackLinkClick } from '@ecomm/analytics';
import { a11yOutline } from '@ecomm/buttons';
import type { LinkProps } from '@ecomm/links';
import { Link } from '@ecomm/links';
import EcommBanner, { CtaText } from './EcommBanner';
import type { BannerPropsCta } from './models';

const SingleCtaBanner: React.FC<React.PropsWithChildren<BannerPropsCta>> = ({
  text,
  theme,
  links,
  dataTestIdPrefix,
  estimates = {},
  markdownRenderAddons = {},
}) => {
  const bannerRef = useBannerReference();

  const [{ url: to, text: ctaText }] = links;

  const TextWithDataTestId: React.FC<React.PropsWithChildren<unknown>> = ({
    children,
  }) => <StyledSpan data-test-id={`${dataTestIdPrefix}Text`}>{children}</StyledSpan>;

  return (
    <StyledLink
      data-test-id={`${dataTestIdPrefix}Url`}
      data-element-id="banner"
      to={to}
      onClick={() => trackLinkClick({ href: to, parent: 'hurricane banner' })}
    >
      <EcommBanner ref={bannerRef} theme={theme}>
        <div>
          <Markdown
            content={text}
            values={{ ...estimates }}
            markdown={{
              renderers: { paragraph: TextWithDataTestId, ...markdownRenderAddons },
            }}
          />{' '}
          <CtaText
            className="singleCta"
            data-test-id={`${dataTestIdPrefix}Cta`}
            theme={theme}
          >
            {ctaText}
          </CtaText>
        </div>
      </EcommBanner>
    </StyledLink>
  );
};

export default SingleCtaBanner;

const StyledSpan = styled.span`
  ${a11yOutline}
`;

const StyledLink = styled(Link)`
  &:focus {
    .singleCta {
      ${a11yOutlineStyles}
    }
  }
` as React.ComponentType<React.PropsWithChildren<LinkProps>>;
