import type { SagaIterator } from 'redux-saga';
import { call, getContext } from 'redux-saga/effects';
import type { PeloLink } from '@peloton/external-links';
import { EXT_LINK_ENV_CONTEXT, toHref } from '@peloton/external-links';
import { redirect } from '@peloton/navigation';

type Options = {
  link: PeloLink;
};

const toRedirectSaga = ({ link }: Options) =>
  function* (): SagaIterator {
    const extLinkEnv = yield getContext(EXT_LINK_ENV_CONTEXT);
    yield call(redirect, toHref(link, extLinkEnv));
  };

export default toRedirectSaga;
