import { useSelector } from 'react-redux';
import { useLocale } from '@peloton/internationalize';
import { getBasePriceForBundleType } from '@ecomm/config/redux';
import { useIsToggleActive } from '@ecomm/feature-toggle';
import { useApr } from '@ecomm/pricing/helpers/useApr';
import { getShippingCostByDevices } from '@ecomm/shipping-estimate/ShippingEstimate';
import type { BundleType } from '@ecomm/shop/models';
import { getMembershipPrice } from '@ecomm/subscription-plans/selectors';
import LocaleHasNoFinancingPartnerError from '../errors/LocaleHasNoFinancingPartnerError';
import type { Props as FinancingProps } from '../FinancingProps';
import { bundleTypeToFinancedDevice } from '../FinancingProps';
import { defaultFinancingTerm, PartnerDisplayNameMap, Partners } from '../models';
import { roundByFinancingPartner } from '../models/monthlyPaymentWithApr';
import useGetFinancingPartner from './useGetFinancingPartner';
import useGetSecondaryFinancingPartner from './useGetSecondaryFinancingPartner';

const useFinancing = (bundleType: BundleType): FinancingProps => {
  const partner = useGetFinancingPartner();
  const secondaryPartner = useGetSecondaryFinancingPartner();
  if (partner === Partners.None) {
    throw new LocaleHasNoFinancingPartnerError(
      'Cannot useFinancing if no financing partner',
    );
  }

  const isToggleActive = useIsToggleActive();
  const isProjectPhoenixEnabled = isToggleActive('projectPhoenix');
  const isZipThirtySixMonthsFinancingEnabled = isToggleActive(
    'zipThirtySixMonthFinancing',
  );

  const devicePrice = useSelector(getBasePriceForBundleType(bundleType));
  const financedDevice = bundleTypeToFinancedDevice(bundleType);
  const membershipPrice = useSelector(getMembershipPrice(bundleType));
  const shippingPriceEstimate = isProjectPhoenixEnabled
    ? getShippingCostByDevices([bundleType])
    : 0;
  const term = defaultFinancingTerm(
    { partner, bundleType },
    isZipThirtySixMonthsFinancingEnabled,
  );
  const termNumber = Number(term);

  const locale = useLocale();
  const apr = useApr(locale, bundleType, termNumber);

  const total = devicePrice + shippingPriceEstimate;
  const monthly = roundByFinancingPartner(total, termNumber, apr, partner);

  return {
    bundleType,
    financedDevice,
    financingPartner: partner,
    secondaryFinancingPartner: secondaryPartner,
    financingPartnerDisplayName: PartnerDisplayNameMap[partner],
    membershipPrice,
    total,
    totalBeforeShipping: devicePrice,
    monthly,
    term,
    shippingEstimate: shippingPriceEstimate,
    apr,
  };
};

export default useFinancing;
