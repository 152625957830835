import type { Reducer } from 'redux';
import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';

export enum Actions {
  Apply = 'pelo/attemptCouponApply/REQUEST',
  ApplySuccess = 'pelo/attemptCouponApply/REQUEST_SUCCESS',
  ApplyFailure = 'pelo/attemptCouponApply/REQUEST_FAILURE',
  RemoveCoupon = 'pelo/removeCoupon/REQUEST',
  RemoveCouponSuccess = 'pelo/removeCoupon/REQUEST_SUCCESS',
  RemoveCouponFailure = 'pelo/removeCoupon/REQUEST_FAILURE',
  ResetCouponError = 'pelo/resetCouponError/REQUEST',
}

type State = {
  isPromoLoading: boolean;
  promoException?: Exception;
  isLastCallSuccess?: boolean;
};

export const defaultState: State = {
  isPromoLoading: false,
};

export const reducer: Reducer<State> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case Actions.Apply:
      return {
        ...state,
        isPromoLoading: true,
      };
    case Actions.ApplySuccess:
      return {
        ...state,
        isPromoLoading: false,
        isLastCallSuccess: true,
        promoException: undefined,
      };
    case Actions.ApplyFailure:
      return {
        ...state,
        isPromoLoading: false,
        promoException: action.payload,
        isLastCallSuccess: false,
      };
    case Actions.RemoveCoupon:
    case Actions.RemoveCouponSuccess:
      return {
        ...state,
        promoException: undefined,
      };
    case Actions.RemoveCouponFailure:
      return {
        ...state,
        promoException: action.payload,
      };
    case Actions.ResetCouponError:
      return {
        ...state,
        promoException: undefined,
      };
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  coupon: State;
};

export type ApplyCoupon = {
  type: Actions.Apply;
  payload: string;
};

export type ApplyCouponSuccess = {
  type: Actions.ApplySuccess;
};

export type ApplyCouponFailure = {
  type: Actions.ApplyFailure;
  payload: Exception;
};

export type RemoveCoupon = {
  type: Actions.RemoveCoupon;
  payload: string;
};

export type RemoveCouponSuccess = {
  type: Actions.RemoveCouponSuccess;
};

export type RemoveCouponFailure = {
  type: Actions.RemoveCouponFailure;
  payload: Exception;
};

export type ResetCouponError = {
  type: Actions.ResetCouponError;
};

type Action =
  | ApplyCoupon
  | ApplyCouponSuccess
  | ApplyCouponFailure
  | RemoveCoupon
  | RemoveCouponSuccess
  | RemoveCouponFailure
  | ResetCouponError;

export const applyCoupon = (code: string): ApplyCoupon => ({
  type: Actions.Apply,
  payload: code,
});

export const applyCouponSuccess = (): ApplyCouponSuccess => ({
  type: Actions.ApplySuccess,
});

export const applyCouponFailure = (error: Error): ApplyCouponFailure => ({
  type: Actions.ApplyFailure,
  payload: toException(`checkout.errors.coupon.${error.message}`),
});

export const removeCoupon = (code: string): RemoveCoupon => ({
  type: Actions.RemoveCoupon,
  payload: code,
});

export const removeCouponSuccess = (): RemoveCouponSuccess => ({
  type: Actions.RemoveCouponSuccess,
});

export const removeCouponFailure = (error: Error): RemoveCouponFailure => ({
  type: Actions.RemoveCouponFailure,
  payload: toException(`checkout.errors.coupon.${error.message}`),
});

export const resetCouponError = (): ResetCouponError => ({
  type: Actions.ResetCouponError,
});
