import { black, grey } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { media } from '@peloton/styles';
import { Swipeable } from '@peloton/swipeable';
import { GlobalUiStateContext } from '@acme-ui/global';
import { CmsHero } from '@ecomm/cms-hero';
import { white } from '@ecomm/colors';
import type { PromoHero } from '@ecomm/copy/models.generated';
import { ChevronLight, Orientation } from '@ecomm/icons';
import CarouselVideoControl from './CarouselVideoControl';
import useCarouselWithAutoAdvance, {
  DEFAULT_INTERVAL,
} from './hooks/useCarouselWithAutoAdvance';
import useDurationSequence from './hooks/useDurationSequence';
import { cmsIdsMemo } from './utils';

type Props = {
  cmsIds: PromoHero[];
  autoAdvance?: boolean;
};

const CmsHeroCarousel: React.FC<React.PropsWithChildren<Props>> = ({ cmsIds }) => {
  const activeVideo = useRef<HTMLVideoElement>();
  const { reduceMotion } = useContext(GlobalUiStateContext);

  const { addSequenceData, sequenceMap, isReady } = useDurationSequence();

  const {
    handleStart,
    handlePause,
    activeSlide,
    handleNext,
    handlePrevious,
    handleAutoAdvance,
    handleClickIndicator,
    isPlaying,
  } = useCarouselWithAutoAdvance(cmsIds);

  // If the sequence is ready, start the carousel.
  // Safeguard for unsetting ready status with cmsIds discrepancy
  useEffect(() => {
    if (isReady && !reduceMotion) {
      handleStart();
      if (activeVideo.current?.paused) activeVideo?.current?.play();
    } else {
      handlePause();
      if (activeVideo.current) resetActiveMedia();
    }
  }, [isReady, reduceMotion]);

  // When changing slides, if the previous slide was a video, reset and stop it.
  const resetActiveMedia = useCallback(() => {
    if (activeVideo.current) {
      activeVideo.current.pause();
      activeVideo.current.currentTime = 0;
    }
  }, [activeVideo]);

  // If the now active slide is a video, play it
  const playActiveMedia = useCallback(() => {
    if (!reduceMotion) activeVideo?.current?.play();
  }, [reduceMotion]);

  const videoEl = sequenceMap[cmsIds[activeSlide]];
  // If the carousel is advancing, handle resetting, setting, and playing potential video elements.
  useEffect(() => {
    resetActiveMedia();

    if (!videoEl) return;

    activeVideo.current = videoEl;
    playActiveMedia();
  }, [activeSlide, videoEl]);

  const onMediaPause = useCallback(
    (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
      const eventVideo = event.currentTarget;

      // If we stop and start a video without changing slides,
      // we have to account for the video ending before the restarted sequence timeout.
      // Progress animation and video will always be in sync.
      if (eventVideo.ended && eventVideo.src === activeVideo?.current?.src) {
        handleAutoAdvance();
      }
    },
    [isPlaying],
  );

  // If video 'onPlay' is called and the carousel is paused, the user wants to resume.
  const onMediaPlay = useCallback(() => {
    if (!isPlaying) {
      handleStart();
    }
  }, [isPlaying]);

  const onMediaToggleClick = useCallback(() => {
    if (isPlaying) {
      activeVideo?.current?.pause();
      handlePause();
    } else {
      activeVideo?.current?.play();
      handleStart();
    }
  }, [isPlaying]);

  const initialHeroProgressiveRenderMap = { [0]: true };

  const [heroProgressiveRenderMap, setHeroProgressiveRenderMap] = React.useState(
    initialHeroProgressiveRenderMap,
  );

  useEffect(() => {
    const updatedProgressiveRenderMap = {
      ...heroProgressiveRenderMap,
      [activeSlide]: true,
    };

    setHeroProgressiveRenderMap(updatedProgressiveRenderMap);
    const timeout = setTimeout(() => {
      setHeroProgressiveRenderMap({
        ...updatedProgressiveRenderMap,
        [activeSlide + 1]: true,
      });
    }, 4000);

    return () => {
      clearTimeout(timeout);
    };
  }, [activeSlide]);

  return (
    <Container>
      <CarouselContainer
        data-test-id="carousel-container"
        style={{ opacity: isReady ? 1 : 0 }}
        onSwipeLeft={handleNext}
        onSwipeRight={handlePrevious}
      >
        {cmsIds.map((cmsId, i) => {
          const heroToRender = (
            <HeroContainer
              data-test-id="cmsHeroContainer"
              className={i === activeSlide ? 'is-active' : ''}
              key={cmsId}
            >
              <CmsHero
                cmsId={cmsId}
                inCarousel
                index={i}
                onPause={onMediaPause}
                onPlay={onMediaPlay}
                onReady={element => addSequenceData(cmsId, element!)}
                activeSlide={activeSlide}
              />
            </HeroContainer>
          );

          if (heroProgressiveRenderMap[i]) {
            return heroToRender;
          }

          return null;
        })}
      </CarouselContainer>
      <BarsContainer>
        {cmsIds.map((cmsId, index) => {
          const className =
            activeSlide === index && !reduceMotion && (isPlaying || activeVideo.current)
              ? 'active'
              : '';
          const complete = activeSlide > index || (reduceMotion && activeSlide === index);
          return (
            <IndicatorButton
              key={`indicator-${index}`}
              aria-label={`slide ${index + 1} button`}
              onClick={() => handleClickIndicator(index)}
            >
              <ProgressBar
                className={className}
                playState={isPlaying ? 'running' : 'paused'}
                complete={complete}
              />
            </IndicatorButton>
          );
        })}
      </BarsContainer>
      <Previous onClick={handlePrevious} aria-label="Previous">
        <ChevronLight width="70px" orientation={Orientation.Left} />
      </Previous>
      <Next onClick={handleNext} aria-label="Next">
        <ChevronLight width="70px" orientation={Orientation.Right} />
      </Next>
      {!reduceMotion && (
        <CarouselVideoControl
          playing={isPlaying}
          onClick={onMediaToggleClick}
          theme="light"
        />
      )}
    </Container>
  );
};

export default React.memo(CmsHeroCarousel, cmsIdsMemo);

const Container = styled.section`
  position: relative;
  background-color: ${black};
`;

const HeroContainer = styled.div``;

const CarouselContainer = styled(Swipeable)`
  display: grid;
  transition: opacity 0.15s ease-in;
  transition-delay: 150ms; /* allow image or video poster in first slide to download before fading in */
  position: relative;

  & > * {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    visibility: hidden;

    /* overlay that fades out as each slide comes in */
    &:after {
      content: '';
      position: absolute;
      pointer-events: none; /* ensures click events still 'pass-through' the overlay */
      background-color: black;
      transition: all 0.2s ease-in;
      opacity: 0.8;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &.is-active {
      visibility: visible;
      z-index: 1;

      &:after {
        opacity: 0;
      }
    }
  }
`;

const NavButton = styled.button`
  align-items: center;
  cursor: pointer;
  display: none;
  height: 80px;
  justify-content: center;
  position: absolute;
  top: calc(50% - 40px);
  width: 100px;
  z-index: 2;

  @media (min-width: 940px) {
    display: flex;
  }

  svg path {
    stroke: ${grey[50]} !important;
    transition: all 150ms ease;
  }

  &:hover svg path {
    box-shadow: ${rgba(black, 0.2)} 5px 3px 50px 0px;
    stroke: ${white} !important;
  }
`;

const Previous = styled(NavButton)`
  left: 0;
  right: auto;
`;

const Next = styled(NavButton)`
  left: auto;
  right: 0;
`;

const progressBarAnimation = keyframes`
  0% { width: 0 }
  100% { width: 100% }
`;

const BarsContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  z-index: 2;
  margin: 0 3rem;

  ${media.tabletXLarge`
    bottom: 8px;
  `}
`;

type ProgressBarProps = {
  playState: 'paused' | 'running';
  complete: boolean;
};

const IndicatorButton = styled.button`
  background: transparent;
  border: none;
  padding: 16px 4px;
  width: 84px;
  position: relative;
`;

const ProgressBar = styled.span<ProgressBarProps>`
  height: 2px;
  width: 100%;
  box-shadow: 0 0 20px 5px ${rgba(black, 0.2)};
  background-color: ${rgba(white, 0.25)};
  display: block;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${props => (props.complete ? '100%' : '0')};
    height: 100%;
    background-color: ${white};
    z-index: 3;
  }

  &.active::after {
    animation: ${progressBarAnimation} ${DEFAULT_INTERVAL}ms linear forwards;
    animation-play-state: ${props => props.playState};
  }
`;
