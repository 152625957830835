import { pick, propEq } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { call, getContext, put, select, take, takeEvery } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import type { Credentials } from '@peloton/auth';
import { UserReducerActionType, logIn } from '@peloton/auth';
import { getIsToggleActive } from '@ecomm/feature-toggle';
import { register } from '../api';
import { LOGIN_FAILED } from '../models';
import {
  RegisterTypes,
  failRegistration,
  getRegistrationData,
  hasRegistrationErrors,
  succeedRegistration,
} from '../redux';

export const loginUser = function* (credentials: Credentials): SagaIterator {
  yield put(logIn(credentials));

  const isSSOEnabled = yield select(getIsToggleActive('sso_register'));

  while (true) {
    const action = yield take([
      UserReducerActionType.LOGIN_FAILURE,
      // NOTE: LOGIN_SUCCESS is unreliable as it doesn't update the local state
      UserReducerActionType.REQUEST_SUCCESS,
    ]);

    if (isError(action)) {
      yield put(failRegistration(LOGIN_FAILED));
    }
    if (!isSSOEnabled && isSuccess(action)) {
      yield put(succeedRegistration());
    }
  }
};

export const registerUser = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  const data = yield select(getRegistrationData);

  try {
    yield call(register, client, data);

    const credentials: Credentials = toCredentials(data);
    yield call(loginUser, credentials);
  } catch (error) {
    yield put(failRegistration(error.message));
  }
};

export const validateSaga = function* (): SagaIterator {
  const hasErrors = yield select(hasRegistrationErrors);

  if (hasErrors) {
    yield put(failRegistration('formInvalid'));
  } else {
    yield call(registerUser);
  }
};

const watcherSaga = function* () {
  yield takeEvery(RegisterTypes.RegistrationRequested, validateSaga);
};

export default watcherSaga;

const toCredentials = pick(['email', 'password']);

const isSuccess = propEq('type', UserReducerActionType.REQUEST_SUCCESS);
const isError = propEq('type', UserReducerActionType.LOGIN_FAILURE);
