import React from 'react';
import { useSelector } from 'react-redux';
import { getIsProdEnv, getIsTestEnv } from '@peloton/env';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';

const AsyncProductTogglePanel = React.lazy(
  () => import('./Panel' /* webpackChunkName: "ProductTogglePanel" */),
);

const ProductTogglePanelWrapper: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isProd = useSelector(safeSelect(getIsProdEnv));
  const isTest = useSelector(safeSelect(getIsTestEnv));
  const prodOverrideActive = useIsToggleActive()('enableProductAvailablityPanel');
  // If undefined we are likely in a test scenario
  if (((isProd && !prodOverrideActive) || isTest) ?? true) {
    return null;
  }

  return (
    <React.Suspense fallback={null}>
      <AsyncProductTogglePanel />
    </React.Suspense>
  );
};

export default ProductTogglePanelWrapper;

// This is essentially only for tests that render the ProductStates Provider and
// also mock their own redux state. This can lead to an error being thrown when
// trying to access missing data.
const safeSelect = <S extends {}, V>(selector: (s: S) => V) => (
  state: S,
): V | undefined => {
  try {
    return selector(state);
  } catch {
    return undefined;
  }
};
