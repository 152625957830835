import React from 'react';
import type { RouteProps } from 'react-router';
import { Redirect, Route } from 'react-router';
import { isRedirectFromGeomodal } from '@ecomm/bootstrapping/internationalize/GeoModalV2/geoModalV2Helpers';
import GeoModalErrorPage from '@ecomm/layout/GeoModalErrorPage';

type Props = RouteProps & {
  isActive: boolean;
};

const PageBuilderRoute: React.FC<React.PropsWithChildren<Props>> = ({
  isActive,
  path,
  component,
  location,
  ...rest
}) => {
  const FALLBACK_PATH = '/'; // TODO - Grab this from Contentful Page model

  if (isActive) {
    return <Route path={path} component={component} location={location} {...rest} />;
  } else if (isRedirectFromGeomodal()) {
    return <GeoModalErrorPage />;
  }
  return <Redirect to={FALLBACK_PATH} />;
};

export default PageBuilderRoute;
