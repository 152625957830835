import { pathOr } from 'ramda';
import type { PeloLink } from '@peloton/external-links';
import type { ExperimentAndVariation } from '@peloton/split-testing';
import { generateBannerExperimentContentfulKeySuffix } from '@peloton/split-testing';
import type { PromoBanner } from '../models.generated';
import { usePromoBannerQuery } from '../queries/contentTypes/promoBanner.generated';
import type { CtaWithUrlData } from './types';
import type { CtaWithDriftInteractionData } from './useCtaWithDriftInteractionData';
import { transformCtaWithDriftInteractionData } from './useCtaWithDriftInteractionData';
import { transformCtaWithUrlData } from './useCtaWithUrlData';

export type PromoBannerData = PromoBannerWithCtaData | PromoBannerWithDriftData;
export type PromoBannerWithCtaDataKinds = 'CtaWithUrl';

export type PromoBannerWithCtaData = {
  key: PromoBanner;
  kind: PromoBannerWithCtaDataKinds;
  text: string;
  theme: string;
  hide: boolean;
  links: CtaWithUrlData[] | PeloLinkCta[];
};

type PeloLinkCta = {
  url: PeloLink;
  text: string;
  targetBlank: boolean;
};

export type PromoBannerWithDriftData = {
  key: PromoBanner;
  kind: 'CtaWithDriftInteraction';
  text: string;
  theme: string;
  hide: boolean;
  driftCta: CtaWithDriftInteractionData;
};

export type PromoBannerCollectionData = {
  key: PromoBanner;
  text: string;
  theme: string;
  hide: boolean;
  linksCollection: { items: object[] };
};

export const isPromoBannerWithCtaData = (
  promoBannerData: PromoBannerData,
): promoBannerData is PromoBannerWithCtaData => {
  return (promoBannerData as PromoBannerWithCtaData).links !== undefined;
};

export const isPromoBannerWithDriftData = (
  promoBannerData: PromoBannerData,
): promoBannerData is PromoBannerWithDriftData => {
  return (promoBannerData as PromoBannerWithDriftData).driftCta !== undefined;
};

const transformCtaWithUrl = (ctaWithUrl: CtaWithUrlData) =>
  transformCtaWithUrlData({ data: { ctaWithUrl } });

const transformPromoBannerCtaLinks = (link: CtaWithUrlData): CtaWithUrlData =>
  transformCtaWithUrl(link);

const isBannerWithDriftInteraction = (linksCollection: any): boolean => {
  return (
    linksCollection?.items?.length && 'interactionId' in (linksCollection?.items[0] ?? {})
  );
};

const transformPromoBannerItem = ({
  key,
  theme,
  text,
  linksCollection,
  hide,
}: PromoBannerCollectionData): PromoBannerData => {
  const fallbackText = `PromoBanner: ${key}`;

  if (isBannerWithDriftInteraction(linksCollection)) {
    const data = transformCtaWithDriftInteractionData({
      data: { ctaWithDriftInteraction: linksCollection.items[0] },
    });
    return {
      key,
      kind: 'CtaWithDriftInteraction',
      theme,
      driftCta: data,
      text,
      hide: !!hide,
    };
  }

  const ctas =
    linksCollection.items.length > 0
      ? linksCollection.items.map(transformPromoBannerCtaLinks)
      : [];

  return {
    key,
    kind: 'CtaWithUrl',
    theme,
    links: ctas,
    text: text || fallbackText,
    hide: !!hide,
  };
};

export const transformPromoBannerData = (response: object): PromoBannerData => {
  const { text, theme, linksCollection = { items: [] }, key, hide } = pathOr(
    [],
    ['data', 'promoBanner'],
    response,
  );

  return transformPromoBannerItem({ key, text, theme, linksCollection, hide });
};

const usePromoBannerData = (id: PromoBanner): PromoBannerData => {
  const res = usePromoBannerQuery({ variables: { id } });
  return transformPromoBannerData(res);
};

export const useOptimizelyPromoBannerData = (
  experimentsAndVariations: ExperimentAndVariation[],
  id: PromoBanner,
  allBannerData: any,
): PromoBannerData => {
  let bannerData = usePromoBannerData(id);

  const banners = pathOr([], ['data', 'promoBannerCollection', 'items'], allBannerData);

  experimentsAndVariations.forEach(experimentAndVariation => {
    const abTestSuffix = generateBannerExperimentContentfulKeySuffix(
      experimentAndVariation,
    );
    const ABBannerName = `${id}${abTestSuffix}`;

    const ABBannerData = banners.find((banner: { key: string; text: string }) => {
      return banner.key === ABBannerName;
    });

    if (ABBannerData && experimentAndVariation) {
      bannerData = transformPromoBannerItem(ABBannerData);
    }
  });

  return bannerData;
};

export default usePromoBannerData;
