import React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BackgroundImage } from '@ecomm/background-media';
import type { BreakpointOptions } from '@ecomm/image';
import useConnectedVideo from '../hooks/useConnectedVideo';
import type { SnapAxis } from '../hooks/useSnapAxis';
import useSnapAxis, { videoSnapCss } from '../hooks/useSnapAxis';
import VideoToggle from './VideoToggle';

export type ConnectedVideoProps = React.VideoHTMLAttributes<HTMLVideoElement> & {
  windowActiveTether?: boolean;
  reduceMotionTether?: boolean;
  freezeTether?: boolean;
  toggleTheme?: 'light' | 'dark';
  toggleBearing?: 'left' | 'right';
  showPlayPauseToggle?: boolean;
  posterBreakpointOptions: BreakpointOptions;
};

const ConnectedVideo: React.FC<React.PropsWithChildren<ConnectedVideoProps>> = ({
  windowActiveTether,
  reduceMotionTether,
  freezeTether,
  toggleTheme = 'light',
  toggleBearing = 'left',
  showPlayPauseToggle,
  posterBreakpointOptions,
  ...restOfProps
}) => {
  const { ref: videoRef } = useConnectedVideo({
    windowActiveTether,
    reduceMotionTether,
    freezeTether,
  });

  const [isPlaying, setIsPlaying] = useState(false);
  const { snapAxis, supportsObjectFit } = useSnapAxis(videoRef.current);

  useEffect(() => {
    const togglePlayState = () => setIsPlaying(!videoRef.current?.paused);
    videoRef.current?.addEventListener('play', togglePlayState);
    videoRef.current?.addEventListener('pause', togglePlayState);

    return () => {
      videoRef.current?.removeEventListener('play', togglePlayState);
      videoRef.current?.removeEventListener('pause', togglePlayState);
    };
  }, [videoRef]);

  const togglePlayPause = () => {
    videoRef.current?.paused ? videoRef.current?.play() : videoRef.current?.pause();
  };

  const { poster, ...propsWithoutPoster } = restOfProps;

  return (
    <VideoContainer breakpointOptions={posterBreakpointOptions} src={poster || ''}>
      {/* It is safe to do this because we don't need captions on our hero vids */}
      {/* eslint-disable-next-line */}
      <StyledVideo
        ref={videoRef}
        snapAxis={snapAxis}
        supportsObjectFit={supportsObjectFit}
        {...propsWithoutPoster}
      />
      {showPlayPauseToggle && (
        <VideoToggle
          onClick={togglePlayPause}
          isPlaying={isPlaying}
          theme={toggleTheme}
          bearing={toggleBearing}
        />
      )}
    </VideoContainer>
  );
};

export default ConnectedVideo;

export const VideoContainer = styled(BackgroundImage)`
  overflow: hidden;
`;

type StyledVideoProps = {
  snapAxis?: SnapAxis;
  supportsObjectFit: boolean;
};

export const StyledVideo = styled.video<StyledVideoProps>`
  object-fit: cover;
  object-position: top center;

  position: relative;
  ${props => videoSnapCss(props.supportsObjectFit, props.snapAxis)};
`;
