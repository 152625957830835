export enum Term {
  FortyThree = 43,
}

export const maxTerm = () => Term.FortyThree;

export const TERMS: Record<string, Term> = {
  '43mo-financing': Term.FortyThree,
};

export const getTerm = (slug: string) => TERMS[slug];
