import {
  AFFIRM_PUBLIC_KEY_US,
  AFFIRM_PUBLIC_KEY_CA,
  AFFIRM_PUBLIC_KEY_AU,
  AFFIRM_TRANSACTIONS_API_PUBLIC_KEY_CA,
  AFFIRM_TRANSACTIONS_API_PUBLIC_KEY_US,
} from '../../../../app-config';
import { SupportedTLD } from '../../../models/locale';

// TODO: pull from one Affirm key when builds are separated by TLD
export const toAffirmKey = (tld: SupportedTLD, useTransactionAPIKeys = false) => {
  if (tld === SupportedTLD.Ca) {
    return useTransactionAPIKeys
      ? AFFIRM_TRANSACTIONS_API_PUBLIC_KEY_CA
      : AFFIRM_PUBLIC_KEY_CA;
  } else if (tld === SupportedTLD.Au) {
    return AFFIRM_PUBLIC_KEY_AU;
  }

  return useTransactionAPIKeys
    ? AFFIRM_TRANSACTIONS_API_PUBLIC_KEY_US
    : AFFIRM_PUBLIC_KEY_US;
};
