import { track } from '@peloton/analytics';

type EloquaProperties = {
  email: string;
};

type EloquaTraits = {
  event: string;
  properties: EloquaProperties;
};

export default <T extends EloquaTraits>(traits: T) =>
  track(
    Object.assign({}, traits, {
      options: {
        integrations: {
          Eloqua: {
            email: traits.properties.email,
          },
        },
      },
    }),
  );
