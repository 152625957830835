import type { IconNames } from '@pelotoncycle/design-system';
import { Icon } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import type { Locale } from '@peloton/internationalize/models/locale';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import type { CtaWithUrlData } from './models';

type AppLinkProps = {
  iconName: IconNames;
  country: Locale;
} & Omit<CtaWithUrlData, 'key'>;

// TODO: use new Link component when it is ready; may affect how we track
const AppStoreLink: React.FC<React.PropsWithChildren<AppLinkProps>> = ({
  text,
  url,
  iconName,
  country,
}) => {
  const { trackEvent } = useTracking();
  const trackOnClick = () => {
    const trackingParams = {
      event: 'Clicked Footer Link',
      properties: {
        linkCategory: 'appstore logos',
        clickedFrom: getCurrentRelativePathname(),
        country,
        linkName: text,
        linkTo: {
          type: 'social',
          href: url,
        },
      },
    };
    trackEvent(trackingParams);
  };
  if (!iconName) {
    console.warn('Not a valid app store link icon name');
    return null;
  }
  return (
    <li>
      <a href={url} onClick={trackOnClick} target="_blank" rel="noreferrer">
        <Icon name={iconName} />
      </a>
    </li>
  );
};

export default AppStoreLink;
