import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { compose } from 'redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';
import type { LoginForm } from './models';
import OAuthLoginFormRedirectView from './OAuthLoginFormRedirectView';
import { submitLoginForm, FORM_NAME } from './redux';

const mapStateToProps = (state: any, ownProps: any) => ({
  initialValues: {
    email: ownProps.email,
  },
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: FORM_NAME,
    touchOnBlur: false,
    onSubmit(values: Partial<LoginForm>, dispatch: Dispatch) {
      return new Promise<void>((resolve, reject) => {
        dispatch(submitLoginForm(values, resolve, reject));
      });
    },
  }),
)(
  OAuthLoginFormRedirectView as React.FC<
    React.PropsWithChildren<InjectedFormProps & { email: string }>
  >,
);
