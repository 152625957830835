import { createContext } from 'react';

type PromoPanelContextValue = {
  setActivePromo: (promo: any) => void;
  resetActivePromo: VoidFunction;
  overrideToday: Date | undefined;
  setOverrideToday: (date: any) => void;
  resetOverrideToday: VoidFunction;
};

export const PromoPanelContext = createContext<PromoPanelContextValue>({
  setActivePromo: () => {},
  resetActivePromo: () => {},
  overrideToday: undefined,
  setOverrideToday: () => {},
  resetOverrideToday: () => {},
});
