import React from 'react';
import { Route } from '@ecomm/router';
import VerifyUgcEmailPage from './VerifyUgcEmailPage';

export const UGC_VERIFICATION_PATH = '/:ugcType/email-verification';

export default [
  <Route
    key="verify-others-email"
    path={UGC_VERIFICATION_PATH}
    component={VerifyUgcEmailPage}
  />,
];
