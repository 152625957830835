import React from 'react';
import { ProductStatesContext } from './Context';
import type { AvailabilityByProductOrNull } from './Context';
import type { AvailabilityState, AvailabilityByProduct } from './models';
import {
  Product,
  ProductStates,
  isAvailableForPurchase,
  isAvailableForMarketingPages,
  isAvailableForShopPages,
  isUnavailableForPurchase,
} from './models';
import { isUnavailableMarketingLeadCapture } from './models/availability';
import productStateUrlMap from './productStateUrlMap';
import { useProductLineAvailabilityStates } from './queries';

export type VerifyProductState = (p: Product, states: ProductStates[]) => boolean;
export const buildContextAPI = (productStateInUse: AvailabilityByProduct) => {
  const getAvailability = (product: Product) => {
    // TODO: remove this mapping once all RainforestCafe usages are removed
    if (product === Product.RainforestCafe && !productStateInUse[product]) {
      return productStateInUse['guide'];
    }
    return productStateInUse[product];
  };

  const isRouteAvailable = (url: string): boolean => {
    // Due to secret items injected into the Product enum, and the funky data
    // resulting, the types may not actually be right here and the
    // AvailabilityState might be undefined. Passing an empty object as a default
    // should cover that.

    return (
      !isRouteControlledByProductToggles(url) ||
      productStateUrlMap[url](
        product => getAvailability(product) ?? { state: ProductStates.Unknown, code: 0 },
      )
    );
  };

  const verifyProductState: VerifyProductState = (product, acceptedStates): boolean => {
    const availabilityState = getAvailability(product);

    return availabilityState && acceptedStates.includes(availabilityState.state);
  };

  const verifyAvailability = (
    product: Product,
    predicate: (a: AvailabilityState) => boolean,
  ) => {
    const availabilityState = getAvailability(product);
    return availabilityState ? predicate(availabilityState) : false;
  };

  // Is purchasable or has ever been purchasable
  // TODO: [POSEIDON-37] - Create new Product State called UnavailableShopTemporary and
  // use the new state below instead of Lead Capture
  const isProductAvailableForPurchaseOrSoldOut = (product: Product) =>
    isProductAvailableForPurchase(product) ||
    verifyProductState(product, [ProductStates.UnavailableShopSoldOut]) ||
    verifyProductState(product, [ProductStates.UnavailableShopLeadCapture]);

  const isProductAvailableForMarketingPages = (product: Product) =>
    verifyAvailability(product, isAvailableForMarketingPages);

  const isProductUnavailableMarketingLeadCapture = (product: Product) =>
    verifyAvailability(product, isUnavailableMarketingLeadCapture);

  const isProductAvailableForShopPages = (product: Product) =>
    verifyAvailability(product, isAvailableForShopPages);

  const isAnyCFUAvailableForPurchase = () =>
    Object.keys(productStateInUse)
      .filter(p => p !== Product.DigitalApp)
      .some((p: Product) => isProductAvailableForPurchase(p));

  const isProductAvailableForShopLeadCapture = (product: Product) =>
    isProductAvailableForShopPages(product) &&
    verifyProductState(product, [ProductStates.UnavailableShopLeadCapture]);

  const isRouteControlledByProductToggles = (pathname: string) => {
    return Object.prototype.hasOwnProperty.call(productStateUrlMap, pathname);
  };

  const isProductAvailableForPurchase = (product: Product) =>
    verifyAvailability(product, isAvailableForPurchase);

  const isProductUnavailableForPurchase = (product: Product) =>
    verifyAvailability(product, isUnavailableForPurchase);

  const isProductUnavailableSoldOut = (product: Product) =>
    verifyProductState(product, [ProductStates.UnavailableShopSoldOut]);

  return {
    isRouteAvailable,
    verifyProductState,
    verifyAvailability,
    isProductAvailableForMarketingPages,
    isProductAvailableForShopPages,
    isProductAvailableForPurchase,
    isProductAvailableForPurchaseOrSoldOut,
    isRouteControlledByProductToggles,
    isAnyCFUAvailableForPurchase,
    isProductAvailableForShopLeadCapture,
    isProductUnavailableForPurchase,
    isProductUnavailableMarketingLeadCapture,
    isProductUnavailableSoldOut,
  };
};

export const useMappedProductLineAvailability = () => {
  const productLineAvailability = useProductLineAvailabilityStates();

  const ProductLineToProduct = {
    BIKE: Product.Bike,
    BIKEPLUS: Product.BikePlus,
    TREAD: Product.Tread,
    TREADPLUS: Product.TreadPlus,
    DIGITAL: Product.DigitalApp,
    GUIDE: Product.RainforestCafe,
    ROW: Product.Row,
    REFURBBIKE: Product.RefurbBike,
    REFURBBIKEPLUS: Product.RefurbBikePlus,
  };

  return Object.entries(productLineAvailability).reduce(
    (acc, [key, { state, code }]) => ({
      ...acc,
      [ProductLineToProduct[key]]: { state, code },
    }),
    {} as AvailabilityByProduct,
  );
};

const initProductLine = {
  Bike: {
    state: 'undefined',
    code: 400,
  },
  BikePlus: {
    state: 'undefined',
    code: 400,
  },
  Tread: {
    state: 'undefined',
    code: 400,
  },
  DigitalApp: {
    state: 'undefined',
    code: 400,
  },
  Guide: {
    state: 'unavailable',
    code: 0,
  },
  RefurbBike: {
    state: 'undefined',
    code: 400,
  },
  RefurbBikePlus: {
    state: 'undefined',
    code: 400,
  },
};

const CatalogProductStatesProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const mappedProductLineAvailability = useMappedProductLineAvailability();

  if (!mappedProductLineAvailability.Bike) {
    return (
      <BaseProductStatesProvider productStates={initProductLine as AvailabilityByProduct}>
        {children}
      </BaseProductStatesProvider>
    );
  }
  return (
    <BaseProductStatesProvider productStates={mappedProductLineAvailability}>
      {children}
    </BaseProductStatesProvider>
  );
};

const BaseProductStatesProvider: React.FC<
  React.PropsWithChildren<{
    productStates: AvailabilityByProduct;
    overrideUserProductStates?: Boolean | undefined;
  }>
> = ({ children, productStates, overrideUserProductStates = false }) => {
  const [
    userProductStates,
    setUserProductStates,
  ] = React.useState<AvailabilityByProductOrNull>(null);
  // Pull from localStorage if an entry exists AND we don't want to override it
  // (i.e. unit test data takes precedence)
  const productStateInUse =
    userProductStates && !overrideUserProductStates ? userProductStates : productStates;

  const context = {
    ...buildContextAPI(productStateInUse),
    productStates: productStateInUse,
    setProductStates: setUserProductStates,
    areStatesOverridden: Boolean(userProductStates),
  };

  return (
    <ProductStatesContext.Provider value={context}>
      {children}
    </ProductStatesContext.Provider>
  );
};

export const ProductStatesProvider: React.FC<
  React.PropsWithChildren<{
    productState?: AvailabilityByProduct;
  }>
> = ({ children, productState }) => {
  return productState ? (
    // If we have injected productStates (i.e. from unit test data), use those
    <BaseProductStatesProvider
      productStates={productState}
      overrideUserProductStates={true}
    >
      {children}
    </BaseProductStatesProvider>
  ) : (
    <CatalogProductStatesProvider>{children}</CatalogProductStatesProvider>
  );
};
