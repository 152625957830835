export const getSessionStoredValue = (key: string): string | null => {
  if (typeof window === 'undefined') return null;

  return sessionStorage.getItem(key);
};

export const setSessionStoredValue = (key: string, value: string | undefined): void => {
  if (typeof window !== 'undefined' && value !== undefined) {
    sessionStorage.setItem(key, value);
  }
};

export const removeSessionStoredValue = (key: string): void => {
  if (typeof window !== 'undefined') {
    sessionStorage.removeItem(key);
  }
};
