import {
  CP_MTM_SKU,
  CP_TRIAL_SKU,
  STUDENT_MTM_SKU,
} from '@peloton/subscription-plans/skus';
import { Partner } from '@ecomm/checkout';
import { CP_TRIAL_PATH, STUDENT_TRIAL_PATH } from '@ecomm/concessionary-pricing/config';
import type {
  DigitalPromotion,
  Faq,
  FormLabel,
  Image,
  Metadata,
  Product,
  Section,
  ValuePropSet,
} from '@ecomm/copy';
import type { ToggleId } from '@ecomm/feature-toggle';
import type { PaymentsOverrideConfiguration } from '@ecomm/quick-checkout/models';

export type PartnershipConfiguration = {
  path: string;
  skipFreeTrial?: boolean;
  sku: string;
  shouldUsePromoSku: boolean;
  slug: string;
  contentfulKey: DigitalPromotion;
  paymentsOverride?: PaymentsOverrideConfiguration;
  planInfoDisabled?: boolean;
  layout: string;
  toggleId?: ToggleId;
  metaDataKey: Metadata;
  keysToOverride: Array<any>;
  attributionPartner: Partner;
  rolloverSku?: string;
};

export const RouteConfigurations = [
  { path: '/digital/promotions/uhc', toggleId: 'uhcPartnership' },
  { path: '/digital/social-impact', toggleId: 'projectHoney' },
  { path: '/digital/partnerships/lg', toggleId: 'lgTv' },
  { path: '/digital/partnerships/lg/reactivate', toggleId: 'lgTv' },
  { path: STUDENT_TRIAL_PATH },
  { path: CP_TRIAL_PATH },
];

export const getPartnershipBy = (
  prop: 'path' | 'slug' | 'sku',
  value: string,
): PartnershipConfiguration =>
  getPartnershipConfigurations().reduce((acc, config) => {
    const id = config[prop];
    return { [id]: config, ...acc };
  }, {})[value];

const skus = {
  'social-impact': 'pl-a-sub-02-si-24m-ft',
  lg: 'pl-a-sub-02-30d-ft',
  cp: CP_MTM_SKU,
  'cp-trial': CP_TRIAL_SKU,
  student: STUDENT_MTM_SKU,
};

const getPartnershipConfigurations = (): PartnershipConfiguration[] => {
  return [
    {
      path: '/digital/promotions/uhc',
      sku: 'digital-12m-uhc',
      shouldUsePromoSku: false,
      attributionPartner: Partner.Peloton,
      slug: 'uhc',
      paymentsOverride: {
        paymentsDisabled: true,
        taxExempt: true,
      },
      contentfulKey: 'digitalPromotion-uhc',
      metaDataKey: 'digitalCheckout-uhc',
      layout: 'uhc',
      toggleId: 'uhcPartnership',
      planInfoDisabled: true,
      keysToOverride: [
        // eslint-disable-next-line content/no-copy-type-assertions
        'digitalCheckout-twoStep.intro' as Section,
        // eslint-disable-next-line content/no-copy-type-assertions
        'digitalCheckout-twoStep.sidePane' as Image,
        // eslint-disable-next-line content/no-copy-type-assertions
        'digitalSubs' as Faq,
        'flags.membershipTermsAndConditions' as FormLabel,
        'legends.email' as FormLabel,
        // eslint-disable-next-line content/no-copy-type-assertions
        'valuePropSet' as ValuePropSet,
      ],
    },
    {
      path: '/digital/social-impact',
      sku: skus['social-impact'],
      shouldUsePromoSku: false,
      attributionPartner: Partner.Peloton,
      slug: 'social-impact',
      paymentsOverride: {
        paymentsDisabled: true,
        taxExempt: true,
      },
      contentfulKey: 'digitalPromotion-honey',
      metaDataKey: 'digitalCheckout-honey',
      layout: 'honey',
      toggleId: 'projectHoney',
      planInfoDisabled: true,
      keysToOverride: [
        'labels.promoCode' as FormLabel,
        'legends.email' as FormLabel,
        'flags.membershipTermsAndConditions' as FormLabel,
      ],
    },
    {
      path: '/digital/partnerships/lg',
      skipFreeTrial: true,
      sku: skus['lg'],
      shouldUsePromoSku: true,
      attributionPartner: Partner.LG,
      slug: 'lg',
      contentfulKey: 'digitalPromotion-lg',
      metaDataKey: 'digitalCheckout-lg',
      layout: 'tv',
      toggleId: 'lgTv',
      keysToOverride: [
        // eslint-disable-next-line content/no-copy-type-assertions
        'digitalCheckout-twoStep.intro' as Section,
        // eslint-disable-next-line content/no-copy-type-assertions
        'digitalSubs' as Faq,
        'errors.tvCode' as FormLabel,
        'errors.tvCodeRequired' as FormLabel,
        'labels.tvCode' as FormLabel,
        'legends.email' as FormLabel,
        'legends.tvCode' as FormLabel,
        // eslint-disable-next-line content/no-copy-type-assertions
        'valuePropSet' as ValuePropSet,
      ],
    },
    {
      path: '/digital/partnerships/lg/reactivate',
      sku: 'digital-mtm',
      shouldUsePromoSku: false,
      attributionPartner: Partner.LG,
      slug: 'lg',
      contentfulKey: 'digitalPromotion-lg',
      metaDataKey: 'digitalCheckout-lg',
      layout: 'defaultSingle',
      toggleId: 'lgTv',
      keysToOverride: [],
    },
    {
      path: STUDENT_TRIAL_PATH,
      skipFreeTrial: true,
      sku: skus['cp-trial'],
      shouldUsePromoSku: true,
      attributionPartner: Partner.Peloton,
      slug: 'cp-students',
      contentfulKey: 'cp.digitalPromotion-students',
      metaDataKey: 'cp.digitalCheckout-students',
      layout: 'concessionary',
      rolloverSku: skus['student'],
      keysToOverride: [
        // eslint-disable-next-line content/no-copy-type-assertions
        skus['cp-trial'] as Product,
        'digitalCheckout-twoStep.intro' as Section,
        // eslint-disable-next-line content/no-copy-type-assertions
        'digitalCheckout-twoStep.sidePane' as Image,
        // eslint-disable-next-line content/no-copy-type-assertions
        'digitalSubs' as Faq,
        'legends.email' as FormLabel,
        // eslint-disable-next-line content/no-copy-type-assertions
        'valuePropSet' as ValuePropSet,
      ],
    },
    {
      path: CP_TRIAL_PATH,
      skipFreeTrial: true,
      sku: skus['cp-trial'],
      shouldUsePromoSku: true,
      attributionPartner: Partner.Peloton,
      slug: 'cp',
      contentfulKey: 'cp.digitalPromotion-cp',
      metaDataKey: 'cp.digitalCheckout-cp',
      layout: 'concessionary',
      rolloverSku: skus['cp'],
      keysToOverride: [
        // eslint-disable-next-line content/no-copy-type-assertions
        skus['cp-trial'] as Product,
        'digitalCheckout-twoStep.intro' as Section,
        // eslint-disable-next-line content/no-copy-type-assertions
        'digitalCheckout-twoStep.sidePane' as Image,
        // eslint-disable-next-line content/no-copy-type-assertions
        'digitalSubs' as Faq,
        'legends.email' as FormLabel,
        // eslint-disable-next-line content/no-copy-type-assertions
        'valuePropSet' as ValuePropSet,
      ],
    },
  ];
};
