/* Don't import any files from another package here. That will cause unsupported dependencies */

import { createMatchSelector } from 'connected-react-router';
import { benefitOptionsVanityRoute } from '@onewellness/pg-benefit-options';
import { route as benefitsRoutes } from '@onewellness/pg-benefits';
import { hardwarePromotionsRoute } from '@onewellness/pg-member-hardware';
import { BASE_PATH, SHORT_BENEFITS_OPTION_PATH } from '.';

export const match1wRoute = createMatchSelector(BASE_PATH);
export const matchShortBenefitCenterRoute = createMatchSelector(
  SHORT_BENEFITS_OPTION_PATH,
);

const ecommRoutes = [hardwarePromotionsRoute, benefitOptionsVanityRoute, benefitsRoutes];

export { ecommRoutes };
