import type { Time } from '@peloton/time';
import type { ID } from '@ecomm/models';

export enum CoachType {
  PelotonCoach = 'peloton_coach',
  GuestCoach = 'guest_coach',
  ProCyclist = 'pro_cyclist',
}

export const isPelotonInstructor = (coachType: CoachType) =>
  coachType === CoachType.PelotonCoach;

// TODO: get from @members/metadata?
export enum FitnessDiscipline {
  Bike = 'bike',
  Tread = 'tread',
  Yoga = 'yoga',
  Other = 'other',
}

export type CardFitnessDiscipline = Exclude<FitnessDiscipline, FitnessDiscipline.Other>;

export type SocialMediaLinks = {
  facebook?: string;
  instagram?: string;
  strava?: string;
  twitter?: string;
};

export type Instructor = {
  aboutImageSrc: string;
  background: string;
  bio: string;
  coachType: CoachType;
  facebook?: string;
  firstName: string;
  fitnessDisciplines: FitnessDiscipline[];
  footerImageSrc: string;
  heroImageSrc: string;
  id: ID;
  instagram?: string;
  listImageSrc: string;
  name: string;
  orderedQAndAs: QAndA[];
  playlistURL?: string;
  quote: string;
  strava?: string;
  twitter?: string;
  username: string;
};

export type QAndA = {
  question: string;
  answer: string;
};

export type PelotonClass = {
  instructor: ID;
  title: string;
  scheduledTime: Time;
};
