import type { RouteComponentProps } from 'react-router';
import {
  containsDuplicateURL,
  removeDuplicateFromUrl,
} from '@peloton/server-routing/toNetlifyConfig';

const UpdateURL: React.FC<React.PropsWithChildren<RouteComponentProps<any>>> = ({
  history,
  location,
}) => {
  containsDuplicateURL(location.pathname)
    ? history.replace({
        ...location,
        pathname: removeDuplicateFromUrl(location.pathname),
      })
    : history.replace({
        ...location,
        pathname: location.pathname.replace(/\/$/, ''),
      });
  return null;
};

export default UpdateURL;
