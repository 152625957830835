import { oneLineTrim } from '@peloton/text';
import type { ID } from '@ecomm/models';

const WWW_CHECKOUT = '/checkout';
export const AFFIRM_CANCEL_MESSAGE = 'cancel';

export const toConfirmPath = (cartId: ID, errorUrl: string = WWW_CHECKOUT) =>
  oneLineTrim(`/ecomm/affirm/checkout
    ?cart_id=${cartId}
    &success_redirect_url=${encodeURIComponent(toConfirmSuccessRedirectUrl())}
    &error_redirect_url=${encodeURIComponent(toConfirmErrorRedirectUrl(errorUrl))}`);

const toConfirmSuccessRedirectUrl = () => toUrl('/delivery');

const toConfirmErrorRedirectUrl = (route: string) => toUrl(route);

export const toCancelUrl = (route: string = WWW_CHECKOUT) =>
  toUrl(`${route}?message=${AFFIRM_CANCEL_MESSAGE}`);

export const toUrl = (relative: string, { host, protocol } = window.location) =>
  `${protocol}//${host}${relative}`;
