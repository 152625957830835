import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import { Status, toUIState } from '@ecomm/models';
import type { UIState } from '@ecomm/models';

export enum ActionTypes {
  ToggleClassBookmarkRequested = 'onewellness/class-bookmark/TOGGLE_REQUESTED',
  ToggleClassBookmarkSucceeded = 'onewellness/class-bookmark/TOGGLE_SUCCEEDED',
  ToggleClassBookmarkFailed = 'onewellness/class-bookmark/TOGGLE_FAILED',
}

export type State = Record<string, { isBookmarked: boolean } & UIState>;
export const defaultState = {};

const reducer = (state: State = defaultState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.ToggleClassBookmarkRequested:
      return {
        ...state,
        [action.payload.classId]: {
          ...state[action.payload.classId],
          ...toUIState(Status.Loading),
        },
      };
    case ActionTypes.ToggleClassBookmarkSucceeded:
      return {
        ...state,
        [action.payload.classId]: {
          ...state[action.payload.classId],
          isBookmarked: action.payload.isBookmarked,
          ...toUIState(Status.Loaded),
        },
      };

    case ActionTypes.ToggleClassBookmarkFailed: {
      const { exception } = action.payload;
      return {
        ...state,
        [action.payload.classId]: {
          ...state[action.payload.classId],
          ...toUIState(Status.Failed, exception),
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  classBookmarks: State;
};

export const toggleClassBookmark = (
  classId: string,
): ToggleClassBookmarkRequestAction => ({
  type: ActionTypes.ToggleClassBookmarkRequested,
  payload: {
    classId,
  },
});

export const toggleClassBookmarkSucceeded = (
  classId: string,
  isBookmarked: boolean,
): ToggleClassBookmarkSuccessAction => ({
  type: ActionTypes.ToggleClassBookmarkSucceeded,
  payload: {
    classId,
    isBookmarked,
  },
});

export const toggleClassBookmarkFailed = (
  classId: string,
  errorId: string = 'onewellness.errors.toggleClassBookarkFailed',
): ToggleClassBookmarkFailedAction => ({
  type: ActionTypes.ToggleClassBookmarkFailed,
  payload: { classId, exception: toException(errorId) },
});

export type ToggleClassBookmarkRequestAction = {
  type: ActionTypes.ToggleClassBookmarkRequested;
  payload: {
    classId: string;
  };
};
type ToggleClassBookmarkSuccessAction = {
  type: ActionTypes.ToggleClassBookmarkSucceeded;
  payload: {
    classId: string;
    isBookmarked: boolean;
  };
};
type ToggleClassBookmarkFailedAction = {
  type: ActionTypes.ToggleClassBookmarkFailed;
  payload: { classId: string; exception: Exception };
};

export type Actions =
  | ToggleClassBookmarkRequestAction
  | ToggleClassBookmarkSuccessAction
  | ToggleClassBookmarkFailedAction;
