import React from 'react';
import styled from 'styled-components';
import { useIsLocal } from '@peloton/env/hooks';
import { toCloudinaryOrLocalSrc } from '@peloton/images/toCloudinarySrc';
import { BreakpointWidth } from '@peloton/styles';
import type { Props as BTProps, BreakpointOptions } from './BreakpointTransforms';
import type { LazyImageProps } from './LazyImage';
import LazyImage from './LazyImage';

export type Props = React.ImgHTMLAttributes<HTMLImageElement> &
  Pick<BTProps, Exclude<keyof BTProps, 'render'>> &
  LazyImageProps;

const StyledPicture = styled.picture`
  display: block;
  user-select: none;

  img {
    object-fit: cover;
    user-select: none;
    vertical-align: middle; // This property addresses an issue where the picture element will expand to be taller than the img it contains.
  }
`;

const possibleOptionKeysInOrder = ['mobile', ...Object.keys(BreakpointWidth)];

const sortBreakpointOptionsByMediaWidthAscending = (
  breakpointOptions: BreakpointOptions,
) => {
  return possibleOptionKeysInOrder.reduce(
    (accumulator, breakpointName) => {
      const optionsProvidedForBreakpoint = breakpointOptions[breakpointName];

      if (optionsProvidedForBreakpoint) {
        accumulator[breakpointName] = optionsProvidedForBreakpoint;
      }

      return accumulator;
    },
    { mobile: { ...breakpointOptions.mobile } },
  );
};

const buildComprehensiveDependencyArrayForUseMemo = (
  sortedBreakpointOptions: BreakpointOptions,
  src: string,
) => {
  return possibleOptionKeysInOrder.reduce(
    (dependencyArray, breakpointName) => {
      const optionsForBreakpoint = sortedBreakpointOptions[breakpointName] || {};

      return dependencyArray.concat([
        optionsForBreakpoint.width,
        optionsForBreakpoint.src,
      ]);
    },
    [src],
  );
};

const useGenerateSourceElements = (
  breakpointOptions: BreakpointOptions,
  src: string,
  isLocal: boolean,
) => {
  const sortedBreakpointOptions = sortBreakpointOptionsByMediaWidthAscending(
    breakpointOptions,
  );

  const memoizationDependencies = buildComprehensiveDependencyArrayForUseMemo(
    sortedBreakpointOptions,
    src,
  );

  return React.useMemo(() => {
    let nextLowestIdentifiedSrc = src;
    const sourceElements = Object.keys(sortedBreakpointOptions).map(breakpointName => {
      const breakpointOption = sortedBreakpointOptions[breakpointName];

      if (breakpointOption.src) {
        nextLowestIdentifiedSrc = breakpointOption.src;
      }

      breakpointOption.src = nextLowestIdentifiedSrc;

      const cloudinarySrc = toCloudinaryOrLocalSrc(isLocal, breakpointOption);

      const media = `(min-width: ${BreakpointWidth[breakpointName]}px)`;

      return breakpointName === 'mobile' ? null : (
        <source
          className={breakpointName}
          key={breakpointName}
          media={media}
          srcSet={cloudinarySrc}
        />
      );
    });

    // while our sources fall back to the lower srcs (hence the sort above), we render the sources
    // in media query width descending, as the browser renders the media match it finds first
    return sourceElements.reverse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, memoizationDependencies);
};

const PerformantImage: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  loading,
  layout,
  ...props
}) => {
  const isLocal = useIsLocal();
  const sourceElements = useGenerateSourceElements(
    props.breakpointOptions,
    props.src,
    isLocal,
  );

  const mobileImgOptions = { src: props.src, ...props.breakpointOptions?.mobile };
  const cloudinarifiedImgSrc = toCloudinaryOrLocalSrc(isLocal, mobileImgOptions);

  return (
    <StyledPicture className={className}>
      {sourceElements}

      {loading !== 'lazy' ? (
        <img alt={props.alt} role={props.role} src={cloudinarifiedImgSrc} />
      ) : (
        <LazyImage
          alt={props.alt}
          height={props.height}
          layout={layout}
          loading="lazy"
          role={props.role}
          src={cloudinarifiedImgSrc}
          width={props.width}
        />
      )}
    </StyledPicture>
  );
};

export default PerformantImage;
