import React from 'react';
import styled from 'styled-components';
import type { InputComponent, InputProps, StyledCss } from './types';

export type Props = InputProps & {
  showPlaceholder?: boolean;
  showAsterisk?: boolean;
};

export type LabelProps = {
  hasText: boolean;
  isActive: boolean;
};

const toInputWithLabel = (
  Input: InputComponent<InputProps>,
  labelStyles: StyledCss<LabelProps>,
) => {
  const LabelText = styled.span<LabelProps>`
    ${
      /*
       * TODO: [@types/styled-components] re-evaluate these typecasts
       * This looks like an issue with @types/styled-components but is blocked by https://github.com/DefinitelyTyped/DefinitelyTyped/issues/33015
       * Once the issue#33015 has been resolved and we can upgrade @types/styled-components, we should be able to remove this cast
       * */
      labelStyles as any
    }
  `;

  const InputWithLabel: React.FC<React.PropsWithChildren<Props>> = ({
    isActive = false,
    className,
    label,
    required,
    placeholder,
    showPlaceholder = true,
    showAsterisk = true,
    ...props
  }) => (
    <label className={className}>
      <LabelText isActive={isActive} hasText={Boolean(props.value)}>
        {label}
        {showAsterisk && required && '*'}
      </LabelText>

      <Input
        {...props}
        label={label}
        required={required}
        placeholder={showPlaceholder ? placeholder : undefined}
      />
    </label>
  );

  InputWithLabel.displayName = 'InputWithLabel';

  return InputWithLabel;
};

export default toInputWithLabel;
