import { asyncComponent } from '@peloton/code-splitting';
import { reducer, popupClosed } from './redux';
import type { Closed, ActionTypes } from './redux';

const ConcessionaryPopup = asyncComponent(
  () => import(/* webpackChunkName: "ConcessionaryPopup" */ './ConcessionaryPopup'),
);

export { ConcessionaryPopup, reducer, popupClosed };
export type { Closed, ActionTypes };
