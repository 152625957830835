import { EquipmentType } from '@ecomm/graphql/types.generated';
import { BundleType } from './BundleType';

const equipmentToBundleType = {
  [BundleType.Bike]: EquipmentType.Bike,
  [BundleType.BikePlus]: EquipmentType.Bikeplus,
  [BundleType.RainforestCafe]: EquipmentType.Guide,
  [BundleType.Tread]: EquipmentType.Tread,
  [BundleType.TreadPlus]: EquipmentType.Treadplus,
  [BundleType.Row]: EquipmentType.Row,
  [BundleType.RefurbishedBike]: EquipmentType.Refurbbike,
  [BundleType.RefurbishedBikePlus]: EquipmentType.Refurbbikeplus,
} as const;

const bundleToEquipmentType = Object.entries(equipmentToBundleType).reduce(
  (acc, [k, v]) => ({ ...acc, [v]: k }),
  {},
);

export const toEquipmentTypeFromBundleType = (bundleType: BundleType) =>
  equipmentToBundleType[bundleType];

export const toBundleTypeFromEquipmentType = (e: EquipmentType) =>
  bundleToEquipmentType[e];
