import { combineReducers } from 'redux';
import type { ReducerState as CartState } from './cart';
import cartReducer from './cart';
import type { ReducerState as CouponState } from './coupon';
import couponReducer from './coupon';
import type { ReducerState as PanelState } from './panel';
import panelReducer from './panel';
import type { ReducerState as UIState } from './ui';
import uiReducer from './ui';

type State = CouponState & CartState & PanelState & UIState;

export default combineReducers({
  panel: panelReducer,
  cart: cartReducer,
  coupon: couponReducer,
  ui: uiReducer,
});

export type ReducerState = {
  cartSummary: State;
};
