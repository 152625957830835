import type {
  ErrorProperties,
  ErrorReporterFactory,
  ReportError,
} from '../ErrorReporter';
import reporterClient from './datadogReporterClient';

const createDatadogErrorReporter = (): ReportError => (
  error: Error,
  properties?: ErrorProperties,
) => reporterClient.addError(error, properties);

const datadogReportErrorFactory: ErrorReporterFactory = () => ({
  reportError: createDatadogErrorReporter(),
});

export default datadogReportErrorFactory;
