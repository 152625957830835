import { grey, spacing, white } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import asyncComponent from '@peloton/code-splitting/asyncComponent';
import type { NavigationData } from '@ecomm/copy/helpers/types';
import Header from './Header';
import type { CloseHandlers } from './NavFlyout';
import SubMenuItemWithFlyout from './SubMenuItemWithFlyout';

const PrimaryLinksContainer = styled.ul`
  margin-top: ${spacing[40]};
  li {
    display: flex;
    align-items: center;
    margin-bottom: ${spacing[32]};
    color: ${white};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Nav = styled.nav`
  padding: 0 1.5rem 3rem;
`;

const NavFlyout = asyncComponent(
  () => import(/* webpackChunkName: "ModalChunk", webpackPrefetch: true */ './NavFlyout'),
);

type Props = {
  isOpen: boolean;
  onCloseRequest: () => void;
  onBackRequest: () => void;
  data: Pick<NavigationData, 'key' | 'title' | 'subnavs'>;
};

const ApparelSecondaryMenuFlyout: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onCloseRequest,
  onBackRequest,
  data: { key, title, subnavs },
}) => {
  return (
    <NavFlyout
      isOpen={isOpen}
      ariaLabel="navigation"
      backgroundColor={grey[90]}
      handleRequestClose={onCloseRequest}
      handleRequestBack={onBackRequest}
    >
      {({ handleRequestClose, handleRequestBack }: CloseHandlers) => (
        <Container>
          <Header
            heading={title}
            handleClose={handleRequestClose}
            handleBack={handleRequestBack}
          />
          <Nav>
            <PrimaryLinksContainer>
              {subnavs.map(nav => {
                return (
                  <SubMenuItemWithFlyout
                    key={nav.key}
                    apparelData={nav}
                    onSubCloseRequest={handleRequestClose}
                  ></SubMenuItemWithFlyout>
                );
              })}
            </PrimaryLinksContainer>
          </Nav>
        </Container>
      )}
    </NavFlyout>
  );
};

export default ApparelSecondaryMenuFlyout;
