import { Body } from '@pelotoncycle/design-system';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSectionData } from '@ecomm/copy';
import { Prerender404Tags } from '@ecomm/metadata';
import { PageSpinner } from '@ecomm/spinner';
import { Content } from './sharedErrorComponents';

const GeoModalError: React.FC<React.PropsWithChildren<unknown>> = () => {
  const history = useHistory();
  const geoModalErrorCopy = useSectionData('geoModalError');

  useEffect(() => {
    setTimeout(() => {
      history.push('/');
    }, 5000);
  }, [history]);

  return (
    <>
      <Prerender404Tags />
      <StyledContent>
        <StyledPageSpinner />
        <StyledBody size="medium">{geoModalErrorCopy.title}</StyledBody>
      </StyledContent>
    </>
  );
};

const StyledContent = styled(Content)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPageSpinner = styled(PageSpinner)`
  margin-bottom: 24px;
`;

const StyledBody = styled(Body)`
  max-width: 400px;
  margin: auto;
`;

export default GeoModalError;
