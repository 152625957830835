import { asyncComponent } from '@peloton/code-splitting';

import HomepageHeroCarousel from './HomepageHeroCarousel';
import LeadGenBlock from './LeadGenBlock';
import ProductBellyBand from './ProductBellyBand';
import TryPelotonSectionWithBorder from './TryPelotonSectionWithBorder';

const PortfolioHomepageValuesSection = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "PortfolioHomepageValuesSection", webpackPrefetch: true */ './PortfolioHomepageValuesSection'
    ),
);

const TryPelotonSection = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "TryPelotonSection", webpackPrefetch: true */ './TryPelotonSection'
    ),
);

export {
  HomepageHeroCarousel,
  LeadGenBlock,
  PortfolioHomepageValuesSection,
  ProductBellyBand,
  TryPelotonSection,
  TryPelotonSectionWithBorder,
};
