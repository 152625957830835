import { put, takeEvery } from 'redux-saga/effects';
import { openLoginModal } from '@ecomm/login-modal';
import type { UserExistsAction } from '@ecomm/registration/redux';
import { RegistrationUserActionTypes } from '@ecomm/registration/redux';
import { toCheckRouteSaga } from '@ecomm/saga-utils';
import { matchRegisterFreeTrial as matcher } from '../route';

export const openModal = function* (action: UserExistsAction) {
  const {
    payload: { email },
  } = action;
  yield put(openLoginModal(email));
};

export const checkRouteAndOpenModal = toCheckRouteSaga({ matcher, onMatch: openModal });

const watcher = function* () {
  yield takeEvery(RegistrationUserActionTypes.UserExists, checkRouteAndOpenModal);
};

export default watcher;
