import { brand, grey } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Markdown } from '@peloton/copy';
import { withExtHrefs } from '@peloton/external-links';
import { Links } from '@peloton/links';
import { media } from '@peloton/styles';
import type { HeaderBodyImage, PromotionalText, KeyValue } from '@ecomm/copy';
import { useHeaderBodyImageData, usePromotionalTextData } from '@ecomm/copy';
import { SuccessMessage } from '@ecomm/email-capture/EmailCaptureForm';
import type { PrivacyProps } from '@ecomm/email-capture/PrivacyNotice';
import { PrivacyNotice } from '@ecomm/email-capture/PrivacyNotice';
import { FormattedMarkdown } from '@ecomm/formatted-markdown';
import { Image } from '@ecomm/image';
import { InlineLink } from '@ecomm/links';
import { b6, b7, hb3 } from '@ecomm/typography';
import LeadCapture from './LeadCapture';

type Props = {
  id: HeaderBodyImage;
  footnoteId?: PromotionalText;
  leadCapturePrivacyKey?: KeyValue;
};

type SegmentProps = {
  unitName: string;
  campaignId?: string;
  campaignName?: string;
  productInterest?: string;
};

type FinancingProps = {
  financingLink: string;
};

const View: React.FC<
  React.PropsWithChildren<Props & SegmentProps & PrivacyProps & FinancingProps>
> = ({
  privacyPolicyLink,
  financingLink,
  id,
  footnoteId = undefined,
  leadCapturePrivacyKey = 'leadCapture.privacy',
  ...props
}) => {
  const { header, body, image } = useHeaderBodyImageData(id);

  return (
    <Container>
      <StyledImage
        src={image.mobile.url}
        alt={image.mobile.description}
        breakpointOptions={{
          mobile: { width: 600 },
          tablet: {
            width: 800,
            src: image.tablet.url,
          },
          desktop: {
            width: 800,
            src: image.desktop.url,
          },
        }}
      />
      <ContentContainer>
        <Header>{header}</Header>
        <Body>{body}</Body>
        <LeadCapture {...props} />
        <StyledPrivacyNotice>
          <FormattedMarkdown
            id={leadCapturePrivacyKey}
            values={{ privacyPolicyLink }}
            markdown={{
              renderers: {
                link: InlineLink,
              },
            }}
          />
        </StyledPrivacyNotice>
        {footnoteId && (
          <FootnoteCopy footnoteId={footnoteId} financingLink={financingLink} />
        )}
      </ContentContainer>
    </Container>
  );
};

const FootnoteCopy: React.FC<
  React.PropsWithChildren<{ footnoteId: PromotionalText; financingLink: string }>
> = ({ footnoteId, financingLink }) => {
  const footnote = usePromotionalTextData(footnoteId);
  const renderers = { paragraph: React.Fragment };
  return (
    <Footnote>
      <Markdown content={footnote} markdown={{ renderers }} values={{ financingLink }} />
    </Footnote>
  );
};

export default withExtHrefs({
  privacyPolicyLink: Links.privacy,
  financingLink: Links.financing,
})(View);

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  form {
    width: 100%;

    > div {
      display: flex;
      justify-content: center;
      flex-direction: column;

      ${media.tablet`
        > div {
          justify-content: center;
          max-width: 100%;
        }
      `}
      input[type='email'] {
        &:required {
          &:not(:disabled) {
            &:not(:focus) {
              &:not([data-user-focused='true']) {
                &:not([data-has-error='true']) {
                  border-color: ${grey[70]};
                }
              }
            }
          }
        }
      }
    }

    ${SuccessMessage} {
      margin: 15px 0 0;
      color: ${brand.darkest};
    }

    input {
      margin-bottom: 0px;
      width: 100%;
    }

    button {
      margin: 10px 0 0;

      ${media.smallScreen`
        margin-top: 0px;
      `}
    }
  }
`;

const ContentContainer = styled.div`
  padding: 40px 20px;

  ${media.smallScreen`
    margin-top: 0px;
  `}

  ${media.tablet`
    padding: 40px;
  `}

  ${media.desktop`
    padding: 40px 60px;
  `}
`;

const StyledImage = styled(Image)`
  width: 100%;
`;

const Header = styled.h1`
  ${hb3}
  margin-bottom: 20px;
`;

const Body = styled.p`
  line-height: 1.5;
  margin-bottom: 40px;

  ${media.tablet`
    max-width: none;
  `}
`;

const Footnote = styled.p`
  ${b7}

  ${media.tablet`
    ${b6}
  `}
`;

const StyledPrivacyNotice = styled(PrivacyNotice)`
  margin-top: 40px;
`;
