import type { RouterRootState } from 'connected-react-router';
import {
  isFinancingPage,
  isHomeTrialPage,
  isBikeMarketingPage,
  isBikePlusMarketingPage,
  isClassesMarketingPage,
  isTreadMarketingPage,
  isGuideMarketingPage,
  isRowMarketingPage,
  isMembershipMarketingPage,
} from '@ecomm/router';
import type { ReducerState } from './redux';
import { ModalVariation } from './redux';

const getPromoModal = (state: ReducerState) => state.promoModal;

export const getOpenModal = (state: ReducerState) => getPromoModal(state).modal;

export const getDismissed = (state: ReducerState) => getPromoModal(state).dismissed;

export const getTrigger = (state: ReducerState) => getPromoModal(state).trigger;

export const modalVariationFromPath = (
  state: RouterRootState,
): ModalVariation | undefined => {
  if (isBikeMarketingPage(state)) {
    return ModalVariation.Bike;
  } else if (isBikePlusMarketingPage(state)) {
    return ModalVariation.BikePlus;
  } else if (isTreadMarketingPage(state)) {
    return ModalVariation.Tread;
  } else if (isClassesMarketingPage(state)) {
    return ModalVariation.Classes;
  } else if (isGuideMarketingPage(state)) {
    return ModalVariation.Guide;
  } else if (isRowMarketingPage(state)) {
    return ModalVariation.Row;
  } else if (isHomeTrialPage(state)) {
    return ModalVariation.HomeTrial;
  } else if (isMembershipMarketingPage(state)) {
    return ModalVariation.Membership;
  } else if (isFinancingPage(state) || isHomeTrialPage(state)) {
    return ModalVariation.Generic;
  } else {
    return;
  }
};
