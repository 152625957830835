import React from 'react';
import { Redirect } from 'react-router';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

// NOTE: It's important that the chunk name matches the chunk name in
// pg-shop-accessories so that the code chunk includes both pages.
const routes = [
  <Route
    key="shop-accessories-product"
    path="/shop/accessories/"
    component={asyncComponent(
      () => import('./Handler' /* webpackChunkName: "ShopAccessories" */),
    )}
  />,
  <Redirect
    key="legacy-accessories-product-redirect"
    exact={true}
    from="/shop/accessories/:category(bike|tread-bootcamp)/:productSlug"
    to="/shop/accessories/:productSlug"
  />,
];

export default routes;
