import type { ClientError } from '@peloton/api';
import { isHttpError } from '@peloton/api';

export enum ErrorCode {
  Unauthenticated = 'Unauthenticated',
  Default = 'default',
}

const errorMap = {
  3020: ErrorCode.Unauthenticated,
};

export const toErrorCode = (error: ClientError): ErrorCode => {
  // this endpoint has the error on the errorCode property (vs. code like all the others)
  const errorCode = isHttpError(error) ? error.responseBody.errorCode : undefined;

  return errorMap[errorCode] || ErrorCode.Default;
};
