import type {
  RentalLocale,
  RentalPrices,
} from '@peloton/internationalize/consolidatedAppConfigs/models';
import { FAAS_LOCALES } from '@peloton/internationalize/consolidatedAppConfigs/models';
import {
  getBikePlusRentalPrice,
  getBikeRentalPrice,
} from '@peloton/internationalize/consolidatedAppConfigs/utils';
import type { Locale } from '@peloton/internationalize/models/locale';
import useContentRentalPrices from '@content/client/www/rental-prices/useRentalPrices';

const rentalEquipmentTypes = ['BIKE', 'BIKE_PLUS'] as const;

export const useRentalPrices = (
  equipment: typeof rentalEquipmentTypes[number],
  locale: Locale,
): RentalPrices => {
  const rentalLocale = locale as RentalLocale;

  const isValidLocale = FAAS_LOCALES.includes(rentalLocale);
  const isValidCfu = rentalEquipmentTypes.includes(equipment);

  const { isLoading, content: contentRentalPrices } = useContentRentalPrices(locale);

  if (!isValidLocale || !isValidCfu) {
    return {
      monthly: 0,
      setup: 0,
    };
  }

  if (isLoading) {
    if (equipment === 'BIKE') {
      return getBikeRentalPrice(rentalLocale);
    }
    if (equipment === 'BIKE_PLUS') {
      return getBikePlusRentalPrice(rentalLocale);
    }
  }

  const prices = contentRentalPrices[equipment];

  return {
    ...prices,
    buyout: prices.buyout.toLocaleString(locale),
  };
};
