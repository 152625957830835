import { combineReducers } from 'redux';
import type { ReducerState as BundlesState } from './bundles';
import bundles from './bundles';
import type { ReducerState as CategoriesState } from './categories';
import categories from './categories';
import type { ReducerState as ConfigureState } from './configure';
import configure from './configure';
import type { ReducerState as DimensionsState } from './dimensions';
import dimensions from './dimensions';
import type { ReducerState as ProductOptionsState } from './productOptions';
import productOptions from './productOptions';
import type { ReducerState as ProductState } from './products';
import products from './products';
import type { ReducerState as TraitsState } from './traits';
import traits from './traits';
import type { ReducerState as UIReducerState } from './ui';
import ui from './ui';

export type State = BundlesState &
  CategoriesState &
  DimensionsState &
  ConfigureState &
  ProductOptionsState &
  ProductState &
  TraitsState &
  UIReducerState;

export default combineReducers({
  bundles,
  categories,
  configure,
  dimensions,
  productOptions,
  products,
  traits,
  ui,
});

export type ReducerState = {
  shop: State;
};
