import type { Reducer } from 'redux';

export enum Actions {
  Open = 'pelo/cart/panel/OPEN',
  Close = 'pelo/cart/panel/CLOSE',
}

export type State = {
  isOpen: boolean;
};

export const defaultState: State = {
  isOpen: false,
};

export type OpenAction = {
  type: Actions.Open;
};

export type CloseAction = {
  type: Actions.Close;
};

type Action = OpenAction | CloseAction;

export const reducer: Reducer<State> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case Actions.Open: {
      return {
        ...state,
        isOpen: true,
      };
    }
    case Actions.Close: {
      return {
        ...state,
        isOpen: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

export type ReducerState = {
  panel: State;
};

export const openCartPanel = (): Action => ({
  type: Actions.Open,
});

export const closeCartPanel = (): Action => ({
  type: Actions.Close,
});
