import { replace } from 'connected-react-router';
import { propEq } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, select, take, takeEvery } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import type { Credentials } from '@peloton/auth';
import { UserReducerActionType, logIn } from '@peloton/auth';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import { getUserTrackingProperties } from '@ecomm/auth';
import type { Exception } from '@ecomm/exceptions/Exception';
import { LOGIN_FAILED } from '@ecomm/registration/models';
import { getRegistrationData, hasRegistrationErrors } from '@ecomm/registration/redux';
import { applyBenefit } from '@onewellness/api';
import { cw_register } from '@onewellness/api/registerUser';
import type { Partner } from '@onewellness/models';
import type { RequestRegisterAction } from '@onewellness/redux';
import {
  applyBenefitSucceeded,
  applyBenefitFailed,
  requestApplyBenefit,
  getPartner,
  failRegistration,
  RegisterActionTypes,
  succeedRegistration,
} from '@onewellness/redux';
import type { Audience } from '@onewellness/routes';
import { Page, toPathWithAudience } from '@onewellness/routes';
import {
  ClaimBenefitStepName,
  trackApplyBenefitSuccess,
  trackApplyBenefitFailure,
  trackCreateAccountSuccess,
} from '@onewellness/segment';

export const applyBenefitSaga = function* ({
  payload: { audience, token },
}: RequestRegisterAction): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  const partner = yield select(getPartner);

  yield put(requestApplyBenefit(audience, token, partner));

  try {
    yield call(applyBenefit, client, token);
    yield all([
      put(applyBenefitSucceeded()),
      call(trackSuccess, audience, partner),
      put(replace(toPathWithAudience(Page.BenefitOptions, audience))),
    ]);
  } catch (error) {
    const report = new DomainError(error.message, { ...error, name: 'ApplyBenefit' });
    const errorId = `errors.${error.message}`;
    yield all([
      put(applyBenefitFailed(errorId)),
      put(failRegistration(errorId)),
      put(reportError({ error: report })),
      call(trackFailure, audience, { id: errorId }, partner),
    ]);
  }
};

export const trackSuccess = function* (
  audience: Audience,
  partner?: Partner,
): SagaIterator {
  const userTrackingProperties = yield select(getUserTrackingProperties);

  yield call(trackApplyBenefitSuccess, {
    audience,
    userTrackingProperties,
    stepName: ClaimBenefitStepName,
    partner,
  });
};

export const trackFailure = function* (
  audience: Audience,
  error?: Exception,
  partner?: Partner,
): SagaIterator {
  const userTrackingProperties = yield select(getUserTrackingProperties);

  yield call(trackApplyBenefitFailure, {
    audience,
    userTrackingProperties,
    exception: error,
    partner,
  });
};

export const loginUser = function* (
  credentials: Credentials,
  registerAction: RequestRegisterAction,
): SagaIterator {
  const partner = yield select(getPartner);
  const userTrackingProperties = yield select(getUserTrackingProperties);

  yield put(logIn(credentials));

  while (true) {
    const action = yield take([
      UserReducerActionType.LOGIN_FAILURE,
      // NOTE: LOGIN_SUCCESS is unreliable as it doesn't update the local state
      UserReducerActionType.REQUEST_SUCCESS,
    ]);

    if (isSuccess(action)) {
      yield all([
        put(succeedRegistration()),
        call(trackCreateAccountSuccess, {
          audience: registerAction.payload.audience,
          userTrackingProperties,
          email: credentials.email,
          partner,
        }),
        call(applyBenefitSaga, registerAction),
      ]);
    } else {
      yield put(failRegistration(LOGIN_FAILED));
    }
  }
};

export const registerUser = function* (action: RequestRegisterAction): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  const data = yield select(getRegistrationData);

  try {
    yield call(cw_register, client, { ...data, token: action.payload.token });
    yield call(loginUser, { email: data.email, password: data.password }, action);
  } catch (error) {
    yield put(failRegistration(error.message));
  }
};

export const validateSaga = function* (action: RequestRegisterAction): SagaIterator {
  const hasErrors = yield select(hasRegistrationErrors);

  if (hasErrors) {
    yield put(failRegistration('errors.incomplete'));
  } else {
    yield call(registerUser, action);
  }
};

const watcherSaga = function* () {
  yield takeEvery(RegisterActionTypes.RegistrationRequested, validateSaga);
};

export default watcherSaga;

const isSuccess = propEq('type', UserReducerActionType.REQUEST_SUCCESS);
