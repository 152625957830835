import React from 'react';
import { track } from '@peloton/analytics';
import { Markdown } from '@peloton/copy';
import { useBannerReference } from '@acme-ui/global';
import { Link } from '@ecomm/links';
import EcommBanner from './EcommBanner';
import type { BannerPropsCta } from './models';

const DualCtaBanner: React.FC<React.PropsWithChildren<BannerPropsCta>> = ({
  text,
  theme,
  links,
  dataTestIdPrefix,
  estimates,
}) => {
  const bannerRef = useBannerReference();

  const [{ text: linkText1, url: linkUrl1 }, { text: linkText2, url: linkUrl2 }] = links;

  const BannerText: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
    <span data-test-id={`${dataTestIdPrefix}Text`}>{children}</span>
  );

  const BannerLink: React.FC<
    React.PropsWithChildren<{ href: string; index: number }>
  > = ({ children, href, index }) => (
    <Link
      to={href}
      data-test-id={`${dataTestIdPrefix}Cta${index + 1}`}
      onClick={() =>
        track({
          event: 'Clicked dual product banner',
          properties: { linkTo: href },
        })
      }
    >
      {children}
    </Link>
  );

  return (
    <EcommBanner ref={bannerRef} theme={theme}>
      <Markdown
        content={text}
        values={{
          link1: `[${linkText1}](${linkUrl1})`,
          link2: `[${linkText2}](${linkUrl2})`,
          ...estimates,
        }}
        markdown={{
          renderers: {
            paragraph: BannerText,
            // eslint-disable-next-line react/display-name
            link: ((i = 0) => (props: any) => <BannerLink {...props} index={i++} />)(),
          },
        }}
      />
    </EcommBanner>
  );
};

export default DualCtaBanner;
