import {
  COMMERCETOOLS_CLIENT_ID,
  COMMERCETOOLS_CLIENT_SECRET,
  COMMERCETOOLS_PROJECT_KEY,
  COMMERCETOOLS_REGION,
  COMMERCETOOLS_CLIENT_ID_PROD,
  COMMERCETOOLS_CLIENT_SECRET_PROD,
  COMMERCETOOLS_PROJECT_KEY_PROD,
  COMMERCETOOLS_REGION_PROD,
} from '@peloton/app-config';
import { isProdEnv, isProdTestEnv, isUatProdEnv } from '@peloton/env/models';
import toApiEnv from '@peloton/env/toApiEnv';
import { getApiCluster } from '@peloton/external-links/models';

export type CommercetoolsClientConfig = {
  region: string;
  projectKey: string;
  clientId: string;
  clientSecret: string;
};

export const getCommercetoolsClientConfig = (): CommercetoolsClientConfig => {
  if (typeof window === 'undefined') {
    return {
      region: COMMERCETOOLS_REGION!,
      projectKey: COMMERCETOOLS_PROJECT_KEY!,
      clientId: COMMERCETOOLS_CLIENT_ID!,
      clientSecret: COMMERCETOOLS_CLIENT_SECRET!,
    };
  }

  const hostname = window.location.hostname;
  const apiEnv = toApiEnv(hostname, {
    isProd: isProdEnv('www', hostname),
    isUatProd: isUatProdEnv(hostname),
    isProdTest: isProdTestEnv('www', hostname),
  });
  const cluster = getApiCluster(apiEnv);

  if (cluster === 'prod') {
    return {
      region: (COMMERCETOOLS_REGION_PROD || COMMERCETOOLS_REGION)!,
      projectKey: (COMMERCETOOLS_PROJECT_KEY_PROD || COMMERCETOOLS_PROJECT_KEY)!,
      clientId: (COMMERCETOOLS_CLIENT_ID_PROD || COMMERCETOOLS_CLIENT_ID)!,
      clientSecret: (COMMERCETOOLS_CLIENT_SECRET_PROD || COMMERCETOOLS_CLIENT_SECRET)!,
    };
  }

  return {
    region: COMMERCETOOLS_REGION!,
    projectKey: COMMERCETOOLS_PROJECT_KEY!,
    clientId: COMMERCETOOLS_CLIENT_ID!,
    clientSecret: COMMERCETOOLS_CLIENT_SECRET!,
  };
};

export const toCommercetoolsAuthUrl = (): string => {
  const { region, projectKey } = getCommercetoolsClientConfig();

  return `https://auth.${region}.gcp.commercetools.com/oauth/${projectKey}/anonymous/token?grant_type=client_credentials&scope=view_products:${projectKey}`;
};

export const toCommercetoolsAuthToken = (): string => {
  const { clientId, clientSecret } = getCommercetoolsClientConfig();

  return `Basic ${btoa(`${clientId}:${clientSecret}`)}`;
};

export const toCommercetoolsApiUrl = (): string => {
  const { region, projectKey } = getCommercetoolsClientConfig();

  return `https://api.${region}.gcp.commercetools.com/${projectKey}/graphql`;
};
