import type { Locale } from '@peloton/internationalize/models';
import useAllBundlePrices from '@ecomm/pricing/hooks/useAllBundlePrices';
import { BundleType } from '@ecomm/shop/models/BundleType';

// Hook version of toDefaultState

type TotalPrices = {
  [BundleType.Bike]: number;
  [BundleType.BikePlus]: number;
  [BundleType.Tread]: number;
  [BundleType.TreadPlus]: number;
  [BundleType.RainforestCafe]: number;
  [BundleType.Row]: number;
  [BundleType.RefurbishedBike]: number;
  [BundleType.RefurbishedBikePlus]: number;
};

export type State = {
  totalPrices: TotalPrices;
};

const bundles = [
  BundleType.Bike,
  BundleType.BikePlus,
  BundleType.Tread,
  BundleType.TreadPlus,
  BundleType.RainforestCafe,
  BundleType.Row,
  BundleType.RefurbishedBike,
  BundleType.RefurbishedBikePlus,
];

const useDefaultState = (locale: Locale): State => {
  const bundlePrices = useAllBundlePrices(locale);

  const totalPrices = bundles.reduce(
    (bundleTotals, bundle) => ({
      ...bundleTotals,
      ...{
        [bundle]: bundlePrices.getLowestPackagePriceByBundle(bundle),
      },
    }),
    {} as TotalPrices,
  );

  return {
    totalPrices,
  };
};

export default useDefaultState;
