import type { SagaIterator } from 'redux-saga';
import { put, select, takeEvery } from 'redux-saga/effects';
import { getShowOptOut } from '@ecomm/checkout/redux/selectors';
import { updateRegistrationField } from '../redux';
import { ActionTypes } from '../redux/user';

export const resolveDefaultMarketingState = function* (): SagaIterator {
  const hasSoftOptIn = yield select(getShowOptOut);

  if (hasSoftOptIn) {
    yield put(updateRegistrationField('allowMarketing', true));
  }
};

const watcherSaga = function* () {
  yield takeEvery(ActionTypes.ResolveDefaultMarketingState, resolveDefaultMarketingState);
};

export default watcherSaga;
