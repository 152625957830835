import React from 'react';

import styled from 'styled-components';

import type { Locale } from '@peloton/internationalize/models';

import { media } from '@peloton/styles';
import { StandardButtonSize, toFullButtonStyles } from '@ecomm/buttons';
import type {
  CtaWithUrl,
  HeaderBodyImage,
  KeyValue,
  LegalText,
  Product,
  PromotionalText,
} from '@ecomm/copy';
import { useKeyValueData } from '@ecomm/copy';

import type { LinkProps } from '@ecomm/links';
import { Link } from '@ecomm/links';
import { Modal, TwoColumnModal } from '@ecomm/modal'; // TODO: import new lead gen modal instead
import { PATH as appTrialRoute } from '@ecomm/pg-register-free-trial';
import { PATH as bikeTrialRoute } from '@ecomm/pg-register-free-trial-bike';
import { PATH as treadTrialRoute } from '@ecomm/pg-register-free-trial-tread';
import { CtaPromoModal } from '../CTAPromoModal';
import { bikeImg, treadImg } from '../img';
import { LeadCaptureModal } from '../LeadCaptureModal';
import type { ModalTrigger } from '../modal-trigger';
import PromoModal from '../PromoModal';
import { ModalVariation } from '../redux';
import { modalPriority, useModalConfigs } from './config';

type ModalType = 'leadCapture' | 'promoModal' | 'ctaModal' | 'twoColumnModal';

type LeadCaptureConfig = {
  contentLabel: string;
  id: HeaderBodyImage;
  modalType: ModalType;
  unitName: string;
  openHandler?: () => void;
};

type CtaModalConfig = {
  contentLabel: string;
  hbiCmsId: HeaderBodyImage;
  ctaCmsId: CtaWithUrl;
  footnoteCmsId: PromotionalText;
  modalType: ModalType;
  unitName: string;
  openHandler?: () => void;
};

type PromoModalConfig = {
  id: KeyValue;
  modalType: ModalType;
  openHandler?: () => void;
};

type TwoColumnModalConfig = {
  contentLabel: string;
  ctaId: CtaWithUrl;
  headerBodyImageId: HeaderBodyImage;
  legalId: LegalText;
  listId: KeyValue;
  modalType: ModalType;
  openHandler: () => void;
  closeHandler: () => void;
};

type ModalConfigType =
  | LeadCaptureConfig
  | PromoModalConfig
  | CtaModalConfig
  | TwoColumnModalConfig;

export type ProductPromoModalProps = {
  close: () => void;
  country: Locale;
  handleCTA: (x: ModalVariation) => void;
  isCTAPromoModal1Active: boolean;
  isCTAPromoModal2Active: boolean;
  isHactActive: boolean;
  isHomeTrialLeadCaptureActive: boolean;
  isUKTestClassLeadCaptureModalActive: boolean;
  isABCProductModalAVariation1: boolean;
  isABCProductModalAVariation2: boolean;
  isABCProductModalAVariation3: boolean;
  isABCProductModalBVariation1: boolean;
  isABCProductModalBVariation2: boolean;
  isABCProductModalBVariation3: boolean;
  isABCProductModalCVariation1: boolean;
  isABCProductModalCVariation2: boolean;
  isABCProductModalCVariation3: boolean;
  isClassesModalVariation1: boolean;
  isClassesModalVariation2: boolean;
  isClassesModalVariation3: boolean;
  isLeadGenPromoModalOriginal: boolean;
  isLeadGenPromoModalVariation1: boolean;
  isTurkeyTrotLeadGenModalActive: boolean;
  isFaasPromoModalActive: boolean;
  deviceType: undefined | ModalVariation;
  digitalPromoSku: Product;
  modalTrigger?: ModalTrigger;
};

export const useModalConfig = (props: ProductPromoModalProps) => {
  const modalConfigs = useModalConfigs(props);
  const firstActiveToggle = modalPriority.find(toggle => props[toggle]);

  return firstActiveToggle ? modalConfigs[firstActiveToggle] : undefined;
};

export const isLeadCapture = (config: ModalConfigType): config is LeadCaptureConfig =>
  config ? config.modalType === 'leadCapture' : false;

export const isCtaModal = (config: ModalConfigType): config is CtaModalConfig =>
  config ? config.modalType === 'ctaModal' : false;

export const isTwoColumnModal = (
  config: ModalConfigType,
): config is TwoColumnModalConfig =>
  config ? config.modalType === 'twoColumnModal' : false;

const deviceOptions = {
  bike: {
    image: {
      src: bikeImg,
      breakpointOptions: {
        mobile: { width: 540 },
        tablet: { width: 780 },
      },
    },
    trialRoute: bikeTrialRoute,
  },
  classes: {
    image: {
      src: bikeImg,
      breakpointOptions: {
        mobile: { width: 540 },
        tablet: { width: 780 },
      },
    },
    trialRoute: appTrialRoute,
  },
  tread: {
    image: {
      src: treadImg,
      breakpointOptions: {
        mobile: { width: 540 },
        tablet: { width: 780 },
      },
    },
    trialRoute: treadTrialRoute,
  },
};

const View: React.FC<React.PropsWithChildren<ProductPromoModalProps>> = props => {
  const { close, deviceType, handleCTA } = props;
  const modalOptions = useModalConfig(props);
  const promoModalContentLabel = useKeyValueData('promoModal.contentLabel');
  const promoModalCta = useKeyValueData('promoModal.cta');

  if (deviceType && isTwoColumnModal(modalOptions)) {
    return (
      <TwoColumnModal
        data-test-id={`cta-promo-modal-${deviceType}`}
        closeHandler={() => {
          modalOptions.closeHandler();
          close();
        }}
        contentLabel={modalOptions.contentLabel}
        ctaId={modalOptions.ctaId}
        headerBodyImageId={modalOptions.headerBodyImageId}
        legalId={modalOptions.legalId}
        listId={modalOptions.listId}
        openHandler={modalOptions.openHandler}
      />
    );
  }

  return deviceType && deviceType !== ModalVariation.Generic && modalOptions ? (
    <>
      {isLeadCapture(modalOptions) ? (
        <Modal
          closeHandler={close}
          contentLabel={modalOptions.contentLabel}
          openHandler={modalOptions.openHandler}
          padded={false}
        >
          <LeadCaptureModal {...modalOptions} />
        </Modal>
      ) : isCtaModal(modalOptions) ? (
        window.Cypress ? null : (
          <Modal
            data-test-id={`cta-promo-modal-${deviceType}`}
            closeHandler={close}
            contentLabel={modalOptions.contentLabel}
            openHandler={modalOptions.openHandler}
            padded={false}
          >
            <CtaPromoModal closeHandler={close} {...modalOptions} />
          </Modal>
        )
      ) : (
        <PromoModal
          closeHandler={close}
          contentLabel={promoModalContentLabel}
          imgProps={deviceOptions[deviceType].image}
          openHandler={modalOptions.openHandler}
          subtitleId={`promoModal.subtitle.${deviceType}`}
          titleId="promoModal.title"
        >
          <StyledButton
            onClick={() => handleCTA(deviceType)}
            to={deviceOptions[deviceType].trialRoute}
          >
            {promoModalCta}
          </StyledButton>
        </PromoModal>
      )}
    </>
  ) : null;
};

const StyledButton = (styled(Link)`
  ${toFullButtonStyles({ size: StandardButtonSize, theme: 'Button1' })}
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${media.tablet`
    width: 240px;
  `}
  ${media.desktop`
    width: 320px;
  `}
` as unknown) as React.ComponentClass<LinkProps>;

export default View;
