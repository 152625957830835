import type { Client } from '@peloton/api';
import { pipeDataTwice } from '@peloton/api';
import { toStoreSlug } from '@peloton/stores';
import type { ID, Slug } from '@ecomm/models';
import { toProducts } from './mappers';

export const toProductUrl = (identifier: ID | Slug) =>
  `/ecomm/store/${toStoreSlug()}/product/${identifier}`;

export const fetchProductBySlug = (api: Client, identifier: ID | Slug) =>
  api.get(toProductUrl(identifier)).then(pipeDataTwice(toProducts));
