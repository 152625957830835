import type { SagaIterator } from 'redux-saga';
import type { Effect } from 'redux-saga/effects';
import { call, select, takeEvery } from 'redux-saga/effects';
import { UserReducerActionType } from '@peloton/auth';
import type { ExtLink, ExtLinkEnv, PeloLink } from '@peloton/external-links';
import {
  getExtLinkEnv,
  toHref,
  toRedirectParams,
  REDIRECT_URL_QUERY_PARAM,
  REDIRECT_APP_QUERY_PARAM,
  toWWWLink,
} from '@peloton/external-links';
import { Links } from '@peloton/links';
import { redirect } from '@peloton/navigation';
import { getLocation } from '@peloton/redux';
import { match1wRoute, matchShortBenefitCenterRoute } from '@onewellness/routes/ecomm';

const toHrefWithRedirect = (link: ExtLink, env: ExtLinkEnv, redirectLink: PeloLink) => {
  const redirectParams = toRedirectParams(redirectLink);

  const redirectQueryString = `?${REDIRECT_URL_QUERY_PARAM}=${btoa(
    redirectParams.redirectUrl,
  )}&${REDIRECT_APP_QUERY_PARAM}=${redirectParams.redirectApp}`;

  return `${toHref(link, env)}${redirectQueryString}`;
};

export const loginRedirectSaga = function* (): SagaIterator {
  /*  
      For now, make sure the login redirect only works on 

      /benefits/corporate/* OR
      /benefit-center
      
      so we don't cause unexpected redirects on other ecomm pages.
  */
  const match = yield select(match1wRoute);
  const matchShortBenefitCenter = yield select(matchShortBenefitCenterRoute);

  if (match || matchShortBenefitCenter) {
    const extLinkEnv = yield select(getExtLinkEnv);
    const location = yield select(getLocation);
    const loginHref = toHrefWithRedirect(
      Links.login,
      extLinkEnv,
      toWWWLink(location.pathname + (location.search ?? '')),
    );
    yield call(redirect, loginHref);
  }
};

const redirectSaga = function* (): IterableIterator<Effect> {
  yield takeEvery(UserReducerActionType.LOGIN_REDIRECT, loginRedirectSaga);
};

export default redirectSaga;
