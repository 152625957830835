import React from 'react';
import { FormattedMessage } from '@ecomm/internationalize';
import { default as AffirmModalLink } from './affirm/ModalLink';
import type { OwnProps as ModalLinkProps, PageType } from './affirm/ModalLinkView';
import useGetFinancingPartner from './hooks/useGetFinancingPartner';
import { Partners } from './models';
import { default as PayBrightModalLink } from './paybright/ModalLink';

type Props = ModalLinkProps & {
  pageType?: PageType;
};

const APRModalLink: React.FC<Props> = ({ pageType, ...props }) => {
  const financingPartner = useGetFinancingPartner();
  return financingPartner === Partners.Affirm ? (
    <AffirmModalLink pageType={pageType} {...props} />
  ) : financingPartner === Partners.PayBright ? (
    <PayBrightModalLink {...props} />
  ) : (
    <FormattedMessage id={`financing.${financingPartner}.apr`} />
  );
};

export default APRModalLink;
