import type { LocationChangeAction } from 'connected-react-router';
import { LOCATION_CHANGE } from 'connected-react-router';
import type { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { fetcherSaga, PlanType } from '@peloton/subscription-plans';
import { isLoaded } from '@ecomm/models';
import { failPlansFetch, requestedPlansFetch, succeedPlansFetch } from '../redux';
import { matchDigitalPromotion } from '../routes';
import { getUIState } from '../selectors';

export const checkStatusSaga = function* (): SagaIterator {
  const status = yield select(getUIState);
  const loaded = yield call(isLoaded, status);

  if (!loaded) {
    yield put(requestedPlansFetch());
    yield call(fetcherSaga, PlanType.Digital, undefined, {
      onSuccess: succeedPlansFetch,
      onError: failPlansFetch,
    });
  }
};

export const checkRouteSaga = function* (_: LocationChangeAction): SagaIterator {
  for (const routeMatch of matchDigitalPromotion) {
    if (yield select(routeMatch)) {
      yield call(checkStatusSaga);
      break;
    }
  }
};

const watcherSaga = function* () {
  yield takeEvery(LOCATION_CHANGE, checkRouteSaga);
};

export default watcherSaga;
