import type { Entities } from '@ecomm/shop/models';
import type { CouponSavings } from '../models';

export enum ActionType {
  Add = 'ecomm/order/ADD_COUPON_SAVINGS',
}

export type State = Entities<CouponSavings>;

export const defaultState = {};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.Add:
      return {
        ...state,
        ...action.payload.couponSavings,
      };
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  couponSavings: State;
};

export const addCouponSavings = (
  couponSavings: Entities<CouponSavings>,
): AddCouponSavingsAction => ({
  type: ActionType.Add,
  payload: { couponSavings },
});

type AddCouponSavingsAction = {
  type: ActionType.Add;
  payload: { couponSavings: Entities<CouponSavings> };
};

type Action = AddCouponSavingsAction;
