import { useEffect, useState, useCallback } from 'react';
import { isMsEdge } from '@peloton/browser';

export type SnapAxis = 'width' | 'height';

const useSnapAxis = (videoElement: HTMLVideoElement | null) => {
  const [supportsObjectFit, setSupportsObjectFit] = useState(true);
  const [snapAxis, setSnapAxis] = useState<SnapAxis | undefined>();

  const calculateSnapAxis = useCallback(() => {
    const wrapperRect = videoElement?.parentElement?.getBoundingClientRect();
    const videoRect = videoElement?.getBoundingClientRect();
    if (wrapperRect && videoRect) {
      const wrapperAspectRatio = wrapperRect.width / wrapperRect.height;
      const videoAspectRatio = videoRect.width / videoRect.height;
      const calculatedSnapAxis =
        wrapperAspectRatio >= videoAspectRatio ? 'width' : 'height';
      if (snapAxis !== calculatedSnapAxis) {
        setSnapAxis(calculatedSnapAxis);
      }
    }
  }, [snapAxis, videoElement]);

  useEffect(() => {
    const videoEl = document.createElement('video');
    const _supportsObjectFit =
      (videoEl.style as any).objectFit !== undefined &&
      (videoEl.style as any).objectPosition !== undefined;

    // Edge supports objectFit and objectPosition on HtmlVideoElement, but not in the actual CSS,
    // So for edge we must mask _supportsObjectFit false.
    if (_supportsObjectFit && isMsEdge() && !snapAxis) {
      calculateSnapAxis();
    }

    setSupportsObjectFit(_supportsObjectFit);
  }, [snapAxis]);

  return { snapAxis, supportsObjectFit };
};

export default useSnapAxis;

export const videoSnapCss = (supportsObjectFit: boolean, snapAxis?: SnapAxis) => {
  if (supportsObjectFit) {
    return 'height: 100%; width: 100%;';
  }
  switch (snapAxis) {
    case 'height':
      return 'height: 100%;';
    case 'width':
      return 'width: 100%;';
    default:
      // undefined
      // hide the video if the snapAxis is unknown
      return 'visibility: hidden;';
  }
};
