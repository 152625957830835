import { useContext } from 'react';
import type { PromoBanner } from '@ecomm/copy';
import { PromoContext } from '../PromoProvider';
import { usePromoPlacement } from './usePromoPlacement';

export type BannerMetadata = {
  cmsId: PromoBanner;
  dataTestIdPrefix: string;
};

const useActivePromoBanner = (): BannerMetadata => {
  const activePromo = useContext(PromoContext);
  const activePlacement = usePromoPlacement();

  return {
    cmsId: activePromo[`${activePlacement}BannerId`],
    dataTestIdPrefix: `${activePlacement}Banner`,
  };
};

export default useActivePromoBanner;
