import type { AxiosInstance } from 'axios';
import { identity } from 'ramda';
import { pipeDataTwice } from '@peloton/api/utils';
import type { ShowroomProps } from '@ecomm/showrooms/model';

type ApiResponse = { data: ShowroomProps[] };

const showroomsUrl = 'ecomm/store';

const fetchShowrooms = async (api?: AxiosInstance) => {
  if (!api) {
    return;
  }
  try {
    const showrooms = await api
      .get<ApiResponse>(showroomsUrl)
      .then(pipeDataTwice(identity));
    return showrooms;
  } catch (e) {
    console.error(e);
    return;
  }
};

export { fetchShowrooms };
