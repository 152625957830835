import React from 'react';
import styled from 'styled-components';
import { track } from '@peloton/analytics';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import { Button1, ButtonWithSpinner } from '@ecomm/buttons';
import { FormatCtaWithUrl } from '@ecomm/copy';
import { PageType } from '@ecomm/financing/affirm/ModalLinkView';
import type { BundleType } from '@ecomm/shop/models';
import { useTrackAffirmPrequalComplete } from './affirm/hooks';
import APRModalLink from './APRModalLink';
import type { FinancedDevice } from './FinancingProps';
import useFinancing from './hooks/useFinancing';

type PrequalifyButtonProps = {
  bundleType: BundleType;
  className?: string;
  componentName: string;
  pageType?: PageType;
};

// Banner type placements don't show product information and are supposed to be generic
export const getProductName = (financedDevice: FinancedDevice, pageType?: PageType) => {
  return pageType === PageType.BANNER ? 'Generic' : financedDevice;
};

const FinancingPrequalifyButton: React.FC<
  React.PropsWithChildren<PrequalifyButtonProps>
> = ({ bundleType, className, componentName, pageType }) => {
  const { total, term, financedDevice } = useFinancing(bundleType);
  const productName = getProductName(financedDevice, pageType);

  const trackPrequalComplete = useTrackAffirmPrequalComplete(componentName, productName);

  return financedDevice ? (
    <StyledAPRModalLink
      amount={total}
      bundleType={bundleType}
      className={className}
      pageType={pageType}
      term={term}
      onPrequalComplete={trackPrequalComplete}
      onClick={trackClick(productName, componentName)}
    >
      {isProcessing => (
        <Button button={Button1} processing={isProcessing}>
          <FormatCtaWithUrl id="prequalifyNow" />
        </Button>
      )}
    </StyledAPRModalLink>
  ) : null;
};

export const trackClick = (productName: string, componentName: string) => () => {
  track({
    event: 'Clicked Prequalify CTA',
    properties: {
      pageName: getCurrentRelativePathname(),
      productName,
      unitName: componentName,
    },
  });
};

const StyledAPRModalLink = styled(APRModalLink)`
  display: inline-block;
`;

const Button = styled(ButtonWithSpinner)`
  display: inline-block;
  padding: 0;
`;

export default FinancingPrequalifyButton;
