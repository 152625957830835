import { reducer as pgCorporateBenefitsSSOEnrollment } from '@onewellness/pg-sso-enrollment';
import benefits from './benefits';
import userProfile from './userProfile';

const reducers = {
  benefits,
  pgCorporateBenefitsSSOEnrollment,
  userProfile,
};

export default reducers;
