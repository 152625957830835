import type { ID } from '@ecomm/models';

export enum Actions {
  CartLoaded = 'ecomm/cart/LOAD_CART',
  CartRetrieved = 'ecomm/cart/RETRIEVE_CART',
}

export const trackCartLoaded = (originalCartId: ID) =>
  ({
    type: Actions.CartLoaded,
    payload: {
      originalCartId,
    },
  } as const);

export const trackCartRetrieved = () =>
  ({
    type: Actions.CartRetrieved,
  } as const);

export type TrackCartLoadedAction = ReturnType<typeof trackCartLoaded>;
export type TrackCartRetrievedAction = ReturnType<typeof trackCartRetrieved>;
