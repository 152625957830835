import React from 'react';
import type { ReactNode, RefObject } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';

type Props = {
  icon: ReactNode;
  dataTestId: string;
  children: ReactNode;
  label?: string;
  isOpen: boolean;
  onClick: VoidFunction;
  buttonRef: RefObject<HTMLButtonElement>;
  className?: string;
};

const MenuTrigger: React.FC<React.PropsWithChildren<Props>> = ({
  icon,
  children,
  dataTestId,
  label,
  isOpen,
  onClick,
  buttonRef,
  className,
}) => {
  const { trackEvent } = useTracking();

  return (
    <li className={className}>
      <StyledButton
        data-test-id={dataTestId}
        aria-label={label}
        aria-expanded={isOpen}
        onClick={() => {
          onClick && onClick();

          trackEvent({
            properties: {
              category: dataTestId,
              linkName: '',
            },
          });
        }}
        ref={buttonRef}
      >
        {icon}
      </StyledButton>
      {isOpen && children}
    </li>
  );
};

export default MenuTrigger;

const StyledButton = styled.button`
  position: relative;
  &[aria-expanded='true']:before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #fff;
    bottom: 0;
    left: calc((100% - 8px) / 2);
    transform: rotate(90deg);
  }
`;
