import type { Currency } from '@peloton/internationalize';
import { toCurrency } from '@peloton/internationalize';
import type { Cart, ShipmentQuote } from '@ecomm/cart/models/Cart';
import { hasEquipmentLeaseInCart } from '@ecomm/cart/models/Cart';
import { hasOrIsBike, hasOrIsBikePlus, hasOrIsTread } from '@ecomm/cart/models/Item';
import type { Money } from '@ecomm/models/Money';
import type { ShippingPrice } from '@ecomm/shipping-estimate/prices';
import { BundleType } from '@ecomm/shop/models/BundleType';
import {
  getBikePlusShippingCosts,
  getBikeShippingCosts,
  getTreadShippingCosts,
  getTreadPlusShippingCosts,
} from './prices';

const deviceShippingCostMap = {
  [BundleType.Bike]: getBikeShippingCosts,
  [BundleType.BikePlus]: getBikePlusShippingCosts,
  [BundleType.Tread]: getTreadShippingCosts,
  [BundleType.TreadPlus]: getTreadPlusShippingCosts,
};

const getDeviceShippingCosts = (
  device: Omit<BundleType, 'accessories'>,
  currency: Currency,
): ShippingPrice => {
  const hasShippingEstimateFn = deviceShippingCostMap.hasOwnProperty(device as string);
  if (hasShippingEstimateFn) {
    return deviceShippingCostMap[device as string](currency);
  } else {
    return { first: 0, additional: 0 };
  }
};

export const getShippingCostByDevices = (
  devices: Omit<BundleType, 'accessories'>[],
  currency: Currency = toCurrency(),
) => {
  let shippingEstimate = 0;

  if (devices.length) {
    const lowestBaseDevice = getLowestNonZeroDevice(devices, currency);

    let deviceFound = false;

    shippingEstimate = devices.reduce((acc, currentDevice) => {
      const hasShippingEstimateFn = deviceShippingCostMap.hasOwnProperty(
        currentDevice as string,
      );
      let currentDevicePrice;
      if (hasShippingEstimateFn) {
        const shippingCosts = getDeviceShippingCosts(currentDevice, currency);
        if (currentDevice === lowestBaseDevice && !deviceFound) {
          deviceFound = true;
          currentDevicePrice = shippingCosts.first;
        } else {
          currentDevicePrice = shippingCosts.additional;
        }
      } else {
        currentDevicePrice = 0;
      }

      return currentDevicePrice + acc;
    }, 0);
  }

  return shippingEstimate;
};

const getLowestNonZeroDevice = (
  devices: Omit<BundleType, 'accessories'>[],
  currency: Currency,
) =>
  devices.reduce(
    (
      prevDevice: Omit<BundleType, 'accessories'>,
      currentDevice: Omit<BundleType, 'accessories'>,
    ) => {
      const currentValue = getDeviceShippingCosts(currentDevice, currency).first;
      const previousValue = getDeviceShippingCosts(prevDevice, currency).first;
      if (previousValue === 0) return currentDevice;
      const preferCurrent =
        (currentValue !== 0 && currentValue < previousValue) || previousValue === 0;
      return preferCurrent ? currentDevice : prevDevice;
    },
  );

const cartCountToDeviceArray = (
  count: number,
  bundleType: Omit<BundleType, 'accessories'>,
) => {
  const deviceArray = [];
  for (let i = 0; i < count; i++) {
    deviceArray.push(bundleType);
  }
  return deviceArray;
};

const toEstimatedShippingCostFromCart = (cart: Cart): Money => {
  const bikeCount = cart.items.filter(hasOrIsBike).length;
  const bikePlusCount = cart.items.filter(hasOrIsBikePlus).length;
  const treadCount = cart.items.filter(hasOrIsTread).length;
  const hasEquipmentLease = hasEquipmentLeaseInCart(cart);

  if (hasEquipmentLease) {
    return 0;
  }

  const devices = [
    ...cartCountToDeviceArray(bikeCount, BundleType.Bike),
    ...cartCountToDeviceArray(bikePlusCount, BundleType.BikePlus),
    ...cartCountToDeviceArray(treadCount, BundleType.Tread),
  ];

  return getShippingCostByDevices(devices);
};

export const estimateShippingQuote = (cart: Cart): ShipmentQuote => ({
  amount: toEstimatedShippingCostFromCart(cart),
  estimated: true,
});
