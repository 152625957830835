import React, { useContext } from 'react';
import { PromoPanelContext } from '@ecomm/cms-promos/PromoPanelProvider';
import type { PageHandler as VariationHandler } from '@page-builder/layout/types';
import type { TypeDateTriggerFields, TypeVariationFields } from '@page-builder/lib/types';

type DateTriggerProps = TypeDateTriggerFields & {
  control: TypeVariationFields['control'];
  variations: TypeVariationFields['variations'];
};

type Props = VariationHandler<DateTriggerProps>;

type ActiveDateProps = {
  start: string;
  end?: string;
  today?: Date;
};

export const isActiveDate = ({ start, end, today = new Date() }: ActiveDateProps) => {
  const hasStarted = new Date(start).valueOf() < today.valueOf();
  const hasEnded = end && new Date(end).valueOf() < today.valueOf();
  return hasStarted && !hasEnded;
};

const DateTriggerBlock: React.FC<React.PropsWithChildren<Props>> = ({
  start,
  end,
  control,
  variations,
  block: VariationBlock,
}) => {
  const { overrideToday: today } = useContext(PromoPanelContext);
  const isActive = isActiveDate({ start, end, today });
  const moduleData = isActive ? variations?.[0] : control;

  if (moduleData) {
    return <VariationBlock block={moduleData} />;
  }

  return null;
};

export default DateTriggerBlock;
