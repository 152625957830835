import type { Client } from '@peloton/api';
import { loadAuthUser } from '@peloton/auth';
import {
  loadCartFailure,
  loadCartInitialSuccess,
  trackCartLoaded,
  trackCartRetrieved,
} from '@ecomm/cart';
import { fetchCart as loadCart } from '@ecomm/cart/api';
import getUpdatedFeatureValue from '@ecomm/feature-toggle/optimizely/getUpdatedFeatureValue';
import { switchToggle } from '@ecomm/feature-toggle/redux';

export type Deps = {
  dispatch: (x: any) => void;
  location: Location;
  client: Client;
};

const initData = async (deps: Deps) => {
  fetchUser({ dispatch: deps.dispatch });
  const cartPromise = await fetchCart(deps);
  fetchUnderMaintenanceToggle({ dispatch: deps.dispatch });
  await cartPromise;
};

export const fetchUser = ({ dispatch }: Pick<Deps, 'dispatch'>) => {
  dispatch(loadAuthUser());
};

export const fetchCart = async ({ dispatch, client, location }: Deps) => {
  const params = new URLSearchParams(location.search);
  const cartId = params.get('cart_id') ?? undefined;

  try {
    const cart = await loadCart(client, cartId);
    dispatch(loadCartInitialSuccess(cart));
    dispatch(trackCartRetrieved());
    if (cartId) {
      dispatch(trackCartLoaded(cartId));
    }
  } catch (e) {
    dispatch(loadCartFailure(e));
  }
};

const fetchUnderMaintenanceToggle = async ({ dispatch }: Pick<Deps, 'dispatch'>) => {
  dispatch(
    switchToggle(
      'underMaintenance',
      await getUpdatedFeatureValue()('underMaintenancePage'),
    ),
  );
};

export default initData;
