import * as yup from 'yup';
import type { Schema } from 'yup';
import { EMAIL_REGEX } from '@ecomm/models';
import { ValidationErrors } from './models';

export const allowMarketing = yup.boolean();

export const email = yup.string().trim().matches(EMAIL_REGEX, ValidationErrors.Invalid);

// Dynamically extending to boolean
yup.addMethod(yup.boolean, 'alwaysTrue', function (message: string) {
  return this.test('alwaysTrue', message, (value: boolean) => {
    return value === true;
  });
});

interface WithAlwaysTrue extends Schema<boolean> {
  alwaysTrue(message: string): this;
}

export const hasAcceptedPolicy = (yup.boolean() as WithAlwaysTrue).alwaysTrue(
  ValidationErrors.Invalid,
);

export const password = yup.string().min(8, ValidationErrors.PasswordTooShort);
