import React from 'react';
import { connect } from 'react-redux';
import type { RouteProps } from 'react-router';
import { Route } from 'react-router';
import type { State as EnvState } from '@peloton/env';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import type { PageViewPayload } from '@ecomm/analytics';
import { trackPageView } from '@ecomm/analytics';
import type { ReducerState as ToggleState } from '@ecomm/feature-toggle';
import { NotFoundError, NotFoundErrorPage } from '@ecomm/layout';
import { useProductStates } from '@ecomm/product-states';
import { toShopUrl } from '@ecomm/shop';
import PageBuilderRoute from '@page-builder/routes/Route';
import type { Toggle } from './models';
import toAreTogglesActive from './toAreTogglesActive';

export type StateProps = {
  isActive: boolean;
};

export type DispatchProps = {
  trackRoute: (payload: PageViewPayload) => void;
};

export type OwnProps = RouteProps & {
  page?: boolean;
  sensitive?: boolean;
  shouldTrack?: boolean;
  toggleId?: Toggle | Toggle[];
  pageBuilderRoute?: boolean;
};

type Props = OwnProps & StateProps & DispatchProps;

export const EcommRoute: React.FC<React.PropsWithChildren<Props>> = ({
  path,
  component,
  isActive,
  page,
  shouldTrack = true,
  trackRoute,
  location,
  pageBuilderRoute,
  ...rest
}) => {
  const { isRouteAvailable, isRouteControlledByProductToggles } = useProductStates();
  const urlOrFormattedShopUrl = toShopUrl(location?.pathname || '');

  React.useEffect(() => {
    if (shouldTrack && location) {
      trackRoute({
        pathname: getCurrentRelativePathname()!,
      });
    }
  }, [location, trackRoute, shouldTrack]);

  if (isRouteControlledByProductToggles(urlOrFormattedShopUrl)) {
    return isRouteAvailable(urlOrFormattedShopUrl) && isActive ? (
      <Route path={path} component={component} location={location} {...rest} />
    ) : (
      <Route component={NotFoundErrorPage} />
    );
  }

  if (pageBuilderRoute) {
    return (
      <PageBuilderRoute
        isActive={isActive}
        path={path}
        component={component}
        location={location}
        {...rest}
      />
    );
  }

  return isActive ? (
    <Route path={path} component={component} location={location} {...rest} />
  ) : (
    <Route component={page ? NotFoundErrorPage : NotFoundError} />
  );
};

EcommRoute.displayName = 'EcommRoute';

type State = ToggleState & EnvState;

const mapStateToProps = (state: State, { toggleId }: OwnProps): StateProps => ({
  isActive: toAreTogglesActive<State>(toggleId)(state),
});

const mapDispatchToProps: DispatchProps = {
  trackRoute: trackPageView,
};

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(EcommRoute);
