import { black, brand, grey } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { Markdown } from '@peloton/copy';
import { media } from '@peloton/styles';
import { Button2, SmallButtonSize } from '@ecomm/buttons';
import { useKeyValueData, FormatText } from '@ecomm/copy';
import { body, l1 } from '@ecomm/typography';
import UnstyledDismiss from './Dismiss';

type Props = StateProps & DispatchProps;

export type StateProps = {
  privacyPolicyLink: string;
  showPrivacyBanner: boolean;
};

export type DispatchProps = {
  onAccept: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onDismiss?: (event: React.MouseEvent<SVGElement>) => void;
};

const PrivacyBanner: React.FC<React.PropsWithChildren<Props>> = ({
  onAccept,
  onDismiss,
  privacyPolicyLink,
  showPrivacyBanner,
  ...props
}) => {
  const bannerMessage = useKeyValueData('privacyBanner.message');
  const buttonText = useKeyValueData('privacyBanner.cta');

  return showPrivacyBanner ? (
    <Container data-test-id="privacyBanner" {...props}>
      <MessageContainer data-test-id="privacyBannerMessage">
        <Markdown
          content={bannerMessage}
          markdown={{
            renderers: {
              link: Link,
            },
          }}
          values={{
            privacyPolicyLink,
          }}
        />
      </MessageContainer>
      <AcceptContainer>
        <AcceptButton data-test-id="privacyBannerCta" onClick={onAccept}>
          <FormatText content={buttonText} />
        </AcceptButton>
      </AcceptContainer>
      {onDismiss && <Dismiss onClick={onDismiss} role="button" />}
    </Container>
  ) : null;
};

export default PrivacyBanner;

const Container = styled.div`
  align-items: center;
  background-color: ${brand.light};
  bottom: 0;
  box-shadow: 0 -1px 10px 0 ${rgba(black, 0.15)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  padding: 30px 30px 88px;
  position: fixed;
  width: 100%;
  z-index: 90005;

  ${media.tabletLarge`
    width: 400px;
    padding-bottom: 30px;
  `}

  @media print {
    display: none;
  }
`;

const MessageContainer = styled.div`
  ${body}
  font-size: 12px;
  text-align: center;

  ${media.tablet`
    font-size: 14px;
  `}

  ${media.desktop`
    text-align: left;
    font-size: 16px;
  `}
`;

const AcceptContainer = styled.div`
  padding-top: 20px;
  ${media.desktop`
    flex-shrink: 0;
  `}
`;

const AcceptButton = styled(Button2).attrs({ size: SmallButtonSize })`
  height: auto;
`;

const Dismiss = styled(UnstyledDismiss)`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  fill: ${grey[50]};
  &:hover {
    fill: ${grey[70]};
  }
`;

const Link = styled.a.attrs({
  rel: 'noopener',
  target: '_blank',
})`
  ${l1}
`;
