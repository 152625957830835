import React, { useEffect } from 'react';
import { OPTIMIZELY_DATAFILE } from '@peloton/app-config';
import { MemberGroup } from '@peloton/auth/models';
import { useUserInfo } from '@peloton/auth/UserInfoProvider';
import { useLocale } from '@peloton/internationalize';
import datafiles from '@ecomm/bootstrapping/features/static';
import { useGenericSetToggleValue } from '@ecomm/feature-toggle/context/ToggleContext';
import { toFeatures } from '@ecomm/feature-toggle/optimizely/client';
import type { DataFiles } from '@ecomm/feature-toggle/optimizely/client';

const RecalculatedFeaturesProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  useRecalculatedFeatures();

  return <>{children}</>;
};

export default RecalculatedFeaturesProvider;

const useRecalculatedFeatures = () => {
  const setToggleValue = useGenericSetToggleValue();
  const { getUser } = useUserInfo();
  const user = getUser();
  const locale = useLocale();

  useEffect(() => {
    if (
      user &&
      'memberGroups' in user &&
      user.memberGroups?.includes(MemberGroup.InternalEcommTester)
    ) {
      const features = toFeatures(
        { locale, isEcommTester: true, email: user.email },
        datafiles,
        (OPTIMIZELY_DATAFILE as keyof DataFiles) || 'production',
      );

      Object.entries(features).forEach(([name, value]) => {
        setToggleValue(name, value.active);
      });
    }
  }, [locale, setToggleValue, user]);
};
