import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

const Routes = [
  <Route
    key="tread-page"
    path={`/:discipline(tread)`}
    component={asyncComponent(() => import('./Handler' /* webpackChunkName: "Tread" */))}
    page
    shouldTrack={false}
  />,
];

export default Routes;
