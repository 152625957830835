import type { ServerRoute, ServerRouteGrouping } from './serverRoute';
import { Status } from './serverRoute';

export const toNetlifyConfig = (groupings: ServerRouteGrouping[]) =>
  groupings
    .map(({ description, routes = [] }) =>
      [description && `# ${description}`, ...routes.map(toServerRoute)]
        .filter(Boolean)
        .join('\n'),
    )
    .join('\n\n');

// TODO: dynamically figure out pretty spacing
export const toServerRoute = (route: ServerRoute) =>
  [
    removeDuplicateFromUrl(route.source).padEnd(50),
    route.destination.padEnd(60),
    toStatus(route),
    toCountry(route),
  ]
    .join('\t')
    .trim();

export const toStatus = (route: ServerRoute): string => {
  const statusStr: string = route.status ?? Status.Redirect_Temporary;

  return route.dontForce ? statusStr : statusStr + '!';
};

// TODO: restrict to our known country codes
export const toCountry = ({ countries }: ServerRoute) =>
  countries ? `Country=${countries.join()}` : '';

export const removeDuplicateFromUrl = (route: string | undefined) => {
  if (route === '/') return '/';

  const splitLoc = route ? route.split('/') : '';

  let newLocation = '';

  for (let i = 0; i < splitLoc.length; i++) {
    if (splitLoc[i] !== splitLoc[i + 1]) newLocation += splitLoc[i] + '/';
  }
  newLocation = newLocation.substring(0, newLocation.length - 1);

  return newLocation;
};

export const containsDuplicateURL = (url: string | undefined) =>
  removeDuplicateFromUrl(url) !== url;
