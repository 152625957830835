import { pathOr } from 'ramda';
import type { HeaderBodyImageGallery } from '../models.generated';
import { useHeaderBodyImageGalleryQuery } from '../queries/contentTypes/headerBodyImageGallery.generated';
import type { HeaderBodyImageData } from './useHeaderBodyImageData';
import { transformImageData } from './useImageData';

export const transformHeaderBodyImageGalleryData = (
  response: object,
): HeaderBodyImageData[] => {
  const rawHeaderBodyImageData = pathOr(
    [],
    ['data', 'headerBodyImageGallery', 'itemsCollection', 'items'],
    response,
  );

  const headerBodyImagesWithFallbacks: HeaderBodyImageData[] = rawHeaderBodyImageData
    .filter((item: HeaderBodyImageData) => !!item)
    .map((headerBodyImageItem: HeaderBodyImageData) => {
      const { key, header, body, image } = headerBodyImageItem;

      const transformedImageData = image
        ? transformImageData({ data: { image } })
        : { image: null };

      return {
        key,
        header: header || `HeaderBodyImageGallery: ${key}`,
        body: body || `HeaderBodyImageGallery: ${key}`,
        ...transformedImageData,
      };
    });

  return headerBodyImagesWithFallbacks;
};

const useHeaderBodyImageGalleryData = (
  id: HeaderBodyImageGallery,
): HeaderBodyImageData[] => {
  const res = useHeaderBodyImageGalleryQuery({ variables: { id } });

  return transformHeaderBodyImageGalleryData(res);
};

export default useHeaderBodyImageGalleryData;
