import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { UIState } from '@ecomm/models';
import { Status, toUIState } from '@ecomm/models';
import type { Audience } from '@onewellness/routes';

export enum ActionTypes {
  UpdateUsername = 'onewellness/apply-benefit/UPDATE_USERNAME_REQUESTED',
  UpdateUsernameSucceeded = 'onewellness/apply-benefit/UPDATE_USERNAME_SUCCEEDED',
  UpdateUsernameFailed = 'onewellness/apply-benefit/UPDATE_USERNAME_FAILED',
}

export type State = UIState;
export const defaultState = toUIState(Status.Init);

const reducer = (state: UIState = defaultState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.UpdateUsername:
      return {
        ...toUIState(Status.Loading),
      };
    case ActionTypes.UpdateUsernameSucceeded:
      return {
        ...state,
        ...toUIState(Status.Loaded),
      };
    case ActionTypes.UpdateUsernameFailed: {
      const { exception } = action.payload;
      return {
        ...state,
        ...toUIState(Status.Failed, exception),
      };
    }
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  username: State;
};

export const requestUpdateUsername = (
  audience: Audience,
  username: string,
): UpdateUsernameAction => ({
  type: ActionTypes.UpdateUsername,
  payload: {
    audience,
    username,
  },
});

export const updateUsernameSucceeded = (): UpdateUsernameSuccessAction => ({
  type: ActionTypes.UpdateUsernameSucceeded,
});

export const updateUsernameFailed = (
  errorId: string = 'onewellness.errors.updateUsernameFailed',
): UpdateUsernameFailedAction => ({
  type: ActionTypes.UpdateUsernameFailed,
  payload: { exception: toException(errorId) },
});

export type UpdateUsernameAction = {
  type: ActionTypes.UpdateUsername;
  payload: {
    audience: Audience;
    username: string;
  };
};
type UpdateUsernameSuccessAction = { type: ActionTypes.UpdateUsernameSucceeded };
type UpdateUsernameFailedAction = {
  type: ActionTypes.UpdateUsernameFailed;
  payload: { exception: Exception };
};

export type Actions =
  | UpdateUsernameAction
  | UpdateUsernameSuccessAction
  | UpdateUsernameFailedAction;
