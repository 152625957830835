import type { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ConnectedRouter } from 'connected-react-router';
import type { History, Location } from 'history';
import React, { useEffect } from 'react';
import { Provider, ReactReduxContext } from 'react-redux';
import { Route } from 'react-router';
import scriptjs from 'scriptjs';
import type { Client } from '@peloton/api';
import {
  ALLOW_COPY_XRAY,
  OPTIMIZELY_KEY,
  USE_APOLLO_V3,
  HIDE_CONSOLE_LOGS,
} from '@peloton/app-config';
import LogoutCookieProvider from '@peloton/auth/LogoutCookieProvider';
import { asyncComponent } from '@peloton/code-splitting';
import { ChatStyles } from '@peloton/drift';
import type { EnvironmentFlags } from '@peloton/env';
import type { ErrorReporter } from '@peloton/error-reporting';
import {
  CodeOwnerErrorBoundary,
  ErrorBoundary,
  ErrorReporterContext,
} from '@peloton/error-reporting';
import { DatadogReporterTracker } from '@peloton/error-reporting/datadog/DatadogReporterTracker';
import { ExtLinkEnvProvider } from '@peloton/external-links/context/Provider';
import type { ExtLinkEnv } from '@peloton/external-links/models';
import type { Locale } from '@peloton/internationalize';
import { LocaleProvider } from '@peloton/internationalize';
import { LocaleCookieProvider } from '@peloton/internationalize/LocaleCookieProvider';
import { LOCALE_TOGGLE } from '@peloton/internationalize/models/toggle';
import LinkComponentProvider from '@peloton/links/LinkComponentProvider';
import { OneTrustStyles } from '@peloton/onetrust/styles';
import { ConnectedSwitch } from '@peloton/redux';
import { ScrollToTop } from '@peloton/scroll';
import { SplitTestingProvider } from '@peloton/split-testing/hooks/provider';
import { ApolloProviderV3 } from '@peloton/with-apollo/v3';
import { GlobalUiStateProvider } from '@acme-ui/global';
import TrackingProvider from '@ecomm/analytics/TrackingProvider';
import { ClientProvider } from '@ecomm/api';
import CRAUserInfoProvider from '@ecomm/auth/CRAUserInfoProvider';
import { FeatureTogglePanel } from '@ecomm/bootstrapping';
import RecalculatedFeaturesProvider from '@ecomm/bootstrapping/feature-toggles/RecalculatedFeaturesProvider';
import { CartProvider } from '@ecomm/cart-next/context/CartContext';
import { CommercetoolsClientProvider } from '@ecomm/commercetools/apollo';
import type { PublishedCopyBlob } from '@ecomm/copy';
import { ToggleProvider } from '@ecomm/feature-toggle/context/ToggleContext';
import type { Toggles } from '@ecomm/feature-toggle/models/Toggles';
import { CitizensProvider } from '@ecomm/financing/citizens';
import GraphQLProvider from '@ecomm/graphql/Provider';
import Internationalize from '@ecomm/internationalize';
import { ErrorPage, SimpleErrorPage } from '@ecomm/layout';
import { Link } from '@ecomm/links';
import { pathnameMatchesOverride, RestrictLocaleAccess } from '@ecomm/locale-restriction';
import LoginModal from '@ecomm/login-modal';
import { NoIndexMetadataTag } from '@ecomm/metadata';
import OAuthProvider from '@ecomm/oauth/OAuthProvider';
import { PrivacyBanner } from '@ecomm/privacy-banner';
import type { AvailabilityByProduct } from '@ecomm/product-states';
import { ProductStatesProvider } from '@ecomm/product-states';
import Panel from '@ecomm/product-states/Panel';
import { ProgramProvider } from '@ecomm/programs';
import { ProductPromoModal } from '@ecomm/promo-modal';
import { UpdateURL, RouteNotFound } from '@ecomm/router';
import { OneWellnessClientProvider } from '@onewellness/api/client';
import { ChatBotScript } from './ChatBotScript';
import { OneTrustTimeoutReporter } from './OneTrustTimeoutReporter';
import { routes, localisedRoutes } from './routes';

const CopyXray = asyncComponent(
  () => import('@ecomm/copy-xray/CopyXray' /* webpackChunkName: "CopyXray" */),
);

const GeoModalSwitcher = asyncComponent(async () => {
  await new Promise<void>(res => {
    scriptjs('/locale.js', res);
  });
  return import('@ecomm/bootstrapping/internationalize');
});

type Props = {
  envFlags: EnvironmentFlags;
  errorHandler: ErrorReporter;
  oneWellnessClient: any;
  commercetoolsClient: any;
  locale: Locale;
  localeModalActive: boolean;
  overrideLocation?: Location;
  restClient: Client;
  publishedLocaleCopy: PublishedCopyBlob;
  store: any; // Types for store aren’t exported from redux
  productState?: AvailabilityByProduct; // Override Provider product states when unit testing
  toggles: Toggles<string>;
  extLinkEnv: ExtLinkEnv;
  localeProductStates: NormalizedCacheObject;
  history: History;
  postRender: () => void;
};

// APOLLO V3 is off for Prod so this console should only appear on stage
if (USE_APOLLO_V3 && !HIDE_CONSOLE_LOGS) {
  console.log('Apollo V3 is active for CRA WWW');
}

const App: React.FC<React.PropsWithChildren<Props>> = ({
  envFlags,
  errorHandler,
  oneWellnessClient,
  commercetoolsClient,
  locale,
  localeModalActive,
  overrideLocation,
  restClient,
  publishedLocaleCopy,
  store,
  productState,
  toggles,
  extLinkEnv,
  localeProductStates,
  history,
  postRender,
}) => {
  useEffect(() => {
    postRender();
  }, [postRender]);

  return (
    <ErrorBoundary
      renderError={() => (
        <>
          <OneTrustStyles />
          <SimpleErrorPage />
        </>
      )}
      reportError={errorHandler.reportError}
      errorContext={{ tags: { component: 'app' } }}
    >
      <CodeOwnerErrorBoundary>
        {/* prevent search engine indexing on non-production pages and new all new locale subpaths*/}
        {!envFlags.isProd && <NoIndexMetadataTag />}
        <ErrorReporterContext.Provider value={{ errorReporter: errorHandler }}>
          <OneTrustTimeoutReporter />
          <ChatStyles />
          <OneTrustStyles />
          <Provider store={store} context={ReactReduxContext}>
            <SplitTestingProvider
              pathname={history.location.pathname}
              optimizelyProjectId={OPTIMIZELY_KEY}
            >
              <ToggleProvider features={toggles}>
                <ChatBotScript envFlags={envFlags} locale={locale} />
                <DatadogReporterTracker />
                <LocaleProvider envLocale={locale}>
                  <Internationalize publishedLocaleCopy={publishedLocaleCopy}>
                    <GlobalUiStateProvider>
                      <LinkComponentProvider LinkComponent={Link}>
                        <ConnectedRouter history={history} context={ReactReduxContext}>
                          <ExtLinkEnvProvider extLinkEnv={extLinkEnv}>
                            <OAuthProvider>
                              <ClientProvider client={restClient}>
                                <GraphQLProvider
                                  env={extLinkEnv}
                                  toggles={toggles}
                                  localeProductStates={localeProductStates}
                                >
                                  <OneWellnessClientProvider client={oneWellnessClient}>
                                    <CommercetoolsClientProvider
                                      client={commercetoolsClient}
                                    >
                                      <ApolloProviderV3
                                        useApolloV3={USE_APOLLO_V3}
                                        locale={locale}
                                      >
                                        <ProgramProvider>
                                          <CitizensProvider>
                                            <ProductStatesProvider
                                              productState={productState}
                                            >
                                              <CartProvider>
                                                <ErrorBoundary
                                                  renderError={() => <ErrorPage />}
                                                  reportError={errorHandler.reportError}
                                                  errorContext={{
                                                    tags: { component: 'page' },
                                                  }}
                                                >
                                                  <CodeOwnerErrorBoundary>
                                                    <CRAUserInfoProvider>
                                                      <RecalculatedFeaturesProvider>
                                                        <LogoutCookieProvider>
                                                          <LocaleCookieProvider>
                                                            <TrackingProvider>
                                                              {ALLOW_COPY_XRAY && (
                                                                <CopyXray />
                                                              )}
                                                              <ScrollToTop>
                                                                <RestrictLocaleAccess
                                                                  disabled={
                                                                    !(
                                                                      envFlags.isProd ||
                                                                      envFlags.isStaging
                                                                    )
                                                                  }
                                                                  locale={locale}
                                                                  restrictedLocales={[]}
                                                                  isOverridePath={pathnameMatchesOverride(
                                                                    window.location
                                                                      .pathname,
                                                                  )}
                                                                  storage={
                                                                    window.localStorage
                                                                  }
                                                                >
                                                                  <ConnectedSwitch
                                                                    location={
                                                                      overrideLocation
                                                                    }
                                                                  >
                                                                    <Route
                                                                      path="/:path+/"
                                                                      exact
                                                                      strict
                                                                      component={
                                                                        UpdateURL
                                                                      }
                                                                    />
                                                                    {LOCALE_TOGGLE
                                                                      ? localisedRoutes
                                                                      : routes}
                                                                    <RouteNotFound page />
                                                                  </ConnectedSwitch>
                                                                  <LoginModal />
                                                                  <ProductPromoModal />
                                                                  {localeModalActive && (
                                                                    <GeoModalSwitcher />
                                                                  )}
                                                                </RestrictLocaleAccess>
                                                                <PrivacyBanner />
                                                              </ScrollToTop>
                                                            </TrackingProvider>
                                                          </LocaleCookieProvider>
                                                        </LogoutCookieProvider>
                                                      </RecalculatedFeaturesProvider>
                                                    </CRAUserInfoProvider>
                                                  </CodeOwnerErrorBoundary>
                                                </ErrorBoundary>
                                                <FeatureTogglePanel />
                                                <Panel />
                                              </CartProvider>
                                            </ProductStatesProvider>
                                          </CitizensProvider>
                                        </ProgramProvider>
                                      </ApolloProviderV3>
                                    </CommercetoolsClientProvider>
                                  </OneWellnessClientProvider>
                                </GraphQLProvider>
                              </ClientProvider>
                            </OAuthProvider>
                          </ExtLinkEnvProvider>
                        </ConnectedRouter>
                      </LinkComponentProvider>
                    </GlobalUiStateProvider>
                  </Internationalize>
                </LocaleProvider>
              </ToggleProvider>
            </SplitTestingProvider>
          </Provider>
        </ErrorReporterContext.Provider>
      </CodeOwnerErrorBoundary>
    </ErrorBoundary>
  );
};

export default App;
