import { put, takeEvery } from 'redux-saga/effects';
import type { A } from '@peloton/auth';
import { UserReducerActionType } from '@peloton/auth';
import type { User } from '@ecomm/auth';
import { DigitalErrors, Severity } from '../models';
import { errorCheckout, resolveError } from '../redux';

export const checkSubscriptions = function* ({
  payload: { hasDeviceSubscription, hasDigitalSubscription },
}: SuccessAction) {
  if (hasDeviceSubscription || hasDigitalSubscription) {
    yield put(errorCheckout(Severity.Warning, DigitalErrors.ExistingSub));
  }
};

export const resolve = function* () {
  yield put(resolveError(Severity.Warning));
};

const watcherSaga = function* () {
  yield takeEvery(UserReducerActionType.REQUEST_SUCCESS, checkSubscriptions);
  yield takeEvery(UserReducerActionType.LOGOUT_SUCCESS, resolve);
};

export default watcherSaga;

export type SuccessAction = A<UserReducerActionType.REQUEST_SUCCESS, User>;
