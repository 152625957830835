import { LOCATION_CHANGE } from 'connected-react-router';
import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import type { ID } from '@ecomm/models';
import { isLoaded } from '@ecomm/models';
import type { Instructor } from '../../../models';
import { addInstructors } from '../data';
import { fetchInstructors } from './api';
import { fail, getUIState, toMatchInstructorsPath, request, success } from './redux';

type Response = {
  entities: {
    instructors: Record<ID, Instructor>;
  };
};

export const fetch = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield put(request());

  try {
    const response: Response = yield call(fetchInstructors, client);
    yield all([put(addInstructors(response.entities.instructors)), put(success())]);
  } catch (err) {
    const error = new DomainError('Error fetching instructors', err);
    yield all([put(reportError({ error })), put(fail(error))]);
  }
};

export const checkFetched = function* (): SagaIterator {
  const uiState = yield select(getUIState);

  if (!isLoaded(uiState)) {
    yield call(fetch);
  }
};

export const checkRoute = function* (): SagaIterator {
  const match = yield select(toMatchInstructorsPath());

  if (match) {
    yield call(checkFetched);
  }
};

const sagas = function* () {
  yield takeLatest(LOCATION_CHANGE, checkRoute);
};

export default sagas;
