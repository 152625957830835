import { toPairs } from 'ramda';
import { createSelector } from 'reselect';
import { toTime } from '@peloton/time';
import type { ID } from '@ecomm/models';
import type { PelotonClass } from '../../models';
import type { ReducerState } from '../redux';

export enum ActionType {
  Add = 'ecomm/classes/ADD',
}

export type ReduxClass = {
  instructor: ID;
  scheduledTime: number;
  title: string;
};

export type State = Record<ID, ReduxClass>;

export const defaultState: State = {};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.Add:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default reducer;

export const addClasses = (classes: State): AddClassesAction => ({
  type: ActionType.Add,
  payload: classes,
});

type AddClassesAction = {
  type: ActionType.Add;
  payload: State;
};

type Action = AddClassesAction;

const getState = (state: ReducerState) => state.instructorsPage.classes.data;

const getID = (_: ReducerState, props: { id: ID }) => props.id;

export const getClass = createSelector(
  getState,
  getID,
  (state: State, classId: ID): PelotonClass => {
    const { instructor, scheduledTime, title } = state && state[classId];
    return {
      instructor,
      scheduledTime: toTime(scheduledTime),
      title,
    };
  },
);

export const getClassesByInstructor = createSelector(
  getState,
  getID,
  (classes: State, instructorId: ID) =>
    toPairs(classes)
      .filter(([_, reduxClass]) => reduxClass.instructor === instructorId)
      .map(([id]) => id),
);
