import { createMatchSelector } from 'connected-react-router';
import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { UIState } from '@ecomm/models';
import { Status } from '@ecomm/models';
import { path } from '../../../routes';
import type { ReducerState } from '../../redux';

export enum ActionType {
  Request = 'ecomm/instructors/REQUEST',
  Success = 'ecomm/instructors/SUCCESS',
  Fail = 'ecomm/instructors/FAIL',
}

export type State = UIState;

export const initialState: State = { status: Status.Init };

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.Request:
      return { status: Status.Loading };

    case ActionType.Fail:
      return {
        status: Status.Failed,
        exception: action.payload.exception,
      };

    case ActionType.Success:
      return { status: Status.Loaded };

    default:
      return state;
  }
};

export default reducer;

type RequestAction = {
  type: ActionType.Request;
};

export const request = (): RequestAction => ({
  type: ActionType.Request,
});

type SuccessAction = {
  type: ActionType.Success;
};

export const success = (): SuccessAction => ({
  type: ActionType.Success,
});

type FailAction = {
  type: ActionType.Fail;
  payload: {
    exception: Exception;
  };
};

export const fail = (error: Error): FailAction => ({
  type: ActionType.Fail,
  payload: {
    exception: toException(error.message),
  },
});

type Action = RequestAction | SuccessAction | FailAction;

export const getUIState = (state: ReducerState) =>
  state.instructorsPage.instructors.fetcher;

export const toMatchInstructorsPath = () => createMatchSelector(path);
