import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, takeEvery, select } from 'redux-saga/effects';
import { assertClientContext, CLIENT_CONTEXT } from '@peloton/api';
import { fetchUserExists } from '@peloton/auth';
import { hasUserEmailErrors } from '@ecomm/checkout/redux';
import { userDoesExist, userDoesNotExist } from '@ecomm/checkout/redux/user';
import { DigitalErrors, Severity } from '@ecomm/quick-checkout/models';
import { errorCheckout, resolveError } from '@ecomm/quick-checkout/redux';
import type { CheckUserExistsAction } from '@onewellness/redux/checkout';
import { CheckUserExistsActionTypes } from '@onewellness/redux/checkout';

export const checkExistingUserSaga = function* ({
  payload,
}: CheckUserExistsAction): SagaIterator {
  const { email = '' } = payload;
  const hasErrors = yield select(hasUserEmailErrors);

  if (hasErrors || !email) {
    return;
  }

  const client = yield getContext(CLIENT_CONTEXT);
  assertClientContext(client);

  const exists = yield call(fetchUserExists, client, email);

  if (exists) {
    yield all([
      put(userDoesExist()),
      put(errorCheckout(Severity.Warning, DigitalErrors.ExistingSub)),
    ]);
  } else {
    yield all([put(userDoesNotExist()), put(resolveError(Severity.Warning))]);
  }
};

const watcher = function* () {
  yield takeEvery(CheckUserExistsActionTypes.CheckUserExists, checkExistingUserSaga);
};

export default watcher;
