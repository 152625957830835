import type { Client } from '@peloton/api';
import { pipeDataTwice, pipeError, throwError } from '@peloton/api';
import { toCountry } from '@peloton/internationalize';
import type { Address, ContactInfo } from '@ecomm/models';
import type { ShippingErrorCode } from '../models';
import { toShippingErrorCode } from '../models';
import { toCart as lightCartMapper } from './lightMapper';
import { runWithCartQueue } from './synchronizer';
import type { ApiShippingInfo, ApiShippingInfoName, LightCartAPIWrapped } from './types';

const UPDATE_SHIPPING_URL = '/ecomm/cart';

type LimitAddressPayload = {
  skipAddressValidation: boolean;
  address: Pick<Address, 'city' | 'state' | 'postalCode' | 'country'>;
};

export const _updateShipping = <Info extends LimitAddressPayload | ContactInfo>(
  api: Client,
  info: Info,
) =>
  api
    .put(UPDATE_SHIPPING_URL, toApiShippingInfo(info))
    .then(pipeDataTwice(data => lightCartMapper((data as LightCartAPIWrapped).cart)))
    .catch(pipeError(throwError<ShippingErrorCode>(toShippingErrorCode)));

export const updateShipping = runWithCartQueue(_updateShipping);

const toApiShippingInfoName = (info: ContactInfo): ApiShippingInfoName =>
  info.address.addressee
    ? {
        shippingAddressee: info.address.addressee,
      }
    : {
        shippingAddresseeFirstName: info.name.first,
        shippingAddresseeLastName: info.name.last,
      };

// eslint-disable-next-line no-control-regex
const stripUnicode = (str: string) => str.replace(/[^\x00-\x7F]/g, '');

export const toApiShippingInfo = (
  info: LimitAddressPayload | ContactInfo,
): Partial<ApiShippingInfo> =>
  'skipAddressValidation' in info
    ? {
        skip_address_validation: true,
        renders_light_cart: true,
        shippingCity: info.address.city,
        shippingState: info.address.state,
        shippingPostalCode: stripUnicode(info.address.postalCode),
        shippingCountry: toCountry().toUpperCase(),
      }
    : {
        ...toApiShippingInfoName(info),
        shippingStreetAddress1: info.address.line1,
        shippingStreetAddress2: info.address.line2,
        shippingCity: info.address.city,
        shippingState: info.address.state,
        shippingPostalCode: stripUnicode(info.address.postalCode),
        shippingPhoneNumber: stripUnicode(info.phone),
        shippingCountry: toCountry().toUpperCase(),
        renders_light_cart: true,
      };
