import type { ParsedUrlQuery } from 'querystring';
import { parse } from 'querystring';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useQueryParams = (): ParsedUrlQuery => {
  const { search } = useLocation();
  return useMemo(() => parse(search.slice(1)), [search]);
};

export default useQueryParams;
