import { Eyebrow } from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import type { ButtonProps } from '@ecomm/buttons';
import { a11yOutline, ButtonWithSpinner, Button5 } from '@ecomm/buttons';
import { useKeyValueData } from '@ecomm/copy';
import { InputWithLabelError } from '@ecomm/forms';
import { b4 } from '@ecomm/typography';

type TestingInfo = {
  page: string;
  section: string;
};

type BtnProps = {
  buttonText: React.ReactNode;
  buttonType?: React.ComponentType<React.PropsWithChildren<ButtonProps>>;
  buttonStyle?: React.CSSProperties;
};

export type SubmitStatus = 'init' | 'submitting' | 'success';
type SetSubmitStatus = (status: SubmitStatus) => void;

export type Props = BtnProps & {
  className?: string;
  formLabelId?: string;
  testingInfo?: TestingInfo;
  toHandleFormSubmit: (
    setSubmitStatus: SetSubmitStatus,
  ) => (email: string, emailRecipient: string) => void;
  hasRecipientEmail?: boolean;
};

const Form: React.FC<React.PropsWithChildren<Props>> = ({
  buttonStyle,
  buttonText,
  buttonType = Button5,
  className,
  formLabelId,
  testingInfo,
  hasRecipientEmail,
  toHandleFormSubmit,
  children,
}) => {
  const [email, setEmail] = React.useState('');
  const [secondEmail, setSecondEmail] = React.useState('');
  const [isActive, setIsActive] = useState(false);
  const [isRecipientActive, setIsRecipientActive] = useState(false);
  const [submitStatus, setSubmitStatus] = React.useState<SubmitStatus>('init');

  const successMessage = useKeyValueData(`emailCaptureSuccess`);
  const failureMessage = useKeyValueData(`emailCaptureFailure`);
  const emailPlaceholder = useKeyValueData(`emailCapturePlaceholder`);
  const secondEmailPlaceholder = useKeyValueData(`secondEmailCapturePlaceholder`);
  const testPrefix = testingInfo && `${testingInfo.page}_${testingInfo.section}`;
  const ctaLinkTestId = testPrefix && `${testPrefix}_ctaLink`;
  const ctaCopyTestId = testPrefix && `${testPrefix}_ctaCopy`;
  const handleFormSubmit = toHandleFormSubmit(setSubmitStatus);

  const isFormDisabled = submitStatus !== 'init';

  const onFormSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleFormSubmit(email, secondEmail);
  };

  return (
    <form aria-labelledby={formLabelId} className={className} onSubmit={onFormSubmit}>
      <div>
        <InputContainer className="input-container">
          <EmailInput
            label={emailPlaceholder}
            type="email"
            className="email-input"
            autoComplete="email"
            placeholder={emailPlaceholder}
            disabled={isFormDisabled}
            onChange={(event: React.SyntheticEvent<HTMLInputElement>) =>
              setEmail(event.currentTarget.value)
            }
            pattern={EMAIL_REGEX}
            customErrorMessage={failureMessage}
            required
            isActive={isActive}
            onFocus={() => setIsActive(true)}
            onBlur={() => setIsActive(!!email)}
          />
          {hasRecipientEmail && (
            <EmailInput
              label={secondEmailPlaceholder}
              type="email"
              className="email-input recipient-input"
              autoComplete="email"
              placeholder={secondEmailPlaceholder}
              disabled={isFormDisabled}
              onChange={(event: React.SyntheticEvent<HTMLInputElement>) =>
                setSecondEmail(event.currentTarget.value)
              }
              pattern={EMAIL_REGEX}
              customErrorMessage={failureMessage}
              isActive={isRecipientActive}
              onFocus={() => setIsRecipientActive(true)}
              onBlur={() => setIsRecipientActive(!!secondEmail)}
            />
          )}
          {children}
          <SubmitButton
            className="submit-button"
            style={buttonStyle}
            processing={submitStatus === 'submitting'}
            button={buttonType}
            disabled={isFormDisabled}
            data-test-id={ctaLinkTestId}
          >
            <Eyebrow size="medium" as="span" data-test-id={ctaCopyTestId}>
              {buttonText}
            </Eyebrow>
          </SubmitButton>
        </InputContainer>

        <SuccessMessage
          className="success-message"
          role="region"
          aria-live="polite"
          tabIndex={-1}
        >
          {submitStatus === 'success' && successMessage}
        </SuccessMessage>
      </div>
    </form>
  );
};

export default Form;

const EMAIL_REGEX = '^.+@.+\\..{2,}$';

export const EmailInput = styled(InputWithLabelError)`
  width: 100%;

  input {
    height: 50px !important;

    &:disabled {
      opacity: 1 !important;
    }
  }

  ${media.smallScreen`
    max-width: 280px;

    input {
      margin-bottom: 10px;
    }
  `}

  ${media.tablet`
    overflow: initial;
  `}
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 480px;
  text-align: left;

  ${media.smallScreen`
    flex-direction: row;
    margin: 0;
  `}
`;

export const SuccessMessage = styled.span`
  display: block;
  margin-top: 6px;
  margin-bottom: 10px;
  color: inherit;
  ${b4}
`;

const SubmitButton = styled(ButtonWithSpinner)`
  ${a11yOutline}
  margin-top: 10px;

  button {
    margin-bottom: 10px;
    min-width: 100% !important;
    span {
      color: inherit;
    }
  }

  ${media.smallScreen`
    margin-top: 0;
    margin-left: 10px;
    margin-bottom: 0;

    button {
      padding: 0 10px;
      min-width: 96px !important;
    }
  `}
`;
