import { path, pickAll } from 'ramda';
import type { CtaWithDriftInteraction } from '../models.generated';
import { useCtaWithDriftInteractionQuery } from '../queries/contentTypes/ctaWithDrift.generated';

export type CtaWithDriftInteractionData = {
  key?: CtaWithDriftInteraction;
  text: string;
  interactionId: number;
};

type CtaWithDriftInteractionResponse = CtaWithDriftInteractionData & {
  interactionId: string;
};

export const transformCtaWithDriftInteractionData = (
  response: object,
): CtaWithDriftInteractionData => {
  const { key, text, interactionId }: CtaWithDriftInteractionResponse = pickAll(
    ['key', 'text', 'interactionId'],
    path(['data', 'ctaWithDriftInteraction'], response) || {},
  );

  const fallBackText = `CtaWithDriftInteraction: ${key}`;

  return {
    key,
    text: text || fallBackText,
    interactionId: interactionId ? parseInt(interactionId, 10) : 0,
  };
};

const useCtaWithDriftInteractionData = (
  id: CtaWithDriftInteraction,
): CtaWithDriftInteractionData => {
  const res = useCtaWithDriftInteractionQuery({ variables: { id } });

  return transformCtaWithDriftInteractionData(res);
};

export default useCtaWithDriftInteractionData;
