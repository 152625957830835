import type { PromoHero } from '@ecomm/copy';

export const arrayEquals = <T>(a: T[], b: T[]) => {
  return a.length === b.length && a.every((itemA, index) => itemA === b[index]);
};

type CarouselProps = {
  cmsIds: PromoHero[];
};

export const cmsIdsMemo = (prevProps: CarouselProps, nextProps: CarouselProps) => {
  return arrayEquals(prevProps.cmsIds, nextProps.cmsIds);
};
