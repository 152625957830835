import { IDME_CLIENT_ID } from '@peloton/app-config';
import type { ExtLinkEnv } from '@peloton/external-links';
import { toHref, toWWWLink } from '@peloton/external-links';

export const generateIdMeAuthorizationLink = (extLinkEnv: ExtLinkEnv) => {
  const redirectUri = getRedirectUri(extLinkEnv);
  const scopes = 'medical,military,nurse,responder,student,teacher';

  // TODO: update IDME_CLIENT_ID env variable with stage client ID after ID.me final review
  const url = `https://groups.id.me/?client_id=${IDME_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code&scopes=${scopes}`;
  return url;
};

const getRedirectUri = (extLinkEnv: ExtLinkEnv) => {
  return toHref(toWWWLink('/idme/callback'), extLinkEnv);
};
