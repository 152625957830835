import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { TransactionState } from '@ecomm/models';
import { Status, toUIState } from '@ecomm/models';

export enum ActionTypes {
  RegistrationFailed = 'ecomm/registration/REGISTRATION_REQUEST_FAILED',
  RegistrationRequested = 'ecomm/registration/REGISTRATION_REQUESTED',
  RegistrationSucceeded = 'ecomm/registration/REGISTRATION_REQUEST_SUCCEEDED',
}

export type State = TransactionState;

export const defaultState = toUIState(Status.Init) as TransactionState;

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionTypes.RegistrationRequested:
      return toUIState(Status.Loading);

    case ActionTypes.RegistrationFailed: {
      const exception = action.payload.exception;
      return toUIState(Status.Failed, exception);
    }
    case ActionTypes.RegistrationSucceeded:
      return toUIState(Status.Init);

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  register: State;
};

export const register = (): RequestAction => ({
  type: ActionTypes.RegistrationRequested,
});

export const succeedRegistration = (): SuccessAction => ({
  type: ActionTypes.RegistrationSucceeded,
});

export const failRegistration = (errorId: string = 'default'): FailAction => ({
  type: ActionTypes.RegistrationFailed,
  payload: { exception: toException(`registration.errors.${errorId}`) },
});

export type RequestAction = {
  type: ActionTypes.RegistrationRequested;
};

export type SuccessAction = {
  type: ActionTypes.RegistrationSucceeded;
};

export type FailAction = {
  type: ActionTypes.RegistrationFailed;
  payload: { exception: Exception };
};

type Action = RequestAction | SuccessAction | FailAction;
