import React from 'react';

export const useWindowActive = (): boolean => {
  const [windowActive, setWindowActive] = React.useState(true);

  React.useEffect(() => {
    const updateWindowState = () =>
      setWindowActive(document.visibilityState === 'visible');

    document.addEventListener('visibilitychange', updateWindowState);

    // Call it on mount for the true initial value
    if (document.visibilityState !== 'visible') {
      updateWindowState();
    }

    return () => {
      document.removeEventListener('visibilitychange', updateWindowState);
    };
  }, []);

  return windowActive;
};
