import { PRODUCTION } from '@peloton/app-config';
import { getCitizens } from './api';

const qa1Host = 'qa1.onepeloton.com';
const prodHost = 'api.onepeloton.com';

const toHostUrl = (isProdEnv?: boolean) => (isProdEnv ? prodHost : qa1Host);

const toRelativeUrl = (cartId: string, referenceId: string) =>
  `ecomm/citizens/initiate_flow?cart_id=${cartId}&same_session=true&reference_id=${referenceId}`;

export const redirectToCitizens = (cartId: string, { protocol } = window.location) => {
  const isProd = PRODUCTION as boolean | undefined;
  const referenceId = getCitizens().getReferenceId();
  const url = `${protocol}//${toHostUrl(isProd)}/${toRelativeUrl(cartId, referenceId)}`;
  window.location.href = url;
};
