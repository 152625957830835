import { pathOr } from 'ramda';
import type { Entities, Product } from '../models';

export enum ActionType {
  Add = 'ecomm/shop/ADD_PRODUCTS',
}

export type State = Entities<Product>;

export const defaultState = {};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.Add: {
      const products = pathOr({}, ['payload', 'products'], action);
      return {
        ...state,
        ...products,
      };
    }

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  products: State;
};

export const addProducts = (products: Entities<Product>): AddProductsAction => ({
  type: ActionType.Add,
  payload: { products },
});

type AddProductsAction = {
  type: ActionType.Add;
  payload: { products: Entities<Product> };
};

type Action = AddProductsAction;
