import type { Locale } from '@peloton/internationalize';

export const ACCESS_OVERRIDE_ENDPOINT = '/grant-access-override';
export const ACCESS_OVERRIDE_KEY_PREFIX = 'access-override';
export const ACCESS_OVERRIDE_KEY_VALUE = 'true';

export type Storage = {
  getItem(item: string): any;
  setItem(item: string, value: any): void;
};

export const toAccessOverrideKey = (locale: Locale) =>
  `${ACCESS_OVERRIDE_KEY_PREFIX}--${locale}`;

export const pathnameMatchesOverride = (pathname: string) =>
  pathname === ACCESS_OVERRIDE_ENDPOINT;

export const hasLocaleRestrictionOverrideForLocale = ({
  isOverridePath,
  storage,
  locale,
}: {
  isOverridePath: boolean;
  storage: Storage;
  locale: Locale;
}) =>
  isOverridePath ||
  (storage && storage.getItem(toAccessOverrideKey(locale)) === ACCESS_OVERRIDE_KEY_VALUE);
