import { grey } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { track } from '@peloton/analytics';
import { Markdown } from '@peloton/copy';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import { media } from '@peloton/styles';
import { ButtonThemes, SmallSize } from '@ecomm/buttons';
import type { CtaWithUrl, HeaderBodyImage, PromotionalText } from '@ecomm/copy';
import {
  useCtaWithUrlData,
  useHeaderBodyImageData,
  usePromotionalTextData,
} from '@ecomm/copy';
import { Image } from '@ecomm/image';
import type { LinkProps } from '@ecomm/links';
import { Link } from '@ecomm/links';
import { b6, hb3 } from '@ecomm/typography';

type Props = {
  hbiCmsId: HeaderBodyImage;
  ctaCmsId: CtaWithUrl;
  footnoteCmsId: PromotionalText;
  closeHandler: () => void;
};

const CtaPromoModal: React.FC<React.PropsWithChildren<Props>> = ({
  hbiCmsId,
  ctaCmsId,
  footnoteCmsId,
  closeHandler,
}) => {
  const {
    header,
    body,
    image: {
      mobile: { url: mobileUrl, description: mobileDescription },
      tablet: { url: tabletUrl },
      desktop: { url: desktopUrl },
    },
  } = useHeaderBodyImageData(hbiCmsId);
  const footnote = usePromotionalTextData(footnoteCmsId);
  const { text, url } = useCtaWithUrlData(ctaCmsId);

  return (
    <Container>
      <StyledImage
        src={mobileUrl}
        alt={mobileDescription}
        breakpointOptions={{
          mobile: { width: 600 },
          tablet: {
            width: 800,
            src: tabletUrl,
          },
          desktop: {
            width: 800,
            src: desktopUrl,
          },
        }}
      />
      <ContentContainer>
        <Header data-test-id="cta-promo-modal-header">{header}</Header>
        <Body data-test-id="cta-promo-modal-body">
          <Markdown
            content={body}
            markdown={{
              renderers: {
                paragraph: 'p',
              },
            }}
          />
        </Body>
        <Button
          to={url}
          onClick={() => clickHandler(url, hbiCmsId as string, closeHandler)}
          data-test-id="cta-promo-modal-cta"
        >
          {text}
        </Button>
        <Footnote data-test-id="cta-promo-modal-footnote">
          <Markdown content={footnote} />
        </Footnote>
      </ContentContainer>
    </Container>
  );
};

const clickHandler = (url: string, unitName: string, closeHandler: () => void) => {
  track({
    event: 'Clicked Link',
    properties: {
      linkTo: url,
      parent: 'Modal with CTA',
      page: getCurrentRelativePathname(),
      unitName: unitName,
    },
  });
  closeHandler();
};

export default CtaPromoModal;

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  padding: 40px 20px;

  ${media.smallScreen`
    margin-top: 0px;
  `}

  ${media.tablet`
    padding: 40px;
  `}

  ${media.desktop`
    padding: 40px 60px;
  `}
`;

const StyledImage = styled(Image)`
  width: 100%;
`;

const Header = styled.h2`
  ${hb3}
  margin-bottom: 1.5rem;
`;

const Body = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 460px;

  ${media.tablet`
    max-width: none;
  `}

  p {
    line-height: 1.5;
    margin-bottom: 2rem;
  }
`;

const Button = (styled(Link)`
  display: none;
  ${ButtonThemes.Button1};
  ${SmallSize}
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin-top: 20px;
  min-width: 100%;

  ${media.tablet`
    margin: 0 24px;
    min-width: auto;
  `}
` as any) as React.ComponentType<React.PropsWithChildren<LinkProps>>;

const Footnote = styled.p`
  ${b6}
  color: ${grey[70]};
  margin-top: 2rem;

  a {
    text-decoration: underline;
  }
`;
