import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { getValidPaths } from '@ecomm/reviews/utils/getValidPaths';
import { Route } from '@ecomm/router';

const ReviewsHandler = asyncComponent(
  () => import('./Handler' /* webpackChunkName: "ReviewsQAndAPage" */),
);

const Routes = [
  <Route
    exact
    page
    key="reviews-q-and-a-page"
    path={getValidPaths()}
    component={ReviewsHandler}
  />,
];

export default Routes;
