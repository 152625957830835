import type { SagaIterator } from 'redux-saga';
import { call, getContext, put, takeEvery, all } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { attemptCouponApply, attemptCouponRemove } from '../api';
import type { ApplyCoupon, RemoveCoupon } from '../redux';
import {
  CouponActions,
  applyCouponFailure,
  applyCouponSuccess,
  removeCouponSuccess,
  removeCouponFailure,
  loadCartSuccess,
} from '../redux';

export const applyCouponSaga = function* (
  client: Client,
  action: ApplyCoupon,
): SagaIterator {
  try {
    const cart = yield call(attemptCouponApply, client, action.payload);
    yield all([put(loadCartSuccess(cart)), put(applyCouponSuccess())]);
  } catch (e) {
    yield put(applyCouponFailure(e));
  }
};

export const removeCouponSaga = function* (
  client: Client,
  action: RemoveCoupon,
): SagaIterator {
  try {
    const cart = yield call(attemptCouponRemove, client, action.payload);
    yield all([put(loadCartSuccess(cart)), put(removeCouponSuccess())]);
  } catch (e) {
    yield put(removeCouponFailure(e));
  }
};

export const couponSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(CouponActions.Apply, applyCouponSaga, client);
  yield takeEvery(CouponActions.RemoveCoupon, removeCouponSaga, client);
};

export default couponSaga;
