import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

const routes = [
  <Route
    key="reasons-page"
    path="/reasons"
    page={true}
    toggleId="reasons"
    component={asyncComponent(() => import('./Page'))}
  />,
];

export default routes;
