import { getDates } from './deliveryDates/data';
import { getUIState as getDeliveryDatesUIState } from './deliveryDates/fetcher/redux';
import type { ReducerState as TReducerState } from './redux';
import reducer from './redux';
import type { RequestAction as TScheduleDeliveryRequestAction } from './scheduleDelivery/redux';
import {
  getUIState as getScheduleDeliveryUIState,
  scheduleDelivery,
  unscheduleDelivery,
} from './scheduleDelivery/redux';

export default reducer;

export {
  getDates,
  getDeliveryDatesUIState,
  getScheduleDeliveryUIState,
  scheduleDelivery,
  unscheduleDelivery,
};

export type ReducerState = TReducerState;
export type ScheduleDeliveryRequestAction = TScheduleDeliveryRequestAction;
