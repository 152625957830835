import type { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { updateEmailWithPolicy } from '@ecomm/cart/api';
import { selectCart } from '@ecomm/cart/redux';
import { redirectToCitizens } from '../citizens/redirectToCitizens';
import type { SubmitFinancingAction } from '../redux';
import { citizensSubmitted, submitFinancingFailure } from '../redux';

export const submitSaga = function* (
  client: Client,
  action: SubmitFinancingAction,
): SagaIterator {
  const { email } = action.payload;
  try {
    yield put(citizensSubmitted());
    yield call(updateEmailWithPolicy, client, email);
    const { cart } = yield select(selectCart);

    yield call(redirectToCitizens, cart?.id);
  } catch (error) {
    yield put(submitFinancingFailure(error));
  }
};
