import type { SWRConfiguration } from 'swr';
import { ALLOW_PREVIEW_COPY } from '@peloton/app-config';

export const IMMUTABLE: SWRConfiguration = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export const REVALIDATE_DEFAULT: SWRConfiguration = {
  revalidateOnFocus: true,
};

export const REVALIDATE_PREVIEW_ONLY: SWRConfiguration = ALLOW_PREVIEW_COPY
  ? REVALIDATE_DEFAULT
  : IMMUTABLE;
