import { useCallback } from 'react';
import { useTracking } from 'react-tracking';
import { isPrepaidCreditItem } from '../cart-summary/cart-items/models';
import type { CartQuery } from '../graphql/queries/Cart.generated';
import { toSegmentProducts, toCategory, toName } from './segmentMappings';

const toEvent = (cart: CartQuery['cart']) => {
  const numberOfCredits =
    (cart?.items ?? []).find(isPrepaidCreditItem)?.numberOfCredits ?? 0;
  return {
    event: 'Cart Viewed',
    properties: {
      name: toName(cart?.items ?? []),
      cart_id: cart?.id,
      category: toCategory(cart),
      hasAccessory: cart?.hasAccessories ?? false,
      price: cart?.total?.amount ?? 0,
      products: toSegmentProducts(cart?.items ?? []),
      hasGift: cart?.isGift ?? false,
      hasSubscriptionGift: cart?.hasPrepaidCredit ?? false,
      giftSubscriptionDuration: numberOfCredits ? `${numberOfCredits} months` : null,
    },
  };
};

const useCartViewedAnalytics = () => {
  const { trackEvent } = useTracking();

  const trackCartViewed = useCallback(
    (data: CartQuery | undefined) => {
      trackEvent(toEvent(data?.cart));
    },
    [trackEvent],
  );

  return {
    trackCartViewed,
  };
};

export default useCartViewedAnalytics;
