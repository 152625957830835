import { LOCATION_CHANGE } from 'connected-react-router';
import type { match as Match } from 'react-router';
import type { SagaIterator } from 'redux-saga';

import { all, call, getContext, put, select, take, takeLatest } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import type { ID } from '@ecomm/models';
import { isLoaded } from '@ecomm/models';
import { getInstructorByUsername, getInstructorsUIState } from '../../';
import { FetcherActionType } from '../../instructors';
import type { ReduxClass } from '../data';
import { addClasses } from '../data';
import { fetchUpcomingClasses } from './api';
import {
  fail,
  getUIState,
  toMatchInstructorsClassesPath,
  request,
  success,
} from './redux';

const sagas = function* () {
  yield takeLatest(LOCATION_CHANGE, checkRoute);
};

const checkRoute = function* () {
  const match: Match<{ username: string }> = yield select(
    toMatchInstructorsClassesPath(),
  );

  if (match && match.params.username) {
    yield call(checkInstructors, match.params.username);
  }
};

const checkInstructors = function* (username: string): SagaIterator {
  const instructorsUIState = yield select(getInstructorsUIState);

  if (isLoaded(instructorsUIState)) {
    yield call(validateInstructor, username);
  } else {
    while (true) {
      yield take(FetcherActionType.Success);
      yield call(validateInstructor, username);
    }
  }
};

const validateInstructor = function* (username: string) {
  const { id } = yield select(getInstructorByUsername, { username });
  if (id) {
    yield call(checkClasses, id);
  }
};

const checkClasses = function* (instructorId: ID): SagaIterator {
  const uiState = yield select(getUIState, instructorId);

  if (!isLoaded(uiState)) {
    yield call(fetch, instructorId);
  }
};

type Response = {
  entities: {
    classes: Record<ID, ReduxClass>;
  };
};

export const fetch = function* (instructorId: ID): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield put(request(instructorId));

  try {
    const response: Response = yield call(fetchUpcomingClasses, client, instructorId);
    yield all([put(addClasses(response.entities.classes)), put(success(instructorId))]);
  } catch (err) {
    const error = new DomainError('Error fetching classes', err);
    yield all([put(reportError({ error })), put(fail(instructorId, error))]);
  }
};

export default sagas;
