import { createSelector } from 'reselect';
import { Status } from '@ecomm/models';
import type { ShowroomProps } from './model';
import { getOperationalShowrooms } from './utils';

export type ShowroomsState = {
  showrooms: ShowroomProps[];
  fetcher: { status: Status };
};

export type State = { showrooms: ShowroomsState };

export enum ActionType {
  Success = 'ecomm/store/SUCCESS',
  Failed = 'ecomm/store/FAILED',
  Request = 'ecomm/store/REQUEST',
}

export type Action = ShowroomsFailed | ShowroomsSucceeded | ShowroomsRequested;

type ShowroomsFailed = {
  type: ActionType.Failed;
};

type ShowroomsSucceeded = {
  type: ActionType.Success;
  payload: ShowroomProps[];
};

type ShowroomsRequested = {
  type: ActionType.Request;
};

const initialState = {
  showrooms: [],
  fetcher: { status: Status.Init },
};

const reducer = (state: ShowroomsState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.Request:
      return { ...state, fetcher: { status: Status.Loading } };

    case ActionType.Success:
      return {
        ...state,
        fetcher: { status: Status.Loaded },
        showrooms: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;

export const loadShowroomsSuccess = (showrooms: ShowroomProps[]) => ({
  type: ActionType.Success,
  payload: showrooms,
});

export const showroomsRequest = () => ({
  type: ActionType.Request,
});

export const getState = (state: State) => {
  return state.showrooms.showrooms;
};

export const getOperationalShowroomsSelector = createSelector(
  getState,
  getOperationalShowrooms,
);
