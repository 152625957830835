import React from 'react';
import { Markdown } from '@peloton/copy';
import { ErrorBanner } from '@ecomm/banners';
import { useBannerData } from '@ecomm/copy';

const MaintenanceBanner: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { text } = useBannerData('downtime');

  return (
    <ErrorBanner>
      <p>
        <Markdown content={text} />
      </p>
    </ErrorBanner>
  );
};

export default MaintenanceBanner;
