import { pathOr } from 'ramda';
import type { Dimension, Entities } from '../models';

export enum ActionType {
  Add = 'ecomm/shop/ADD_DIMENSIONS',
}

export type State = Entities<Dimension>;

export const defaultState = {};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.Add: {
      const dimensions = pathOr({}, ['payload', 'dimensions'], action);
      return {
        ...state,
        ...dimensions,
      };
    }

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  dimensions: State;
};

export const addDimensions = (dimensions: Entities<Dimension>): AddDimensionsAction => ({
  type: ActionType.Add,
  payload: { dimensions },
});

type AddDimensionsAction = {
  type: ActionType.Add;
  payload: { dimensions: Entities<Dimension> };
};

type Action = AddDimensionsAction;
