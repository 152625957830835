import { Status } from '@ecomm/models';

type State = {
  postalCode: string;
  status: Status;
};

export type ReducerState = {
  postalCodeCheck: State;
};

export enum ActionType {
  SetStatus = 'www/postalCodeCheck/SET_STATUS',
  SetPostalCode = 'www/postalCodeCheck/SET_POSTAL_CODE',
}

export const setPostalCode = (postalCode: string) =>
  ({
    type: ActionType.SetPostalCode,
    payload: {
      postalCode,
    },
  } as const);

export const setStatus = (status: Status) =>
  ({
    type: ActionType.SetStatus,
    payload: {
      status,
    },
  } as const);

export type SetPostalCodeAction = ReturnType<typeof setPostalCode>;

export type SetStatusAction = ReturnType<typeof setStatus>;

type Action = SetPostalCodeAction | SetStatusAction;

const initialState: State = {
  postalCode: '',
  status: Status.Init,
};

const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.SetPostalCode:
      return { ...state, postalCode: action.payload.postalCode };
    case ActionType.SetStatus:
      return { ...state, status: action.payload.status };
    default:
      return state;
  }
};

export default reducer;
