import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';
const PATH = '/partner/verizon';

const route = (
  <Route
    component={asyncComponent(() => import('./Handler'))}
    exact
    key="verizon-pages"
    page
    path={PATH}
  />
);
export default route;
