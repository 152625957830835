import { spacing, Flex } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles/breakpoints';
import type { CtaWithUrlSetData } from '@ecomm/copy/helpers/types';
import LinkList from './LinkList';

const LinkColumn: React.FC<
  React.PropsWithChildren<{
    ctas: CtaWithUrlSetData | undefined;
    isCollection?: boolean;
  }>
> = ({ ctas, isCollection }) => (
  <StyledFlex
    flexDirection="column"
    gap={isCollection ? spacing[16] : spacing[12]}
    isCollection={isCollection!}
  >
    {ctas && <LinkList ctas={ctas} />}
  </StyledFlex>
);

export default LinkColumn;

const StyledFlex = styled(Flex)<{ isCollection?: boolean }>`
  flex-grow: 1;
  ${props =>
    props.isCollection ? media.desktopXLarge`width: 223px;` : `width: ${spacing[112]};`}
`;
