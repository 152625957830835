import { black, brand } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { ContentSizedDiv } from '@peloton/content-sized-div/ContentSizedDiv';
import { defaultTransition, hover } from '@peloton/styles';
import type { ChevronProps } from '@ecomm/icons';
import { Chevron, Orientation } from '@ecomm/icons';

type AccordionHeaderText = {
  opened?: React.ReactNode;
  closed?: React.ReactNode;
};

export type AccordionHeaderStateProps = AccordionState & AccordionHeaderText;

export type AccordionProps = React.HTMLAttributes<HTMLDivElement> & {
  duration?: number;
  headerText?: AccordionHeaderText;
  isOpen?: boolean;
  name?: string;
  openOnLoad?: boolean;
  renderHeader?: (headerProps: AccordionHeaderStateProps) => JSX.Element;
  onHeaderClick?: (_: boolean) => void;
};

type AccordionState = {
  isOpen: boolean;
};

type AccordionChevronProps = AccordionState & ChevronProps;

class Accordion extends React.PureComponent<AccordionProps, AccordionState> {
  constructor(props: AccordionProps) {
    super(props);

    this.state = this.props.openOnLoad ? { isOpen: true } : { isOpen: false };
  }

  public render() {
    const {
      children,
      duration,
      headerText,
      name,
      renderHeader,
      onHeaderClick,
      ...attrs
    } = this.props;
    const isOpen = this.props.isOpen || this.state.isOpen;

    const [controlName, contentName] = name
      ? [`${name}AccordionControl`, `${name}AccordionContent`]
      : ['accordionControl', 'accordionContent'];

    return (
      <Container {...attrs}>
        <button
          aria-controls={contentName}
          aria-expanded={isOpen}
          data-test-id="accordionHeader"
          id={controlName}
          onClick={e => {
            onHeaderClick?.(isOpen);
            this.handleHeaderClick(e);
          }}
        >
          {renderHeader ? (
            renderHeader({ isOpen, ...headerText })
          ) : (
            <HeaderBar {...headerText} isOpen={isOpen} />
          )}
        </button>
        <ParentDiv
          aria-hidden={!isOpen}
          aria-labelledby={controlName}
          duration={duration}
          id={contentName}
          role="region"
        >
          <AccordionContent data-test-id="accordionContent" isOpen={isOpen}>
            {children}
          </AccordionContent>
        </ParentDiv>
      </Container>
    );
  }

  private handleHeaderClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    this.setState({ isOpen: !this.state.isOpen });
  };
}

export const AccordionChevron: React.FC<
  React.PropsWithChildren<AccordionChevronProps>
> = ({ isOpen, className }) => (
  <StyledChevron
    aria-hidden="true"
    orientation={isOpen ? Orientation.Up : Orientation.Down}
    className={className}
  />
);

type AutoProps = React.HTMLProps<HTMLDivElement> & {
  isOpen: boolean;
  duration: number;
};

export const AutoAccordian: React.FC<React.PropsWithChildren<AutoProps>> = ({
  children,
  duration,
  isOpen,
}) => (
  <ParentDiv duration={duration} aria-hidden={!isOpen}>
    <AccordionContent data-test-id="autoAccordion" isOpen={isOpen}>
      {children}
    </AccordionContent>
  </ParentDiv>
);

const HeaderBar: React.FC<React.PropsWithChildren<AccordionHeaderStateProps>> = ({
  opened,
  closed,
  isOpen,
}) => (
  <Header>
    {isOpen ? opened : closed}
    <StyledAccordionChevron isOpen={isOpen} />
  </Header>
);

const Container = styled.div`
  > button {
    width: 100%;
  }
`;

const Header = styled.div`
  align-items: center;
  background-color: ${black};
  color: white;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  ${defaultTransition()}
  ${hover`
    background-color: ${brand.darkest};
  `}
`;

const StyledAccordionChevron = styled(AccordionChevron)`
  fill: white;
  height: 12px;
  width: 12px;
  margin-left: 10px;
`;

const StyledChevron = styled(Chevron)`
  ${defaultTransition('transform')}
`;

export default Accordion;

const ParentDiv = styled(ContentSizedDiv)<{ duration?: number }>`
  ${({ duration }) => defaultTransition('height', duration)}
`;

const AccordionContent = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) => (isOpen ? '' : 'height: 0;')};

  a {
    ${({ isOpen }) => (isOpen ? '' : 'display: none;')};
  }
`;
