import React from 'react';
import { isDefined } from '@peloton/types';

const noop = () => undefined;

class Swipeable extends React.Component<Props> {
  public static defaultProps: Partial<Props> = {
    onSwipeLeft: noop,
    onSwipeRight: noop,
    onSwipeDown: noop,
    onSwipeUp: noop,
    onTap: noop,
  };

  private swipeStart: Coordinate | undefined;
  private swipeEnd: Coordinate | undefined;

  public start = (event: SwipeEvent): void => {
    this.swipeStart = this.lastPosition(event);
  };

  public end = (event: SwipeEvent): void => {
    this.swipeEnd = this.lastPosition(event);
    this.detectGesture();
  };

  public detectGesture() {
    if (!isDefined(this.swipeStart) || !isDefined(this.swipeEnd)) {
      return;
    }
    const dX = this.swipeEnd.x - this.swipeStart.x;
    const dY = this.swipeEnd.y - this.swipeStart.y;

    if (Math.abs(dX) < 20 && Math.abs(dY) < 20) {
      this.props.onTap!();
    } else if (Math.abs(dX) > Math.abs(dY)) {
      dX < 0 ? this.props.onSwipeLeft!() : this.props.onSwipeRight!();
    } else {
      dY < 0 ? this.props.onSwipeUp!() : this.props.onSwipeDown!();
    }
  }

  render() {
    const {
      onSwipeLeft,
      onSwipeRight,
      onSwipeDown,
      onSwipeUp,
      onTap,
      ...props
    } = this.props;
    return (
      <div
        {...props}
        onTouchStart={this.start}
        onTouchEnd={this.end}
        onMouseDown={this.start}
        onMouseUp={this.end}
      >
        {props.children}
      </div>
    );
  }

  private lastPosition(event: SwipeEvent): Coordinate {
    if (isTouchEvent(event)) {
      return {
        x: event.changedTouches[0].screenX,
        y: event.changedTouches[0].screenY,
      };
    } else {
      return {
        x: event.screenX,
        y: event.screenY,
      };
    }
  }
}

const isTouchEvent = (event: SwipeEvent): event is React.TouchEvent<HTMLDivElement> =>
  event.hasOwnProperty('touches');

type Props = React.HTMLAttributes<HTMLDivElement> & {
  onSwipeLeft?(): void;
  onSwipeRight?(): void;
  onSwipeUp?(): void;
  onSwipeDown?(): void;
  onTap?(): void;
};

type Coordinate = {
  x: number;
  y: number;
};

type SwipeEvent = React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>;

export default Swipeable;
