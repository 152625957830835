import { combineReducers } from 'redux';
import type { ReducerState as FetchConnectionNameReducerState } from './fetchConnectionName';
import connection from './fetchConnectionName';
import type { ReducerState as VerifyJWTReducerState } from './verifyJWT';
import verifyJWT from './verifyJWT';

export type State = FetchConnectionNameReducerState & VerifyJWTReducerState;

export default combineReducers({ connection, verifyJWT });

export type ReducerState = {
  pgCorporateBenefitsSSOEnrollment: State;
};
