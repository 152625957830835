import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

const routes = [
  <Route
    key="ways-to-try-peloton"
    path="/try"
    page={true}
    toggleId="waysToTry"
    component={asyncComponent(
      () => import('./Page' /* webpackChunkName: "WaysToTryPeloton" */),
    )}
  />,
];

export default routes;
