import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getIsProdEnv } from '@peloton/env';
import { FormattedMessage } from '@ecomm/internationalize';
import { Modal } from '@ecomm/modal';
import type { Money } from '@ecomm/models';
import { toDollars } from '@ecomm/models';
import type { BundleType } from '@ecomm/shop/models';
import { l1, reg } from '@ecomm/typography';
import { PageType } from '../affirm/ModalLinkView';
import type { Term } from '../models';
import { getMerchantId, getSubdomain } from './config';

export type OwnProps = Omit<React.HTMLAttributes<HTMLAnchorElement>, 'children'> & {
  amount: Money;
  bundleType: BundleType;
  term: Term;
  pageType?: string;
  children?(isProcessing: boolean): React.ReactNode;
};

type PayBrightModalProps = {
  close: () => void;
  amount: Money;
};

type PayBrightEvents = {
  data?: {
    pb_height?: number;
    close_pbModal?: boolean;
  };
};

const PayBrightModal: React.FC<React.PropsWithChildren<PayBrightModalProps>> = ({
  close,
  amount,
}) => {
  const [iframeHeight, setIFrameHeight] = useState(530);
  const isProd = useSelector(getIsProdEnv);

  useEffect(() => {
    const handleMessages = (message: PayBrightEvents) => {
      if (message.data && message.data.pb_height) {
        setIFrameHeight(message.data.pb_height + 20);
      }

      if (message.data && message.data.close_pbModal) {
        close();
      }
    };
    window.addEventListener('message', handleMessages);

    return () => {
      window.removeEventListener('message', handleMessages);
    };
  });

  const merchantId = getMerchantId(isProd);
  const subdomain = getSubdomain(isProd);
  const dollars = toDollars(amount);

  return (
    <StyledModal contentLabel="paybright" closeHandler={close}>
      <StyledIFrame
        height={iframeHeight}
        src={
          `https://${subdomain}.paybright.com/Payments/PreApproval/Preapproval_v2.aspx` +
          `?mid=${merchantId}` +
          `&purchase_amount=${dollars}`
        }
        title="Paybright Modal"
      />
    </StyledModal>
  );
};

const ModalLink: React.FC<OwnProps> = ({
  amount,
  bundleType,
  term,
  pageType = PageType.PRODUCT,
  className,
  children,
  ...props
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <span>
      {/* eslint-disable-next-line styled-components-a11y/click-events-have-key-events */}
      <StyledLink
        onClick={() => setModalOpen(true)}
        {...props}
        role="button"
        tabIndex={0}
      >
        {(children && children(false)) || (
          <FormattedMessage id="financing.paybright.apr" />
        )}
      </StyledLink>
      {modalOpen && <PayBrightModal close={() => setModalOpen(false)} amount={amount} />}
    </span>
  );
};

const StyledModal = styled(Modal)<{ children: React.ReactNode }>`
  background-color: white;
  margin-bottom: 50px;
`;

const StyledIFrame = styled.iframe`
  width: 100%;
  height: ${({ height }) => height};
`;

const StyledLink = styled.a`
  ${reg} ${l1};
`;

export default ModalLink;
