import React from 'react';
import PelotonInternationalize, {
  toCountry,
  toCurrency,
  toLanguage,
  toLocale,
} from '@peloton/internationalize';
import type { PropsOf } from '@peloton/react';
import type { PublishedCopyBlob } from '@ecomm/copy';
import { Context as ContentfulContext, useContentfulClient } from '@ecomm/copy';
import { TAG } from '@ecomm/copy/config';
import { useIsToggleActive } from '@ecomm/feature-toggle';
import StyledPageSpinner from '@page-builder/layout/PageSpinner';

type Props = PropsOf<typeof PelotonInternationalize> & {
  publishedLocaleCopy: PublishedCopyBlob;
};

const Internationalize: React.FC<React.PropsWithChildren<Props>> = ({
  country = toCountry(),
  language = toLanguage(country),
  currency = toCurrency(country),
  locale = toLocale(language, country),
  publishedLocaleCopy,
  ...props
}) => {
  const isToggleActive = useIsToggleActive();
  const isPreview = isToggleActive('previewCopy');
  const contextValue = useContentfulClient({
    preview: isPreview,
    locale,
    publishedLocaleCopy,
    tag: TAG,
  });
  const showPageSpinner =
    contextValue?.loadingFinished === false && process.env.NODE_ENV !== 'test';
  if (showPageSpinner) {
    return <StyledPageSpinner />;
  }

  return (
    // TS error to be fixed during Apollo 3 migration
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
    // @ts-ignore
    <ContentfulContext.Provider value={contextValue}>
      <PelotonInternationalize locale={locale} {...props} />
    </ContentfulContext.Provider>
  );
};

export default Internationalize;
