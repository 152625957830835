import { useContext, useRef } from 'react';
import useIsomorphicEffect from '@acme-ui/global/hooks/useIsomorphicEffect';
import { GlobalReferenceContext, GlobalRefElement } from '../GlobalReferenceProvider';
import useResizeRefWatch from './useResizeRefWatch';

const useBannerReference = () => {
  const { setElement } = useContext(GlobalReferenceContext);
  const bannerRef = useRef<HTMLDivElement | null>(null);
  useResizeRefWatch(bannerRef, ref =>
    setElement(GlobalRefElement.BANNER, ref?.getBoundingClientRect()),
  );

  // Next.js throws a warning not to use useLayoutEffect server side -- useIsomorphicEffect gets around the warning
  useIsomorphicEffect(() => {
    setElement(GlobalRefElement.BANNER, bannerRef.current?.getBoundingClientRect());
  }, []);

  return bannerRef;
};

export default useBannerReference;
