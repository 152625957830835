import type { SagaIterator } from 'redux-saga';
import { all, call, select, takeEvery } from 'redux-saga/effects';
import { track } from '@peloton/analytics';
import { toCountry } from '@peloton/internationalize';
import { getUserTrackingProperties } from '@ecomm/auth';
import {
  getCartId,
  getCartViewedProps,
  getCheckoutCategory,
  getHasTradeIn,
  getPromotionId,
  getShippingMethod,
} from '@ecomm/cart';
import { getSelectedPaymentMethod } from '@ecomm/checkout/redux';
import { getDigitalPlanSku } from '@ecomm/pg-digital-checkout/selectors';

enum EventType {
  Viewed = 'Checkout Step Viewed',
  Completed = 'Checkout Step Completed',
}

type ActionPayload = {
  stepName: string;
  event: EventType;
};

type CustomStepAction = {
  type: Analytics.CustomStep;
  payload: ActionPayload;
};
export const trackAnalytics = function* ({
  payload: { stepName, event },
}: CustomStepAction): SagaIterator {
  const user = yield select(getUserTrackingProperties);
  const properties = yield all({
    category: select(getCheckoutCategory),
    checkoutID: select(getCartId),
    hasTradeIn: select(getHasTradeIn),
    products: select(getCartViewedProps),
    paymentMethod: select(getSelectedPaymentMethod),
    shippingMethod: select(getShippingMethod),
    country: toCountry(),
    promotion: select(getPromotionId),
    digitalProduct: select(getDigitalPlanSku),
    ...user,
    stepName,
  });

  const eventObject = {
    event,
    properties,
  };

  yield call(track, eventObject);
};

const watcherSaga = function* () {
  yield takeEvery(Analytics.CustomStep, trackAnalytics);
};

export const customStepViewed = (stepName: string): CustomStepAction => ({
  type: Analytics.CustomStep,
  payload: {
    stepName,
    event: EventType.Viewed,
  },
});

export const customStepCompleted = (stepName: string): CustomStepAction => ({
  type: Analytics.CustomStep,
  payload: {
    stepName,
    event: EventType.Completed,
  },
});

export enum Analytics {
  CustomStep = 'ecomm/checkout/CUSTOM_STEP',
}

export default watcherSaga;
