// THIS IS AN AUTOGENERATED FILE (@ecomm/graphql/codegen.yml). DO NOT EDIT THIS FILE DIRECTLY.
/* eslint-disable */
import * as Types from '../../graphql/types.generated';

import { DocumentNode } from 'graphql';
import * as ApolloReactCommon from '@ecomm/graphql/hooks';
import * as ApolloReactHooks from '@ecomm/graphql/hooks';
export type TierPricingQueryVariables = Types.Exact<{
  platform?: Types.Maybe<Types.Scalars['String']>;
  storeSlug: Types.Scalars['String'];
}>;

export type TierPricingQuery = { __typename?: 'Query' } & {
  subscriptionTiers: { __typename?: 'SubscriptionTiers' } & {
    nodes: Array<
      { __typename?: 'SubscriptionTier' } & Pick<Types.SubscriptionTier, 'level'> & {
          identifiers: Array<
            { __typename?: 'SubscriptionTierIdentifier' } & Pick<
              Types.SubscriptionTierIdentifier,
              'billingFrequency'
            > & {
                paidSubscriptionPlan?: Types.Maybe<
                  { __typename?: 'TierSubscriptionPlan' } & Pick<
                    Types.TierSubscriptionPlan,
                    'displayPrice' | 'priceInCents'
                  >
                >;
              }
          >;
        }
    >;
  };
};

export const TierPricingDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TierPricing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'platform' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeSlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionTiers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storeSlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeSlug' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'identifiers' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'platform' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'platform' },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billingFrequency' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'paidSubscriptionPlan' },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'displayPrice' },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'priceInCents' },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'level' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useTierPricingQuery__
 *
 * To run a query within a React component, call `useTierPricingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTierPricingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTierPricingQuery({
 *   variables: {
 *      platform: // value for 'platform'
 *      storeSlug: // value for 'storeSlug'
 *   },
 * });
 */
export function useTierPricingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TierPricingQuery,
    TierPricingQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<TierPricingQuery, TierPricingQueryVariables>(
    TierPricingDocument,
    baseOptions,
  );
}
export function useTierPricingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TierPricingQuery,
    TierPricingQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<TierPricingQuery, TierPricingQueryVariables>(
    TierPricingDocument,
    baseOptions,
  );
}
export type TierPricingQueryHookResult = ReturnType<typeof useTierPricingQuery>;
export type TierPricingLazyQueryHookResult = ReturnType<typeof useTierPricingLazyQuery>;
export type TierPricingQueryResult = ApolloReactCommon.QueryResult<
  TierPricingQuery,
  TierPricingQueryVariables
>;
