import {
  Accordion,
  white,
  Body,
  spacing,
  Media,
  Flex,
  grey,
  Eyebrow,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { asyncComponent } from '@peloton/code-splitting';
import { media } from '@peloton/styles';
import type { Subnav } from '@ecomm/copy/helpers/types';
import { Link } from '@ecomm/internationalize-ui';
import Header from './Header';
import type { CloseHandlers } from './NavFlyout';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Nav = styled.nav`
  padding: 0 1.5rem 3rem;
`;

const PrimaryLinksContainer = styled.ul`
  color: ${white};

  li {
    display: flex;
    align-items: right;
    margin: 2.5rem 0;

    > svg {
      float: right;
    }
  }
`;

const StyledAccordion = styled(Accordion)`
  background-color: transparent;
  padding: 0;
  margin: ${spacing[40]} ${spacing[16]} 0 0;
  color: ${white};

  ${media.tabletXLarge`
    margin: ${spacing[40]} 0 0 0;
  `}

  ul {
    border-top: none;
  }

  ul li {
    padding: 0;
    display: block;
    margin: 0 0 ${spacing[8]} 0;
    border-bottom: none;
  }

  > ul > li > div > div > button {
    padding: 0;
    color: ${white};
  }

  > ul > li > div > div > div > div {
    padding: 0;
  }

  > ul > li > div > div > button > span > h3 {
    color: ${white};
  }

  > ul > li > div > div > button > svg > path {
    fill: ${grey[50]};
  }

  > ul > li > div > div > div {
    padding-top: ${spacing[24]};

    ${media.tabletXLarge`
      padding-top: ${spacing[16]};
    `}
  }

  ul li:last-child {
    border-bottom: none;
  }
`;

const StyledBody = styled(Body)`
  width: 100%;
  padding-bottom: ${spacing[24]};
  color: ${white};
`;

const StyledCollectionBody = styled(Body)`
  width: 100%;
  color: ${white};
`;

const StyledMedia = styled(Media)`
  margin-right: ${spacing[24]};

  picture {
    width: ${spacing[88]};
    height: ${spacing[88]};
    display: block;
    border-radius: ${spacing[4]};
  }

  picture img {
    border-radius: ${spacing[4]};
  }
`;

const StyledCollectionLink = styled(Link)`
  display: block !important;
  padding-bottom: ${spacing[24]};

  &:last-child {
    padding-bottom: ${spacing[32]};
  }
`;

const StyledLink = styled(Link)`
  display: block !important;

  &:last-child > span > p {
    padding-bottom: ${spacing[32]};
  }
`;

const StyledEyebrow = styled(Eyebrow)`
  font-size: 18px;
  line-height: 1.375em;
`;

const NavFlyout = asyncComponent(
  () => import(/* webpackChunkName: "ModalChunk", webpackPrefetch: true */ './NavFlyout'),
);

type Props = {
  subData: Subnav[];
  isOpen: boolean;
  apparelData: Subnav;
  onSubCloseRequest: () => void;
  onSubBackRequest: () => void;
};

const SubSecondaryMenuFlyout: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onSubCloseRequest,
  onSubBackRequest,
  subData,
  apparelData,
}) => {
  const filterCategoryAccordions = subData.filter(
    item => 'ctas' in item && item.ctas.filter(cta => cta.url).length > 1,
  );
  const categoryAccordions = filterCategoryAccordions.map((item, index) => {
    const isCollection = item.key.includes('collections');
    return {
      content: isCollection
        ? 'ctas' in item &&
          item.ctas.map(cta => {
            const linkTarget = cta.targetBlank ? '_blank' : '_self';
            return (
              <StyledCollectionLink
                key={cta.key}
                to={cta.url}
                target={linkTarget}
                href={cta.url}
                variant="body"
              >
                <Flex flexDirection="row" alignItems="center">
                  <StyledMedia
                    media={{
                      alt: cta?.image?.title ?? '',
                      mobile: cta?.image?.url ?? '',
                      type: 'image',
                    }}
                    objectFit="contain"
                    height={spacing[88]}
                    width={spacing[88]}
                  />
                  <StyledCollectionBody size="medium">{cta.text}</StyledCollectionBody>
                </Flex>
              </StyledCollectionLink>
            );
          })
        : 'ctas' in item &&
          item.ctas.map(cta => {
            const linkTarget = cta.targetBlank ? '_blank' : '_self';
            return (
              <StyledLink
                key={cta.key}
                to={cta.url}
                href={cta.url}
                target={linkTarget}
                variant="body"
              >
                <StyledBody size="medium" key={cta.key}>
                  {cta.text}
                </StyledBody>
              </StyledLink>
            );
          }),
      title: (
        <StyledEyebrow size="large">{'title' in item ? item.title : ''}</StyledEyebrow>
      ),
      isOpen: index === 0 ? true : false,
    };
  });

  return (
    <NavFlyout
      isOpen={isOpen}
      ariaLabel="navigation"
      backgroundColor="#181a1d"
      handleRequestClose={onSubCloseRequest}
      handleRequestBack={onSubBackRequest}
    >
      {({ handleRequestClose, handleRequestBack }: CloseHandlers) => (
        <Container>
          <Header
            heading={'title' in apparelData ? apparelData.title : ''}
            handleClose={handleRequestClose}
            handleBack={handleRequestBack}
          />
          <Nav>
            <PrimaryLinksContainer>
              <StyledAccordion
                multiple
                ariaHidden
                textAlign="center"
                scrollTo={false}
                values={categoryAccordions}
              />
            </PrimaryLinksContainer>
          </Nav>
        </Container>
      )}
    </NavFlyout>
  );
};

export default SubSecondaryMenuFlyout;
