import { brand, grey } from '@pelotoncycle/design-system';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { inlineLinkStyles } from '@ecomm/links';
import { b6, b7 } from '@ecomm/typography';

export const StyledCopyDiv = styled.div`
  ${b6}
  color: ${grey[70]};
  margin: 0 auto 0 0;
  max-width: 1000px;
  padding: 10px 0;

  ol {
    list-style: decimal;
  }

  ${media.desktopLarge`
    &:first-child {
      margin-top: 10px;
    }
  `}

  a {
    ${inlineLinkStyles}
  }

  p:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const StyledLegalCopy = styled.p`
  ${b7}
  color: ${brand.dark};
  margin: 0 auto;
  padding: 0 0 30px;
  max-width: 100%;

  > span {
    display: block;
    > span {
      padding: 4px 0 0 3px;
    }
  }
`;

export const Divider = styled.hr`
  background-color: ${grey[40]};
  border-style: none;
  height: 1px;
  margin: 30px 0 0;
  width: 100%;

  ${media.desktopLarge`
    margin-top: 40px;
  `}
`;

export const StyledWrapper = styled.div`
  padding-bottom: 50px;
`;
