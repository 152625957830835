import { hasVariation } from '@peloton/split-testing';

const APP_LEAD_CAPTURE_EXPERIMENT = 'App Optimization Modal';
const VARIATION_1 = 'Original';
const VARIATION_2 = 'Variation #1';

export const showAppLeadCaptureVariation1 = hasVariation(
  APP_LEAD_CAPTURE_EXPERIMENT,
  VARIATION_1,
);

export const showAppLeadCaptureVariation2 = hasVariation(
  APP_LEAD_CAPTURE_EXPERIMENT,
  VARIATION_2,
);
