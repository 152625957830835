import type { SagaIterator } from 'redux-saga';
import { call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { getUserId, setPasswordForNewUser } from '@peloton/auth';
import type { Request } from './redux/newUserPassword';
import {
  ActionType,
  setNewUserPasswordFailure,
  setNewUserPasswordSuccess,
} from './redux/newUserPassword';

export const setNewUserPasswordSaga = function* (
  client: Client,
  action: Request,
): SagaIterator {
  const userId = yield select(getUserId);
  try {
    yield call(setPasswordForNewUser, client, userId, action.payload.password);
    yield put(setNewUserPasswordSuccess());
  } catch (e) {
    yield put(setNewUserPasswordFailure(e));
  }
};

export const setPasswordSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(ActionType.Request, setNewUserPasswordSaga, client);
};

export default setPasswordSaga;
