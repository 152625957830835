import { schema } from 'normalizr';
import type { ReduxClass } from '../../data';
import type { ApiClass } from './models';

const processClass = ({
  instructorId,
  scheduledStartTime,
  title,
}: ApiClass): ReduxClass => ({
  instructor: instructorId,
  scheduledTime: scheduledStartTime,
  title,
});

export const classSchema = new schema.Entity(
  'classes',
  {},
  { processStrategy: processClass },
);
