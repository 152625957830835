import { brand, grey } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { superRed } from '@ecomm/colors';
import { FormattedMarkdown } from '@ecomm/formatted-markdown';
import type { Props as ImageProps } from '@ecomm/image';
import { Image } from '@ecomm/image';
import { FormattedMessage } from '@ecomm/internationalize';
import type { ModalProps } from '@ecomm/modal';
import { Modal } from '@ecomm/modal';
import { hb3, b3, b6 } from '@ecomm/typography';

export type Props = ModalProps & {
  className?: string;
  footerId?: string;
  imgBottom?: boolean;
  imgProps: ImageProps;
  subtitleId?: string;
  titleId: string;
};

const PromoModal: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  children,
  footerId,
  imgBottom = false,
  imgProps,
  padded: _,
  subtitleId,
  titleId,
  ...props
}) => (
  <Modal {...props} padded={false}>
    <Container imgBottom={imgBottom} className={className}>
      <ImageContainerParent>
        <ImageContainer>
          <StyledImage {...imgProps} />
        </ImageContainer>
      </ImageContainerParent>
      <Content>
        <Title>
          <FormattedMarkdown id={titleId} />
        </Title>
        {subtitleId && (
          <SubTitle>
            <FormattedMessage id={subtitleId} />
          </SubTitle>
        )}
        {children && <ChildrenContainer>{children}</ChildrenContainer>}
        {footerId && (
          <Footer>
            <FormattedMessage id={footerId} />
          </Footer>
        )}
      </Content>
    </Container>
  </Modal>
);

export default PromoModal;

const Container = styled.div`
  display: flex;
  flex-direction: ${({ imgBottom }: { imgBottom: boolean }) =>
    imgBottom ? 'column-reverse' : 'column'};
  width: 100%;
`;

const Content = styled.section`
  padding: 40px 20px 50px 20px;
  color: ${brand.darkest};

  padding: 30px 20px;
  ${media.smallScreen`
    padding: 40px 30px;
  `}

  ${media.tablet`
    padding: 56px 48px;
  `}

  ${media.desktop`
    padding: 56px 48px;
  `}
`;

const ImageContainerParent = styled.div`
  min-height: 50vw;
  ${media.desktop`
    min-height: 420px;
  `}
  position: relative;
`;

const ImageContainer = styled.div`
  overflow: hidden;
  position: absolute;
  max-height: 100%;
`;

const StyledImage = styled(Image)`
  height: 50vw;
  ${media.desktop`
    height: 420px;
  `}
  min-width: 100%;
`;

const Title = styled.h1`
  ${hb3}
  em {
    font-style: normal;
    color: ${superRed};
  }
`;

const SubTitle = styled.h2`
  ${b3}
  margin-top: 10px;
  ${media.smallScreen`
    margin-top: 20px;
  `}
`;

const Footer = styled.footer`
  ${b6}
  color: ${grey[70]};
  margin-top: 40px;
`;

const ChildrenContainer = styled.div`
  margin-top: 20px;
  ${media.smallScreen`
    margin-top: 20px;
  `}
  ${media.tablet`
    margin-top: 40px;
  `}
`;
