import type { Interval } from '@peloton/subscription-plans';
import type { ID, Image, Money } from '@ecomm/models';
import type { ProductLine } from './Product';

export type ProductOption = {
  description: string;
  id: ID;
  name: string;
  interval: Interval;
  isAvailable: boolean;
  prepaidMonths?: number;
  price: Money;
  productLine: ProductLine;
  traits: ID[];
  images: Image[];
  sku: string;
  slug: string;
};

export const toIsProductOption = (productLine: ProductLine) => (
  productOption: ProductOption,
): boolean => productOption.productLine === productLine;
