import { pathOr, mergeDeepRight } from 'ramda';
import type { Bundle, Entities } from '../models';

export enum ActionType {
  Add = 'ecomm/shop/ADD_BUNDLES',
}

export type State = Entities<Bundle>;

export const defaultState = {};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.Add: {
      const bundles = pathOr({}, ['payload', 'bundles'], action);
      return mergeDeepRight(state, bundles);
    }
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  bundles: State;
};

export const addBundles = (bundles: Entities<Bundle>) => ({
  type: ActionType.Add,
  payload: { bundles },
});

type AddBundlesAction = {
  type: ActionType.Add;
  payload: { bundles: Entities<Bundle> };
};

type Action = AddBundlesAction;
