import { ALLOW_PREVIEW_COPY } from '@peloton/app-config';
import type { Locale } from '@peloton/internationalize';
import { toLocaleFromHostname } from '@peloton/internationalize';
import { getClient as getPeloContentClient } from './peloContentClient';
import { parseAggregateData } from './utils/parseAggregateData';
import { globalEntriesConfig } from './www/aggregates/aggregateMap';

/**
 * Exclusively used to fetch content for global app components (e.g. Nav,
 * Footer, Cart) that have been rewritten to be compatible in both next-www
 * and www.
 */
export const toFetcher = (
  preview: boolean = Boolean(ALLOW_PREVIEW_COPY),
  locale?: Locale,
) => async (entryId: string) => {
  try {
    const client = getPeloContentClient(preview);
    const config = globalEntriesConfig[entryId];

    if (!config) {
      throw new Error('No generated file assigned to this entry ID');
    }

    const data = await client.getEntry(entryId, {
      include: 10,
      locale: locale || toLocaleFromHostname(),
    });

    return parseAggregateData(data, entryId);
  } catch (err) {
    console.log(`Failed to parse peloContent entry id: ${entryId}`);
    throw new Error(err);
  }
};

const globalEntriesFetcher = toFetcher();

export default globalEntriesFetcher;
