import { BundleType } from '@ecomm/shop/models/BundleType';

export enum Term {
  Twelve = 12,
  TwentyFour = 24,
  ThirtyNine = 39,
  FortyThree = 43,
}

export const maxTerm = (bundleType?: BundleType) => {
  if (
    bundleType &&
    [BundleType.BikePlus, BundleType.Tread, BundleType.Row].indexOf(bundleType) >= 0
  ) {
    return Term.FortyThree;
  }
  return Term.ThirtyNine;
};

export const TERMS: Record<string, Term> = {
  '12mo-financing': Term.Twelve,
  '24mo-financing': Term.TwentyFour,
  '39mo-financing': Term.ThirtyNine,
  '43mo-financing': Term.FortyThree,
};

export const getTerm = (slug: string) => TERMS[slug];
