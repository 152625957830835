import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';

const getPageName = () => {
  const pathname = getCurrentRelativePathname();
  if (pathname && isBikeRoute(pathname)) {
    return getBikePageName(pathname);
  }
  return '';
};

const getBikePageName = (pathname: string) => {
  switch (pathname) {
    case '/bike':
      return `bike`;
    case '/bike-plus':
      return `bikePlus`;
    case '/bikes/financing':
      return `bikesFinancing`;
    case '/bikes/trade-in':
      return `bikesTradeIn`;
    case '/bikes':
    default:
      return `bikes`;
  }
};

const isBikeRoute = (pathname: string) => pathname.startsWith('/bike');

const getSubnavCtaTestId = (element: string) => {
  const ending = `subnavCta_${element}`;
  return `${getPageName()}_${ending}`;
};

export default { getPageName, getSubnavCtaTestId };
