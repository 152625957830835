import type { FormAction } from 'redux-form';
import { actionTypes } from 'redux-form';
import type { LoginForm } from './models';

export const FORM_NAME = 'login';

export enum ActionType {
  SubmitForm = 'pelo/login-form/submit',
}

type SuccessHandler = () => void;
type ErrorHandler = (e: Error) => void;

export type SubmitLoginFormAction = {
  type: ActionType.SubmitForm;
  payload: Partial<LoginForm>;
  onSuccess: SuccessHandler;
  onError: ErrorHandler;
};

export const submitLoginForm = (
  values: Partial<LoginForm>,
  onSuccess: SuccessHandler,
  onError: ErrorHandler,
): SubmitLoginFormAction => ({
  type: ActionType.SubmitForm,
  payload: values,
  onSuccess,
  onError,
});

export const isLoginFormAction = (action: FormAction) => action.meta.form === FORM_NAME;

// Submitted form
export const isLoginFormSubmittedAction = (action: FormAction) =>
  action.type === actionTypes.SET_SUBMIT_SUCCEEDED && isLoginFormAction(action);

// These are aliased so that other packages can consume without knowledge
// of redux-form as it is likely we will move away from it at some point.
export const LOGIN_FORM_SUBMITTED = actionTypes.SET_SUBMIT_SUCCEEDED;
export type LoginFormAction = FormAction;
