import React from 'react';
import { Redirect } from 'react-router';
import { asyncComponent } from '@peloton/code-splitting';
import type { ToggleId } from '@ecomm/feature-toggle';
import { Route } from '@ecomm/router';
import { createI18nMatchSelector } from '@ecomm/saga-utils';
import { RouteConfigurations } from './partnershipConfigurations';

const Routes = RouteConfigurations.map(config => (
  <Route
    key={config.path}
    path={config.path}
    page={true}
    exact={true}
    toggleId={config.toggleId as ToggleId}
    component={asyncComponent(
      () => import('./Handler' /* webpackChunkName: "DigitalPromotion" */),
    )}
  />
));

Routes.push(
  <Route key="skyRedirect" path="/sky" exact={true}>
    <Redirect to="/digital/partnerships/sky" />
  </Route>,
);

export const matchDigitalPromotion = RouteConfigurations.map(({ path }) =>
  createI18nMatchSelector(`${path}`),
);

export default Routes;
