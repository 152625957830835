import React from 'react';
import { css } from 'styled-components';
import type { InputComponent, InputWithLabelProps } from '@peloton/forms';
import { toInputWithLabel, toTextInput } from '@peloton/forms';
import { inputStyles, labelStyles } from './styles';

const InputWithPadding = toTextInput({
  ...inputStyles,
  base: css`
    ${inputStyles.base}
    padding-top: 14px;
  `,
});

export const BaseInputWithLabel = toInputWithLabel(
  InputWithPadding,
  labelStyles,
) as InputComponent<InputWithLabelProps>;

const InputWithLabel: InputComponent<InputWithLabelProps> = props => (
  <BaseInputWithLabel {...props} showPlaceholder={false} />
);

export default InputWithLabel;
