import {
  countBy,
  head,
  identity,
  mergeDeepWith,
  map,
  pipe,
  prop,
  sortBy,
  union,
  values,
} from 'ramda';
import type { ID } from '@ecomm/models';
import type { Bundle } from './Bundle';
import type { Category } from './Category';
import type { Dimension } from './Dimension';
import type { Product } from './Product';
import type { ProductOption } from './ProductOption';
import type { Trait } from './Trait';

export type Entities<T> = Record<ID, T>;

export type NormalizedBundle = {
  entities: BundleEntities &
    DimensionEntities &
    CategoryEntities &
    OptionEntities &
    ProductEntities &
    TraitEntities;
};

export type NormalizedProduct = {
  entities: ProductEntities &
    DimensionEntities &
    CategoryEntities &
    OptionEntities &
    TraitEntities;
};

export type BundleEntities = { bundles: Entities<Bundle> };
export type CategoryEntities = { categories: Entities<Category> };
export type DimensionEntities = { dimensions: Entities<Dimension> };
export type OptionEntities = { options: Entities<ProductOption> };
export type ProductEntities = { products: Entities<Product> };
export type TraitEntities = { traits: Entities<Trait> };

export const mergeDeepWithUnion = <A = any, B = any>(a: A, b: B): A & B =>
  mergeDeepWith((x: any, y: any) => (Array.isArray(x) ? union(x, y) : x), a, b);

type WithPrice = Bundle | ProductOption | Product;

export const sortEntitiesByPrice = pipe<Entities<WithPrice>, WithPrice[], WithPrice[]>(
  values,
  sortBy(prop('price')),
);

export const toCheapestEntity = pipe<Entities<WithPrice>, WithPrice[], WithPrice>(
  sortEntitiesByPrice,
  head,
);

export const toCheapestEntityId = pipe(toCheapestEntity, prop('id'));

export type WithCount = { count: number };

export const toEntitiesWithCount = pipe<ID[], Entities<number>, Entities<WithCount>>(
  countBy<string>(identity),
  map<Entities<number>, Entities<WithCount>>((count: number) => ({ count })),
);
