import { combineReducers } from 'redux';
import type { ReducerState as FormState } from './form';
import form from './form';
import type { ReducerState as RegisterState } from './register';
import { default as register } from './register';

export type State = RegisterState & FormState;

export default combineReducers({ form, register });

export type ReducerState = {
  registration: State;
};
