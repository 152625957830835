import type { SagaIterator } from 'redux-saga';
import { put, select, takeEvery } from 'redux-saga/effects';
import { AddressType, OrderType } from '@ecomm/checkout/models';
import { hasCheckoutErrors, reviewOrder } from '@ecomm/checkout/redux';
import { PaymentMethod } from '@ecomm/models';
import type { CheckoutRequestAction } from '../redux';
import { ActionTypes, checkoutSuccess, failCheckout } from '../redux';

export const validateCardSaga = function* (action: CheckoutRequestAction): SagaIterator {
  const hasErrors = yield select(hasCheckoutErrors, {
    addressType: AddressType.Billing,
    paymentMethod: PaymentMethod.CreditCard,
  });

  if (hasErrors) {
    yield put(failCheckout('checkout.errors.incomplete'));
  } else {
    yield put(checkoutSuccess());
    yield put(
      reviewOrder({
        kind: OrderType.PaymentGateway,
        method: PaymentMethod.CreditCard,
        ...action.payload,
      }),
    );
  }
};

const watcherSaga = function* () {
  yield takeEvery(ActionTypes.CheckoutRequested, validateCardSaga);
};

export default watcherSaga;
