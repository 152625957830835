import React from 'react';
import { compose } from 'recompose';
import { Button1 } from '@ecomm/buttons';
import { useCtaWithUrlData } from '@ecomm/copy';
import { EloquaEmailCapture, withEmailCaptureData } from '@ecomm/email-capture';
import { useLeadGenDefaultEventProps } from '@ecomm/feature-toggle/useLeadGenPromo';

type EmailCaptureProps = {
  path: string;
  country: string;
  productInterest: string;
};

type SegmentProps = {
  unitName: string;
  campaignId?: string;
  campaignName?: string;
  additionalProps?: Record<string, string>;
};

const LeadCapture: React.FC<
  React.PropsWithChildren<EmailCaptureProps & SegmentProps>
> = ({
  path,
  country,
  productInterest,
  unitName,
  campaignId = '7011C000001QeUUQA0',
  campaignName = 'Email Capture Unit',
  additionalProps,
}) => {
  const buttonText = useCtaWithUrlData('modalLeadCaptureCTA');
  const defaultProps = useLeadGenDefaultEventProps();

  const eventProps = {
    ...defaultProps,
    campaignId,
    campaignName,
    country,
    pageName: path,
    productInterest,
    propertyType: 'Web',
    unitName: unitName,
    unitPlacement: 'Modal',
    ...additionalProps,
  };
  return (
    <EloquaEmailCapture
      eventName="Submitted Email for Marketing"
      eventProps={eventProps}
      buttonType={Button1}
      buttonText={buttonText.text}
      buttonStyle={{ borderRadius: '3px', minWidth: '96px' }}
    />
  );
};

export default compose(withEmailCaptureData)(LeadCapture);
