import useSWR from 'swr';
import { IMMUTABLE } from './revalidation';

export const useMicroCopy = (key: string): string => {
  const { data, error } = useSWR('microCopy', IMMUTABLE);

  if (error) {
    throw new Error(`Error retrieving micro copy: ${error}`);
  }

  const value = data && data[key];

  if (!value) {
    throw new Error(`Error fetching micro copy at key: ${key}`);
  }

  return value;
};

export const useMicroCopyBulk = (filteredKeys?: string[]): Record<string, string> => {
  const { data, error } = useSWR('microCopy', IMMUTABLE);

  if (error) {
    throw new Error(`Error retrieving micro copy: ${error}`);
  }

  if (!filteredKeys) {
    return data;
  }

  const values = {};

  filteredKeys.forEach(k => {
    if (data && data[k]) {
      values[k] = data[k];
    }
  });

  return values;
};
