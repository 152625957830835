import type { Client, ClientError } from '@peloton/api';
import { pipeDataTwice, pipeError, isHttpError, throwError } from '@peloton/api';
import { toCart as lightCartMapper } from './lightMapper';
import { toCart as oldCartMapper } from './mapper';
import type { LightCartAPIWrapped } from './types';

const toAddCouponURL = (code: string) => `/ecomm/add_coupon/${code}`;
const toRemoveCouponURL = (code: string) => `/ecomm/remove_coupon/${code}`;

enum ErrorCode {
  PromotionAllowedOncePerTransaction = 'promotionAllowedOncePerTransaction',
  CartDoesNotQualifyForPromotion = 'cartDoesNotQualifyForPromotion',
  PromotionNotApplicable = 'promotionNotApplicable',
  PromotionRedemptionLimitReached = 'promotionRedemptionLimitReached',
  InvalidCode = 'invalidCode',
  PromotionExpired = 'promotionExpired',
  RegionInvalid = 'regionInvalid',
  Default = 'default',
}

const apiErrorMap = {
  4922: ErrorCode.CartDoesNotQualifyForPromotion,
  4924: ErrorCode.PromotionAllowedOncePerTransaction,
  4925: ErrorCode.PromotionNotApplicable,
  4928: ErrorCode.CartDoesNotQualifyForPromotion,
  4929: ErrorCode.CartDoesNotQualifyForPromotion,
  4930: ErrorCode.PromotionAllowedOncePerTransaction,
  4931: ErrorCode.PromotionRedemptionLimitReached,
  4932: ErrorCode.InvalidCode,
  4933: ErrorCode.PromotionExpired,
  4935: ErrorCode.RegionInvalid,
};

const toErrorCode = (error: ClientError): ErrorCode => {
  const apiErrorCode = isHttpError(error) ? error.responseBody.errorCode : undefined;

  return apiErrorMap[apiErrorCode] || ErrorCode.Default;
};

export const attemptCouponApply = (api: Client, code: string) =>
  api
    .post(toAddCouponURL(code), { couponsV2: true, renders_light_cart: true })
    .then(pipeDataTwice(data => lightCartMapper((data as LightCartAPIWrapped).cart)))
    .catch(pipeError(throwError<ErrorCode>(toErrorCode)));

export const attemptCouponRemove = (api: Client, code: string) =>
  api
    .post(toRemoveCouponURL(code))
    .then(pipeDataTwice(oldCartMapper))
    .catch(pipeError(throwError<ErrorCode>(toErrorCode)));
