import styled, { css } from 'styled-components';

// hide content visually, but make available to screen reader
// https://webaim.org/techniques/css/invisiblecontent/
// https://accessible360.com/accessible360-blog/use-aria-label-screen-reader-text/

export const screenReaderOnly = css`
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px 1px 1px 1px); // IE 6 and 7
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  overflow: hidden !important;
`;

export const ScreenReaderHeader = styled.h1`
  ${screenReaderOnly}
`;

export const ScreenReaderText = styled.span<{ children?: React.ReactNode }>`
  ${screenReaderOnly}
`;
