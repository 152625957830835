import type { HostnameEnv } from '@peloton/env-hostnames/toHostnameEnv';
import { toHostnameEnv } from '@peloton/env-hostnames/toHostnameEnv';
import { isProdEnv, isProdTestEnv, isUatProdBackendEnv } from '@peloton/env/models';
import type { CartContactInfo } from '@ecomm/cart/models';
import { PaymentMethod } from '@ecomm/models';
import { toShippingInfo } from './adapters';

type Klarna = any;

interface KlarnaWindow extends Window {
  Klarna: Klarna;
}

const defaultApp = 'www';

const { app: hostApp } = (toHostnameEnv(window.location.hostname) as HostnameEnv) ?? {
  app: defaultApp,
};

// Klarna Direct Debit is configured differently for sandbox vs prod env
const KlarnaPaymentMethodMap = {
  [PaymentMethod.Financing]: 'pay_over_time',
  [PaymentMethod.KlarnaBankTransfer]: 'direct_bank_transfer',
  [PaymentMethod.KlarnaDirectDebit]:
    isProdEnv(hostApp) || isUatProdBackendEnv() || isProdTestEnv(hostApp)
      ? 'direct_debit'
      : 'pay_now',
  [PaymentMethod.KlarnaInvoice]: 'pay_later',
};

export type AuthResponse = {
  approved: boolean;
  authorization_token: string;
  error?: string;
  finalize_required: boolean;
  show_form: boolean;
};

type ShippingInfo =
  | {
      shipping_address: {
        given_name: string;
        family_name: string;
        street_address: string;
        street_address2: string;
        postal_code: string;
        city: string;
        email: string;
        country: string;
      };
    }
  | {};

export type KlarnaApi = {
  Payments: {
    init: ({ client_token }: { client_token: string }) => any;
    load: (
      {
        container,
        payment_method_category,
      }: { container: string; payment_method_category: string },
      callback: (response: { show_form: boolean }) => void,
    ) => void;
    authorize: (
      { payment_method_category }: { payment_method_category: string },
      shippingInfo: ShippingInfo,
      callback: (response: AuthResponse) => void,
    ) => void;
  };
};

export const getKlarna = (): KlarnaApi => ((window as unknown) as KlarnaWindow).Klarna;

type KlarnaAuthorizeResults = {
  success: boolean;
  errors: (string | undefined)[];
  token?: string;
  finalize_required: boolean;
};
export const authorize = (
  paymentType: PaymentMethod,
  shippingInfo?: CartContactInfo & { email: string },
): Promise<KlarnaAuthorizeResults> =>
  new Promise((resolve, reject) => {
    getKlarna().Payments.authorize(
      { payment_method_category: KlarnaPaymentMethodMap[paymentType] },
      {
        ...(toShippingInfo(shippingInfo) as ShippingInfo),
      },
      (response: AuthResponse) => {
        resolve({
          success: response.approved,
          errors: [response.error],
          token: response.authorization_token,
          finalize_required: response.finalize_required,
        });
      },
    );
  });

type LoadWidgetResponse = {
  show_form: boolean;
  errors?: string[];
};

type LoadWidgetResults = {
  success: boolean;
  errors?: string[];
};
export const loadWidget = (paymentType: PaymentMethod): Promise<LoadWidgetResults> =>
  new Promise(resolve => {
    getKlarna().Payments.load(
      {
        container: '#klarnaWidget',
        payment_method_category: KlarnaPaymentMethodMap[paymentType],
      },
      (response: LoadWidgetResponse) => {
        resolve({
          success: response.show_form && !response.errors,
          errors: response.errors,
        });
      },
    );
  });

const KLARNA_SDK_URL = 'https://x.klarnacdn.net/kp/lib/v1/api.js';
export const addKlarnaSDKToPage = async () => {
  const script = await document.createElement('script');
  script.src = KLARNA_SDK_URL;
  script.async = true;
  await document.body.appendChild(script);
};
