import { push } from 'connected-react-router';
import { path, prop } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { getLocation } from '@peloton/redux';
import { addItemsToCart } from '@ecomm/cart/api/createCartItems';
import { loadCartSuccess } from '@ecomm/cart/redux';
import { updateShipping } from '@ecomm/checkout/redux';
import type { ID, ContactInfo } from '@ecomm/models';
import {
  ActionType,
  addToCartSuccess,
  areProductsConfigurable,
  getCheapestOptions,
  getIsProductConfigured,
  getOptionAsConfigured,
  startTransaction,
  matchShopAccessoriesRoute,
} from '../redux';
import type { AddProductToCartRequestedAction } from '../redux/shopper';
import toShopUrl from '../toShopUrl';

export const redirectSaga = function* (shopUrl: string): SagaIterator {
  const match = yield select(matchShopAccessoriesRoute);
  if (match) {
    yield put(push(shopUrl));
  }
};

export const addProductToCartSaga = function* (
  id: ID,
  productOption: ID,
  shopUrl: string,
  isUpsell?: boolean,
): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);

  yield put(startTransaction(id));
  try {
    const payload = {
      products: [{ productOptionId: productOption, quantity: 1 }],
    };
    const cart = yield call(addItemsToCart, client, payload);

    const maybePostalCode = path(['shipping', 'address', 'postalCode'], cart);
    if (maybePostalCode) {
      yield put(updateShipping(prop('shipping', cart) as ContactInfo));
    }

    yield all([
      put(addToCartSuccess(id, productOption, isUpsell)),
      put(loadCartSuccess(cart)),
    ]);
    yield call(redirectSaga, shopUrl);
  } catch (e) {
    // TODO: Error handling
    return;
  }
};

export const configureProductSaga = function* (
  action: AddProductToCartRequestedAction,
): SagaIterator {
  const { id, isUpsell } = action.payload;

  const { isConfigured, needsConfiguration } = yield all({
    isConfigured: select(getIsProductConfigured, { id }),
    location: select(getLocation),
    needsConfiguration: select(areProductsConfigurable, { products: [id] }),
  });

  const { pathname } = location;
  const shopUrl = toShopUrl(pathname);

  if (needsConfiguration && !isConfigured) {
    // NOTE: Should we need to display more nuanced errors to the user,
    // this is the place to do so.
  } else if (!needsConfiguration) {
    const cheapestProductOption = yield select(getCheapestOptions, { products: [id] });
    yield call(addProductToCartSaga, id, cheapestProductOption[0], shopUrl, isUpsell);
  } else {
    const configuredProductOption = yield select(getOptionAsConfigured, { id });
    if (configuredProductOption && configuredProductOption.id) {
      yield call(addProductToCartSaga, id, configuredProductOption.id, shopUrl, isUpsell);
    } else {
      // TODO: Display error that this product (option) doesnt exist for this trait set
    }
  }
};

const watcherSaga = function* () {
  yield takeEvery(ActionType.AddProductToCartRequested, configureProductSaga);
};

export default watcherSaga;
