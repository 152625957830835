import { EquipmentType } from '@ecomm/graphql/types.generated';

export type TrackingCategory =
  | 'bike'
  | 'bike-plus'
  | 'guide'
  | 'tread'
  | 'tread-plus'
  | 'row'
  | 'Accessories';

const toTrackingCategory = (catalogProductLine?: string): TrackingCategory => {
  const knownCategoriesMap: Record<EquipmentType, TrackingCategory> = {
    [EquipmentType.Bike]: 'bike',
    [EquipmentType.Bikeplus]: 'bike-plus',
    [EquipmentType.Guide]: 'guide',
    [EquipmentType.Tread]: 'tread',
    [EquipmentType.Treadplus]: 'tread-plus',
    [EquipmentType.Row]: 'row',
    [EquipmentType.Refurbbike]: 'bike',
    [EquipmentType.Refurbbikeplus]: 'bike-plus',
  };
  if (catalogProductLine && knownCategoriesMap[catalogProductLine]) {
    return knownCategoriesMap[catalogProductLine];
  } else {
    return 'Accessories';
  }
};

export default toTrackingCategory;
