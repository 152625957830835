import type { ToggleDefinitions } from '@ecomm/feature-toggle';
import { ToggleStatus } from '@ecomm/feature-toggle/models/ToggleStatus';

const features: ToggleDefinitions = {
  accessibilityPage: {
    status: ToggleStatus.Enabled,
  },
  accessoriesOfferPromo: {
    status: ToggleStatus.Enabled,
  },
  activeDigitalPromotion: {
    status: ToggleStatus.Disabled,
  },
  activeDigitalPromotionAlternate1: {
    status: ToggleStatus.Disabled,
  },
  addressValidation: {
    status: ToggleStatus.Enabled,
  },
  angeliqueKerber: {
    status: ToggleStatus.Disabled,
  },
  appleWatchLegalDisclaimer: {
    status: ToggleStatus.Disabled,
  },
  arVisualizerV3Override: {
    status: ToggleStatus.Disabled,
  },
  authorizationToken: {
    status: ToggleStatus.Dark,
  },
  AUwinterpromo2023: {
    status: ToggleStatus.Disabled,
  },
  bikeClasses: {
    status: ToggleStatus.Enabled,
  },
  boxingDayBikePlusPromo: {
    status: ToggleStatus.DateTrigger,
    start: '2021-12-26T03:01:00-05:00',
    end: '2022-01-09T03:00:59-05:00',
  },
  bikePlusPageBuilder: {
    status: ToggleStatus.Enabled,
  },
  bikePlusUpdates: {
    status: ToggleStatus.Enabled,
  },
  bikePageBuilder: {
    status: ToggleStatus.Enabled,
  },
  bikeReviews: {
    status: ToggleStatus.Enabled,
  },
  bikeV1HeroV2: {
    status: ToggleStatus.Enabled,
  },
  blog: {
    status: ToggleStatus.Enabled,
  },
  careersPageCrmButton: {
    status: ToggleStatus.DateTrigger,
    start: '2022-04-28T13:00:00.000Z',
    end: '3000-07-19T03:59:59.000Z',
  },
  careersPageVideo: {
    status: ToggleStatus.Enabled,
  },
  careersRefresh: {
    status: ToggleStatus.DateTrigger,
    start: '2021-09-01T00:01:00-04:00',
    end: '3000-07-19T03:59:59.000Z',
  },
  classFilters: {
    status: ToggleStatus.Dark,
  },
  ccpaNotice: {
    status: ToggleStatus.Enabled,
  },
  concessionaryPricingVeterinarians: {
    status: ToggleStatus.DateTrigger,
    start: '2022-04-27T10:30:00-04:00',
    end: '3000-07-19T03:59:59.000Z',
  },
  consolidatedReviews: {
    status: ToggleStatus.Enabled,
  },
  chasePartnership: {
    status: ToggleStatus.DateTrigger,
    start: '2021-03-30T08:30:00-04:00',
    end: '3000-07-19T03:59:59.000Z',
  },
  classPageCfuBannerCta: {
    status: ToggleStatus.Dark,
  },
  copyXray: {
    status: ToggleStatus.Disabled,
  },
  copyXrayExport: {
    status: ToggleStatus.Disabled,
  },
  covid19: {
    status: ToggleStatus.Enabled,
  },
  digitalCheckout: {
    status: ToggleStatus.Enabled,
  },
  digitalCheckoutTwoStep: {
    status: ToggleStatus.Enabled,
  },
  deliveryExpansion: {
    status: ToggleStatus.Disabled,
  },
  dfbeuros: {
    status: ToggleStatus.Disabled,
  },
  showAlternateDigitalCheckout: {
    status: ToggleStatus.Enabled,
  },
  extendedWarranty: {
    status: ToggleStatus.Enabled,
  },
  financingValueCalculator: {
    status: ToggleStatus.Enabled,
  },
  financingHomeTrial: {
    status: ToggleStatus.Enabled,
  },
  financingHomeTrialTest: {
    status: ToggleStatus.Enabled,
  },
  financingCitizensPageBuilder: {
    status: ToggleStatus.Disabled,
  },
  financingAffirmPageBuilder: {
    status: ToggleStatus.Disabled,
  },
  financingPageBuilder: {
    status: ToggleStatus.Enabled,
  },
  freeTrialHotelPromo: {
    status: ToggleStatus.Enabled,
  },
  freeTrial600MassPromo: {
    status: ToggleStatus.Enabled,
  },
  freeTrialProductInterest: {
    status: ToggleStatus.Enabled,
  },
  freeShoesRentalPromo: {
    status: ToggleStatus.Enabled,
  },
  geoModalV2: {
    status: ToggleStatus.Enabled,
  },
  giftingOrders: {
    status: ToggleStatus.Enabled,
  },
  googlePay: {
    status: ToggleStatus.Enabled,
  },
  guideCrossSell: {
    status: ToggleStatus.Disabled,
  },
  guidePageBuilder: {
    status: ToggleStatus.Enabled,
  },
  showFinancingPartner: {
    status: ToggleStatus.Enabled,
  },
  hideDiscover: {
    status: ToggleStatus.Disabled,
  },
  holidayLP: {
    status: ToggleStatus.DateTrigger,
    start: '2022-11-14T00:00:00-05:00',
    end: '2022-11-18T22:00:00-05:00',
  },
  holidayMothersDay: {
    status: ToggleStatus.Disabled,
  },
  holidayStrikethrough: {
    status: ToggleStatus.DateTrigger,
    start: '2021-09-01T00:00:00-05:00',
    end: '2021-12-26T00:00:00-05:00',
  },
  homepagePageBuilder: {
    status: ToggleStatus.Enabled,
  },
  homepageProductBlockSwitch: {
    status: ToggleStatus.Enabled,
  },
  homeTrialLeadCaptureModal: {
    status: ToggleStatus.Disabled,
  },
  klarnaAdditionalOpts: {
    status: ToggleStatus.Disabled,
  },
  klarnaPayIn3Accessories: {
    status: ToggleStatus.Disabled,
  },
  laneBreak: {
    status: ToggleStatus.DateTrigger,
    start: '2022-02-17T14:00:00.000Z',
    end: '3000-07-19T03:59:59.000Z',
  },
  lavender: {
    status: ToggleStatus.Disabled,
  },
  leadGenBetaPage: {
    status: ToggleStatus.DateTrigger,
    start: '2021-11-09T07:50:00-05:00',
    end: '2118-12-31T10:00:00-08:00',
  },
  lgTv: {
    status: ToggleStatus.DateTrigger,
    start: '2021-11-15T23:00:00-05:00',
    end: '3000-12-31T23:59:59-05:00',
  },
  mercury: {
    status: ToggleStatus.DateTrigger,
    start: '2022-04-14T18:00:00-04:00',
    end: '2022-07-13T10:00:00-04:00',
  },
  mothersDayModule: {
    status: ToggleStatus.DateTrigger,
    start: '2021-04-01T00:00:00-00:00',
    end: '2021-05-10T06:59:59.000Z',
  },
  needsAcceptanceOfTerms: {
    status: ToggleStatus.Disabled,
  },
  needsConsentForMarketing: {
    status: ToggleStatus.Disabled,
  },
  rentalsHideBikePlus: {
    status: ToggleStatus.DateTrigger,
    start: '2022-06-30T08:00:00-04:00',
    end: '2022-08-17T07:00:00-04:00',
  },
  optOutMarketing: {
    status: ToggleStatus.Disabled,
  },
  optimizeFinancingTerms: {
    status: ToggleStatus.Enabled,
  },
  orderHistoryDetailInAccountApp: {
    status: ToggleStatus.Enabled,
  },
  orderStatusForAppPurchases: {
    status: ToggleStatus.Enabled,
  },
  ourPledgeFooterLink: {
    status: ToggleStatus.DateTrigger,
    start: '2021-08-02T09:00:00-04:00',
    end: '3000-07-19T03:59:59.000Z',
  },
  pageBuilder: {
    status: ToggleStatus.Dark,
  },
  pgCareersPerksSection: {
    status: ToggleStatus.Enabled,
  },
  productRecommendations: {
    status: ToggleStatus.Enabled,
  },
  productValuePropLinks: {
    status: ToggleStatus.Enabled,
  },
  pressQuotes: {
    status: ToggleStatus.Enabled,
  },
  previewCopy: {
    status: ToggleStatus.Local,
  },
  projectCardinal: {
    status: ToggleStatus.DateTrigger,
    start: '2020-10-27T12:45:00.000Z',
    end: '3000-07-19T03:59:59.000Z',
  },
  projectHoney: {
    status: ToggleStatus.Enabled,
  },
  projectPhoenix: {
    status: ToggleStatus.DateTrigger,
    start: '2022-01-31T07:00:00-05:00',
    end: '2022-07-13T10:00:00-04:00',
  },
  protectionPlan: {
    status: ToggleStatus.DateTrigger,
    start: '2022-04-05T10:15:00.000Z',
    end: '3000-01-01T23:59:59-05:00',
  },
  postPurchaseFlowPage: {
    status: ToggleStatus.Enabled,
  },
  questionsAndAnswers: {
    status: ToggleStatus.Enabled,
  },
  reasons: {
    status: ToggleStatus.DateTrigger,
    start: '2020-10-05T00:00:00.000Z',
    end: '2099-12-31T03:59:59.000Z',
  },
  refurbishedBikeStrikethrough: {
    status: ToggleStatus.DateTrigger,
    start: '2023-03-16T09:00:00-04:00',
    end: '2023-04-02T09:00:00-04:00',
  },
  refurbishedBikePlusStrikethrough: {
    status: ToggleStatus.Disabled,
  },
  rokuLaunch: {
    status: ToggleStatus.DateTrigger,
    start: '2020-07-01T15:00:00.000Z',
    end: '3000-07-19T03:59:59.000Z',
  },
  reviewSubmissionRedesign: {
    status: ToggleStatus.Enabled,
  },
  safetyPage: {
    status: ToggleStatus.Enabled,
  },
  sapphireRedirect: {
    status: ToggleStatus.Enabled,
  },
  setupPage: {
    status: ToggleStatus.Enabled,
  },
  troyTownshipRedirect: {
    status: ToggleStatus.Enabled,
  },
  shouldShowVat: {
    status: ToggleStatus.Disabled,
  },
  showAbandonedCartLegalMessage: {
    status: ToggleStatus.Enabled,
  },
  showBikePlusStrikethrough: {
    status: ToggleStatus.Disabled,
  },
  showcaseBlogContent: {
    status: ToggleStatus.Enabled,
  },
  showDigitalCheckoutPaymentRequest: {
    status: ToggleStatus.Enabled,
  },
  showFinancingAprAndTermsFinePrint: {
    status: ToggleStatus.Enabled,
  },
  showFinancingComplaints: {
    status: ToggleStatus.Disabled,
  },
  showFinancingDisclosure: {
    status: ToggleStatus.Disabled,
  },
  showFinancingTermsAndConditions: {
    status: ToggleStatus.Disabled,
  },
  showFinancingLegalModalOnCheckout: {
    status: ToggleStatus.Disabled,
  },
  showGuideStrikethrough: {
    status: ToggleStatus.DateTrigger,
    start: '2023-02-21T09:00:00-05:00',
    end: '2023-05-22T09:00:00-05:00',
  },
  showRowAndTreadStrikethrough: {
    status: ToggleStatus.Disabled,
  },
  showLondonStudioLink: {
    status: ToggleStatus.Disabled,
  },
  showNYCStudioLink: {
    status: ToggleStatus.Enabled,
  },
  showPrivacyBanner: {
    status: ToggleStatus.Disabled,
  },
  showRecallLink: {
    status: ToggleStatus.Enabled,
  },
  showTreadFAQs: {
    status: ToggleStatus.Enabled,
  },
  showRowStrikethrough: {
    status: ToggleStatus.Disabled,
  },
  studentPage: {
    status: ToggleStatus.DateTrigger,
    start: '2021-11-01T14:00:00.000Z',
    end: '3000-07-19T03:59:59.000Z',
  },
  studioInNav: {
    status: ToggleStatus.Disabled,
  },
  supportCommercialBike: {
    status: ToggleStatus.Enabled,
  },
  subscribe: {
    status: ToggleStatus.Disabled,
  },
  showNearestShowroomModules: {
    status: ToggleStatus.Enabled,
  },
  showNearestShowroomModal: {
    status: ToggleStatus.Enabled,
  },
  freeTrialForThirtyDays: {
    status: ToggleStatus.DateTrigger,
    start: '2019-12-18T16:00:00.000Z',
    end: '3000-07-19T03:59:59.000Z',
  },
  freeTrialForNinetyDays: {
    status: ToggleStatus.Disabled,
  },
  freeTrialForSixtyDays: {
    status: ToggleStatus.DateTrigger,
    start: '2023-10-03T04:00:00.000Z',
    end: '2023-11-01T14:00:00.000Z',
  },
  treadClasses: {
    status: ToggleStatus.Enabled,
  },
  treadPageBuilder: {
    status: ToggleStatus.Enabled,
  },
  treadPlusSignupNavLink: {
    status: ToggleStatus.DateTrigger,
    start: '2021-08-24T07:00:00-04:00',
    end: '2118-12-31T10:00:00-08:00',
  },
  treadPlusSignupPage: {
    status: ToggleStatus.DateTrigger,
    start: '2021-08-24T07:00:00-04:00',
    end: '2118-12-31T10:00:00-08:00',
  },
  treadPlusReviews: {
    status: ToggleStatus.Disabled,
  },
  displayGuideReviews: {
    status: ToggleStatus.Enabled,
  },
  turkeyTrotLeadGenModal: {
    status: ToggleStatus.DateTrigger,
    start: '2021-08-04T09:00:00-04:00',
    end: '2021-11-23T23:59:00-04:00',
  },
  underMaintenance: {
    status: ToggleStatus.Disabled,
  },
  userContentMod: {
    status: ToggleStatus.Enabled,
  },
  vcCollapsableResults: {
    status: ToggleStatus.Disabled,
  },
  waysToTry: {
    status: ToggleStatus.DateTrigger,
    start: '2021-05-11T04:00:00.000Z',
    end: '3000-07-19T03:59:59.000Z',
  },
  tryPeloton: {
    status: ToggleStatus.Enabled,
  },
  whileYouWait: {
    status: ToggleStatus.DateTrigger,
    start: '2020-11-20T14:45:00.000Z',
    end: '3000-07-19T03:59:59.000Z',
  },
  stripeSetupIntentsCaptcha: {
    status: ToggleStatus.Enabled,
  },
  stripeCreateIntentsCaptcha: {
    status: ToggleStatus.Enabled,
  },
  skyTv: {
    status: ToggleStatus.Disabled,
  },
  hotelFinder: {
    status: ToggleStatus.Enabled,
  },
  showPortfolioHomepage: {
    status: ToggleStatus.Enabled,
  },
  shouldShowTaxEstimated: {
    status: ToggleStatus.Enabled,
  },
  uhcPartnership: {
    status: ToggleStatus.DateTrigger,
    start: '2021-09-01T04:00:00.000Z',
    end: '3021-09-01T04:00:00.000Z',
  },
  deltaPage: {
    status: ToggleStatus.DateTrigger,
    start: '2021-11-01T14:00:00.000Z',
    end: '2099-12-31T14:00:00.000Z',
  },
  holidayBikePage: {
    status: ToggleStatus.DateTrigger,
    start: '2021-12-03T08:00:00.000Z',
    end: '2021-12-14T08:00:00.000Z',
  },
  holidayTreadPage: {
    status: ToggleStatus.DateTrigger,
    start: '2021-11-15T08:01:00.000Z',
    end: '2021-11-30T08:00:00.000Z',
  },
  projectNationPage: {
    status: ToggleStatus.DateTrigger,
    start: '2021-12-13T13:00:00.000Z',
    end: '2022-01-31T15:30:00.000Z',
  },
  valueCalculatorBundles: {
    status: ToggleStatus.Local,
  },
  valueCalculatorShareAndSend: {
    status: ToggleStatus.Enabled,
  },
  prequalifyNow: {
    status: ToggleStatus.Enabled,
  },
  securityPage: {
    status: ToggleStatus.Enabled,
  },
  bikeRentals: {
    status: ToggleStatus.Enabled,
  },
  productsPage: {
    status: ToggleStatus.DateTrigger,
    start: '2022-06-15T04:00:00.000Z',
    end: '3000-06-15T13:00:00.000Z',
  },
  commerceToolsAccessories: {
    status: ToggleStatus.Disabled,
  },
  commercetoolsAccVarPriceStrikeThrough: {
    status: ToggleStatus.Disabled,
  },
  commerceToolsAvailability: {
    status: ToggleStatus.Disabled,
  },
  instructorsPageBuilder: {
    status: ToggleStatus.Dark,
  },
  usStatesPolicyUpdate: {
    status: ToggleStatus.DateTrigger,
    start: '2022-12-30T00:00:00-05:00',
    end: '3000-12-31T00:00:00-05:00',
  },
  mothersDay23: {
    status: ToggleStatus.Disabled,
  },
  poseidon: {
    status: ToggleStatus.Disabled,
  },
  treadBracket: {
    status: ToggleStatus.Disabled,
  },
  rearGuard: {
    status: ToggleStatus.Disabled,
  },
  datadogFeatureFlags: {
    status: ToggleStatus.Disabled,
  },
  showAtAboutPage: {
    status: ToggleStatus.Disabled,
  },
  showTreadPlusLeadGen: {
    status: ToggleStatus.Disabled,
  },
  showTreadPlusPreOrderPage: {
    status: ToggleStatus.Disabled,
  },
  showFinalSaleDisclaimer: {
    status: ToggleStatus.Disabled,
  },
  showDeKloppPage: {
    status: ToggleStatus.Disabled,
  },
  showStaticFinancingView: {
    status: ToggleStatus.Disabled,
  },
  umichFinancing: {
    status: ToggleStatus.DateTrigger,
    start: '2023-11-07T09:00:00-04:00',
    end: '3000-12-31T00:00:00-05:00',
  },
  removeGuideFromMainNav: {
    status: ToggleStatus.Enabled,
  },
  nbaAllStarRoute: {
    status: ToggleStatus.Disabled,
  },
  freeClassesAvailable: {
    status: ToggleStatus.Disabled,
  },
};

export default features;
