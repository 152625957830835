import React from 'react';

const Context = React.createContext<React.ElementType | undefined>(undefined);

const LinkComponentProvider: React.FC<
  React.PropsWithChildren<{ LinkComponent?: React.ElementType }>
> = ({ children, LinkComponent }) => (
  <Context.Provider value={LinkComponent}>{children}</Context.Provider>
);

export const useLinkComponent = () => {
  return React.useContext(Context);
};

export default LinkComponentProvider;
