import React from 'react';
import type { AccessibleIconProps } from '@ecomm/icons/types';

const LocationArrow: React.FC<React.PropsWithChildren<AccessibleIconProps>> = props => (
  <svg role="presentation" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <title>{props.title}</title>
    <g fill="none" fillRule="evenodd">
      <g fill="#181A1D">
        <g>
          <g>
            <path
              d="M120 5L114 17 114 11 108 11z"
              transform="translate(-1107 -1636) translate(959 1547) translate(40 84)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LocationArrow;
