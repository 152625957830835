import { all, fork } from 'redux-saga/effects';
import analyticsSaga from './analytics';
import cartSagas from './cart';
import couponSagas from './coupon';
import openCartSaga from './openCart';
import removeItemSagas from './removeItem';

export default function* combinedCartSaga() {
  yield all([
    fork(cartSagas),
    fork(couponSagas),
    fork(removeItemSagas),
    fork(analyticsSaga),
    fork(openCartSaga),
  ]);
}
