import type { LocationChangeAction } from 'connected-react-router';
import { LOCATION_CHANGE } from 'connected-react-router';
import { takeEvery, put, all } from 'redux-saga/effects';
import { openCartPanel } from '../redux';

const QUERY = 'cart=open';

export const optionallyOpenCart = function* (action: LocationChangeAction) {
  if (action.payload.location.search.includes(QUERY)) {
    yield all([put(openCartPanel())]);
  }
};

const watcherSaga = function* () {
  yield takeEvery(LOCATION_CHANGE, optionallyOpenCart);
};

export default watcherSaga;
