import { PRODUCTION } from '@peloton/app-config';
const qa1Host = 'qa1.onepeloton.com';
const prodHost = 'api.onepeloton.com.au';

const toHostUrl = (isProdEnv?: boolean) => (isProdEnv ? prodHost : qa1Host);

const toRelativeUrl = (cartId: string) => `ecomm/zipco/create_checkout?cart_id=${cartId}`;

export const redirectToZip = (cartId: string, { protocol } = window.location) => {
  const isProd = PRODUCTION as boolean | undefined;
  const url = `${protocol}//${toHostUrl(isProd)}/${toRelativeUrl(cartId)}`;
  window.location.href = url;
};
