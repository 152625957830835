import React from 'react';
import { useErrorReporter } from '@peloton/error-reporting/useErrorReporter';
import useCartWithVariables from '@ecomm/cart-next/hooks/useCartWithVariables';
import { useMigrationStatus } from '@ecomm/pg-checkout-commercetools/utils/useMigrationStatus';
import CartIconBuyFlow from './CartIconBuyFlow';
import CartIconMonolith from './CartIconMonolith';

const CartIcon: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { errorReporter } = useErrorReporter();
  const { CTCartEnabled } = useMigrationStatus();
  const { data, loading } = useCartWithVariables({
    throwError: false,
    reportSwallowedError: errorReporter.reportError,
  });
  const numberOfItemsInMonolithCart = data?.cart?.numberOfItems ?? 0;
  const isBuyFlow = CTCartEnabled && !numberOfItemsInMonolithCart;

  return (
    <>
      {isBuyFlow ? (
        <CartIconBuyFlow hideCount={loading} />
      ) : (
        <CartIconMonolith hideCount={loading} />
      )}
    </>
  );
};

export default CartIcon;
