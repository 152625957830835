import { grey, white, spacing, Eyebrow } from '@pelotoncycle/design-system';
import React, { useEffect, useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { useOauth } from '@peloton/auth/OauthProvider';
import { useLocale } from '@peloton/next/hooks/useLocale';
import useNav from '@content/client/www/nav/useNav';
import { useClearCartOnLogout } from '@ecomm/commercetools/hooks/useClearCartOnLogout';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { Link } from '@ecomm/internationalize-ui';
import logAction from '@ecomm/logging/logAction';
import { useLoginUrl, useNextLoginUrl } from '@ecomm/oauth/OAuthProvider';
import useLogout from '@ecomm/oauth/useLogout';
import { Menu } from './PopupMenu';

const accountMenuId = 'nav.account';

const SIGN_IN_KEY = 'nav.account.signIn';
const SIGN_OUT_KEY = 'nav.account.signOut';

/*
 * This type is coming from @ecomm/copy/components/CtaSetWithUrl,
 * but the type requires access to our redux links so redefining
 * a less strict version here
 */
type CtaSetWithUrlData = {
  key: string;
  text: string;
  url: string;
  targetBlank: boolean;
};

const AccountMenu: React.FC<React.PropsWithChildren<unknown>> = () => {
  const locale = useLocale();
  const { content, isLoading } = useNav(locale);
  const { trackEvent } = useTracking();

  const [href, setHref] = useState('');

  const setReturnToUrl = () => {
    setHref(window.location.href);
  };

  useEffect(setReturnToUrl, []);

  const { isAuthenticated } = useOauth();

  if (isLoading) {
    return null;
  }

  const ctas = content.userMenu['navigation.userMenu']['nav.account'].ctas;

  return (
    <StyledMenu data-test-id={'accountCardOpen'}>
      <ul aria-label="Account menu">
        {ctas.map(({ key, text, url, targetBlank }: CtaSetWithUrlData) => {
          const isSignInLink = SIGN_IN_KEY === key;
          const isSignOutLink = SIGN_OUT_KEY === key;
          const isAuthLink = isSignInLink || isSignOutLink;

          const onLinkClick = () => {
            trackEvent({
              properties: {
                category: accountMenuId,
                unitName: key,
                linkName: text,
                linkTo: url,
              },
            });
          };

          return isAuthLink ? (
            <React.Fragment key={text}>
              {isSignInLink && !isAuthenticated && (
                <AuthListItem>
                  <SignInLink text={text} currentHref={href} onClick={onLinkClick} />
                </AuthListItem>
              )}
              {isSignOutLink && isAuthenticated && (
                <AuthListItem>
                  <SignOutLink text={text} currentHref={href} onClick={onLinkClick} />
                </AuthListItem>
              )}
            </React.Fragment>
          ) : (
            <li key={text}>
              <StyledLink
                data-test-id={key}
                href={url}
                to={url}
                variant="body"
                target={targetBlank ? '_blank' : '_self'}
                onClick={onLinkClick}
              >
                <Eyebrow size="medium" as="p">
                  {text}
                </Eyebrow>
              </StyledLink>
            </li>
          );
        })}
      </ul>
    </StyledMenu>
  );
};

export default AccountMenu;

const StyledMenu = styled(Menu)`
  right: 0;
  background-color: ${white};
  border-radius: 3px;
  padding: ${spacing[40]};
  white-space: nowrap;

  li:not(:last-child) {
    min-width: 164px;
    margin-bottom: 1.5rem;
  }
`;

const StyledLink = styled(Link)`
  &:hover {
    color: ${grey[70]};
  }

  &:after {
    display: none;
  }
`;

const AuthListItem = styled.li`
  border-top: 1px solid ${grey[40]};
  padding-top: ${spacing[24]};
`;

type AuthLinkProps = Pick<CtaSetWithUrlData, 'text'> & {
  currentHref: string;
  onClick: () => void;
};

const SignInLink: React.FC<React.PropsWithChildren<AuthLinkProps>> = ({
  text,
  currentHref,
  onClick,
}) => {
  const legacyLoginUrl = useLoginUrl()(currentHref);
  const nextLoginUrl = useNextLoginUrl()(currentHref);
  const isToggleActive = useIsToggleActive();
  const isNextAuthLoginActive = isToggleActive('nextAuthLogin');
  const loginUrl = isNextAuthLoginActive ? nextLoginUrl : legacyLoginUrl;

  const onClickDecorated = () => {
    const uuid = window.crypto.randomUUID();
    sessionStorage.setItem('login-uuid', uuid);

    logAction('clicking sign in', {
      isNextAuthLoginActive,
      uuid,
    });

    onClick();
  };

  return (
    <StyledLink href={loginUrl} to={loginUrl} variant="body" onClick={onClickDecorated}>
      <Eyebrow size="medium" as="p">
        {text}
      </Eyebrow>
    </StyledLink>
  );
};

const SignOutLink: React.FC<React.PropsWithChildren<AuthLinkProps>> = ({
  text,
  currentHref,
  onClick,
}) => {
  const logout = useLogout();
  const clearCart = useClearCartOnLogout();

  return (
    <StyledLink
      href="#"
      to="#"
      variant="body"
      onClick={async e => {
        onClick();
        e.preventDefault();
        clearCart();
        await logout({}, { logoutParams: { returnTo: currentHref } });
      }}
    >
      <Eyebrow size="medium" as="p">
        {text}
      </Eyebrow>
    </StyledLink>
  );
};
