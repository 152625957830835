import type { SagaIterator } from 'redux-saga';
import { call, getContext, takeLatest, put } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { UserReducerActionType } from '@peloton/auth';
import { fetchUserFreeTrialEligibility } from '../api';
import { freeTrialEligibilitySet } from '../redux';

const freeTrialEligibilitySaga = function* (client: Client): SagaIterator {
  const userFreeTrialEligibility = yield call(fetchUserFreeTrialEligibility, client);
  yield put(freeTrialEligibilitySet(userFreeTrialEligibility.isEligibleForFreeTrial));
};

const watcherSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeLatest(
    UserReducerActionType.REQUEST_SUCCESS,
    freeTrialEligibilitySaga,
    client,
  );
};

export default watcherSaga;
