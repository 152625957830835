import { useContext, useEffect, useRef } from 'react';
import { GlobalUiStateContext } from '../GlobalUiStateProvider';
import { useWindowActive } from '../useWindowActive';

type TetherConfig = {
  reduceMotionTether?: boolean;
  windowActiveTether?: boolean;
  freezeTether?: boolean;
};

const useConnectedVideo = (config: TetherConfig) => {
  const ref = useRef<HTMLVideoElement | null>(null);
  const { reduceMotion, freeze } = useContext(GlobalUiStateContext);
  const windowActive = useWindowActive();
  const { reduceMotionTether, windowActiveTether, freezeTether } = config;

  useEffect(() => {
    if (!windowActive && windowActiveTether) {
      ref.current?.pause();
    }
  }, [windowActive, windowActiveTether]);

  useEffect(() => {
    if (reduceMotion && reduceMotionTether && !ref.current?.paused) {
      ref.current?.pause();
    }
  }, [reduceMotion, reduceMotionTether]);

  useEffect(() => {
    if (freeze && freezeTether && !ref.current?.paused) {
      ref.current?.pause();
    }
  }, [freeze, freezeTether]);

  return { ref };
};

export default useConnectedVideo;
