import type { BlurAction as TBlurAction, UpdateAction as TUpdateAction } from './form';
import {
  ActionTypes as FormTypes,
  blurRegistrationField,
  resolveFieldError,
  updateFieldError,
  updateRegistrationField,
} from './form';
import type {
  FailAction as TFailAction,
  RequestAction as TRequestAction,
  SuccessAction as TSuccessAction,
} from './register';
import {
  ActionTypes as RegisterTypes,
  failRegistration,
  register,
  succeedRegistration,
} from './register';
import type { ReducerState as TReducerState } from './rootReducer';
import reducer from './rootReducer';
import {
  getDoPasswordsMatch,
  getFormErrors,
  getRegistrationData,
  getRegistrationField,
  getRegistrationFieldErrors,
  getRegistrationStatus,
  getUserExists,
  hasRegistrationErrors,
} from './selectors';
import type { UserExistsAction as TUserExistsAction } from './user';
import {
  ActionTypes as RegistrationUserActionTypes,
  userExists,
  resolveDefaultMarketingState,
} from './user';

export default reducer;

export {
  FormTypes,
  RegisterTypes,
  RegistrationUserActionTypes,
  blurRegistrationField,
  failRegistration,
  getDoPasswordsMatch,
  getFormErrors,
  getRegistrationData,
  getRegistrationField,
  getRegistrationFieldErrors,
  getRegistrationStatus,
  getUserExists,
  hasRegistrationErrors,
  register,
  resolveFieldError,
  resolveDefaultMarketingState,
  succeedRegistration,
  updateFieldError,
  updateRegistrationField,
  userExists,
};

export type BlurAction = TBlurAction;
export type FailAction = TFailAction;
export type ReducerState = TReducerState;
export type RequestAction = TRequestAction;
export type SuccessAction = TSuccessAction;
export type UpdateAction = TUpdateAction;
export type UserExistsAction = TUserExistsAction;
