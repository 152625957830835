import { Label, brand, grey } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import type { Country } from '@peloton/internationalize/models/country';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import { media, mobilePortrait } from '@peloton/styles/breakpoints';
import { setSessionSimplifiedNav } from '@acme-ui/nav/hooks/useSimplifiedNav';
import { Link } from '@ecomm/internationalize-ui';
import type { CtaWithUrlData, CtaWithUrlSetData } from './models';

export type Props = CtaWithUrlSetData & {
  country: Country;
};

const LinkColumn: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  ctas,
  country,
}) => {
  return (
    <ColumnContainer>
      <ColumnHeader>
        <Label is="h2" size="large">
          {title}
        </Label>
      </ColumnHeader>

      {ctas.map((link, index) => {
        return (
          <FooterLink
            country={country}
            linkCategory={title}
            {...link}
            key={`link-${index}`}
          />
        );
      })}
    </ColumnContainer>
  );
};

export default LinkColumn;

type FooterLinkProps = Omit<CtaWithUrlData, 'key'> & {
  linkCategory?: string;
  country: Country;
};

const FooterLink: React.FC<React.PropsWithChildren<FooterLinkProps>> = ({
  text,
  url,
  targetBlank,
  ariaLabel,
  linkCategory,
  country,
}) => {
  const { trackEvent } = useTracking();

  const trackOnClick = () => {
    const trackingParams = {
      event: 'Clicked Footer Link',
      properties: {
        clickedFrom: getCurrentRelativePathname(),
        country,
        linkCategory,
        linkName: text,
        linkTo: url,
      },
    };
    trackEvent(trackingParams);
    setSessionSimplifiedNav('false');
  };

  return (
    <LinkItem>
      <Link
        ariaLabel={ariaLabel}
        target={targetBlank ? '_blank' : undefined}
        onClick={trackOnClick}
        href={url}
        variant="body"
        size="small"
      >
        <Label size="large">{text}</Label>
      </Link>
    </LinkItem>
  );
};

const ColumnContainer = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  flex-basis: 50%;

  ${media.tablet`
    flex-basis: 25%;
    margin-top: 0;
  `}

  ${media.desktop`
    margin: 0;
    flex-basis: auto;
  `}

  ${media.desktopXLarge`
    &:not(:last-child) {
      margin-right: 80px;
    }
  `}

  ${media.infinity`
    &:not(:last-child) {
      margin-right: 100px;
    }
  `}
`;

const ColumnHeader = styled.li`
  color: ${brand.darkest};
  margin-bottom: 15px;

  h2 {
    color: ${brand.dark};
  }
`;

const LinkItem = styled.li`
  font-size: 14px;
  line-height: normal;
  margin-bottom: 10px;

  ${mobilePortrait`
    margin: 10px 0px 10px 0px;
  `}

  a {
    text-decoration: none;
    color: ${grey[70]};
    &:hover {
      color: ${brand.dark};
    }

    p {
      line-height: normal;
    }
  }

  a::after,
  span::after {
    display: none;
  }
`;
