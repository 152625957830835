import React from 'react';
import { Link } from '@ecomm/links';

type Props = {
  href: string;
};

// @TODO consolidate with generic solution
const LinkRenderer: React.FC<React.PropsWithChildren<Props>> = ({ href, children }) => {
  return <Link to={href}>{children}</Link>;
};

export default LinkRenderer;
