import type { ClientDetails } from '@peloton/analytics';
import { toClientDetailsHeader } from '@peloton/analytics';
import type { Client } from '@peloton/api';

export const createBookmarkForClass = <
  BookmarkAnalyticsProps extends Record<string, any>
>(
  api: Client,
  classId: string,
  analytics: ClientDetails<BookmarkAnalyticsProps>,
) =>
  api.post(
    '/api/favorites/create',
    { rideId: classId },
    toClientDetailsHeader(analytics),
  );

export const deleteBookmarkForClass = <
  BookmarkAnalyticsProps extends Record<string, any>
>(
  api: Client,
  classId: string,
  analytics: ClientDetails<BookmarkAnalyticsProps>,
) =>
  api.post(
    '/api/favorites/delete',
    { rideId: classId },
    toClientDetailsHeader(analytics),
  );
