import React from 'react';
import type { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router';
import { asyncComponent } from '@peloton/code-splitting';
import { FeatureToggle } from '@ecomm/feature-toggle';
import { Route } from '@ecomm/router';

const path = '/shipping-reservation/:id';

const RouteOrRedirect: React.FC<
  React.PropsWithChildren<RouteComponentProps<{ id: string }>>
> = props => (
  <FeatureToggle toggleId="postPurchaseFlowPage">
    {isV2ReserveEnabled =>
      isV2ReserveEnabled ? (
        <Redirect
          from={path}
          to={`/delivery/${props.match.params.id}`}
          key="delivery-page-redirect"
          exact
        />
      ) : (
        route
      )
    }
  </FeatureToggle>
);

const route = (
  <Route
    key="shipping-reservation-page"
    path={path}
    component={asyncComponent(
      () =>
        import('./ShippingReservationPage' /* webpackChunkName: "ShippingReservation" */),
    )}
  />
);

const routes = [
  <Route
    key="shipping-reservation-or-redirect"
    path={path}
    component={RouteOrRedirect}
  />,
];

export default routes;
