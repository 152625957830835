import React from 'react';
import { useTracking } from 'react-tracking';
import { toDeviceCategoryFromWindow } from '@peloton/analytics/deviceCategory';
import { isSegmentInitialized, trackSegment as track } from '@peloton/analytics/segment';
import { getBrowser } from '@peloton/analytics/utils';
import { useUserInfo } from '@peloton/auth/UserInfoProvider';
import { useCookies } from '@peloton/cookies';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import logAction from '@ecomm/logging/logAction';

// Emulating the segment logic to call the callback once the tracking event fires if segment is blocked
// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
const maybeManuallyCallCallback = (callback?: () => void) => {
  if (callback && !isSegmentInitialized()) {
    logAction('Manually calling callback for segment event while segment is blocked');
    setTimeout(callback, 300);
  }
};

const TrackingProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { getUser } = useUserInfo();
  const { getCookie } = useCookies();

  const { Track } = useTracking(
    {},
    {
      dispatch: ({ properties, ...data }: any) => {
        // have reference to window here
        const user = getUser();

        const defaultProperties = {
          page: getCurrentRelativePathname(),
          deviceCategory: toDeviceCategoryFromWindow(),
          browser: getBrowser(),
          ttclid: sessionStorage.getItem('ttclid'),
          ttp: getCookie('_ttp'),
          ...user,
        };

        maybeManuallyCallCallback(data.callback);

        return track({
          ...data,
          properties: { ...defaultProperties, ...properties },
        });
      },
    },
  );
  return <Track>{children}</Track>;
};
export default TrackingProvider;
