import React from 'react';
import { useErrorReporter } from '@peloton/error-reporting';

const ONE_TRUST_MS_TIMEOUT = 10000;

// NOTE: window is not available in Next.js so we need to keep any declaration
//   of this function within useEffect which isn't called serverside but
//   instead at render.
//   See: nextjs.org/docs/migrating/from-create-react-app#safely-accessing-web-apis
const promiseTimeout = (promise: Promise<any>, callback: () => void, ms: number) => {
  let timeoutID: number;
  const timeoutPromise = new Promise((res, rej) => {
    timeoutID = window.setTimeout(() => {
      callback();
      res(timeoutID);
    }, ms);
  });
  return Promise.race([promise, timeoutPromise]).then(res => {
    window.clearTimeout(timeoutID);
    return res;
  });
};

export const OneTrustTimeoutReporter: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { errorReporter } = useErrorReporter();
  React.useEffect(() => {
    promiseTimeout(
      // NOTE: window is not available in Next.js so we need to keep this code within
      //   useEffect which isn't called serverside but instead at render.
      //   See: nextjs.org/docs/migrating/from-create-react-app#safely-accessing-web-apis
      window.__promiseHooks.oneTrustLoaderPromise,
      () => {
        errorReporter.reportError(
          new Error('OneTrust has failed to load and has timed out'),
        );
      },
      ONE_TRUST_MS_TIMEOUT,
    );
  });

  return null;
};
