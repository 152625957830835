import { spacing, Flex, grey } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import type { CtaWithUrlSetData } from '@ecomm/copy/helpers/types';
import LinkList from './LinkList';

const LinkRow: React.FC<
  React.PropsWithChildren<{ ctas: CtaWithUrlSetData | undefined }>
> = ({ ctas }) => (
  <StyledFlex
    gap={spacing[40]}
    showBorder={Boolean(ctas)}
    padding={`${spacing[16]} 0 ${spacing[24]} 0`}
    margin={`${spacing[32]} 0 0 0`}
  >
    {ctas && <LinkList ctas={ctas} footerData={true} />}
  </StyledFlex>
);

export default LinkRow;

const StyledFlex = styled(Flex)<{ showBorder: boolean }>`
  overflow: auto;
  white-space: nowrap;
  ${({ showBorder }) => (showBorder ? `border-top: ${grey[40]} solid 1px;` : '')}
`;
