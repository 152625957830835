import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

const Routes = [
  // The trade in route has to come before the /bikes route as otherwise, it's unreachable.
  // It would be better to integrate it into the Handler; however, that is currently not possible
  // as the trade in page brings its own nav/layout
  <Route
    key="bikes-trade-in"
    path={`/bikes/trade-in`}
    component={asyncComponent(
      () => import('@ecomm/pg-bikes-trade-in' /* webpackChunkName: "BikesTradeInPage" */),
    )}
    page
    exact
    shouldTrack={true}
  />,
  <Route
    key="bikes-pages"
    path="/bikes"
    component={asyncComponent(() => import('./Handler' /* webpackChunkName: "Bikes" */))}
    page={true}
    shouldTrack={false}
  />,
];

export default Routes;
