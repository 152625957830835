import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

const Routes = [
  <Route
    exact
    key="cfu-signup"
    path="/:cfu(bike|bike-plus|bikes|tread|tread-plus|treads)/sign-up"
    component={asyncComponent(() => import('./Handler' /* webpackChunkName: "Bike" */))}
  />,
];

export default Routes;
