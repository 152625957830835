import React from 'react';
import type { RouteComponentProps } from 'react-router';
import { Redirect, withRouter } from 'react-router';
import { compose } from 'recompose';
import type { ExtLinkEnvProps } from '@peloton/external-links';
import { toHref, withExtLinkEnv } from '@peloton/external-links';
import type { Locale } from '@peloton/internationalize';
import { checkAndRemoveLocalePrefix } from '@peloton/internationalize/models/path';
import { Links } from '@peloton/links';
import { redirect } from '@peloton/navigation';
import { Route } from '@ecomm/router';
import type { Storage } from './shared';
import {
  ACCESS_OVERRIDE_ENDPOINT,
  ACCESS_OVERRIDE_KEY_VALUE,
  toAccessOverrideKey,
  hasLocaleRestrictionOverrideForLocale,
} from './shared';

export type Props = {
  disabled?: boolean;
  locale: Locale;
  restrictedLocales: Locale[];
  isOverridePath: boolean;
  storage: Storage;
  unrestrictedPaths?: Partial<Record<Locale, string[]>>;
  windowLocation?: Location; // dependency injecting window.location for tests
  children?: React.ReactNode;
};

type FeatureProps = {
  germanLaunch: boolean;
};

class RestrictLocaleAccess extends React.Component<
  Props & FeatureProps & ExtLinkEnvProps & RouteComponentProps<{}>
> {
  public componentDidMount() {
    if (this.shouldRedirect()) {
      redirect(
        toHref(this.getPelotonHref(), this.props.extLinkEnv),
        this.props.windowLocation || window.location,
      );
    }
  }

  render() {
    return (
      <>
        <Route path={ACCESS_OVERRIDE_ENDPOINT} component={this.GrantLocaleAccess} />
        {this.shouldRedirect() || this.props.children}
      </>
    );
  }

  private shouldRedirect() {
    return (
      !this.props.disabled &&
      this.isCurrentLocaleRestricted() &&
      this.isRouteRestricted() &&
      !this.hasLocaleRestrictionOverride()
    );
  }

  private isCurrentLocaleRestricted() {
    return this.props.restrictedLocales.indexOf(this.props.locale) !== -1;
  }

  private isRouteRestricted() {
    const { unrestrictedPaths } = this.props;
    const relativeUrl = checkAndRemoveLocalePrefix(this.props.location.pathname);
    if (!Boolean(unrestrictedPaths)) {
      return true;
    }
    return (
      unrestrictedPaths &&
      unrestrictedPaths[this.props.locale] &&
      !unrestrictedPaths[this.props.locale]!.find((path: string) => path === relativeUrl)
    );
  }

  private getPelotonHref() {
    return { ...Links.peloton, hostnameOverrides: undefined };
  }

  private hasLocaleRestrictionOverride() {
    return hasLocaleRestrictionOverrideForLocale({
      isOverridePath: this.props.isOverridePath,
      storage: this.props.storage,
      locale: this.props.locale,
    });
  }

  private GrantLocaleAccess: React.FC<
    React.PropsWithChildren<RouteComponentProps<{}>>
  > = () => {
    this.props.storage.setItem(
      toAccessOverrideKey(this.props.locale),
      ACCESS_OVERRIDE_KEY_VALUE,
    );
    return <Redirect to="/" />;
  };
}

export default compose<Props, Props>(withExtLinkEnv, withRouter)(RestrictLocaleAccess);
