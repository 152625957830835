import React from 'react';
import type { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router';
import { asyncComponent } from '@peloton/code-splitting';
import { NotFoundError, NotFoundErrorPage, Page } from '@ecomm/layout';
import { Product, useProductStates } from '@ecomm/product-states';
import { usePrograms } from '@ecomm/programs';
import { InternationalSwitch, Route } from '@ecomm/router';

const Handler: React.FC<React.PropsWithChildren<RouteComponentProps<{}>>> = ({
  match,
}) => {
  const { isProductAvailableForMarketingPages } = useProductStates();
  const { homeTrial } = usePrograms();
  const isBikePlusActive = isProductAvailableForMarketingPages(Product.BikePlus);

  if (isBikePlusActive) {
    return (
      <InternationalSwitch>
        <Redirect
          key="bike-instructors-redirect"
          from={`${match.path}/instructors/:username?`}
          to="/instructors/bike/:username?"
        />
        <Redirect
          key="hometrial-redirect"
          from={`${match.path}/home-trial`}
          to="/home-trial"
        />
        <NotFoundErrorPage />
      </InternationalSwitch>
    );
  }

  return (
    <Page>
      <InternationalSwitch>
        <Redirect
          key="bike-instructors-redirect"
          from={`${match.path}/instructors/:username?`}
          to="/instructors/bike/:username?"
        />
        {homeTrial && (
          <Route
            key="bike-trial-page"
            path={`${match.path}/home-trial`}
            component={asyncComponent(
              () => import(/*webpackChunkName: "BikeTrial"*/ '@ecomm/pg-bike-trial'),
            )}
          />
        )}
        <NotFoundError />
      </InternationalSwitch>
    </Page>
  );
};

export default Handler;
