import { createClientHelpers, createClients } from './client';

const clientParams = {
  space: process.env.PB_CF_SPACE_ID as string,
  accessToken: process.env.PB_CF_DELIVERY_ACCESS_TOKEN as string,
  previewAccessToken: process.env.PB_CF_PREVIEW_ACCESS_TOKEN,
  environment: process.env.PB_CF_ENV || 'master',
};

const { client, previewClient } = createClients(clientParams);

const { getClient } = createClientHelpers(client, previewClient);

export { getClient, client, previewClient };

export default client;
