import { useSelector } from 'react-redux';
import { useApq } from '@peloton/auth';
import { getLocale } from '@peloton/env';
import { toStoreSlug } from '@peloton/stores';
import { fromLocale, toCurrencyfromLocale } from '@ecomm/graphql-bridge';
import type { ProductLinesQueryResult } from './ProductLinesQuery.generated';
import { useProductLinesQuery as useBaseProductLinesQuery } from './ProductLinesQuery.generated';

export type ProductLinesHook = Pick<
  ProductLinesQueryResult,
  'error' | 'loading' | 'data'
>;

const useProductLinesQuery = (): ProductLinesHook => {
  // TODO: Set locale and currency as default variables on all requests made via graphql
  const baseLocale = useSelector(getLocale);
  const locale = fromLocale(baseLocale);
  const currency = toCurrencyfromLocale(baseLocale);
  const catalog = toStoreSlug();
  const shouldUseApq = useApq();

  const { data, loading, error } = useBaseProductLinesQuery({
    variables: {
      currency,
      locale,
      catalog,
    },
    suspend: false,
    // If there is a secrets token in the browser this lets us bypass cache
    // by forcing a POST request
    fetchPolicy: shouldUseApq ? 'cache-first' : 'network-only',
    useApq: shouldUseApq,
  });

  return {
    data,
    loading,
    error,
  };
};

export default useProductLinesQuery;
