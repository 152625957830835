import { connect } from 'react-redux';
import type { DispatchProps, StateProps } from './LoginModalView';
import LoginModalView from './LoginModalView';
import type { State } from './redux';
import { closeLoginModal, selectIsOpen, selectPrefilledEmail } from './redux';

type GlobalState = {
  loginModal: State;
};

const mapStateToProps = ({ loginModal: state }: GlobalState) => ({
  email: selectPrefilledEmail(state),
  isOpen: selectIsOpen(state),
});

const mapDispatchToProps = {
  closeHandler: closeLoginModal,
};

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(LoginModalView);
