import { setCookie } from '../../auth/cookies';
import { CHECKOUT_ACCESS_TOKEN_STORAGE_KEY } from '../constants';
import { SameSiteValues } from './constants';
import { parseJwt } from './parseJwt';

export const setJwtToCookies = (token: string) => {
  const { exp: expires } = parseJwt(token);

  const domain = `${window.location.hostname.split('.').slice(1).join('.')}`;

  setCookie({
    name: CHECKOUT_ACCESS_TOKEN_STORAGE_KEY,
    value: JSON.stringify(token),
    options: {
      domain,
      path: '/',
      sameSite: SameSiteValues.LAX,
      expires,
    },
  });
};
