import { hasVariation } from '@peloton/split-testing';

const PRODUCT_LEAD_CAPTURE_EXPERIMENT = 'Product Lead Capture Modal B';
const VARIATION_1 = 'Variation #1';
const VARIATION_2 = 'Variation #2';
const VARIATION_3 = 'Variation #3';

export const showModalBVariation1 = hasVariation(
  PRODUCT_LEAD_CAPTURE_EXPERIMENT,
  VARIATION_1,
);

export const showModalBVariation2 = hasVariation(
  PRODUCT_LEAD_CAPTURE_EXPERIMENT,
  VARIATION_2,
);
export const showModalBVariation3 = hasVariation(
  PRODUCT_LEAD_CAPTURE_EXPERIMENT,
  VARIATION_3,
);
