import { auFlag, caFlag, deFlag, ukFlag, usFlag, atFlag } from '../flags';
import { Locale } from './locale';

const localeFlagMap: Record<Locale, string> = {
  [Locale.EnglishCanada]: caFlag,
  [Locale.EnglishUnitedKingdom]: ukFlag,
  [Locale.EnglishUnitedStates]: usFlag,
  [Locale.EnglishAustralia]: auFlag,
  [Locale.GermanGermany]: deFlag,
  [Locale.GermanAustria]: atFlag,
  [Locale.EnglishBeta]: usFlag,
  [Locale.SpanishMexico]: ukFlag, // TODO: replace with mexico flag
  [Locale.FrenchCanada]: caFlag,
};

export const toLocaleFlag = (locale: Locale): string => localeFlagMap[locale];
