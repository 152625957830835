import type { ID, Slug } from '@ecomm/models';

export enum CategoryType {
  Package = 'package',
  Accessories = 'accessories',
  Bike = 'bike',
  Tread = 'tread',
  TreadPlus = 'tread-plus',
  Stoneocean = 'stoneocean', // TODO: completely replace with BikePlus when possible
  BikePlus = 'bike-plus',
  BikeV1 = 'bikev1',
  More = 'more-sets',
}

export type Category = {
  id: ID;
  name: string;
  slug: Slug;
  bundles: ID[];
  products: ID[];
};

export const PACKAGE_CATEGORY = CategoryType.Package;

export const isPackageCategory = (category: Category) =>
  category.slug === PACKAGE_CATEGORY;
