import type { ModalTrigger } from './modal-trigger';
import { isModalDismissed } from './utils';

// the generic variation is for displaying promo modals
// that do not have a specific page trigger and base no
// content on the page they've been triggered from.
//
// for example, a promo modal that appears on financing
// and home trial, leading to bike rentals
export enum ModalVariation {
  Bike = 'bike',
  BikePlus = 'bike-plus',
  Classes = 'classes',
  Tread = 'tread',
  Row = 'row',
  Guide = 'guide',
  HomeTrial = 'home-trial',
  Membership = 'membership',
  Generic = 'generic',
}

export type ReducerState = {
  promoModal: State;
};

export type State = {
  modal: undefined | ModalVariation;
  dismissed: boolean;
  trigger?: ModalTrigger;
};

const toDefaultState = () => ({
  modal: undefined,
  // to make sure we don't show the modal multiple times
  // per session
  dismissed: isModalDismissed(),
});

export const defaultState = toDefaultState();

export const reducer = (state = defaultState, action: Action) => {
  switch (action.type) {
    case Actions.OpenModal: {
      return {
        ...state,
        modal: action.payload.modal,
        trigger: action.payload.trigger,
      };
    }
    case Actions.ClickCTA:
    case Actions.CloseModal: {
      return {
        ...state,
        modal: undefined,
        dismissed: true,
        trigger: undefined,
      };
    }
    case Actions.ClearOpenModal: {
      if (state.modal) {
        return {
          ...state,
          modal: undefined,
          trigger: undefined,
        };
      } else {
        return state;
      }
    }
    default: {
      return state;
    }
  }
};

export enum Actions {
  OpenModal = 'ecomm/promo-modal/OPEN_MODAL',
  // this differs from close modal because it is not closed by
  // user action, so we don't want to mark it as dismissed.
  ClearOpenModal = 'ecomm/promo-modal/CLEAR_OPEN_MODAL',
  CloseModal = 'ecomm/promo-modal/CLOSE_MODAL',
  ClickCTA = 'ecomm/promo-modal/CLICK_CTA',
}

type Action = OpenModal | ClearOpenModal | CloseModal | ClickCTA;

export type OpenModal = {
  type: Actions.OpenModal;
  payload: {
    modal: ModalVariation;
    trigger?: ModalTrigger;
  };
};

type ClearOpenModal = {
  type: Actions.ClearOpenModal;
};

type CloseModal = {
  type: Actions.CloseModal;
};

type ClickCTA = {
  type: Actions.ClickCTA;
  payload: {
    modal: ModalVariation;
  };
};

export const openModal = (variation: ModalVariation, trigger?: ModalTrigger) => ({
  type: Actions.OpenModal,
  payload: {
    modal: variation,
    trigger,
  },
});

export const clearOpenModal = () => ({
  type: Actions.ClearOpenModal,
});

export const closeModal = () => ({
  type: Actions.CloseModal,
});

export const clickCTA = (variation: ModalVariation) => ({
  type: Actions.ClickCTA,
  payload: {
    modal: variation,
  },
});
