import React from 'react';
import { Redirect } from 'react-router-dom';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';
import { FitnessDiscipline } from './models';

const optionalSlugPattern = '(/:optionalSlug)?';
const containsInstructorsPattern = ':instructors(.*instructors.*)';
const disciplinePattern = `:discipline(${FitnessDiscipline.Bike}|${FitnessDiscipline.Tread}|${FitnessDiscipline.Yoga}|${FitnessDiscipline.Yoga})?`;

export const path = `${optionalSlugPattern}/${containsInstructorsPattern}/${disciplinePattern}`;

const routes = [
  <Route
    key="instructor-page"
    path="/instructors/:discipline(bike|tread|yoga)?"
    component={asyncComponent(
      () => import('./Handler' /* webpackChunkName: "Instructors" */),
    )}
    shouldTrack={false}
  />,
  <Redirect
    key="yoga-instructors-list"
    from="/yoga/instructors/:username?"
    to="/instructors/yoga/:username?"
  />,
];

export default routes;
