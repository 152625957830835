import { createMatchSelector } from 'connected-react-router';
import {
  any as anyOf,
  compose,
  difference,
  equals,
  filter,
  find,
  flatten,
  flip,
  head,
  intersection,
  map,
  mapObjIndexed,
  mergeDeepLeft,
  pick,
  pipe,
  prop,
  propEq,
  propOr,
  repeat,
  sort,
  sortBy,
  subtract,
  uniq,
  values,
} from 'ramda';
import type { match } from 'react-router';
import { createSelector } from 'reselect';
import type { Exception } from '@ecomm/exceptions';
import type { ReducerState as ToggleState } from '@ecomm/feature-toggle';
import type { ID, Slug, TransactionState, UIState } from '@ecomm/models';
import { getException, isLoading, Status } from '@ecomm/models';
import type { Warranty } from '@ecomm/warranty/models';
import type { ReducerState as WarrantyState } from '@ecomm/warranty/redux';
import { getAllWarranties } from '@ecomm/warranty/redux';
import type {
  Bundle,
  BundleConfiguration,
  Category,
  Dimension,
  Entities,
  Product,
  ProductConfiguration,
  ProductLine,
  ProductOption,
  Trait,
  WithCount,
} from '../models';
import {
  BundleType,
  isAccessoriesBundle,
  isBikeBundle,
  isBikePlusBundle,
  isBundleConfigured,
  isConfigurable,
  isGuideBundle,
  isPackageCategory,
  isProductConfigured,
  isTreadBundle,
  isTreadPlusBundle,
  toCheapestEntity,
  toEntitiesWithCount,
  toIsProduct,
  toIsProductOption,
} from '../models';
import type { ReducerState as RootState, State as ShopState } from './rootReducer';

export const getShop = (state: RootState): ShopState => state.shop;

export const getBundles = (state: RootState): Entities<Bundle> => getShop(state).bundles;

export const getProducts = (state: RootState): Entities<Product> =>
  getShop(state).products;

export const getCategories = (state: RootState): Entities<Category> =>
  getShop(state).categories;

export const getConfigurations = (state: RootState) => getShop(state).configure;

export const getDimensions = (state: RootState): Entities<Dimension> =>
  getShop(state).dimensions;

export const getProductOptions = (state: RootState): Entities<ProductOption> =>
  getShop(state).productOptions;

export const getTraits = (state: RootState): Entities<Trait> => getShop(state).traits;

export const getBundlesByIds = (
  state: RootState,
  props: { bundles: ID[] },
): Entities<Bundle> => pick(props.bundles, getBundles(state));

export const getProductsByIds = (
  state: RootState,
  props: { products: ID[] },
): Entities<Product> => pick(props.products, getProducts(state));

export const getTraitsByIds = (
  state: RootState,
  props: { traits: ID[] },
): Entities<Trait> => pick(props.traits, getTraits(state));

export const getUIState = (state: RootState) => getShop(state).ui;

export const getUIStateForBundleType = (
  state: RootState,
  props: { bundleType: BundleType },
): UIState | undefined => getShop(state).ui[props.bundleType];

export const getUIStateAreAnyLoading = (state: RootState): boolean =>
  Object.values(getShop(state).ui).some(product =>
    isLoading(product || { status: Status.Init }),
  );

export const getTransactionState = (
  state: RootState,
  props: { id: ID },
): TransactionState | undefined => getShop(state).ui[props.id];

export const getBundleById = (state: RootState, props: { id: ID }): Bundle | undefined =>
  (getBundles(state) || {})[props.id];

export const getProductById = (
  state: RootState,
  props: { id: ID },
): Product | undefined => getProducts(state)[props.id];

export const getProductOptionById = (
  state: RootState,
  props: { id: ID },
): ProductOption | undefined => getProductOptions(state)[props.id];

export const getDimensionById = (
  state: RootState,
  props: { id: ID },
): Dimension | undefined => getDimensions(state)[props.id];

export const getBundleBySlug = (
  state: RootState,
  props: { slug: Slug },
): Bundle | undefined => find(propEq('slug', props.slug), values(getBundles(state)));

const getSlugs = (_: RootState, props: { slugs: string[] }) => props.slugs;

export const getCategoriesBySlugs = createSelector(
  getCategories,
  getSlugs,
  (categories: Entities<Category>, slugs: string[]) =>
    map(slug => find(propEq('slug', slug), values(categories)) ?? {}, slugs),
);

export const getCategoriesByIds = (categoryIds: ID[]) =>
  createSelector(getCategories, (categories: Entities<Category>) =>
    map((id: ID) => categories[id], categoryIds),
  );

const getPropByCategorySlugs = (key: 'bundles' | 'products') =>
  createSelector(getCategoriesBySlugs, (categories: Category[]): string[] => {
    const allBundleIds = map<Category, string[]>(propOr([], key), categories);
    return allBundleIds.length > 0 ? allBundleIds.reduce(intersection) : [];
  });

export const getBundleType = createSelector<
  RootState,
  { id: string },
  Bundle | undefined,
  BundleType | undefined
>(getBundleById, propOr(undefined, 'bundleType'));

export const getSortedBundleIdsByCategorySlugs = createSelector(
  getPropByCategorySlugs('bundles'),
  getBundles,
  (bundleIds, allBundles) =>
    bundleIds.sort((bundleIdA, bundleIdB) => {
      const priceA = propOr<number, Bundle, number>(0, 'price', allBundles[bundleIdA]);
      const priceB = propOr<number, Bundle, number>(0, 'price', allBundles[bundleIdB]);

      return priceA - priceB;
    }),
);

export const getProductIdsByCategorySlugs = getPropByCategorySlugs('products');

const getProductLine = (_: RootState, props: { productLine: ProductLine }) =>
  props.productLine;

export const getProductOptionsByProductLine = createSelector(
  getProductOptions,
  getProductLine,
  (productOptions: Entities<ProductOption>, productLine: ProductLine) =>
    filter(toIsProductOption(productLine), values(productOptions)),
);

export const areProductsProductLine = createSelector<
  RootState,
  { products: ID[]; productLine: ProductLine },
  Entities<Product>,
  ProductLine,
  boolean
>(
  getProductsByIds,
  getProductLine,
  (products: Entities<Product>, productLine: ProductLine) =>
    anyOf(toIsProduct(productLine), values(products)),
);

const toSlugOrder = (slugSortOrderMap: Record<string, number>) => (slug: string) =>
  slugSortOrderMap[slug] ?? -1;

const toSortProductsBySlug = pipe(
  toSlugOrder,
  slugOrder => ({ slug: slugA = '' }, { slug: slugB = '' }) =>
    slugOrder(slugA) - slugOrder(slugB),
);

export const getProductConfigurationsByBundleId = (
  state: RootState,
  props: { bundleId: ID; slugSortOrderMap?: Record<string, number> },
): ProductConfiguration[] => {
  const bundleConfiguration = getBundleConfigurationById(state, { id: props.bundleId });
  const sortProductsBySlug = toSortProductsBySlug(props.slugSortOrderMap || {});
  const slugsById = Object.keys(bundleConfiguration).map((id: string) => ({
    id,
    slug: getProductById(state, { id })?.slug,
  }));

  const sortedProductConfigurations = slugsById
    .sort(sortProductsBySlug)
    .map(({ id }) => ({ id, ...bundleConfiguration[id] }));
  return sortedProductConfigurations;
};

export const getBundleConfigurationById = (state: RootState, props: { id: ID }) =>
  getConfigurations(state).bundles[props.id];

export const getProductConfigurationById = (state: RootState, props: { id: ID }) => {
  return getConfigurations(state).products[props.id];
};

export const getPackageSelectionsBySlug = (state: RootState, props: { slug: Slug }) =>
  getConfigurations(state).packages[props.slug] ?? {};

export const getProductBySlug = (
  state: RootState,
  props: { slug: Slug },
): Product | undefined => find(propEq('slug', props.slug), values(getProducts(state)));

const getFilterMethod = (state: ToggleState, props: { bundleType: BundleType }) => {
  const errorMessage = `BundleType: ${
    props.bundleType || 'undefined'
  } not implemented in getFilterMethod`;
  if (!props.bundleType) {
    throw Error(errorMessage);
  }

  switch (props.bundleType) {
    case BundleType.Bike:
      return isBikeBundle;

    case BundleType.Tread:
      return isTreadBundle;

    case BundleType.Accessories:
      return isAccessoriesBundle;

    case BundleType.BikePlus:
      return isBikePlusBundle;

    case BundleType.TreadPlus:
      return isTreadPlusBundle;

    case BundleType.RainforestCafe:
      return isGuideBundle;

    default:
      throw Error(errorMessage);
  }
};

export const getTraitsByProductId = createSelector(
  getProductById,
  getProductOptions,
  getTraits,
  (product: Product, allProductOptions: Entities<ProductOption>, allTraits) =>
    uniq(
      product.options
        .map(option => allProductOptions[option])
        .reduce((acc: string[], curr: ProductOption) => {
          if (curr.isAvailable) {
            return [...acc, ...curr.traits];
          } else {
            return [...acc];
          }
        }, []),
    ),
);

export const getBundleByType = createSelector<
  ToggleState & RootState,
  { bundleType: BundleType },
  Entities<Bundle>,
  (b: Bundle) => boolean,
  Bundle[]
>(getBundles, getFilterMethod, (bundles, bundleCheck) =>
  pipe<Entities<Bundle>, Bundle[], Bundle[]>(values, filter(bundleCheck))(bundles),
);

export const getBundleEntitiesByType = createSelector(getBundleByType, bundles =>
  bundles.reduce((accum, entity) => ({ ...accum, [entity.id]: entity }), {}),
);

export const getBundleIdsByType = createSelector(
  getBundleByType,
  pipe<Bundle[], Bundle[], ID[]>(sortBy(prop('price')), map(prop('id'))),
);

export const getPackageIdsByType = createSelector<
  RootState,
  { bundleType: BundleType },
  Entities<Bundle>,
  Entities<Category>,
  ID[]
>(
  getBundleEntitiesByType,
  getCategories,
  (bundles: Entities<Bundle>, categories: Entities<Category>): ID[] => {
    const allPackageIds = pipe<
      Entities<Category>,
      Category[],
      Category[],
      Category,
      ID[]
    >(
      values,
      filter(isPackageCategory),
      head,
      prop('bundles'),
    )(categories);

    const packageIds = pipe<Entities<Bundle>, Bundle[], Bundle[], ID[]>(
      values,
      sortBy(prop('price')),
      map(prop('id')),
    )(pick(allPackageIds || [], bundles || {}));

    return packageIds;
  },
);

const getProductCount = (_: RootState, props: { products: ID[] }) =>
  toEntitiesWithCount(props.products);

export const getProductsWithCount = createSelector<
  RootState,
  { products: ID[] },
  Entities<Product>,
  Entities<WithCount>,
  Entities<Product & WithCount>
>(getProductsByIds, getProductCount, mergeDeepLeft);

export const getProductsWithCountFromBundle = createSelector<
  RootState,
  { id: ID },
  Bundle | undefined,
  Entities<Product>,
  Entities<Product & WithCount>
>(getBundleById, getProducts, (bundle, allProducts) => {
  const { products: productIds = [] } = bundle || {};
  const products = pick(productIds, allProducts);
  const productCount = toEntitiesWithCount(productIds);

  return mergeDeepLeft(products, productCount);
});

export const areProductsConfigurable = createSelector(
  getProductsByIds,
  pipe(values, anyOf(isConfigurable)),
);

const toCheapestOptions = (
  products: Entities<Product & WithCount>,
  productOptions: Entities<ProductOption>,
): ID[] => {
  const flippedPick = flip(pick);

  const toCheapestId = compose(
    prop('id'),
    toCheapestEntity,
    flippedPick(productOptions),
    prop('options'),
  );

  return flatten<ID>(
    map<Product & WithCount, string[]>(
      ({ count, ...product }) => repeat(toCheapestId(product), count),
      values(products),
    ),
  );
};

export const getProductPriceRange = createSelector(
  getProductById,
  getProductOptions,
  (product: Product, allProductOptions: Entities<ProductOption>) => {
    const options = product.options.map(option => allProductOptions[option]);
    const prices = pipe<ProductOption[], number[], number[], number[]>(
      map(prop('price')),
      uniq,
      sort(subtract),
    )(options);

    return prices.length >= 2
      ? { low: prices[0], high: prices[prices.length - 1] }
      : undefined;
  },
);

export const getCheapestBundle = createSelector(
  getBundleByType,
  pipe<Bundle[], Bundle[], Bundle>(sortBy(prop('price')), head),
);

export const getCheapestOptions = createSelector(
  getProductsWithCount,
  getProductOptions,
  toCheapestOptions,
);

export const getIsBundleConfigured = createSelector(
  getBundleConfigurationById,
  isBundleConfigured,
);

export const getIsProductConfigured = createSelector(
  getProductConfigurationById,
  (config: ProductConfiguration) => config && isProductConfigured(config),
);

const toConfigurableOptions = (
  configuration: BundleConfiguration,
  products: Entities<Product & WithCount>,
  productOptions: Entities<ProductOption>,
): ID[] => {
  const toOptionId = ({ traits }: ProductConfiguration, id: ID) => {
    const availableOptions = pick(products[id].options, productOptions);

    return map(
      (trait: ID) => find(propEq('traits', [trait]), values(availableOptions)).id,
      traits,
    );
  };

  return pipe(mapObjIndexed(toOptionId), values, flatten)(configuration);
};

export const getOptionsAsConfigured = createSelector<
  RootState,
  { id: ID },
  Entities<Product & WithCount>,
  Entities<ProductOption>,
  BundleConfiguration | undefined,
  ID[]
>(
  getProductsWithCountFromBundle,
  getProductOptions,
  getBundleConfigurationById,
  (products, allProductOptions, configuration = {}) => {
    const configurableProductIds = Object.keys(configuration);

    const nonConfigurableProductIds = difference(
      Object.keys(products),
      configurableProductIds,
    );

    const nonConfigurableProducts = pick(nonConfigurableProductIds, products);
    const configurableProducts = pick(configurableProductIds, products);

    const nonConfigurableOptions = toCheapestOptions(
      nonConfigurableProducts,
      allProductOptions,
    );

    const configurableOptions = toConfigurableOptions(
      configuration,
      configurableProducts,
      allProductOptions,
    );

    return [...nonConfigurableOptions, ...configurableOptions];
  },
);

export const getOptionAsConfigured = createSelector(
  getProductOptions,
  getProductConfigurationById,
  (allProductOptions, configuration = { count: 1, traits: [] }) => {
    return find((option: ProductOption) => equals(option.traits, configuration.traits))(
      values(allProductOptions),
    );
  },
);

export const strictGetOptionAsConfigured = createSelector(
  getProductOptions,
  getProductById,
  getProductConfigurationById,
  (allProductOptions, product, configuration) => {
    if (!configuration || !product) {
      return undefined;
    }

    const productOptions = values(allProductOptions).filter(
      option => product.options.indexOf(option.id) >= 0,
    );

    return find((option: ProductOption) => equals(option.traits, configuration.traits))(
      productOptions,
    );
  },
);

export const getAreWarrantiesUpdating = createSelector<
  RootState & WarrantyState,
  Partial<Record<string, UIState>>,
  Warranty[],
  boolean
>(
  (state: RootState) => getShop(state).ui,
  getAllWarranties,
  (ui, allWarranties: Warranty[]) =>
    anyOf(pipe(prop('id'), (id: string) => ui[id] || { status: Status.Init }, isLoading))(
      allWarranties,
    ),
);

export type ShopRouteMatch = match<{ bundleType: string }>;

const matchShopRouteUs = createMatchSelector('/shop/:bundleType');
const matchShopRouteIntl = createMatchSelector('/:locale/shop/:bundleType');

export const matchShopRoute = createSelector(
  matchShopRouteUs,
  matchShopRouteIntl,
  (matchUs, matchIntl) => matchUs || matchIntl,
);

const matchShopAccessoriesRouteUs = createMatchSelector(
  `/shop/${BundleType.Accessories}`,
);
const matchShopAccessoriesRouteIntl = createMatchSelector(
  `/:locale/shop/${BundleType.Accessories}`,
);
export const matchShopAccessoriesRoute = createSelector(
  matchShopAccessoriesRouteUs,
  matchShopAccessoriesRouteIntl,
  (matchUs, matchIntl) => matchUs || matchIntl,
);

const matchShopDeviceRouteUs = createMatchSelector(
  `/shop/:bundleType(${BundleType.Bike}|${BundleType.Tread})`,
);

const matchShopDeviceRouteIntl = createMatchSelector(
  `/:locale/shop/:bundleType(${BundleType.Bike}|${BundleType.Tread})`,
);

export const matchShopDeviceRoute = createSelector(
  matchShopDeviceRouteUs,
  matchShopDeviceRouteIntl,
  (matchUs, matchIntl) => matchUs || matchIntl,
);

const configureRouteOptions = [
  BundleType.Bike,
  BundleType.Tread,
  BundleType.BikePlus,
  BundleType.TreadPlus,
].join('|');

const matchConfigureRouteUs = createMatchSelector(
  `/shop/:bundleType(${configureRouteOptions})/:slug`,
);
const matchConfigureRouteIntl = createMatchSelector(
  `/:locale/shop/:bundleType(${configureRouteOptions})/:slug`,
);

export const matchConfigureRoute = createSelector(
  matchConfigureRouteUs,
  matchConfigureRouteIntl,
  (matchUs, matchIntl) => matchUs || matchIntl,
);

const configureRefurbRouteOptions = [BundleType.Bike, BundleType.BikePlus].join('|');
const matchRefurbConfigureRouteUs = createMatchSelector(
  `/shop/refurbished/:bundleType(${configureRefurbRouteOptions})`,
);
const matchRefurbConfigureRouteIntl = createMatchSelector(
  `/:locale/shop/refurbished/:bundleType(${configureRefurbRouteOptions})`,
);
export const matchRefurbConfigureRoute = createSelector(
  matchRefurbConfigureRouteUs,
  matchRefurbConfigureRouteIntl,
  (matchUs, matchIntl) => matchUs || matchIntl,
);

export const getUIException = createSelector([getUIState], uiState =>
  values(uiState).reduce(
    (acc: Exception | undefined, curr) => getException(curr) ?? acc,
    undefined,
  ),
);
