import { combineReducers } from 'redux';
import type { State as DataState } from './data';
import data, {
  getInstructor,
  getInstructors,
  getInstructorByUsername,
  getInstructorIdsByFilter,
  getInstructorIdsByDiscipline,
  getInstructorsUserNames,
} from './data';
import type { State as FetcherState } from './fetcher';
import fetcher from './fetcher';

export type State = {
  data: DataState;
  fetcher: FetcherState;
};

export {
  getInstructor,
  getInstructors,
  getInstructorByUsername,
  getInstructorIdsByFilter,
  getInstructorIdsByDiscipline,
  getInstructorsUserNames,
};

export default combineReducers({
  data,
  fetcher,
});
