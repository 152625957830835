import React from 'react';
import { Markdown } from '@peloton/copy';
import { useLegalTextData } from '@ecomm/copy';
import {
  Divider,
  StyledCopyDiv,
  StyledLegalCopy,
  StyledWrapper,
} from './LegalSectionStyles';

const ProductLegalSection: React.FC<React.PropsWithChildren<unknown>> = () => {
  const legalTextId = 'partner.verizon.legalText';
  const legalText = useLegalTextData(legalTextId);

  return (
    <StyledWrapper data-test-id={legalTextId}>
      <StyledLegalCopy>
        <StyledCopyDiv>
          <Markdown content={legalText} />
        </StyledCopyDiv>
        <Divider />
      </StyledLegalCopy>
    </StyledWrapper>
  );
};
export default ProductLegalSection;
