import { Eyebrow, Icon, brand, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles/breakpoints';
import { Link } from '@ecomm/internationalize-ui';
import type { CtaWithUrlSetData, CtaWithUrlData } from './models';

type CtaWithDriftInteractionData = {
  key: string;
  text: string;
  interactionId: string;
};

type CtaData = CtaWithUrlData | CtaWithDriftInteractionData;

type CtaWithUrlSet = Omit<CtaWithUrlSetData, 'ctas'> & {
  ctas: CtaData[];
};

export type Props = CtaWithUrlSet & {
  onChatRequest?: () => void;
};

const ContactSection: React.FC<React.PropsWithChildren<Props>> = ({
  ctas,
  onChatRequest,
}) => {
  const iconMap = {
    ['footer.contact.phone']: 'phone',
    ['footer.contact.driftChat']: 'chat',
    ['footer.contact.support']: 'help',
  };

  return (
    <List>
      {ctas.map((cta, index) => {
        if ('interactionId' in cta && onChatRequest) {
          return (
            <li key="chat">
              <ContactLink
                is="button"
                onClick={() => onChatRequest()}
                variant="body"
                size="small"
              >
                {cta.key && <Icon height={16} width={18} name={iconMap[cta.key]} />}
                <Eyebrow size="medium">{cta.text}</Eyebrow>
              </ContactLink>
            </li>
          );
        }
        if ('url' in cta) {
          return (
            <li key={`${cta.key}-${index}`}>
              <ContactLink href={cta.url} target="_blank" variant="body" size="small">
                {cta.key && <Icon height={16} width={18} name={iconMap[cta.key]} />}
                <Eyebrow size="medium">{cta.text}</Eyebrow>
              </ContactLink>
            </li>
          );
        }
        return null;
      })}
    </List>
  );
};

export default ContactSection;

const List = styled.ul`
  display: grid;
  gap: ${spacing[12]};
  ${media.desktopLarge`
    margin-right: 0;
  `}
`;

const ContactLink = styled(Link)`
  text-transform: inherit;

  &:hover svg path,
  &:hover {
    fill: ${brand.primary};
    color: ${brand.primary};
  }

  &::after {
    display: none;
  }

  > span {
    display: flex;
    align-items: center;
    gap: ${spacing[8]};
  }
`;
