import cuid from 'cuid';
import React from 'react';
import styled from 'styled-components';
import type { InputComponent, InputElements, InputProps, StyledCss } from './types';

export type Props = InputProps & {
  customErrorMessage?: string;
  overrideErrorMessage?: React.ReactNode;
  onErrorShown?: () => void;
};

type State = {
  errorMessage?: React.ReactNode;
  hasError: boolean;
  showError: boolean;
};

const DefaultErrorMessage = styled.div<{ errorMessageStyles: StyledCss<any> }>`
  ${p => p.errorMessageStyles}
`;

type ErrorMessageProps = {
  errorMessageStyles: StyledCss<ErrorMessageProps>;
  CustomErrorComponent?: React.FC<React.PropsWithChildren<{ role: string }>>;
};

const ErrorMessage: React.FC<React.PropsWithChildren<ErrorMessageProps>> = ({
  CustomErrorComponent,
  errorMessageStyles,
  children,
}) =>
  CustomErrorComponent ? (
    <CustomErrorComponent role="alert" aria-live="polite">
      {children}
    </CustomErrorComponent>
  ) : (
    <DefaultErrorMessage
      role="alert"
      aria-live="polite"
      errorMessageStyles={errorMessageStyles}
    >
      {children}
    </DefaultErrorMessage>
  );

const toInputWithError = (
  Input: InputComponent<InputProps>,
  errorMessageStyles: StyledCss<ErrorMessageProps>,
  CustomErrorComponent?: React.FC<React.PropsWithChildren<unknown>>,
) => {
  return class InputWithError extends React.Component<Props, State> {
    static displayName = 'InputWithError';

    public state = {
      errorMessage: undefined,
      hasError: false,
      showError: false,
    };

    private _input: InputElements;
    private uniqueId = cuid();

    public componentDidMount() {
      const { pattern, customErrorMessage } = this.props;
      if (pattern && !Boolean(customErrorMessage)) {
        throw Error('Must provide prop `customErrorMessage` if using `pattern`');
      }
      this.updateShowErrorState();
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
      this.updateShowErrorState();

      if (this.state.showError && !prevState.showError) {
        // fire the errorShown event if the error went from hidden to shown
        this.fireErrorShown();
      }
    }

    public render() {
      const {
        className,
        customErrorMessage,
        overrideErrorMessage,
        touched = false,
        onErrorShown,
        ...props
      } = this.props;
      const errorId = this.uniqueId;
      return (
        <div className={className}>
          <Input
            {...props}
            data-has-error={this.state.showError}
            getRef={this.handleRef}
            onInvalid={this.handleOnInvalid}
            aria-describedby={this.state.showError ? errorId : undefined}
          />
          {this.state.showError && (
            <ErrorMessage
              CustomErrorComponent={CustomErrorComponent}
              errorMessageStyles={errorMessageStyles}
            >
              <span id={errorId}>{this.state.errorMessage}</span>
            </ErrorMessage>
          )}
        </div>
      );
    }

    private fireErrorShown = () => {
      if (this.props.onErrorShown) {
        this.props.onErrorShown();
      }
    };

    private handleRef = (ref: InputElements | null) => {
      if (ref !== null) {
        this._input = ref;
      }
    };

    private handleOnInvalid = (e: React.SyntheticEvent<HTMLElement>) => {
      e.preventDefault();
      this.setState({ hasError: true });
    };

    private updateShowErrorState = () => {
      const {
        customErrorMessage,
        overrideErrorMessage,
        required,
        touched,
        value,
      } = this.props;

      const errorMessage = this.getErrorMessage({
        hasValue: Boolean(value),
        isRequired: Boolean(required),
        customErrorMessage: customErrorMessage ? customErrorMessage : '',
        validationMessage: this._input ? this._input.validationMessage : '',
        overrideErrorMessage: overrideErrorMessage,
      });

      const showError =
        (touched || this.state.hasError || Boolean(overrideErrorMessage)) &&
        Boolean(errorMessage);

      if (
        showError !== this.state.showError &&
        errorMessage !== this.state.errorMessage
      ) {
        this.setState({
          errorMessage,
          showError,
        });
      }
    };

    private getErrorMessage = ({
      hasValue,
      isRequired,
      customErrorMessage,
      validationMessage,
      overrideErrorMessage,
    }: {
      hasValue: boolean;
      isRequired: boolean;
      customErrorMessage: string;
      validationMessage: string;
      overrideErrorMessage?: React.ReactNode;
    }) => {
      if (Boolean(overrideErrorMessage)) {
        return overrideErrorMessage;
      }
      if (isRequired && !hasValue && !customErrorMessage) {
        return `${this.props.label} is required.`;
      } else if (validationMessage) {
        return typeof customErrorMessage !== 'undefined'
          ? customErrorMessage
          : validationMessage;
      } else {
        return '';
      }
    };
  };
};

export default toInputWithError;
