import type { Partners } from '@ecomm/financing/models/Partners';
import type { PossibleShowroomState } from '@ecomm/showrooms/model';

export type Programs = {
  financingPartner: Partners;
  secondaryFinancingPartner: Partners;
  showroom: PossibleShowroomState;
  virtualShowroom: boolean;
  homeTrial: boolean;
  valueCalculator: boolean;
  apparel: boolean;
  tradeIn: boolean;
};
export const programArray: (keyof Programs)[] = [
  'financingPartner',
  'secondaryFinancingPartner',
  'showroom',
  'virtualShowroom',
  'homeTrial',
  'valueCalculator',
  'apparel',
  'tradeIn',
];
