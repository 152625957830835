import { min } from 'ramda';
import { isIE11 } from '@peloton/browser/identify';
import { ScrollDirection } from '@ecomm/hooks/useScrollPosition';
import type { OnClickProps } from './models';
import { Menu } from './models';

export const onMenuClick = ({
  currentMenu,
  menuItem,
  setCurrentMenu,
  setFocusedMenu,
}: OnClickProps) => () => {
  const menu = currentMenu === menuItem ? Menu.None : menuItem;
  setCurrentMenu(menu);
  setFocusedMenu(menu);
};

export const isActiveMenu = (activeMenu: Menu, menuItems: Menu[]) => {
  return menuItems.includes(activeMenu);
};

export const isCurrentMenu = (currentMenu: Menu, menuItems: Menu[]) => {
  return menuItems.includes(currentMenu);
};

export const scrollRatio = (scrollPosition: number = 0) =>
  typeof window !== 'undefined' ? scrollPosition / window.innerHeight : 0;

export const gradientFromScrollPosition = (scrollPosition: number) => {
  const alpha = scrollRatio(scrollPosition);
  const fromColor = `rgba(24, 26, 29, ${min(alpha, 1)})`;
  const toColor = `rgba(24, 26, 29, ${min(alpha + 0.3, 1)})`;
  return `linear-gradient(to top, ${fromColor}, ${toColor})`;
};

export const mainNavTop = (
  scrollDirection: ScrollDirection,
  pixelsSinceChange: number,
  headerHeight: number,
) =>
  isIE11()
    ? 0
    : scrollDirection === ScrollDirection.Up
    ? Math.min(0, -headerHeight + pixelsSinceChange)
    : Math.max(-headerHeight, -pixelsSinceChange);

export const headbandTop = (
  scrollDirection: ScrollDirection,
  pixelsSinceChange: number,
  headerHeight: number,
) =>
  isIE11()
    ? 0
    : scrollDirection === ScrollDirection.Up
    ? Math.min(headerHeight, pixelsSinceChange)
    : Math.max(0, headerHeight - pixelsSinceChange);
