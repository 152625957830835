import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { usePromotionalTextData } from '@ecomm/copy';
import { TogglePause, TogglePlay } from '@ecomm/icons';

type Theme = 'light' | 'dark';
type Bearing = 'left' | 'right';

type Props = {
  theme?: Theme;
  bearing: Bearing;
  isPlaying: boolean;
  onClick?: VoidFunction;
};

const VideoToggle: React.FC<React.PropsWithChildren<Props>> = ({
  theme,
  bearing,
  isPlaying,
  onClick,
}) => {
  const pauseLabel = usePromotionalTextData('playPauseToggleDefaultPauseLabel');
  const playLabel = usePromotionalTextData('playPauseToggleDefaultPlayLabel');

  return (
    <PlayPauseButton bearing={bearing} onClick={onClick}>
      {isPlaying ? (
        <TogglePause label={pauseLabel} theme={theme} />
      ) : (
        <TogglePlay label={playLabel} theme={theme} />
      )}
    </PlayPauseButton>
  );
};

export default VideoToggle;

export const PlayPauseButton = styled.button<{ bearing: Bearing }>`
  position: absolute;
  bottom: 0;
  ${props => (props.bearing === 'left' ? 'left: 0' : 'right: 0')};
  z-index: 3;
  padding: 16px;
  ${media.desktopLarge`
    padding: 24px;
  `}

  svg {
    width: 26px;

    ${media.desktopLarge`
      width: 34px;
    `}
  }

  transition: opacity 250ms ease;
  &:hover {
    opacity: 0.6;
  }
`;
