import { useSelector } from 'react-redux';
import {
  CP_TRIAL_SKU,
  CP_MTM_SKU,
  STUDENT_MTM_SKU,
} from '@peloton/subscription-plans/skus';
import type { Product } from '@ecomm/copy';
import type { ReducerState as ToggleState, ToggleId } from '@ecomm/feature-toggle';
import { getIsToggleActive } from '@ecomm/feature-toggle';
import { getPromoToggles } from './promoToggles';

export const getActiveDigitalPromotion = (state: ToggleState) => {
  const promoToggles = getPromoToggles();
  const activeToggle = Object.keys(promoToggles).find(toggle =>
    getIsToggleActive(toggle as ToggleId)(state),
  );
  return activeToggle && promoToggles[activeToggle];
};

export const useActiveDigitalPromotion = () => {
  const promoToggles = getPromoToggles();
  return Object.entries(promoToggles)
    .map(([key, value]) => {
      // looping through a constant will result in consistent behavior. will necessitate a change if we start looping through a dynamically sourced object
      // eslint-disable-next-line react-hooks/rules-of-hooks
      if (useSelector(getIsToggleActive(key as ToggleId))) {
        return value as Product;
      }
      return undefined;
    })
    .find(Boolean);
};

type Algorithm = 'postFix' | 'preFix';

const transFormKey = <T>(baseKey: T, sku: string, algorithm?: Algorithm): string => {
  switch (algorithm) {
    case 'preFix':
      return `${sku}-${baseKey}`;
    default:
      return `${baseKey}-${sku}`;
  }
};
export const getKeyFromDigitalSku = <T>(
  baseKey: T,
  sku?: Product,
  algorithm?: Algorithm,
): T => {
  switch (sku) {
    case 'digital-30d':
    case 'pl-a-sub-02-30d-ft':
    case CP_TRIAL_SKU:
    case CP_MTM_SKU:
    case STUDENT_MTM_SKU:
    case undefined:
    case null:
      return baseKey;
    default:
      return (transFormKey(baseKey, sku, algorithm) as unknown) as T;
  }
};
export const useKeyFromDigitalContext = <T>(baseKey: T, algorithm?: Algorithm): T => {
  const sku = useActiveDigitalPromotion();
  return getKeyFromDigitalSku(baseKey, sku, algorithm);
};

export const getDisabledSkus = (state?: ToggleState) => {
  const disabledSlugs: Array<Product> = [];
  // If we ever have a slug we want to disable / enabled via FF or date trigger
  // Add it to this array.
  return disabledSlugs;
};
