import type { CreditCard, Address, Token, PaymentMethod } from '@ecomm/models';
import { isCreditCardComplete, isAddressComplete } from '@ecomm/models';

export type CheckoutBillingInfo = Address &
  CreditCard & { token: Token } & { method: PaymentMethod };

export const isCheckoutBillingInfoComplete = ({
  token,
  method,
  ...info
}: CheckoutBillingInfo): boolean =>
  Object.keys(info).length > 0 &&
  isAddressComplete(info) &&
  Boolean(token) &&
  (token ? true : isCreditCardComplete(info)) &&
  Boolean(method);
