import { call, takeEvery } from 'redux-saga/effects';
import type {
  ScrollToElementAction,
  ScrollAction,
  ScrollToAndFocusElementAction,
} from './redux';
import { ActionType } from './redux';

const windowScroll = (payload: ScrollToOptions) => {
  window.scrollTo(payload);
};

const scrollSaga = function* (action: ScrollAction) {
  yield call(windowScroll, action.payload);
};

const scrollToElementSaga = function* ({
  payload: { selector, options },
}: ScrollToElementAction) {
  const el: HTMLElement = yield call([document, 'querySelector'], selector);
  if (el) {
    yield call([el, 'scrollIntoView'], options);
  }
};

const scrollToAndFocusElementSaga = function* ({
  payload: { selector, options },
}: ScrollToAndFocusElementAction) {
  const el: HTMLElement = yield call([document, 'querySelector'], selector);
  if (el) {
    yield call([el, 'scrollIntoView'], options);
    yield call([el, 'focus'], { preventScroll: true });
  }
};

export default function* sagas() {
  yield takeEvery(ActionType.Scroll, scrollSaga);
  yield takeEvery(ActionType.ScrollToElement, scrollToElementSaga);
  yield takeEvery(ActionType.ScrollToAndFocusElement, scrollToAndFocusElementSaga);
}
