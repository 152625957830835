import { SubmissionError } from 'redux-form';
import { call, put, take, takeEvery, race } from 'redux-saga/effects';
import { logIn, UserReducerActionType } from '@peloton/auth';
import { isFormValid } from './models';
import type { SubmitLoginFormAction } from './redux';
import { ActionType } from './redux';

export const INVALID_FORM_MESSAGE = 'errors.invalidLogin';
export const GENERIC_ERROR_MESSAGE = 'errors.genericNetwork';

export const submitLoginFormSaga = function* (action: SubmitLoginFormAction) {
  const form = action.payload;
  if (isFormValid(form)) {
    try {
      yield put(logIn(form));

      const { success } = yield race({
        success: take(UserReducerActionType.LOGIN_SUCCESS),
        failure: take(UserReducerActionType.LOGIN_FAILURE),
      });

      if (success) {
        yield call(action.onSuccess);
      } else {
        yield callOnError(action, INVALID_FORM_MESSAGE);
      }
    } catch (_) {
      yield callOnError(action, GENERIC_ERROR_MESSAGE);
    }
  } else {
    yield callOnError(action, INVALID_FORM_MESSAGE);
  }
};

const callOnError = (action: SubmitLoginFormAction, msg: string) =>
  call(action.onError, new SubmissionError({ _error: msg }));

export const loginFormSaga = function* () {
  yield takeEvery(ActionType.SubmitForm, submitLoginFormSaga);
};
