import { pick } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeData, pipeError, throwError } from '@peloton/api';
import type { Partners } from '@ecomm/financing';
import type { ErrorCode } from '../models';
import { toErrorCode } from '../models';

const PAYMENT_TOKEN_ENDPOINT = '/ecomm/payments/token';

const toPaymentTokenData = pick(['id', 'clientToken']) as () => ClientTokenResponse;

type ClientTokenResponse = {
  clientToken: string;
};

export const createPaymentToken = (
  api: Client,
  billingPartner: Partners,
  // this is always true
  klarnaDirectIntegration: boolean,
  paymentAmountInCents?: number,
) =>
  api
    .post(PAYMENT_TOKEN_ENDPOINT, {
      billingPartner,
      klarnaDirectIntegration,
      paymentAmountInCents,
    })
    .then(pipeData(toPaymentTokenData))
    .catch(pipeError(throwError<ErrorCode>(toErrorCode)));
