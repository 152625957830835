import type { MixedSchema, StringSchema } from 'yup';

export type Validator = MixedSchema | StringSchema;

export type Validations<T> = Record<keyof T, Validator>;

export enum ValidationErrors {
  Invalid = 'invalid',
  Missing = 'missing',
  PasswordTooShort = 'passwordTooShort',
  PasswordsDontMatch = 'passwordsDontMatch',
  UserExists = 'userExists',
}
