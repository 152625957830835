import { useIsToggleActive } from '@ecomm/feature-toggle';

export const TOGGLE_ID = 'projectPhoenix';
export const KEY_PREFIX = 'projectPhoenix.';

export const useProjectPhoenixCopyKey = <KeyType extends string>(
  baseKey: KeyType,
): KeyType => {
  const isToggleActive = useIsToggleActive()(TOGGLE_ID);

  if (isToggleActive) {
    return `${KEY_PREFIX}${baseKey}` as KeyType;
  }

  return baseKey;
};
