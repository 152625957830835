import type { Effect } from '@redux-saga/types';
import type { SagaIterator } from 'redux-saga';
import { call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import { getScreenPropsSaga, toClientDetails } from '@peloton/analytics';
import { CLIENT_CONTEXT } from '@peloton/api';
import { createConfigurable } from '@peloton/config-singleton';
import {
  createBookmarkForClass,
  deleteBookmarkForClass,
} from '@onewellness/api/bookmarkForClass';
import { isClassBookmarked } from '@onewellness/redux';
import type { ToggleClassBookmarkRequestAction } from '@onewellness/redux/userProfile';
import {
  ToggleClassBookmarkActionTypes,
  toggleClassBookmarkFailed,
  toggleClassBookmarkSucceeded,
} from '@onewellness/redux/userProfile';

type Config = {
  getAnalyticsPropsForBookmark: (
    knownAnalyticsProps: Record<string, any>,
  ) => IterableIterator<Effect | Record<string, any>>;
};

export const { config, updateConfig } = createConfigurable<Config>({
  getAnalyticsPropsForBookmark: function* (props: Record<string, any>) {
    return props;
  },
});

export const toggleBookmarkClassSaga = function* (
  action: ToggleClassBookmarkRequestAction,
): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  const isCurrentlyBookmarked = yield select(state =>
    isClassBookmarked(state, action.payload.classId),
  );

  try {
    const screenProps = yield call(getScreenPropsSaga);
    const props = yield call(config.getAnalyticsPropsForBookmark, screenProps);
    const analyticsProps = toClientDetails(props, 'On-Demand');
    isCurrentlyBookmarked
      ? yield call(deleteBookmarkForClass, client, action.payload.classId, analyticsProps)
      : yield call(
          createBookmarkForClass,
          client,
          action.payload.classId,
          analyticsProps,
        );

    yield put(
      toggleClassBookmarkSucceeded(action.payload.classId, !isCurrentlyBookmarked),
    );
  } catch (e) {
    yield put(toggleClassBookmarkFailed(action.payload.classId, e));
  }
};

const watcher = function* () {
  yield takeEvery(
    ToggleClassBookmarkActionTypes.ToggleClassBookmarkRequested,
    toggleBookmarkClassSaga,
  );
};

export default watcher;
