import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { toLocale } from '@peloton/internationalize';
import { productToBundleType, Product } from '@ecomm/product-states';
import type { BundleType } from '@ecomm/shop/models';
import { getDeviceMembershipPrice } from '@ecomm/subscription-plans';
import type { ReducerState } from './prices';
import toDefaultState from './toDefaultState';

const getConfig = (state: ReducerState) => state.config;

export const getTotalPrices = createSelector([getConfig], config => config.totalPrices);

export const getBasePriceForBundleType = (bundleType: BundleType) =>
  createSelector([getTotalPrices], totalPrices => {
    return totalPrices[bundleType];
  });

export const useProductPrice = (product: Product, locale = toLocale()): number => {
  const subscriptionPrice = useSelector(getDeviceMembershipPrice);

  if (product === Product.DigitalApp) {
    return subscriptionPrice;
  }

  const bundle = productToBundleType(product);
  const totalPrices = toDefaultState(locale).totalPrices;

  return totalPrices[bundle];
};
