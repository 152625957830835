import { push } from 'connected-react-router';
import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import { getLocation } from '@peloton/redux';
import { createCartItems } from '@ecomm/cart/api';
import { loadCartSuccess } from '@ecomm/cart/redux';
import type { ID } from '@ecomm/models';
import { ProductLine } from '../models';
import {
  areProductsConfigurable,
  getProductOptionsByProductLine,
  getBundleById,
  getCheapestOptions,
  getIsBundleConfigured,
  getOptionsAsConfigured,
  startTransaction,
  failTransaction,
} from '../redux';
import type { AddToCartRequestedAction } from '../redux/shopper';
import { addToCartSuccess, ActionType } from '../redux/shopper';
import toShopUrl from '../toShopUrl';

export const addToCart = function* (
  useLightCart: boolean,
  id: ID,
  productOptions: ID[],
  tradeIn: boolean,
): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  const subscriptionProductOption = yield select(getProductOptionsByProductLine, {
    productLine: ProductLine.DeviceSubscription,
  });
  const cart = yield call(
    createCartItems(useLightCart),
    client,
    id,
    productOptions,
    tradeIn,
    subscriptionProductOption[0].id,
  );

  return cart;
};

export const addPackageToCartSaga = function* (
  id: ID,
  productOptions: ID[],
  tradeIn: boolean,
  shopUrl: string,
): SagaIterator {
  yield put(startTransaction(id));

  try {
    const cart = yield call(addToCart, true, id, productOptions, tradeIn);

    yield all([
      put(loadCartSuccess(cart)),
      put(addToCartSuccess(id)),
      put(push(shopUrl)),
    ]);
  } catch (err) {
    const error = new DomainError(
      'Error adding package to cart in addPackageToCartSaga',
      err,
    );
    yield put(reportError({ error }));
    yield put(failTransaction(id));
  }
};

export const configurePackageSaga = function* (
  action: AddToCartRequestedAction,
): SagaIterator {
  const { id, tradeIn } = action.payload;
  const { products } = yield select(getBundleById, { id }) || {};
  const { isConfigured, location, needsConfiguration } = yield all({
    isConfigured: select(getIsBundleConfigured, { id }),
    location: select(getLocation),
    needsConfiguration: select(areProductsConfigurable, { products }),
  });

  const { pathname } = location;
  const shopUrl = toShopUrl(pathname);

  if (!isConfigured) {
    // NOTE: Should we need to display more nuanced errors to the user,
    // this is the place to do so.
  } else if (!needsConfiguration) {
    const cheapestProductOptions = yield select(getCheapestOptions, { products });
    yield call(addPackageToCartSaga, id, cheapestProductOptions, tradeIn, shopUrl);
  } else {
    const productOptions = yield select(getOptionsAsConfigured, { id });
    yield call(addPackageToCartSaga, id, productOptions, tradeIn, shopUrl);
  }
};

const watcherSaga = function* () {
  yield takeEvery(ActionType.AddToCartRequested, configurePackageSaga);
};

export default watcherSaga;
