import {
  Body,
  brand,
  LinkButton,
  EyebrowHeadlineBody,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Markdown } from '@peloton/copy';
import type { CtaWithUrl, ValuePropData } from '@ecomm/copy';
import { useCtaWithUrlData } from '@ecomm/copy';

interface ErrorProps {
  body: ValuePropData['body'];
  headline: ValuePropData['title'];
}

export const ErrorDisplay: React.FC<React.PropsWithChildren<ErrorProps>> = ({
  children,
  headline,
  body,
}) => {
  return (
    <Container>
      <EyebrowHeadlineBody eyebrowText={headline} size="large">
        <StyledBody>
          <Markdown content={body ?? ''} />
        </StyledBody>
      </EyebrowHeadlineBody>
      {children}
    </Container>
  );
};

interface ErrorDisplayButtonProps {
  ctaId: CtaWithUrl;
  href: string;
}

export const ErrorDisplayButton: React.FC<
  React.PropsWithChildren<ErrorDisplayButtonProps>
> = ({ ctaId, href }) => {
  const buttonProps = useCtaWithUrlData(ctaId);
  return (
    <LinkButton
      color="primary"
      href={href}
      variant="solid"
      width="adaptive"
      {...buttonProps}
    />
  );
};

const Container = styled.div`
  background: ${brand.lightest};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  max-width: 500px;
  padding: 48px;
  padding-bottom: 40px;
`;

const StyledBody = styled(Body)`
  white-space: pre-line;
`;
