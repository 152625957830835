import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { FAAS_BUYOUT_CHECKOUT } from '@ecomm/checkout/routes';
import { Route } from '@ecomm/router';

const Routes = [
  <Route
    key="faas-buyout-checkout-page"
    path={FAAS_BUYOUT_CHECKOUT}
    exact
    component={asyncComponent(
      () =>
        import(
          './FaasBuyoutCheckoutPage' /* webpackChunkName:  "FaasBuyoutCheckoutPage" */
        ),
    )}
  />,
  <Route
    key="buyout"
    path={'/buyout'}
    exact
    pageBuilderRoute
    component={asyncComponent(() => import('./FaasBuyoutPage'))}
  />,
];

export default Routes;
