import type { LocationChangeAction } from 'connected-react-router';
import { LOCATION_CHANGE } from 'connected-react-router';
import type { SagaIterator } from 'redux-saga';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { fetcherSaga, PlanType } from '@peloton/subscription-plans';
import { isLoaded } from '@ecomm/models';
import { failPlansFetch, requestedPlansFetch, succeedPlansFetch } from './redux';
import { matchConcessionaryPricing } from './routes';
import { getUIState } from './selectors';

export const checkStatusSaga = function* (): SagaIterator {
  const status = yield select(getUIState);
  const loaded = yield call(isLoaded, status);

  if (!loaded) {
    yield put(requestedPlansFetch());
    yield call(fetcherSaga, PlanType.Digital, undefined, {
      onSuccess: succeedPlansFetch,
      onError: failPlansFetch,
    });
  }
};

export const checkRouteSaga = function* (_: LocationChangeAction): SagaIterator {
  const match = yield select(matchConcessionaryPricing);

  if (match) {
    yield call(checkStatusSaga);
  }
};

const watcherSaga = function* () {
  yield takeEvery(LOCATION_CHANGE, checkRouteSaga);
};

export default function* () {
  yield fork(watcherSaga);
}
