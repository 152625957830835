import { useEffect } from 'react';
import { useErrorReporter } from '@peloton/error-reporting';
import { NotFoundPageError } from '@ecomm/layout/PageError';

const useReportNotFoundPageError = () => {
  const {
    errorReporter: { reportError },
  } = useErrorReporter();

  useEffect(() => {
    reportError(new NotFoundPageError());
  }, [reportError]);
};

export default useReportNotFoundPageError;
