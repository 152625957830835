import { connect } from 'react-redux';
import type { ExtLinkEnvState } from '@peloton/external-links';
import { getExtLinkEnv, toHref } from '@peloton/external-links';
import { Links } from '@peloton/links';
import type { ReducerState as ToggleState } from '@ecomm/feature-toggle';
import { getIsToggleActive } from '@ecomm/feature-toggle';
import type { PrivacyBannerState } from './redux';
import { accept, getHasDismissed } from './redux';
import type { DispatchProps, StateProps } from './View';
import View from './View';

const mapStateToProps = (
  state: PrivacyBannerState & ExtLinkEnvState & ToggleState,
): StateProps => ({
  privacyPolicyLink: toHref(Links.privacy, getExtLinkEnv(state)),
  showPrivacyBanner:
    !getHasDismissed(state) && getIsToggleActive('showPrivacyBanner')(state),
});

const mapDispatchToProps: DispatchProps = {
  onAccept: accept,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
