import { useLocale } from '@peloton/next/hooks/useLocale';
import { useCtPrice } from '@peloton/next/services/commercetools/fetchCtPricingData';
import useDefaultState from '@ecomm/config/next/useDefaultState';
import type { BundleType } from '@ecomm/shop/models/BundleType';

const useBundlePrice = (bundleType: BundleType, ctEnabled: boolean = false): number => {
  const locale = useLocale();
  const { totalPrices } = useDefaultState(locale);
  const { price: ctPrice } = useCtPrice(bundleType);
  return ctEnabled && ctPrice ? ctPrice : totalPrices[bundleType];
};

export default useBundlePrice;
