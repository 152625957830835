import {
  accessibility,
  bike,
  bikeAccessories,
  bikePlus,
  bikes,
  instructors,
  instructorsBike,
  instructorsTread,
  membershipTerms,
  privacy,
  row,
  rowSpecs,
  rowWhy,
  shopRow,
  shopBike,
  shopBikePlus,
  shopGuide,
  tos,
  tread,
  treadAccessories,
  treadSpecs,
  digitalCheckout30Day,
  app,
  membership,
  cfuBikeSignup,
  cfuBikesSignup,
  cfuBikePlusSignup,
} from '@peloton/links/www';
import { bikesClasses, bikesCompare } from '@peloton/links/wwwPrivate';
import { treadsSpecs } from '@peloton/links/wwwPrivateTread';
import type { AvailabilityState } from './models';
import {
  Product,
  ProductStates,
  isAvailableForMarketingPages,
  isAvailableForShopPages,
} from './models';

type GetProductAvailability = (y: Product) => AvailabilityState;

const anyAvailableProduct = (getProductAvailability: GetProductAvailability): boolean =>
  Object.values(Product)
    .map(getProductAvailability)
    .map(isAvailableForMarketingPages)
    .reduce((x, y) => x || y, false);

type ProductAvailabilityPredicate = (x: GetProductAvailability) => boolean;

type ProductStateUrlMap = Record<string, ProductAvailabilityPredicate>;

const atLeastOneBikeLeadCapture: ProductAvailabilityPredicate = getProductAvailability =>
  getProductAvailability(Product.Bike).state ===
    ProductStates.UnavailableMarketingLeadCapture ||
  getProductAvailability(Product.BikePlus).state ===
    ProductStates.UnavailableMarketingLeadCapture;

const productStateUrlMap: ProductStateUrlMap = {
  [cfuBikeSignup.path]: getProductAvailability => {
    return (
      getProductAvailability(Product.Bike).state ===
      ProductStates.UnavailableMarketingLeadCapture
    );
  },
  [cfuBikePlusSignup.path]: atLeastOneBikeLeadCapture,
  [cfuBikesSignup.path]: atLeastOneBikeLeadCapture,
  [bikes.path]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.Bike)) &&
    isAvailableForMarketingPages(getProductAvailability(Product.BikePlus)),

  [bikesCompare.path]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.Bike)) &&
    isAvailableForMarketingPages(getProductAvailability(Product.BikePlus)),

  [bikesClasses.path]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.Bike)) ||
    isAvailableForMarketingPages(getProductAvailability(Product.BikePlus)) ||
    isAvailableForMarketingPages(getProductAvailability(Product.DigitalApp)),

  [bike.path]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.Bike)),

  [shopBike.path]: getProductAvailability =>
    isAvailableForShopPages(getProductAvailability(Product.Bike)),

  [bikePlus.path]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.BikePlus)),

  [shopBikePlus.path]: getProductAvailability =>
    isAvailableForShopPages(getProductAvailability(Product.BikePlus)),

  [bikeAccessories.path]: getProductAvailability =>
    isAvailableForShopPages(getProductAvailability(Product.Bike)),

  [tread.path]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.Tread)),

  [treadAccessories.path]: getProductAvailability =>
    isAvailableForShopPages(getProductAvailability(Product.Tread)),

  [treadSpecs.path]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.Tread)),

  [treadsSpecs.path]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.Tread)) &&
    isAvailableForMarketingPages(getProductAvailability(Product.TreadPlus)),

  [instructors.path]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.DigitalApp)),

  [instructorsBike.path]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.DigitalApp)) &&
    (isAvailableForMarketingPages(getProductAvailability(Product.Bike)) ||
      isAvailableForMarketingPages(getProductAvailability(Product.BikePlus))),

  [instructorsTread.path]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.DigitalApp)) &&
    (isAvailableForMarketingPages(getProductAvailability(Product.Tread)) ||
      isAvailableForMarketingPages(getProductAvailability(Product.TreadPlus))),

  [digitalCheckout30Day.path]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.DigitalApp)),

  [app.path]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.DigitalApp)),

  [membership.path]: anyAvailableProduct,
  [privacy.path]: anyAvailableProduct,
  [tos.path]: anyAvailableProduct,
  [membershipTerms.path]: anyAvailableProduct,
  [accessibility.path]: anyAvailableProduct,

  [shopGuide.path]: getProductAvailability =>
    isAvailableForShopPages(getProductAvailability(Product.RainforestCafe)),

  [row.path]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.Row)),

  [rowSpecs.path]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.Row)),
  [rowWhy.path]: getProductAvailability =>
    isAvailableForMarketingPages(getProductAvailability(Product.Row)),

  [shopRow.path]: getProductAvailability =>
    isAvailableForShopPages(getProductAvailability(Product.Row)),
};

export default productStateUrlMap;
