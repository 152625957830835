import useSWR from 'swr';
import { useLocale } from '@peloton/internationalize';
import { previewClient as client } from '@content/client/pageBuilderClient';
import type { TypeDateTriggerFields } from '@page-builder/lib/types';

const useDateTriggerEntries = () => {
  const locale = useLocale();
  const getDateTriggerEntries = async () => {
    const params = {
      include: 10,
      limit: 100,
      locale,
      content_type: 'dateTrigger',
      order: '-sys.createdAt',
    };
    const { items } = await client.getEntries<TypeDateTriggerFields>(params);

    return items;
  };

  return useSWR('dateTriggers', getDateTriggerEntries);
};

export default useDateTriggerEntries;
