import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import type { Slug } from '@ecomm/models';
import { fetchBundleBySlug } from '../api';
import { toProductConfiguration } from '../models';
import type { RequestBundleSlugAction } from '../redux';
import {
  addCategories,
  addBundles,
  addProducts,
  addProductOptions,
  addProductConfigurations,
  addTraits,
  addDimensions,
  bundleSlugFetchFailed,
  bundleSlugFetchSucceeded,
  UiActionType,
  getBundleBySlug,
} from '../redux';

export const fetchSaga = function* (slug: Slug): SagaIterator {
  try {
    const client = yield getContext(CLIENT_CONTEXT);
    const normalizedResponse = yield call(fetchBundleBySlug, client, slug);
    const {
      entities: { categories, bundles, options, dimensions, traits, products },
    } = normalizedResponse;

    const productConfigurations = yield call(toProductConfiguration, products);

    // Reference/undefined errors were occuring in selectors because entities
    // were being put into redux before their dependencies (doesnt seem to
    // matter that promise.all was being used)
    yield all([
      put(addTraits(traits)),
      put(addDimensions(dimensions)),
      put(addBundles(bundles)),
      put(addProductOptions(options)),
      put(addProducts(products)),
      put(addCategories(categories)),
      put(addProductConfigurations(productConfigurations)),
    ]);

    yield put(bundleSlugFetchSucceeded(slug));
  } catch (err) {
    const error = new DomainError(`Error fetching ${slug} in fetchSaga saga`, err);
    yield all([put(reportError({ error })), put(bundleSlugFetchFailed(slug))]);
  }
};

export const checkStoreSaga = function* (action: RequestBundleSlugAction): SagaIterator {
  const bundle = yield select(getBundleBySlug, { slug: action.payload.slug });
  if (!bundle) {
    yield call(fetchSaga, action.payload.slug);
  } else {
    yield put(bundleSlugFetchSucceeded(action.payload.slug));
  }
};

const watcherSaga = function* () {
  yield takeEvery(UiActionType.BundleSlugRequest, checkStoreSaga);
};

export default watcherSaga;
