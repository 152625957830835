import type { NormalizedCacheObject, ApolloClient } from '@apollo/client';
import { toLocaleFromHostname } from '@peloton/internationalize';
import { SetGiftInformationOnCartDocument } from '@ecomm/checkout/hooks/SetGiftingInformationOnCart.generated';
import type {
  SetGiftInformationOnCartMutation,
  SetGiftInformationOnCartMutationVariables,
} from '@ecomm/checkout/hooks/SetGiftingInformationOnCart.generated';
import { getApolloClientInstanceV3 } from '@ecomm/graphql/clientInstance';

type giftingInformation = {
  cartId: any;
  recipientName: string;
  gifterName?: string;
  recipientEmail?: string;
  giftMessage?: string;
};

export const _setGiftingInformation = async (giftingInformation: giftingInformation) => {
  const locale = toLocaleFromHostname(window.location.hostname);
  // @ts-expect-error
  const apolloClient: ApolloClient<NormalizedCacheObject> = getApolloClientInstanceV3(
    locale,
  );

  SetGiftInformationOnCartDocument;
  const response = await apolloClient.mutate<
    SetGiftInformationOnCartMutation,
    SetGiftInformationOnCartMutationVariables
  >({
    mutation: SetGiftInformationOnCartDocument,
    variables: {
      giftingInformation,
    },
  });

  if ((await response)?.data?.setGiftInformationOnCart?.__typename === 'DomainError') {
    throw new Error();
  }
};
