import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

const Routes = [
  <Route
    key="membership-page"
    exact={true}
    path="/membership"
    component={asyncComponent(
      () => import('./Page' /* webpackChunkName: "Membership" */),
    )}
    page
  />,
];

export default Routes;
