/* eslint-disable */
import { SupportedTLD } from '@peloton/internationalize/models/locale';
import { toAffirmKey } from '@peloton/internationalize/consolidatedAppConfigs/ecomm/financing/affirmConfig';
import {
  AFFIRM_SCRIPT_URL_US,
  AFFIRM_SCRIPT_URL_CA,
  AFFIRM_SCRIPT_URL_AU,
} from '@peloton/app-config';
import getAffirm from './api';

// This is a copy paste from the affirm api documentation
// When this script is executed, it adds a <script> tag to the document head
// When that script tag executes it adds affirm to the window object
// After that affirm.window is used for certain integrations (affirm 'learn more' modal)
// https://docs.affirm.com/Integrate_Affirm/Promotional_Messaging#section_3
const affirmLoader = (l: any, g: any, m: any, e: any, a: any, f: any, b: any) => {
  let d,
    c = l[m] || {},
    h = document.createElement(f),
    n = document.getElementsByTagName(f)[0],
    k = function (a: any, b: any, c: any) {
      return function () {
        a[b]._.push([c, arguments]);
      };
    };
  c[e] = k(c, e, 'set');
  d = c[e];
  c[a] = {};
  c[a]._ = [];
  d._ = [];
  c[a][b] = k(c, a, b);
  a = 0;
  for (
    b = 'set add save post open empty reset on off trigger ready setProduct'.split(' ');
    a < b.length;
    a++
  )
    d[b[a]] = k(c, e, b[a]);
  a = 0;
  for (b = ['get', 'token', 'url', 'items']; a < b.length; a++) d[b[a]] = function () {};
  h.async = !0;
  h.src = g[f];
  n.parentNode.insertBefore(h, n);
  delete g[f];
  d(g);
  l[m] = c;
};

// Generate the affirm config object based on current environment
const getAffirmConfig = (tld: SupportedTLD, useTransactionAPIKeys = false) => ({
  public_api_key: toAffirmKey(tld, useTransactionAPIKeys),
  script: getScriptUrl(tld),
  locale: tld === SupportedTLD.Ca ? 'en_CA' : 'en_US',
  country_code: tld === SupportedTLD.Ca ? 'CAN' : 'USA',
});

// Affirm url's pulled in from root app-config/stage.env for dev, and app-config/prod.env for prod
// TODO: should pull from one key when builds are separated by TLD
const getScriptUrl = (tld: SupportedTLD) => {
  if (tld === SupportedTLD.Au) {
    return AFFIRM_SCRIPT_URL_AU;
  } else if (tld === SupportedTLD.Ca) {
    return AFFIRM_SCRIPT_URL_CA;
  }
  return AFFIRM_SCRIPT_URL_US;
};

const loadAffirm = (tld: SupportedTLD, useTransactionAPIKeys = false): void => {
  if (!Boolean(getAffirm())) {
    const affirmConfig = getAffirmConfig(tld, useTransactionAPIKeys);
    // Invoke the affirm script with the config object
    // Again this comes from affirm
    // https://docs.affirm.com/Integrate_Affirm/Promotional_Messaging#section_3
    affirmLoader(window, affirmConfig, 'affirm', 'checkout', 'ui', 'script', 'ready');
  }
};

export default loadAffirm;
