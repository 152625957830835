import { environmentFlags } from '@peloton/env';
import { toAbsoluteUrl } from '@peloton/external-links';
import { CP_MTM_SKU, STUDENT_MTM_SKU } from '@peloton/subscription-plans/skus';

export const CP_TRIAL_PATH = '/digital/offers/cp-free-trial';
export const STUDENT_TRIAL_PATH = '/digital/offers/students-free-trial';

export const SEGMENTS = {
  students: {
    offerUrl: '/offers/students',
    redirectUrl: toAbsoluteUrl('/digital/offers/students-free-trial'),
    sheerIdProgramUrl: 'https://offers.sheerid.com/peloton/ecom/student/',
    subscriptionSku: STUDENT_MTM_SKU,
  },
  military: {
    offerUrl: '/offers/military',
    redirectUrl: toAbsoluteUrl('/digital/offers/cp-free-trial'),
    sheerIdProgramUrl: 'https://offers.sheerid.com/peloton/ecom/military/',
    subscriptionSku: CP_MTM_SKU,
  },
  healthcare: {
    offerUrl: '/offers/healthcare',
    redirectUrl: toAbsoluteUrl('/digital/offers/cp-free-trial'),
    sheerIdProgramUrl: 'https://offers.sheerid.com/peloton/ecom/medical/',
    subscriptionSku: CP_MTM_SKU,
  },
  veterinarian: {
    offerUrl: '/offers/veterinarian',
    redirectUrl: toAbsoluteUrl('/digital/offers/cp-free-trial'),
    sheerIdProgramUrl: 'https://offers.sheerid.com/peloton/ecom/licensed-professional/',
    subscriptionSku: CP_MTM_SKU,
  },
  educators: {
    offerUrl: '/offers/educators',
    redirectUrl: toAbsoluteUrl('/digital/offers/cp-free-trial'),
    sheerIdProgramUrl: 'https://offers.sheerid.com/peloton/ecom/teacher/',
    subscriptionSku: CP_MTM_SKU,
  },
  firstResponders: {
    offerUrl: '/offers/firstResponders',
    redirectUrl: toAbsoluteUrl('/digital/offers/cp-free-trial'),
    sheerIdProgramUrl: 'https://offers.sheerid.com/peloton/ecom/first-responder/',
    subscriptionSku: CP_MTM_SKU,
  },
};

export const STAGING_SEGMENTS = {
  ...SEGMENTS,
  students: {
    ...SEGMENTS.students,
    sheerIdProgramUrl: 'https://offers.sheerid.com/peloton/staging/ecom/student/',
  },
  military: {
    ...SEGMENTS.military,
    sheerIdProgramUrl: 'https://offers.sheerid.com/peloton/staging/ecom/military/',
  },
  healthcare: {
    ...SEGMENTS.healthcare,
    sheerIdProgramUrl: 'https://offers.sheerid.com/peloton/staging/ecom/medical/',
  },
  veterinarian: {
    ...SEGMENTS.veterinarian,
    sheerIdProgramUrl:
      'https://offers.sheerid.com/peloton/staging/ecom/licensed-professional/',
  },
  educators: {
    ...SEGMENTS.educators,
    sheerIdProgramUrl: 'https://offers.sheerid.com/peloton/staging/ecom/teacher/',
  },
  firstResponders: {
    ...SEGMENTS.firstResponders,
    sheerIdProgramUrl: 'https://offers.sheerid.com/peloton/staging/ecom/first-responder/',
  },
};

export const LOCAL_SEGMENTS = { ...STAGING_SEGMENTS };

export type Segment = {
  offerUrl: string;
  redirectUrl: string;
  sheerIdProgramUrl: string;
  subscriptionSku: string;
};

export type Segments = {
  students: Segment;
  military: Segment;
  healthcare: Segment;
  veterinarian?: Segment;
  educators: Segment;
  firstResponders: Segment;
};

export const getSegments = (): Segments => {
  const APP = 'www';
  const envFlags = environmentFlags(APP)();
  const { isProd, isStaging } = envFlags;
  let segments: Segments;

  if (isProd) {
    segments = {
      ...SEGMENTS,
    };
  } else if (isStaging) {
    segments = {
      ...STAGING_SEGMENTS,
    };
  } else {
    segments = {
      ...LOCAL_SEGMENTS,
    };
  }

  return segments;
};
