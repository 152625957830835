import { useContext, useRef } from 'react';
import { GlobalReferenceContext, GlobalRefElement } from '../GlobalReferenceProvider';
import useIsomorphicEffect from './useIsomorphicEffect';
import useResizeRefWatch from './useResizeRefWatch';

// SubHeaderNav (old) or Headband (new)
const useSubHeaderReference = () => {
  const { setElement } = useContext(GlobalReferenceContext);
  const subHeaderRef = useRef<HTMLDivElement | null>(null);
  useResizeRefWatch(subHeaderRef, ref =>
    setElement(GlobalRefElement.SUBHEADER, ref?.getBoundingClientRect(), ref),
  );

  useIsomorphicEffect(() => {
    setElement(
      GlobalRefElement.SUBHEADER,
      subHeaderRef.current?.getBoundingClientRect(),
      subHeaderRef.current,
    );
  }, []);

  return subHeaderRef;
};

export default useSubHeaderReference;
