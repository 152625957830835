import { useState, useEffect } from 'react';
import { checkAndRemoveLocalePrefix } from '@peloton/internationalize/models/path';
import { useHasVariation } from '@peloton/split-testing/hooks';

const SIMPLIFIED_NAV_EXPERIMENT = 'Simplified Nav';
const ORGANIC_TRAFFIC_EXPERIMENT = 'Simplified Nav - Organic Traffic';
const ORGANIC_TRAFFIC_VARIATION = 'Variation #1';
const SIMPLIFIED_NAV_VARIATION = 'Variation #1';

const bikePaths = [
  '/bike',
  '/bike-plus',
  '/shop/bike/bike-package',
  '/shop/bike-plus/bike-plus-basics-package-us',
];

const utmParams = [
  '?utm_source=google&utm_medium=search',
  '?utm_source=facebook&utm_medium=paid_social',
  '?utm_source=tiktok&utm_medium=paid_social',
];

export const useIsSimplifiedNavExperimentActive = () => {
  const isSimplifiedNavExperimentActive = useHasVariation(
    SIMPLIFIED_NAV_EXPERIMENT,
    SIMPLIFIED_NAV_VARIATION,
  );
  return isSimplifiedNavExperimentActive;
};

export const useIsOrganicTrafficExperimentActive = () => {
  const isOrganicTrafficExperimentActive = useHasVariation(
    ORGANIC_TRAFFIC_EXPERIMENT,
    ORGANIC_TRAFFIC_VARIATION,
  );

  return isOrganicTrafficExperimentActive;
};

export const setSessionSimplifiedNav = (value: 'true' | 'false') => {
  if (window) {
    window.sessionStorage.setItem('simplifiedNav', value);
  }
};

export const useSimplifiedNav = () => {
  const [shouldDisplaySimplifiedNav, setShouldDisplaySimplifiedNav] = useState(false);
  const inSimplifiedNavVariation = !!useIsSimplifiedNavExperimentActive();
  const inOrganicTrafficVariation = !!useIsOrganicTrafficExperimentActive();

  useEffect(() => {
    const sessionSimplifiedNav = window.sessionStorage.getItem('simplifiedNav');

    const path = window.location.pathname;
    const search = window.location.search;

    const isOrganicTraffic = document.referrer === '';
    const isBikePLPOrPDP =
      bikePaths.some(bikePath => path.includes(bikePath)) &&
      utmParams.some(utmParam => search.includes(utmParam));

    // if value has been explicitly set to false, never show simplified nav
    const isSimplifiedNavEnabled =
      sessionSimplifiedNav !== 'false' &&
      isOrganicTraffic &&
      (isBikePLPOrPDP ||
        inSimplifiedNavVariation ||
        inOrganicTrafficVariation ||
        sessionSimplifiedNav === 'true');
    const isHomePage = checkAndRemoveLocalePrefix(window.location.pathname) === '/';

    if (isHomePage && sessionSimplifiedNav === 'true') {
      setSessionSimplifiedNav('false');
    }

    const shouldDisplay = isSimplifiedNavEnabled && !isHomePage;
    if (shouldDisplay && sessionSimplifiedNav === null) {
      // If it's explicitly been set to false, don't set to true
      setSessionSimplifiedNav('true');
    }

    setShouldDisplaySimplifiedNav(shouldDisplay);
  }, [inOrganicTrafficVariation, inSimplifiedNavVariation]);

  return {
    shouldDisplaySimplifiedNav,
  };
};
