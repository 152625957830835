export const URL = 'https://graphql.contentful.com/content/v1/spaces/6jnflt57iyzx';
export const QUERY_TYPENAME_MAP = {
  accountLink: 'AccountLink',
  allPromos: 'PromoCollection',
  banner: 'Banner',
  companyPageText: 'CompanyPageText',
  contentAggregate: 'ContentAggregate',
  captureForm: 'CaptureForm',
  cta: 'Cta',
  ctaWithUrl: 'CtaWithUrl',
  ctaWithDriftInteraction: 'CtaWithDriftInteraction',
  ctaWithUrlSet: 'CtaWithUrlSet',
  contentByCfu: 'ContentByCfu',
  digitalLink: 'DigitalLink',
  digitalPromotion: 'DigitalPromotion',
  externalLink: 'ExternalLink',
  faq: 'Faq',
  forms: 'Forms',
  headband: 'Headband',
  headerBodyImage: 'HeaderBodyImage',
  headerBodyImageGallery: 'HeaderBodyImageGallery',
  headerBodyImageWithLink: 'HeaderBodyImageWithLink',
  headerBodyImageWithLinkGallery: 'HeaderBodyImageWithLinkGallery',
  hero: 'Hero',
  image: 'Image',
  imageGallery: 'ImageGallery',
  keyValue: 'KeyValue',
  legalText: 'LegalText',
  longHeaderBodyFootnote: 'LongHeaderBodyFootnote',
  markdownPage: 'MarkdownPage',
  mediaSection: 'MediaSection',
  memberReasonSet: 'MemberReasonSet',
  metadata: 'Metadata',
  modal: 'Modal',
  navigation: 'Navigation',
  otdLookup: 'OtdLookup',
  pressQuote: 'PressQuote',
  product: 'Product',
  productBlock: 'ProductBlock',
  productBlockSet: 'ProductBlockSet',
  productDetailsCard: 'ProductDetailsCard',
  productNav: 'ProductNav',
  promo: 'Promo',
  promoBanner: 'PromoBanner',
  promoHero: 'PromoHero',
  promoHeroSet: 'PromoHeroSet',
  promoModule: 'PromoModule',
  promotionalText: 'PromotionalText',
  quoteBanner: 'QuoteBanner',
  registrationPage: 'RegistrationPage',
  review: 'Review',
  reviewSection: 'ReviewSection',
  section: 'Section',
  shop: 'Shop',
  staticAsset: 'StaticAsset',
  track: 'Track',
  valueProp: 'ValueProp',
  valuePropSet: 'ValuePropSet',
  video: 'Video',
  wwwLink: 'WwwLink',
} as const;

export const TAG = 'www';
