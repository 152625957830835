import { track } from '@peloton/analytics/analytics';
import type { ExtLink } from '@peloton/external-links';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import { TrackingEvent } from './models';

export type TrackingProps = {
  href: string | ExtLink;
  parent: string;
  additionalProps?: object;
};

export const hrefToString = (link: ExtLink) => ('href' in link ? link.href : link.path);

const trackLinkClick = ({ href, parent, additionalProps }: TrackingProps) =>
  track({
    event: TrackingEvent.ClickedLink,
    properties: {
      linkTo: typeof href === 'string' ? href : hrefToString(href),
      parent: parent,
      page: getCurrentRelativePathname(),
      ...additionalProps,
    },
  });

export default trackLinkClick;
