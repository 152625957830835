import React from 'react';
import { useSelector } from 'react-redux';
import { getUser, isSignedIn } from '@peloton/auth';
import { UserInfoContext } from '@peloton/auth/UserInfoProvider';
import type { User } from './models';

const CRAUserInfoProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const user = useSelector(getUser);

  const value = React.useMemo(() => {
    const userValue = isSignedIn(user)
      ? {
          isLoggedIn: true,
          hasDeviceSubscription: (user as User).hasDeviceSubscription || false,
          hasDigitalSubscription: (user as User).hasDigitalSubscription || false,
          email: user.email,
          hashedEmail: user.hashedEmail,
          userId: user.id,
          memberGroups: user.memberGroups,
          isLoading: false,
        }
      : {
          isLoggedIn: false,
          hasDeviceSubscription: false,
          hasDigitalSubscription: false,
          memberGroups: [],
        };

    return {
      getUser: () => userValue,
    };
  }, [user]);

  return <UserInfoContext.Provider value={value}>{children}</UserInfoContext.Provider>;
};

export default CRAUserInfoProvider;
