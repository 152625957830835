import { parse, stringify } from 'querystring';
import {
  GEOMODAL_V2_PACKAGE_NAME_TO_SLUG_MAP,
  localeTldMap,
  Locale,
  removeHostTld,
} from '@peloton/internationalize';
import {
  addCurrentLocaleToUrl,
  checkAndRemoveLocalePrefix,
} from '@peloton/internationalize/models/path';

const austriaNavLinks = [
  '/bike',
  '/bike-plus',
  '/tread',
  '/app',
  '/about',
  '/privacy-policy',
  '/membership-terms',
  '/accessibility',
  '/mymembership',
];

export const isGeoModalDisabled = (): boolean =>
  document.cookie.indexOf('DISABLE_SUGGESTED_LOCALE=') !== -1;

export const wasPreviouslyHidden = (): boolean =>
  window.sessionStorage && window.sessionStorage.getItem('geoModalHidden') === 'true';

export const isRedirectFromGeomodal = (
  { is404 }: { is404: boolean } = { is404: false },
): boolean => {
  const queryString = window.location.search;
  const urlParams = parse(queryString);
  const redirectedFromGeomodal =
    urlParams['?geoModal'] === 'hide' || urlParams['geoModal'] === 'hide';
  return redirectedFromGeomodal;
};

export const resetGeomodalState = ({ is404 }: { is404: boolean }) => {
  setPreviouslyHidden();
  if (!is404) {
    resetURLFromGeomodal();
  }
};

export const setPreviouslyHidden = (): void =>
  window.sessionStorage && window.sessionStorage.setItem('geoModalHidden', 'true');

export const sameLocale = (browserLocale: Locale | null, targetLocale: Locale): boolean =>
  targetLocale === browserLocale;

const isClasspathUnavailableForAustria = (path: string, locale: string): boolean => {
  return (
    locale === Locale.GermanAustria &&
    path.startsWith('/classes') &&
    !austriaNavLinks.some(austriaPath => path.startsWith(austriaPath))
  );
};

export const domainSwitcher = (
  location: URL,
  _currentLocale: Locale,
  destinationLocale: Locale,
) => {
  const toTld = localeTldMap[destinationLocale];
  const port = location.port ? `:${location.port}` : '';
  const noPortHost = location.host.replace(`${port}`, '');
  const hostname = removeHostTld(noPortHost) + toTld;
  const pathname = checkAndRemoveLocalePrefix(location.pathname);
  if (isClasspathUnavailableForAustria(pathname, destinationLocale)) {
    return `${location.protocol}//${hostname}${port}?geoModal=hide`;
  }

  const translatedPathname = convertShopUrl(pathname, destinationLocale);

  const locationSearch = location.search
    ? location.search + '&geoModal=hide'
    : '?geoModal=hide';

  return `${location.protocol}//${hostname}${port}${translatedPathname}${locationSearch}${location.hash}`;
};

export const i18nUrlSwitcher = (location: URL, destinationLocale: Locale) => {
  const pathname = location.pathname;
  const pathnameSansLocale = checkAndRemoveLocalePrefix(pathname);
  const port = location.port ? `:${location.port}` : '';
  const noPortHost = location.host.replace(`${port}`, '');
  const hostname = removeHostTld(noPortHost) + '.com';

  if (isClasspathUnavailableForAustria(pathname, destinationLocale)) {
    return `${location.protocol}//${hostname}${port}/${Locale.GermanAustria}?geoModal=hide`;
  }

  const translatedPathname = convertShopUrl(pathnameSansLocale, destinationLocale);

  const locationSearch = location.search
    ? location.search + '&geoModal=hide'
    : '?geoModal=hide';

  const newLocaleTranslatedPathname = addCurrentLocaleToUrl(
    translatedPathname,
    destinationLocale,
  );

  return `${location.protocol}//${hostname}${port}${newLocaleTranslatedPathname}${locationSearch}${location.hash}`;
};

const getBundleName = (slug: string) => {
  let foundBundleName;
  Object.keys(GEOMODAL_V2_PACKAGE_NAME_TO_SLUG_MAP).forEach(locale => {
    Object.keys(GEOMODAL_V2_PACKAGE_NAME_TO_SLUG_MAP[locale]).forEach(bundleName => {
      if (GEOMODAL_V2_PACKAGE_NAME_TO_SLUG_MAP[locale][bundleName] === slug) {
        foundBundleName = bundleName;
      }
    });
  });
  return foundBundleName;
};

export const translateSlug = (slug: string, locale: Locale) => {
  const bundleName = getBundleName(slug);

  if (bundleName) {
    return GEOMODAL_V2_PACKAGE_NAME_TO_SLUG_MAP[locale][bundleName];
  }

  return slug;
};

export const convertShopUrl = (path: string, destination: Locale): string => {
  const [empty, shop, bundleType, slugOrType] = path.split('/');
  if (bundleType === 'accessories') {
    return path;
  }
  let translatedSlug = slugOrType;

  if (slugOrType) {
    translatedSlug = translateSlug(slugOrType, destination);
  }

  let convertedShopURL = [empty, shop, bundleType, translatedSlug].join('/');
  while (convertedShopURL.includes('//')) {
    convertedShopURL = convertedShopURL.replace('//', '/');
  }
  return convertedShopURL.replace(/\/$/, '');
};

export const resetURLFromGeomodal = () => {
  const queryString = window.location.search;
  const urlParams = parse(queryString);

  delete urlParams['geoModal'];
  delete urlParams['?geoModal'];
  const newQueryString = decodeURIComponent(stringify(urlParams));
  const tidiedUrl = `${location.pathname}${newQueryString}${location.hash}`;

  window.history.replaceState({}, 'Title', '' + tidiedUrl);
};
