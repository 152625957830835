import { T, always, complement, cond, isEmpty, match, pipe, prop, propEq } from 'ramda';
import type { Country } from '@peloton/internationalize';
import { theUserIsInTheUnitedStates, toCountry } from '@peloton/internationalize';
import type { Email } from '@ecomm/models';

export enum PageState {
  ActiveSub = 'ErrorActiveSubscription',
  CompletedFreeTrial = 'ErrorCompletedFreeTrial',
  ExistingUser = 'GrantFreeTrial',
  NewUser = 'Registration',
}

export enum ExistingError {
  Active = 'ExistingActive',
  Completed = 'ExistingCompleted',
}

type ProductInterest = 'bike' | 'tread' | 'digital';

type EventType = 'capture' | 'submit';

export enum CampaignId {
  Bike = '7011C0000012gvSQAQ',
  Digital = '7011C0000012gvcQAA',
  Tread = '7011C0000012gvXQAQ',
  Maxwell = '7011C000001Qe1XQAS',
  Woodlark = '7011C000001Qe1cQAC',
  '600Mass' = '7011C000001Qe1cQAC',
}

export enum CampaignName {
  Bike = 'Acquisition Content Trial Bike',
  Digital = 'Acquisition Content Trial Digital',
  Tread = 'Acquisition Content Trial Tread',
  Maxwell = 'maxwell_promo_2019',
  Woodlark = 'woodlark',
  '600Mass' = 'hotel_600_mass',
}

export enum Consent {
  Explicit = 'explicit',
  Implicit = 'implicit',
  NoConsent = 'No Consent',
}

export enum EventName {
  Captured = 'Captured Email',
  Submitted = 'Submitted Email',
}

type Campaign = {
  campaignId: CampaignId;
  campaignName: CampaignName;
};

type UpcaseCountry = 'CA' | 'GB' | 'US';

export type TrackingData = Campaign & {
  country: UpcaseCountry;
  email: Email;
  eventName: EventName;
  pageName: string;
  productInterest: ProductInterest;
  variation: undefined;
  propertyType: 'Web';
  firstName: undefined;
  lastName: undefined;
  marketingConsent: Consent;
  postalCode: undefined;
};

type CampaignOptions = {
  productInterest: ProductInterest;
  pageName: string;
};

const propMatches = (propName: string, pattern: RegExp) => (x: CampaignOptions) =>
  pipe<CampaignOptions, string, any[], boolean>(
    prop(propName),
    match(pattern),
    complement(isEmpty),
  )(x);

const toCampaignId = cond<CampaignOptions, CampaignId>([
  [propMatches('pageName', /maxwell/), always(CampaignId.Maxwell)],
  [propMatches('pageName', /600mass/), always(CampaignId['600Mass'])],
  [propMatches('pageName', /woodlark/), always(CampaignId.Woodlark)],
  [propEq('productInterest', 'bike'), always(CampaignId.Bike)],
  [propEq('productInterest', 'tread'), always(CampaignId.Tread)],
  [T, always(CampaignId.Digital)],
]);

const toCampaignName = cond<CampaignOptions, CampaignName>([
  [propMatches('pageName', /maxwell/), always(CampaignName.Maxwell)],
  [propMatches('pageName', /600mass/), always(CampaignName['600Mass'])],
  [propMatches('pageName', /woodlark/), always(CampaignName.Woodlark)],
  [propEq('productInterest', 'bike'), always(CampaignName.Bike)],
  [propEq('productInterest', 'tread'), always(CampaignName.Tread)],
  [T, always(CampaignName.Digital)],
]);

export const toConsent = (
  country: Country,
  event: EventType,
  allowMarketing: boolean = false,
): Consent => {
  // In the US, we always send Implicit
  if (theUserIsInTheUnitedStates(country)) {
    return Consent.Implicit;
  }
  // When users emails are captured in non-US countries, we always send Explicit
  if (event === 'capture') {
    return Consent.Explicit;
  }
  // When the event is Submitted, we check the flag
  return allowMarketing ? Consent.Explicit : Consent.NoConsent;
};

export const toCampaign = (
  productInterest: ProductInterest,
  pageName: string,
): Campaign => ({
  campaignId: toCampaignId({ productInterest, pageName }),
  campaignName: toCampaignName({ productInterest, pageName }),
});

export const toUpcaseCountry = (country: Country): UpcaseCountry =>
  country.toUpperCase() as UpcaseCountry;

export const toEventName = (eventType: EventType): EventName =>
  eventType === 'submit' ? EventName.Submitted : EventName.Captured;

type Opts = {
  allowMarketing?: boolean;
  country?: Country;
  email: Email;
  event: EventType;
  pageName: string;
  productInterest: ProductInterest;
};

export const toTrackingData = ({
  allowMarketing,
  country = toCountry(),
  email,
  event,
  pageName,
  productInterest,
}: Opts): TrackingData => ({
  ...toCampaign(productInterest, pageName),
  country: toUpcaseCountry(country),
  email,
  productInterest:
    productInterest === 'bike' || productInterest === 'tread'
      ? productInterest
      : 'digital',
  eventName: toEventName(event),
  pageName,
  marketingConsent: toConsent(country, event, allowMarketing),
  propertyType: 'Web',
  firstName: undefined,
  lastName: undefined,
  postalCode: undefined,
  variation: undefined,
});
