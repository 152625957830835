import { grey, white, Icon, Eyebrow } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';

type Props = {
  handleClose: () => void;
  handleBack?: () => void;
  heading?: string;
};

const Header: React.FC<React.PropsWithChildren<Props>> = ({
  handleClose,
  handleBack,
  heading,
}) => (
  <HeaderContainer hasBackButton={Boolean(handleBack)}>
    {handleBack && (
      <IconContainer
        data-test-id="navigation-back"
        onClick={handleBack}
        aria-label="Back"
      >
        <Icon primaryColor={white} name="back" />
      </IconContainer>
    )}
    <Eyebrow textColor={grey[50]}>{heading}</Eyebrow>
    <IconContainer
      data-test-id="navigation-close"
      onClick={handleClose}
      aria-label="Close"
    >
      <Icon name="close" primaryColor={white} />
    </IconContainer>
  </HeaderContainer>
);

export default Header;

export const HeaderContainer = styled.div<{ hasBackButton: boolean }>`
  display: flex;
  justify-content: ${({ hasBackButton }) =>
    hasBackButton ? 'space-between' : 'flex-end'};
  align-items: center;
  width: 100%;
  height: 4rem;
`;

const IconContainer = styled.button`
  fill: ${white};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: calc(21px + 3rem);
`;
