import { always, propOr } from 'ramda';
import type { ActionCreator, AnyAction } from 'redux';

type GenericActionCreator = ActionCreator<AnyAction>;

export type Hooks = {
  onError: GenericActionCreator;
  onSuccess: GenericActionCreator;
};

export const defaultHook = always({ type: '' });

// Necessary to explicitly type getHook as typescript has an issue when partially
// applying propOr. Another fix is typing at the call site or casting, but prefer
// not to leak types from the util.
export const getHook = (hookName: keyof Hooks, hooks?: Hooks): GenericActionCreator =>
  propOr(defaultHook, hookName, hooks);
