import { useTracking } from 'react-tracking';
import { TrackingEvent } from '../models';
import type { TrackingProps } from '../trackLinkClick';
import { hrefToString } from '../trackLinkClick';

const useTrackLinkClick = () => {
  const { trackEvent } = useTracking();

  const trackLinkClick = ({ href, parent, additionalProps }: TrackingProps) =>
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        linkTo: typeof href === 'string' ? href : hrefToString(href),
        parent,
        ...additionalProps,
      },
    });

  return {
    trackLinkClick,
  };
};

export default useTrackLinkClick;
