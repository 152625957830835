import { useSelector } from 'react-redux';
import { getOperationalShowroomsSelector } from '@ecomm/showrooms';
import { filterShowroomsByLocation, findNearestShowroom } from './../showroomUtils';
import { useIpGeolocation } from './useIpGeolocation';

export const useNearestShowroom = (isEnabled: boolean = true) => {
  const showrooms = useSelector(getOperationalShowroomsSelector);
  const { position } = useIpGeolocation(isEnabled);
  const nearestShowrooms = filterShowroomsByLocation(showrooms, position);
  const nearestShowroom = findNearestShowroom(nearestShowrooms);

  if (nearestShowroom) {
    return nearestShowroom;
  }
  return null;
};
