import type { LocationChangeAction } from 'connected-react-router';
import { LOCATION_CHANGE } from 'connected-react-router';
import { call, takeLatest } from 'redux-saga/effects';
import { openChat } from './chat';

export const checkRouteSaga = function* (action: LocationChangeAction) {
  if (action.payload.location.search.includes('chat=true')) {
    yield call(openChat);
  }
};

const watcherSaga = function* () {
  yield takeLatest(LOCATION_CHANGE, checkRouteSaga);
};

export default watcherSaga;
