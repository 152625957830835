export const HOME_ROUTE = '/';

/**
 * The indie-cart page is no more. Instead, /cart will redirect to the homepage with the cart open.
 *
 * Let's keep this separate from the SHADOWED_ROUTES list
 * since it has special meaning beyond being a simple alias.
 */
export const CART_ROUTE = '/cart';

export const SHADOWED_ROUTES = [
  '/billsimmons',
  '/bloomberg',
  '/bs',
  '/cnn',
  '/deal',
  '/espn',
  '/foxnews',
  '/gordondeal',
  '/pmt',
  '/podsaveamerica',
  '/psa',
  '/rogan',
  '/sirius',
  '/stuff',
  '/wrestling',
];
