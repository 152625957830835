import type { Client } from '@peloton/api';
import { pipeDataTwice } from '@peloton/api';
import type { ID } from '@ecomm/models';
import { toOrderDeliveryDates } from './mappers';

const deliveryDatesUrl = '/ecomm/warehouse/available_delivery_dates?order_id=';

export const fetchDeliveryDates = (api: Client, orderId: ID) =>
  api
    .get(`${deliveryDatesUrl}${orderId}`)
    .then(pipeDataTwice(toOrderDeliveryDates(orderId)));
