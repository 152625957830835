// NOTE: This format will likely soon be deprecated in favor of app level routing.
// If you are seeing this in the far future, something has gone wrong.
import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';
import { createI18nMatchSelector } from '@ecomm/saga-utils';

export const PATH = '/register/free-trial';

const route = [
  <Route
    exact={true}
    key={PATH}
    path={PATH}
    component={asyncComponent(
      () => import('./Handler' /* webpackChunkName: "RegisterFreeTrial" */),
    )}
  />,
];

export default route;

export const matchRegisterFreeTrial = createI18nMatchSelector(
  `${PATH}/:productInterest(bike|tread|maxwell|600mass|woodlark)?`,
);
