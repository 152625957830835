import type { ClientError } from '@peloton/api';
import { isHttpError } from '@peloton/api';

export enum ErrorCode {
  ExistingTrial = 'UserHasExistingTrial',
  Unauthenticated = 'UserUnauthenticated',
  Default = 'default',
}

const errorMap = {
  150: ErrorCode.ExistingTrial,
  3020: ErrorCode.Unauthenticated,
};

export const toErrorCode = (error: ClientError): ErrorCode => {
  const errorCode = isHttpError(error) ? error.responseBody.code : undefined;

  return errorMap[errorCode] || ErrorCode.Default;
};

export const NEEDS_LOGIN = 'UserNeedsToLogin';
