import { pathOr, pickAll } from 'ramda';
import type { HeaderBodyImage } from '../models.generated';
import { useHeaderBodyImageQuery } from '../queries/contentTypes/headerBodyImage.generated';
import type { ImageType } from './useImageData';
import { transformImageData } from './useImageData';

export type HeaderBodyImageData = {
  key: string;
  header: string;
  body: string;
  image: ImageType;
  theme?: string;
};

export const transformHeaderBodyImageData = (
  response: object,
  id: HeaderBodyImage,
): HeaderBodyImageData => {
  const {
    key = id,
    header,
    body,
    image = {
      mobile: {
        url: '',
        description: '',
      },
      tablet: {
        url: '',
        description: '',
      },
      desktop: {
        url: '',
        description: '',
      },
      desktopLarge: {
        url: '',
        description: '',
      },
    },
    theme,
  }: HeaderBodyImageData = pickAll(
    ['key', 'header', 'body', 'image', 'theme'],
    pathOr({}, ['data', 'headerBodyImage'], response),
  );

  const transformedImageData = transformImageData({ data: { image } });

  return {
    key,
    header: header || `Header Body Image: ${key}`,
    body: body || `Header Body Image: ${key}`,
    ...transformedImageData,
    theme,
  };
};

const useHeaderBodyImageData = (id: HeaderBodyImage): HeaderBodyImageData => {
  const res = useHeaderBodyImageQuery({ variables: { id } });

  return transformHeaderBodyImageData(res, id);
};

export default useHeaderBodyImageData;
