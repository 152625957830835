import { pathOr } from 'ramda';
import type { Effect } from 'redux-saga/effects';
import { call, getContext, put, takeEvery, select } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { assertClientContext, CLIENT_CONTEXT } from '@peloton/api';
import { UserReducerActionType, getSignedInUserEmail } from '@peloton/auth/redux';
import { updateEmail } from '@ecomm/cart/api';
import { updateEmailSuccess } from '@ecomm/cart/redux';
import type { EnteredEmailAction } from '../redux/user';
import { ActionType } from '../redux/user';

const EMAIL_REGEX = RegExp(/^.+@.+\..{2,}$/);

export const updateCartUserSaga = function* (
  client: Client,
  action: EnteredEmailAction,
): IterableIterator<Effect> {
  const email = pathOr(yield select(getSignedInUserEmail), ['payload', 'email'], action);

  const valid = email && email.match(EMAIL_REGEX);

  if (valid) {
    try {
      yield call(updateEmail, client, email);
      yield put(updateEmailSuccess(email));
    } catch (err) {
      return;
    }
  }
};

const cartUserEmailSaga = function* (): IterableIterator<Effect> {
  const client = yield getContext(CLIENT_CONTEXT);
  assertClientContext(client);

  yield takeEvery(
    [ActionType.EnteredEmail, UserReducerActionType.REQUEST_SUCCESS],
    updateCartUserSaga,
    client,
  );
};

export default cartUserEmailSaga;
