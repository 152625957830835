import { pickAll } from 'ramda';
import React from 'react';
import useTrackLinkClick from '@ecomm/analytics/hooks/useTrackLinkClick';
import type { LinkProps } from './Link';
import Link from './Link';

type TrackingProps = {
  parent: string;
  eventProps: object;
};

type ClickEvent = React.MouseEvent<HTMLAnchorElement, MouseEvent> &
  React.MouseEvent<HTMLButtonElement, MouseEvent>;

export type Props = LinkProps & TrackingProps;

const TrackingLink: React.FC<React.PropsWithChildren<Props>> = ({
  eventProps,
  parent,
  onClick,
  ...linkProps
}) => {
  const { trackLinkClick } = useTrackLinkClick();
  const { to }: { to: string } = pickAll(['to'], linkProps);

  const handleClick = (event: ClickEvent) => {
    onClick && onClick(event);
    trackLinkClick({
      href: to ? to : '',
      parent: parent,
      additionalProps: eventProps,
    });
  };

  return <Link {...linkProps} onClick={handleClick} />;
};

export default React.memo(TrackingLink);
