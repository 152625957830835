import React from 'react';
import { Markdown } from '@peloton/copy';
import { useBannerReference } from '@acme-ui/global';
import TrackedLinkRenderer from '@page-builder/modules/Banner/TrackedLinkRenderer';
import EcommBanner from './EcommBanner';
import type { BannerProps } from './models';

const UnclickableBanner: React.FC<React.PropsWithChildren<BannerProps>> = ({
  text,
  theme,
  dataTestIdPrefix,
  estimates,
  markdownRenderAddons = {},
}) => {
  const bannerRef = useBannerReference();

  const TextWithDataTestId: React.FC<React.PropsWithChildren<unknown>> = ({
    children,
  }) => <span data-test-id={`${dataTestIdPrefix}Text`}>{children}</span>;

  return (
    <EcommBanner ref={bannerRef} clickable={false} theme={theme}>
      <Markdown
        content={text}
        values={{ ...estimates }}
        markdown={{
          renderers: {
            paragraph: TextWithDataTestId,
            link: TrackedLinkRenderer,
            ...markdownRenderAddons,
          },
        }}
      />
    </EcommBanner>
  );
};

export default UnclickableBanner;
