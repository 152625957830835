import React from 'react';
import { SWRConfig } from 'swr';
import { toLocaleFromHostname } from '@peloton/internationalize';
import Footer from '@acme-ui/footer/Footer';
import { MainHeaderNav } from '@acme-ui/nav/HeaderNav';
import { useCRAGeoModalCopy } from '@content/client/craGeoModalCopy';
import { toFetcher } from '@content/client/craGlobalEntriesFetcher';
import { useIsToggleActive } from '@ecomm/feature-toggle';
import LegalSection from '@ecomm/footer/content/LegalSection';
import { useAllOtdEstimatesForMarkdown } from '@ecomm/otd/useOtdEstimates';
import { productPrices as prices } from '@ecomm/pricing/static';
import ErrorComponent from './ErrorComponent';
import Container from './PageContainer';

const ErrorPageView = () => {
  const locale = toLocaleFromHostname();
  const preview = useIsToggleActive()('previewCopy');
  const otdEstimates = useAllOtdEstimatesForMarkdown();
  const geoModalCopy = useCRAGeoModalCopy();

  return (
    <SWRConfig
      value={{
        fetcher: toFetcher(preview),
        fallback: { prices, otdEstimates, geoModalCopy },
      }}
    >
      <Container>
        <MainHeaderNav />
        <ErrorComponent />
        <LegalSection />
        <Footer locale={locale} />
      </Container>
    </SWRConfig>
  );
};

export default ErrorPageView;
