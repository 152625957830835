import type {
  AddBundleConfigurationsAction as TAddBundleConfigurationsAction,
  UpdateBundleConfigurationAction as TUpdateBundleConfigurationAction,
} from './bundles';
import { addBundleConfigurations, updateBundleConfiguration } from './bundles';
import { updateSinglePackageSelection, updateAllPackageSelections } from './packages';
import type {
  AddProductConfigurationsAction as TAddProductConfigurationsAction,
  UpdateProductConfigurationAction as TUpdateProductConfigurationAction,
} from './products';
import { addProductConfigurations, updateProductConfiguration } from './products';
import type { ReducerState as TReducerState } from './rootReducer';
import rootReducer from './rootReducer';

export default rootReducer;

export {
  addBundleConfigurations,
  addProductConfigurations,
  updateBundleConfiguration,
  updateProductConfiguration,
  updateSinglePackageSelection,
  updateAllPackageSelections,
};

export type ReducerState = TReducerState;
export type AddBundleConfigurationsAction = TAddBundleConfigurationsAction;
export type AddProductConfigurationsAction = TAddProductConfigurationsAction;
export type UpdateBundleConfigurationAction = TUpdateBundleConfigurationAction;
export type UpdateProductConfigurationAction = TUpdateProductConfigurationAction;
