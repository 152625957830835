// Provider for storing user's locale in a tld or subpath cookie
// this cookie will be used by non-www apps to determine how to redirect from a tld to a subpath url

// eslint-disable-next-line no-restricted-imports
import Cookies from 'cookies-js';
import React from 'react';
import { getSubpathLocale, toMatchingTld } from './';

const setLocaleCookie = () => {
  const subpathLocale = getSubpathLocale(window.location.href);
  const tldLocale = toMatchingTld(window.location.hostname);
  Cookies.set('subpath_locale', subpathLocale, { domain: `.onepeloton${tldLocale}` });
  Cookies.set('tld_locale', tldLocale, { domain: `.onepeloton${tldLocale}` });
};

const LocaleCookieProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  React.useEffect(() => {
    setLocaleCookie();
  }, []);

  return <>{children}</>;
};

export { LocaleCookieProvider };
