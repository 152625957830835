import { GUIDE_MEMBERSHIP_PRICING, Locale } from '@peloton/internationalize';
import { isGuideBundleTypeMatch } from '@ecomm/shop/models/Bundle';
import type { BundleType } from '@ecomm/shop/models/BundleType';

export const localeToMembershipPrice = (
  locale: Locale,
  isIncreasedAllAccessMembershipPriceEnabled: Boolean = false,
) => {
  if (isIncreasedAllAccessMembershipPriceEnabled) {
    return toIncreasedMembershipPrice(locale);
  }
  if (locale === Locale.EnglishCanada) {
    return 4900;
  }

  if (locale === Locale.EnglishAustralia) {
    return 5900;
  }

  return 3900;
};

export const getMembershipPriceForLocale = (
  device: BundleType,
  locale: Locale,
  isIncreasedAllAccessMembershipPriceEnabled: Boolean = false,
) => {
  if (isGuideBundleTypeMatch(device)) {
    return GUIDE_MEMBERSHIP_PRICING[locale];
  }
  return localeToMembershipPrice(locale, isIncreasedAllAccessMembershipPriceEnabled);
};

const toIncreasedMembershipPrice = (locale: Locale) => {
  if (locale === Locale.EnglishCanada) {
    return 5500;
  }

  if (locale === Locale.EnglishAustralia) {
    return 5900;
  }

  if (locale === Locale.EnglishUnitedStates) {
    return 4400;
  }
  return 3900;
};
