export enum ProductStates {
  Unavailable = 'unavailable',
  UnavailableMarketing = 'unavailable.marketing',
  UnavailableMarketingLeadCapture = 'unavailable.marketing.leadCapture',
  UnavailableShopLeadCapture = 'unavailable.shop.leadCapture',
  UnavailableShopSoldOut = 'unavailable.shop.soldOut',
  AvailableRestrictedByPostalCode = 'available.restricted.postalCode',
  AvailablePreorder = 'available.shop.preorder',
  Available = 'available',
  Unknown = 'unknown',
}

export const isProductState = (productState: any): productState is ProductStates =>
  Object.values(ProductStates).some(p => p === productState);

export const stringToProductState = (s: string): ProductStates => {
  switch (s) {
    case 'unavailable': {
      return ProductStates.Unavailable;
    }
    case 'unavailable.marketing': {
      return ProductStates.UnavailableMarketing;
    }
    case 'unavailable.marketing.leadCapture': {
      return ProductStates.UnavailableMarketingLeadCapture;
    }
    case 'unavailable.shop.leadCapture': {
      return ProductStates.UnavailableShopLeadCapture;
    }
    case 'unavailable.shop.soldOut': {
      return ProductStates.UnavailableShopSoldOut;
    }
    case 'available.restricted.postalCode': {
      return ProductStates.AvailableRestrictedByPostalCode;
    }
    case 'available.shop.preorder': {
      return ProductStates.AvailablePreorder;
    }
    case 'available': {
      return ProductStates.Available;
    }
    default:
      return ProductStates.Unknown;
  }
};
