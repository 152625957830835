import { replace } from 'connected-react-router';
import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, takeEvery } from 'redux-saga/effects';
import { enrollWithJWTAPI } from '@onewellness/api';
import { CLIENT_CONTEXT } from '@onewellness/api/client';
import { Audience, Page, toPathWithAudience } from '@onewellness/routes';
import type { VerifyJWTRequestAction } from '../redux';
import { VerifyJWTActionTypes, verifyJWTSucceeded, verifyJWTFailed } from '../redux';

export const verifyJWTSaga = function* ({
  payload: { jwt },
}: VerifyJWTRequestAction): SagaIterator {
  try {
    const client = yield getContext(CLIENT_CONTEXT);
    const responseData = yield call(enrollWithJWTAPI, client, jwt);

    const accessToken = responseData.enrollWithJwt.accessToken;

    if (accessToken) {
      yield all([
        put(verifyJWTSucceeded()),
        put(
          replace(
            `${toPathWithAudience(
              Page.GetStarted,
              Audience.Corporate,
            )}?token=${accessToken}`,
          ),
        ),
      ]);
    } else {
      yield put(verifyJWTFailed());
    }
  } catch (e) {
    yield put(verifyJWTFailed(e));
  }
};

const watcherSaga = function* (): SagaIterator {
  yield takeEvery(VerifyJWTActionTypes.VerifyJWTRequested, verifyJWTSaga);
};

export default watcherSaga;
