import { propEq } from 'ramda';

export enum SubscriptionType {
  Commercial = 'commercial',
  Consumer = 'consumer',
  Guide = 'guide',
}

export const isGuideSubType = (sub: SubLike): boolean =>
  isType(SubscriptionType.Guide, sub);

export const subHasEquipmentLease = (sub: SubLike): boolean => isLeased(true, sub);

const isType = propEq('subscriptionType');

const isLeased = propEq('hasEquipmentLease');

type SubLike = {
  hasEquipmentLease?: boolean;
  subscriptionType: SubscriptionType;
};
