import React from 'react';
import { NotFoundError, NotFoundErrorPage } from '@ecomm/layout';
import Route from './Route';

export type Props = {
  page?: boolean;
};

const RouteNotFound: React.FC<React.PropsWithChildren<Props>> = ({ page }) =>
  page ? <Route component={NotFoundErrorPage} /> : <Route component={NotFoundError} />;

export default RouteNotFound;
