import { brand, grey, Body } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { toFormattedText } from '@peloton/copy';
import { media } from '@peloton/styles';
import { FormatCtaWithUrl, useKeyValueData } from '@ecomm/copy';
import { APRModalLink, PageType } from '@ecomm/financing';
import { Link } from '@ecomm/internationalize-ui';
import { AffirmLogo } from '@ecomm/logos';
import { BundleType } from '@ecomm/shop/models';
import { useTrackAffirmPrequalComplete } from '../affirm/hooks';
import { trackClick } from '../FinancingPrequalifyButton';
import useFinancing from '../hooks/useFinancing';

export const PrequalifyNowHomePageModule = () => {
  const zeroDollars = 0;
  const headlineCopy = useKeyValueData('prequalifyNowModule.home.headline');
  const headlineText = toFormattedText(headlineCopy, { zeroDollars });
  const { total, term } = useFinancing(BundleType.Bike);

  const pageType = PageType.BANNER;
  const componentName = 'prequalifyNowHomeModule';
  const productName = 'Generic';

  const trackPrequalComplete = useTrackAffirmPrequalComplete(componentName, productName);

  return (
    <StyledContainer>
      <StyledBody data-test-id="prequalify-homepage-module-heading">
        <StyledContent>
          <Body size="small" is="span" display="inline-block">
            {headlineText}
          </Body>
          <StyledAffirmLogo textColor={brand.darkest} swoopColor={brand.darkest} />
        </StyledContent>
        <StyledLink
          component={({ children, className }) => (
            <APRModalLink
              amount={total}
              term={term}
              bundleType={BundleType.Bike}
              pageType={pageType}
              className={className}
              onPrequalComplete={trackPrequalComplete}
              onClick={trackClick(productName, componentName)}
            >
              {() => <>{children}</>}
            </APRModalLink>
          )}
          variant="body"
          size="large"
        >
          <FormatCtaWithUrl id="prequalifyNow" />
        </StyledLink>
      </StyledBody>

      <Hairline />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  background-color: ${brand.light};
`;

const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0px;

  ${media.desktop`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 48px 0px;
`}
`;

const StyledContent = styled.div`
  padding: 8px 0x;

  ${media.desktop`
    padding: 0px;
  `}
`;

const StyledAffirmLogo = styled(AffirmLogo)`
  width: 50px;
  height: 18.2px;
  margin-left: 6px;

  ${media.desktop`
    margin-left: 6px;
  `}
`;

const StyledLink = styled(Link)`
  &:after {
    transform: translateY(-5px);
  }

  padding-top: 8px;

  ${media.desktop`
    padding-left: 24px;
    padding-top: 0px;
  `}
`;

const Hairline = styled.hr`
  border: 0;
  border-top: 1px solid ${grey[40]};
  margin: 0 32px;

  ${media.tablet`
    margin 0 auto;
    padding: 0;
    width: 460px;
  `};

  ${media.desktopLarge`
    width: 900px;
  `};
`;
