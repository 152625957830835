import { intersection } from 'ramda';
import { CP_MTM_SKU, STUDENT_MTM_SKU } from '@peloton/subscription-plans/skus';
import type { Verification } from '@ecomm/checkout/models';
import { CP_TRIAL_PATH, STUDENT_TRIAL_PATH } from './config';

const concessionarySkus = [CP_MTM_SKU, STUDENT_MTM_SKU];

export const shouldTrackConcessionary = (
  activeRoute: string,
  promotion: string,
  verification: Verification,
  skus: string = '',
): boolean => {
  const isConcessionaryRoute =
    activeRoute.includes(CP_TRIAL_PATH) || activeRoute.includes(STUDENT_TRIAL_PATH);
  const isConcessionarySku = intersection(skus.split(','), concessionarySkus).length > 0;
  if (
    !promotion &&
    verification &&
    verification.segment &&
    (isConcessionaryRoute || isConcessionarySku)
  ) {
    return true;
  }
  return false;
};
