import React, { useContext, useMemo, useEffect } from 'react';
import { GlobalReferenceContext } from '@acme-ui/global/GlobalReferenceProvider';
import { gradientFromScrollPosition, scrollRatio } from '@ecomm/header/utils';
import useScrollPosition from '@ecomm/hooks/useScrollPosition';
import { MainHeaderNav } from './HeaderNav';

type NavProps = {
  transparent: boolean;
  Headband?: React.FC<React.PropsWithChildren<unknown>>;
  sticky?: boolean;
};

const Nav: React.FC<React.PropsWithChildren<NavProps>> = ({
  transparent,
  Headband,
  sticky = true,
}) => {
  const { subHeader } = useContext(GlobalReferenceContext);
  const { position: currentPosition, setPosition } = useScrollPosition();
  // Some pages have a custom or dynamic headband that is stored in subHeader.
  const isStickyForReal = sticky && !subHeader;

  const mainNavStyle = useMemo(() => {
    const backgroundStyles = transparent
      ? { background: gradientFromScrollPosition(currentPosition) }
      : {};
    return {
      ...backgroundStyles,
    };
  }, [currentPosition, transparent]);

  // Account for nav position on refresh
  useEffect(() => {
    const initialLoadPosition = scrollRatio(window.pageYOffset);
    setPosition(initialLoadPosition);
  }, [setPosition]);

  return (
    <>
      <MainHeaderNav
        style={mainNavStyle}
        transparent={transparent}
        sticky={isStickyForReal}
      />
      {Headband && <Headband />}
    </>
  );
};

export default Nav;
