import { all, fork } from 'redux-saga/effects';
import { saga as getStartedSaga } from '@onewellness/pg-get-started';
import { sagas as ssoEnrollmentSagas } from '../pg-sso-enrollment';
import plansSaga from './getPlansSaga';
import lookupSaga from './lookupSaga';
import partnerLookupSaga from './partnerLookupSaga';
import redirectToLoginSaga from './redirectToLoginSaga';
import toggleBookmarkForClassSaga from './toggleBookmarkForClassSaga';
import trackOrderSaga from './trackOrderSaga';
import updateUsernameSaga from './updateUsernameSaga';
import userExistsSaga from './userExistsSaga';

export default function* sagas() {
  yield all([
    fork(getStartedSaga),
    fork(ssoEnrollmentSagas),
    fork(plansSaga),
    fork(lookupSaga),
    fork(partnerLookupSaga),
    fork(redirectToLoginSaga),
    fork(toggleBookmarkForClassSaga),
    fork(trackOrderSaga),
    fork(userExistsSaga),
    fork(updateUsernameSaga),
  ]);
}
