import React from 'react';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { Markdown } from '@peloton/copy';
import type { State as EnvState } from '@peloton/env';
import type { PeloLink } from '@peloton/external-links';
import { withExtHrefs } from '@peloton/external-links';
import { Links } from '@peloton/links';
import type { LegalText } from '@ecomm/copy';
import { useLegalTextData } from '@ecomm/copy';
import { useCopyWithFinancing } from '@ecomm/copy/helpers/useCopyWithFinancing';
import type { ReducerState as FeatureToggleState } from '@ecomm/feature-toggle';
import { inlineLinkStyles, TrackingLink } from '@ecomm/links';
import type { Money } from '@ecomm/models';
import { getDeviceMembershipPrice } from '@ecomm/subscription-plans';
import { Divider, StyledCopyDiv, StyledWrapper } from './LegalSectionStyles';

type EnvProps = { membershipPrice: Money };

type LegalSectionProps = {
  copyKey: LegalText;
  testId?: string;
};

type LinkProps = {
  tosLink: string;
  membershipLink: string;
  privacyLink: string;
};

export const ProductValueLegalSection: React.FC<
  React.PropsWithChildren<
    LegalSectionProps & LinkProps & EnvProps & RouteComponentProps<{ path: string }>
  >
> = ({
  copyKey,
  testId,
  tosLink,
  membershipLink,
  privacyLink,
  match: {
    params: { path },
  },
}) => {
  const { content, pricing } = useCopyWithFinancing(useLegalTextData, copyKey);

  const values = {
    tosLink: makeBlank(tosLink),
    membershipLink: makeBlank(membershipLink),
    privacyLink: makeBlank(privacyLink),
  };

  if (content === 'norender') {
    return null;
  }
  return (
    <StyledWrapper data-test-id={testId}>
      <StyledCopyDiv>
        <Markdown
          content={content}
          values={{
            ...pricing,
            ...values,
          }}
          markdown={{
            renderers: {
              link: LinkTemplate(copyKey, path),
              paragraph: 'p',
            },
          }}
        />
      </StyledCopyDiv>
      <Divider />
    </StyledWrapper>
  );
};

const withLinks = withExtHrefs({
  tosLink: Links.tos,
  membershipLink: Links.membershipTerms,
  privacyLink: Links.privacy,
});

const mapStateToProps = (state: EnvState & FeatureToggleState) => ({
  membershipPrice: getDeviceMembershipPrice(state),
});

export default connect(mapStateToProps)(withLinks(ProductValueLegalSection));

const makeBlank = (url: string) => {
  return `${url}|_blank`;
};

interface LinkTarget {
  children: string[];
  href: string;
}

// eslint-disable-next-line react/display-name
const LinkTemplate = (key: string, path: string | PeloLink) => ({
  href,
  children,
}: LinkTarget) => {
  const [url, target] = href.split('|');
  return (
    <StyledTrackingLink
      eventProps={{
        linkTo: url,
        page: path,
        parent: key,
        unitName: '',
      }}
      target={target || '_self'}
      to={url}
    >
      <span>{children}</span>
    </StyledTrackingLink>
  );
};

const StyledTrackingLink = styled(TrackingLink)<{ to: string }>`
  span {
    ${inlineLinkStyles}
  }
` as any;
