import type { Time } from '@peloton/time';
import { toTime } from '@peloton/time';
import type { ID } from '@ecomm/models';
import type { ReducerState } from '../redux';

export enum ActionType {
  Add = 'ecomm/deliveryDates/ADD',
}

export type DeliveryDate = string;

export type State = Record<ID, DeliveryDate[]>;

export const defaultState: State = {};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.Add:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default reducer;

export const addDates = (dates: State): AddDeliveryDatesAction => ({
  type: ActionType.Add,
  payload: dates,
});

type AddDeliveryDatesAction = {
  type: ActionType.Add;
  payload: State;
};

type Action = AddDeliveryDatesAction;

const getState = (state: ReducerState) =>
  state.shippingReservationPage.deliveryDates.data;

export const getDates = (state: ReducerState, id: ID, numDays: number): Time[] =>
  getState(state)
    [id].slice(0, numDays)
    .map(day => toTime(day));
