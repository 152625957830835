import { pathOr } from 'ramda';
import type { LegalText } from '../models.generated';
import { useLegalTextQuery } from '../queries/contentTypes/legalText.generated';

export const transformLegalTextData = (response: object): string => {
  const key = pathOr('undefined', ['data', 'legalText', 'key'], response);

  return pathOr(`Legal Text: ${key}`, ['data', 'legalText', 'text'], response);
};

const useLegalTextData = (id: LegalText): string => {
  const res = useLegalTextQuery({ variables: { id } });

  return transformLegalTextData(res);
};

export default useLegalTextData;
