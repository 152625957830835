import type { ProductStates } from './productState';

export type AvailabilityState = {
  state: ProductStates;
  code: number;
};

export const AVAILABLE = 400;
export const AVAILABLE_RESTRICTED = 300;
export const UNAVAILABLE_SHOP_SOLD_OUT = 201;
export const UNAVAILABLE_SHOP = 200;
export const UNAVAILABLE_SHOP_LEADCAPTURE = 101;
export const UNAVAILABLE_MARKETING = 100;
export const UNAVAILABLE_MARKETING_LEAD_CAPTURE = 101;
export const UNAVAILABLE = 0;

const toAvailabilityCheck = (predicate: (a: AvailabilityState) => boolean) => (
  availabilityState: AvailabilityState,
) => predicate(availabilityState);

export const isAvailableForAddToCart = toAvailabilityCheck(
  ({ code }) => code >= AVAILABLE,
);
export const isAddToCartRestricted = toAvailabilityCheck(
  ({ code }) => code >= AVAILABLE_RESTRICTED && code < AVAILABLE,
);
export const isAvailableForPurchase = toAvailabilityCheck(
  ({ code }) => code >= AVAILABLE_RESTRICTED,
);
export const isUnavailableForPurchase = toAvailabilityCheck(
  ({ code }) => code < AVAILABLE_RESTRICTED,
);
export const isAvailableForShopPages = toAvailabilityCheck(
  ({ code }) => code >= UNAVAILABLE_SHOP,
);
export const isAvailableForMarketingPages = toAvailabilityCheck(
  ({ code }) => code >= UNAVAILABLE_MARKETING,
);

export const isUnavailableMarketingLeadCapture = toAvailabilityCheck(
  ({ code }) => code == UNAVAILABLE_MARKETING_LEAD_CAPTURE,
);
