import type { Locale } from '@peloton/internationalize';
import { CONTENTFUL_TOKEN } from '@ecomm/contentful/accessToken';
import type { PublishedCopyBlob } from '@ecomm/copy/helpers';
import toBaseClientV3 from './toClientV3';

type Options = {
  locale: Locale;
  preview: boolean;
  publishedLocaleCopy: PublishedCopyBlob;
  tag: string;
};

const toClient = (opts: Options) =>
  toBaseClientV3({
    ...opts,
    token: CONTENTFUL_TOKEN,
    fetch,
  });

export default toClient;
