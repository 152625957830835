import {
  brand,
  limitedEdition,
  red,
  white,
  grey,
  black,
} from '@pelotoncycle/design-system';
import { shade } from 'polished';

export type Theme = keyof typeof THEMES;

const defaultTheme = {
  backgroundColor: brand.light,
  color: brand.darkest,
  emphasisColor: brand.primary,
  hoverColor: shade(0.95, brand.light),
};

export const THEMES = {
  lilac: {
    backgroundColor: limitedEdition.lilac,
    color: grey[90],
  },
  lime: {
    backgroundColor: limitedEdition.lime,
    color: grey[90],
  },
  rose: {
    backgroundColor: limitedEdition.rose,
    color: grey[90],
  },
  blue: {
    backgroundColor: limitedEdition.blue,
    color: white,
  },
  patina: {
    backgroundColor: limitedEdition.patina,
    color: white,
  },
  pelotonRed: {
    backgroundColor: limitedEdition.pelotonRed,
    color: white,
  },
  roseGold: {
    backgroundColor: limitedEdition.roseGold,
    color: black,
  },
  michiganBlue: {
    backgroundColor: limitedEdition.michiganBlue,
    color: white,
  },
  red: {
    backgroundColor: brand.primary,
    color: white,
    emphasisColor: white,
    hoverColor: red[80],
  },
  black: {
    backgroundColor: brand.dark,
    color: white,
    emphasisColor: white,
    hoverColor: grey[70],
  },
  white: {
    ...defaultTheme,
  },
  defaultTheme,
};
