import type { LocationChangeAction } from 'connected-react-router';
import { LOCATION_CHANGE } from 'connected-react-router';
import type { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { loadCart } from '@ecomm/cart';
import { matchCheckoutRoute } from '@ecomm/checkout/redux';
import {
  loadBikeWarranties,
  loadBikePlusWarranties,
  loadGuideWarranties,
  loadTreadPlusWarranties,
  loadTreadWarranties,
  loadRowWarranties,
  loadRefurbBikeWarranties,
  loadRefurbBikePlusWarranties,
} from '@ecomm/warranty';

export const initCheckoutPageSaga = function* (isFirstRendering: boolean) {
  yield all(
    ([
      put(loadBikeWarranties()),
      put(loadTreadWarranties()),
      put(loadBikePlusWarranties()),
      put(loadTreadPlusWarranties()),
      put(loadGuideWarranties()),
      put(loadRowWarranties()),
      put(loadRefurbBikeWarranties()),
      put(loadRefurbBikePlusWarranties()),
    ] as any[]).concat(isFirstRendering ? [] : [put(loadCart())]),
  );
};

export const checkCheckoutPageSaga = function* (
  action: LocationChangeAction,
): SagaIterator {
  const match = yield select(matchCheckoutRoute);
  if (match) {
    yield call(initCheckoutPageSaga, action.payload.isFirstRendering);
  }
};

const watcherSaga = function* () {
  yield takeEvery(LOCATION_CHANGE, checkCheckoutPageSaga);
};

export default watcherSaga;
