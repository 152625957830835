// This regex checks for:
// 1. An iframe that closes with /> or </iframe>
// 2. Has a title and src (in any order)
// 3. The src is either Spotify or Youtube
export const iframeRegex = new RegExp(
  /(?:<iframe.((?=.*title)(?=.*src).+(spotify|youtube)).*)(?:(?:\/>)|(?:>.*?<\/iframe>))/,
);

export const regexValidation = (value: string) => {
  const pattern = iframeRegex;
  if (!pattern.test(value)) {
    throw new Error(`Invalid Embed Code`);
  }
  return value;
};

export const nameToModuleId = (name: string) =>
  name
    .toLowerCase()
    .replace(/[^-\w\s]/g, '')
    .trim()
    .replace(/\s/g, '-');

/**
 * Replaces ' plus' in product name with '+' for display purposes.
 * e.g. Bike Plus -> Bike+
 * @param productName - Product Name string
 * @return Product Display Name
 */
export const toProductDisplayName = (productName: string) =>
  productName.replace(/ plus/i, '+');

/**
 * Checks the beginning of a string for 'https://' protocol, and adds if not present.
 * @param address URL string
 * @returns URL string forcing 'https://' protocol
 */
export const ensureHttpsAddress = (address: string) => {
  // Regular expression to check if the string starts with "http://" or "//"
  const httpRegex = /^(http:|\/\/)/;

  if (httpRegex.test(address)) {
    address = address.replace(httpRegex, 'https://');
  } else if (!address.startsWith('https://')) {
    address = 'https://' + address;
  }

  return address;
};

// Checks to find URLs within the string and wrap them in Markdown-style hyperlink syntax.
export const markdownifyUrls = (text: string) => {
  return text.replace(/(((https?:\/\/)|(www\.))[^\s]+)/g, '[$1]($1)');
};
