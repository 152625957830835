import { DRIFT_DEFAULT_INTERACTION_ID, toLocale } from '@peloton/internationalize';
import { adaExists, openAdaChat } from './ada';
import { driftExists, startDriftInteraction } from './drift';

export type ChatOpts = {
  driftInteractionId?: number;
  adaAnswerId?: string;
};

export const openChat = async (opts: ChatOpts = {}) => {
  try {
    const interactionId =
      opts.driftInteractionId ?? DRIFT_DEFAULT_INTERACTION_ID[toLocale()];
    if (driftExists() && interactionId) {
      startDriftInteraction(interactionId);
    } else if (adaExists()) {
      await openAdaChat(opts.adaAnswerId);
    } else {
      (window as any).$zopim.livechat.window.show();
    }
  } catch (e) {
    if (e instanceof TypeError) {
      console.warn('Chat has not been initialized'); // TODO: improve error reporting
    } else {
      throw e;
    }
  }
};
