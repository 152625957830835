import type { AxiosInstance } from 'axios';
import { useEffect, useState } from 'react';
import { useClient } from '@peloton/api/ClientContext';
import { DomainError } from '@peloton/domain-error';
import { useErrorReporter } from '@peloton/error-reporting';

const VERIFY_URL = '/ecomm/bazaar/auth';

export enum EmailVerificationState {
  LOADING = 0,
  SUCCESS = 1,
  FAILURE = 2,
}

type BVPayload = Record<string, string>;

export function useVerifyEmail(token?: string): EmailVerificationState {
  const [state, setState] = useState(EmailVerificationState.LOADING);
  const { errorReporter } = useErrorReporter();
  const client = useClient();
  useEffect(() => {
    const request = async (bvToken: string) => {
      try {
        await verifyEmail(client, getVerificationPayload(bvToken));
        setState(EmailVerificationState.SUCCESS);
      } catch (e: unknown) {
        errorReporter.reportError(new DomainError('Unable to verify email token', e));
        setState(EmailVerificationState.FAILURE);
      }
    };
    if (token) {
      request(token);
    }
  }, [client, token, errorReporter]);
  return state;
}

const verifyEmail = async (client: AxiosInstance, payload: BVPayload) =>
  await client.post(VERIFY_URL, payload);

const getVerificationPayload = (token: string) => ({ auth_token: token });
