import { useOauth } from '@peloton/auth/OauthProvider';
import type { QueryHookOptions } from '@ecomm/graphql/hooks';
import { getCountryCart } from '@ecomm/pg-checkout-commercetools/helpers/ct-cart-helper';
import { useMigrationStatus } from '@ecomm/pg-checkout-commercetools/utils/useMigrationStatus';
import { useGetShopCartJupiterQuery } from '@ecomm/shop-cart/graphql/queries/ShopCart.generated';

type Opts = {
  fetchPolicy?: QueryHookOptions<any, any>['fetchPolicy'];
  suspend?: boolean;
  key?: string;
};

const useGetCartItems = (opts?: Opts) => {
  const { isLoading: isAuthLoading } = useOauth();
  const { CTCartEnabled } = useMigrationStatus();
  const country = getCountryCart();

  const { data, loading, refetch, error } = useGetShopCartJupiterQuery({
    variables: { country: country, key: opts?.key },
    suspend: false,
    skip: !CTCartEnabled || isAuthLoading,
    notifyOnNetworkStatusChange: true,
    throwError: false,
    ...(opts ?? {}),
  });

  if (error) {
    return {
      data: undefined,
      shopCartResult: undefined,
      totalLineItemQuantity: 0,
      loading: false,
      refetchCart: refetch,
    };
  } else {
    return {
      data: data?.shopCart,
      shopCartResult: data,
      totalLineItemQuantity: data?.shopCart?.totalLineItemQuantity ?? 0,
      loading,
      refetchCart: refetch,
    };
  }
};

export default useGetCartItems;
