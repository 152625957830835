import { LOCATION_CHANGE } from 'connected-react-router';
import { throttle } from 'frame-throttle';
import type { SagaIterator } from 'redux-saga';
import { eventChannel } from 'redux-saga';
import { call, fork, put, race, select, take } from 'redux-saga/effects';
import { getUser } from '@peloton/auth';
import { setUserAttribute, setUserAttributeSaga } from '@peloton/split-testing';
import { getIsToggleActive } from '@ecomm/feature-toggle';
import { Actions as ModalActions, isOpen as isAModalOpen } from '@ecomm/modal';
import { isBikeOrTreadMarketingOrClassesPage } from '@ecomm/router';
import { toForkOrCancel } from '@ecomm/saga-utils';
import type { ModalTrigger } from '../modal-trigger';
import { toModalTrigger } from '../modal-trigger';
import type { ModalVariation } from '../redux';
import { openModal, clearOpenModal } from '../redux';
import { getDismissed, modalVariationFromPath } from '../selectors';

export const IDLE_TIMEOUT = 8 * 1000;
export const EXIT_INTENT_THRESHOLD = 15;
export const EXIT_INTENT_SCROLL_SPEED = -8; // px per ms

const checkRoute = function* (): SagaIterator {
  return yield select(isBikeOrTreadMarketingOrClassesPage);
};

const isDisabled = function* (): SagaIterator {
  const isModalOpen = yield select(isAModalOpen);
  const user = yield select(getUser);
  const activeSub = user && (user.hasDeviceSubscription || user.hasDigitalSubscription);

  const previouslyDismissed = yield select(getDismissed);
  // for testing
  const disabled = /DISABLE_PROMO_MODALS/.test(document.cookie);

  if (previouslyDismissed || disabled || activeSub || isModalOpen) {
    return true;
  }

  return false;
};

export const popModal = function* (modalTrigger?: ModalTrigger): SagaIterator {
  if (yield call(isDisabled)) {
    return;
  }

  const variationFromPath: ModalVariation = yield select(modalVariationFromPath);

  if (variationFromPath) {
    yield call(
      setUserAttributeSaga,
      setUserAttribute('bike-tread-marketing-timeout-reached', 'true'),
    );
    yield put(openModal(variationFromPath, modalTrigger));
  }
};

// Some versions of the Drift widget emit a `focusin` event on mount. This was starting the countdown
// for showing the promo modal, and then, on Android Chrome, the modal logs a new entry for
// Largest Contentful Paint. This throws off our performance stats, which hurts SEO, among other
// things. So we're ignoring `focusin`, as TalkBack doesn't seem to use it, but
// listening to it in the comprehensive activity array below, as VoiceOver fires _only_ `focusin` events,
// and we don't want a modal interrupting screen reader users while they navigate the page.
const EVENTS_TO_IGNORE_FOR_STARTING_INITIAL_IDLE_TIMER = [
  'focusin',
  'mousemove', // largest contentful paints are still logged after mousemove events. we ignore these for starting the idle timer
];

const COMPREHENSIVE_USER_ACTIVITY_EVENTS = [
  'focusin',
  'keydown',
  'mousedown',
  'mousemove',
  'scroll',
  'touchstart',
  'wheel',
];

const idleTimeoutChannel = () => {
  // A channel is something that we can connect an external event source (like a DOM event)
  // to redux-saga by "taking" from it
  return eventChannel(emitter => {
    function startIdleTimeout(duration: number) {
      return setTimeout(() => {
        const now = Date.now();
        const timeSinceLastEvent = now - lastEventTime;

        // If the time elapsed since the last event is less than our minimum timeout,
        // we recursively call startIdleTimeout with the time we need to wait until we
        // hit our minimum idle timeout (the difference between the minimum timeout and the
        // elapsed time since last event)
        if (timeSinceLastEvent < IDLE_TIMEOUT) {
          const remainingWaitTime = IDLE_TIMEOUT - timeSinceLastEvent;
          timeout = startIdleTimeout(remainingWaitTime);
        } else {
          emitter(true);
        }
      }, duration);
    }

    // Initialize timout with the max duration and set the last event time as the current time
    let timeout: NodeJS.Timeout;
    let lastEventTime: number;

    const eventListener = (e: Event) => {
      lastEventTime = Date.now();

      const eventShouldInitializeTimer = !EVENTS_TO_IGNORE_FOR_STARTING_INITIAL_IDLE_TIMER.includes(
        e.type,
      );

      if (!timeout && eventShouldInitializeTimer) {
        timeout = startIdleTimeout(IDLE_TIMEOUT);
      }
    };

    COMPREHENSIVE_USER_ACTIVITY_EVENTS.forEach(event =>
      document.addEventListener(event, eventListener),
    );
    return function cleanup() {
      clearTimeout(timeout);
      COMPREHENSIVE_USER_ACTIVITY_EVENTS.forEach(event =>
        document.removeEventListener(event, eventListener),
      );
    };
  });
};

const pageLeaveExitIntentChannel = () => {
  return eventChannel(emitter => {
    let hasNavigatedOutsideThreshold = false;

    const eventListener = (e: MouseEvent) => {
      if (e.clientY < EXIT_INTENT_THRESHOLD && hasNavigatedOutsideThreshold) {
        emitter(true);
      } else if (e.clientY > EXIT_INTENT_THRESHOLD) {
        hasNavigatedOutsideThreshold = true;
      }
    };

    document.addEventListener('mousemove', eventListener);
    return function cleanup() {
      document.removeEventListener('mousemove', eventListener);
    };
  });
};

const scrollSpeedExitIntentChannel = () => {
  return eventChannel(emitter => {
    let lastScrollPosition = window.scrollY;
    let lastScrollTime = Date.now();
    let scrollSpeed = 0;

    const eventListener = () => {
      const currentScrollPosition = window.scrollY;
      const now = Date.now();

      scrollSpeed = (currentScrollPosition - lastScrollPosition) / (now - lastScrollTime);

      lastScrollPosition = currentScrollPosition;
      lastScrollTime = now;

      // EXIT_INTENT_SCROLL_SPEED is negative because we're looking for the scroll position to be
      // decreasing, meaning that it is moving towards the top of the page
      if (scrollSpeed <= EXIT_INTENT_SCROLL_SPEED) {
        emitter(true);
      }
    };

    const throttledScrollListener = throttle(eventListener);

    window.addEventListener('scroll', throttledScrollListener);
    return function cleanup() {
      window.removeEventListener('scroll', throttledScrollListener);
    };
  });
};

const runModalTriggers = function* (): SagaIterator {
  if (yield call(isDisabled)) {
    return;
  }

  // on re-running the triggers, we want to make sure the state doesn't have an
  // already open modal.  this state can be caused by a trigger succeeding
  // but none of the modals being active for that specific page, then
  // when the user navigates to a new page with an active modal, it is
  // immediately displayed instead of waiting.
  yield put(clearOpenModal());

  const modalTriggerChannels = [
    idleTimeoutChannel(),
    pageLeaveExitIntentChannel(),
    scrollSpeedExitIntentChannel(),
  ];

  // Block until one of the trigger channels returns a value by turning all channels into "take" effects
  const [inactivity, pageLeave, scrollSpeed] = yield race(modalTriggerChannels.map(take));

  const modalTrigger = toModalTrigger({ inactivity, pageLeave, scrollSpeed });

  // Make sure that all channels call their cleanup functions to remove event listeners
  modalTriggerChannels.forEach(channel => channel.close());

  yield fork(popModal, modalTrigger);
};

const modalTriggerSaga = toForkOrCancel(
  [
    LOCATION_CHANGE,
    // when a non-promo modal closes, and we haven't
    // shown the promo modal, we want to restart the
    // timer if it has run out.
    ModalActions.Closed,
  ],
  checkRoute,
  runModalTriggers,
);

export const sagas = function* (): SagaIterator {
  const active = yield select(getIsToggleActive('freeTrialProductInterest'));
  if (active) {
    yield fork(modalTriggerSaga);
  }
};
