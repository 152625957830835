import type { UIState } from '@ecomm/models';
import { Status } from '@ecomm/models';
import type { Action } from './removeItem';
import { ActionType } from './removeItem';

export const initialState: UIState = { status: Status.Init };

export type State = UIState;

const reducer = (state: UIState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.RemoveItem:
    case ActionType.CartLoading:
      return { status: Status.Loading };
    case ActionType.RemoveItemSucceeded:
    case ActionType.CartReady:
      return { status: Status.Loaded };
    case ActionType.RemoveItemFailed:
      return {
        status: Status.Failed,
        exception: action.payload.exception,
      };
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  ui: State;
};
