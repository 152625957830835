import type { SagaIterator } from 'redux-saga';
import { call, put, take, getContext } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import { ActionTypes as AppActionTypes } from '@ecomm/app';
import { fetchShowrooms } from './api';
import { loadShowroomsSuccess, showroomsRequest } from './redux';
import { filterOutInvisibleShowrooms } from './utils';

export const fetchShowroomsSaga = function* (client: Client): SagaIterator {
  yield take(AppActionTypes.Init);

  yield put(showroomsRequest());

  try {
    const data = yield call(fetchShowrooms, client);
    const filteredData = filterOutInvisibleShowrooms(data);
    yield put(loadShowroomsSuccess(filteredData));
  } catch (err) {
    const error = new DomainError('Error fetching showrooms', err);
    yield put(reportError({ error }));
  }
};

const saga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield call(fetchShowroomsSaga, client);
};

export default saga;
