import { all, fork } from 'redux-saga/effects';
import paymentIntentSagas from './paymentIntents';
import type { SetupIntentOptions as TSetupIntentOptions } from './setupIntents';
import { createSetupIntentSaga } from './setupIntents';
import submitPaymentSagas from './submitPayment';

export default function* sagas() {
  yield all([fork(paymentIntentSagas), fork(submitPaymentSagas)]);
}

export { createSetupIntentSaga };
export type SetupIntentOptions = TSetupIntentOptions;
