import {
  Body,
  Button,
  Container,
  Eyebrow,
  Headline,
  Support,
  brand,
  grey,
  spacing,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { useLocale } from '@peloton/internationalize';
import { Panels, usePanelManagerContext } from '@acme-ui/global/PanelManagerProvider';
import Flyout from '@ecomm/flyout';
import type { TypeDateTrigger } from '@page-builder/lib/types';
import { isActiveDate } from '@page-builder/modules/Variation/DateTriggerBlock';
import { useDateTriggerContext } from './DateTriggerProvider';
import useDateTriggerEntries from './hooks/useDateTriggerEntries';

const DateTriggerPanel: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { activePanel, setActivePanel } = usePanelManagerContext();
  const { overrideToday, setOverrideToday, resetOverrideToday } = useDateTriggerContext();
  const { data: dateTriggers, error } = useDateTriggerEntries();
  const locale = useLocale();

  if (!dateTriggers || error) {
    return null;
  }

  const handleClick = (start: string) => {
    const startDate = new Date(start);
    // Ensure new override date (new start) and today arguments passed to isActiveDate util are not equal
    const date = addMinuteToDate(startDate);
    setOverrideToday(date);
  };

  const handleSubmit = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault();
    const date = new Date(e.target['date-trigger-input'].value);
    setOverrideToday(new Date(date));
  };

  return (
    <Flyout
      isOpen={activePanel === Panels.DateTrigger}
      ariaLabel={'Date Trigger'}
      backgroundColor="#ffffff"
      handleRequestClose={() => setActivePanel(undefined)}
    >
      <Container padding="50px 30px" width="320px">
        <Support>
          *Panel supported on Page Builder pages using Variation content model
        </Support>
        <StyledHeadline size="small">Date Triggers</StyledHeadline>
        <Container verticalPadding={`0 ${spacing[8]}`}>
          <Eyebrow size="small">
            Date:{' '}
            {overrideToday?.toLocaleDateString(undefined, {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </Eyebrow>
          <Eyebrow size="small">
            Time:{' '}
            {overrideToday?.toLocaleTimeString(undefined, {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </Eyebrow>
        </Container>
        <form onSubmit={handleSubmit}>
          <StyledTextInput name={'date-trigger-input'} type="datetime-local" />
          <Button
            size="small"
            type="submit"
            width="adaptive"
            style={{ marginBottom: '1rem' }}
          >
            Set Date
          </Button>
        </form>
        <Button size="small" onClick={resetOverrideToday} width="adaptive">
          Reset Date Trigger
        </Button>
        {dateTriggers
          .filter(filterDateTriggers)
          .map(({ fields: { start, end, name } }, i) => {
            const isActive = isActiveDate({ start, end, today: overrideToday });
            return (
              <DateTriggerWrapper
                className={isActive ? 'active' : ''}
                key={`promo-panel-${i}`}
              >
                <button
                  id={`${i}`}
                  type="button"
                  onClick={() => handleClick(start)}
                  name="activeDateTrigger"
                >
                  {isActive && <Eyebrow>ACTIVE</Eyebrow>}
                  <Body size="medium">{name}</Body>
                  <Support size="large">
                    Start: {formatDate(start, locale) || 'N/A'}
                  </Support>
                  <Support size="large">End: {formatDate(end, locale) || 'N/A'}</Support>
                </button>
              </DateTriggerWrapper>
            );
          })}
      </Container>
    </Flyout>
  );
};

export default DateTriggerPanel;

const addMinuteToDate = (date: Date) => {
  date.setMinutes(date.getMinutes() + 1);
  return date;
};

const filterDateTriggers = (item: TypeDateTrigger) => {
  return item.fields.name;
};

export const formatDate = (utcDate?: string, locale?: string) => {
  if (!utcDate) return undefined;

  const date = new Date(utcDate);
  return date.toLocaleString(`${locale}`);
};

const DateTriggerWrapper = styled.div`
  border: solid 1px ${grey[30]};
  border-radius: 10px;
  padding: 30px 15px;
  box-shadow: 2px 0px 20px 2px rgba(0, 0, 0, 0.08);
  margin-top: 15px;

  &.active {
    border: solid 3px ${brand.primary};
  }

  &:hover {
    box-shadow: 2px 0px 20px 2px rgba(0, 0, 0, 0.15);
  }
`;

const StyledHeadline = styled(Headline)`
  margin-bottom: 1rem;
`;

const StyledTextInput = styled.input`
  border: 1px solid ${grey[50]};
  border-radius: 8px;
  display: block;
  padding: 12px;
  margin-bottom: 1rem;
  width: 100%;
`;
