import type { ShowroomProps, LocatedShowroom } from '@ecomm/showrooms/model';

export type LatLng = {
  latitude: number;
  longitude: number;
};

type Coordinates = LatLng | Pick<GeolocationCoordinates, 'latitude' | 'longitude'>;

const MAXIMUM_MILE_RADIUS = 40;
export const haversineDistance = (coords1: Coordinates, coords2: Coordinates) => {
  const EarthR = 3958.8;
  const radLat1 = coords1.latitude * (Math.PI / 180);
  const radLat2 = coords2.latitude * (Math.PI / 180);
  const diffLat = radLat2 - radLat1;
  const diffLng = (coords2.longitude - coords1.longitude) * (Math.PI / 180);
  const diff =
    2 *
    EarthR *
    Math.asin(
      Math.sqrt(
        Math.sin(diffLat / 2) * Math.sin(diffLat / 2) +
          Math.cos(radLat1) *
            Math.cos(radLat2) *
            Math.sin(diffLng / 2) *
            Math.sin(diffLng / 2),
      ),
    );
  return diff;
};

export const visibleShowroomsFilter = (showroom: ShowroomProps) => showroom.isVisible;

export const locationFilter = (position: Coordinates) => (
  entityWithCordinates: Coordinates,
) => {
  const entityCoords = {
    latitude: entityWithCordinates.latitude,
    longitude: entityWithCordinates.longitude,
  };
  const diff = haversineDistance(position, entityCoords);
  return diff < MAXIMUM_MILE_RADIUS;
};

export const filterShowroomsByLocation = (
  showrooms: ShowroomProps[],
  locationData: LatLng | null,
): LocatedShowroom[] => {
  if (!locationData) {
    return [];
  }
  const nearestShowrooms = showrooms.filter(locationFilter(locationData));

  return nearestShowrooms.map(showroom => {
    return {
      ...showroom,
      distanceMilesToCustomer: haversineDistance(locationData, {
        latitude: showroom.latitude,
        longitude: showroom.longitude,
      }),
    };
  });
};

export const findNearestShowroom = (showrooms: LocatedShowroom[]): LocatedShowroom => {
  return showrooms.sort((a, b) => {
    return a.distanceMilesToCustomer - b.distanceMilesToCustomer;
  })[0];
};
