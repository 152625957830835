import React from 'react';
import useHasScrolledIntoView from './useHasScrolledIntoView';

type LazyBelowFoldContextType = {
  displayLazyBelowFold: boolean;
  isScrolledBelowFold: boolean;
  setIsScrolledBelowFold: React.Dispatch<React.SetStateAction<boolean>>;
};

const LazyBelowFoldContext = React.createContext<LazyBelowFoldContextType>({
  displayLazyBelowFold: false,
  isScrolledBelowFold: false,
  setIsScrolledBelowFold: () => {},
});

type LazyBelowFoldProviderProps = {
  hasBelowFold: boolean;
};

export const LazyBelowFoldProvider: React.FC<
  React.PropsWithChildren<LazyBelowFoldProviderProps>
> = ({ children, hasBelowFold }) => {
  const [isScrolledBelowFold, setIsScrolledBelowFold] = React.useState(false);
  const displayLazyBelowFold = isScrolledBelowFold || !hasBelowFold;
  return (
    <LazyBelowFoldContext.Provider
      value={{
        displayLazyBelowFold,
        isScrolledBelowFold,
        setIsScrolledBelowFold,
      }}
    >
      {children}
    </LazyBelowFoldContext.Provider>
  );
};

const useLazyBelowFold = () => React.useContext(LazyBelowFoldContext);

export const BelowFold: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const {
    hasMonitoredElementScrolledIntoView,
    setMonitoredElement,
    forceVisible,
  } = useHasScrolledIntoView({
    threshold: 0,
  });
  const { setIsScrolledBelowFold } = useLazyBelowFold();
  React.useEffect(() => {
    setIsScrolledBelowFold(hasMonitoredElementScrolledIntoView);
  }, [hasMonitoredElementScrolledIntoView]);
  React.useEffect(() => {
    if (location.hash) forceVisible();
  }, []);
  return <div ref={setMonitoredElement}>{children}</div>;
};

type LazyBelowFoldProps = {
  dataTestId?: string;
};

export const LazyBelowFold: React.FC<React.PropsWithChildren<LazyBelowFoldProps>> = ({
  children,
  dataTestId,
}) => {
  const { displayLazyBelowFold } = useLazyBelowFold();
  if (!displayLazyBelowFold) return null;
  return <div data-test-id={dataTestId}>{children}</div>;
};
