import { black, grey } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { track } from '@peloton/analytics';
import type { ExtLink } from '@peloton/external-links';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import { Links } from '@peloton/links';
import { media } from '@peloton/styles';
import { getUserTrackingProperties } from '@ecomm/auth';
import type { Image as ImageData } from '@ecomm/copy';
import { useImageData } from '@ecomm/copy';
import PerformantImage from '@ecomm/image/PerformantImage';
import type { LinkProps } from '@ecomm/links';
import { Link } from '@ecomm/links';
import { hb6, hb8 } from '@ecomm/typography';

type Props = {
  cardCount: number;
  id: ImageData;
  testId: string;
  url: ExtLink;
};

type StyleProps = {
  cardCount: number;
};

const imageSizes = {
  mobile: 120,
  smallScreen: 135,
  tablet: 155,
  tabletXLarge: 125,
  desktopLarge: 150,
};

const ProductBellyBandCard: React.FC<React.PropsWithChildren<Props>> = ({
  cardCount,
  id,
  url,
  testId,
}) => {
  const {
    image: {
      mobile: { url: imgSrc, description },
    },
  } = useImageData(id);

  const { isLoggedIn, hasDeviceSubscription, hasDigitalSubscription } = useSelector(
    getUserTrackingProperties,
  );

  return (
    <Card cardCount={cardCount} data-test-id={testId}>
      <StyledLink
        to={url}
        onClick={() =>
          track({
            event: 'Clicked Product Belly Band on HP',
            properties: {
              productName: description,
              page: getCurrentRelativePathname(),
              isLoggedIn,
              hasDeviceSubscription,
              hasDigitalSubscription,
            },
          })
        }
        target={url === Links.boutique ? '_blank' : ''}
      >
        <StyledPerformantImage
          src={imgSrc}
          alt=""
          breakpointOptions={{
            mobile: { width: imageSizes.mobile },
            smallScreen: { width: imageSizes.smallScreen },
            tablet: { width: imageSizes.tablet },
            desktopLarge: { width: imageSizes.desktopLarge },
          }}
        />
        <Label>{description}</Label>
      </StyledLink>
    </Card>
  );
};

export default ProductBellyBandCard;

const horizontalLayout = css`
  height: 80px;
  &:not(:last-of-type) {
    margin-right: 40px;
  }
  a {
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    padding: 5px;
    transform: translate3d(-25px, 0, 0);
    width: calc(100% + 25px);
  }
  img {
    position: relative;
    transform: none;
  }
  p {
    min-width: 120px;
  }
`;

const Card = styled.li`
  background-color: ${grey[20]};
  border-radius: 8px;
  box-shadow: 5px 3px 15px 0 ${rgba(black, 0.03)};
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 140px;
  transition: all 300ms ease;
  &:not(:last-of-type) {
    margin-right: 30px;
  }

  ${media.tabletXLarge`
    &:not(:last-of-type) {
      margin-right: 25px;
    }
  `}

  @media (min-width: 580px) and (max-width: 767px) {
    height: 170px;
  }

  ${media.desktop`
    min-width: 130px;
    max-width: 180px;

    &:hover {
      backface-visibility: hidden;
      box-shadow: 5px 3px 50px 0 ${rgba(black, 0.2)};
      cursor: pointer;
      transform: translate3d(0, 0, 0) scale(1.08);
      transition: all 300ms ease;
    }
  `}

  ${media.desktopLarge`
    height: 170px;
    min-width: 150px;

    &:not(:last-of-type) {
      margin-right: 40px;
    }
  `}

  ${(props: StyleProps) =>
    props.cardCount <= 3 &&
    css`
      ${media.tabletXLarge`
        ${horizontalLayout}
      `}

      ${media.desktop`
        max-width: 280px;
      `}
    `}
`;

const StyledLink = (styled(Link)`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  padding: 15px;
  position: relative;
  width: 100%;
` as any) as React.ComponentType<React.PropsWithChildren<LinkProps>>;

const StyledPerformantImage = styled(PerformantImage)`
  img {
    left: 0;
    max-width: ${imageSizes.mobile}px;
    position: absolute;
    top: 0;
    transform: translate3d(-15%, -7%, 0);
    width: 150%;

    ${media.smallScreen`
      max-width: ${imageSizes.smallScreen}px;
    `}

    ${media.tablet`
      max-width: ${imageSizes.tablet}px;
    `}
  
    ${media.tabletXLarge`
      max-width: ${imageSizes.tabletXLarge}px;
    `}
  
    ${media.desktopLarge`
      max-width: ${imageSizes.desktopLarge}px;
    `}
  }
`;

const Label = styled.p`
  ${hb8};
  color: ${grey[70]};
  font-size: 9px;

  @media (min-width: 360px) {
    font-size: 11px;
  }

  @media (min-width: 375px) {
    font-size: 12px;
  }

  ${media.desktopLarge`
    ${hb6}
  `}
`;
