import type { TypePolicy } from '@apollo/client';
import {
  ApolloClient as ApolloClientV3,
  InMemoryCache as InMemoryCacheV3,
  HttpLink as HttpLinkV3,
} from '@apollo/client';
import { USE_APOLLO_V3_ECOPY } from '@peloton/app-config';
// Since this is only a type it should be okay in this isomorphic code
import type NodeFetch from 'node-fetch';
import type { Locale } from '@peloton/internationalize';
import type { PublishedCopyBlob } from '@ecomm/copy/helpers';
import { URL, QUERY_TYPENAME_MAP } from './config';
import generatedFragmentType from './fragmentTypes.generatedV3';

const fetchRetry = require('@vercel/fetch-retry');

type Options = {
  locale: Locale;
  preview: boolean;
  publishedLocaleCopy?: PublishedCopyBlob;
  fetch: typeof fetch | typeof NodeFetch;
  token: string;
  tag: string;
};

const toTypePolicy = (
  field: string,
  map: typeof QUERY_TYPENAME_MAP,
): TypePolicy['fields'] => ({
  [field]: {
    read: (_, { args, toReference }) =>
      // TS error to be fixed during Apollo 3 migration
      // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
      // @ts-ignore
      toReference({ __typename: map[field], key: args?.id, url: args?.url }),
  },
});

const toQueryTypePolicies = (map: typeof QUERY_TYPENAME_MAP) =>
  Object.keys(map).reduce(
    (acc, curr) => ({
      ...acc,
      ...toTypePolicy(curr, map),
    }),
    {},
  );

const toTypePolicies = (map: typeof QUERY_TYPENAME_MAP) =>
  Object.values(map).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: {
        // @ts-expect-error
        keyFields: obj => (obj.key ? ['key'] : []),
      },
    }),
    {},
  );

export const toClientV3 = ({
  locale,
  preview,
  publishedLocaleCopy,
  tag,
  token,
  fetch,
}: Options) => {
  const link = new HttpLinkV3({
    uri: URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    fetch: fetchRetry(fetch),
  });

  const cache = new InMemoryCacheV3({
    possibleTypes: generatedFragmentType.possibleTypes,
    typePolicies: {
      Query: {
        fields: toQueryTypePolicies(QUERY_TYPENAME_MAP),
      },
      Asset: {
        keyFields: ['url'],
      },
      ...toTypePolicies(QUERY_TYPENAME_MAP),
    },
  });

  if (publishedLocaleCopy) {
    cache.restore(publishedLocaleCopy);
  }

  const client = new ApolloClientV3({
    cache,
    link,
    defaultOptions: {
      query: {
        variables: {
          locale,
          preview,
          tag,
        },
      },
    },
  });

  if (typeof window !== 'undefined' && USE_APOLLO_V3_ECOPY) {
    (window as any).__printCopy = () => client.extract();
  }

  return client;
};

export default toClientV3;
