import { pickBy } from 'ramda';
import type { Locale } from '@peloton/internationalize';
import type { PromoData } from '@ecomm/copy/helpers';
import type { Promo } from '@ecomm/copy/models.generated';
import type { ActiveExperiment } from '@ecomm/feature-toggle/homepageCarouselExperiment';

export const ORIGINAL_SUFFIX = '-original';
export const VARIATION_SUFFIX = '-variation';

const promoValidNow = (start: string, end?: string): boolean => {
  const today = new Date();
  const hasStarted = new Date(start).valueOf() < today.valueOf();
  const hasEnded = end && new Date(end).valueOf() < today.valueOf();

  return hasStarted && !hasEnded;
};

export const promoIsApplicable = (
  { start, end, validLocales }: PromoData,
  currentLocale: Locale,
) => {
  if (!start) return false;

  const promoValidInCurrentLocale = validLocales?.includes(currentLocale);

  if (!promoValidInCurrentLocale) return false;

  return promoValidNow(start, end);
};

export const isPromoPartOfAnExperiment = (key?: Promo) => {
  const lowercaseKey = key?.toLowerCase();

  return lowercaseKey
    ? lowercaseKey.endsWith(ORIGINAL_SUFFIX) || lowercaseKey.endsWith(VARIATION_SUFFIX)
    : false;
};

export const toPromoExperimentPair = (
  activePromo: PromoData | undefined,
  promos: PromoData[],
) => {
  if (!activePromo) return;

  if (!isPromoPartOfAnExperiment(activePromo?.key)) {
    return {
      original: activePromo,
      variation: undefined,
    };
  }

  const lowercasedKey = activePromo.key!.toLowerCase();

  const activeIsOriginal = lowercasedKey.endsWith(ORIGINAL_SUFFIX);

  const activeKeySuffix = activeIsOriginal ? ORIGINAL_SUFFIX : VARIATION_SUFFIX;
  const otherKeySuffix = activeIsOriginal ? VARIATION_SUFFIX : ORIGINAL_SUFFIX;
  const activeKeyPrefix = lowercasedKey.split(activeKeySuffix)[0];

  const otherPromo = promos.find(
    promo => promo.key?.toLowerCase() === `${activeKeyPrefix}${otherKeySuffix}`,
  );
  const originalPromo = activeIsOriginal ? activePromo : otherPromo;
  const variationPromo = !activeIsOriginal ? activePromo : otherPromo;

  return {
    original: originalPromo,
    variation: variationPromo,
  };
};

export const getPromoToMatchExperimentBucket = (
  promoPair: any,
  activeExperiment: ActiveExperiment,
) => {
  if (!promoPair) return;

  if (!isPromoPartOfAnExperiment(promoPair?.original?.key)) return promoPair.original;

  return activeExperiment.isOriginal ? promoPair.original : promoPair.variation;
};

export const getPopulatedPromoFields = (activePromo?: PromoData): Partial<PromoData> =>
  activePromo ? pickBy(val => !!val, activePromo) : {};
