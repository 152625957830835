import { call, takeEvery } from 'redux-saga/effects';
import { persist } from './persist';
import { Actions } from './redux';

export const acceptSaga = function* () {
  yield call(persist);
};

const sagas = function* () {
  yield takeEvery(Actions.Accept, acceptSaga);
};

export default sagas;
