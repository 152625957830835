import { hasExperimentAndVariation } from '@peloton/split-testing';

const CTA_PROMO_MODAL_EXPERIMENT = 'Modal with CTA';
const VARIATION_1 = 'Variation #1';
const VARIATION_2 = 'Variation #2';

export const showCTAPromoModal1 = hasExperimentAndVariation(
  CTA_PROMO_MODAL_EXPERIMENT,
  VARIATION_1,
);
export const showCTAPromoModal2 = hasExperimentAndVariation(
  CTA_PROMO_MODAL_EXPERIMENT,
  VARIATION_2,
);
