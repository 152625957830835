import React from 'react';

const IdMeSvg: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 220 43"
  >
    <g id="Page-1">
      <g id="_x30_1-Desktop" transform="translate(-302.000000, -2846.000000)">
        <g id="Verify-IDme" transform="translate(302.000000, 2846.000000)">
          <g id="Group-7">
            <path
              id="Background-Copy"
              className="st0"
              d="M3.8,0h212.5c2.1,0,3.8,1.7,3.8,3.8v35c0,2.1-1.7,3.8-3.8,3.8H3.8
					c-2.1,0-3.8-1.7-3.8-3.8v-35C0,1.7,1.7,0,3.8,0z"
            />
            <path
              id="Background-Copy_1_"
              className="st0"
              d="M3.8,0h212.5c2.1,0,3.8,1.7,3.8,3.8v35c0,2.1-1.7,3.8-3.8,3.8H3.8
					c-2.1,0-3.8-1.7-3.8-3.8v-35C0,1.7,1.7,0,3.8,0z"
            />
            <g id="Group-5-Copy-3" transform="translate(43.000000, 0.000000)">
              <polygon
                id="Fill-4"
                className="st1"
                points="0.1,42.5 0.8,42.5 0.8,0 0.1,0 					"
              />
            </g>
            <g className="st2">
              <path
                className="st3"
                d="M65.4,15.3h2.3L64.1,26h-2.5L58,15.3h2.3l2,6.4c0.1,0.4,0.2,0.8,0.3,1.3c0.1,0.5,0.2,0.8,0.2,1
						c0.1-0.4,0.2-1.2,0.5-2.4L65.4,15.3z"
              />
              <path
                className="st3"
                d="M72.6,26.1c-1.3,0-2.3-0.4-3.1-1.1c-0.7-0.7-1.1-1.8-1.1-3.1c0-1.4,0.3-2.4,1-3.2c0.7-0.8,1.6-1.1,2.8-1.1
						c1.2,0,2.1,0.3,2.7,1s1,1.6,1,2.7v1.1h-5.3c0,0.6,0.2,1.1,0.6,1.5s0.8,0.5,1.5,0.5c0.5,0,1-0.1,1.4-0.2s0.9-0.3,1.4-0.5v1.7
						c-0.4,0.2-0.8,0.3-1.3,0.4C73.8,26.1,73.3,26.1,72.6,26.1z M72.3,19.2c-0.5,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.4,0.7-0.5,1.3h3.1
						c0-0.6-0.2-1-0.4-1.3C73.1,19.4,72.8,19.2,72.3,19.2z"
              />
              <path
                className="st3"
                d="M82.3,17.7c0.3,0,0.6,0,0.8,0.1l-0.2,2.1c-0.2,0-0.4-0.1-0.7-0.1c-0.7,0-1.3,0.2-1.7,0.5
						c-0.4,0.4-0.6,0.9-0.6,1.5V26h-2.2v-8.2h1.7l0.3,1.4h0.1c0.3-0.5,0.6-0.8,1-1.1C81.4,17.8,81.8,17.7,82.3,17.7z"
              />
              <path
                className="st3"
                d="M84.5,15.7c0-0.7,0.4-1.1,1.2-1.1s1.2,0.4,1.2,1.1c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.9,0.3
						C84.9,16.8,84.5,16.4,84.5,15.7z M86.8,26h-2.2v-8.2h2.2V26z"
              />
              <path
                className="st3"
                d="M93.7,19.5h-1.9V26h-2.2v-6.5h-1.2v-1.1l1.2-0.6v-0.6c0-0.9,0.2-1.6,0.7-2s1.2-0.6,2.2-0.6
						c0.8,0,1.5,0.1,2.1,0.3l-0.6,1.6c-0.4-0.1-0.9-0.2-1.2-0.2c-0.3,0-0.5,0.1-0.7,0.3c-0.1,0.2-0.2,0.4-0.2,0.7v0.5h1.9V19.5z"
              />
              <path
                className="st3"
                d="M93.8,17.8h2.4l1.5,4.6c0.1,0.4,0.2,0.9,0.3,1.4h0c0.1-0.5,0.2-1,0.3-1.4l1.5-4.6h2.4L98.9,27
						c-0.3,0.9-0.8,1.5-1.4,1.9c-0.6,0.4-1.3,0.6-2.1,0.6c-0.4,0-0.8,0-1.1-0.1v-1.8c0.3,0.1,0.6,0.1,0.9,0.1c0.4,0,0.7-0.1,1-0.4
						c0.3-0.2,0.5-0.6,0.7-1.1l0.1-0.4L93.8,17.8z"
              />
              <path
                className="st3"
                d="M114.1,26l-0.6-2.9l-0.8-3.6h-0.1l-1.5,6.5h-2.4l-2.3-8.2h2.2l0.9,3.6c0.2,0.6,0.3,1.5,0.5,2.7h0
						c0-0.4,0.1-1,0.3-1.8l0.1-0.6l1-3.9h2.5l1,3.9c0,0.1,0,0.3,0.1,0.5c0,0.2,0.1,0.4,0.1,0.7c0,0.2,0.1,0.5,0.1,0.7
						c0,0.2,0,0.4,0.1,0.5h0c0-0.4,0.1-0.8,0.2-1.4c0.1-0.6,0.2-1,0.2-1.2l1-3.6h2.2l-2.4,8.2H114.1z"
              />
              <path
                className="st3"
                d="M120.1,15.7c0-0.7,0.4-1.1,1.2-1.1s1.2,0.4,1.2,1.1c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.9,0.3
						C120.5,16.8,120.1,16.4,120.1,15.7z M122.5,26h-2.2v-8.2h2.2V26z"
              />
              <path
                className="st3"
                d="M128.3,24.4c0.4,0,0.9-0.1,1.4-0.3v1.7c-0.6,0.2-1.2,0.4-2.1,0.4c-0.9,0-1.5-0.2-2-0.7
						c-0.4-0.5-0.6-1.1-0.6-2v-3.9H124v-0.9l1.2-0.7l0.6-1.7h1.4v1.7h2.3v1.7h-2.3v3.9c0,0.3,0.1,0.6,0.3,0.7
						C127.7,24.3,128,24.4,128.3,24.4z"
              />
              <path
                className="st3"
                d="M138.9,26h-2.2v-4.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0-1.1,0.2-1.4,0.6s-0.4,1.1-0.4,2.1V26h-2.2V14.6h2.2v2.3
						c0,0.2,0,0.6-0.1,1.3l-0.1,0.7h0.1c0.5-0.8,1.3-1.2,2.4-1.2c1,0,1.7,0.3,2.2,0.8c0.5,0.5,0.7,1.3,0.7,2.2V26z"
              />
            </g>
            <g id="idme-wallet-copy-2" transform="translate(147.000000, 12.000000)">
              <path
                id="Shape"
                className="st3"
                d="M2.1,0H1.3C0.4,0,0,0.3,0,0.9v15.2C0,16.7,0.4,17,1.3,17h0.7c0.9,0,1.3-0.3,1.3-0.9V0.9
						C3.4,0.3,2.9,0,2.1,0z"
              />
              <path
                id="Shape_1_"
                className="st3"
                d="M16.1,15.2c0-1.8,1.2-3.4,2.9-3.9c0.2-0.8,0.3-1.8,0.3-2.8C19.3,3,16.4,0,11.2,0H5.7
						C4.9,0,4.5,0.3,4.5,1.1v14.8c0,0.7,0.4,1.1,1.1,1.1h5.5c2,0,3.6-0.4,4.9-1.3C16.1,15.6,16.1,15.4,16.1,15.2z M11.2,13.6H8.7
						V3.4h2.5c3.2,0,3.9,2.8,3.9,5.1S14.4,13.6,11.2,13.6z"
              />
              <circle id="Oval" className="st3" cx="19.8" cy="15.3" r="1.7" />
              <path
                id="Shape_2_"
                className="st3"
                d="M46.2,12.4c-0.1,0-0.1,0-0.2,0c-0.3,0-0.4,0.1-0.6,0.3c-0.1,0.1-0.2,0.3-0.3,0.5
						c-0.3,0.6-0.7,1.2-1.2,1.6c-0.7,0.6-1.7,0.9-2.5,0.7c-0.5-0.1-0.8-0.5-1.1-0.7c-0.4-0.5-0.6-1.3-0.5-2.1
						c1.2-0.1,4.9-0.7,5.3-3.5c0.1-0.5-0.1-1.1-0.4-1.5c-0.4-0.5-1.2-0.8-2-0.8c-2.5,0-4.9,2.6-5.2,5.6c-0.1,0.8,0,1.6,0.3,2.3
						c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.1-0.5,0.1c-0.2-0.1-0.2-0.3-0.3-0.4c-0.1-0.5,0-1.2,0.2-1.8c0.1-0.4,0.2-0.8,0.3-1.1
						c0.3-1.1,0.6-2.2,0.5-3.3c-0.1-1-0.8-1.7-1.9-1.7c-1.5,0-2.5,1.1-3.1,2c0-0.7-0.2-1.2-0.5-1.5s-0.7-0.5-1.3-0.5
						c-1.5,0-2.4,1-3.1,1.9c0-0.1,0-0.2,0-0.3c0-0.4,0.1-0.9-0.3-1.3c-0.2-0.2-0.4-0.3-0.8-0.3c-0.3,0-0.4,0-0.7,0.1
						c0,0-0.3,0.1-0.4,0.2c-0.2,0.2-0.1,0.5-0.1,0.7c0,0,0,0,0,0.1c0,0.1,0,0.3,0,0.5c-0.1,1.1-0.3,2.2-0.4,3.1
						c-0.1,0.5-0.2,1-0.3,1.5c-0.3,1.1-0.5,2.3-0.6,3.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h0.1c0.8,0,1.6,0,1.8-0.5
						c0.2-0.5,0.3-1.1,0.4-1.7l0.1-0.3c0.2-1.3,0.4-2.2,0.9-3.3c0.2-0.6,0.6-1.1,0.9-1.5c0.4-0.5,0.8-1,1.3-1c0.2,0,0.3,0.1,0.4,0.2
						c0.4,0.6-0.1,2.6-0.4,3.5c-0.1,0.2-0.1,0.3-0.1,0.5l-0.2,0.7c-0.3,1-0.5,1.9-0.6,3c0,0.1,0,0.2,0,0.3v0.1l0.1,0.1
						c0.2,0.2,1.7-0.1,1.7-0.1c0.5-0.2,0.7-0.7,0.7-0.9c0.1-0.5,0.2-0.9,0.3-1.3v0c0.2-0.8,0.3-1.7,0.6-2.5c0.5-1.6,1.2-2.7,1.9-3.3
						c0.3-0.2,0.7-0.3,0.8-0.1c0.3,0.3,0.1,1.3,0,1.7c-0.1,0.5-0.2,1.1-0.4,1.7v0c-0.1,0.3-0.2,0.7-0.3,1.1
						c-0.2,1.1-0.4,2.4,0.2,3.2c0.3,0.4,0.8,0.6,1.4,0.6c0.7,0,1.3-0.2,2-0.7c0.2-0.1,0.4-0.3,0.6-0.5c0.8,0.9,1.6,1.3,2.9,1.3
						c2.8,0,4.1-1.9,4.9-3.3c0.1-0.2,0.2-0.4,0.3-0.6C46.5,12.8,46.4,12.5,46.2,12.4z M42.7,8.8c0,0.1,0,0.1,0,0.1s0,0.1,0,0.2
						c0,0.2-0.1,0.5-0.1,0.7c-0.4,1.3-1.5,1.6-2.5,1.6c0.1-0.6,0.3-1.1,0.6-1.6c0.5-1,1.1-1.6,1.6-1.6c0.1,0,0.1,0,0.1,0
						c0,0,0,0,0.1,0h0h0c0,0,0,0,0.1,0.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0.1l0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1v0
						C42.7,8.7,42.7,8.7,42.7,8.8L42.7,8.8z"
              />
            </g>
          </g>
          <g id="Group-15" transform="translate(11.000000, 11.000000)">
            <path
              id="Shape_3_"
              className="st3"
              d="M10.5,21C4.7,21,0,16.3,0,10.5S4.7,0,10.5,0c1.9,0,3.8,0.5,5.5,1.6c0.5,0.3,0.7,1,0.4,1.5
					c-0.3,0.5-1,0.7-1.5,0.4c-1.3-0.8-2.8-1.2-4.3-1.2c-4.6,0-8.3,3.7-8.3,8.3s3.7,8.3,8.3,8.3s8.3-3.7,8.3-8.3
					c0-0.6-0.1-1.2-0.3-1.9c-0.1-0.6,0.2-1.2,0.8-1.3c0.6-0.1,1.2,0.2,1.3,0.8c0.2,0.9,0.3,1.7,0.3,2.4C21,16.3,16.3,21,10.5,21z"
            />
            <path
              id="Path"
              className="st1"
              d="M10.5,13.7L21.7,2.9c0.5-0.4,0.5-1.1,0-1.6c-0.5-0.4-1.2-0.4-1.6,0l-10.3,10L7,8.7
					c-0.5-0.4-1.2-0.4-1.6,0c-0.5,0.4-0.5,1.1,0,1.6l3.5,3.4C9.1,13.9,9.4,14,9.7,14S10.3,13.9,10.5,13.7z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default IdMeSvg;
