import { useMemo } from 'react';
import { useLocale } from '@peloton/internationalize';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { useApr } from '@ecomm/pricing/helpers/useApr';
import useBundlePrice from '@ecomm/pricing/hooks/useBundlePrice';
import { getShippingCostByDevices } from '@ecomm/shipping-estimate/ShippingEstimate';
import type { BundleType } from '@ecomm/shop/models/BundleType';
import { getMembershipPriceForLocale } from '@ecomm/subscription-plans/utils';
import LocaleHasNoFinancingPartnerError from '../errors/LocaleHasNoFinancingPartnerError';
import type { Props as FinancingProps } from '../FinancingProps';
import { bundleTypeToFinancedDevice } from '../FinancingProps';
import { roundByFinancingPartner } from '../models/monthlyPaymentWithApr';
import { PartnerDisplayNameMap, Partners } from '../models/Partners';
import { defaultFinancingTerm } from '../models/term';
import useGetFinancingPartner from './useGetFinancingPartner';
import useGetSecondaryFinancingPartner from './useGetSecondaryFinancingPartner';

// Next compatible / non-redux rewrite of useFinancing

const useFinancingNext = (bundleType: BundleType): FinancingProps => {
  const isToggleActive = useIsToggleActive();
  const isCtPriceEnabled = isToggleActive('commerceToolsPricing');
  const devicePrice = useBundlePrice(bundleType, !!isCtPriceEnabled);
  const locale = useLocale();

  const partner = useGetFinancingPartner();
  const secondaryPartner = useGetSecondaryFinancingPartner();
  if (partner === Partners.None) {
    throw new LocaleHasNoFinancingPartnerError(
      'Cannot useFinancing if no financing partner',
    );
  }

  const isProjectPhoenixEnabled = isToggleActive('projectPhoenix');
  const isIncreasedAllAccessMembershipPriceEnabled = isToggleActive(
    'increasedAllAccessMembershipPrice',
  );
  const isZipThirtySixMonthsFinancingEnabled = isToggleActive(
    'zipThirtySixMonthFinancing',
  );
  const shippingPriceEstimate = isProjectPhoenixEnabled
    ? getShippingCostByDevices([bundleType])
    : 0;

  const term = defaultFinancingTerm(
    { partner, bundleType },
    isZipThirtySixMonthsFinancingEnabled,
  );
  const termNumber = Number(term);
  const apr = useApr(locale, bundleType, termNumber);

  return useMemo(() => {
    const financedDevice = bundleTypeToFinancedDevice(bundleType);
    const membershipPrice = getMembershipPriceForLocale(
      bundleType,
      locale,
      isIncreasedAllAccessMembershipPriceEnabled,
    );
    const total = devicePrice + shippingPriceEstimate;

    const monthly = roundByFinancingPartner(total, termNumber, apr, partner);

    return {
      bundleType,
      financedDevice,
      financingPartner: partner,
      secondaryFinancingPartner: secondaryPartner,
      financingPartnerDisplayName: PartnerDisplayNameMap[partner],
      membershipPrice,
      total,
      totalBeforeShipping: devicePrice,
      monthly,
      term,
      apr,
      shippingEstimate: shippingPriceEstimate,
    };
  }, [
    partner,
    secondaryPartner,
    shippingPriceEstimate,
    devicePrice,
    bundleType,
    locale,
    isIncreasedAllAccessMembershipPriceEnabled,
    term,
    termNumber,
    apr,
  ]);
};

export default useFinancingNext;
