import { Locale } from '@peloton/internationalize/models/locale';
import { Currency } from '@ecomm/graphql/types.generated';

const fromLocale = (locale: Locale): Currency =>
  ({
    [Locale.EnglishUnitedStates]: Currency.Usd,
    [Locale.EnglishUnitedKingdom]: Currency.Gbp,
    [Locale.EnglishCanada]: Currency.Cad,
    [Locale.GermanGermany]: Currency.Eur,
    [Locale.GermanAustria]: Currency.Eur,
    [Locale.EnglishAustralia]: Currency.Aud,
    [Locale.EnglishBeta]: Currency.Usd,
    [Locale.SpanishMexico]: Currency.Usd,
    [Locale.FrenchCanada]: Currency.Cad,
  }[locale]);

export default fromLocale;
