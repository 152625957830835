import type { SagaIterator } from 'redux-saga';
import { call, takeEvery } from 'redux-saga/effects';
import { Actions } from '../redux';
import { storeModalDismissed } from '../utils';

export const trackModalDismissed = function* (): SagaIterator {
  yield call(storeModalDismissed);
};

const watcher = function* () {
  yield takeEvery([Actions.CloseModal, Actions.ClickCTA], trackModalDismissed);
};

export default watcher;
