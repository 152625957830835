import { path, pickAll } from 'ramda';
import type { CtaWithUrl } from '../models.generated';
import { useCtaWithUrlQuery } from '../queries/contentTypes/ctaWithUrl.generated';
import type { CtaWithUrlData } from './types';

export const getLinkTargetPropsFromTargetBlank = (targetBlank: boolean) =>
  targetBlank ? { target: '_blank', rel: 'noopener noreferrer' } : {};

type CtaWithUrlResponse = CtaWithUrlData & {
  link: any;
};
export const transformCtaWithUrlData = (
  response: object,
  id?: CtaWithUrl,
): CtaWithUrlData => {
  const { ariaLabel, key = id, targetBlank, text, link }: CtaWithUrlResponse = pickAll(
    ['ariaLabel', 'key', 'targetBlank', 'text', 'link'],
    path(['data', 'ctaWithUrl'], response) || {},
  );

  const fallBackText = `CtaWithUrl: ${key}`;

  return {
    ariaLabel,
    key,
    targetBlank: !!targetBlank,
    text: text || fallBackText,
    url: link?.url || fallBackText,
  };
};

const useCtaWithUrlData = (id: CtaWithUrl): CtaWithUrlData => {
  const res = useCtaWithUrlQuery({ variables: { id } });

  return transformCtaWithUrlData(res, id);
};

export default useCtaWithUrlData;
