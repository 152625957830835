import { takeEvery, call } from 'redux-saga/effects';
import { submitSaga } from '@ecomm/financing/sagas/klarnaSaga';
import type { SubmitPayment } from '../redux';
import { Actions } from '../redux';

const handlePayment = function* ({ payload: { kind, order, cart } }: SubmitPayment) {
  yield call(submitSaga, kind, order.billing);
};

const sagas = function* () {
  yield takeEvery(Actions.SubmitPayment, handlePayment);
};

export default sagas;
