import { TLD } from '@peloton/app-config';
import { Locale } from '@peloton/internationalize/models/locale';
import { toStoreSlug } from './Store';
import { Store, toStore } from './WebStore';

export const getCatalog = (locale?: Locale) => {
  // to be able to build from .com with locales other than en-US
  if (!locale) return toStoreSlug();

  const localesTLD = {
    [Locale.EnglishUnitedStates]: Store.UnitedStates,
    [Locale.EnglishUnitedKingdom]: Store.UnitedKingdom,
    [Locale.EnglishCanada]: Store.Canada,
    [Locale.FrenchCanada]: Store.Canada,
    [Locale.GermanGermany]: Store.Germany,
    [Locale.EnglishAustralia]: Store.Australia,
    [Locale.GermanAustria]: Store.Austria,
  };

  // if TLD env is not set ex. for prospects app
  return localesTLD[locale] || toStore(TLD || '.com');
};
