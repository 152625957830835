import React from 'react';
import { AVAILABLE_LOCALES_FOR_ECOMM_INTERNATIONALIZE } from '@peloton/internationalize';
import type { Locale } from '@peloton/internationalize';

type Props = {
  children: (availableLocales: Locale[]) => React.ReactNode;
};

const AvailableLocales: React.FC<Props> = ({ children }) => {
  const availableLocales = toAvailableLocales();

  return <> {children(availableLocales)} </>;
};

const toAvailableLocales = (): Locale[] => [
  ...AVAILABLE_LOCALES_FOR_ECOMM_INTERNATIONALIZE,
];

export { toAvailableLocales };

export default AvailableLocales;
