import { useLocale } from '@peloton/internationalize';
import { BundleType } from '@ecomm/shop/models/BundleType';
import useIsToggleActive from './hooks/useIsToggleActive';
import type { ToggleId } from './models/ToggleId';
import type { OptimizelyToggleId } from './optimizely/features';

export const BikePlusPromoPriceMap = {
  'en-US': 35000,
  'en-CA': 45000,
  'en-GB': 30000,
  'en-AU': 50000,
};

export const BikePlusPromoFinancingMap = {
  'en-US': 5000,
  'en-CA': 6700,
  'en-GB': 4700,
  'en-AU': 7500,
};

export const BikePlusPromoMap = {
  boxingDayBikePlusPromo: {
    'en-US': {
      discount: 30000,
      financing: 5200,
    },
    'en-CA': {
      discount: 35000,
      financing: 6900,
    },
    'en-GB': {
      discount: 27000,
      financing: 4800,
    },
    'en-AU': {
      discount: 45000,
      financing: 7600,
    },
  },
};

export type BikePlusPromo = {
  isPromo: boolean;
  discount: number;
  monthlyPromo: number;
};

export const useBoxingDayBikePlusPromo = (bundleType: BundleType): BikePlusPromo => {
  const isBoxingDayBikePlusPromo = useIsToggleActive()('boxingDayBikePlusPromo');
  const locale = useLocale();

  if (
    bundleType === BundleType.BikePlus &&
    isBoxingDayBikePlusPromo &&
    BikePlusPromoPriceMap[locale]
  ) {
    return {
      isPromo: true,
      discount: BikePlusPromoPriceMap[locale],
      monthlyPromo: BikePlusPromoFinancingMap[locale],
    };
  }

  return {
    isPromo: false,
    discount: 0,
    monthlyPromo: 0,
  };
};

export const useBikePlusPromo = (
  bundleType: BundleType,
  toggle: ToggleId | OptimizelyToggleId,
): BikePlusPromo => {
  const isBikePlusPromo = useIsToggleActive()(toggle);
  const locale = useLocale();

  if (
    bundleType === BundleType.BikePlus &&
    isBikePlusPromo &&
    BikePlusPromoMap[toggle] &&
    BikePlusPromoMap[toggle][locale]
  ) {
    const promo = BikePlusPromoMap[toggle][locale];

    return {
      isPromo: true,
      discount: promo.discount,
      monthlyPromo: promo.financing,
    };
  }

  return {
    isPromo: false,
    discount: 0,
    monthlyPromo: 0,
  };
};
