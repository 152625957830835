import { brand, grey } from '@pelotoncycle/design-system';
import { white } from '@ecomm/colors';

export type ThemeProps = keyof typeof THEMES;

export const THEMES = {
  offBlack: {
    backgroundColor: brand.darkest,
    buttonColor: white,
    finePrintColor: grey[40],
    headerColor: white,
  },
  darkGray: {
    backgroundColor: brand.dark,
    buttonColor: white,
    finePrintColor: grey[40],
    headerColor: white,
  },
  offWhite: {
    backgroundColor: brand.light,
    buttonColor: brand.darkest,
    finePrintColor: grey[70],
    headerColor: brand.darkest,
  },
  white: {
    backgroundColor: white,
    buttonColor: brand.darkest,
    finePrintColor: grey[70],
    headerColor: brand.darkest,
  },
};
