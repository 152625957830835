import { normalize } from 'normalizr';
import { pathOr } from 'ramda';
import type { Entities } from '@ecomm/models';
import type { Subscription } from '../models';
import subscriptions from './schemas';
import type { ApiSubscriptions } from './types';

const mapper = (data: ApiSubscriptions): Entities<Subscription> =>
  pathOr({}, ['entities', 'subscriptions'], normalize(data, subscriptions));

export default mapper;
