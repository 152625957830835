import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';
import { createI18nMatchSelector } from '@ecomm/saga-utils';

const Routes = ['/offers/:segment', '/offers'].map(path => (
  <Route
    key={path}
    path={path}
    exact
    component={asyncComponent(
      () => import('./Handler' /* webpackChunkName: "ConcessionaryPricing" */),
    )}
  />
));
export default Routes;

export const matchConcessionaryPricing = createI18nMatchSelector('/offers');
