import { propEq } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { call, select, takeEvery } from 'redux-saga/effects';
import { Links } from '@peloton/links';
import { toCheckRouteSaga, toRedirectSaga } from '@ecomm/saga-utils';
import { hasActiveSub } from '@ecomm/user-subscriptions';
import { ActionTypes as UserSubscriptionActionTypes } from '@ecomm/user-subscriptions/redux';
import { matchRegisterFreeTrial as matcher } from '../route';

export const redirectToClassesSaga = toRedirectSaga({ link: Links.classes });

export const redirectActive = function* (): SagaIterator {
  const activeSub = yield select(hasActiveSub);
  if (activeSub) {
    yield call(redirectToClassesSaga);
  }
};

export const checkRouteAndRedirectActive = toCheckRouteSaga({
  matcher,
  onMatch: redirectActive,
  predicate: propEq('isExact', true),
});

const watcher = function* () {
  yield takeEvery(UserSubscriptionActionTypes.Success, checkRouteAndRedirectActive);
};

export default watcher;
