import { Container, Eyebrow, Icon, white, red } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { useErrorReporter } from '@peloton/error-reporting';
import { media } from '@peloton/styles';
import useCartWithVariables from '@ecomm/cart-next/hooks/useCartWithVariables';

const CartIconMonolith: React.FC<React.PropsWithChildren<{ hideCount: boolean }>> = ({
  hideCount,
}) => {
  const { errorReporter } = useErrorReporter();
  const numberOfItems =
    useCartWithVariables({
      throwError: false,
      reportSwallowedError: errorReporter.reportError,
      fetchPolicy: 'network-only',
    }).data?.cart?.numberOfItems ?? 0;

  return (
    <StyledContainer data-test-id="legacy-cart-icon">
      <Icon name="cart" primaryColor={white} title="Cart-l10n" />
      {!hideCount && numberOfItems ? (
        <ItemCountBadge
          is="span"
          size="small"
          display="flex"
          textColor={white}
          data-test-id="cart-icon-count"
        >
          {numberOfItems}
        </ItemCountBadge>
      ) : null}
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  position: relative;
  width: auto;
  svg {
    fill: none;
    display: block;
  }
`;

const ItemCountBadge = styled(Eyebrow)`
  background: ${red[80]};
  height: 17px;
  width: 17px;
  border-radius: 50%;
  place-content: center;
  align-items: center;
  position: absolute;
  left: 20px;
  top: -3px;

  ${media.tabletXLarge`
    width: 20px;
    height: 20px;
  `}
`;

export default CartIconMonolith;
