import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { ID } from '@ecomm/models';

export enum ActionType {
  OrderRequest = 'ecomm/order/FETCH_ORDER',
  OrderSuccess = 'ecomm/order/FETCH_ORDER_SUCCESS',
  OrderFailure = 'ecomm/order/FETCH_ORDER_FAILURE',
}

export enum Status {
  Init = 'initial',
  Loading = 'loading',
  Loaded = 'loaded',
  Failed = 'failed',
}

type InitialState = { status: Status.Init };
type LoadingState = { status: Status.Loading };
type LoadedState = { status: Status.Loaded };
type FailedState = { status: Status.Failed; exception: Exception };

export type State = InitialState | LoadingState | LoadedState | FailedState;

export const initialState: InitialState = { status: Status.Init };

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.OrderRequest:
      return { status: Status.Loading };
    case ActionType.OrderSuccess:
      return { status: Status.Loaded };
    case ActionType.OrderFailure:
      return {
        status: Status.Failed,
        exception: action.payload.exception,
      };
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  ui: State;
};

export const requestOrderFetch = (id: ID): RequestOrderAction => ({
  type: ActionType.OrderRequest,
  payload: { id },
});

export type RequestOrderAction = {
  type: ActionType.OrderRequest;
  payload: { id: ID };
};

export const orderFetchSuccess = (): RequestOrderSuccessAction => ({
  type: ActionType.OrderSuccess,
});

export type RequestOrderSuccessAction = {
  type: ActionType.OrderSuccess;
};

export const orderFetchFailed = (errorId: string): RequestOrderFailedAction => ({
  type: ActionType.OrderFailure,
  payload: { exception: toException(errorId) },
});

export type RequestOrderFailedAction = {
  type: ActionType.OrderFailure;
  payload: { exception: Exception };
};

type Action = RequestOrderAction | RequestOrderSuccessAction | RequestOrderFailedAction;
