import type { SagaIterator } from 'redux-saga';
import { call, select, takeEvery } from 'redux-saga/effects';
import { track } from '@peloton/analytics';
import { getCartId, getShippingMethod } from '@ecomm/cart';
import type { SelectPaymentMethodAction } from '@ecomm/checkout/redux';
import { PaymentActions } from '@ecomm/checkout/redux';
import { PaymentMethod } from '@ecomm/models';

type Event = {
  checkoutId: string;
  orderId: string;
  paymentMethod: string;
  shippingMethod: string;
  step: number;
};

export const trackPaymentInfoEnteredSaga = function* ({
  payload: { paymentMethod },
}: SelectPaymentMethodAction): SagaIterator {
  if (paymentMethod === PaymentMethod.Unknown) {
    return;
  }

  const cartId = yield select(getCartId);
  const shippingMethod = yield select(getShippingMethod);

  const event = toEvent({
    paymentMethod,
    checkoutId: cartId,
    shippingMethod,
  });

  yield call(track, event);
};

const watcherSaga = function* () {
  yield takeEvery(PaymentActions.SelectPaymentMethod, trackPaymentInfoEnteredSaga);
};

export default watcherSaga;

const toEvent = (properties: Partial<Event>) => ({
  event: 'Payment Info Entered',
  properties: {
    step: 3,
    ...properties,
  },
});
