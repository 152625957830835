import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { UIState } from '@ecomm/models';
import { Status, toUIState } from '@ecomm/models';

export enum ActionTypes {
  FetchConnectionNameRequested = 'onewellness/pg-sso-enrollment/ui/FETCH_CONNECTION_NAME_REQUESTED',
  FetchConnectionNameSucceeded = 'onewellness/pg-sso-enrollment/ui/FETCH_CONNECTION_NAME_SUCCEEDED',
  FetchConnectionNameFailed = 'onewellness/pg-sso-enrollment/ui/FETCH_CONNECTION_NAME_FAILED',
}

export type State = UIState & {
  name?: string;
};

export const defaultState: State = {
  ...toUIState(Status.Init),
};

const reducer = (state: State = defaultState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.FetchConnectionNameRequested:
      return {
        ...toUIState(Status.Loading),
        partnerSlug: action.payload.partnerSlug,
      };
    case ActionTypes.FetchConnectionNameSucceeded:
      return {
        ...state,
        ...toUIState(Status.Loaded),
        name: action.payload.connection,
      };

    case ActionTypes.FetchConnectionNameFailed: {
      const { exception } = action.payload;
      return {
        ...state,
        ...toUIState(Status.Failed, exception),
      };
    }
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  connection: State;
};

export const fetchConnectionName = (
  partnerSlug: string,
): FetchConnectionNameRequestAction => ({
  type: ActionTypes.FetchConnectionNameRequested,
  payload: {
    partnerSlug,
  },
});

export const fetchConnectionNameSucceeded = (
  connection: string,
): FetchConnectionNameSuccessAction => ({
  type: ActionTypes.FetchConnectionNameSucceeded,
  payload: {
    connection,
  },
});

export const fetchConnectionNameFailed = (
  errorId: string = 'corporateBenefitsVerification.errors.fetchConnectionName',
  exception: any | undefined = undefined,
): FetchConnectionNameFailedAction => ({
  type: ActionTypes.FetchConnectionNameFailed,
  payload: { exception: { ...exception, ...toException(errorId) } },
});

export type FetchConnectionNameRequestAction = {
  type: ActionTypes.FetchConnectionNameRequested;
  payload: {
    partnerSlug: string;
  };
};
type FetchConnectionNameSuccessAction = {
  type: ActionTypes.FetchConnectionNameSucceeded;
  payload: {
    connection: string;
  };
};
type FetchConnectionNameFailedAction = {
  type: ActionTypes.FetchConnectionNameFailed;
  payload: { exception: Exception };
};

type Actions =
  | FetchConnectionNameRequestAction
  | FetchConnectionNameSuccessAction
  | FetchConnectionNameFailedAction;
