import { useMemo } from 'react';
import { useLocale } from '@peloton/internationalize';
import usePromosGroupedByProduct from '@content/client/hooks/usePromosGroupedByProduct';
import toBasicsPackage from '@ecomm/shop/localizedBasicPackagesMap';
import { BundleType } from '@ecomm/shop/models/BundleType';

type BundleTypeDiscountMap = Record<BundleType, number>;

const ALL_BUNDLE_TYPES = Object.values(BundleType);
export const EMPTY_DISCOUNTS_BY_BUNDLE_TYPE = ALL_BUNDLE_TYPES.reduce(
  (discountsMap, bundleType) => ({
    ...discountsMap,
    [bundleType]: 0,
  }),
  {},
) as BundleTypeDiscountMap;

const useDiscountsByBundleType = (): BundleTypeDiscountMap => {
  const locale = useLocale();
  const groupedPromos = usePromosGroupedByProduct();

  const discountsByBundleType = useMemo<BundleTypeDiscountMap>(() => {
    if (!groupedPromos.data) {
      return EMPTY_DISCOUNTS_BY_BUNDLE_TYPE;
    }

    const discountsByBundleTypeMap = {
      ...EMPTY_DISCOUNTS_BY_BUNDLE_TYPE,
    };

    for (const bundleType of ALL_BUNDLE_TYPES) {
      const basicsSlug = toBasicsPackage(bundleType, locale);
      const cfuPromo = groupedPromos.data[bundleType];
      const basicsPromo = cfuPromo?.fields.bundles.find(
        bundlePromo => bundlePromo.fields.slug === basicsSlug,
      );

      discountsByBundleTypeMap[bundleType] =
        (basicsPromo?.fields.couponDiscount || 0) * 100;
    }

    return discountsByBundleTypeMap;
  }, [groupedPromos.data, locale]);

  return discountsByBundleType;
};

export default useDiscountsByBundleType;
