import { useState, useEffect } from 'react';

const setSessionHideSignup = (value: 'true' | 'false') => {
  if (window) {
    window.sessionStorage.setItem('hideSignup', value);
  }
};

export const useHideSignup = () => {
  const [shouldHideSignup, setShouldHideSignup] = useState(false);

  useEffect(() => {
    const search = window.location.search;
    if (
      search.toLowerCase().includes('utm_source=android_app') ||
      search.toLowerCase().includes('utm_source=ios_app')
    ) {
      setShouldHideSignup(true);
      setSessionHideSignup('true');
    } else {
      const sessionHideSignup = window.sessionStorage.getItem('hideSignup');
      if (sessionHideSignup === 'true') {
        setShouldHideSignup(true);
      }
    }
  }, []);
  return {
    shouldHideSignup,
  };
};
