import { gql } from '@apollo/client';
import type { ApolloClient } from 'apollo-client';
import { toData } from './utils';

export const enrollWithJWT = (client: ApolloClient<any>, jwt: string) =>
  client
    .mutate({
      mutation: ENROLL_WITH_JWT,
      variables: { jwt },
    })
    .then(toData);

const ENROLL_WITH_JWT = gql`
  mutation EnrollWithJwt($jwt: String!) {
    enrollWithJwt(jwtToken: $jwt) {
      accessToken
    }
  }
`;
