import {
  Eyebrow,
  Media,
  Support,
  spacing,
  Flex,
  grey,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import type { CtaWithUrlSetData } from '@ecomm/copy/helpers/types';
import { Link } from '@ecomm/internationalize-ui';

const LinkList: React.FC<
  React.PropsWithChildren<{ ctas: CtaWithUrlSetData; footerData?: boolean }>
> = ({ ctas, footerData }) => (
  <>
    {footerData ? (
      <StyledEyebrow size="small" textColor={grey[80]}>
        {ctas.title}
      </StyledEyebrow>
    ) : (
      <Eyebrow size="small" textColor={grey[80]}>
        {ctas.title}
      </Eyebrow>
    )}
    {ctas.ctas.map(cta => {
      const linkTarget = cta.targetBlank ? '_blank' : '_self';

      return ctas.key.includes('collections') ? (
        <StyledLink
          key={cta.key}
          to={cta.url}
          href={cta.url}
          target={linkTarget}
          variant="body"
        >
          <Flex flexDirection="row" alignItems="center">
            <StyledMedia
              media={{
                alt: cta?.image?.title ?? '',
                mobile: cta?.image?.url ?? '',
                type: 'image',
              }}
              objectFit="contain"
              height={spacing[88]}
              width={spacing[88]}
            />
            <Support key={cta.key} size="large">
              {cta.text}
            </Support>
          </Flex>
        </StyledLink>
      ) : (
        <StyledLink
          key={cta.key}
          target={linkTarget}
          to={cta.url}
          href={cta.url}
          variant="body"
        >
          <Support key={cta.key} size="large">
            {cta.text}
          </Support>
        </StyledLink>
      );
    })}
  </>
);

export default LinkList;

const StyledLink = styled(Link)`
  /* The following CSS styles are added to remove the underline on the link generated from the underlying DS Link component */
  &::after {
    display: none;
  }
`;
const StyledMedia = styled(Media)`
  margin-right: ${spacing[24]};
  picture > img {
    width: ${spacing[88]};
    height: ${spacing[88]};
    border-radius: ${spacing[4]};
  }
`;

const StyledEyebrow = styled(Eyebrow)`
  padding-top: ${spacing[4]};
`;
