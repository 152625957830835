import { compose, propOr, prop } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { call, select, put, takeEvery, getContext } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { DomainError, ErrorType } from '@peloton/domain-error';
import { selectStripeAccountKey, selectVendorKeys } from '@ecomm/vendor-keys';
import { createPaymentIntent, confirmPaymentIntent } from '../api';
import type { PreparePayments, FinalizePayments } from '../redux';
import {
  Actions,
  preparePaymentsSuccess,
  preparePaymentsFailure,
  finalizePaymentsSuccess,
  finalizePaymentsFailure,
  stripePayment,
} from '../redux';
import { PaymentErrorMap } from '../stripe';

const preparePaymentIntent = function* (
  client: Client,
  { payload: { gateway, paymentInfo, billingDetails } }: PreparePayments,
) {
  const source: stripe.PaymentMethodResponse = yield call(
    gateway.createPaymentMethod,
    'card',
    { billing_details: { ...billingDetails } },
  );

  if (source.error) {
    const error = new DomainError(prop(source.error.code || 'default', PaymentErrorMap), {
      name: ErrorType.SCA,
    });

    yield put(preparePaymentsFailure(error));
  } else if (source.paymentMethod) {
    yield put(
      preparePaymentsSuccess({
        kind: 'stripePaymentIntent',
        paymentMethod: source.paymentMethod,
        paymentGateway: gateway,
      }),
    );
  }
};

export const finalizePaymentIntent = function* (
  client: Client,
  { payload: { kind, paymentInfo, captchaToken, captchaVersion } }: FinalizePayments,
): SagaIterator {
  const stripeAccount = yield select(compose(selectStripeAccountKey, selectVendorKeys));

  const payment = yield select(stripePayment);
  try {
    const intent = yield call(createPaymentIntent, client, {
      paymentMethod: payment.paymentMethod.id,
      amount: paymentInfo.amount,
      currency: paymentInfo.currency,
      country: payment.paymentMethod?.billing_details?.address?.country,
      captchaToken,
      captchaVersion,
    });

    const { status, clientKey } = yield call(confirmPaymentIntent, client, {
      paymentIntentId: intent.id,
      stripeAccount,
    });

    if (status === 'requires_source_action') {
      // displays 3d secure challenge
      const { error } = yield call(payment.paymentGateway.handleCardAction, clientKey);

      if (error) {
        throw new DomainError(propOr('default', error.code, PaymentErrorMap), {
          ...error,
          name: ErrorType.SCA,
        });
      } else {
        // reconfirm intent after
        yield call(confirmPaymentIntent, client, {
          paymentIntentId: intent.id,
          stripeAccount,
        });
        yield put(
          finalizePaymentsSuccess({
            kind: 'stripePaymentIntent',
            id: intent.id,
          }),
        );
      }
    } else {
      yield put(
        finalizePaymentsSuccess({
          kind: 'stripePaymentIntent',
          id: intent.id,
        }),
      );
    }
  } catch (error) {
    yield put(finalizePaymentsFailure(error));
  }
};

const sagas = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(Actions.PreparePayments, preparePaymentIntent, client);
  yield takeEvery(Actions.FinalizePayments, finalizePaymentIntent, client);
};

export default sagas;
