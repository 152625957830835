import {
  getClass,
  getClassesByInstructor,
  getUIState as getClassesUIState,
} from './classes';
import {
  getInstructor,
  getInstructors,
  getInstructorIdsByFilter,
  getInstructorIdsByDiscipline,
  getInstructorsUserNames,
  getInstructorByUsername,
  getUIState as getInstructorsUIState,
} from './instructors';
import type { ReducerState as TReducerState } from './redux';
import reducer from './redux';

export {
  getClass,
  getClassesByInstructor,
  getClassesUIState,
  getInstructor,
  getInstructors,
  getInstructorByUsername,
  getInstructorIdsByFilter,
  getInstructorIdsByDiscipline,
  getInstructorsUIState,
  getInstructorsUserNames,
};

export default reducer;

export type ReducerState = TReducerState;
