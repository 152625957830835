import {
  hasExperimentAndVariation,
  hasExperimentAndVariationWithoutControl,
} from '@peloton/split-testing';

const FINANCING_LD_STRIKETHROUGH_COMBO_EXPERIMENT = 'Financing LD Strikethrough Combo';
const FINANCING_LD_COMBO_VARIATION_1 = 'Variation #1';
const FINANCING_LD_COMBO_VARIATION_2 = 'Variation #2';
const FINANCING_LD_COMBO_ORIGINAL = 'Original';

export const getIsFinancingLDComboControlActive = hasExperimentAndVariationWithoutControl(
  FINANCING_LD_STRIKETHROUGH_COMBO_EXPERIMENT,
  FINANCING_LD_COMBO_ORIGINAL,
);

export const getIsFinancingLDComboVariation1Active = hasExperimentAndVariation(
  FINANCING_LD_STRIKETHROUGH_COMBO_EXPERIMENT,
  FINANCING_LD_COMBO_VARIATION_1,
);

export const getIsFinancingLDComboVariation2Active = hasExperimentAndVariation(
  FINANCING_LD_STRIKETHROUGH_COMBO_EXPERIMENT,
  FINANCING_LD_COMBO_VARIATION_2,
);
