import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import { getJwtFromCookies, setJwtToCookies } from '@peloton/auth/authToken';
import { CHECKOUT_ACCESS_TOKEN_STORAGE_KEY } from '@peloton/auth/constants';
import { useOauth } from '@peloton/auth/OauthProvider';
import { useErrorReporter } from '@peloton/error-reporting/useErrorReporter';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import useLocalStorage from '@ecomm/hooks/useLocalStorage';
import isTokenExpired from './isTokenExpired';
import useTokenMismatchHandler from './useTokenMismatchHandler';

export const addAuthorizationHeader = (
  config: AxiosRequestConfig,
  token: string | null,
) => {
  const authToken = token?.replace(/"/g, '');
  config.headers['Authorization'] = `Bearer ${authToken}`;
};

const useAuthorizationHeader = (client: AxiosInstance) => {
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useOauth();
  const [clientHeadersReady, setClientHeadersReady] = useState<boolean>(false);
  const {
    errorReporter: { reportError },
  } = useErrorReporter();

  const [checkoutAccessToken, , removeCheckoutToken] = useLocalStorage(
    CHECKOUT_ACCESS_TOKEN_STORAGE_KEY,
    null,
  );

  const isOauthCookiesEnabled = useIsToggleActive()('oauth_cookie_enabled');

  useTokenMismatchHandler(client);

  useEffect(() => {
    if (isTokenExpired(checkoutAccessToken)) {
      removeCheckoutToken();
    }

    let requestInterceptor: number;

    const cookieJwt = getJwtFromCookies();

    const jwt = isOauthCookiesEnabled ? cookieJwt : checkoutAccessToken;

    const addInterceptor = (token?: string) => {
      requestInterceptor = client.interceptors.request.use(async config => {
        if (token) {
          addAuthorizationHeader(config, token);
        }
        if (isAuthenticated) {
          try {
            const accessToken = await getAccessTokenSilently();

            if (isOauthCookiesEnabled && !cookieJwt) {
              setJwtToCookies(accessToken);
            }

            if (accessToken) {
              addAuthorizationHeader(config, accessToken);
            }
          } catch (error) {
            reportError(new Error(`Access token getting error : ${error}`));
            // There was an error getting the access token (probably either because of a timeout or the user not being logged in)
            // In either case, we can let this request go through without an authorization header
          }
        } else {
          if (jwt) {
            addAuthorizationHeader(config, jwt);
          }
        }
        return config;
      });
      setClientHeadersReady(true);
    };

    if (isLoading) {
      return;
    }

    addInterceptor();

    return () => {
      client.interceptors.request.eject(requestInterceptor);
      setClientHeadersReady(false);
    };
  }, [
    client.interceptors.request,
    getAccessTokenSilently,
    isAuthenticated,
    checkoutAccessToken,
    removeCheckoutToken,
    reportError,
    isLoading,
    isOauthCookiesEnabled,
  ]);

  return clientHeadersReady;
};

export default useAuthorizationHeader;
