import type { Reducer } from 'redux';
import type { Cart } from '@ecomm/cart';
import type { OrderData } from '@ecomm/checkout/models';
import type { Money, Token, PaymentMethod } from '@ecomm/models';
import type { PaymentGateway } from './stripe';

export enum Actions {
  // when user goes the review page
  PreparePayments = 'ecomm/payments/PREPARE_PAYMENTS',
  PreparePaymentsSuccess = 'ecomm/payments/PREPARE_PAYMENTS_SUCCESS',
  PreparePaymentsFailure = 'ecomm/payments/PREPARE_PAYMENTS_FAILURE',

  // immediately before calling checkout
  FinalizePayments = 'ecomm/payments/FINALIZE_PAYMENTS',
  FinalizePaymentsSuccess = 'ecomm/payments/FINALIZE_PAYMENT_SUCCESS',
  FinalizePaymentsFailure = 'ecomm/payments/FINALIZE_PAYMENT_FAILURE',

  SubmitPayment = 'ecomm/payments/SUBMIT_PAYMENT',
}

type Action = PreparePayments | PreparePaymentsSuccess;

type StripePaymentIntent = {
  kind: 'stripePaymentIntent';
  paymentGateway: PaymentGateway;
  paymentMethod: stripe.paymentMethod.PaymentMethod;
  paymentIntent?: {
    clientSecret: string;
    id: string;
    status: 'requires_confirmation' | 'requires_source_action';
  };
};

type State = {
  stripe?: StripePaymentIntent;
};

const defaultState: State = {};

export const reducer: Reducer<State> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case Actions.PreparePaymentsSuccess:
      return {
        stripe: action.payload,
      };

    default:
      return state;
  }
};

export type PreparePayments = {
  type: Actions.PreparePayments;
  payload: {
    gateway: PaymentGateway;
    paymentInfo: PaymentInfo;
    billingDetails: any;
    paymentMethod: stripe.paymentMethod.PaymentMethod;
  };
};

export type PreparePaymentsSuccess = {
  type: Actions.PreparePaymentsSuccess;
  payload: StripePaymentIntent;
};

export type PreparePaymentsFailure = {
  type: Actions.PreparePaymentsFailure;
  payload: { error: Error };
};

export type FinalizePayments = {
  type: Actions.FinalizePayments;
  payload: {
    kind: 'stripePaymentIntent';
    paymentInfo: PaymentInfo;
    captchaToken?: string;
    captchaVersion?: string;
  };
};

export type FinalizePaymentsFailure = {
  type: Actions.FinalizePaymentsFailure;
  payload: { error: Error };
};

type PaymentInfo = {
  currency: string;
  amount: Money;
};

export const preparePayments = (
  gateway: PaymentGateway,
  paymentInfo: PaymentInfo,
  billingDetails: object,
) => ({
  type: Actions.PreparePayments,
  payload: { gateway, paymentInfo, billingDetails },
});

export const preparePaymentsSuccess = (payment: StripePaymentIntent) => ({
  type: Actions.PreparePaymentsSuccess,
  payload: payment,
});

export const preparePaymentsFailure = (error: Error) => ({
  type: Actions.PreparePaymentsFailure,
  payload: { error },
});

export const finalizePayments = (
  kind: 'stripePaymentIntent',
  paymentInfo: PaymentInfo,
  captchaToken?: string,
  captchaVersion?: string,
) => ({
  type: Actions.FinalizePayments,
  payload: { kind, paymentInfo, captchaToken, captchaVersion },
});

export const finalizePaymentsSuccess = (token: Token) => ({
  type: Actions.FinalizePaymentsSuccess,
  token,
});

export const finalizePaymentsFailure = (error: Error) => ({
  type: Actions.FinalizePaymentsFailure,
  payload: { error },
});

type GlobalState = {
  payments: State;
};

export type SubmitPayment = {
  type: Actions.SubmitPayment;
  payload: { kind: PaymentMethod; order: OrderData; cart: Cart };
};

export const submitPayment = (kind: PaymentMethod, order: OrderData, cart: Cart) => ({
  type: Actions.SubmitPayment,
  payload: {
    kind,
    order,
    cart,
  },
});

export const stripePayment = (state: GlobalState) => state.payments.stripe;
