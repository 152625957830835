export enum ActionType {
  Open = 'ecomm/tradeInUnavailableModal/OPEN',
  Close = 'ecomm/tradeInUnavailableModal/CLOSE',
  RemoveTradeIn = 'ecomm/tradeInUnavailable/REMOVE_TRADE_IN',
  RemoveTradeInSuccess = 'ecomm/tradeInUnavailable/REMOVE_TRADE_IN_SUCCESS',
  RemoveTradeInFailure = 'ecomm/tradeInUnavailable/REMOVE_TRADE_IN_FAILURE',
}

export const REDUCER_KEY = 'tradeIn';

export type ReducerState = {
  [REDUCER_KEY]: State;
};

type State = {
  isOpen: boolean;
  isProcessing: boolean;
};

export const defaultState = {
  isOpen: false,
  isProcessing: false,
};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.Open:
      return {
        ...state,
        isOpen: true,
      };

    case ActionType.Close:
      return {
        ...state,
        isOpen: false,
      };

    case ActionType.RemoveTradeIn:
      return {
        ...state,
        isProcessing: true,
      };

    case ActionType.RemoveTradeInSuccess:
    case ActionType.RemoveTradeInFailure:
      return {
        ...state,
        isOpen: false,
        isProcessing: false,
      };

    default:
      return state;
  }
};

export default reducer;

type OpenAction = {
  type: ActionType.Open;
};

type CloseAction = {
  type: ActionType.Close;
};

export type RemoveTradeInAction = {
  type: ActionType.RemoveTradeIn;
};

export type RemoveTradeInSuccessAction = {
  type: ActionType.RemoveTradeInSuccess;
};

export type RemoveTradeInFailureAction = {
  type: ActionType.RemoveTradeInFailure;
};

export const openModal = (): OpenAction => ({
  type: ActionType.Open,
});

export const closeModal = (): CloseAction => ({
  type: ActionType.Close,
});

export const removeTradeIn = (): RemoveTradeInAction => ({
  type: ActionType.RemoveTradeIn,
});

export const removeTradeInSuccess = (): RemoveTradeInSuccessAction => ({
  type: ActionType.RemoveTradeInSuccess,
});

export const removeTradeInFailure = (): RemoveTradeInFailureAction => ({
  type: ActionType.RemoveTradeInFailure,
});

const selectTradeIn = (state: ReducerState) => state[REDUCER_KEY];

export const selectIsOpen = (state: ReducerState): boolean => selectTradeIn(state).isOpen;

export const selectIsProcessing = (state: ReducerState): boolean =>
  selectTradeIn(state).isProcessing;

type Action =
  | OpenAction
  | CloseAction
  | RemoveTradeInAction
  | RemoveTradeInSuccessAction
  | RemoveTradeInFailureAction;
