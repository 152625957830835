import { lensPath, set } from 'ramda';
import type { ID } from '@ecomm/models';
import type { ProductConfiguration, Entities } from '../../models';

export enum ActionType {
  AddProductConfigurations = 'ecomm/shop/ADD_PRODUCT_CONFIGURATIONS',
  UpdateProductConfiguration = 'ecomm/shop/UPDATE_PRODUCT_CONFIGURATION',
}

type State = Entities<ProductConfiguration>;

export const initialState: State = {};

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.AddProductConfigurations:
      return {
        ...state,
        ...action.payload.configurations,
      };

    case ActionType.UpdateProductConfiguration: {
      const { product, trait, traitIndex } = action.payload;
      const updateLens = lensPath([product, 'traits', traitIndex]);

      return set(updateLens, trait, state);
    }
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  products: State;
};

export const addProductConfigurations = (
  configurations: Entities<ProductConfiguration>,
): AddProductConfigurationsAction => ({
  type: ActionType.AddProductConfigurations,
  payload: {
    configurations,
  },
});

type UpdateObject = {
  product: ID;
  trait: ID;
  traitIndex: number;
};

export const updateProductConfiguration = ({
  product,
  trait,
  traitIndex,
}: UpdateObject): UpdateProductConfigurationAction => ({
  type: ActionType.UpdateProductConfiguration,
  payload: {
    product,
    trait,
    traitIndex,
  },
});

export type AddProductConfigurationsAction = {
  type: ActionType.AddProductConfigurations;
  payload: {
    configurations: Entities<ProductConfiguration>;
  };
};

export type UpdateProductConfigurationAction = {
  type: ActionType.UpdateProductConfiguration;
  payload: {
    product: ID;
    trait: ID;
    traitIndex: number;
  };
};

type Action = AddProductConfigurationsAction | UpdateProductConfigurationAction;
