import type { RumInitConfiguration } from '@datadog/browser-rum';
import { datadogRum } from '@datadog/browser-rum';
import { COMMIT_REF, MONITORING_ENV, DD_RUM_SAMPLE_RATE } from '@peloton/app-config';
import type { ErrorProperties } from '../ErrorReporter';

export type FeatureFlag = string | boolean;
export type FeatureFlags = { [key: string]: FeatureFlag };

export interface DatadogReporterClient {
  init: (featureFlags?: FeatureFlags) => void;
  addFeatureFlag: (key: string, value: FeatureFlag) => void;
  addFeatureFlags: (FeatureFlags: FeatureFlags) => void;
  addError: (error: Error, properties?: ErrorProperties) => void;
}
const allowedTracingOrigins = [/https:\/\/.*k8s\.onepeloton.*/];

const datadogReady = (): boolean => !!datadogRum?.getInternalContext();

const keyPattern = /\s+/gi;
const getDatadogKey = (key: string) => key.replace(keyPattern, '_');

const init = (): void => {
  // check oneTrust Performance cookie consent before creating DD
  const win: any = window;
  const hasConsentToPerfCookies = win?.OnetrustActiveGroups?.includes('C0002') || false;
  if (!hasConsentToPerfCookies) {
    return;
  }

  const sampleRateAppConfig: number = Number.parseInt(DD_RUM_SAMPLE_RATE!) || 0;
  const sampleRate = window.navigator.userAgent.match(/HeadlessChrome|Prerender/)
    ? 0
    : sampleRateAppConfig;
  if (datadogRum && !datadogRum.getInternalContext()) {
    const rumConfig: RumInitConfiguration = {
      applicationId: 'd410086b-125a-4c4e-827a-76e0ac5ac591',
      clientToken: 'pub61d267c2792aa455b002a59e01b73caf',
      site: 'datadoghq.com',
      service: 'www',
      env: MONITORING_ENV,
      version: COMMIT_REF,
      sessionSampleRate: sampleRate,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      silentMultipleInit: true,
      allowedTracingOrigins,
    };
    // TODO: figure out nginx rewrites for this proxy on DPs
    if (MONITORING_ENV !== 'uat') {
      rumConfig.proxy = '/ddforward';
    }
    rumConfig.enableExperimentalFeatures = ['feature_flags'];

    datadogRum.init(rumConfig);
  }
};

const addFeatureFlag = (key: string, value: FeatureFlag): void => {
  if (datadogReady()) {
    datadogRum.addFeatureFlagEvaluation(getDatadogKey(key), value);
  }
};

const addFeatureFlags = (featureFlags: FeatureFlags): void => {
  if (datadogReady()) {
    for (const key in featureFlags) {
      datadogRum.addFeatureFlagEvaluation(getDatadogKey(key), featureFlags[key]);
    }
  }
};

const addError = (error: Error, properties?: ErrorProperties): void => {
  /* This is a workaround to fix errors not being posted for deploy previews
     Somehow datadogRum wasn't available after CDN initialization in deploy previews (CDN being blocked?)
     Added a check here to initialize datadog if it wasn't available
   */
  init();

  if (datadogReady()) {
    if (typeof properties === 'object' && properties !== null) {
      const { tags, extra, ...extraProperties } = properties;

      datadogRum.addError(error, {
        tags,
        extra: { ...extra, ...extraProperties },
      });
    }

    datadogRum.addError(error, undefined);
  }
};

const reporterClient: DatadogReporterClient = {
  init,
  addFeatureFlag,
  addFeatureFlags,
  addError,
};

export default reporterClient;
