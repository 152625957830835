import { useContext, useEffect, useState, useCallback } from 'react';
import { Context } from './../Provider';

const defaultSettings = {
  enableHighAccuracy: false,
  timeout: Infinity,
  maximumAge: 0,
};

export function useGeolocation() {
  const { position, setPosition, geolocation, setGeolocation } = useContext(Context);
  const [error, setError] = useState<GeolocationPositionError | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Next SSR friendly approach
    setGeolocation(navigator.geolocation);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getGeolocation = useCallback(() => {
    const successCb = (position: GeolocationPosition) => {
      setPosition(position);
      setIsLoading(false);
    };

    const errorCb = (error: GeolocationPositionError) => {
      setError(error);
      setIsLoading(false);
    };

    setIsLoading(true);
    if (geolocation) {
      geolocation.getCurrentPosition(successCb, errorCb, defaultSettings);
    }
  }, [geolocation, setPosition]);

  return { position, error, isLoading, getGeolocation };
}
