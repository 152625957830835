import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { useApq } from '@peloton/auth/config';
import { useLocale } from '@peloton/internationalize';

import type {
  QueryFinancingPerLocaleQuery,
  QueryFinancingPerLocaleQueryVariables,
} from './QueryFinancingPerLocale.generated';
import { QueryFinancingPerLocaleDocument } from './QueryFinancingPerLocale.generated';

export const useGetFinancingAvailabilityQuery = () => {
  const apolloClient = useApolloClient();
  const locale = useLocale();

  const apq = useApq();

  const financingAvailabilityQuery = useCallback(async () => {
    const { data } = await apolloClient.query<
      QueryFinancingPerLocaleQuery,
      QueryFinancingPerLocaleQueryVariables
    >({
      query: QueryFinancingPerLocaleDocument,
      variables: {
        locale,
      },
      context: { useApq: apq },
    });
    return data;
  }, [apolloClient, apq, locale]);

  return financingAvailabilityQuery;
};
