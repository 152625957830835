import React from 'react';
import { Redirect } from 'react-router';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

// NOTE: It's important that the chunk name matches the chunk name in
// pg-shop-accessories-display so that the code chunk includes both pages.
const ShopAccessoriesPage = asyncComponent(
  () => import('./ShopAccessoriesPage' /* webpackChunkName: "ShopAccessories" */),
);

const routes = [
  <Route
    key="shop-accessories"
    exact={true}
    path="/shop/accessories"
    component={ShopAccessoriesPage}
  />,
  <Redirect
    key="legacy-accessories-redirect"
    exact={true}
    from="/shop/accessories/:category(bike|tread-bootcamp)"
    to="/shop/accessories"
  />,
];

export default routes;
