import type { Reducer } from 'redux';

export type GlobalState = {
  concessionaryPopup: State;
};

type State = {
  open: boolean;
};

export const defaultState: State = {
  open: true,
};

export enum ActionTypes {
  Closed = 'ecomm/concessionary-popup/CLOSED',
}

export const reducer: Reducer<State> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case ActionTypes.Closed: {
      return {
        open: false,
      };
    }
    default:
      return state;
  }
};

type Action = Closed;

export type Closed = {
  type: ActionTypes.Closed;
};

export const popupClosed = (): Closed => ({
  type: ActionTypes.Closed,
});

const selectConcessionaryPopup = (state: GlobalState): State => state.concessionaryPopup;
export const selectConcessionaryPopupOpen = (state: GlobalState): boolean =>
  selectConcessionaryPopup(state).open;
