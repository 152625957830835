import type { ViewedProductAction as TViewedProductAction } from './analytics';
import { ActionType as AnalyticsActionType, viewedProduct } from './analytics';
import { addBundles } from './bundles';
import { addCategories } from './categories';
import type {
  UpdateBundleConfigurationAction as TUpdateBundleConfigurationAction,
  UpdateProductConfigurationAction as TUpdateProductConfigurationAction,
} from './configure';
import {
  addBundleConfigurations,
  addProductConfigurations,
  updateBundleConfiguration,
  updateSinglePackageSelection,
  updateAllPackageSelections,
  updateProductConfiguration,
} from './configure';
import { addDimensions } from './dimensions';
import { addProductOptions } from './productOptions';
import { addProducts } from './products';
import type { ReducerState as TReducerState } from './rootReducer';
import reducer from './rootReducer';
import type { ShopRouteMatch as TShopRouteMatch } from './selectors';
import {
  areProductsConfigurable,
  areProductsProductLine,
  getAreWarrantiesUpdating,
  getBundleById,
  getBundleBySlug,
  getBundleConfigurationById,
  getSortedBundleIdsByCategorySlugs,
  getBundleIdsByType,
  getBundleType,
  getBundles,
  getBundlesByIds,
  getCategoriesByIds,
  getCheapestBundle,
  getCheapestOptions,
  getConfigurations,
  getDimensionById,
  getDimensions,
  getIsBundleConfigured,
  getIsProductConfigured,
  getOptionAsConfigured,
  getOptionsAsConfigured,
  getPackageIdsByType,
  getPackageSelectionsBySlug,
  getProductById,
  getProductBySlug,
  getProductConfigurationById,
  getProductConfigurationsByBundleId,
  getProductIdsByCategorySlugs,
  getProductOptions,
  getProductOptionById,
  getProductOptionsByProductLine,
  getProducts,
  getProductsByIds,
  getProductPriceRange,
  getProductsWithCount,
  getShop,
  getTraits,
  getTraitsByIds,
  getTraitsByProductId,
  getTransactionState,
  getUIStateAreAnyLoading,
  getUIStateForBundleType,
  getUIException,
  matchConfigureRoute,
  matchRefurbConfigureRoute,
  matchShopAccessoriesRoute,
  matchShopDeviceRoute,
  matchShopRoute,
  strictGetOptionAsConfigured,
} from './selectors';
import type {
  AddToCartRequestedAction as TAddToCartRequestedAction,
  AddWarrantyToCartRequestedAction as TAddWarrantyToCartRequestedAction,
  RemoveWarrantyFromCartRequestedAction as TRemoveWarrantyFromCartRequestedAction,
} from './shopper';
import {
  ActionType,
  addToCartSuccess,
  addToCartFailure,
  addWarrantyToCartSuccess,
  requestAddBundleToCart,
  requestAddProductToCart,
  requestAddToCart,
  requestAddWarrantyToCart,
  requestRemoveWarrantyFromCart,
} from './shopper';
import { addTraits } from './traits';
import type {
  RequestBundleTypesSuccessAction as TRequestBundleTypesSuccessAction,
  RequestProductSlugAction as TRequestProductSlugAction,
  RequestBundleSlugAction as TRequestBundleSlugAction,
  RequestProductSlugFailAction as TRequestProductSlugFailAction,
  RequestProductSlugSuccessAction as TRequestProductSlugSuccessAction,
} from './ui';
import {
  ActionType as UiActionType,
  bundleTypesFetchFailed,
  bundleTypesFetchSucceeded,
  clearErrorsMatchingId,
  productSlugFetchFailed,
  productSlugFetchSucceeded,
  requestBundleTypes,
  requestProductSlugFetch,
  requestBundleSlugFetch,
  bundleSlugFetchFailed,
  bundleSlugFetchSucceeded,
  startTransaction,
  failTransaction,
} from './ui';

export default reducer;

export {
  ActionType,
  AnalyticsActionType,
  UiActionType,
  addBundleConfigurations,
  addBundles,
  addCategories,
  addDimensions,
  addProductConfigurations,
  addProductOptions,
  addProducts,
  addToCartSuccess,
  addToCartFailure,
  addTraits,
  addWarrantyToCartSuccess,
  areProductsConfigurable,
  areProductsProductLine,
  bundleTypesFetchFailed,
  bundleTypesFetchSucceeded,
  clearErrorsMatchingId,
  failTransaction,
  getAreWarrantiesUpdating,
  getBundleById,
  getBundleBySlug,
  getBundleConfigurationById,
  getBundleIdsByType,
  getBundleType,
  getBundles,
  getBundlesByIds,
  getCategoriesByIds,
  getCheapestBundle,
  getCheapestOptions,
  getConfigurations,
  getDimensionById,
  getDimensions,
  getIsBundleConfigured,
  getIsProductConfigured,
  getOptionAsConfigured,
  getOptionsAsConfigured,
  getPackageIdsByType,
  getPackageSelectionsBySlug,
  getProductById,
  getProductBySlug,
  getProductConfigurationById,
  getProductIdsByCategorySlugs,
  getProductOptionById,
  getProductOptions,
  getProductOptionsByProductLine,
  getProductPriceRange,
  getProducts,
  getProductsByIds,
  getProductsWithCount,
  getShop,
  getSortedBundleIdsByCategorySlugs,
  getTraits,
  getTraitsByIds,
  getTraitsByProductId,
  getTransactionState,
  getUIException,
  getUIStateAreAnyLoading,
  getUIStateForBundleType,
  matchConfigureRoute,
  matchRefurbConfigureRoute,
  matchShopAccessoriesRoute,
  matchShopDeviceRoute,
  matchShopRoute,
  productSlugFetchFailed,
  productSlugFetchSucceeded,
  requestAddBundleToCart,
  requestAddProductToCart,
  requestAddToCart,
  requestAddWarrantyToCart,
  requestBundleTypes,
  requestProductSlugFetch,
  requestBundleSlugFetch,
  requestRemoveWarrantyFromCart,
  startTransaction,
  updateBundleConfiguration,
  updateSinglePackageSelection,
  updateAllPackageSelections,
  updateProductConfiguration,
  viewedProduct,
  getProductConfigurationsByBundleId,
  bundleSlugFetchFailed,
  bundleSlugFetchSucceeded,
  strictGetOptionAsConfigured,
};

export type AddToCartRequestedAction = TAddToCartRequestedAction;
export type AddWarrantyToCartRequestedAction = TAddWarrantyToCartRequestedAction;
export type ReducerState = TReducerState;
export type RemoveWarrantyFromCartRequestedAction = TRemoveWarrantyFromCartRequestedAction;
export type RequestBundleTypesSuccessAction = TRequestBundleTypesSuccessAction;
export type RequestProductSlugAction = TRequestProductSlugAction;
export type RequestProductSlugFailAction = TRequestProductSlugFailAction;
export type RequestProductSlugSuccessAction = TRequestProductSlugSuccessAction;
export type ShopRouteMatch = TShopRouteMatch;
export type UpdateBundleConfigurationAction = TUpdateBundleConfigurationAction;
export type UpdateProductConfigurationAction = TUpdateProductConfigurationAction;
export type ViewedProductAction = TViewedProductAction;
export type RequestBundleSlugAction = TRequestBundleSlugAction;
