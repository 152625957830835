import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import type { Breakpoint } from '@peloton/responsive/reducer';
import { getMediaType } from '@peloton/responsive/reducer';
import { ConnectedVideo, toVideoProps, VideoContainer } from '@acme-ui/global';
import type { PromoHeroData } from '@ecomm/copy';
import type { HeroCarouselProps } from '../models';
import type { TrackingProps } from '../trackingUtils';
import HeroContent from './HeroContent';
import {
  ContainerStyles,
  buildMediaAgnosticHeroBreakpointDimensions,
  mediaAgnosticHeroBreakpointDimensions,
} from './styles';

type OptionalFields = 'image' | 'eyebrow' | 'support';
type Props = Required<Omit<PromoHeroData, OptionalFields>> &
  Pick<PromoHeroData, 'eyebrow' | 'support'> &
  TrackingProps &
  HeroCarouselProps & { activeSlide?: number };

const VideoHero: React.FC<React.PropsWithChildren<Props>> = ({
  video,
  inCarousel = false,
  onPause,
  onPlay,
  onReady,
  ...content
}) => {
  const currentBreakpoint = useSelector(getMediaType);
  const { poster, src } = toVideoProps(video, currentBreakpoint as Breakpoint);
  return (
    <Container>
      <ConnectedVideo
        src={src}
        poster={poster.src}
        posterBreakpointOptions={buildMediaAgnosticHeroBreakpointDimensions({
          breakpointOptions: poster.breakpointOptions,
          requiredDimensions: mediaAgnosticHeroBreakpointDimensions,
        })}
        autoPlay={!inCarousel}
        loop={!inCarousel}
        muted
        preload="auto"
        playsInline
        onLoadedMetadata={event => onReady && onReady(event.currentTarget)}
        onPause={onPause}
        onPlay={onPlay}
        showPlayPauseToggle={!inCarousel}
      />
      <StyledHeroContent {...content} />
    </Container>
  );
};

export default VideoHero;

const Container = styled.div`
  ${ContainerStyles}
  position: relative;

  ${VideoContainer} {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }
`;

const StyledHeroContent = styled(HeroContent)`
  position: relative;
`;
