import { Locale, useLocale } from '@peloton/internationalize';
import { assertNeverProduct } from '@ecomm/product-states/models/product';

const useDigitalPrice = (localeParam?: Locale): number => {
  const uselocale = useLocale();
  const locale = localeParam || uselocale;
  if (locale === Locale.EnglishUnitedStates) {
    return 1299;
  }

  if (locale === Locale.EnglishAustralia) {
    return 1699;
  }

  if (locale === Locale.EnglishCanada) {
    return 1699;
  }

  if (locale === Locale.EnglishUnitedKingdom) {
    return 1299;
  }
  if (locale === Locale.GermanGermany) {
    return 1299;
  }
  if (locale === Locale.GermanAustria) {
    return 1299;
  }

  if (locale === Locale.EnglishBeta) {
    return 1299;
  }

  if (locale === Locale.SpanishMexico) {
    return 1299;
  }

  if (locale === Locale.FrenchCanada) {
    return 1299;
  }
  return assertNeverProduct(
    locale,
    `for digital access price in subscription-plans/selectors`,
  );
};

export default useDigitalPrice;
