import type { LocationChangeAction } from 'connected-react-router';
import { LOCATION_CHANGE, replace } from 'connected-react-router';
import { contains, isEmpty } from 'ramda';
import type { match as Match } from 'react-router';
import type { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { fetcherSaga, getSubscriptionSlugs, PlanType } from '@peloton/subscription-plans';
import { mapGuestPassLegacySku } from '@ecomm/checkout/hooks/mapGuestPassLegacySku';
import { getDisabledSkus } from '@ecomm/cms-digital';
import type { Slug } from '@ecomm/models';
import { isLoaded } from '@ecomm/models';
import { failPlansFetch, requestedPlansFetch, succeedPlansFetch } from '../redux';
import { matchDigitalCheckout } from '../routes';
import { getUIState } from '../selectors';

export const validateRouteSaga = function* (slug: Slug): SagaIterator {
  const disabledSlugs = yield select(getDisabledSkus);
  const availableSlugs = yield select(getSubscriptionSlugs);
  const isValidSlug = contains(mapGuestPassLegacySku(slug), availableSlugs);
  const isDisabledSlug = contains(mapGuestPassLegacySku(slug), disabledSlugs);

  if (isEmpty(availableSlugs)) {
    yield put(replace('/digital'));
  } else if (!isValidSlug || isDisabledSlug) {
    // TODO: Get the links from a better source
    yield put(replace('/digital/checkout'));
  }
};

export const checkStatusSaga = function* (slug: Slug): SagaIterator {
  const status = yield select(getUIState);
  const loaded = yield call(isLoaded, status);

  if (loaded) {
    yield call(validateRouteSaga, slug);
  } else {
    yield put(requestedPlansFetch());
    yield call(fetcherSaga, PlanType.Digital, undefined, {
      onSuccess: succeedPlansFetch,
      onError: failPlansFetch,
    });

    yield call(validateRouteSaga, slug);
  }
};

export const checkRouteSaga = function* (_: LocationChangeAction) {
  const match: Match<{ slug: Slug }> = yield select(matchDigitalCheckout);

  if (match) {
    const slug: Slug = match.params.slug;
    yield call(checkStatusSaga, slug);
  }
};

const watcherSaga = function* () {
  yield takeEvery(LOCATION_CHANGE, checkRouteSaga);
};

export default watcherSaga;
