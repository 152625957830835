import { grey } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { useGeolocation } from '@ecomm/geolocation/hooks/useGeolocation';
import { locationFilter, visibleShowroomsFilter } from '@ecomm/geolocation/showroomUtils';
import { useGetShowroomStatus, useShowroomData } from '@ecomm/showrooms/hooks';
import { Spinner } from '@ecomm/spinner';
import { b5 } from '@ecomm/typography';
import ShowroomLinks from './MyShowrooms/ShowroomLinks';
import UseMyLocationLink from './MyShowrooms/UseMyLocationLink';

type Props = {
  className?: string;
  geolocationErrorText: string;
  isNavLink?: boolean;
  noShowroomsText: string;
  numberOfStores?: number;
  trackingParent?: string;
  useMyLocationText: string;
};

const MyShowrooms: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  geolocationErrorText,
  isNavLink = true,
  noShowroomsText,
  numberOfStores = 2,
  trackingParent,
  useMyLocationText,
}) => {
  const showrooms = useGetShowroomStatus();
  const showroomData = useShowroomData();
  const { error, position, getGeolocation, isLoading } = useGeolocation();

  let currentCoords;
  if (position && (position as GeolocationPosition).coords) {
    currentCoords = {
      latitude: (position as GeolocationPosition).coords.latitude,
      longitude: (position as GeolocationPosition).coords.longitude,
    };
  }

  if (showrooms === 'DISABLED') {
    return null;
  }

  const nearestVisibleShowrooms =
    currentCoords && showroomData
      ? showroomData
          .filter(visibleShowroomsFilter)
          .filter(locationFilter(currentCoords))
          .slice(0, numberOfStores)
      : [];

  const hasVisibleShowrooms = nearestVisibleShowrooms.length > 0;

  if (isLoading) return <Spinner className={className} style={{ display: 'block' }} />;
  if (position) {
    return hasVisibleShowrooms ? (
      <ShowroomLinks className={className} showrooms={nearestVisibleShowrooms} />
    ) : (
      <ErrorText>{noShowroomsText}</ErrorText>
    );
  }
  return error ? (
    <ErrorText>{geolocationErrorText}</ErrorText>
  ) : (
    <UseMyLocationLink
      className={className}
      isNavLink={isNavLink}
      onClick={getGeolocation}
      trackingParent={trackingParent}
      text={useMyLocationText}
    />
  );
};

const ErrorText = styled.p`
  ${b5};
  color: ${grey[70]};
`;

export default MyShowrooms;
