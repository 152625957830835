import React from 'react';
import { Partners } from '@ecomm/financing/models/Partners';
import type { Programs } from './models';

export const defaultProgramsData: Programs = {
  financingPartner: Partners.Affirm,
  secondaryFinancingPartner: Partners.None,
  showroom: 'OPEN',
  virtualShowroom: false,
  homeTrial: false,
  valueCalculator: false,
  apparel: false,
  tradeIn: false,
};

const ProgramContext = React.createContext<
  Programs & {
    setPrograms: (programs: Partial<Programs>) => void;
    initialPrograms: Programs;
  }
>({
  ...defaultProgramsData,
  setPrograms: () => {},
  initialPrograms: defaultProgramsData,
});

export default ProgramContext;

export const usePrograms = () => {
  const context = React.useContext(ProgramContext);

  if (context === undefined) {
    throw new Error('usePrograms must be used within a ProgramContext');
  }

  return context;
};
