import React from 'react';
import type { Options } from 'react-observer-api';
import { useVisibilityHook } from 'react-observer-api';

import { isRobot } from '@peloton/browser';

const useHasScrolledIntoView = (options?: Options) => {
  const { setElement, isVisible, forceVisible } = useVisibilityHook(options);
  const isWebCrawler = isRobot(window.navigator.userAgent);

  React.useEffect(() => {
    if (isWebCrawler) {
      forceVisible();
    }
  }, []);

  return {
    setMonitoredElement: setElement,
    hasMonitoredElementScrolledIntoView: isVisible,
    forceVisible,
  };
};

export default useHasScrolledIntoView;
