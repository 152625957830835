import { track } from '@peloton/analytics';
import { toCountryFromLocale } from '@peloton/internationalize';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import { useKeyFromDigitalContext, getKeyFromDigitalSku } from '@ecomm/cms-digital';
import type { HeaderBodyImage } from '@ecomm/copy/models.generated';
import {
  leadGenPromoConfig,
  VARIATION,
  ORIGINAL,
} from '@ecomm/feature-toggle/useLeadGenPromo';
import type { ProductPromoModalProps } from './View';

export const modalPriority = [
  'isTurkeyTrotLeadGenModalActive',
  'isBlackFridayLeadCaptureModalVariation1',
  'isLeadGenPromoModalVariation1',
  'isLeadGenPromoModalOriginal',
  'isCTAPromoModal1Active',
  'isCTAPromoModal2Active',
  'isABCFinancingLeadCaptureActive',
  'isABCHomeTrialLeadCaptureActive',
  'isABCHTFinancingLeadCaptureActive',
  'isUKTestClassLeadCaptureModalActive',
  'isHactActive',
  'isFaasPromoModalActive',
  'isHomeTrialLeadCaptureActive',
  'isABCProductModalAVariation1',
  'isABCProductModalAVariation2',
  'isABCProductModalAVariation3',
  'isABCProductModalBVariation1',
  'isABCProductModalBVariation2',
  'isABCProductModalBVariation3',
  'isABCProductModalCVariation1',
  'isABCProductModalCVariation2',
  'isABCProductModalCVariation3',
  'isABAppModalVariation1',
  'isABAppModalVariation2',
  'isClassesModalVariation1',
  'isClassesModalVariation2',
  'isClassesModalVariation3',
];

export const modalOpenTrackingProps = (
  unitName: string,
  props: ProductPromoModalProps,
) => ({
  event: 'Lead Capture Modal Opened',
  properties: {
    campaignId: '7011C000001QeUUQA0',
    campaignName: 'Email Capture Unit',
    country: props.country,
    pageName: getCurrentRelativePathname(),
    productInterest: props.deviceType,
    propertyType: 'Web',
    unitName,
    unitPlacement: 'Modal',
    modalTrigger: props.modalTrigger,
  },
});

export const ctaModalOpenTrackingProps = (
  unitName: string,
  props: ProductPromoModalProps,
) => ({
  event: 'Modal Opened',
  properties: {
    country: props.country,
    pageName: getCurrentRelativePathname(),
    productInterest: props.deviceType,
    propertyType: 'Web',
    unitName,
    unitPlacement: 'Modal',
    modalTrigger: props.modalTrigger,
  },
});

export const ctaModalCloseTrackingProps = (
  unitName: string,
  props: ProductPromoModalProps,
) => ({
  event: 'Modal Closed',
  properties: {
    country: props.country,
    pageName: getCurrentRelativePathname(),
    productInterest: props.deviceType,
    propertyType: 'Web',
    unitName,
    unitPlacement: 'Modal',
    modalTrigger: props.modalTrigger,
  },
});

export const useModalConfigs = (props: ProductPromoModalProps): any => {
  const { digitalPromoSku } = props;
  const leadCaptureModal1Key = useKeyFromDigitalContext(
    'classesLeadCaptureModal1' as HeaderBodyImage,
  );
  return Object.assign(
    {
      isTurkeyTrotLeadGenModalActive: {
        id: 'turkeyTrotLeadGenModal',
        modalType: 'leadCapture',
        unitName: 'Turkey Trot Lead Gen Modal',
        openHandler: () =>
          track(modalOpenTrackingProps('Turkey Trot Lead Gen Modal', props)),
        leadCapturePrivacyKey: 'leadCapture.privacy.turkeyTrot',
        campaignId: '7016O000001ORFuQAO',
        campaignName: 'Turkey Trot Lead Capture',
        additionalProps: {
          productItemInterest: 'tread',
        },
      },
      isLeadGenPromoModalOriginal: {
        id: 'apparelPromoLeadCaptureModal',
        modalType: 'leadCapture',
        unitName: 'Modal',
        leadCapturePrivacyKey: 'leadCapture.privacy.apparelPromo',
        campaignId: leadGenPromoConfig[ORIGINAL].campaignId,
        campaignName: leadGenPromoConfig[ORIGINAL].campaignName,
        openHandler: () =>
          track(
            modalOpenTrackingProps('Modal', {
              ...props,
              deviceType: undefined,
            }),
          ),
      },
      isLeadGenPromoModalVariation1: {
        id: 'accessoriesPromoLeadCaptureModal',
        modalType: 'leadCapture',
        unitName: 'Modal',
        leadCapturePrivacyKey: 'leadCapture.privacy.accessoriesPromo',
        campaignId: leadGenPromoConfig[VARIATION].campaignId,
        campaignName: leadGenPromoConfig[VARIATION].campaignName,
        openHandler: () =>
          track(
            modalOpenTrackingProps('Modal', {
              ...props,
              deviceType: undefined,
            }),
          ),
      },
      isBlackFridayLeadCaptureModalVariation1: {
        id: 'blackFridayLeadCaptureModal',
        contentLabel: 'BFCM Modal',
        modalType: 'leadCapture',
        unitName: 'BFCM Modal',
        productInterest: props.deviceType || 'digital',
        marketingConsent: 'explicit',
        openHandler: () => {
          const trackingProps = modalOpenTrackingProps('BFCM Modal', {
            ...props,
          });

          track({
            event: trackingProps.event,
            properties: {
              ...trackingProps.properties,
              productInterest: props.deviceType || 'digital',
              marketingConsent: 'explicit',
              country:
                toCountryFromLocale(trackingProps.properties.country) ||
                trackingProps.properties.country,
            },
          });
        },
      },
      isClassesModalVariation1: {
        id: leadCaptureModal1Key,
        modalType: 'leadCapture',
        unitName: 'Classes Page',
        openHandler: () =>
          track(
            modalOpenTrackingProps('Classes Page', {
              ...props,
              deviceType: undefined,
            }),
          ),
        marketingConsent: 'implicit',
      },
      isClassesModalVariation2: {
        id: 'classesLeadCaptureModal2',
        modalType: 'leadCapture',
        unitName: 'Classes Page',
        openHandler: () =>
          track(
            modalOpenTrackingProps('Classes Page', {
              ...props,
              deviceType: undefined,
            }),
          ),
      },
      isClassesModalVariation3: {
        id: 'classesLeadCaptureModal3',
        modalType: 'leadCapture',
        unitName: 'Classes Page',
        openHandler: () =>
          track(
            modalOpenTrackingProps('Classes Page', {
              ...props,
              deviceType: undefined,
            }),
          ),
      },
      isCTAPromoModal1Active: {
        hbiCmsId: 'CTAPromoModal1',
        ctaCmsId: 'CTAPromoModal1.cta',
        footnoteCmsId: 'CTAPromoModal1.footnote',
        modalType: 'ctaModal',
        openHandler: () => track(ctaModalOpenTrackingProps('CTAPromoModal1', props)),
      },
      isCTAPromoModal2Active: {
        hbiCmsId: 'CTAPromoModal2',
        ctaCmsId: 'CTAPromoModal2.cta',
        footnoteCmsId: 'CTAPromoModal2.footnote',
        modalType: 'ctaModal',
        openHandler: () => track(ctaModalOpenTrackingProps('CTAPromoModal2', props)),
      },
      isFaasPromoModalActive: {
        modalType: 'twoColumnModal',
        headerBodyImageId: 'faasPromoModal',
        contentLabel: 'FAAS Promo',
        ctaId: 'faasPromoModal.cta',
        legalId: 'faasPromoModal.legal',
        listId: 'faasPromoModal.list',
        openHandler: () => track(ctaModalOpenTrackingProps('FaaS Promo Modal', props)),
        closeHandler: () => track(ctaModalCloseTrackingProps('FaaS Promo Modal', props)),
      },
      isHactActive: {
        id: 'promoModal.contentLabel',
        modalType: 'promoModal',
      },
      isHomeTrialLeadCaptureActive: {
        contentLabel: 'Home Trial Lead Capture',
        id: 'germanyHomeTrialLeadCaptureModal',
        modalType: 'leadCapture',
        unitName: 'Home Trial Modal',
      },
      isUKTestClassLeadCaptureModalActive: {
        contentLabel: 'UK Test Class Lead Capture',
        id: 'testClassModal',
        modalType: 'leadCapture',
        unitName: 'UK Test Ride Modal',
        openHandler: () => track(modalOpenTrackingProps('UK Test Ride Modal', props)),
      },
      isABAppModalVariation1: {
        hbiCmsId: getKeyFromDigitalSku(
          'appOptimizationModal.promoModal',
          digitalPromoSku,
        ),
        ctaCmsId: getKeyFromDigitalSku('appOptimizationModal.cta', digitalPromoSku),
        footnoteCmsId: getKeyFromDigitalSku(
          'appOptimizationModal.footnote',
          digitalPromoSku,
        ),
        modalType: 'ctaModal',
        unitName: 'App Optimization Modal',
        openHandler: () =>
          track(ctaModalOpenTrackingProps('App Optimization Modal', props)),
      },
      isABAppModalVariation2: {
        id: getKeyFromDigitalSku('appOptimizationModal.leadCapture', digitalPromoSku),
        modalType: 'leadCapture',
        unitName: 'App Optimization Modal',
        openHandler: () => track(modalOpenTrackingProps('App Optimization Modal', props)),
      },
      isABCFinancingLeadCaptureActive: {
        id: 'financingLeadCaptureModal',
        modalType: 'leadCapture',
        unitName: 'Financing Email Capture Modal',
        footnoteId: 'membershipAccessoriesWithLink',
        openHandler: () =>
          track(modalOpenTrackingProps('Financing Email Capture Modal', props)),
      },
      isABCHomeTrialLeadCaptureActive: {
        id: 'homeTrialLeadCaptureModal',
        modalType: 'leadCapture',
        unitName: 'Home Trial Email Capture Modal',
        footnoteId: 'newCustomersOnly',
        openHandler: () =>
          track(modalOpenTrackingProps('Financing Email Capture Modal', props)),
      },
      isABCHTFinancingLeadCaptureActive: {
        id: 'htFinancingLeadCaptureModal',
        modalType: 'leadCapture',
        unitName: 'Home Trial and Financing Email Capture Modal',
        footnoteId: 'membershipAccessoriesWithLink',
        openHandler: () =>
          track(modalOpenTrackingProps('Financing Email Capture Modal', props)),
      },
      isABCProductModalAVariation1: {
        id: 'ProductLeadCaptureModalAVariation1',
        modalType: 'leadCapture',
        unitName: '30D Trial Lead Capture',
        openHandler: () =>
          track(modalOpenTrackingProps('Product Email Capture Modal', props)),
      },
      isABCProductModalAVariation2: {
        id: 'ProductLeadCaptureModalAVariation2',
        modalType: 'leadCapture',
        unitName: '30D Trial Lead Capture',
        openHandler: () =>
          track(modalOpenTrackingProps('Product Email Capture Modal', props)),
      },
      isABCProductModalAVariation3: {
        id: 'ProductLeadCaptureModalAVariation3',
        modalType: 'leadCapture',
        unitName: '30D Trial Lead Capture',
        openHandler: () =>
          track(modalOpenTrackingProps('Product Email Capture Modal', props)),
      },
      isABCProductModalBVariation1: {
        id: 'ProductLeadCaptureModalBVariation1',
        modalType: 'leadCapture',
        unitName: 'Product Lead Capture Modal B Variation 1',
        openHandler: () =>
          track(modalOpenTrackingProps('Product Email Capture Modal', props)),
      },
      isABCProductModalBVariation2: {
        id: 'ProductLeadCaptureModalBVariation2',
        modalType: 'leadCapture',
        unitName: 'Product Lead Capture Modal B Variation 2',
        openHandler: () =>
          track(modalOpenTrackingProps('Product Email Capture Modal', props)),
      },
      isABCProductModalBVariation3: {
        id: 'ProductLeadCaptureModalBVariation3',
        modalType: 'leadCapture',
        unitName: 'Product Lead Capture Modal B Variation 3',
        openHandler: () =>
          track(modalOpenTrackingProps('Product Email Capture Modal', props)),
      },
      isABCProductModalCVariation1: {
        id: 'ProductLeadCaptureModalCVariation1',
        modalType: 'leadCapture',
        unitName: 'Product Lead Capture Modal C Variation 1',
        openHandler: () =>
          track(modalOpenTrackingProps('Product Email Capture Modal', props)),
      },
      isABCProductModalCVariation2: {
        id: 'ProductLeadCaptureModalCVariation2',
        modalType: 'leadCapture',
        unitName: 'Product Lead Capture Modal C Variation 2',
        openHandler: () =>
          track(modalOpenTrackingProps('Product Email Capture Modal', props)),
      },
      isABCProductModalCVariation3: {
        id: 'ProductLeadCaptureModalCVariation3',
        modalType: 'leadCapture',
        unitName: 'Product Lead Capture Modal C Variation 3',
        openHandler: () =>
          track(modalOpenTrackingProps('Product Email Capture Modal', props)),
      },
    },
    overrideModalConfigs(props),
  );
};

export const overrideModalConfigs = (props: ProductPromoModalProps): any => {
  const { digitalPromoSku } = props;
  if (!digitalPromoSku) return {};

  const unitIds = {
    'digital-60d': ['60 day', '60D'],
    'digital-90d': ['90D', '90D'],
    'digital-mtm-3for1': ['3for1', '3for1'],
  };

  const unitId = unitIds[digitalPromoSku];

  if (!unitId) return {};

  return {
    isABCHomeTrialLeadCaptureActive: {
      id: getKeyFromDigitalSku('homeTrialLeadCaptureModal', digitalPromoSku),
      modalType: 'leadCapture',
      unitName: `Home Trial Email Capture Modal ${unitId[0]} free trial`,
      footnoteId: 'newCustomersOnly',
      openHandler: () =>
        track(modalOpenTrackingProps('Financing Email Capture Modal', props)),
    },
    isABCProductModalAVariation1: {
      id: getKeyFromDigitalSku('ProductLeadCaptureModalAVariation1', digitalPromoSku),
      modalType: 'leadCapture',
      unitName: `${unitId[1]} Trial Lead Capture`,
      openHandler: () =>
        track(modalOpenTrackingProps('Product Email Capture Modal', props)),
    },
    isABCProductModalAVariation2: {
      id: getKeyFromDigitalSku('ProductLeadCaptureModalAVariation2', digitalPromoSku),
      modalType: 'leadCapture',
      unitName: `${unitId[1]} Trial Lead Capture`,
      openHandler: () =>
        track(modalOpenTrackingProps('Product Email Capture Modal', props)),
    },
    isABCProductModalAVariation3: {
      id: getKeyFromDigitalSku('ProductLeadCaptureModalAVariation3', digitalPromoSku),
      modalType: 'leadCapture',
      unitName: `${unitId[1]} Trial Lead Capture`,
      openHandler: () =>
        track(modalOpenTrackingProps('Product Email Capture Modal', props)),
    },
    isABCFinancingLeadCaptureActive: {
      id: getKeyFromDigitalSku('financingLeadCaptureModal', digitalPromoSku),
      modalType: 'leadCapture',
      unitName: `${unitId[1]} Trial Financing Email Capture Modal`,
      footnoteId: 'membershipAccessoriesWithLink',
      openHandler: () =>
        track(modalOpenTrackingProps('Financing Email Capture Modal', props)),
    },
  };
};
