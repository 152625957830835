import { camelize } from 'humps';
import { createContext, useContext, useEffect, useState } from 'react';
import { useAsync } from 'react-use';
import { checkAndRemoveLocalePrefix } from '@peloton/internationalize/models/path';
import type { Experiments } from '../models';
import { addToAudienceAndBucket } from '../optimizely';
import { hasVariationFor, installPromisified } from '../SplitTesting';

export const ORIGINAL = 'Original';

export const Context = createContext<{
  experiments: Experiments | null;
  isLoaded: boolean;
  hasStalled: boolean;
  refreshExperiments?: () => void;
  onLoad: () => void;
}>({
  experiments: null,
  isLoaded: false,
  hasStalled: false,
  onLoad: () => {},
});

export const STALL_TIMEOUT = 5000;

export const useSplitTestingContext = () => useContext(Context);

// TODO: other things that the redux/sagas implementation does
// SET_USER_ATTRIBUTE
export const useOptimizely = (
  onLoad: () => void,
  optimizelyProjectId?: string,
  skipInstall?: boolean,
): void => {
  if (optimizelyProjectId === undefined) {
    throw new Error('Missing Optimizely Key');
  }
  const state = useAsync(async () => {
    if (skipInstall) {
      return;
    }
    await installPromisified(optimizelyProjectId);
    onLoad();
  }, [optimizelyProjectId]);

  if (state.error) {
    throw new Error('Optimizely failed to load');
  }
};

export const useHasVariation = (
  experiment: string,
  variation: string,
  noControl?: boolean,
) => {
  const { experiments } = useContext(Context);
  if (experiments === null) {
    return null;
  }

  return hasVariationFor(experiment, variation, experiments, noControl);
};

export const useSetUserAttribute = (attribute: string, value: any) => {
  const { refreshExperiments, isLoaded } = useSplitTestingContext();
  useEffect(() => {
    if (isLoaded) {
      addToAudienceAndBucket(attribute, value);
      refreshExperiments && refreshExperiments();
    }
  }, [isLoaded, attribute, value]);
};

/*
 * TODO: this seems to be excluding an experiment that depends on Google Geolocation API,
 * it was moved over completely since it is used in the redux implementation, but we should
 * move this to where the experiment is being used instead
 */
export const BANNER_OPTIMIZELY_SHOWROOMS_PUSH = 'bannerOptimizelyShowroomsPush';

export const camelCasedPath = (pathName: string) => {
  const snakeCasedPath = pathName.substring(1).replace(/[/,-]/g, '_');

  return camelize(snakeCasedPath);
};

export const usePathname = () => {
  const [pathname, setPathname] = useState('');

  useEffect(() => {
    setPathname(checkAndRemoveLocalePrefix(window.location.pathname));
  }, []);

  return pathname;
};

export const useRouteName = () => {
  const pathname = usePathname();

  return pathname === '/' ? 'home' : camelCasedPath(pathname);
};

export const useExperimentAndVariationForCampaign = (
  campaignName: string,
  allowOriginalVariation: boolean = false,
) => {
  const { experiments } = useContext(Context);

  if (experiments) {
    return getOptimizelyExperimentAndVariation(
      experiments,
      campaignName,
      allowOriginalVariation,
    );
  }

  return null;
};

export const usePersonalizationContentfulKeyPrefix = () => {
  const routeName = useRouteName();
  const campaignName = `${routeName}PagePersonalization`;

  const experimentAndVariation = useExperimentAndVariationForCampaign(campaignName);

  if (experimentAndVariation) {
    const { experiment, variation } = experimentAndVariation;
    return `${campaignName}.${experiment}.${variation}.`;
  }

  return '';
};

const getOptimizelyExperimentAndVariation = (
  experiments: Experiments,
  campaignName: string,
  allowOriginalVariation: boolean = false,
) => {
  for (const experiment in experiments) {
    if (experiment.includes(campaignName)) {
      const variation = experiments[experiment];
      const isOriginal = variation === ORIGINAL;
      const isValidExperiment = !isOriginal || (isOriginal && allowOriginalVariation);

      if (isValidExperiment) {
        return { experiment, variation };
      }
    }
  }

  return null;
};
