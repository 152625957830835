import { LOCATION_CHANGE, push } from 'connected-react-router';
import { pathOr } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import type { ID } from '@ecomm/models';
import { isLoaded } from '@ecomm/models';
import type { DeliveryDate } from '../data';
import { addDates } from '../data';
import { fetchDeliveryDates } from './api';
import { fail, getUIState, getMatch, request, success } from './redux';

type Response = Record<ID, DeliveryDate[]>;

export const fetch = function* (orderId: ID): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield put(request(orderId));

  try {
    const response: Response = yield call(fetchDeliveryDates, client, orderId);
    if (response && response[orderId] && response[orderId].length !== 0) {
      yield all([put(addDates(response)), put(success(orderId))]);
    } else {
      yield put(push(`/checkout/success?order_id=${orderId}`));
    }
  } catch (err) {
    const error = new DomainError('Error fetching delivery dates', err);
    yield all([put(reportError({ error })), put(fail(orderId, error))]);
  }
};

export const checkFetched = function* (orderId: ID): SagaIterator {
  const uiState = yield select(getUIState, orderId);

  if (!isLoaded(uiState)) {
    yield call(fetch, orderId);
  }
};

export const checkRoute = function* (): SagaIterator {
  const match = yield select(getMatch);
  const orderId = pathOr(undefined, ['params', 'id'], match);

  if (match) {
    yield call(checkFetched, orderId);
  }
};

const sagas = function* () {
  yield takeLatest(LOCATION_CHANGE, checkRoute);
};

export default sagas;
