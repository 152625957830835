import type { SagaIterator } from 'redux-saga';
import { all, call, select, takeEvery } from 'redux-saga/effects';
import { track } from '@peloton/analytics';
import { toCountry } from '@peloton/internationalize';
import { getUserTrackingProperties } from '@ecomm/auth';
import {
  getCartViewedProps,
  getCheckoutCategory,
  getCartItems,
  getHasTradeIn,
  getPromotionId,
  getShippingMethod,
  getCartId,
} from '@ecomm/cart';
import type { Item } from '@ecomm/cart/models/Item';
import { getSelectedPaymentMethod } from '@ecomm/checkout/redux';
import type { SubmitFinancingAction } from '@ecomm/financing';
import { Actions as FinancingActions, Partners } from '@ecomm/financing';
import type { OrderSubmitAction } from '../redux/submitOrder';
import { Actions as OrderActions } from '../redux/submitOrder';

export const analyticsSaga = function* (
  action: SubmitFinancingAction | OrderSubmitAction,
): SagaIterator {
  const financingPartner =
    action.type === FinancingActions.SubmitFinancing && action.payload?.financingPartner;

  const products = yield select(getCartViewedProps);
  const category = yield select(getCheckoutCategory);
  const cartItems = yield select(getCartItems);
  const name = cartItems.map((item: Item) => item.name);
  if (
    !financingPartner ||
    financingPartner === Partners.Affirm ||
    financingPartner === Partners.Citizens
  ) {
    yield call(track, {
      event: 'Submitted Checkout',
      properties: { billingPartner: financingPartner, products, category, name },
    });
  }
};

export enum EventType {
  ConfirmationModalOpened = 'Address Confirmation Modal Opened',
  SuggestedAddressConfirmed = 'Suggested Address Confirmed',
  OriginalAddressConfirmed = 'Original Entered Address Confirmed',
  ConfirmationModalClosed = 'Address Confirmation Modal Closed',
  VerificationModalOpened = 'Address Verification Modal Opened',
  EnteredAddressConfirmed = 'Entered Address Confirmed',
  EditAddressLinkClicked = 'Edit Address Link Clicked',
  VerificationModalClosed = 'Address Verification Modal Closed',
}

export enum Analytics {
  CustomStep = 'Address Validation Step Completed',
}

type ActionPayload = {
  stepName: string;
  event: Analytics.CustomStep;
};

export type CustomStepAction = {
  type: EventType;
  payload: ActionPayload;
};

export const trackAnalytics = function* ({
  payload: { stepName, event },
}: CustomStepAction): SagaIterator {
  const user = yield select(getUserTrackingProperties);
  const properties = yield all({
    category: select(getCheckoutCategory),
    checkoutID: select(getCartId),
    hasTradeIn: select(getHasTradeIn),
    products: select(getCartViewedProps),
    paymentMethod: select(getSelectedPaymentMethod),
    shippingMethod: select(getShippingMethod),
    country: toCountry(),
    promotion: select(getPromotionId),
    ...user,
    stepName,
  });

  const eventObject = {
    event,
    properties,
  };

  yield call(track, eventObject);
};

export const confirmationModalOpened = (stepName: string): CustomStepAction => ({
  type: EventType.ConfirmationModalOpened,
  payload: {
    stepName,
    event: Analytics.CustomStep,
  },
});

export const suggestedAddressConfirmed = (stepName: string): CustomStepAction => ({
  type: EventType.SuggestedAddressConfirmed,
  payload: {
    stepName,
    event: Analytics.CustomStep,
  },
});

export const originalAddressConfirmed = (stepName: string): CustomStepAction => ({
  type: EventType.OriginalAddressConfirmed,
  payload: {
    stepName,
    event: Analytics.CustomStep,
  },
});

export const confirmationModalClosed = (stepName: string): CustomStepAction => ({
  type: EventType.ConfirmationModalClosed,
  payload: {
    stepName,
    event: Analytics.CustomStep,
  },
});

export const verificationModalOpened = (stepName: string): CustomStepAction => ({
  type: EventType.VerificationModalOpened,
  payload: {
    stepName,
    event: Analytics.CustomStep,
  },
});

export const enteredAddressConfirmed = (stepName: string): CustomStepAction => ({
  type: EventType.EnteredAddressConfirmed,
  payload: {
    stepName,
    event: Analytics.CustomStep,
  },
});

export const editAddressLinkClicked = (stepName: string): CustomStepAction => ({
  type: EventType.EditAddressLinkClicked,
  payload: {
    stepName,
    event: Analytics.CustomStep,
  },
});

export const verificationModalClosed = (stepName: string): CustomStepAction => ({
  type: EventType.VerificationModalClosed,
  payload: {
    stepName,
    event: Analytics.CustomStep,
  },
});

const sagas = function* () {
  yield takeEvery(FinancingActions.SubmitFinancing, analyticsSaga);
  yield takeEvery(OrderActions.OrderSubmit, analyticsSaga);
  yield takeEvery(EventType.ConfirmationModalOpened, trackAnalytics);
  yield takeEvery(EventType.SuggestedAddressConfirmed, trackAnalytics);
  yield takeEvery(EventType.OriginalAddressConfirmed, trackAnalytics);
  yield takeEvery(EventType.ConfirmationModalClosed, trackAnalytics);
  yield takeEvery(EventType.VerificationModalOpened, trackAnalytics);
  yield takeEvery(EventType.EnteredAddressConfirmed, trackAnalytics);
  yield takeEvery(EventType.EditAddressLinkClicked, trackAnalytics);
  yield takeEvery(EventType.VerificationModalClosed, trackAnalytics);
};

export default sagas;
