import React, { createContext, useContext, useEffect } from 'react';
import { useClient } from '@peloton/api/ClientContext';
import { getCurrentUser } from './fetch';
import type { UserTrackingProperties } from './models/user';
import { useOauth } from './OauthProvider';
import type { ApiMeUser } from './types';

type UserInfo = {
  getUser: () => ApiMeUser | {};
  isUserLoading?: boolean;
};

export const UserInfoContext = createContext<UserInfo>({
  getUser: () => ({}),
  isUserLoading: true,
});

const UserInfoProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [isUserLoading, setIsUserLoading] = React.useState(true);
  const userRef = React.useRef<UserTrackingProperties>({
    isLoggedIn: false,
    hasDeviceSubscription: false,
    hasDigitalSubscription: false,
  });

  const client = useClient();
  const { isAuthenticated, isLoading } = useOauth();

  useEffect(() => {
    if (!client) return;
    const request = async () => {
      if (!isAuthenticated) {
        setIsUserLoading(false);
        return;
      }

      const currentUser = await getCurrentUser(client);

      if (currentUser) {
        const {
          hasActiveDeviceSubscription,
          hasActiveDigitalSubscription,
          email,
          obfuscatedEmail: hashedEmail,
          id,
        } = currentUser;

        userRef.current = {
          isLoggedIn: true,
          hasDeviceSubscription: hasActiveDeviceSubscription,
          hasDigitalSubscription: hasActiveDigitalSubscription,
          email,
          hashedEmail,
          userId: id,
        };

        setIsUserLoading(false);
      }
    };
    if (!isLoading) {
      request();
    }
  }, [client, isAuthenticated, isLoading]);

  const value = React.useMemo(
    () => ({
      getUser: () => userRef.current,
      isUserLoading,
    }),
    [isUserLoading, userRef],
  );

  return <UserInfoContext.Provider value={value}>{children}</UserInfoContext.Provider>;
};

export default UserInfoProvider;

export const useUserInfo = () => {
  const { getUser, isUserLoading } = useContext(UserInfoContext);
  return { getUser, isUserLoading };
};
