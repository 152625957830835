import { push } from 'connected-react-router';
import type { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getLocale } from '@peloton/env';
import Normalizers from '@peloton/forms/normalizers';
import {
  shortcodeToProvince,
  theUserIsInCanada,
  toCountry,
} from '@peloton/internationalize';
import { Links } from '@peloton/links';
import type { Cart } from '@ecomm/cart';
import {
  hasOnlyBuyoutInCart,
  hasPostalCode,
  hasShipmentQuote,
  getShippingFromCart,
  isEmpty,
} from '@ecomm/cart';
import type { RequestSuccess } from '@ecomm/cart/redux/cart';
import { Actions as CartActions } from '@ecomm/cart/redux/cart';

import type { ContactInfo } from '@ecomm/models';
import { AddressType } from '../models';
import {
  checkoutReady,
  updateAddressField,
  updateShipping,
  matchCheckoutRoute,
} from '../redux';
import { matchBuyoutRoute } from '../redux/selectors';

const shouldUpdateShipping = (cart: Cart): boolean =>
  hasPostalCode(cart) && !hasShipmentQuote(cart) && !hasOnlyBuyoutInCart(cart);

export const updateShippingDetailsSaga = function* (cart: Cart) {
  if (shouldUpdateShipping(cart)) {
    yield put(updateShipping(getShippingFromCart(cart) as ContactInfo));
  }
};

export const cartLoadedSaga = function* (action: RequestSuccess) {
  const cart = action.payload;

  if (isEmpty(cart)) {
    yield put(push(Links.peloton.path));
  } else {
    yield all([
      call(updateShippingDetailsSaga, cart),
      call(hydrateShippingForm, cart),
      put(checkoutReady()),
    ]);
  }
};

// Only dispatch the action to preload the shipping info form if there is a truthy value
const updateShippingField = (
  field: Parameters<typeof updateAddressField>[1],
  val: string | undefined,
) => (val ? updateAddressField(AddressType.Shipping, field, val) : { type: 'noop' });

export const hydrateShippingForm = function* (cart: Cart): SagaIterator {
  const locale = yield select(getLocale);
  const state = cart.shipping.address.state;
  const postalCode = cart.shipping.address.postalCode;

  yield all([
    put(updateShippingField('lastName', cart.shipping.name.last)),
    put(updateShippingField('firstName', cart.shipping.name.first)),
    put(updateShippingField('phone', cart.shipping.phone)),
    put(updateShippingField('line1', cart.shipping.address.line1)),
    put(updateShippingField('line2', cart.shipping.address.line2)),
    put(updateShippingField('city', cart.shipping.address.city)),
    put(
      updateShippingField(
        'state',
        state && theUserIsInCanada(toCountry())
          ? shortcodeToProvince[state] || state
          : state,
      ),
    ),
    put(
      updateShippingField(
        'postalCode',
        postalCode ? Normalizers.zipCode[locale](postalCode) : postalCode,
      ),
    ),
  ]);
};

export const checkRouteSaga = function* (action: RequestSuccess): SagaIterator {
  const checkoutMatch = yield select(matchCheckoutRoute);
  const isCheckout = checkoutMatch && checkoutMatch.isExact;

  if (isCheckout) {
    yield call(cartLoadedSaga, action);
  }

  const buyoutMatch = yield select(matchBuyoutRoute);
  const isBuyout = buyoutMatch && buyoutMatch.isExact;

  if (isBuyout) {
    yield all([call(hydrateShippingForm, action.payload), put(checkoutReady())]);
  }
};

const watcherSaga = function* () {
  yield takeLatest(CartActions.REQUEST_INITIAL_SUCCESS, checkRouteSaga);
};

export default watcherSaga;
