import type { ContentfulClientApi } from 'contentful';
import useSWR from 'swr';
import { useLocale } from '@peloton/internationalize';
import type { Locale } from '@peloton/internationalize';

const fetchEntry = async (
  client: ContentfulClientApi,
  entryId: string,
  locale: Locale,
) => {
  const params = {
    include: 10,
    locale,
  };
  return await client.getEntry<any>(entryId, params);
};

export const fetchEntries = async (
  client: ContentfulClientApi,
  locale: Locale,
  params: any,
) => {
  return await client.getEntries({ locale, ...params });
};

const useEntry = (client: ContentfulClientApi, entryId: string) => {
  const locale = useLocale();
  return useSWR(entryId, () => fetchEntry(client, entryId, locale));
};

export default useEntry;
