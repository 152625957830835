import { join, pathOr, pipe, props } from 'ramda';
import type { Address } from './Address';
import { isAddressComplete } from './Address';
import type { Name } from './Name';

export type ContactInfo = {
  name: Name;
  phone: string;
  address: Address;
};

export const isContactInfoComplete = (contactInfo: ContactInfo): boolean =>
  Boolean(contactInfo.name.first.trim()) &&
  Boolean(contactInfo.name.last.trim()) &&
  Boolean(contactInfo.phone.trim()) &&
  isAddressComplete(contactInfo.address);

export const toAddressee = pipe(
  pathOr({}, ['name']),
  props(['first', 'last']),
  join(' '),
);
