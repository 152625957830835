import React from 'react';
import type { RouteComponentProps } from 'react-router';
import { Route } from 'react-router';
import { Markdown } from '@peloton/copy';
import type { LegalText } from '@ecomm/copy';
import { useLegalTextData } from '@ecomm/copy';
import { InternationalSwitch } from '@ecomm/router/i18n/InternationalSwitch';
import { StyledLegalCopy } from './LegalSectionStyles';
import RefurbishedBikeLegalSection from './RefurbishedBikeLegalSection';

type Props = {
  end: Date;
  legalTextId: LegalText;
  start: Date;
};

const PromoLegalSection: React.FC<React.PropsWithChildren<Props>> = ({
  end,
  legalTextId,
  start,
}) => {
  return (
    <InternationalSwitch>
      <Route
        path="/shop/refurbished/:bundleType(bike|bike-plus)"
        exact
        render={props => <RefurbishedBikeLegalSection {...props} />}
      />
      <Route
        render={props => (
          <GlobalPromoLegalSection
            start={start}
            end={end}
            legalTextId={legalTextId}
            {...props}
          />
        )}
      />
    </InternationalSwitch>
  );
};

const GlobalPromoLegalSection: React.FC<
  React.PropsWithChildren<Props & RouteComponentProps>
> = ({ end, legalTextId, start }) => {
  const legalText = useLegalTextData(legalTextId);

  return (
    <StyledLegalCopy data-test-id={legalTextId}>
      <Markdown
        content={legalText}
        values={{
          start: start,
          end: end,
        }}
      />
    </StyledLegalCopy>
  );
};

export default PromoLegalSection;
