import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { UIState } from '@ecomm/models';
import { Status, toUIState } from '@ecomm/models';

export enum ActionTypes {
  VerifyJWTRequested = 'onewellness/pg-sso-enrollment/ui/VERIFY_JWT_REQUESTED',
  VerifyJWTSucceeded = 'onewellness/pg-sso-enrollment/ui/VERIFY_JWT_SUCCEEDED',
  VerifyJWTFailed = 'onewellness/pg-sso-enrollment/ui/VERIFY_JWT_FAILED',
}

export type State = UIState & {
  jwt?: string;
};

export const defaultState: State = {
  ...toUIState(Status.Init),
};

const reducer = (state: State = defaultState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.VerifyJWTRequested:
      return {
        ...toUIState(Status.Loading),
        jwt: action.payload.jwt,
      };
    case ActionTypes.VerifyJWTSucceeded:
      return {
        ...state,
        ...toUIState(Status.Loaded),
      };

    case ActionTypes.VerifyJWTFailed: {
      const { exception } = action.payload;
      return {
        ...state,
        ...toUIState(Status.Failed, exception),
      };
    }
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  verifyJWT: State;
};

export const requestVerifyJWT = (jwt: string): VerifyJWTRequestAction => ({
  type: ActionTypes.VerifyJWTRequested,
  payload: {
    jwt,
  },
});

export const verifyJWTSucceeded = (): VerifyJWTSuccessAction => ({
  type: ActionTypes.VerifyJWTSucceeded,
});

export const verifyJWTFailed = (
  errorId: string = 'corporateBenefitsVerification.errors.verifyJWT',
): VerifyJWTFailedAction => ({
  type: ActionTypes.VerifyJWTFailed,
  payload: { exception: toException(errorId) },
});

export type VerifyJWTRequestAction = {
  type: ActionTypes.VerifyJWTRequested;
  payload: {
    jwt: string;
  };
};
type VerifyJWTSuccessAction = {
  type: ActionTypes.VerifyJWTSucceeded;
};
type VerifyJWTFailedAction = {
  type: ActionTypes.VerifyJWTFailed;
  payload: { exception: Exception };
};

type Actions = VerifyJWTRequestAction | VerifyJWTSuccessAction | VerifyJWTFailedAction;
