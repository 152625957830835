import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import { assertClientContext, CLIENT_CONTEXT } from '@peloton/api';
import { getSignedInUserId, successfulProfileUpdate, toUser } from '@peloton/auth';
import { getUserTrackingProperties } from '@ecomm/auth';
import { updateMemberUsername } from '@onewellness/api';
import type { UpdateUsernameAction } from '@onewellness/redux';
import {
  updateUsernameSucceeded,
  updateUsernameFailed,
  UpdateUsernameActionTypes,
} from '@onewellness/redux';
import { trackUsernameUpdated } from '@onewellness/segment';

export const updateSaga = function* ({ payload }: UpdateUsernameAction): SagaIterator {
  const { audience, username } = payload;

  const client = yield getContext(CLIENT_CONTEXT);
  assertClientContext(client);

  const userTrackingProperties = yield select(getUserTrackingProperties);

  try {
    const memberId = yield select(getSignedInUserId);

    const updatedUser = yield call(updateMemberUsername, client, memberId, username);

    yield put(successfulProfileUpdate(toUser(updatedUser)));
    yield all([
      put(updateUsernameSucceeded()),
      call(trackUsernameUpdated, { audience, userTrackingProperties }),
    ]);
  } catch (e) {
    yield put(updateUsernameFailed(e.message));
  }
};

const watcher = function* () {
  yield takeEvery(UpdateUsernameActionTypes.UpdateUsername, updateSaga);
};

export default watcher;
