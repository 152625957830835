import type { Validations } from '@ecomm/form-validation';
import {
  ValidationErrors,
  allowMarketing,
  email,
  hasAcceptedPolicy,
  password,
} from '@ecomm/form-validation';
import type { Registration } from './Registration';

export const toRegistrationValidation = (): Validations<Registration> => ({
  allowMarketing,
  email: email.required(ValidationErrors.Missing),
  hasAcceptedPolicy: hasAcceptedPolicy.required(ValidationErrors.Missing),
  password: password.required(ValidationErrors.Missing),
});
